import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StyledInputLabel = styled(InputLabel)({
  color: "#102A43",
});

function PlotDetailsForm({ data, onSubmit, dataDetail }) {
  const [districts, setDistricts] = useState(data);
  const [blocks, setBlocks] = useState([]);
  const [villages, setVillages] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [blockNo, setBlockNo] = useState("");
  const [villageId, setVillageId] = useState("");
  const [khesraNo, setkhesraNo] = useState(null);
  const [khataNo, setkhataNo] = useState(null);
  const [subid, setSubid] = useState("");
  const [blockId, setblockId] = useState("");

  console.log(dataDetail);

  useEffect(() => {
    // Call the handleDistrictChange function when the component mounts
    handleDistrictChange({ target: { value: dataDetail.district } });
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    // Call the handleBlockChange function when the component mounts
    handleBlockChange({ target: { value: parseInt(dataDetail.blockNum) } });
    if (dataDetail.village) {
      setVillageId(dataDetail.village);
    }
  }, [blocks]);

  const handleDistrictChange = (event) => {
    const selectedDistrictId = event.target.value;
    setkhataNo("");
    setkhesraNo("");

    const selectedDistrict = districts.find(
      (d) => d.district_id === selectedDistrictId
    );

    if (selectedDistrict) {
      setDistrictId(selectedDistrictId);
      setBlocks(selectedDistrict.block);
      setVillages([]);
      setBlockNo("");
      setblockId("");
      setVillageId("");
    } else {
      setDistrictId("");
      setBlocks([]);
      setVillages([]);
      setBlockNo("");
      setblockId("");
      setVillageId("");
    }
  };

  const handleBlockChange = (event) => {
    const selectedBlockNumber = event.target.value;
    setkhataNo("");
    setkhesraNo("");

    const selectedBlock = blocks.find(
      (b) => b.block_no === selectedBlockNumber
    );

    if (selectedBlock) {
      setBlockNo(selectedBlockNumber);
      setVillages(selectedBlock.village);
      setVillageId("");
      if (selectedBlock.block_no === selectedBlockNumber) {
        setblockId(selectedBlock.block_id);
        setSubid(selectedBlock.sub_div_code);
      }
    } else {
      setBlockNo("");
      setblockId("");
      setVillages([]);
      setVillageId("");
    }
  };

  const handleSubmit = () => {
    if (!districtId || !blockId || !villageId) {
      toast.error("Please select all required fields", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (!khataNo && !khesraNo) {
      toast.error("Please give the khata number or khesra number", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const formDetails = {
        district: districtId,
        block: blockId,
        sub_div_code: subid,
        village: villageId,
        khesra_no: khesraNo,
        khata_no: khataNo,
        // district,block,village,khesraNo,khataNo
      };

      console.log(formDetails);
      // Call the onSubmit prop and pass the form details
      onSubmit(formDetails);
    }
  };

  return (
    <Grid container rowGap={4} className="form-container">
      <FormControl fullWidth variant="outlined" className="select-input">
        <StyledInputLabel id="district-label">District*</StyledInputLabel>
        <Select
          labelId="district-label"
          value={districtId}
          onChange={handleDistrictChange}
          label="District*"
        >
          <MenuItem value="">
            <em>Select District</em>
          </MenuItem>
          {districts &&
            districts.map((d) => (
              <MenuItem key={d.district_id} value={d.district_id}>
                {d.district_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <StyledInputLabel id="block-label">Block*</StyledInputLabel>
        <Select
          labelId="block-label"
          value={blockNo}
          onChange={handleBlockChange}
          label="Block*"
        >
          <MenuItem value="">Select Block</MenuItem>
          {blocks &&
            blocks.map((d) => (
              <MenuItem key={d.block_no} value={d.block_no}>
                {d.block_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <StyledInputLabel id="village-label">Village*</StyledInputLabel>
        <Select
          labelId="village-label"
          value={villageId}
          onChange={(e) => setVillageId(e.target.value)}
          label="Village*"
        >
          <MenuItem value="">Select Village</MenuItem>
          {villages &&
            villages.map((d) => (
              <MenuItem key={d.village_id} value={d.village_id}>
                {d.village_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <TextField
        label="Khesra Number"
        type="number"
        value={khesraNo}
        onChange={(e) => setkhesraNo(e.target.value)}
        className="text-input"
        fullWidth
        InputLabelProps={{
          style: { color: "#102A43" }, // Set the desired color here
        }}
      />

      <TextField
        label="Khata Number"
        type="number"
        value={khataNo}
        onChange={(e) => setkhataNo(e.target.value)}
        className="text-input"
        fullWidth
        InputLabelProps={{
          style: { color: "#102A43" }, // Set the desired color here
        }}
      />

      <Button
        variant="contained"
        color="primary"
        className="submit-button"
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <ToastContainer />
    </Grid>
  );
}

export default PlotDetailsForm;
