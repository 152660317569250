import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Typography, styled, GlobalStyles, colors } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledLink = styled(NavLink)(({ theme }) => ({
  display: 'block',
  textDecoration: 'none',
  textAlign:'center',
  margin: theme.spacing(8),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  border: '2px solid #334E68',
  backgroundColor:'#E0E8F9',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#9AA5B1',
  },
  '& h4': {
    margin: 0,
  },
}));

const Home = () => {

  const isSmallScreen = useMediaQuery('(max-width:700px)');

  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: "#F0F4F8",
            margin: 0,
            padding: 0
          }
        }}
      />


      <Typography variant={isSmallScreen ? 'h4' : 'h2'} align="center" gutterBottom backgroundColor="#243B53" color="#F0F4F8">
        Bihar Bhumi Jankari
      </Typography>
      <StyledLink to="/plots" activeClassName="active-link">
        <Typography variant={isSmallScreen ? 'h6' : 'h4'} color={'#3E4C59'}>अपना खाता देखे !!</Typography>
      </StyledLink>
      <StyledLink to="https://parimarjan.bihar.gov.in/biharBhumireport/ViewJamabandi" activeClassName="active-link">
        <Typography variant={isSmallScreen ? 'h6' : 'h4'} color={'#3E4C59'}>पंजी !!</Typography>
      </StyledLink>
    </>
  );
};

export default Home;
