import React, { useState, useRef } from 'react';
import { animateScroll } from 'react-scroll';
import { Link, NavLink } from 'react-router-dom';
import { Typography, Grid, GlobalStyles, styled, Box } from '@mui/material';
import '@fontsource/roboto/300.css';
import PlotDetailsForm from './components/form/plotDetailsForm.js';
import data from './assets/data.js';
import khatap from './components/datab/content.js'
import Table from './components/Table/table.js'
import useMediaQuery from '@mui/material/useMediaQuery';


const StyledGridItem = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4)
}));

const StyledLink = styled(NavLink)({
  textDecoration: 'none',
  fontSize: '1.2rem',
  fontStyle:'unset',
  color: '#243B53',
});

const StyledBox = styled('div')(({ theme }) => ({
  border: '2px solid #334E68', // Set border properties for the rectangular box
  padding: theme.spacing(2), // Adjust padding as needed
  textAlign: 'center', // Center the text within the box
  // Add additional styles as needed
  transition: 'box-shadow 0.3s ease',
  backgroundColor:'#E0E8F9',

  '&:hover': {
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    color: '#A61B1B'
  },
}));

function SeePlot() {
  // const datap = Axios.get('http://localhost:8080/api/search/village_level_search?village_no=2&district_no=14&block_no=13&sub_div_code=2&khata_no=8');
  // console.log(datap.json);
  
  const isSmallScreen = useMediaQuery('(max-width:700px)');
  const isHorizontal = useMediaQuery('(max-width: 1199px)')
  const [formData, setFormData] = useState(null);
  const tableRef = useRef(null);

 

  const handleFormSubmit = (data) => {
    // console.log(data);
    setFormData(data);
    animateScroll.scrollTo(tableRef.current.offsetTop, { duration: 2000 });
  };

  const dataDetail = {
    district: '',
    blockNum:'',
    village: '', 
  }

  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: "#F0F4F8",
            margin: 0,
            padding: 0
          }
        }}
      />

      <Typography variant={isSmallScreen ? 'h5' : 'h3'} fontWeight={'900'} textAlign={'center'} backgroundColor={'#243B53'} paddingY={'10px'} color={'#F0F4F8'}/*sx={{ typography: { sm: 'body1', xs: 'body2' } }}*/>
        Land Record for Entire Bihar State
      </Typography>


      <Grid container mt={4} pb={5} alignItems={'flex-start'} justifyContent={'space-around'} >
        {isHorizontal &&
          <Grid item xs={10} lg={3} mb ={5} style={{alignItems:'center', justifyContent:'center', padding:'50px', backgroundColor:'#D9E2EC'}}>
            <Typography variant='h6' fontWeight={900} mb={4} style={{textAlign:'center', textDecoration:'solid', textTransform:'capitalize'}}>Enter any details which you have</Typography>
            <PlotDetailsForm data={data} onSubmit={handleFormSubmit} dataDetail = {dataDetail}/>
          </Grid>
        }
        <Grid item xs={10} lg={7} pb={4} alignContent={'flex-start'} style={{ backgroundColor: '#9FB3C8', overflowY: 'auto' }}>
          <Typography variant={isSmallScreen ? 'h6' : 'h4'} fontWeight={600} component="h2" textAlign={'center'} style={{ backgroundColor: '#334E68' }} color={'#E0FCFF'}>
            Bhumi Record for Bihar
          </Typography>
          <Grid item container >
            {data.map((district) => (
              <StyledGridItem item key={district.district_id} xs={12} sm={6} md={4} >
                <StyledLink to={`${district.district_id}/${district.district_name}`} state={district} >
                  <StyledBox >
                    <Typography variant="body1">
                      {district.district_name}
                    </Typography>
                  </StyledBox>
                </StyledLink>
              </StyledGridItem>
            ))}
          </Grid>
        </Grid>

        
        {!isHorizontal &&
          <Grid item xs={10} lg={3} style={{alignItems:'center', justifyContent:'center', padding:'50px', backgroundColor:'#D9E2EC'}}>
            <Typography variant='h6' fontWeight={900} mb={4} style={{textAlign:'center', textDecoration:'solid', textTransform:'capitalize'}}>Enter any details which you have</Typography>
            <PlotDetailsForm data={data} onSubmit={handleFormSubmit} dataDetail = {dataDetail}/>
          </Grid>
        }
      </Grid>
      <Box ref={tableRef}>
          {formData && <Table formData={formData} />}
      </Box>
      
    </>
  );
}

export default SeePlot;

/*{ id: district.district_id, name: district }*/
{/* <List
              sx={{
                width: '100%',
                maxWidth: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: '100%',
              }}
              subheader={<li />}
            >
              {data.map((district) => (
                <Paper elevation={6} style={{ padding: '20px', textAlign: 'center',cursor:'pointer', margin:'20px'}}>
                  <ListItem key={district.district_id}>
                      <Link to={`/${district.district_name}`} state={{ id: district.district_id, name: district }} 
                      component="div"
                      variant="body1"
                      style={{
                        fontWeight: 'bold',
                        fontSize: '2rem',
                        color: '#000', // Default color
                        transition: 'color 0.3s',
                        textAlign:'center', // Smooth transition
                        '&:hover': {
                          color: '#f00', // Change color on hover
                        },
                      }}
                      >
                          <Typography variant="body1" style={{fontWeight: 'bold', fontSize: '2rem'}}>{district.district_name}</Typography>
                      </Link>
                  </ListItem>
                </Paper>
              ))}
  </List> */}
