const districtsData = [
  {
    "block": [
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अमरपुरxk"
                },
                {
                    "village_id": 3,
                    "village_name": "अलौदिया सराय"
                },
                {
                    "village_id": 4,
                    "village_name": "आशानगर"
                },
                {
                    "village_id": 5,
                    "village_name": "इटौरा"
                },
                {
                    "village_id": 6,
                    "village_name": "इमादपुर"
                },
                {
                    "village_id": 7,
                    "village_name": "उपरावाँ"
                },
                {
                    "village_id": 8,
                    "village_name": "उपरौरा"
                },
                {
                    "village_id": 9,
                    "village_name": "ओकनामा"
                },
                {
                    "village_id": 10,
                    "village_name": "कमरपुर पहाड़पुर"
                },
                {
                    "village_id": 11,
                    "village_name": "करिंगापुर"
                },
                {
                    "village_id": 12,
                    "village_name": "कल्याणपुर"
                },
                {
                    "village_id": 13,
                    "village_name": "कासिमचक"
                },
                {
                    "village_id": 14,
                    "village_name": "कोरई"
                },
                {
                    "village_id": 15,
                    "village_name": "कोसुक"
                },
                {
                    "village_id": 16,
                    "village_name": "कोहना सराय"
                },
                {
                    "village_id": 17,
                    "village_name": "गुंजरचक"
                },
                {
                    "village_id": 18,
                    "village_name": "गुलनी"
                },
                {
                    "village_id": 19,
                    "village_name": "गोनावॉं"
                },
                {
                    "village_id": 20,
                    "village_name": "चक मोहम्मद"
                },
                {
                    "village_id": 21,
                    "village_name": "चकदुल्ला"
                },
                {
                    "village_id": 22,
                    "village_name": "चकरसलपुर"
                },
                {
                    "village_id": 23,
                    "village_name": "चकहजियाईन"
                },
                {
                    "village_id": 24,
                    "village_name": "चाँन्दपुरा"
                },
                {
                    "village_id": 25,
                    "village_name": "चिस्तियाना"
                },
                {
                    "village_id": 26,
                    "village_name": "छबीलापुर"
                },
                {
                    "village_id": 27,
                    "village_name": "छातों"
                },
                {
                    "village_id": 28,
                    "village_name": "डुमरावाँ"
                },
                {
                    "village_id": 29,
                    "village_name": "डोयमकीता"
                },
                {
                    "village_id": 30,
                    "village_name": "तकियाकला"
                },
                {
                    "village_id": 31,
                    "village_name": "तुंगी"
                },
                {
                    "village_id": 32,
                    "village_name": "तेतरावॉं"
                },
                {
                    "village_id": 33,
                    "village_name": "तिउरी"
                },
                {
                    "village_id": 34,
                    "village_name": "देवकली"
                },
                {
                    "village_id": 35,
                    "village_name": "देवधा"
                },
                {
                    "village_id": 36,
                    "village_name": "देवीसराय"
                },
                {
                    "village_id": 37,
                    "village_name": "धनकी"
                },
                {
                    "village_id": 38,
                    "village_name": "नगवॉं"
                },
                {
                    "village_id": 39,
                    "village_name": "नेपुरा"
                },
                {
                    "village_id": 40,
                    "village_name": "पचौङी"
                },
                {
                    "village_id": 41,
                    "village_name": "पेढ़का"
                },
                {
                    "village_id": 42,
                    "village_name": "पतुआना"
                },
                {
                    "village_id": 43,
                    "village_name": "परोहा"
                },
                {
                    "village_id": 44,
                    "village_name": "पलटपुरा"
                },
                {
                    "village_id": 45,
                    "village_name": "पलनी"
                },
                {
                    "village_id": 46,
                    "village_name": "पावा"
                },
                {
                    "village_id": 47,
                    "village_name": "पीरपहाङी"
                },
                {
                    "village_id": 48,
                    "village_name": "फतेहली"
                },
                {
                    "village_id": 49,
                    "village_name": "बरियारपुर"
                },
                {
                    "village_id": 50,
                    "village_name": "बेलछी"
                },
                {
                    "village_id": 51,
                    "village_name": "बहुआरा"
                },
                {
                    "village_id": 52,
                    "village_name": "बियावानी"
                },
                {
                    "village_id": 53,
                    "village_name": "भैंसासुर"
                },
                {
                    "village_id": 54,
                    "village_name": "भोजपुर"
                },
                {
                    "village_id": 55,
                    "village_name": "मकदुआने"
                },
                {
                    "village_id": 56,
                    "village_name": "मघड़ा"
                },
                {
                    "village_id": 57,
                    "village_name": "मेघीनगवॉं"
                },
                {
                    "village_id": 58,
                    "village_name": "मजिदपुर"
                },
                {
                    "village_id": 59,
                    "village_name": "मण्डाछ खुर्द"
                },
                {
                    "village_id": 60,
                    "village_name": "मननकी"
                },
                {
                    "village_id": 61,
                    "village_name": "मुरौरा"
                },
                {
                    "village_id": 62,
                    "village_name": "मुस्तफापुर"
                },
                {
                    "village_id": 63,
                    "village_name": "मुसादपुर"
                },
                {
                    "village_id": 64,
                    "village_name": "मेहनौर"
                },
                {
                    "village_id": 65,
                    "village_name": "महानन्दपुर"
                },
                {
                    "village_id": 66,
                    "village_name": "माहीखैदक"
                },
                {
                    "village_id": 67,
                    "village_name": "मीरपुर ओकनामा"
                },
                {
                    "village_id": 68,
                    "village_name": "मोहीउद्दिनपुर"
                },
                {
                    "village_id": 69,
                    "village_name": "राणाविगहा"
                },
                {
                    "village_id": 70,
                    "village_name": "रामजीचक"
                },
                {
                    "village_id": 71,
                    "village_name": "रामपुर बैगनाबाद"
                },
                {
                    "village_id": 72,
                    "village_name": "रोजेवदरेआलम"
                },
                {
                    "village_id": 73,
                    "village_name": "लखरावxkB"
                },
                {
                    "village_id": 74,
                    "village_name": "लोटन"
                },
                {
                    "village_id": 75,
                    "village_name": "लोहगानी"
                },
                {
                    "village_id": 76,
                    "village_name": "वजीरपुर बैगनाबाद"
                },
                {
                    "village_id": 77,
                    "village_name": "वेरौटी"
                },
                {
                    "village_id": 78,
                    "village_name": "वाजिxQVnb"
                },
                {
                    "village_id": 79,
                    "village_name": "वासवन विगहा"
                },
                {
                    "village_id": 80,
                    "village_name": "विसकुरवा"
                },
                {
                    "village_id": 81,
                    "village_name": "सकरौल"
                },
                {
                    "village_id": 82,
                    "village_name": "समस्ती"
                },
                {
                    "village_id": 83,
                    "village_name": "सरबहदी"
                },
                {
                    "village_id": 84,
                    "village_name": "साठोपुर"
                },
                {
                    "village_id": 85,
                    "village_name": "सिंxFRn"
                },
                {
                    "village_id": 86,
                    "village_name": "सिंगारहाट"
                },
                {
                    "village_id": 87,
                    "village_name": "सिपाह"
                },
                {
                    "village_id": 88,
                    "village_name": "सोहxNmj"
                },
                {
                    "village_id": 89,
                    "village_name": "सोहसलेमपुर"
                },
                {
                    "village_id": 90,
                    "village_name": "हुजुरपुर xZrjUvb"
                },
                {
                    "village_id": 91,
                    "village_name": "हरगावॉं"
                },
                {
                    "village_id": 92,
                    "village_name": "हाजीपुर मखदुमपुर"
                }
            ],
            "block_id": "1",
            "block_no": 0,
            "block_name": "बिहार शरीफ [001]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अनन्तपुर"
                },
                {
                    "village_id": 3,
                    "village_name": "अब्दुलहैचक"
                },
                {
                    "village_id": 4,
                    "village_name": "अम्बा"
                },
                {
                    "village_id": 5,
                    "village_name": "अमरपुर"
                },
                {
                    "village_id": 6,
                    "village_name": "इतासंग-भदवा"
                },
                {
                    "village_id": 7,
                    "village_name": "इन्दवास"
                },
                {
                    "village_id": 8,
                    "village_name": "ईमामगंज"
                },
                {
                    "village_id": 9,
                    "village_name": "उतरनामॉं"
                },
                {
                    "village_id": 10,
                    "village_name": "उफरौल"
                },
                {
                    "village_id": 11,
                    "village_name": "ओरा"
                },
                {
                    "village_id": 12,
                    "village_name": "कुतूबपुरा"
                },
                {
                    "village_id": 13,
                    "village_name": "कुमरडीह"
                },
                {
                    "village_id": 14,
                    "village_name": "कादीविगहा"
                },
                {
                    "village_id": 15,
                    "village_name": "कायमपुर"
                },
                {
                    "village_id": 16,
                    "village_name": "खाजेऐतवार सराय"
                },
                {
                    "village_id": 17,
                    "village_name": "गैवी"
                },
                {
                    "village_id": 18,
                    "village_name": "गोबरिया"
                },
                {
                    "village_id": 19,
                    "village_name": "चन्दौरा"
                },
                {
                    "village_id": 20,
                    "village_name": "जहाँगीरा"
                },
                {
                    "village_id": 21,
                    "village_name": "डिहरा"
                },
                {
                    "village_id": 22,
                    "village_name": "देकपुरा"
                },
                {
                    "village_id": 23,
                    "village_name": "दोसुत"
                },
                {
                    "village_id": 24,
                    "village_name": "धमौली"
                },
                {
                    "village_id": 25,
                    "village_name": "निजॉय"
                },
                {
                    "village_id": 26,
                    "village_name": "पचासा"
                },
                {
                    "village_id": 27,
                    "village_name": "पैठना"
                },
                {
                    "village_id": 28,
                    "village_name": "पतासंग"
                },
                {
                    "village_id": 29,
                    "village_name": "पुनहा"
                },
                {
                    "village_id": 30,
                    "village_name": "पेशौर"
                },
                {
                    "village_id": 31,
                    "village_name": "पावाडीह"
                },
                {
                    "village_id": 32,
                    "village_name": "पितौंजिया"
                },
                {
                    "village_id": 33,
                    "village_name": "फटकपुरा"
                },
                {
                    "village_id": 34,
                    "village_name": "बनवारीपूर मोरा"
                },
                {
                    "village_id": 35,
                    "village_name": "बबुरवन्ना"
                },
                {
                    "village_id": 36,
                    "village_name": "बरान्दी"
                },
                {
                    "village_id": 37,
                    "village_name": "भंडारी"
                },
                {
                    "village_id": 38,
                    "village_name": "भेन्डा"
                },
                {
                    "village_id": 39,
                    "village_name": "भागनबिगहा"
                },
                {
                    "village_id": 40,
                    "village_name": "मई फरीदा"
                },
                {
                    "village_id": 41,
                    "village_name": "मंगरावाद"
                },
                {
                    "village_id": 42,
                    "village_name": "मजीतपुर"
                },
                {
                    "village_id": 43,
                    "village_name": "मजीदपुर"
                },
                {
                    "village_id": 44,
                    "village_name": "मड़नपुरा"
                },
                {
                    "village_id": 45,
                    "village_name": "मुशेपुर"
                },
                {
                    "village_id": 46,
                    "village_name": "मुस्तफापुर"
                },
                {
                    "village_id": 47,
                    "village_name": "महम्मदपुर"
                },
                {
                    "village_id": 48,
                    "village_name": "मिर्जापुर"
                },
                {
                    "village_id": 49,
                    "village_name": "मिल्की"
                },
                {
                    "village_id": 50,
                    "village_name": "मोरातलाव"
                },
                {
                    "village_id": 51,
                    "village_name": "मोहिउद्दीनपुर"
                },
                {
                    "village_id": 52,
                    "village_name": "रहुई"
                },
                {
                    "village_id": 53,
                    "village_name": "राधोपुर"
                },
                {
                    "village_id": 54,
                    "village_name": "वक्कूचक"
                },
                {
                    "village_id": 55,
                    "village_name": "वेना"
                },
                {
                    "village_id": 56,
                    "village_name": "वाजीतपुर"
                },
                {
                    "village_id": 57,
                    "village_name": "वासक सैदी"
                },
                {
                    "village_id": 58,
                    "village_name": "शेरपुर"
                },
                {
                    "village_id": 59,
                    "village_name": "शाहपुर"
                },
                {
                    "village_id": 60,
                    "village_name": "सुपासंग"
                },
                {
                    "village_id": 61,
                    "village_name": "सरैया"
                },
                {
                    "village_id": 62,
                    "village_name": "सुलेमानपुर"
                },
                {
                    "village_id": 63,
                    "village_name": "सोनसा"
                },
                {
                    "village_id": 64,
                    "village_name": "सोनसिकरा"
                },
                {
                    "village_id": 65,
                    "village_name": "सोसन्दी"
                },
                {
                    "village_id": 66,
                    "village_name": "हुड़ररिया"
                },
                {
                    "village_id": 67,
                    "village_name": "हवनपुरा"
                },
                {
                    "village_id": 68,
                    "village_name": "हुसैनपुर"
                }
            ],
            "block_id": "2",
            "block_no": 1,
            "block_name": "रहुई [002]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अजरकवे सिरसी"
                },
                {
                    "village_id": 3,
                    "village_name": "अबु महमदपुर"
                },
                {
                    "village_id": 4,
                    "village_name": "अलीपुर"
                },
                {
                    "village_id": 5,
                    "village_name": "आxZb"
                },
                {
                    "village_id": 6,
                    "village_name": "ऊखङा"
                },
                {
                    "village_id": 7,
                    "village_name": "करीमचक वलवा"
                },
                {
                    "village_id": 8,
                    "village_name": "काजीचक"
                },
                {
                    "village_id": 9,
                    "village_name": "किचनी"
                },
                {
                    "village_id": 10,
                    "village_name": "कोलावाँ"
                },
                {
                    "village_id": 11,
                    "village_name": "खरथुआ"
                },
                {
                    "village_id": 12,
                    "village_name": "खैरा"
                },
                {
                    "village_id": 13,
                    "village_name": "गंगटा"
                },
                {
                    "village_id": 14,
                    "village_name": "गोखुलपुर"
                },
                {
                    "village_id": 15,
                    "village_name": "गोडटुटा"
                },
                {
                    "village_id": 16,
                    "village_name": "गोनावाँ"
                },
                {
                    "village_id": 17,
                    "village_name": "गोसाईविगहा"
                },
                {
                    "village_id": 18,
                    "village_name": "चक नईमरूस्तमपुर"
                },
                {
                    "village_id": 19,
                    "village_name": "चकआकमल"
                },
                {
                    "village_id": 20,
                    "village_name": "चकरसिद"
                },
                {
                    "village_id": 21,
                    "village_name": "चकहनीफा"
                },
                {
                    "village_id": 22,
                    "village_name": "चेरन"
                },
                {
                    "village_id": 23,
                    "village_name": "चेरो"
                },
                {
                    "village_id": 24,
                    "village_name": "चौघङ"
                },
                {
                    "village_id": 25,
                    "village_name": "चौरिया"
                },
                {
                    "village_id": 26,
                    "village_name": "छतियाना"
                },
                {
                    "village_id": 27,
                    "village_name": "छिड़ी"
                },
                {
                    "village_id": 28,
                    "village_name": "जगतपुर"
                },
                {
                    "village_id": 29,
                    "village_name": "जीवनपुर"
                },
                {
                    "village_id": 30,
                    "village_name": "डिहरा"
                },
                {
                    "village_id": 31,
                    "village_name": "डिहरी"
                },
                {
                    "village_id": 32,
                    "village_name": "तेलमर"
                },
                {
                    "village_id": 33,
                    "village_name": "तारविगहा"
                },
                {
                    "village_id": 34,
                    "village_name": "तीरा"
                },
                {
                    "village_id": 35,
                    "village_name": "दक्षणिपुर"
                },
                {
                    "village_id": 36,
                    "village_name": "दैली"
                },
                {
                    "village_id": 37,
                    "village_name": "धिमोय"
                },
                {
                    "village_id": 38,
                    "village_name": "नचवार"
                },
                {
                    "village_id": 39,
                    "village_name": "नजीवनगर"
                },
                {
                    "village_id": 40,
                    "village_name": "नब्वह"
                },
                {
                    "village_id": 41,
                    "village_name": "नेहुसा"
                },
                {
                    "village_id": 42,
                    "village_name": "निशानी"
                },
                {
                    "village_id": 43,
                    "village_name": "पचौरा"
                },
                {
                    "village_id": 44,
                    "village_name": "पतसिया"
                },
                {
                    "village_id": 45,
                    "village_name": "पाकङ"
                },
                {
                    "village_id": 46,
                    "village_name": "पोआरी"
                },
                {
                    "village_id": 47,
                    "village_name": "पोरई"
                },
                {
                    "village_id": 48,
                    "village_name": "फैजावाद"
                },
                {
                    "village_id": 49,
                    "village_name": "फलहनवाँ"
                },
                {
                    "village_id": 50,
                    "village_name": "बेढ़ना"
                },
                {
                    "village_id": 51,
                    "village_name": "बामोचक"
                },
                {
                    "village_id": 52,
                    "village_name": "भेङिया"
                },
                {
                    "village_id": 53,
                    "village_name": "भूपतपुर"
                },
                {
                    "village_id": 54,
                    "village_name": "भोजपुर"
                },
                {
                    "village_id": 55,
                    "village_name": "मई"
                },
                {
                    "village_id": 56,
                    "village_name": "मुढ़ारी"
                },
                {
                    "village_id": 57,
                    "village_name": "मुन्दीचक"
                },
                {
                    "village_id": 58,
                    "village_name": "मुस्तफापुर"
                },
                {
                    "village_id": 59,
                    "village_name": "महमदपुर वलवा"
                },
                {
                    "village_id": 60,
                    "village_name": "महेशपुर"
                },
                {
                    "village_id": 61,
                    "village_name": "मानिकपुर"
                },
                {
                    "village_id": 62,
                    "village_name": "मिरदाहा चक"
                },
                {
                    "village_id": 63,
                    "village_name": "मोवारकपुर"
                },
                {
                    "village_id": 64,
                    "village_name": "रूपसपुर"
                },
                {
                    "village_id": 65,
                    "village_name": "रूस्तमपुर"
                },
                {
                    "village_id": 66,
                    "village_name": "रूस्तमपुर टाल"
                },
                {
                    "village_id": 67,
                    "village_name": "रामपुर"
                },
                {
                    "village_id": 68,
                    "village_name": "रामसंग"
                },
                {
                    "village_id": 69,
                    "village_name": "लधौरा"
                },
                {
                    "village_id": 70,
                    "village_name": "लोहरा"
                },
                {
                    "village_id": 71,
                    "village_name": "वराह"
                },
                {
                    "village_id": 72,
                    "village_name": "वस्ती"
                },
                {
                    "village_id": 73,
                    "village_name": "वसनियाxfkB"
                },
                {
                    "village_id": 74,
                    "village_name": "वाजीतपुर"
                },
                {
                    "village_id": 75,
                    "village_name": "वीरमपुर"
                },
                {
                    "village_id": 76,
                    "village_name": "शेरपुर"
                },
                {
                    "village_id": 77,
                    "village_name": "श्रीचन्दपुर"
                },
                {
                    "village_id": 78,
                    "village_name": "सुढ़ारी"
                },
                {
                    "village_id": 79,
                    "village_name": "सन्दलपुर"
                },
                {
                    "village_id": 80,
                    "village_name": "सवनहुआ"
                },
                {
                    "village_id": 81,
                    "village_name": "सादिकपुर"
                },
                {
                    "village_id": 82,
                    "village_name": "सिरसी"
                },
                {
                    "village_id": 83,
                    "village_name": "सोराड़ीह"
                },
                {
                    "village_id": 84,
                    "village_name": "हरिहरपुर"
                }
            ],
            "block_id": "3",
            "block_no": 2,
            "block_name": "हरनौत [003]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अजनौरा"
                },
                {
                    "village_id": 3,
                    "village_name": "अजयपुर"
                },
                {
                    "village_id": 4,
                    "village_name": "अन्धना"
                },
                {
                    "village_id": 5,
                    "village_name": "अहियापुर"
                },
                {
                    "village_id": 6,
                    "village_name": "इशापुर"
                },
                {
                    "village_id": 7,
                    "village_name": "ईब्राहिमपुर"
                },
                {
                    "village_id": 8,
                    "village_name": "ककड़ीया"
                },
                {
                    "village_id": 9,
                    "village_name": "ककैला"
                },
                {
                    "village_id": 10,
                    "village_name": "कखड़ा"
                },
                {
                    "village_id": 11,
                    "village_name": "कठनपुरा"
                },
                {
                    "village_id": 12,
                    "village_name": "कैड़ी"
                },
                {
                    "village_id": 13,
                    "village_name": "कथौली"
                },
                {
                    "village_id": 14,
                    "village_name": "कुन्दी"
                },
                {
                    "village_id": 15,
                    "village_name": "कपरसरी"
                },
                {
                    "village_id": 16,
                    "village_name": "केवई"
                },
                {
                    "village_id": 17,
                    "village_name": "किशमिरीचक"
                },
                {
                    "village_id": 18,
                    "village_name": "किसुनपुर"
                },
                {
                    "village_id": 19,
                    "village_name": "गनपुरा"
                },
                {
                    "village_id": 20,
                    "village_name": "चकचन्डासी"
                },
                {
                    "village_id": 21,
                    "village_name": "चन्डासी"
                },
                {
                    "village_id": 22,
                    "village_name": "चरूईपर"
                },
                {
                    "village_id": 23,
                    "village_name": "जगदीशपुर तियारी"
                },
                {
                    "village_id": 24,
                    "village_name": "जुहीचक"
                },
                {
                    "village_id": 25,
                    "village_name": "डोईया"
                },
                {
                    "village_id": 26,
                    "village_name": "दरूआरा"
                },
                {
                    "village_id": 27,
                    "village_name": "दाउदपुर"
                },
                {
                    "village_id": 28,
                    "village_name": "नदिऔना"
                },
                {
                    "village_id": 29,
                    "village_name": "ननौरा"
                },
                {
                    "village_id": 30,
                    "village_name": "नारी"
                },
                {
                    "village_id": 31,
                    "village_name": "नीरपुर"
                },
                {
                    "village_id": 32,
                    "village_name": "नोसरा"
                },
                {
                    "village_id": 33,
                    "village_name": "पपरनौसा"
                },
                {
                    "village_id": 34,
                    "village_name": "परासी"
                },
                {
                    "village_id": 35,
                    "village_name": "परिऔना"
                },
                {
                    "village_id": 36,
                    "village_name": "बेगमपुर"
                },
                {
                    "village_id": 37,
                    "village_name": "बड़ारा"
                },
                {
                    "village_id": 38,
                    "village_name": "बुथौल"
                },
                {
                    "village_id": 39,
                    "village_name": "बेलसर"
                },
                {
                    "village_id": 40,
                    "village_name": "बाराखुर्द"
                },
                {
                    "village_id": 41,
                    "village_name": "भखरी"
                },
                {
                    "village_id": 42,
                    "village_name": "मकनपुर"
                },
                {
                    "village_id": 43,
                    "village_name": "मच्छलडीहा"
                },
                {
                    "village_id": 44,
                    "village_name": "मुजप्फरपुर"
                },
                {
                    "village_id": 45,
                    "village_name": "मढारा"
                },
                {
                    "village_id": 46,
                    "village_name": "मण्डाच्छ"
                },
                {
                    "village_id": 47,
                    "village_name": "ममुराबाद"
                },
                {
                    "village_id": 48,
                    "village_name": "मेयार"
                },
                {
                    "village_id": 49,
                    "village_name": "मुस्तफापुर"
                },
                {
                    "village_id": 50,
                    "village_name": "मुसेपुर सर्फउदीन"
                },
                {
                    "village_id": 51,
                    "village_name": "यमुनापुर"
                },
                {
                    "village_id": 52,
                    "village_name": "रतनपुरा"
                },
                {
                    "village_id": 53,
                    "village_name": "रसलपुर"
                },
                {
                    "village_id": 54,
                    "village_name": "रामडीहा"
                },
                {
                    "village_id": 55,
                    "village_name": "लोहड़ी"
                },
                {
                    "village_id": 56,
                    "village_name": "वशं गोपालपुर"
                },
                {
                    "village_id": 57,
                    "village_name": "वंश गोपालपुर"
                },
                {
                    "village_id": 58,
                    "village_name": "शंकरपुर झाँवा"
                },
                {
                    "village_id": 59,
                    "village_name": "शेरपुर"
                },
                {
                    "village_id": 60,
                    "village_name": "सकरौठा"
                },
                {
                    "village_id": 61,
                    "village_name": "सरगाँव"
                },
                {
                    "village_id": 62,
                    "village_name": "सुल्तानपुर"
                },
                {
                    "village_id": 63,
                    "village_name": "हेगनपुरा"
                }
            ],
            "block_id": "4",
            "block_no": 3,
            "block_name": "नुरसराय [004]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अन्दी"
                },
                {
                    "village_id": 3,
                    "village_name": "अम्वाबिगहा"
                },
                {
                    "village_id": 4,
                    "village_name": "अमावाँ"
                },
                {
                    "village_id": 5,
                    "village_name": "अस्थावाँ"
                },
                {
                    "village_id": 6,
                    "village_name": "उगावाँ"
                },
                {
                    "village_id": 7,
                    "village_name": "ओइयाव"
                },
                {
                    "village_id": 8,
                    "village_name": "ओन्दा"
                },
                {
                    "village_id": 9,
                    "village_name": "कमलपुर"
                },
                {
                    "village_id": 10,
                    "village_name": "कुलती"
                },
                {
                    "village_id": 11,
                    "village_name": "कैला"
                },
                {
                    "village_id": 12,
                    "village_name": "कोनंद"
                },
                {
                    "village_id": 13,
                    "village_name": "खेतलपुरा"
                },
                {
                    "village_id": 14,
                    "village_name": "खाजे अहमदपुर"
                },
                {
                    "village_id": 15,
                    "village_name": "खोरमपुर"
                },
                {
                    "village_id": 16,
                    "village_name": "गिलानी"
                },
                {
                    "village_id": 17,
                    "village_name": "गोंगचक"
                },
                {
                    "village_id": 18,
                    "village_name": "गोविन्दपुर"
                },
                {
                    "village_id": 19,
                    "village_name": "चकदीन"
                },
                {
                    "village_id": 20,
                    "village_name": "चिस्तीपुर"
                },
                {
                    "village_id": 21,
                    "village_name": "जगरनाथपुर"
                },
                {
                    "village_id": 22,
                    "village_name": "जंगीपुर"
                },
                {
                    "village_id": 23,
                    "village_name": "जेठियार"
                },
                {
                    "village_id": 24,
                    "village_name": "जाना"
                },
                {
                    "village_id": 25,
                    "village_name": "जियर"
                },
                {
                    "village_id": 26,
                    "village_name": "डुमरावां"
                },
                {
                    "village_id": 27,
                    "village_name": "देशना"
                },
                {
                    "village_id": 28,
                    "village_name": "धोवीविगहा"
                },
                {
                    "village_id": 29,
                    "village_name": "नेरुत"
                },
                {
                    "village_id": 30,
                    "village_name": "निजामपुरा"
                },
                {
                    "village_id": 31,
                    "village_name": "नोआवाँ"
                },
                {
                    "village_id": 32,
                    "village_name": "नौरोजपुर"
                },
                {
                    "village_id": 33,
                    "village_name": "पचेतन"
                },
                {
                    "village_id": 34,
                    "village_name": "पेसौढ़ा"
                },
                {
                    "village_id": 35,
                    "village_name": "पिपरापुर"
                },
                {
                    "village_id": 36,
                    "village_name": "फतेहपुर"
                },
                {
                    "village_id": 37,
                    "village_name": "फिरोजपुर"
                },
                {
                    "village_id": 38,
                    "village_name": "बड़ेपुर"
                },
                {
                    "village_id": 39,
                    "village_name": "बेनार"
                },
                {
                    "village_id": 40,
                    "village_name": "बहादुरपुर"
                },
                {
                    "village_id": 41,
                    "village_name": "भैरो विगहा"
                },
                {
                    "village_id": 42,
                    "village_name": "भिखनी बिगहा"
                },
                {
                    "village_id": 43,
                    "village_name": "मदारपुर"
                },
                {
                    "village_id": 44,
                    "village_name": "मुफ्तीपुर"
                },
                {
                    "village_id": 45,
                    "village_name": "मुर्गियाचक"
                },
                {
                    "village_id": 46,
                    "village_name": "मलावां"
                },
                {
                    "village_id": 47,
                    "village_name": "मलावां"
                },
                {
                    "village_id": 48,
                    "village_name": "महमुदावाद"
                },
                {
                    "village_id": 49,
                    "village_name": "महम्मदपुर"
                },
                {
                    "village_id": 50,
                    "village_name": "महानन्दपुर"
                },
                {
                    "village_id": 51,
                    "village_name": "मानपुर"
                },
                {
                    "village_id": 52,
                    "village_name": "मालती"
                },
                {
                    "village_id": 53,
                    "village_name": "रजावाँ"
                },
                {
                    "village_id": 54,
                    "village_name": "रामपुर कटहरी"
                },
                {
                    "village_id": 55,
                    "village_name": "रामी विघा"
                },
                {
                    "village_id": 56,
                    "village_name": "लखनुबिघा"
                },
                {
                    "village_id": 57,
                    "village_name": "वेदौली"
                },
                {
                    "village_id": 58,
                    "village_name": "शेरपुर"
                },
                {
                    "village_id": 59,
                    "village_name": "शहवाजपुर"
                },
                {
                    "village_id": 60,
                    "village_name": "सकरावां"
                },
                {
                    "village_id": 61,
                    "village_name": "सदरपुर"
                },
                {
                    "village_id": 62,
                    "village_name": "सरवहदीनगर"
                },
                {
                    "village_id": 63,
                    "village_name": "हरगावां"
                }
            ],
            "block_id": "5",
            "block_no": 4,
            "block_name": "अस्थावाँ [005]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अख्तियारपुर"
                },
                {
                    "village_id": 3,
                    "village_name": "अहियाचक"
                },
                {
                    "village_id": 4,
                    "village_name": "इब्राहिमपुर"
                },
                {
                    "village_id": 5,
                    "village_name": "उतरथू"
                },
                {
                    "village_id": 6,
                    "village_name": "कथराही"
                },
                {
                    "village_id": 7,
                    "village_name": "कमालपुर"
                },
                {
                    "village_id": 8,
                    "village_name": "गाजीपुर"
                },
                {
                    "village_id": 9,
                    "village_name": "छतरपुर"
                },
                {
                    "village_id": 10,
                    "village_name": "छाछु विगहा"
                },
                {
                    "village_id": 11,
                    "village_name": "जक्की"
                },
                {
                    "village_id": 12,
                    "village_name": "जैतीपुर"
                },
                {
                    "village_id": 13,
                    "village_name": "जमसारी"
                },
                {
                    "village_id": 14,
                    "village_name": "जहाना"
                },
                {
                    "village_id": 15,
                    "village_name": "ताजनीपुर"
                },
                {
                    "village_id": 16,
                    "village_name": "दरियापुर"
                },
                {
                    "village_id": 17,
                    "village_name": "निगराईन"
                },
                {
                    "village_id": 18,
                    "village_name": "नीरपुर"
                },
                {
                    "village_id": 19,
                    "village_name": "नीरपुर खालसा"
                },
                {
                    "village_id": 20,
                    "village_name": "नौरंगा"
                },
                {
                    "village_id": 21,
                    "village_name": "बकरा"
                },
                {
                    "village_id": 22,
                    "village_name": "बरहोग"
                },
                {
                    "village_id": 23,
                    "village_name": "बिन्द"
                },
                {
                    "village_id": 24,
                    "village_name": "मकनपुर"
                },
                {
                    "village_id": 25,
                    "village_name": "मदनचक"
                },
                {
                    "village_id": 26,
                    "village_name": "मुफ्तीपुर"
                },
                {
                    "village_id": 27,
                    "village_name": "मसियां"
                },
                {
                    "village_id": 28,
                    "village_name": "महमुदावाद"
                },
                {
                    "village_id": 29,
                    "village_name": "मिरजापुर"
                },
                {
                    "village_id": 30,
                    "village_name": "रसलपुर"
                },
                {
                    "village_id": 31,
                    "village_name": "राजोपुर"
                },
                {
                    "village_id": 32,
                    "village_name": "रामपुर"
                },
                {
                    "village_id": 33,
                    "village_name": "लक्ष्मीपुर बगुलवा"
                },
                {
                    "village_id": 34,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 35,
                    "village_name": "शिबेसिंगपुर"
                },
                {
                    "village_id": 36,
                    "village_name": "सुरतपुर"
                }
            ],
            "block_id": "6",
            "block_no": 5,
            "block_name": "बिन्द [006]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "इसुआ"
                },
                {
                    "village_id": 3,
                    "village_name": "केनार"
                },
                {
                    "village_id": 4,
                    "village_name": "काजीचक"
                },
                {
                    "village_id": 5,
                    "village_name": "काजीचक"
                },
                {
                    "village_id": 6,
                    "village_name": "कोतरा"
                },
                {
                    "village_id": 7,
                    "village_name": "चेरों"
                },
                {
                    "village_id": 8,
                    "village_name": "छोटी मीसियाँ"
                },
                {
                    "village_id": 9,
                    "village_name": "जगजीवनपुर"
                },
                {
                    "village_id": 10,
                    "village_name": "तोड़ा"
                },
                {
                    "village_id": 11,
                    "village_name": "धनुकी"
                },
                {
                    "village_id": 12,
                    "village_name": "धनावाँ"
                },
                {
                    "village_id": 13,
                    "village_name": "नौआरूप"
                },
                {
                    "village_id": 14,
                    "village_name": "प्यारेपुर"
                },
                {
                    "village_id": 15,
                    "village_name": "प्रणावॉं"
                },
                {
                    "village_id": 16,
                    "village_name": "पौझ"
                },
                {
                    "village_id": 17,
                    "village_name": "फतेहपुर डुमरा"
                },
                {
                    "village_id": 18,
                    "village_name": "बढ़िया"
                },
                {
                    "village_id": 19,
                    "village_name": "मुत्तर्जाचक"
                },
                {
                    "village_id": 20,
                    "village_name": "मलावाँ"
                },
                {
                    "village_id": 21,
                    "village_name": "मुशहरी"
                },
                {
                    "village_id": 22,
                    "village_name": "मिसियाँ"
                },
                {
                    "village_id": 23,
                    "village_name": "मीरनगर"
                },
                {
                    "village_id": 24,
                    "village_name": "मोती बिगहा"
                },
                {
                    "village_id": 25,
                    "village_name": "रूपसपुर"
                },
                {
                    "village_id": 26,
                    "village_name": "शेखड़ा"
                },
                {
                    "village_id": 27,
                    "village_name": "सदहा"
                },
                {
                    "village_id": 28,
                    "village_name": "समस्तीपुर"
                },
                {
                    "village_id": 29,
                    "village_name": "सरमेरा"
                },
                {
                    "village_id": 30,
                    "village_name": "ससौर"
                },
                {
                    "village_id": 31,
                    "village_name": "ससौर टाल"
                },
                {
                    "village_id": 32,
                    "village_name": "सिंघौल"
                },
                {
                    "village_id": 33,
                    "village_name": "सिंधौल टाल"
                },
                {
                    "village_id": 34,
                    "village_name": "सिंधौल नईछक्का"
                },
                {
                    "village_id": 35,
                    "village_name": "सोनडीहा"
                },
                {
                    "village_id": 36,
                    "village_name": "हसैना"
                },
                {
                    "village_id": 37,
                    "village_name": "हुसैना"
                }
            ],
            "block_id": "7",
            "block_no": 6,
            "block_name": "सरमेरा [007]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अकबरपुर"
                },
                {
                    "village_id": 3,
                    "village_name": "अरपा"
                },
                {
                    "village_id": 4,
                    "village_name": "अलीपुर"
                },
                {
                    "village_id": 5,
                    "village_name": "अलीपुर"
                },
                {
                    "village_id": 6,
                    "village_name": "असाढ़ी"
                },
                {
                    "village_id": 7,
                    "village_name": "इन्दौत"
                },
                {
                    "village_id": 8,
                    "village_name": "कपसियामा"
                },
                {
                    "village_id": 9,
                    "village_name": "कुर्था"
                },
                {
                    "village_id": 10,
                    "village_name": "केशोपुर"
                },
                {
                    "village_id": 11,
                    "village_name": "कुसxrQk"
                },
                {
                    "village_id": 12,
                    "village_name": "काबा"
                },
                {
                    "village_id": 13,
                    "village_name": "कायता"
                },
                {
                    "village_id": 14,
                    "village_name": "कोरावाँ"
                },
                {
                    "village_id": 15,
                    "village_name": "खड्डी"
                },
                {
                    "village_id": 16,
                    "village_name": "गंगापुर"
                },
                {
                    "village_id": 17,
                    "village_name": "गन्नीपुर"
                },
                {
                    "village_id": 18,
                    "village_name": "गुलनी"
                },
                {
                    "village_id": 19,
                    "village_name": "गिलानी पुर"
                },
                {
                    "village_id": 20,
                    "village_name": "गोसाईपुर"
                },
                {
                    "village_id": 21,
                    "village_name": "गौरा"
                },
                {
                    "village_id": 22,
                    "village_name": "घोषी"
                },
                {
                    "village_id": 23,
                    "village_name": "चमण्डी"
                },
                {
                    "village_id": 24,
                    "village_name": "चिकसौरा"
                },
                {
                    "village_id": 25,
                    "village_name": "जुनियार"
                },
                {
                    "village_id": 26,
                    "village_name": "जलालपुर"
                },
                {
                    "village_id": 27,
                    "village_name": "त्रिलोकविगहा"
                },
                {
                    "village_id": 28,
                    "village_name": "दबौल"
                },
                {
                    "village_id": 29,
                    "village_name": "नवडीहा"
                },
                {
                    "village_id": 30,
                    "village_name": "पेंदापुर"
                },
                {
                    "village_id": 31,
                    "village_name": "पुना"
                },
                {
                    "village_id": 32,
                    "village_name": "फुलवरिया"
                },
                {
                    "village_id": 33,
                    "village_name": "बभनडीहा"
                },
                {
                    "village_id": 34,
                    "village_name": "बभनबरूही"
                },
                {
                    "village_id": 35,
                    "village_name": "ब्रह्मस्थान"
                },
                {
                    "village_id": 36,
                    "village_name": "बरहीविगहा"
                },
                {
                    "village_id": 37,
                    "village_name": "बाजिदपुर"
                },
                {
                    "village_id": 38,
                    "village_name": "बारा"
                },
                {
                    "village_id": 39,
                    "village_name": "बिजला"
                },
                {
                    "village_id": 40,
                    "village_name": "भदौल"
                },
                {
                    "village_id": 41,
                    "village_name": "भोकिला"
                },
                {
                    "village_id": 42,
                    "village_name": "मई"
                },
                {
                    "village_id": 43,
                    "village_name": "मखदूमपुर"
                },
                {
                    "village_id": 44,
                    "village_name": "मङवा"
                },
                {
                    "village_id": 45,
                    "village_name": "मदहा"
                },
                {
                    "village_id": 46,
                    "village_name": "मरांची"
                },
                {
                    "village_id": 47,
                    "village_name": "मलाxfkA"
                },
                {
                    "village_id": 48,
                    "village_name": "महमदपुर"
                },
                {
                    "village_id": 49,
                    "village_name": "महेशपुर"
                },
                {
                    "village_id": 50,
                    "village_name": "मियाँविगहा"
                },
                {
                    "village_id": 51,
                    "village_name": "मिर्जापुर"
                },
                {
                    "village_id": 52,
                    "village_name": "मोमिदपुर"
                },
                {
                    "village_id": 53,
                    "village_name": "यारपुर"
                },
                {
                    "village_id": 54,
                    "village_name": "रेङी"
                },
                {
                    "village_id": 55,
                    "village_name": "रजबा"
                },
                {
                    "village_id": 56,
                    "village_name": "लोहण्डा"
                },
                {
                    "village_id": 57,
                    "village_name": "शेखोपुर"
                },
                {
                    "village_id": 58,
                    "village_name": "सरदारविगहा"
                },
                {
                    "village_id": 59,
                    "village_name": "सिपारा"
                },
                {
                    "village_id": 60,
                    "village_name": "सोहरापुर"
                },
                {
                    "village_id": 61,
                    "village_name": "हरवंश पुर"
                },
                {
                    "village_id": 62,
                    "village_name": "हिलसा"
                }
            ],
            "block_id": "1",
            "block_no": 7,
            "block_name": "हिलसा [001]",
            "sub_div_code": "2"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "?????"
                },
                {
                    "village_id": 3,
                    "village_name": "अकबरपुर"
                },
                {
                    "village_id": 4,
                    "village_name": "अंकुरी"
                },
                {
                    "village_id": 5,
                    "village_name": "अमनावाँ"
                },
                {
                    "village_id": 6,
                    "village_name": "अर्जुन सरथुआ"
                },
                {
                    "village_id": 7,
                    "village_name": "अशरफपुर"
                },
                {
                    "village_id": 8,
                    "village_name": "आत्मा"
                },
                {
                    "village_id": 9,
                    "village_name": "इचहोस"
                },
                {
                    "village_id": 10,
                    "village_name": "इस्लामपुर"
                },
                {
                    "village_id": 11,
                    "village_name": "कटबारसलपुर"
                },
                {
                    "village_id": 12,
                    "village_name": "कपसिया"
                },
                {
                    "village_id": 13,
                    "village_name": "केवई"
                },
                {
                    "village_id": 14,
                    "village_name": "केवाली"
                },
                {
                    "village_id": 15,
                    "village_name": "काशमीरीचक"
                },
                {
                    "village_id": 16,
                    "village_name": "कासिंमपुर"
                },
                {
                    "village_id": 17,
                    "village_name": "कोचरा"
                },
                {
                    "village_id": 18,
                    "village_name": "कोरावाँ"
                },
                {
                    "village_id": 19,
                    "village_name": "कोविल"
                },
                {
                    "village_id": 20,
                    "village_name": "खरजमा"
                },
                {
                    "village_id": 21,
                    "village_name": "खोरमपुर"
                },
                {
                    "village_id": 22,
                    "village_name": "गंगटी"
                },
                {
                    "village_id": 23,
                    "village_name": "गवसपुर"
                },
                {
                    "village_id": 24,
                    "village_name": "चंद्र नटई खरजमा"
                },
                {
                    "village_id": 25,
                    "village_name": "चन्दनपुरा"
                },
                {
                    "village_id": 26,
                    "village_name": "चन्धारी"
                },
                {
                    "village_id": 27,
                    "village_name": "चौरमा"
                },
                {
                    "village_id": 28,
                    "village_name": "छोटी पैठना"
                },
                {
                    "village_id": 29,
                    "village_name": "जेठौना"
                },
                {
                    "village_id": 30,
                    "village_name": "जैतिया"
                },
                {
                    "village_id": 31,
                    "village_name": "जैतीपुर"
                },
                {
                    "village_id": 32,
                    "village_name": "डौंरा"
                },
                {
                    "village_id": 33,
                    "village_name": "तेतरीया"
                },
                {
                    "village_id": 34,
                    "village_name": "दरियापुर"
                },
                {
                    "village_id": 35,
                    "village_name": "दानापुर"
                },
                {
                    "village_id": 36,
                    "village_name": "धमौली"
                },
                {
                    "village_id": 37,
                    "village_name": "धोवडीहा"
                },
                {
                    "village_id": 38,
                    "village_name": "नीलकण्ठविगहा"
                },
                {
                    "village_id": 39,
                    "village_name": "पचरूखिया"
                },
                {
                    "village_id": 40,
                    "village_name": "पचलोवा"
                },
                {
                    "village_id": 41,
                    "village_name": "पनहर"
                },
                {
                    "village_id": 42,
                    "village_name": "परशुराय"
                },
                {
                    "village_id": 43,
                    "village_name": "पहाङपुरा"
                },
                {
                    "village_id": 44,
                    "village_name": "पिलखी"
                },
                {
                    "village_id": 45,
                    "village_name": "फरीदपुर"
                },
                {
                    "village_id": 46,
                    "village_name": "बकौर"
                },
                {
                    "village_id": 47,
                    "village_name": "बड़ाय"
                },
                {
                    "village_id": 48,
                    "village_name": "बड़ी पैठाना"
                },
                {
                    "village_id": 49,
                    "village_name": "ब्रहगावाँ"
                },
                {
                    "village_id": 50,
                    "village_name": "बैरा"
                },
                {
                    "village_id": 51,
                    "village_name": "बेले"
                },
                {
                    "village_id": 52,
                    "village_name": "बौरोडीह"
                },
                {
                    "village_id": 53,
                    "village_name": "भगवानपुर"
                },
                {
                    "village_id": 54,
                    "village_name": "भभौरा"
                },
                {
                    "village_id": 55,
                    "village_name": "मखदुमपुर"
                },
                {
                    "village_id": 56,
                    "village_name": "मखदुमपुर"
                },
                {
                    "village_id": 57,
                    "village_name": "मखदुमपुर"
                },
                {
                    "village_id": 58,
                    "village_name": "म़खदुमपुर"
                },
                {
                    "village_id": 59,
                    "village_name": "मेढ़ी"
                },
                {
                    "village_id": 60,
                    "village_name": "मैदी कला"
                },
                {
                    "village_id": 61,
                    "village_name": "मैदी खुर्द"
                },
                {
                    "village_id": 62,
                    "village_name": "मधुकरचक"
                },
                {
                    "village_id": 63,
                    "village_name": "मनीचक"
                },
                {
                    "village_id": 64,
                    "village_name": "मुस्तफापुर"
                },
                {
                    "village_id": 65,
                    "village_name": "महुअरी"
                },
                {
                    "village_id": 66,
                    "village_name": "महमदपुर"
                },
                {
                    "village_id": 67,
                    "village_name": "महमुदा"
                },
                {
                    "village_id": 68,
                    "village_name": "मिल्की"
                },
                {
                    "village_id": 69,
                    "village_name": "मिल्की"
                },
                {
                    "village_id": 70,
                    "village_name": "मोजफरा"
                },
                {
                    "village_id": 71,
                    "village_name": "मोवारकपुर"
                },
                {
                    "village_id": 72,
                    "village_name": "मोहिउद्धिनपुर"
                },
                {
                    "village_id": 73,
                    "village_name": "मौसुमा रामनगर"
                },
                {
                    "village_id": 74,
                    "village_name": "रतनपुरा"
                },
                {
                    "village_id": 75,
                    "village_name": "रूपसपुर"
                },
                {
                    "village_id": 76,
                    "village_name": "रानीपुर"
                },
                {
                    "village_id": 77,
                    "village_name": "वरडीह"
                },
                {
                    "village_id": 78,
                    "village_name": "वरदाहा"
                },
                {
                    "village_id": 79,
                    "village_name": "वेशवक"
                },
                {
                    "village_id": 80,
                    "village_name": "शेखल्ली"
                },
                {
                    "village_id": 81,
                    "village_name": "शरीफावाद"
                },
                {
                    "village_id": 82,
                    "village_name": "सुखचैन"
                },
                {
                    "village_id": 83,
                    "village_name": "सुढ़ी"
                },
                {
                    "village_id": 84,
                    "village_name": "सण्डा"
                },
                {
                    "village_id": 85,
                    "village_name": "सैफाबाद"
                },
                {
                    "village_id": 86,
                    "village_name": "सरथुआ खुर्द"
                },
                {
                    "village_id": 87,
                    "village_name": "सरैयापर"
                },
                {
                    "village_id": 88,
                    "village_name": "सिढ़ारी"
                },
                {
                    "village_id": 89,
                    "village_name": "सोहजना"
                },
                {
                    "village_id": 90,
                    "village_name": "हेरथु"
                },
                {
                    "village_id": 91,
                    "village_name": "हरवंशपुर"
                },
                {
                    "village_id": 92,
                    "village_name": "हाजीपुर चन्द्रनटई"
                }
            ],
            "block_id": "2",
            "block_no": 8,
            "block_name": "इस्लामपुर [002]",
            "sub_div_code": "2"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अतराम चक"
                },
                {
                    "village_id": 3,
                    "village_name": "अमरनार खास"
                },
                {
                    "village_id": 4,
                    "village_name": "इसहाकपुर"
                },
                {
                    "village_id": 5,
                    "village_name": "एकंगरडीह"
                },
                {
                    "village_id": 6,
                    "village_name": "एकंगरसराय"
                },
                {
                    "village_id": 7,
                    "village_name": "ओप"
                },
                {
                    "village_id": 8,
                    "village_name": "ओरियावां"
                },
                {
                    "village_id": 9,
                    "village_name": "औंगारी"
                },
                {
                    "village_id": 10,
                    "village_name": "कंजास"
                },
                {
                    "village_id": 11,
                    "village_name": "केलाविगहा"
                },
                {
                    "village_id": 12,
                    "village_name": "केशोपुर"
                },
                {
                    "village_id": 13,
                    "village_name": "कोठरी"
                },
                {
                    "village_id": 14,
                    "village_name": "कोरथू"
                },
                {
                    "village_id": 15,
                    "village_name": "कोसियावां"
                },
                {
                    "village_id": 16,
                    "village_name": "खोजपुर"
                },
                {
                    "village_id": 17,
                    "village_name": "ग्यासपुर"
                },
                {
                    "village_id": 18,
                    "village_name": "गोमहर"
                },
                {
                    "village_id": 19,
                    "village_name": "चकदह"
                },
                {
                    "village_id": 20,
                    "village_name": "चम्हेड़ा"
                },
                {
                    "village_id": 21,
                    "village_name": "चौरई"
                },
                {
                    "village_id": 22,
                    "village_name": "जगाई"
                },
                {
                    "village_id": 23,
                    "village_name": "जमुआवाँ"
                },
                {
                    "village_id": 24,
                    "village_name": "जयतीपुर"
                },
                {
                    "village_id": 25,
                    "village_name": "जहानचक"
                },
                {
                    "village_id": 26,
                    "village_name": "तेल्हाड़ा"
                },
                {
                    "village_id": 27,
                    "village_name": "तेल्हाड़ा सानी"
                },
                {
                    "village_id": 28,
                    "village_name": "तेलियामई"
                },
                {
                    "village_id": 29,
                    "village_name": "तारूपुर"
                },
                {
                    "village_id": 30,
                    "village_name": "तारापुर"
                },
                {
                    "village_id": 31,
                    "village_name": "तोलडबिगहा"
                },
                {
                    "village_id": 32,
                    "village_name": "दनियावां"
                },
                {
                    "village_id": 33,
                    "village_name": "दनियावां"
                },
                {
                    "village_id": 34,
                    "village_name": "धनगाँवा"
                },
                {
                    "village_id": 35,
                    "village_name": "धनहर"
                },
                {
                    "village_id": 36,
                    "village_name": "धुरगांव"
                },
                {
                    "village_id": 37,
                    "village_name": "धर्म बिगहा"
                },
                {
                    "village_id": 38,
                    "village_name": "धावां"
                },
                {
                    "village_id": 39,
                    "village_name": "नई"
                },
                {
                    "village_id": 40,
                    "village_name": "नराईच"
                },
                {
                    "village_id": 41,
                    "village_name": "नारायणपुर"
                },
                {
                    "village_id": 42,
                    "village_name": "निशनपुरा"
                },
                {
                    "village_id": 43,
                    "village_name": "पेन्दापुर दनियावां"
                },
                {
                    "village_id": 44,
                    "village_name": "पुरा"
                },
                {
                    "village_id": 45,
                    "village_name": "पसंघी"
                },
                {
                    "village_id": 46,
                    "village_name": "पार्थु"
                },
                {
                    "village_id": 47,
                    "village_name": "फरीदपुर"
                },
                {
                    "village_id": 48,
                    "village_name": "बंगलापर"
                },
                {
                    "village_id": 49,
                    "village_name": "बढ़ौना"
                },
                {
                    "village_id": 50,
                    "village_name": "बदलपुर"
                },
                {
                    "village_id": 51,
                    "village_name": "बरसियावां"
                },
                {
                    "village_id": 52,
                    "village_name": "ब्रिजोखरी"
                },
                {
                    "village_id": 53,
                    "village_name": "बादराबाद"
                },
                {
                    "village_id": 54,
                    "village_name": "बाला विगहा"
                },
                {
                    "village_id": 55,
                    "village_name": "बांस बिगहा"
                },
                {
                    "village_id": 56,
                    "village_name": "भानु विगहा"
                },
                {
                    "village_id": 57,
                    "village_name": "मखदुमपुर"
                },
                {
                    "village_id": 58,
                    "village_name": "मंडाच्छ"
                },
                {
                    "village_id": 59,
                    "village_name": "मुन्दीपुर"
                },
                {
                    "village_id": 60,
                    "village_name": "मुसहरी"
                },
                {
                    "village_id": 61,
                    "village_name": "महम्मदपुर"
                },
                {
                    "village_id": 62,
                    "village_name": "महमहमदपुर"
                },
                {
                    "village_id": 63,
                    "village_name": "महराजगंज"
                },
                {
                    "village_id": 64,
                    "village_name": "माधोपु अमनार"
                },
                {
                    "village_id": 65,
                    "village_name": "मानसिंघपुर"
                },
                {
                    "village_id": 66,
                    "village_name": "मिर्जापुर"
                },
                {
                    "village_id": 67,
                    "village_name": "मोजफराबाद"
                },
                {
                    "village_id": 68,
                    "village_name": "मोजाहिदपुर"
                },
                {
                    "village_id": 69,
                    "village_name": "मोसिमगंज"
                },
                {
                    "village_id": 70,
                    "village_name": "रक्सा"
                },
                {
                    "village_id": 71,
                    "village_name": "रसलपुर"
                },
                {
                    "village_id": 72,
                    "village_name": "रसलपुर -2"
                },
                {
                    "village_id": 73,
                    "village_name": "रसलपुर-1"
                },
                {
                    "village_id": 74,
                    "village_name": "रसलपुर-3"
                },
                {
                    "village_id": 75,
                    "village_name": "रसीसा"
                },
                {
                    "village_id": 76,
                    "village_name": "रादील"
                },
                {
                    "village_id": 77,
                    "village_name": "रानीपुर"
                },
                {
                    "village_id": 78,
                    "village_name": "लिवडी"
                },
                {
                    "village_id": 79,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 80,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 81,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 82,
                    "village_name": "वाजितपुर"
                },
                {
                    "village_id": 83,
                    "village_name": "विशुनपुर"
                },
                {
                    "village_id": 84,
                    "village_name": "सैदपुर"
                },
                {
                    "village_id": 85,
                    "village_name": "सुलतानxVnb"
                },
                {
                    "village_id": 86,
                    "village_name": "सुलतानपुर"
                },
                {
                    "village_id": 87,
                    "village_name": "सादिकपुर"
                },
                {
                    "village_id": 88,
                    "village_name": "सालेहपुर"
                },
                {
                    "village_id": 89,
                    "village_name": "सिरियावां"
                },
                {
                    "village_id": 90,
                    "village_name": "सोनियावां"
                },
                {
                    "village_id": 91,
                    "village_name": "हाजिपुर"
                }
            ],
            "block_id": "3",
            "block_no": 9,
            "block_name": "एकंगरसराय [003]",
            "sub_div_code": "2"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अलावां"
                },
                {
                    "village_id": 3,
                    "village_name": "अलीपुर"
                },
                {
                    "village_id": 4,
                    "village_name": "कुतलुपुर"
                },
                {
                    "village_id": 5,
                    "village_name": "किरतीपुर"
                },
                {
                    "village_id": 6,
                    "village_name": "चौसन्डा"
                },
                {
                    "village_id": 7,
                    "village_name": "तारा विगहा"
                },
                {
                    "village_id": 8,
                    "village_name": "दादुपुर"
                },
                {
                    "village_id": 9,
                    "village_name": "धनावां"
                },
                {
                    "village_id": 10,
                    "village_name": "पवई"
                },
                {
                    "village_id": 11,
                    "village_name": "पिलिxI"
                },
                {
                    "village_id": 12,
                    "village_name": "फतेहपुर"
                },
                {
                    "village_id": 13,
                    "village_name": "ब्रिकमपुर"
                },
                {
                    "village_id": 14,
                    "village_name": "बाजितपुर खिरौरी"
                },
                {
                    "village_id": 15,
                    "village_name": "मई"
                },
                {
                    "village_id": 16,
                    "village_name": "माणिकपुर"
                },
                {
                    "village_id": 17,
                    "village_name": "मिलकी"
                },
                {
                    "village_id": 18,
                    "village_name": "लदुआरी"
                },
                {
                    "village_id": 19,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 20,
                    "village_name": "विसाई बिगहा"
                },
                {
                    "village_id": 21,
                    "village_name": "शंकरडीह"
                },
                {
                    "village_id": 22,
                    "village_name": "शिवनगर"
                },
                {
                    "village_id": 23,
                    "village_name": "सिनाxfkB"
                },
                {
                    "village_id": 24,
                    "village_name": "सोनचरी"
                }
            ],
            "block_id": "4",
            "block_no": 10,
            "block_name": "परबलपुर [004]",
            "sub_div_code": "2"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "??????"
                },
                {
                    "village_id": 3,
                    "village_name": "??????"
                },
                {
                    "village_id": 4,
                    "village_name": "अकैड"
                },
                {
                    "village_id": 5,
                    "village_name": "अमरौरा"
                },
                {
                    "village_id": 6,
                    "village_name": "अरौत"
                },
                {
                    "village_id": 7,
                    "village_name": "उतरा"
                },
                {
                    "village_id": 8,
                    "village_name": "कचरा"
                },
                {
                    "village_id": 9,
                    "village_name": "कछियावा"
                },
                {
                    "village_id": 10,
                    "village_name": "कैथिर"
                },
                {
                    "village_id": 11,
                    "village_name": "कल्याणपुर वाली"
                },
                {
                    "village_id": 12,
                    "village_name": "केवई"
                },
                {
                    "village_id": 13,
                    "village_name": "कोरूत"
                },
                {
                    "village_id": 14,
                    "village_name": "कोरनावा"
                },
                {
                    "village_id": 15,
                    "village_name": "कोरनावा"
                },
                {
                    "village_id": 16,
                    "village_name": "खपुरा"
                },
                {
                    "village_id": 17,
                    "village_name": "गंगाविगहा"
                },
                {
                    "village_id": 18,
                    "village_name": "गंगौर"
                },
                {
                    "village_id": 19,
                    "village_name": "गिलानीचक"
                },
                {
                    "village_id": 20,
                    "village_name": "गोखुलपुर"
                },
                {
                    "village_id": 21,
                    "village_name": "गोनकुरा"
                },
                {
                    "village_id": 22,
                    "village_name": "गौरी"
                },
                {
                    "village_id": 23,
                    "village_name": "चण्डी"
                },
                {
                    "village_id": 24,
                    "village_name": "चान्दपुर"
                },
                {
                    "village_id": 25,
                    "village_name": "जगतपुर"
                },
                {
                    "village_id": 26,
                    "village_name": "जलालपुर"
                },
                {
                    "village_id": 27,
                    "village_name": "टाङापर"
                },
                {
                    "village_id": 28,
                    "village_name": "डिहरा"
                },
                {
                    "village_id": 29,
                    "village_name": "तुलसी"
                },
                {
                    "village_id": 30,
                    "village_name": "दयालपुर"
                },
                {
                    "village_id": 31,
                    "village_name": "दरवेशपुर"
                },
                {
                    "village_id": 32,
                    "village_name": "दरियापुर xCsZk"
                },
                {
                    "village_id": 33,
                    "village_name": "दरियापुर खालसा"
                },
                {
                    "village_id": 34,
                    "village_name": "दामोदरपुर"
                },
                {
                    "village_id": 35,
                    "village_name": "दोलटपुर"
                },
                {
                    "village_id": 36,
                    "village_name": "दौलतपुर"
                },
                {
                    "village_id": 37,
                    "village_name": "नरसंडा"
                },
                {
                    "village_id": 38,
                    "village_name": "नैली"
                },
                {
                    "village_id": 39,
                    "village_name": "निजामपुर"
                },
                {
                    "village_id": 40,
                    "village_name": "पंचाने"
                },
                {
                    "village_id": 41,
                    "village_name": "पड़री"
                },
                {
                    "village_id": 42,
                    "village_name": "पनचाने"
                },
                {
                    "village_id": 43,
                    "village_name": "बदौरा"
                },
                {
                    "village_id": 44,
                    "village_name": "भगवानपुर"
                },
                {
                    "village_id": 45,
                    "village_name": "भेदिया"
                },
                {
                    "village_id": 46,
                    "village_name": "भोभी"
                },
                {
                    "village_id": 47,
                    "village_name": "मतेपुर"
                },
                {
                    "village_id": 48,
                    "village_name": "मनसापुर"
                },
                {
                    "village_id": 49,
                    "village_name": "मंशापुर"
                },
                {
                    "village_id": 50,
                    "village_name": "महकार"
                },
                {
                    "village_id": 51,
                    "village_name": "महम्दपुर"
                },
                {
                    "village_id": 52,
                    "village_name": "महेशपुर"
                },
                {
                    "village_id": 53,
                    "village_name": "माधोपुर"
                },
                {
                    "village_id": 54,
                    "village_name": "माहो"
                },
                {
                    "village_id": 55,
                    "village_name": "मिलकी"
                },
                {
                    "village_id": 56,
                    "village_name": "मोकीमपुर"
                },
                {
                    "village_id": 57,
                    "village_name": "मोबारकपुर"
                },
                {
                    "village_id": 58,
                    "village_name": "मोसिमपुर"
                },
                {
                    "village_id": 59,
                    "village_name": "मोहीउद्दीनपुर"
                },
                {
                    "village_id": 60,
                    "village_name": "रूखाई"
                },
                {
                    "village_id": 61,
                    "village_name": "रैठा"
                },
                {
                    "village_id": 62,
                    "village_name": "रूपसपुर"
                },
                {
                    "village_id": 63,
                    "village_name": "रूपसपुर"
                },
                {
                    "village_id": 64,
                    "village_name": "रसलपुर"
                },
                {
                    "village_id": 65,
                    "village_name": "रैसा"
                },
                {
                    "village_id": 66,
                    "village_name": "रामपुर"
                },
                {
                    "village_id": 67,
                    "village_name": "लोदीपुर पोराजीत"
                },
                {
                    "village_id": 68,
                    "village_name": "वढ़ौना"
                },
                {
                    "village_id": 69,
                    "village_name": "वदरवाली"
                },
                {
                    "village_id": 70,
                    "village_name": "वेलछना"
                },
                {
                    "village_id": 71,
                    "village_name": "वेलछी"
                },
                {
                    "village_id": 72,
                    "village_name": "वहादुरपुर"
                },
                {
                    "village_id": 73,
                    "village_name": "वहादुरपुर"
                },
                {
                    "village_id": 74,
                    "village_name": "वाजितपुर मिलकी"
                },
                {
                    "village_id": 75,
                    "village_name": "वाजितपुर सोनसा"
                },
                {
                    "village_id": 76,
                    "village_name": "विरनावा"
                },
                {
                    "village_id": 77,
                    "village_name": "शेखपुरा"
                },
                {
                    "village_id": 78,
                    "village_name": "श्री रामपुर खरजमा"
                },
                {
                    "village_id": 79,
                    "village_name": "सतनाग"
                },
                {
                    "village_id": 80,
                    "village_name": "सैदनपुर"
                },
                {
                    "village_id": 81,
                    "village_name": "सुमका"
                },
                {
                    "village_id": 82,
                    "village_name": "सरथा"
                },
                {
                    "village_id": 83,
                    "village_name": "सैरा"
                },
                {
                    "village_id": 84,
                    "village_name": "सालेहपुर"
                },
                {
                    "village_id": 85,
                    "village_name": "सिन्दुआरा"
                },
                {
                    "village_id": 86,
                    "village_name": "सिरनावा"
                },
                {
                    "village_id": 87,
                    "village_name": "सौआ"
                },
                {
                    "village_id": 88,
                    "village_name": "हनुमान गढ़"
                },
                {
                    "village_id": 89,
                    "village_name": "हसनी"
                }
            ],
            "block_id": "5",
            "block_no": 11,
            "block_name": "चण्ड़ी [005]",
            "sub_div_code": "2"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अमेरा"
                },
                {
                    "village_id": 3,
                    "village_name": "अस्टा"
                },
                {
                    "village_id": 4,
                    "village_name": "कचहरिया"
                },
                {
                    "village_id": 5,
                    "village_name": "कछियावा"
                },
                {
                    "village_id": 6,
                    "village_name": "करियामा"
                },
                {
                    "village_id": 7,
                    "village_name": "कोयलविगहा"
                },
                {
                    "village_id": 8,
                    "village_name": "खरजमा"
                },
                {
                    "village_id": 9,
                    "village_name": "छरियारी खुर्द"
                },
                {
                    "village_id": 10,
                    "village_name": "जुङी"
                },
                {
                    "village_id": 11,
                    "village_name": "जैतपुर"
                },
                {
                    "village_id": 12,
                    "village_name": "डीहा"
                },
                {
                    "village_id": 13,
                    "village_name": "ढोटुचक"
                },
                {
                    "village_id": 14,
                    "village_name": "थरथरी"
                },
                {
                    "village_id": 15,
                    "village_name": "दुधीचक"
                },
                {
                    "village_id": 16,
                    "village_name": "नटाईचक"
                },
                {
                    "village_id": 17,
                    "village_name": "नरारी"
                },
                {
                    "village_id": 18,
                    "village_name": "नारायणपुर"
                },
                {
                    "village_id": 19,
                    "village_name": "पमारा"
                },
                {
                    "village_id": 20,
                    "village_name": "प्रतापपुर"
                },
                {
                    "village_id": 21,
                    "village_name": "भतहर"
                },
                {
                    "village_id": 22,
                    "village_name": "भतहर"
                },
                {
                    "village_id": 23,
                    "village_name": "मेहतरमा"
                },
                {
                    "village_id": 24,
                    "village_name": "राजावाद"
                },
                {
                    "village_id": 25,
                    "village_name": "लखाचक"
                },
                {
                    "village_id": 26,
                    "village_name": "वङी छरियारी"
                },
                {
                    "village_id": 27,
                    "village_name": "वेरमा"
                },
                {
                    "village_id": 28,
                    "village_name": "वस्ता"
                },
                {
                    "village_id": 29,
                    "village_name": "शेखपुरा"
                },
                {
                    "village_id": 30,
                    "village_name": "सैदवरही"
                },
                {
                    "village_id": 31,
                    "village_name": "सलेमपुर"
                }
            ],
            "block_id": "6",
            "block_no": 12,
            "block_name": "थरथरी [006]",
            "sub_div_code": "2"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अब्बुपुर"
                },
                {
                    "village_id": 3,
                    "village_name": "अमात"
                },
                {
                    "village_id": 4,
                    "village_name": "असिया"
                },
                {
                    "village_id": 5,
                    "village_name": "कन्धौली"
                },
                {
                    "village_id": 6,
                    "village_name": "कर्मथु"
                },
                {
                    "village_id": 7,
                    "village_name": "कराय"
                },
                {
                    "village_id": 8,
                    "village_name": "खनपुरा"
                },
                {
                    "village_id": 9,
                    "village_name": "खालिमचक"
                },
                {
                    "village_id": 10,
                    "village_name": "खोखना"
                },
                {
                    "village_id": 11,
                    "village_name": "गबसपुर"
                },
                {
                    "village_id": 12,
                    "village_name": "गुलङीया विघा"
                },
                {
                    "village_id": 13,
                    "village_name": "गालिमपुर"
                },
                {
                    "village_id": 14,
                    "village_name": "गोन्दु विगहा"
                },
                {
                    "village_id": 15,
                    "village_name": "चकबाजिद पुर"
                },
                {
                    "village_id": 16,
                    "village_name": "चकमोहिउदीनपुर"
                },
                {
                    "village_id": 17,
                    "village_name": "चन्दकुरा"
                },
                {
                    "village_id": 18,
                    "village_name": "चौकी हुड़ारी"
                },
                {
                    "village_id": 19,
                    "village_name": "चौरासी"
                },
                {
                    "village_id": 20,
                    "village_name": "छितरविगहा"
                },
                {
                    "village_id": 21,
                    "village_name": "जलालपुर"
                },
                {
                    "village_id": 22,
                    "village_name": "डियाxfkA"
                },
                {
                    "village_id": 23,
                    "village_name": "तुलसीपुर"
                },
                {
                    "village_id": 24,
                    "village_name": "निरिया"
                },
                {
                    "village_id": 25,
                    "village_name": "पकरी"
                },
                {
                    "village_id": 26,
                    "village_name": "परवनपुर"
                },
                {
                    "village_id": 27,
                    "village_name": "परशुराय"
                },
                {
                    "village_id": 28,
                    "village_name": "फरासपुर"
                },
                {
                    "village_id": 29,
                    "village_name": "बेरथु"
                },
                {
                    "village_id": 30,
                    "village_name": "बेरमा"
                },
                {
                    "village_id": 31,
                    "village_name": "मxrOxfk"
                },
                {
                    "village_id": 32,
                    "village_name": "मकदुमपुर"
                },
                {
                    "village_id": 33,
                    "village_name": "मकरौता"
                },
                {
                    "village_id": 34,
                    "village_name": "मलिकपुर"
                },
                {
                    "village_id": 35,
                    "village_name": "मुसाढ़ी"
                },
                {
                    "village_id": 36,
                    "village_name": "मिल्की xjnbkNxm"
                },
                {
                    "village_id": 37,
                    "village_name": "मोहीउद्दीनपुर"
                },
                {
                    "village_id": 38,
                    "village_name": "रसलपुर"
                },
                {
                    "village_id": 39,
                    "village_name": "विनसा"
                },
                {
                    "village_id": 40,
                    "village_name": "सबचक"
                },
                {
                    "village_id": 41,
                    "village_name": "सलेमपुर"
                },
                {
                    "village_id": 42,
                    "village_name": "सलेमपुर"
                },
                {
                    "village_id": 43,
                    "village_name": "सहबाजपुर"
                },
                {
                    "village_id": 44,
                    "village_name": "साध"
                },
                {
                    "village_id": 45,
                    "village_name": "हथिला"
                }
            ],
            "block_id": "7",
            "block_no": 13,
            "block_name": "करायपरसुराय [007]",
            "sub_div_code": "2"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अकैङ"
                },
                {
                    "village_id": 3,
                    "village_name": "अरियांवा"
                },
                {
                    "village_id": 4,
                    "village_name": "असरफपुर"
                },
                {
                    "village_id": 5,
                    "village_name": "आख्तियारपुर"
                },
                {
                    "village_id": 6,
                    "village_name": "कंगाली विगहा"
                },
                {
                    "village_id": 7,
                    "village_name": "कुङवा"
                },
                {
                    "village_id": 8,
                    "village_name": "कछीयावा"
                },
                {
                    "village_id": 9,
                    "village_name": "कैला"
                },
                {
                    "village_id": 10,
                    "village_name": "केवई"
                },
                {
                    "village_id": 11,
                    "village_name": "कोरारी"
                },
                {
                    "village_id": 12,
                    "village_name": "खजुरा"
                },
                {
                    "village_id": 13,
                    "village_name": "गिलानीचक"
                },
                {
                    "village_id": 14,
                    "village_name": "गोराईपुर"
                },
                {
                    "village_id": 15,
                    "village_name": "चिरैयादयाल"
                },
                {
                    "village_id": 16,
                    "village_name": "चिस्तीपुर"
                },
                {
                    "village_id": 17,
                    "village_name": "जलालपुर"
                },
                {
                    "village_id": 18,
                    "village_name": "दामोदरपुर"
                },
                {
                    "village_id": 19,
                    "village_name": "धोरहा"
                },
                {
                    "village_id": 20,
                    "village_name": "नगरनौसा"
                },
                {
                    "village_id": 21,
                    "village_name": "नगवा"
                },
                {
                    "village_id": 22,
                    "village_name": "प्रसडीहा"
                },
                {
                    "village_id": 23,
                    "village_name": "फजिलापुर"
                },
                {
                    "village_id": 24,
                    "village_name": "भुतहा खार"
                },
                {
                    "village_id": 25,
                    "village_name": "भदरू"
                },
                {
                    "village_id": 26,
                    "village_name": "भोभी"
                },
                {
                    "village_id": 27,
                    "village_name": "मुनियामपुर"
                },
                {
                    "village_id": 28,
                    "village_name": "मल विगहा"
                },
                {
                    "village_id": 29,
                    "village_name": "मुशाहरी"
                },
                {
                    "village_id": 30,
                    "village_name": "मुस्तफापुर"
                },
                {
                    "village_id": 31,
                    "village_name": "मुस्तफापुर xUfZk"
                },
                {
                    "village_id": 32,
                    "village_name": "महानंदपुर"
                },
                {
                    "village_id": 33,
                    "village_name": "मानसिगपुर xQmUk"
                },
                {
                    "village_id": 34,
                    "village_name": "मोहीउद्दीनपुर"
                },
                {
                    "village_id": 35,
                    "village_name": "रामपुर"
                },
                {
                    "village_id": 36,
                    "village_name": "रामपुर टीनी"
                },
                {
                    "village_id": 37,
                    "village_name": "लोदीपुर उसमानपुर"
                },
                {
                    "village_id": 38,
                    "village_name": "लोदीपुर तीना"
                },
                {
                    "village_id": 39,
                    "village_name": "वडीहा"
                },
                {
                    "village_id": 40,
                    "village_name": "वलवा"
                },
                {
                    "village_id": 41,
                    "village_name": "वाजिदपुर"
                },
                {
                    "village_id": 42,
                    "village_name": "विसुनपुर"
                },
                {
                    "village_id": 43,
                    "village_name": "शाहपुर"
                },
                {
                    "village_id": 44,
                    "village_name": "सैदनपुर सकरौढ़ा"
                },
                {
                    "village_id": 45,
                    "village_name": "सैदपुर"
                },
                {
                    "village_id": 46,
                    "village_name": "सैरा"
                },
                {
                    "village_id": 47,
                    "village_name": "सलेमपुर"
                },
                {
                    "village_id": 48,
                    "village_name": "हेयाटपुर"
                }
            ],
            "block_id": "8",
            "block_no": 14,
            "block_name": "नगरनौसा [008]",
            "sub_div_code": "2"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अण्डवस"
                },
                {
                    "village_id": 3,
                    "village_name": "उजरपुर"
                },
                {
                    "village_id": 4,
                    "village_name": "कङाह"
                },
                {
                    "village_id": 5,
                    "village_name": "कटारी"
                },
                {
                    "village_id": 6,
                    "village_name": "कतलपुरा"
                },
                {
                    "village_id": 7,
                    "village_name": "करीमपुर"
                },
                {
                    "village_id": 8,
                    "village_name": "कहटा"
                },
                {
                    "village_id": 9,
                    "village_name": "खैरा"
                },
                {
                    "village_id": 10,
                    "village_name": "खैराचमन"
                },
                {
                    "village_id": 11,
                    "village_name": "गोपडीहा"
                },
                {
                    "village_id": 12,
                    "village_name": "गोरौर"
                },
                {
                    "village_id": 13,
                    "village_name": "चकभुई"
                },
                {
                    "village_id": 14,
                    "village_name": "चकरसुल"
                },
                {
                    "village_id": 15,
                    "village_name": "चन्दौरा"
                },
                {
                    "village_id": 16,
                    "village_name": "छबिलापुर"
                },
                {
                    "village_id": 17,
                    "village_name": "जलालपुर खरजम्मा"
                },
                {
                    "village_id": 18,
                    "village_name": "झालर"
                },
                {
                    "village_id": 19,
                    "village_name": "ठेरा"
                },
                {
                    "village_id": 20,
                    "village_name": "तेतरीया"
                },
                {
                    "village_id": 21,
                    "village_name": "दरियापुर"
                },
                {
                    "village_id": 22,
                    "village_name": "दोंगी"
                },
                {
                    "village_id": 23,
                    "village_name": "धरमपुर"
                },
                {
                    "village_id": 24,
                    "village_name": "नेकपुर"
                },
                {
                    "village_id": 25,
                    "village_name": "नागडीह"
                },
                {
                    "village_id": 26,
                    "village_name": "नाहुब"
                },
                {
                    "village_id": 27,
                    "village_name": "नीमा"
                },
                {
                    "village_id": 28,
                    "village_name": "नोनही"
                },
                {
                    "village_id": 29,
                    "village_name": "पंडितपुर"
                },
                {
                    "village_id": 30,
                    "village_name": "पथरौरा"
                },
                {
                    "village_id": 31,
                    "village_name": "पिलखी"
                },
                {
                    "village_id": 32,
                    "village_name": "बड़हरी"
                },
                {
                    "village_id": 33,
                    "village_name": "बढ़ौना"
                },
                {
                    "village_id": 34,
                    "village_name": "बलौर"
                },
                {
                    "village_id": 35,
                    "village_name": "बहेङा"
                },
                {
                    "village_id": 36,
                    "village_name": "बिरचैत"
                },
                {
                    "village_id": 37,
                    "village_name": "भूई"
                },
                {
                    "village_id": 38,
                    "village_name": "मजैठा"
                },
                {
                    "village_id": 39,
                    "village_name": "मटिहानी"
                },
                {
                    "village_id": 40,
                    "village_name": "मेयार"
                },
                {
                    "village_id": 41,
                    "village_name": "महुअल्ला"
                },
                {
                    "village_id": 42,
                    "village_name": "महादेवपुर"
                },
                {
                    "village_id": 43,
                    "village_name": "मालीसांढ़"
                },
                {
                    "village_id": 44,
                    "village_name": "मियाविगहा"
                },
                {
                    "village_id": 45,
                    "village_name": "मिल्की"
                },
                {
                    "village_id": 46,
                    "village_name": "मोरा"
                },
                {
                    "village_id": 47,
                    "village_name": "मौलनाडीह"
                },
                {
                    "village_id": 48,
                    "village_name": "रटना"
                },
                {
                    "village_id": 49,
                    "village_name": "रसलपुर"
                },
                {
                    "village_id": 50,
                    "village_name": "राजगीर"
                },
                {
                    "village_id": 51,
                    "village_name": "लहुआर"
                },
                {
                    "village_id": 52,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 53,
                    "village_name": "वनौली"
                },
                {
                    "village_id": 54,
                    "village_name": "वरनौसा"
                },
                {
                    "village_id": 55,
                    "village_name": "वराकर"
                },
                {
                    "village_id": 56,
                    "village_name": "वेलौवा"
                },
                {
                    "village_id": 57,
                    "village_name": "वहुएन"
                },
                {
                    "village_id": 58,
                    "village_name": "शेखफुल"
                },
                {
                    "village_id": 59,
                    "village_name": "साहपुर"
                },
                {
                    "village_id": 60,
                    "village_name": "सिथौरा"
                },
                {
                    "village_id": 61,
                    "village_name": "सीमा"
                },
                {
                    "village_id": 62,
                    "village_name": "सोनरविगहा"
                },
                {
                    "village_id": 63,
                    "village_name": "सौराई"
                },
                {
                    "village_id": 64,
                    "village_name": "हसनपुर"
                },
                {
                    "village_id": 65,
                    "village_name": "हंसराजपुर"
                }
            ],
            "block_id": "1",
            "block_no": 15,
            "block_name": "राजगीर [001]",
            "sub_div_code": "3"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "कमलविगहा"
                },
                {
                    "village_id": 3,
                    "village_name": "करियन्ना"
                },
                {
                    "village_id": 4,
                    "village_name": "कुल"
                },
                {
                    "village_id": 5,
                    "village_name": "खोजागाछी"
                },
                {
                    "village_id": 6,
                    "village_name": "खोन्दुपुर"
                },
                {
                    "village_id": 7,
                    "village_name": "गन्धुपुर"
                },
                {
                    "village_id": 8,
                    "village_name": "गोरमा"
                },
                {
                    "village_id": 9,
                    "village_name": "गोरविगहा"
                },
                {
                    "village_id": 10,
                    "village_name": "गोरावां"
                },
                {
                    "village_id": 11,
                    "village_name": "गोविन्दपुर"
                },
                {
                    "village_id": 12,
                    "village_name": "घोस्तावां"
                },
                {
                    "village_id": 13,
                    "village_name": "चण्डी मो."
                },
                {
                    "village_id": 14,
                    "village_name": "चमरडीहा"
                },
                {
                    "village_id": 15,
                    "village_name": "जलालपुर"
                },
                {
                    "village_id": 16,
                    "village_name": "डुमरी"
                },
                {
                    "village_id": 17,
                    "village_name": "ताजुविगहा"
                },
                {
                    "village_id": 18,
                    "village_name": "दरिया सराय"
                },
                {
                    "village_id": 19,
                    "village_name": "धनछुही"
                },
                {
                    "village_id": 20,
                    "village_name": "धरहरा"
                },
                {
                    "village_id": 21,
                    "village_name": "नेपुरा"
                },
                {
                    "village_id": 22,
                    "village_name": "नरहरविगहा"
                },
                {
                    "village_id": 23,
                    "village_name": "नानन्द"
                },
                {
                    "village_id": 24,
                    "village_name": "नीरपुर"
                },
                {
                    "village_id": 25,
                    "village_name": "पंचवारा"
                },
                {
                    "village_id": 26,
                    "village_name": "फतेहपुर"
                },
                {
                    "village_id": 27,
                    "village_name": "बाजीतपुर"
                },
                {
                    "village_id": 28,
                    "village_name": "बिण्डीडीह"
                },
                {
                    "village_id": 29,
                    "village_name": "भगवानपुर"
                },
                {
                    "village_id": 30,
                    "village_name": "भटविगहा"
                },
                {
                    "village_id": 31,
                    "village_name": "भदारी"
                },
                {
                    "village_id": 32,
                    "village_name": "मनियावां"
                },
                {
                    "village_id": 33,
                    "village_name": "माधोपुर"
                },
                {
                    "village_id": 34,
                    "village_name": "मालिकपुर"
                },
                {
                    "village_id": 35,
                    "village_name": "माहुरी"
                },
                {
                    "village_id": 36,
                    "village_name": "मितवां"
                },
                {
                    "village_id": 37,
                    "village_name": "मोहनपुर"
                },
                {
                    "village_id": 38,
                    "village_name": "लक्ष्मीपुर"
                },
                {
                    "village_id": 39,
                    "village_name": "लड्डु"
                },
                {
                    "village_id": 40,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 41,
                    "village_name": "वङगाँव"
                },
                {
                    "village_id": 42,
                    "village_name": "समसेरा"
                },
                {
                    "village_id": 43,
                    "village_name": "सुरजपुर"
                },
                {
                    "village_id": 44,
                    "village_name": "सवैत"
                },
                {
                    "village_id": 45,
                    "village_name": "सिलाव"
                }
            ],
            "block_id": "2",
            "block_no": 16,
            "block_name": "सिलाव [002]",
            "sub_div_code": "3"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अकौना"
                },
                {
                    "village_id": 3,
                    "village_name": "अमियाँ विगहा"
                },
                {
                    "village_id": 4,
                    "village_name": "अरावां"
                },
                {
                    "village_id": 5,
                    "village_name": "आँट"
                },
                {
                    "village_id": 6,
                    "village_name": "एकसारा"
                },
                {
                    "village_id": 7,
                    "village_name": "कुतलुपुर"
                },
                {
                    "village_id": 8,
                    "village_name": "करजारा"
                },
                {
                    "village_id": 9,
                    "village_name": "कोल्हुआ"
                },
                {
                    "village_id": 10,
                    "village_name": "कोसनारा"
                },
                {
                    "village_id": 11,
                    "village_name": "खैरा"
                },
                {
                    "village_id": 12,
                    "village_name": "गंगटी"
                },
                {
                    "village_id": 13,
                    "village_name": "जन्धारो"
                },
                {
                    "village_id": 14,
                    "village_name": "जनारो"
                },
                {
                    "village_id": 15,
                    "village_name": "जफरा"
                },
                {
                    "village_id": 16,
                    "village_name": "धरहरा"
                },
                {
                    "village_id": 17,
                    "village_name": "नोहसा"
                },
                {
                    "village_id": 18,
                    "village_name": "मखदुमपुर"
                },
                {
                    "village_id": 19,
                    "village_name": "मैजरा"
                },
                {
                    "village_id": 20,
                    "village_name": "मधार"
                },
                {
                    "village_id": 21,
                    "village_name": "मुरगाँवा"
                },
                {
                    "village_id": 22,
                    "village_name": "म़रसुआ"
                },
                {
                    "village_id": 23,
                    "village_name": "महमदपुर"
                },
                {
                    "village_id": 24,
                    "village_name": "महेशपुर"
                },
                {
                    "village_id": 25,
                    "village_name": "माड़ी"
                },
                {
                    "village_id": 26,
                    "village_name": "रामगंज"
                },
                {
                    "village_id": 27,
                    "village_name": "वेन"
                },
                {
                    "village_id": 28,
                    "village_name": "वभनियावां"
                },
                {
                    "village_id": 29,
                    "village_name": "वारा"
                },
                {
                    "village_id": 30,
                    "village_name": "विरवल विगहा"
                },
                {
                    "village_id": 31,
                    "village_name": "शहरी"
                },
                {
                    "village_id": 32,
                    "village_name": "सिद्दीविगहा"
                },
                {
                    "village_id": 33,
                    "village_name": "सौरे"
                }
            ],
            "block_id": "3",
            "block_no": 17,
            "block_name": "वेन [003]",
            "sub_div_code": "3"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "कटौना"
                },
                {
                    "village_id": 3,
                    "village_name": "कतरडीह"
                },
                {
                    "village_id": 4,
                    "village_name": "कतरपुर"
                },
                {
                    "village_id": 5,
                    "village_name": "कतरी"
                },
                {
                    "village_id": 6,
                    "village_name": "कोयरीxXlGk"
                },
                {
                    "village_id": 7,
                    "village_name": "दरवेशपुरा"
                },
                {
                    "village_id": 8,
                    "village_name": "पटोरिया"
                },
                {
                    "village_id": 9,
                    "village_name": "पलटपुर"
                },
                {
                    "village_id": 10,
                    "village_name": "मैरावरीठ"
                },
                {
                    "village_id": 11,
                    "village_name": "रसलपुर"
                },
                {
                    "village_id": 12,
                    "village_name": "वादी"
                },
                {
                    "village_id": 13,
                    "village_name": "विलारी"
                },
                {
                    "village_id": 14,
                    "village_name": "सैदपुर"
                },
                {
                    "village_id": 15,
                    "village_name": "सैदी"
                }
            ],
            "block_id": "4",
            "block_no": 18,
            "block_name": "कतरीसराय [004]",
            "sub_div_code": "3"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अमदाहा"
                },
                {
                    "village_id": 3,
                    "village_name": "आदमपुर"
                },
                {
                    "village_id": 4,
                    "village_name": "ईशुआ"
                },
                {
                    "village_id": 5,
                    "village_name": "कंदोपुर"
                },
                {
                    "village_id": 6,
                    "village_name": "केरुआ"
                },
                {
                    "village_id": 7,
                    "village_name": "कासोचक"
                },
                {
                    "village_id": 8,
                    "village_name": "खानपुरा"
                },
                {
                    "village_id": 9,
                    "village_name": "गाजीपुर"
                },
                {
                    "village_id": 10,
                    "village_name": "गिरियक"
                },
                {
                    "village_id": 11,
                    "village_name": "घोड़ा-कटोरा"
                },
                {
                    "village_id": 12,
                    "village_name": "घोराही"
                },
                {
                    "village_id": 13,
                    "village_name": "घोंसरावां"
                },
                {
                    "village_id": 14,
                    "village_name": "चोरसुआ"
                },
                {
                    "village_id": 15,
                    "village_name": "जलालपुर"
                },
                {
                    "village_id": 16,
                    "village_name": "डाक्टर ईंगलिश"
                },
                {
                    "village_id": 17,
                    "village_name": "तख्तरोजा"
                },
                {
                    "village_id": 18,
                    "village_name": "तरोखर"
                },
                {
                    "village_id": 19,
                    "village_name": "दुर्गापुर"
                },
                {
                    "village_id": 20,
                    "village_name": "दशरथपुर"
                },
                {
                    "village_id": 21,
                    "village_name": "धोबी विगहा"
                },
                {
                    "village_id": 22,
                    "village_name": "नशीरपुर"
                },
                {
                    "village_id": 23,
                    "village_name": "प्यारेपुर"
                },
                {
                    "village_id": 24,
                    "village_name": "प्यारेपुर xCrbkJm"
                },
                {
                    "village_id": 25,
                    "village_name": "पुरैनी"
                },
                {
                    "village_id": 26,
                    "village_name": "पुरी"
                },
                {
                    "village_id": 27,
                    "village_name": "पहलौवा"
                },
                {
                    "village_id": 28,
                    "village_name": "पोखरपुर"
                },
                {
                    "village_id": 29,
                    "village_name": "बकरा"
                },
                {
                    "village_id": 30,
                    "village_name": "मदतपुरा"
                },
                {
                    "village_id": 31,
                    "village_name": "मरकट्टा"
                },
                {
                    "village_id": 32,
                    "village_name": "महम्मदपुर"
                },
                {
                    "village_id": 33,
                    "village_name": "महिला"
                },
                {
                    "village_id": 34,
                    "village_name": "मानपुर"
                },
                {
                    "village_id": 35,
                    "village_name": "रैत्तर"
                },
                {
                    "village_id": 36,
                    "village_name": "रुपसपुर"
                },
                {
                    "village_id": 37,
                    "village_name": "लखाचक"
                },
                {
                    "village_id": 38,
                    "village_name": "वढ़ैता"
                },
                {
                    "village_id": 39,
                    "village_name": "सकुचीडीह"
                },
                {
                    "village_id": 40,
                    "village_name": "सतौआ"
                },
                {
                    "village_id": 41,
                    "village_name": "सैदपुर"
                },
                {
                    "village_id": 42,
                    "village_name": "साईंडीह"
                },
                {
                    "village_id": 43,
                    "village_name": "सिकन्दरा"
                },
                {
                    "village_id": 44,
                    "village_name": "हैवतपुर"
                },
                {
                    "village_id": 45,
                    "village_name": "हसनपुर"
                }
            ],
            "block_id": "5",
            "block_no": 19,
            "block_name": "गिरियक [005]",
            "sub_div_code": "3"
        }
    ],
    "district_id": "01",
    "district_name": "नालन्दा"
},
{
  "block": [
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अंजनी"
                },
                {
                    "village_id": 3,
                    "village_name": "अंजनीचक"
                },
                {
                    "village_id": 4,
                    "village_name": "अदलुचक"
                },
                {
                    "village_id": 5,
                    "village_name": "अमैन"
                },
                {
                    "village_id": 6,
                    "village_name": "अरसठ"
                },
                {
                    "village_id": 7,
                    "village_name": "आदमपुर"
                },
                {
                    "village_id": 8,
                    "village_name": "आलमपुर"
                },
                {
                    "village_id": 9,
                    "village_name": "इमादपुर"
                },
                {
                    "village_id": 10,
                    "village_name": "इरकी एराजी"
                },
                {
                    "village_id": 11,
                    "village_name": "इसमाईलपुर"
                },
                {
                    "village_id": 12,
                    "village_name": "ईरकी"
                },
                {
                    "village_id": 13,
                    "village_name": "ककडीया"
                },
                {
                    "village_id": 14,
                    "village_name": "कडौना"
                },
                {
                    "village_id": 15,
                    "village_name": "केन्‍दुआ"
                },
                {
                    "village_id": 16,
                    "village_name": "कनौदी"
                },
                {
                    "village_id": 17,
                    "village_name": "कनौदी"
                },
                {
                    "village_id": 18,
                    "village_name": "कुम्‍हवा"
                },
                {
                    "village_id": 19,
                    "village_name": "करौता"
                },
                {
                    "village_id": 20,
                    "village_name": "कल्‍पा"
                },
                {
                    "village_id": 21,
                    "village_name": "कसई"
                },
                {
                    "village_id": 22,
                    "village_name": "कालुपुर"
                },
                {
                    "village_id": 23,
                    "village_name": "कालुपुर"
                },
                {
                    "village_id": 24,
                    "village_name": "किनारी"
                },
                {
                    "village_id": 25,
                    "village_name": "कोरमा"
                },
                {
                    "village_id": 26,
                    "village_name": "कोसडिहरा"
                },
                {
                    "village_id": 27,
                    "village_name": "खैरा"
                },
                {
                    "village_id": 28,
                    "village_name": "खरोज"
                },
                {
                    "village_id": 29,
                    "village_name": "खिदरपुरा"
                },
                {
                    "village_id": 30,
                    "village_name": "गुहापाकड"
                },
                {
                    "village_id": 31,
                    "village_name": "गोडिहा"
                },
                {
                    "village_id": 32,
                    "village_name": "गोनवा"
                },
                {
                    "village_id": 33,
                    "village_name": "गोनसा"
                },
                {
                    "village_id": 34,
                    "village_name": "गोपालपुर"
                },
                {
                    "village_id": 35,
                    "village_name": "गौरापुर"
                },
                {
                    "village_id": 36,
                    "village_name": "घोरहट"
                },
                {
                    "village_id": 37,
                    "village_name": "घोषी"
                },
                {
                    "village_id": 38,
                    "village_name": "घोषी खुर्द"
                },
                {
                    "village_id": 39,
                    "village_name": "चैनपुरा"
                },
                {
                    "village_id": 40,
                    "village_name": "चन्‍हरीया"
                },
                {
                    "village_id": 41,
                    "village_name": "चिरईयॉंटॉंड"
                },
                {
                    "village_id": 42,
                    "village_name": "चिलंगीयाचक"
                },
                {
                    "village_id": 43,
                    "village_name": "चौवन"
                },
                {
                    "village_id": 44,
                    "village_name": "जमुआवॉ"
                },
                {
                    "village_id": 45,
                    "village_name": "जमालपुर धुरीया"
                },
                {
                    "village_id": 46,
                    "village_name": "जहानाबाद"
                },
                {
                    "village_id": 47,
                    "village_name": "जामुक"
                },
                {
                    "village_id": 48,
                    "village_name": "टाली"
                },
                {
                    "village_id": 49,
                    "village_name": "डोहीया"
                },
                {
                    "village_id": 50,
                    "village_name": "तुरकौल"
                },
                {
                    "village_id": 51,
                    "village_name": "दरोगाचक"
                },
                {
                    "village_id": 52,
                    "village_name": "देवसी खन्‍धा"
                },
                {
                    "village_id": 53,
                    "village_name": "दाउदपुर"
                },
                {
                    "village_id": 54,
                    "village_name": "दौलतपुर"
                },
                {
                    "village_id": 55,
                    "village_name": "दौलतपुर"
                },
                {
                    "village_id": 56,
                    "village_name": "धनगावॉं"
                },
                {
                    "village_id": 57,
                    "village_name": "धन्‍धरविगहा"
                },
                {
                    "village_id": 58,
                    "village_name": "धनौती"
                },
                {
                    "village_id": 59,
                    "village_name": "धुरीया"
                },
                {
                    "village_id": 60,
                    "village_name": "नसरत चक"
                },
                {
                    "village_id": 61,
                    "village_name": "निजाई"
                },
                {
                    "village_id": 62,
                    "village_name": "निजामुदीनपुर"
                },
                {
                    "village_id": 63,
                    "village_name": "नौरू"
                },
                {
                    "village_id": 64,
                    "village_name": "पकरी"
                },
                {
                    "village_id": 65,
                    "village_name": "पचासाचक"
                },
                {
                    "village_id": 66,
                    "village_name": "पचासाचक"
                },
                {
                    "village_id": 67,
                    "village_name": "पंडुई"
                },
                {
                    "village_id": 68,
                    "village_name": "पतरीया"
                },
                {
                    "village_id": 69,
                    "village_name": "परशुरामपुर"
                },
                {
                    "village_id": 70,
                    "village_name": "परसविगहा"
                },
                {
                    "village_id": 71,
                    "village_name": "पशुरामचक"
                },
                {
                    "village_id": 72,
                    "village_name": "पिंजौर"
                },
                {
                    "village_id": 73,
                    "village_name": "फरीदपुर"
                },
                {
                    "village_id": 74,
                    "village_name": "फरीदपुर"
                },
                {
                    "village_id": 75,
                    "village_name": "बदहर"
                },
                {
                    "village_id": 76,
                    "village_name": "बुधनटाली"
                },
                {
                    "village_id": 77,
                    "village_name": "बभना"
                },
                {
                    "village_id": 78,
                    "village_name": "बभना कटैया"
                },
                {
                    "village_id": 79,
                    "village_name": "बाजीतपुर 7"
                },
                {
                    "village_id": 80,
                    "village_name": "बिस्‍टौल"
                },
                {
                    "village_id": 81,
                    "village_name": "भेवड."
                },
                {
                    "village_id": 82,
                    "village_name": "मखदुमपुर"
                },
                {
                    "village_id": 83,
                    "village_name": "मखदुमपुर उॅंटा"
                },
                {
                    "village_id": 84,
                    "village_name": "मेघडीया"
                },
                {
                    "village_id": 85,
                    "village_name": "मुठेर"
                },
                {
                    "village_id": 86,
                    "village_name": "मडु.आ"
                },
                {
                    "village_id": 87,
                    "village_name": "मेदनीचक"
                },
                {
                    "village_id": 88,
                    "village_name": "मदारपुर उॅंटा"
                },
                {
                    "village_id": 89,
                    "village_name": "मुस्‍तीचक"
                },
                {
                    "village_id": 90,
                    "village_name": "मुसेपुर"
                },
                {
                    "village_id": 91,
                    "village_name": "महमदा"
                },
                {
                    "village_id": 92,
                    "village_name": "मांदिल"
                },
                {
                    "village_id": 93,
                    "village_name": "मान्‍देविगहा"
                },
                {
                    "village_id": 94,
                    "village_name": "मिनजुमला"
                },
                {
                    "village_id": 95,
                    "village_name": "मिल्‍की"
                },
                {
                    "village_id": 96,
                    "village_name": "मीरचक"
                },
                {
                    "village_id": 97,
                    "village_name": "मोकर"
                },
                {
                    "village_id": 98,
                    "village_name": "मोहनपुर"
                },
                {
                    "village_id": 99,
                    "village_name": "रूपसपुर"
                },
                {
                    "village_id": 100,
                    "village_name": "रसलपुर भिठिया"
                },
                {
                    "village_id": 101,
                    "village_name": "रसीदपुर"
                },
                {
                    "village_id": 102,
                    "village_name": "लुतफुल्‍लाहचक"
                },
                {
                    "village_id": 103,
                    "village_name": "लरसा"
                },
                {
                    "village_id": 104,
                    "village_name": "लिलाम"
                },
                {
                    "village_id": 105,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 106,
                    "village_name": "वेणीचक"
                },
                {
                    "village_id": 107,
                    "village_name": "वलवा"
                },
                {
                    "village_id": 108,
                    "village_name": "वलवा"
                },
                {
                    "village_id": 109,
                    "village_name": "वसंतपुर"
                },
                {
                    "village_id": 110,
                    "village_name": "वाजीतपुर 4"
                },
                {
                    "village_id": 111,
                    "village_name": "वाजीदपुर 8"
                },
                {
                    "village_id": 112,
                    "village_name": "शाहपुर"
                },
                {
                    "village_id": 113,
                    "village_name": "सतासीचक"
                },
                {
                    "village_id": 114,
                    "village_name": "सुरंगापुर-भवानीचक"
                },
                {
                    "village_id": 115,
                    "village_name": "सुलतानी"
                },
                {
                    "village_id": 116,
                    "village_name": "सलेमपुर"
                },
                {
                    "village_id": 117,
                    "village_name": "सलेमपुर"
                },
                {
                    "village_id": 118,
                    "village_name": "सलेमापुर"
                },
                {
                    "village_id": 119,
                    "village_name": "सलारपुर"
                },
                {
                    "village_id": 120,
                    "village_name": "सेवनन"
                },
                {
                    "village_id": 121,
                    "village_name": "सहवाजपुर"
                },
                {
                    "village_id": 122,
                    "village_name": "सिकरिया"
                },
                {
                    "village_id": 123,
                    "village_name": "सिकरीया"
                },
                {
                    "village_id": 124,
                    "village_name": "सोहे"
                },
                {
                    "village_id": 125,
                    "village_name": "सोहेचक"
                },
                {
                    "village_id": 126,
                    "village_name": "हमीदचक"
                },
                {
                    "village_id": 127,
                    "village_name": "हसौडा"
                }
            ],
            "block_id": "1",
            "block_no": 0,
            "block_name": "जहानाबाद [001]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अफजलपुर"
                },
                {
                    "village_id": 3,
                    "village_name": "अमरथुआ"
                },
                {
                    "village_id": 4,
                    "village_name": "अमरपुर पाली"
                },
                {
                    "village_id": 5,
                    "village_name": "अलगना"
                },
                {
                    "village_id": 6,
                    "village_name": "असियावॉं"
                },
                {
                    "village_id": 7,
                    "village_name": "इब्राहीमपुर"
                },
                {
                    "village_id": 8,
                    "village_name": "इमलिया"
                },
                {
                    "village_id": 9,
                    "village_name": "उत्‍तर सेरथु"
                },
                {
                    "village_id": 10,
                    "village_name": "उसरी"
                },
                {
                    "village_id": 11,
                    "village_name": "ऐनवॉं"
                },
                {
                    "village_id": 12,
                    "village_name": "औलियाचक"
                },
                {
                    "village_id": 13,
                    "village_name": "कुमरडीहा"
                },
                {
                    "village_id": 14,
                    "village_name": "काको"
                },
                {
                    "village_id": 15,
                    "village_name": "काजीसराय"
                },
                {
                    "village_id": 16,
                    "village_name": "काजीसराय मिल्‍की"
                },
                {
                    "village_id": 17,
                    "village_name": "किसुनपुर"
                },
                {
                    "village_id": 18,
                    "village_name": "कोशियावॉ"
                },
                {
                    "village_id": 19,
                    "village_name": "कोशियावॉ मिल्‍की"
                },
                {
                    "village_id": 20,
                    "village_name": "कोसीयावॉं"
                },
                {
                    "village_id": 21,
                    "village_name": "कोसीयावॉं"
                },
                {
                    "village_id": 22,
                    "village_name": "खपुरा"
                },
                {
                    "village_id": 23,
                    "village_name": "खलीसपुर"
                },
                {
                    "village_id": 24,
                    "village_name": "गोपालपुर"
                },
                {
                    "village_id": 25,
                    "village_name": "गोलकपुर"
                },
                {
                    "village_id": 26,
                    "village_name": "घटकन"
                },
                {
                    "village_id": 27,
                    "village_name": "चंदौरा"
                },
                {
                    "village_id": 28,
                    "village_name": "चातर"
                },
                {
                    "village_id": 29,
                    "village_name": "छोटकी कनौली"
                },
                {
                    "village_id": 30,
                    "village_name": "जमालपुर"
                },
                {
                    "village_id": 31,
                    "village_name": "जलालपुर"
                },
                {
                    "village_id": 32,
                    "village_name": "डेढ.सैया"
                },
                {
                    "village_id": 33,
                    "village_name": "तेतरीया"
                },
                {
                    "village_id": 34,
                    "village_name": "दक्षिणी"
                },
                {
                    "village_id": 35,
                    "village_name": "दमुहा"
                },
                {
                    "village_id": 36,
                    "village_name": "देवधरा"
                },
                {
                    "village_id": 37,
                    "village_name": "धर्मपुर"
                },
                {
                    "village_id": 38,
                    "village_name": "नगमा"
                },
                {
                    "village_id": 39,
                    "village_name": "नगमा"
                },
                {
                    "village_id": 40,
                    "village_name": "नगमा एराजी"
                },
                {
                    "village_id": 41,
                    "village_name": "नगमा सिकरी"
                },
                {
                    "village_id": 42,
                    "village_name": "नगवॉं"
                },
                {
                    "village_id": 43,
                    "village_name": "नदीयावॉं"
                },
                {
                    "village_id": 44,
                    "village_name": "नेयाजीपुर"
                },
                {
                    "village_id": 45,
                    "village_name": "नेरथुआ"
                },
                {
                    "village_id": 46,
                    "village_name": "नरवां मिल्‍की"
                },
                {
                    "village_id": 47,
                    "village_name": "नारायणपुर"
                },
                {
                    "village_id": 48,
                    "village_name": "नारायणपुर मुरारी"
                },
                {
                    "village_id": 49,
                    "village_name": "निगार पाली"
                },
                {
                    "village_id": 50,
                    "village_name": "नोनही"
                },
                {
                    "village_id": 51,
                    "village_name": "पखनपुरा"
                },
                {
                    "village_id": 52,
                    "village_name": "पिंजौरा"
                },
                {
                    "village_id": 53,
                    "village_name": "फिरोजी"
                },
                {
                    "village_id": 54,
                    "village_name": "बढौना"
                },
                {
                    "village_id": 55,
                    "village_name": "बदहारा"
                },
                {
                    "village_id": 56,
                    "village_name": "बेम्‍मई"
                },
                {
                    "village_id": 57,
                    "village_name": "बसंतपुर हड.हड."
                },
                {
                    "village_id": 58,
                    "village_name": "बारा"
                },
                {
                    "village_id": 59,
                    "village_name": "बीबीपुर"
                },
                {
                    "village_id": 60,
                    "village_name": "भदसेरी"
                },
                {
                    "village_id": 61,
                    "village_name": "भदसारा"
                },
                {
                    "village_id": 62,
                    "village_name": "भरथुआ"
                },
                {
                    "village_id": 63,
                    "village_name": "भेलावर"
                },
                {
                    "village_id": 64,
                    "village_name": "भीखमपुर"
                },
                {
                    "village_id": 65,
                    "village_name": "मई"
                },
                {
                    "village_id": 66,
                    "village_name": "मखदुमपुर"
                },
                {
                    "village_id": 67,
                    "village_name": "मखदुमपुर पाली"
                },
                {
                    "village_id": 68,
                    "village_name": "मदारपुर"
                },
                {
                    "village_id": 69,
                    "village_name": "मनियारी"
                },
                {
                    "village_id": 70,
                    "village_name": "मनीयामा"
                },
                {
                    "village_id": 71,
                    "village_name": "मुरासा"
                },
                {
                    "village_id": 72,
                    "village_name": "मसुरचक"
                },
                {
                    "village_id": 73,
                    "village_name": "मेंहदीचक"
                },
                {
                    "village_id": 74,
                    "village_name": "महमदपुर"
                },
                {
                    "village_id": 75,
                    "village_name": "महमुदपुर"
                },
                {
                    "village_id": 76,
                    "village_name": "मिश्रविगहा"
                },
                {
                    "village_id": 77,
                    "village_name": "रसलपुर"
                },
                {
                    "village_id": 78,
                    "village_name": "रसुला"
                },
                {
                    "village_id": 79,
                    "village_name": "रहिमाचक"
                },
                {
                    "village_id": 80,
                    "village_name": "रानीपुर"
                },
                {
                    "village_id": 81,
                    "village_name": "रामदानी"
                },
                {
                    "village_id": 82,
                    "village_name": "रामपुरवारा"
                },
                {
                    "village_id": 83,
                    "village_name": "रायपुरा"
                },
                {
                    "village_id": 84,
                    "village_name": "लालाचक"
                },
                {
                    "village_id": 85,
                    "village_name": "लिलिया बिगहा"
                },
                {
                    "village_id": 86,
                    "village_name": "लोचनाचक"
                },
                {
                    "village_id": 87,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 88,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 89,
                    "village_name": "लोदीपुर लॉंजो"
                },
                {
                    "village_id": 90,
                    "village_name": "वरावॉं"
                },
                {
                    "village_id": 91,
                    "village_name": "वारा"
                },
                {
                    "village_id": 92,
                    "village_name": "विशुनपुर हड.हड."
                },
                {
                    "village_id": 93,
                    "village_name": "सैदपुर"
                },
                {
                    "village_id": 94,
                    "village_name": "सैदावाद"
                },
                {
                    "village_id": 95,
                    "village_name": "सलेमपुर लॉंजो"
                },
                {
                    "village_id": 96,
                    "village_name": "सुलेमानपुर"
                },
                {
                    "village_id": 97,
                    "village_name": "साह सलेमपुर"
                },
                {
                    "village_id": 98,
                    "village_name": "सिकरीया"
                },
                {
                    "village_id": 99,
                    "village_name": "सिंहटी"
                },
                {
                    "village_id": 100,
                    "village_name": "हबीबपुर"
                },
                {
                    "village_id": 101,
                    "village_name": "हाजी सराय"
                },
                {
                    "village_id": 102,
                    "village_name": "हाजीपुर"
                },
                {
                    "village_id": 103,
                    "village_name": "हाटी"
                }
            ],
            "block_id": "2",
            "block_no": 1,
            "block_name": "काको [002]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अइरा"
                },
                {
                    "village_id": 3,
                    "village_name": "अख्तियारपुर"
                },
                {
                    "village_id": 4,
                    "village_name": "अवगीला"
                },
                {
                    "village_id": 5,
                    "village_name": "आरीफचक"
                },
                {
                    "village_id": 6,
                    "village_name": "इसहाकचक"
                },
                {
                    "village_id": 7,
                    "village_name": "उचिटा"
                },
                {
                    "village_id": 8,
                    "village_name": "उत्‍तरापटी"
                },
                {
                    "village_id": 9,
                    "village_name": "कखौरा"
                },
                {
                    "village_id": 10,
                    "village_name": "कुन्‍डिला"
                },
                {
                    "village_id": 11,
                    "village_name": "कुन्डिला आहर"
                },
                {
                    "village_id": 12,
                    "village_name": "कन्‍सुआ"
                },
                {
                    "village_id": 13,
                    "village_name": "कमरपुर धावा"
                },
                {
                    "village_id": 14,
                    "village_name": "कसवॉं"
                },
                {
                    "village_id": 15,
                    "village_name": "काजीचक"
                },
                {
                    "village_id": 16,
                    "village_name": "किताविस"
                },
                {
                    "village_id": 17,
                    "village_name": "किन्‍दुई"
                },
                {
                    "village_id": 18,
                    "village_name": "खैरूचक"
                },
                {
                    "village_id": 19,
                    "village_name": "खैरा"
                },
                {
                    "village_id": 20,
                    "village_name": "गगनकुरा"
                },
                {
                    "village_id": 21,
                    "village_name": "गपोचक"
                },
                {
                    "village_id": 22,
                    "village_name": "गलिमापुर"
                },
                {
                    "village_id": 23,
                    "village_name": "गोपालपुर"
                },
                {
                    "village_id": 24,
                    "village_name": "गोपालपुर"
                },
                {
                    "village_id": 25,
                    "village_name": "घेजन"
                },
                {
                    "village_id": 26,
                    "village_name": "चगोडी"
                },
                {
                    "village_id": 27,
                    "village_name": "चिकसौरा"
                },
                {
                    "village_id": 28,
                    "village_name": "जगनाथपुर"
                },
                {
                    "village_id": 29,
                    "village_name": "ज्ञानी विगहा"
                },
                {
                    "village_id": 30,
                    "village_name": "जहॉंगीपुर"
                },
                {
                    "village_id": 31,
                    "village_name": "झुनाठी"
                },
                {
                    "village_id": 32,
                    "village_name": "धानाडिहरी"
                },
                {
                    "village_id": 33,
                    "village_name": "नदपुरा"
                },
                {
                    "village_id": 34,
                    "village_name": "नुरचक"
                },
                {
                    "village_id": 35,
                    "village_name": "नेहालपुर"
                },
                {
                    "village_id": 36,
                    "village_name": "नारायणपुर"
                },
                {
                    "village_id": 37,
                    "village_name": "निरपुर"
                },
                {
                    "village_id": 38,
                    "village_name": "नोआवॉं"
                },
                {
                    "village_id": 39,
                    "village_name": "पंडित विगहा"
                },
                {
                    "village_id": 40,
                    "village_name": "पतिआवॉं"
                },
                {
                    "village_id": 41,
                    "village_name": "पन्‍डौल"
                },
                {
                    "village_id": 42,
                    "village_name": "परमेश्‍वरपुर"
                },
                {
                    "village_id": 43,
                    "village_name": "पिरीजपुरा"
                },
                {
                    "village_id": 44,
                    "village_name": "पोखवॉं"
                },
                {
                    "village_id": 45,
                    "village_name": "फरीदपुर"
                },
                {
                    "village_id": 46,
                    "village_name": "फौलादपुर"
                },
                {
                    "village_id": 47,
                    "village_name": "बढेता"
                },
                {
                    "village_id": 48,
                    "village_name": "बढौना"
                },
                {
                    "village_id": 49,
                    "village_name": "भगवानपुर"
                },
                {
                    "village_id": 50,
                    "village_name": "भेडीआ"
                },
                {
                    "village_id": 51,
                    "village_name": "भरसन्‍डा"
                },
                {
                    "village_id": 52,
                    "village_name": "मुर्गीयाचक"
                },
                {
                    "village_id": 53,
                    "village_name": "मुरहरा"
                },
                {
                    "village_id": 54,
                    "village_name": "मुस्तिचक"
                },
                {
                    "village_id": 55,
                    "village_name": "महदपुरा"
                },
                {
                    "village_id": 56,
                    "village_name": "महदीपुर"
                },
                {
                    "village_id": 57,
                    "village_name": "मानपुर वलवा"
                },
                {
                    "village_id": 58,
                    "village_name": "मिर्जापुर"
                },
                {
                    "village_id": 59,
                    "village_name": "मिश्रौलिया"
                },
                {
                    "village_id": 60,
                    "village_name": "मौलानाचक"
                },
                {
                    "village_id": 61,
                    "village_name": "रकसीआ"
                },
                {
                    "village_id": 62,
                    "village_name": "रतनी"
                },
                {
                    "village_id": 63,
                    "village_name": "रफीचक"
                },
                {
                    "village_id": 64,
                    "village_name": "रूस्‍तमचक"
                },
                {
                    "village_id": 65,
                    "village_name": "रसलपुर"
                },
                {
                    "village_id": 66,
                    "village_name": "रसुलपुर"
                },
                {
                    "village_id": 67,
                    "village_name": "रामपुर"
                },
                {
                    "village_id": 68,
                    "village_name": "रामपुर बढेता"
                },
                {
                    "village_id": 69,
                    "village_name": "रीता विगहा"
                },
                {
                    "village_id": 70,
                    "village_name": "लाखापुर"
                },
                {
                    "village_id": 71,
                    "village_name": "लोधनचक"
                },
                {
                    "village_id": 72,
                    "village_name": "वंदीपुर बढेता"
                },
                {
                    "village_id": 73,
                    "village_name": "वेदौली"
                },
                {
                    "village_id": 74,
                    "village_name": "वलोपुर"
                },
                {
                    "village_id": 75,
                    "village_name": "विजलीपुर"
                },
                {
                    "village_id": 76,
                    "village_name": "विशुनपुर"
                },
                {
                    "village_id": 77,
                    "village_name": "विशुनपुर जेठीआरा"
                },
                {
                    "village_id": 78,
                    "village_name": "शंकर विगहा"
                },
                {
                    "village_id": 79,
                    "village_name": "शकुराबाद"
                },
                {
                    "village_id": 80,
                    "village_name": "शाहमोहम्‍मद विगहा"
                },
                {
                    "village_id": 81,
                    "village_name": "सुजौला"
                },
                {
                    "village_id": 82,
                    "village_name": "सेन्‍धवा"
                },
                {
                    "village_id": 83,
                    "village_name": "सरता"
                },
                {
                    "village_id": 84,
                    "village_name": "सरेया"
                },
                {
                    "village_id": 85,
                    "village_name": "सरैया बाजार"
                },
                {
                    "village_id": 86,
                    "village_name": "सुरही"
                },
                {
                    "village_id": 87,
                    "village_name": "सुल्‍तानपुर"
                },
                {
                    "village_id": 88,
                    "village_name": "सलेमपुर"
                },
                {
                    "village_id": 89,
                    "village_name": "सलारपुर"
                },
                {
                    "village_id": 90,
                    "village_name": "सेसम्‍बा"
                },
                {
                    "village_id": 91,
                    "village_name": "सहवाजपुर"
                },
                {
                    "village_id": 92,
                    "village_name": "सिकन्‍दरपुर"
                },
                {
                    "village_id": 93,
                    "village_name": "सिकन्‍दरा"
                },
                {
                    "village_id": 94,
                    "village_name": "सोहरैया"
                },
                {
                    "village_id": 95,
                    "village_name": "हजामपुर"
                },
                {
                    "village_id": 96,
                    "village_name": "हडपुर"
                },
                {
                    "village_id": 97,
                    "village_name": "हरना खास"
                },
                {
                    "village_id": 98,
                    "village_name": "हसनपुरा"
                }
            ],
            "block_id": "3",
            "block_no": 2,
            "block_name": "रतनी फरीदपुर [003]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "उतीमपुर"
                },
                {
                    "village_id": 3,
                    "village_name": "केउर"
                },
                {
                    "village_id": 4,
                    "village_name": "कटौली"
                },
                {
                    "village_id": 5,
                    "village_name": "कन्‍दौल"
                },
                {
                    "village_id": 6,
                    "village_name": "केवला"
                },
                {
                    "village_id": 7,
                    "village_name": "कसियावॉ"
                },
                {
                    "village_id": 8,
                    "village_name": "कोकरसा"
                },
                {
                    "village_id": 9,
                    "village_name": "खुदौरी"
                },
                {
                    "village_id": 10,
                    "village_name": "खौना"
                },
                {
                    "village_id": 11,
                    "village_name": "गंगापुर"
                },
                {
                    "village_id": 12,
                    "village_name": "गिंजी"
                },
                {
                    "village_id": 13,
                    "village_name": "गोडिहा"
                },
                {
                    "village_id": 14,
                    "village_name": "चिरी"
                },
                {
                    "village_id": 15,
                    "village_name": "जगतपुर"
                },
                {
                    "village_id": 16,
                    "village_name": "जारू"
                },
                {
                    "village_id": 17,
                    "village_name": "डुमरी"
                },
                {
                    "village_id": 18,
                    "village_name": "डिहुरी"
                },
                {
                    "village_id": 19,
                    "village_name": "तिसौर"
                },
                {
                    "village_id": 20,
                    "village_name": "तीर्रा"
                },
                {
                    "village_id": 21,
                    "village_name": "दादपुर"
                },
                {
                    "village_id": 22,
                    "village_name": "दावथु"
                },
                {
                    "village_id": 23,
                    "village_name": "धवलविगहा"
                },
                {
                    "village_id": 24,
                    "village_name": "नरमा"
                },
                {
                    "village_id": 25,
                    "village_name": "नौगढ"
                },
                {
                    "village_id": 26,
                    "village_name": "बेतौली"
                },
                {
                    "village_id": 27,
                    "village_name": "बोलकपुर"
                },
                {
                    "village_id": 28,
                    "village_name": "बौरी"
                },
                {
                    "village_id": 29,
                    "village_name": "मुरगॉव"
                },
                {
                    "village_id": 30,
                    "village_name": "मसौली"
                },
                {
                    "village_id": 31,
                    "village_name": "मिर्जापुर"
                },
                {
                    "village_id": 32,
                    "village_name": "मोकिमपुर"
                },
                {
                    "village_id": 33,
                    "village_name": "रघुनाथपुर"
                },
                {
                    "village_id": 34,
                    "village_name": "रूस्‍तमपुर"
                },
                {
                    "village_id": 35,
                    "village_name": "लाट"
                },
                {
                    "village_id": 36,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 37,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 38,
                    "village_name": "वैगनी"
                },
                {
                    "village_id": 39,
                    "village_name": "वलीपुर"
                },
                {
                    "village_id": 40,
                    "village_name": "विहटा"
                },
                {
                    "village_id": 41,
                    "village_name": "वीर्रा"
                },
                {
                    "village_id": 42,
                    "village_name": "शरमा"
                },
                {
                    "village_id": 43,
                    "village_name": "सुकियावॉ"
                },
                {
                    "village_id": 44,
                    "village_name": "सैदपुर"
                },
                {
                    "village_id": 45,
                    "village_name": "सुरजपुर"
                },
                {
                    "village_id": 46,
                    "village_name": "सेरथुआ"
                },
                {
                    "village_id": 47,
                    "village_name": "सलेमपुर"
                },
                {
                    "village_id": 48,
                    "village_name": "सिसरा"
                },
                {
                    "village_id": 49,
                    "village_name": "हरपुर"
                }
            ],
            "block_id": "4",
            "block_no": 3,
            "block_name": "हुलासगंज [004]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अनथपुर"
                },
                {
                    "village_id": 3,
                    "village_name": "अरहिट"
                },
                {
                    "village_id": 4,
                    "village_name": "ओकरी"
                },
                {
                    "village_id": 5,
                    "village_name": "कठौतिया एराजी"
                },
                {
                    "village_id": 6,
                    "village_name": "करहरा"
                },
                {
                    "village_id": 7,
                    "village_name": "किसरामपुर"
                },
                {
                    "village_id": 8,
                    "village_name": "कौडियॉ"
                },
                {
                    "village_id": 9,
                    "village_name": "गंधार"
                },
                {
                    "village_id": 10,
                    "village_name": "गाजीपुर"
                },
                {
                    "village_id": 11,
                    "village_name": "गोविन्‍दपुर"
                },
                {
                    "village_id": 12,
                    "village_name": "घोबडी"
                },
                {
                    "village_id": 13,
                    "village_name": "चंधरिया"
                },
                {
                    "village_id": 14,
                    "village_name": "चरूई"
                },
                {
                    "village_id": 15,
                    "village_name": "छोटकी अकौना"
                },
                {
                    "village_id": 16,
                    "village_name": "जगदीशपुर"
                },
                {
                    "village_id": 17,
                    "village_name": "जैतीपुर कुरवा"
                },
                {
                    "village_id": 18,
                    "village_name": "जलालपुर"
                },
                {
                    "village_id": 19,
                    "village_name": "जागीरदारी"
                },
                {
                    "village_id": 20,
                    "village_name": "टरवॉ"
                },
                {
                    "village_id": 21,
                    "village_name": "डहरपुर मिल्‍की"
                },
                {
                    "village_id": 22,
                    "village_name": "दमालपुर"
                },
                {
                    "village_id": 23,
                    "village_name": "देवरा"
                },
                {
                    "village_id": 24,
                    "village_name": "देवरामठ"
                },
                {
                    "village_id": 25,
                    "village_name": "दौलतपुर"
                },
                {
                    "village_id": 26,
                    "village_name": "धामापुर"
                },
                {
                    "village_id": 27,
                    "village_name": "नईमा"
                },
                {
                    "village_id": 28,
                    "village_name": "नुरपुर"
                },
                {
                    "village_id": 29,
                    "village_name": "पेउता"
                },
                {
                    "village_id": 30,
                    "village_name": "पखनपुर"
                },
                {
                    "village_id": 31,
                    "village_name": "परीआवॉं"
                },
                {
                    "village_id": 32,
                    "village_name": "पिताम्‍बर पुर"
                },
                {
                    "village_id": 33,
                    "village_name": "पिरोधा"
                },
                {
                    "village_id": 34,
                    "village_name": "बडकी अकौना"
                },
                {
                    "village_id": 35,
                    "village_name": "बन्‍छीली"
                },
                {
                    "village_id": 36,
                    "village_name": "बन्‍ध्‍ुागंज"
                },
                {
                    "village_id": 37,
                    "village_name": "बैना"
                },
                {
                    "village_id": 38,
                    "village_name": "भिमलपुर"
                },
                {
                    "village_id": 39,
                    "village_name": "मईमा"
                },
                {
                    "village_id": 40,
                    "village_name": "मन्‍डई"
                },
                {
                    "village_id": 41,
                    "village_name": "मसाढ‍ निस्‍फ"
                },
                {
                    "village_id": 42,
                    "village_name": "महमदपुर"
                },
                {
                    "village_id": 43,
                    "village_name": "महमदपुर"
                },
                {
                    "village_id": 44,
                    "village_name": "मिर्जापुर"
                },
                {
                    "village_id": 45,
                    "village_name": "मिल्‍की"
                },
                {
                    "village_id": 46,
                    "village_name": "मिल्‍की वेचिराग"
                },
                {
                    "village_id": 47,
                    "village_name": "वंरछी विगहा"
                },
                {
                    "village_id": 48,
                    "village_name": "वहियाराचक"
                },
                {
                    "village_id": 49,
                    "village_name": "विसुनपुर"
                },
                {
                    "village_id": 50,
                    "village_name": "शेखपुरा"
                },
                {
                    "village_id": 51,
                    "village_name": "सकरौढा"
                },
                {
                    "village_id": 52,
                    "village_name": "सर्इस्‍तावाद"
                },
                {
                    "village_id": 53,
                    "village_name": "सादीपुर ऐमा"
                },
                {
                    "village_id": 54,
                    "village_name": "साहिदपुर"
                },
                {
                    "village_id": 55,
                    "village_name": "सिकन्‍दरपुर"
                },
                {
                    "village_id": 56,
                    "village_name": "हवलीपुर"
                }
            ],
            "block_id": "5",
            "block_no": 4,
            "block_name": "मोदनगंज [005]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अतियावॉं"
                },
                {
                    "village_id": 3,
                    "village_name": "अहियासा"
                },
                {
                    "village_id": 4,
                    "village_name": "उवेर"
                },
                {
                    "village_id": 5,
                    "village_name": "कुर्र"
                },
                {
                    "village_id": 6,
                    "village_name": "कैरवा"
                },
                {
                    "village_id": 7,
                    "village_name": "केवाली"
                },
                {
                    "village_id": 8,
                    "village_name": "कोरमा"
                },
                {
                    "village_id": 9,
                    "village_name": "कोर्रा"
                },
                {
                    "village_id": 10,
                    "village_name": "खिरौटी"
                },
                {
                    "village_id": 11,
                    "village_name": "गंधार  एराजी"
                },
                {
                    "village_id": 12,
                    "village_name": "गम्‍हरपुर"
                },
                {
                    "village_id": 13,
                    "village_name": "गोडसर"
                },
                {
                    "village_id": 14,
                    "village_name": "गोलकपुर"
                },
                {
                    "village_id": 15,
                    "village_name": "घोषी"
                },
                {
                    "village_id": 16,
                    "village_name": "चौपहा"
                },
                {
                    "village_id": 17,
                    "village_name": "छपन्‍ना"
                },
                {
                    "village_id": 18,
                    "village_name": "डमौआ"
                },
                {
                    "village_id": 19,
                    "village_name": "डहरपुर"
                },
                {
                    "village_id": 20,
                    "village_name": "ढोढरी"
                },
                {
                    "village_id": 21,
                    "village_name": "दरियापुर"
                },
                {
                    "village_id": 22,
                    "village_name": "धुरियारी"
                },
                {
                    "village_id": 23,
                    "village_name": "नगवॉं"
                },
                {
                    "village_id": 24,
                    "village_name": "नन्‍दना"
                },
                {
                    "village_id": 25,
                    "village_name": "नवादा"
                },
                {
                    "village_id": 26,
                    "village_name": "परावन"
                },
                {
                    "village_id": 27,
                    "village_name": "पाठक विगहा"
                },
                {
                    "village_id": 28,
                    "village_name": "फरीदपुरा"
                },
                {
                    "village_id": 29,
                    "village_name": "भखरा"
                },
                {
                    "village_id": 30,
                    "village_name": "भारथु"
                },
                {
                    "village_id": 31,
                    "village_name": "मखदुमपुर"
                },
                {
                    "village_id": 32,
                    "village_name": "मेटरा"
                },
                {
                    "village_id": 33,
                    "village_name": "मेहदीपुर"
                },
                {
                    "village_id": 34,
                    "village_name": "माधोपुर"
                },
                {
                    "village_id": 35,
                    "village_name": "मीराविगहा"
                },
                {
                    "village_id": 36,
                    "village_name": "मोहमदपुर"
                },
                {
                    "village_id": 37,
                    "village_name": "मोहिउदीनपुर"
                },
                {
                    "village_id": 38,
                    "village_name": "लखावर"
                },
                {
                    "village_id": 39,
                    "village_name": "लखीसराय"
                },
                {
                    "village_id": 40,
                    "village_name": "लोदीपुर"
                },
                {
                    "village_id": 41,
                    "village_name": "वभनपुरा"
                },
                {
                    "village_id": 42,
                    "village_name": "वाजितपुर"
                },
                {
                    "village_id": 43,
                    "village_name": "विरूपुर"
                },
                {
                    "village_id": 44,
                    "village_name": "विशुनपुर"
                },
                {
                    "village_id": 45,
                    "village_name": "वोमई"
                },
                {
                    "village_id": 46,
                    "village_name": "श्रीपुर"
                },
                {
                    "village_id": 47,
                    "village_name": "शाहपुर"
                },
                {
                    "village_id": 48,
                    "village_name": "शाहपुर मिल्‍की"
                },
                {
                    "village_id": 49,
                    "village_name": "सेखपुरा"
                },
                {
                    "village_id": 50,
                    "village_name": "समरकन्‍द"
                },
                {
                    "village_id": 51,
                    "village_name": "सरहद"
                },
                {
                    "village_id": 52,
                    "village_name": "सलारपुर"
                },
                {
                    "village_id": 53,
                    "village_name": "साहोविगहा"
                },
                {
                    "village_id": 54,
                    "village_name": "सोनवॉं"
                }
            ],
            "block_id": "6",
            "block_no": 5,
            "block_name": "घोषी [006]",
            "sub_div_code": "1"
        },
        {
            "village": [
                {
                    "village_id": 2,
                    "village_name": "अकौना"
                },
                {
                    "village_id": 3,
                    "village_name": "अमरपुर"
                },
                {
                    "village_id": 4,
                    "village_name": "अमसारा"
                },
                {
                    "village_id": 5,
                    "village_name": "अलाहगंज"
                },
                {
                    "village_id": 6,
                    "village_name": "ईकिक्‍ल खास"
                },
                {
                    "village_id": 7,
                    "village_name": "ईकिल ढोढा"
                },
                {
                    "village_id": 8,
                    "village_name": "ईसमाईलपुर"
                },
                {
                    "village_id": 9,
                    "village_name": "उमता"
                },
                {
                    "village_id": 10,
                    "village_name": "ऑकोपुर"
                },
                {
                    "village_id": 11,
                    "village_name": "ओवा"
                },
                {
                    "village_id": 12,
                    "village_name": "ककडि.या"
                },
                {
                    "village_id": 13,
                    "village_name": "कुकरी विगहा"
                },
                {
                    "village_id": 14,
                    "village_name": "कच नावॉं"
                },
                {
                    "village_id": 15,
                    "village_name": "कतरासीन"
                },
                {
                    "village_id": 16,
                    "village_name": "कुतवनचक"
                },
                {
                    "village_id": 17,
                    "village_name": "कनसारा"
                },
                {
                    "village_id": 18,
                    "village_name": "कनौली"
                },
                {
                    "village_id": 19,
                    "village_name": "कुमरडिह"
                },
                {
                    "village_id": 20,
                    "village_name": "क्‍यामचक"
                },
                {
                    "village_id": 21,
                    "village_name": "कुर्था"
                },
                {
                    "village_id": 22,
                    "village_name": "कलानैर"
                },
                {
                    "village_id": 23,
                    "village_name": "केवटार"
                },
                {
                    "village_id": 24,
                    "village_name": "काईली"
                },
                {
                    "village_id": 25,
                    "village_name": "काकरपुर"
                },
                {
                    "village_id": 26,
                    "village_name": "काजिचक"
                },
                {
                    "village_id": 27,
                    "village_name": "कान्‍दर"
                },
                {
                    "village_id": 28,
                    "village_name": "कोईरी विगहा"
                },
                {
                    "village_id": 29,
                    "village_name": "कोहरा"
                },
                {
                    "village_id": 30,
                    "village_name": "कोहरा"
                },
                {
                    "village_id": 31,
                    "village_name": "खरका"
                },
                {
                    "village_id": 32,
                    "village_name": "खरौना"
                },
                {
                    "village_id": 33,
                    "village_name": "खुशियालपुर"
                },
                {
                    "village_id": 34,
                    "village_name": "खसखोरी"
                },
                {
                    "village_id": 35,
                    "village_name": "खेाजपुरा"
                },
                {
                    "village_id": 36,
                    "village_name": "खानपुर"
                },
                {
                    "village_id": 37,
                    "village_name": "गाजीपुर"
                },
                {
                    "village_id": 38,
                    "village_name": "गोलविगहा"
                },
                {
                    "village_id": 39,
                    "village_name": "चकढोढा"
                },
                {
                    "village_id": 40,
                    "village_name": "चन्‍दई"
                },
                {
                    "village_id": 41,
                    "village_name": "चमंडी"
                },
                {
                    "village_id": 42,
                    "village_name": "चाढ."
                },
                {
                    "village_id": 43,
                    "village_name": "चातर"
                },
                {
                    "village_id": 44,
                    "village_name": "चालिस कुरवा"
                },
                {
                    "village_id": 45,
                    "village_name": "चिलोरी"
                },
                {
                    "village_id": 46,
                    "village_name": "चिस्तिपुर पकरी"
                },
                {
                    "village_id": 47,
                    "village_name": "छतीयाना"
                },
                {
                    "village_id": 48,
                    "village_name": "छरियारी"
                },
                {
                    "village_id": 49,
                    "village_name": "जगदीशपुर"
                },
                {
                    "village_id": 50,
                    "village_name": "जगपुरा"
                },
                {
                    "village_id": 51,
                    "village_name": "जैतीया"
                },
                {
                    "village_id": 52,
                    "village_name": "जमनगंज"
                },
                {
                    "village_id": 53,
                    "village_name": "जलामलपुर"
                },
                {
                    "village_id": 54,
                    "village_name": "टेहटा"
                },
                {
                    "village_id": 55,
                    "village_name": "टिकारौ"
                },
                {
                    "village_id": 56,
                    "village_name": "डकरा"
                },
                {
                    "village_id": 57,
                    "village_name": "ढकनी विगहा"
                },
                {
                    "village_id": 58,
                    "village_name": "तरहुआ"
                },
                {
                    "village_id": 59,
                    "village_name": "तिलकई"
                },
                {
                    "village_id": 60,
                    "village_name": "देवकुली"
                },
                {
                    "village_id": 61,
                    "village_name": "दानूविगहा"
                },
                {
                    "village_id": 62,
                    "village_name": "दामादरपुर"
                },
                {
                    "village_id": 63,
                    "village_name": "दौलतपुर"
                },
                {
                    "village_id": 64,
                    "village_name": "धनुकी"
                },
                {
                    "village_id": 65,
                    "village_name": "धनकौल"
                },
                {
                    "village_id": 66,
                    "village_name": "धरनई"
                },
                {
                    "village_id": 67,
                    "village_name": "धराउत"
                },
                {
                    "village_id": 68,
                    "village_name": "नन्‍दनपुरा"
                },
                {
                    "village_id": 69,
                    "village_name": "नेर"
                },
                {
                    "village_id": 70,
                    "village_name": "नवगढ"
                },
                {
                    "village_id": 71,
                    "village_name": "नारायणपुर"
                },
                {
                    "village_id": 72,
                    "village_name": "निजुमला"
                },
                {
                    "village_id": 73,
                    "village_name": "निरपुर"
                },
                {
                    "village_id": 74,
                    "village_name": "निवारी"
                },
                {
                    "village_id": 75,
                    "village_name": "पुनहदा"
                },
                {
                    "village_id": 76,
                    "village_name": "पलेया"
                },
                {
                    "village_id": 77,
                    "village_name": "पिपरा"
                },
                {
                    "village_id": 78,
                    "village_name": "पॉपु"
                },
                {
                    "village_id": 79,
                    "village_name": "फतेहपुर"
                },
                {
                    "village_id": 80,
                    "village_name": "बन्‍देया"
                },
                {
                    "village_id": 81,
                    "village_name": "बेरका"
                },
                {
                    "village_id": 82,
                    "village_name": "बेला"
                },
                {
                    "village_id": 83,
                    "village_name": "बोकनारी कला"
                },
                {
                    "village_id": 84,
                    "village_name": "बोकनारी खुर्द"
                },
                {
                    "village_id": 85,
                    "village_name": "भैख"
                },
                {
                    "village_id": 86,
                    "village_name": "भाने विगहा"
                },
                {
                    "village_id": 87,
                    "village_name": "भीमपुरा"
                },
                {
                    "village_id": 88,
                    "village_name": "मकपा"
                },
                {
                    "village_id": 89,
                    "village_name": "मकरपुर"
                },
                {
                    "village_id": 90,
                    "village_name": "मखदुमपुर"
                },
                {
                    "village_id": 91,
                    "village_name": "मंझोस"
                },
                {
                    "village_id": 92,
                    "village_name": "मटौर"
                },
                {
                    "village_id": 93,
                    "village_name": "मटौरचक"
                },
                {
                    "village_id": 94,
                    "village_name": "मठ मरसुआ"
                },
                {
                    "village_id": 95,
                    "village_name": "मननपुर"
                },
                {
                    "village_id": 96,
                    "village_name": "मरसुआ"
                },
                {
                    "village_id": 97,
                    "village_name": "मलाठी"
                },
                {
                    "village_id": 98,
                    "village_name": "मुसीविरा"
                },
                {
                    "village_id": 99,
                    "village_name": "महंगुपुर"
                },
                {
                    "village_id": 100,
                    "village_name": "महमदपुर"
                },
                {
                    "village_id": 101,
                    "village_name": "महम्‍दीपुर"
                },
                {
                    "village_id": 102,
                    "village_name": "महम्‍मदपुर"
                },
                {
                    "village_id": 103,
                    "village_name": "महेवा"
                },
                {
                    "village_id": 104,
                    "village_name": "माछिल"
                },
                {
                    "village_id": 105,
                    "village_name": "मिराविगहा"
                },
                {
                    "village_id": 106,
                    "village_name": "मोगल विगहा"
                },
                {
                    "village_id": 107,
                    "village_name": "राजाविगहा"
                },
                {
                    "village_id": 108,
                    "village_name": "रामपुर"
                },
                {
                    "village_id": 109,
                    "village_name": "लखसम्‍मा"
                },
                {
                    "village_id": 110,
                    "village_name": "लडौआ"
                },
                {
                    "village_id": 111,
                    "village_name": "लडौआ"
                },
                {
                    "village_id": 112,
                    "village_name": "वेरथू"
                },
                {
                    "village_id": 113,
                    "village_name": "वाजितपुर"
                },
                {
                    "village_id": 114,
                    "village_name": "विर्रा"
                },
                {
                    "village_id": 115,
                    "village_name": "विशुनगंज"
                },
                {
                    "village_id": 116,
                    "village_name": "विशुनपुर"
                },
                {
                    "village_id": 117,
                    "village_name": "शुकनाविगहा"
                },
                {
                    "village_id": 118,
                    "village_name": "शेखपुरा"
                },
                {
                    "village_id": 119,
                    "village_name": "सूगॉव"
                },
                {
                    "village_id": 120,
                    "village_name": "सैदपुर"
                },
                {
                    "village_id": 121,
                    "village_name": "सूपी"
                },
                {
                    "village_id": 122,
                    "village_name": "सुमेरा"
                },
                {
                    "village_id": 123,
                    "village_name": "सुमेरा अजरकबे"
                },
                {
                    "village_id": 124,
                    "village_name": "सुरका"
                },
                {
                    "village_id": 125,
                    "village_name": "सेरथुआ"
                },
                {
                    "village_id": 126,
                    "village_name": "सरेन"
                },
                {
                    "village_id": 127,
                    "village_name": "सरैया"
                },
                {
                    "village_id": 128,
                    "village_name": "सुलातनपुर"
                },
                {
                    "village_id": 129,
                    "village_name": "सेवति"
                },
                {
                    "village_id": 130,
                    "village_name": "सागरपुर"
                },
                {
                    "village_id": 131,
                    "village_name": "सामोचक"
                },
                {
                    "village_id": 132,
                    "village_name": "सिरपतपुर"
                },
                {
                    "village_id": 133,
                    "village_name": "सोलहन्‍डा"
                },
                {
                    "village_id": 134,
                    "village_name": "सोहजना"
                },
                {
                    "village_id": 135,
                    "village_name": "हडैल"
                }
            ],
            "block_id": "7",
            "block_no": 6,
            "block_name": "मखदुमपुर [007]",
            "sub_div_code": "1"
        }
    ],
  "district_id": "33",
  "district_name": "जहानाबाद"
},
{
    "block":  [
    {
        "village": [
            {
                "label": "अख्तियारपुर 0218",
                "village_id": 2,
                "village_name": "अख्तियारपुर"
            },
            {
                "label": "अझौर 0237",
                "village_id": 3,
                "village_name": "अझौर"
            },
            {
                "label": "अमरौर किरतपुर 0371",
                "village_id": 4,
                "village_name": "अमरौर किरतपुर"
            },
            {
                "label": "अलौदीपुर 0393",
                "village_id": 5,
                "village_name": "अलौदीपुर"
            },
            {
                "label": "अलौदीपुर 0394",
                "village_id": 6,
                "village_name": "अलौदीपुर"
            },
            {
                "label": "अलौदीपुर 0395",
                "village_id": 7,
                "village_name": "अलौदीपुर"
            },
            {
                "label": "अलौदीपुर 0399",
                "village_id": 8,
                "village_name": "अलौदीपुर"
            },
            {
                "label": "आन्‍नदुपर 0357",
                "village_id": 9,
                "village_name": "आन्‍नदुपर"
            },
            {
                "label": "इनियार 0331",
                "village_id": 10,
                "village_name": "इनियार"
            },
            {
                "label": "ईटवा 0411",
                "village_id": 11,
                "village_name": "ईटवा"
            },
            {
                "label": "ईटवा 0390",
                "village_id": 12,
                "village_name": "ईटवा"
            },
            {
                "label": "उलाव 0412",
                "village_id": 13,
                "village_name": "उलाव"
            },
            {
                "label": "एराजी खरीगदी 0349",
                "village_id": 14,
                "village_name": "एराजी खरीगदी"
            },
            {
                "label": "कैथ 0335",
                "village_id": 15,
                "village_name": "कैथ"
            },
            {
                "label": "कैथमा 0403",
                "village_id": 16,
                "village_name": "कैथमा"
            },
            {
                "label": "कमतौली 0404",
                "village_id": 17,
                "village_name": "कमतौली"
            },
            {
                "label": "कमतौली 0405",
                "village_id": 18,
                "village_name": "कमतौली"
            },
            {
                "label": "कुशमौत 0334",
                "village_id": 19,
                "village_name": "कुशमौत"
            },
            {
                "label": "कारीचक 0361",
                "village_id": 20,
                "village_name": "कारीचक"
            },
            {
                "label": "कोरीया 0195",
                "village_id": 21,
                "village_name": "कोरीया"
            },
            {
                "label": "कौआ चौड़ 0224",
                "village_id": 22,
                "village_name": "कौआ चौड़"
            },
            {
                "label": "खम्‍हार 0216",
                "village_id": 23,
                "village_name": "खम्‍हार"
            },
            {
                "label": "खातोपुर 0339",
                "village_id": 24,
                "village_name": "खातोपुर"
            },
            {
                "label": "खातोपुर 0504",
                "village_id": 25,
                "village_name": "खातोपुर"
            },
            {
                "label": "गौसपुर 0221",
                "village_id": 26,
                "village_name": "गौसपुर"
            },
            {
                "label": "चक परना 0236",
                "village_id": 27,
                "village_name": "चक परना"
            },
            {
                "label": "चक पहाड़ 0354",
                "village_id": 28,
                "village_name": "चक पहाड़"
            },
            {
                "label": "चक फरीद 0367",
                "village_id": 29,
                "village_name": "चक फरीद"
            },
            {
                "label": "चक फरीद 0372",
                "village_id": 30,
                "village_name": "चक फरीद"
            },
            {
                "label": "चक रहमान 0233",
                "village_id": 31,
                "village_name": "चक रहमान"
            },
            {
                "label": "चक्‍का 0217",
                "village_id": 32,
                "village_name": "चक्‍का"
            },
            {
                "label": "चकगोपाल 0345",
                "village_id": 33,
                "village_name": "चकगोपाल"
            },
            {
                "label": "चकदानिस 0341",
                "village_id": 34,
                "village_name": "चकदानिस"
            },
            {
                "label": "चकनसीर 0347",
                "village_id": 35,
                "village_name": "चकनसीर"
            },
            {
                "label": "चकफरीद 0368",
                "village_id": 36,
                "village_name": "चकफरीद"
            },
            {
                "label": "चकबेगन 0226",
                "village_id": 37,
                "village_name": "चकबेगन"
            },
            {
                "label": "चकबेगान 0231",
                "village_id": 38,
                "village_name": "चकबेगान"
            },
            {
                "label": "चकयासीन 0225",
                "village_id": 39,
                "village_name": "चकयासीन"
            },
            {
                "label": "चांदपुरा 0240",
                "village_id": 40,
                "village_name": "चांदपुरा"
            },
            {
                "label": "चांदपुरा 0199",
                "village_id": 41,
                "village_name": "चांदपुरा"
            },
            {
                "label": "चांदपुरा 0201",
                "village_id": 42,
                "village_name": "चांदपुरा"
            },
            {
                "label": "चिलमिल 0360",
                "village_id": 43,
                "village_name": "चिलमिल"
            },
            {
                "label": "चोड्डा 0517",
                "village_id": 44,
                "village_name": "चोड्डा"
            },
            {
                "label": "छोटी सांख 0230",
                "village_id": 45,
                "village_name": "छोटी सांख"
            },
            {
                "label": "जिनेदपुर 0223",
                "village_id": 46,
                "village_name": "जिनेदपुर"
            },
            {
                "label": "डुमरी 0410",
                "village_id": 47,
                "village_name": "डुमरी"
            },
            {
                "label": "धवौली 0336",
                "village_id": 48,
                "village_name": "धवौली"
            },
            {
                "label": "नागहद 0374",
                "village_id": 49,
                "village_name": "नागहद"
            },
            {
                "label": "नाड़हीचक 0227",
                "village_id": 50,
                "village_name": "नाड़हीचक"
            },
            {
                "label": "नीमा 0241",
                "village_id": 51,
                "village_name": "नीमा"
            },
            {
                "label": "नीमा मदनपुरवीर 0239",
                "village_id": 52,
                "village_name": "नीमा मदनपुरवीर"
            },
            {
                "label": "नौरंगा 0384",
                "village_id": 53,
                "village_name": "नौरंगा"
            },
            {
                "label": "पचम्‍बा 0358",
                "village_id": 54,
                "village_name": "पचम्‍बा"
            },
            {
                "label": "पनसल्‍ला 0526",
                "village_id": 55,
                "village_name": "पनसल्‍ला"
            },
            {
                "label": "पन्‍हांस 0355",
                "village_id": 56,
                "village_name": "पन्‍हांस"
            },
            {
                "label": "परना 0238",
                "village_id": 57,
                "village_name": "परना"
            },
            {
                "label": "पसपुरा 0406",
                "village_id": 58,
                "village_name": "पसपुरा"
            },
            {
                "label": "पहाड़ चक 0353",
                "village_id": 59,
                "village_name": "पहाड़ चक"
            },
            {
                "label": "पिपरा 0386",
                "village_id": 60,
                "village_name": "पिपरा"
            },
            {
                "label": "पोखरिया 0346",
                "village_id": 61,
                "village_name": "पोखरिया"
            },
            {
                "label": "फतेहपुर 0363",
                "village_id": 62,
                "village_name": "फतेहपुर"
            },
            {
                "label": "बड़ी सौख 0232",
                "village_id": 63,
                "village_name": "बड़ी सौख"
            },
            {
                "label": "बांगभैरव 0385",
                "village_id": 64,
                "village_name": "बांगभैरव"
            },
            {
                "label": "बागवाड़ा 0362",
                "village_id": 65,
                "village_name": "बागवाड़ा"
            },
            {
                "label": "बाघी 0376",
                "village_id": 66,
                "village_name": "बाघी"
            },
            {
                "label": "बाघी 0382",
                "village_id": 67,
                "village_name": "बाघी"
            },
            {
                "label": "बाधा 0356",
                "village_id": 68,
                "village_name": "बाधा"
            },
            {
                "label": "बिनोदपुर 0229",
                "village_id": 69,
                "village_name": "बिनोदपुर"
            },
            {
                "label": "भर्रा 0352",
                "village_id": 70,
                "village_name": "भर्रा"
            },
            {
                "label": "भैरवार 0527",
                "village_id": 71,
                "village_name": "भैरवार"
            },
            {
                "label": "मकससपुर 0391",
                "village_id": 72,
                "village_name": "मकससपुर"
            },
            {
                "label": "मैनका सलेमपुर 0525",
                "village_id": 73,
                "village_name": "मैनका सलेमपुर"
            },
            {
                "label": "मस्‍ती फतेहपुर 0524",
                "village_id": 74,
                "village_name": "मस्‍ती फतेहपुर"
            },
            {
                "label": "महम्‍मदपुर 0344",
                "village_id": 75,
                "village_name": "महम्‍मदपुर"
            },
            {
                "label": "महारथपुर 0370",
                "village_id": 76,
                "village_name": "महारथपुर"
            },
            {
                "label": "मालपुर 0401",
                "village_id": 77,
                "village_name": "मालपुर"
            },
            {
                "label": "मियांचक 0389",
                "village_id": 78,
                "village_name": "मियांचक"
            },
            {
                "label": "मिर्जापुर बन्‍दुआर 0342",
                "village_id": 79,
                "village_name": "मिर्जापुर बन्‍दुआर"
            },
            {
                "label": "मिलकी 0351",
                "village_id": 80,
                "village_name": "मिलकी"
            },
            {
                "label": "मीरगंज 0383",
                "village_id": 81,
                "village_name": "मीरगंज"
            },
            {
                "label": "मोकिमपुर बेगूसराय 0348",
                "village_id": 82,
                "village_name": "मोकिमपुर बेगूसराय"
            },
            {
                "label": "मोहन ऐघु 0503",
                "village_id": 83,
                "village_name": "मोहन ऐघु"
            },
            {
                "label": "मोहनपुर 0200",
                "village_id": 84,
                "village_name": "मोहनपुर"
            },
            {
                "label": "मोहनपुर 0215",
                "village_id": 85,
                "village_name": "मोहनपुर"
            },
            {
                "label": "मोहनपुर बरारी 0214",
                "village_id": 86,
                "village_name": "मोहनपुर बरारी"
            },
            {
                "label": "मोहम्‍दपुर रघुनाथ 0375",
                "village_id": 87,
                "village_name": "मोहम्‍दपुर रघुनाथ"
            },
            {
                "label": "मोहम्‍मदपुर रघुनाथ 0377",
                "village_id": 88,
                "village_name": "मोहम्‍मदपुर रघुनाथ"
            },
            {
                "label": "मोहम्‍मदपुर रघुनाथ 0378",
                "village_id": 89,
                "village_name": "मोहम्‍मदपुर रघुनाथ"
            },
            {
                "label": "मोहम्‍मदपुर रघुनाथ 0379",
                "village_id": 90,
                "village_name": "मोहम्‍मदपुर रघुनाथ"
            },
            {
                "label": "मोहम्‍मदपुर रघुनाथ 0381",
                "village_id": 91,
                "village_name": "मोहम्‍मदपुर रघुनाथ"
            },
            {
                "label": "मोहम्‍मदपुर रघुनाथ 0388",
                "village_id": 92,
                "village_name": "मोहम्‍मदपुर रघुनाथ"
            },
            {
                "label": "रैचयाही 0416",
                "village_id": 93,
                "village_name": "रैचयाही"
            },
            {
                "label": "रेचियाही 0414",
                "village_id": 94,
                "village_name": "रेचियाही"
            },
            {
                "label": "रजौड़ा 0219",
                "village_id": 95,
                "village_name": "रजौड़ा"
            },
            {
                "label": "रतौली 0365",
                "village_id": 96,
                "village_name": "रतौली"
            },
            {
                "label": "रैयियाही 0415",
                "village_id": 97,
                "village_name": "रैयियाही"
            },
            {
                "label": "रहीमपुर तैरया 0234",
                "village_id": 98,
                "village_name": "रहीमपुर तैरया"
            },
            {
                "label": "राजा डुमरी 0338",
                "village_id": 99,
                "village_name": "राजा डुमरी"
            },
            {
                "label": "राजाडुमरी 0505",
                "village_id": 100,
                "village_name": "राजाडुमरी"
            },
            {
                "label": "रामनगर 0408",
                "village_id": 101,
                "village_name": "रामनगर"
            },
            {
                "label": "रामनगर 0402",
                "village_id": 102,
                "village_name": "रामनगर"
            },
            {
                "label": "लरुआरा 0359",
                "village_id": 103,
                "village_name": "लरुआरा"
            },
            {
                "label": "लाखो 0337",
                "village_id": 104,
                "village_name": "लाखो"
            },
            {
                "label": "लोदीपुर 0521",
                "village_id": 105,
                "village_name": "लोदीपुर"
            },
            {
                "label": "वन्‍द्धार 0235",
                "village_id": 106,
                "village_name": "वन्‍द्धार"
            },
            {
                "label": "वहदरपुर 0518",
                "village_id": 107,
                "village_name": "वहदरपुर"
            },
            {
                "label": "वहदरपुर 0519",
                "village_id": 108,
                "village_name": "वहदरपुर"
            },
            {
                "label": "वाजिदपुर 0340",
                "village_id": 109,
                "village_name": "वाजिदपुर"
            },
            {
                "label": "वासुदेवपुर 0198",
                "village_id": 110,
                "village_name": "वासुदेवपुर"
            },
            {
                "label": "विनोदपुर 0364",
                "village_id": 111,
                "village_name": "विनोदपुर"
            },
            {
                "label": "विशनपुर 0343",
                "village_id": 112,
                "village_name": "विशनपुर"
            },
            {
                "label": "विशनपुर चतुर्भुज 0409",
                "village_id": 113,
                "village_name": "विशनपुर चतुर्भुज"
            },
            {
                "label": "विशनपुर चतुर्भुज 0407",
                "village_id": 114,
                "village_name": "विशनपुर चतुर्भुज"
            },
            {
                "label": "विशनपुर चतुर्भुज 0396",
                "village_id": 115,
                "village_name": "विशनपुर चतुर्भुज"
            },
            {
                "label": "विशनपुर चतुर्भुज 0392",
                "village_id": 116,
                "village_name": "विशनपुर चतुर्भुज"
            },
            {
                "label": "शमसुद्यीनपुर 0522",
                "village_id": 117,
                "village_name": "शमसुद्यीनपुर"
            },
            {
                "label": "शमसुद्यीनपुर 0523",
                "village_id": 118,
                "village_name": "शमसुद्यीनपुर"
            },
            {
                "label": "शेरपुर 0242",
                "village_id": 119,
                "village_name": "शेरपुर"
            },
            {
                "label": "शाहपुर 0520",
                "village_id": 120,
                "village_name": "शाहपुर"
            },
            {
                "label": "सुजा 0350",
                "village_id": 121,
                "village_name": "सुजा"
            },
            {
                "label": "सल्‍खू तेघड़ा 0413",
                "village_id": 122,
                "village_name": "सल्‍खू तेघड़ा"
            },
            {
                "label": "सांख बुजुर्ग 0228",
                "village_id": 123,
                "village_name": "सांख बुजुर्ग"
            },
            {
                "label": "सिकन्‍दरपुर 0222",
                "village_id": 124,
                "village_name": "सिकन्‍दरपुर"
            },
            {
                "label": "सिंघौल 0373",
                "village_id": 125,
                "village_name": "सिंघौल"
            },
            {
                "label": "हेमरा 0397",
                "village_id": 126,
                "village_name": "हेमरा"
            },
            {
                "label": "हेमरा 0398",
                "village_id": 127,
                "village_name": "हेमरा"
            },
            {
                "label": "हेमरा 0400",
                "village_id": 128,
                "village_name": "हेमरा"
            },
            {
                "label": "हमीदचक मिलकी 0369",
                "village_id": 129,
                "village_name": "हमीदचक मिलकी"
            },
            {
                "label": "हमीरचक मिलकी 0366",
                "village_id": 130,
                "village_name": "हमीरचक मिलकी"
            },
            {
                "label": "हरदिया 0220",
                "village_id": 131,
                "village_name": "हरदिया"
            },
            {
                "label": "हर्रख 0387",
                "village_id": 132,
                "village_name": "हर्रख"
            },
            {
                "label": "हर्रख 0380",
                "village_id": 133,
                "village_name": "हर्रख"
            },
            {
                "label": "हैवतपुर 0196",
                "village_id": 134,
                "village_name": "हैवतपुर"
            }
        ],
        "block_id": "1",
        "block_no": 0,
        "block_name": "बेगूसराय [001]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अमरपुर 0369",
                "village_id": 2,
                "village_name": "अमरपुर"
            },
            {
                "label": "अरजी पपरौर 0502",
                "village_id": 3,
                "village_name": "अरजी पपरौर"
            },
            {
                "label": "अलमोचक  0556",
                "village_id": 4,
                "village_name": "अलमोचक "
            },
            {
                "label": "असकरा 0532",
                "village_id": 5,
                "village_name": "असकरा"
            },
            {
                "label": "असुरारी 0472",
                "village_id": 6,
                "village_name": "असुरारी"
            },
            {
                "label": "इब्राहिमपुर 0539",
                "village_id": 7,
                "village_name": "इब्राहिमपुर"
            },
            {
                "label": "इब्राहिमपुर 0546",
                "village_id": 8,
                "village_name": "इब्राहिमपुर"
            },
            {
                "label": "केशाबे 0557",
                "village_id": 9,
                "village_name": "केशाबे"
            },
            {
                "label": "कारीचक 0456",
                "village_id": 10,
                "village_name": "कारीचक"
            },
            {
                "label": "खुटौना 0525",
                "village_id": 11,
                "village_name": "खुटौना"
            },
            {
                "label": "खेमकरणपुर 0497",
                "village_id": 12,
                "village_name": "खेमकरणपुर"
            },
            {
                "label": "गंगा प्रसाद 0368",
                "village_id": 13,
                "village_name": "गंगा प्रसाद"
            },
            {
                "label": "गढ़हारा 0486",
                "village_id": 14,
                "village_name": "गढ़हारा"
            },
            {
                "label": "गोविन्‍दपुर 0552",
                "village_id": 15,
                "village_name": "गोविन्‍दपुर"
            },
            {
                "label": "चक रिसालदपुर 0493",
                "village_id": 16,
                "village_name": "चक रिसालदपुर"
            },
            {
                "label": "चक श्रीनाथ 0490",
                "village_id": 17,
                "village_name": "चक श्रीनाथ"
            },
            {
                "label": "चक सहनद 0530",
                "village_id": 18,
                "village_name": "चक सहनद"
            },
            {
                "label": "चक हकीम  0506",
                "village_id": 19,
                "village_name": "चक हकीम "
            },
            {
                "label": "चकअजनी 0528",
                "village_id": 20,
                "village_name": "चकअजनी"
            },
            {
                "label": "चकबल 0481",
                "village_id": 21,
                "village_name": "चकबल"
            },
            {
                "label": "चकवली 0540",
                "village_id": 22,
                "village_name": "चकवली"
            },
            {
                "label": "छपकी 0457",
                "village_id": 23,
                "village_name": "छपकी"
            },
            {
                "label": "जेन्‍हरा 0551",
                "village_id": 24,
                "village_name": "जेन्‍हरा"
            },
            {
                "label": "जेमहारा 0536",
                "village_id": 25,
                "village_name": "जेमहारा"
            },
            {
                "label": "जमालपुर 0545",
                "village_id": 26,
                "village_name": "जमालपुर"
            },
            {
                "label": "ठकुरीचक 0480",
                "village_id": 27,
                "village_name": "ठकुरीचक"
            },
            {
                "label": "तिलारथ 0521",
                "village_id": 28,
                "village_name": "तिलारथ"
            },
            {
                "label": "देवना 0535",
                "village_id": 29,
                "village_name": "देवना"
            },
            {
                "label": "देवना 0533",
                "village_id": 30,
                "village_name": "देवना"
            },
            {
                "label": "देहपुर 0524",
                "village_id": 31,
                "village_name": "देहपुर"
            },
            {
                "label": "दालीपुर 0522",
                "village_id": 32,
                "village_name": "दालीपुर"
            },
            {
                "label": "दौलतपुर 0555",
                "village_id": 33,
                "village_name": "दौलतपुर"
            },
            {
                "label": "नुरपुर 0537",
                "village_id": 34,
                "village_name": "नुरपुर"
            },
            {
                "label": "नारायणपुर 0538",
                "village_id": 35,
                "village_name": "नारायणपुर"
            },
            {
                "label": "नारायणपुर 0543",
                "village_id": 36,
                "village_name": "नारायणपुर"
            },
            {
                "label": "नारायणपुर 0544",
                "village_id": 37,
                "village_name": "नारायणपुर"
            },
            {
                "label": "नारायणपुर 0547",
                "village_id": 38,
                "village_name": "नारायणपुर"
            },
            {
                "label": "नींगा 0517",
                "village_id": 39,
                "village_name": "नींगा"
            },
            {
                "label": "पपरौर 0507",
                "village_id": 40,
                "village_name": "पपरौर"
            },
            {
                "label": "पपरौर 0509",
                "village_id": 41,
                "village_name": "पपरौर"
            },
            {
                "label": "पीपरा देवरु 0484",
                "village_id": 42,
                "village_name": "पीपरा देवरु"
            },
            {
                "label": "पीपरा देवरु 0473",
                "village_id": 43,
                "village_name": "पीपरा देवरु"
            },
            {
                "label": "फतेहपुर 0527",
                "village_id": 44,
                "village_name": "फतेहपुर"
            },
            {
                "label": "बखतपुर 0512",
                "village_id": 45,
                "village_name": "बखतपुर"
            },
            {
                "label": "बथौली 0511",
                "village_id": 46,
                "village_name": "बथौली"
            },
            {
                "label": "बभनगामा 0455",
                "village_id": 47,
                "village_name": "बभनगामा"
            },
            {
                "label": "बाघ मिर्जापुर 0458",
                "village_id": 48,
                "village_name": "बाघ मिर्जापुर"
            },
            {
                "label": "बीहट 0504",
                "village_id": 49,
                "village_name": "बीहट"
            },
            {
                "label": "भभौर 0548",
                "village_id": 50,
                "village_name": "भभौर"
            },
            {
                "label": "मकरदही 0559",
                "village_id": 51,
                "village_name": "मकरदही"
            },
            {
                "label": "मखापुर 0534",
                "village_id": 52,
                "village_name": "मखापुर"
            },
            {
                "label": "मलहीपुर 0503",
                "village_id": 53,
                "village_name": "मलहीपुर"
            },
            {
                "label": "महना 0542",
                "village_id": 54,
                "village_name": "महना"
            },
            {
                "label": "महम्‍दपुर बारो 0492",
                "village_id": 55,
                "village_name": "महम्‍दपुर बारो"
            },
            {
                "label": "महम्‍दपुर बिरो 0485",
                "village_id": 56,
                "village_name": "महम्‍दपुर बिरो"
            },
            {
                "label": "महम्‍मदपुर 0474",
                "village_id": 57,
                "village_name": "महम्‍मदपुर"
            },
            {
                "label": "महम्‍मदपुर बारो 0494",
                "village_id": 58,
                "village_name": "महम्‍मदपुर बारो"
            },
            {
                "label": "महम्‍मदपुर बारो 0484",
                "village_id": 59,
                "village_name": "महम्‍मदपुर बारो"
            },
            {
                "label": "मालती 0505",
                "village_id": 60,
                "village_name": "मालती"
            },
            {
                "label": "मालती 0483",
                "village_id": 61,
                "village_name": "मालती"
            },
            {
                "label": "मालती 0384",
                "village_id": 62,
                "village_name": "मालती"
            },
            {
                "label": "मालती 0477",
                "village_id": 63,
                "village_name": "मालती"
            },
            {
                "label": "मालती साईस्‍तावाद 0381",
                "village_id": 64,
                "village_name": "मालती साईस्‍तावाद"
            },
            {
                "label": "मिर्जापुर 0516",
                "village_id": 65,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "मोबारकपुर 0459",
                "village_id": 66,
                "village_name": "मोबारकपुर"
            },
            {
                "label": "मोसादपुर 0523",
                "village_id": 67,
                "village_name": "मोसादपुर"
            },
            {
                "label": "मौदा बभनगाम 0514",
                "village_id": 68,
                "village_name": "मौदा बभनगाम"
            },
            {
                "label": "रजौड़ा 0382",
                "village_id": 69,
                "village_name": "रजौड़ा"
            },
            {
                "label": "रजौड़ा 0379",
                "village_id": 70,
                "village_name": "रजौड़ा"
            },
            {
                "label": "रजौड़ा भगवानपुर 0476",
                "village_id": 71,
                "village_name": "रजौड़ा भगवानपुर"
            },
            {
                "label": "रूपसपुर 0553",
                "village_id": 72,
                "village_name": "रूपसपुर"
            },
            {
                "label": "रामपुर बहुआरा 0460",
                "village_id": 73,
                "village_name": "रामपुर बहुआरा"
            },
            {
                "label": "रिसालदपुर 0495",
                "village_id": 74,
                "village_name": "रिसालदपुर"
            },
            {
                "label": "रिसालदपुर 0496",
                "village_id": 75,
                "village_name": "रिसालदपुर"
            },
            {
                "label": "लगौली 0520",
                "village_id": 76,
                "village_name": "लगौली"
            },
            {
                "label": "लदौड़ा 0518",
                "village_id": 77,
                "village_name": "लदौड़ा"
            },
            {
                "label": "विशनपुर चांद 0541",
                "village_id": 78,
                "village_name": "विशनपुर चांद"
            },
            {
                "label": "सुगराहा 0383",
                "village_id": 79,
                "village_name": "सुगराहा"
            },
            {
                "label": "सैदपुर 0531",
                "village_id": 80,
                "village_name": "सैदपुर"
            },
            {
                "label": "सैदपुर हांस 0529",
                "village_id": 81,
                "village_name": "सैदपुर हांस"
            },
            {
                "label": "सुल्‍तानपुर 0513",
                "village_id": 82,
                "village_name": "सुल्‍तानपुर"
            },
            {
                "label": "सलीमपुर 0491",
                "village_id": 83,
                "village_name": "सलीमपुर"
            },
            {
                "label": "सलीमपुर बारो 0487",
                "village_id": 84,
                "village_name": "सलीमपुर बारो"
            },
            {
                "label": "सलीमपुर बारो 0488",
                "village_id": 85,
                "village_name": "सलीमपुर बारो"
            },
            {
                "label": "सवौरा 0554",
                "village_id": 86,
                "village_name": "सवौरा"
            },
            {
                "label": "सवौरा 0549",
                "village_id": 87,
                "village_name": "सवौरा"
            },
            {
                "label": "सवौरा 0550",
                "village_id": 88,
                "village_name": "सवौरा"
            },
            {
                "label": "सहुरी 0515",
                "village_id": 89,
                "village_name": "सहुरी"
            },
            {
                "label": "सिंगदाहा 0510",
                "village_id": 90,
                "village_name": "सिंगदाहा"
            },
            {
                "label": "सिमरिया 0498",
                "village_id": 91,
                "village_name": "सिमरिया"
            },
            {
                "label": "सिमरिया 0499",
                "village_id": 92,
                "village_name": "सिमरिया"
            },
            {
                "label": "सिमरिया 0500",
                "village_id": 93,
                "village_name": "सिमरिया"
            },
            {
                "label": "सिमरिया 0501",
                "village_id": 94,
                "village_name": "सिमरिया"
            },
            {
                "label": "सिमरौन 0519",
                "village_id": 95,
                "village_name": "सिमरौन"
            },
            {
                "label": "सिसवा 0558",
                "village_id": 96,
                "village_name": "सिसवा"
            },
            {
                "label": "सोगराहा 0478",
                "village_id": 97,
                "village_name": "सोगराहा"
            },
            {
                "label": "सोगराहा 0479",
                "village_id": 98,
                "village_name": "सोगराहा"
            },
            {
                "label": "हरपुर 0526",
                "village_id": 99,
                "village_name": "हरपुर"
            },
            {
                "label": "हवासपुर 0508",
                "village_id": 100,
                "village_name": "हवासपुर"
            },
            {
                "label": "हाजीपुर 0482",
                "village_id": 101,
                "village_name": "हाजीपुर"
            },
            {
                "label": "हाजीपुर 0380",
                "village_id": 102,
                "village_name": "हाजीपुर"
            },
            {
                "label": "हाजीपुर 0475",
                "village_id": 103,
                "village_name": "हाजीपुर"
            },
            {
                "label": "हाजीपुर 0377",
                "village_id": 104,
                "village_name": "हाजीपुर"
            },
            {
                "label": "हाजीपुर 0378",
                "village_id": 105,
                "village_name": "हाजीपुर"
            }
        ],
        "block_id": "2",
        "block_no": 1,
        "block_name": "बरौनी [002]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अराजी पानापुर 0446",
                "village_id": 2,
                "village_name": "अराजी पानापुर"
            },
            {
                "label": "गेन्‍हरपुर 0408",
                "village_id": 3,
                "village_name": "गेन्‍हरपुर"
            },
            {
                "label": "गोपालपुर 0417",
                "village_id": 4,
                "village_name": "गोपालपुर"
            },
            {
                "label": "गोविन्‍दपुर रामपुर 0464",
                "village_id": 5,
                "village_name": "गोविन्‍दपुर रामपुर"
            },
            {
                "label": "गोविन्‍दपुर रामपुर 0433",
                "village_id": 6,
                "village_name": "गोविन्‍दपुर रामपुर"
            },
            {
                "label": "जगदर 0467",
                "village_id": 7,
                "village_name": "जगदर"
            },
            {
                "label": "डीह 0424",
                "village_id": 8,
                "village_name": "डीह"
            },
            {
                "label": "नौला 0419",
                "village_id": 9,
                "village_name": "नौला"
            },
            {
                "label": "पकरी रसुलपुर 0436",
                "village_id": 10,
                "village_name": "पकरी रसुलपुर"
            },
            {
                "label": "पकरी रसुलपुर 0437",
                "village_id": 11,
                "village_name": "पकरी रसुलपुर"
            },
            {
                "label": "पर्रा 0461",
                "village_id": 12,
                "village_name": "पर्रा"
            },
            {
                "label": "पानापुर 0438",
                "village_id": 13,
                "village_name": "पानापुर"
            },
            {
                "label": "पानापुर 0439",
                "village_id": 14,
                "village_name": "पानापुर"
            },
            {
                "label": "पानापुर 0440",
                "village_id": 15,
                "village_name": "पानापुर"
            },
            {
                "label": "पानापुर 0441",
                "village_id": 16,
                "village_name": "पानापुर"
            },
            {
                "label": "पानापुर 0442",
                "village_id": 17,
                "village_name": "पानापुर"
            },
            {
                "label": "पानापुर 0444",
                "village_id": 18,
                "village_name": "पानापुर"
            },
            {
                "label": "पिपरादोदराज 0434",
                "village_id": 19,
                "village_name": "पिपरादोदराज"
            },
            {
                "label": "फजिलपुर 0465",
                "village_id": 20,
                "village_name": "फजिलपुर"
            },
            {
                "label": "फजिलपुर 0466",
                "village_id": 21,
                "village_name": "फजिलपुर"
            },
            {
                "label": "बरैपुरा 0430",
                "village_id": 22,
                "village_name": "बरैपुरा"
            },
            {
                "label": "भवानंदपुर 0445",
                "village_id": 23,
                "village_name": "भवानंदपुर"
            },
            {
                "label": "मुजफ्फरा 0443",
                "village_id": 24,
                "village_name": "मुजफ्फरा"
            },
            {
                "label": "मुरादपुर 0463",
                "village_id": 25,
                "village_name": "मुरादपुर"
            },
            {
                "label": "राजापुर 0447",
                "village_id": 26,
                "village_name": "राजापुर"
            },
            {
                "label": "राजापुर 0448",
                "village_id": 27,
                "village_name": "राजापुर"
            },
            {
                "label": "राजापुर 0449",
                "village_id": 28,
                "village_name": "राजापुर"
            },
            {
                "label": "राजापुर 0450",
                "village_id": 29,
                "village_name": "राजापुर"
            },
            {
                "label": "राजापुर 0451",
                "village_id": 30,
                "village_name": "राजापुर"
            },
            {
                "label": "राजापुर 0452",
                "village_id": 31,
                "village_name": "राजापुर"
            },
            {
                "label": "लक्ष्‍मीपुर 0462",
                "village_id": 32,
                "village_name": "लक्ष्‍मीपुर"
            },
            {
                "label": "वहवलपुर 0432",
                "village_id": 33,
                "village_name": "वहवलपुर"
            },
            {
                "label": "वीरपुर 0435",
                "village_id": 34,
                "village_name": "वीरपुर"
            },
            {
                "label": "सकुरहुला 0453",
                "village_id": 35,
                "village_name": "सकुरहुला"
            },
            {
                "label": "सरौजा 0454",
                "village_id": 36,
                "village_name": "सरौजा"
            },
            {
                "label": "हरिहरपुर 0431",
                "village_id": 37,
                "village_name": "हरिहरपुर"
            }
        ],
        "block_id": "3",
        "block_no": 2,
        "block_name": "वीरपुर [003]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अराजी भवानन्‍दपुर 0417",
                "village_id": 2,
                "village_name": "अराजी भवानन्‍दपुर"
            },
            {
                "label": "कुरुम टोली 0542",
                "village_id": 3,
                "village_name": "कुरुम टोली"
            },
            {
                "label": "काशिमपुर 0546",
                "village_id": 4,
                "village_name": "काशिमपुर"
            },
            {
                "label": "कासिमपुर 0547",
                "village_id": 5,
                "village_name": "कासिमपुर"
            },
            {
                "label": "खड़गपुर 0544",
                "village_id": 6,
                "village_name": "खड़गपुर"
            },
            {
                "label": "खड़हूवा टोला 0536",
                "village_id": 7,
                "village_name": "खड़हूवा टोला"
            },
            {
                "label": "खोरमपुर 0500",
                "village_id": 8,
                "village_name": "खोरमपुर"
            },
            {
                "label": "गड़ेरी टोल 0545",
                "village_id": 9,
                "village_name": "गड़ेरी टोल"
            },
            {
                "label": "गोदर गांवां 0508",
                "village_id": 10,
                "village_name": "गोदर गांवां"
            },
            {
                "label": "गोरगामा 0541",
                "village_id": 11,
                "village_name": "गोरगामा"
            },
            {
                "label": "चकभेलु 0490",
                "village_id": 12,
                "village_name": "चकभेलु"
            },
            {
                "label": "चाक 0489",
                "village_id": 13,
                "village_name": "चाक"
            },
            {
                "label": "छितरौर 0501",
                "village_id": 14,
                "village_name": "छितरौर"
            },
            {
                "label": "जलालपुर 0499",
                "village_id": 15,
                "village_name": "जलालपुर"
            },
            {
                "label": "जाफरपुर 0429",
                "village_id": 16,
                "village_name": "जाफरपुर"
            },
            {
                "label": "धनुकटोली 0539",
                "village_id": 17,
                "village_name": "धनुकटोली"
            },
            {
                "label": "नयागांव 0491",
                "village_id": 18,
                "village_name": "नयागांव"
            },
            {
                "label": "पथुआ बरारी 0431",
                "village_id": 19,
                "village_name": "पथुआ बरारी"
            },
            {
                "label": "बखड़ा 0507",
                "village_id": 20,
                "village_name": "बखड़ा"
            },
            {
                "label": "बरियारपुर  0511",
                "village_id": 21,
                "village_name": "बरियारपुर "
            },
            {
                "label": "बलहपुर 0492",
                "village_id": 22,
                "village_name": "बलहपुर"
            },
            {
                "label": "बागडोम 0549",
                "village_id": 23,
                "village_name": "बागडोम"
            },
            {
                "label": "मटिहानी 0502",
                "village_id": 24,
                "village_name": "मटिहानी"
            },
            {
                "label": "मनिअप्‍पा 0506",
                "village_id": 25,
                "village_name": "मनिअप्‍पा"
            },
            {
                "label": "महेन्‍द्रपुर 0548",
                "village_id": 26,
                "village_name": "महेन्‍द्रपुर"
            },
            {
                "label": "महेन्‍द्रपुर 0554",
                "village_id": 27,
                "village_name": "महेन्‍द्रपुर"
            },
            {
                "label": "महम्‍दपुर गौतम 0514",
                "village_id": 28,
                "village_name": "महम्‍दपुर गौतम"
            },
            {
                "label": "महम्‍मदपुर मणी 0513",
                "village_id": 29,
                "village_name": "महम्‍मदपुर मणी"
            },
            {
                "label": "मीनापुर 0512",
                "village_id": 30,
                "village_name": "मीनापुर"
            },
            {
                "label": "रामदीरी 0421",
                "village_id": 31,
                "village_name": "रामदीरी"
            },
            {
                "label": "रामदीरी 0422",
                "village_id": 32,
                "village_name": "रामदीरी"
            },
            {
                "label": "रामदीरी 0418",
                "village_id": 33,
                "village_name": "रामदीरी"
            },
            {
                "label": "रामदीरी 0419",
                "village_id": 34,
                "village_name": "रामदीरी"
            },
            {
                "label": "रामदीरी 0420",
                "village_id": 35,
                "village_name": "रामदीरी"
            },
            {
                "label": "रामदीरी 0423",
                "village_id": 36,
                "village_name": "रामदीरी"
            },
            {
                "label": "रामदीरी 0424",
                "village_id": 37,
                "village_name": "रामदीरी"
            },
            {
                "label": "रामनगर 0553",
                "village_id": 38,
                "village_name": "रामनगर"
            },
            {
                "label": "रामपुर बसवन 0495",
                "village_id": 39,
                "village_name": "रामपुर बसवन"
            },
            {
                "label": "लाल दियारा 0555",
                "village_id": 40,
                "village_name": "लाल दियारा"
            },
            {
                "label": "लालपुर 0516",
                "village_id": 41,
                "village_name": "लालपुर"
            },
            {
                "label": "शेरपुर 0540",
                "village_id": 42,
                "village_name": "शेरपुर"
            },
            {
                "label": "शिरनियां 0488",
                "village_id": 43,
                "village_name": "शिरनियां"
            },
            {
                "label": "शिरनियां बरारी 0430",
                "village_id": 44,
                "village_name": "शिरनियां बरारी"
            },
            {
                "label": "सैदपुर ऐमा 0494",
                "village_id": 45,
                "village_name": "सैदपुर ऐमा"
            },
            {
                "label": "सैदपुर ऐमा 0497",
                "village_id": 46,
                "village_name": "सैदपुर ऐमा"
            },
            {
                "label": "सैदपुर खालस 0496",
                "village_id": 47,
                "village_name": "सैदपुर खालस"
            },
            {
                "label": "सैदपुर खालसा 0498",
                "village_id": 48,
                "village_name": "सैदपुर खालसा"
            },
            {
                "label": "सैदपुर खालसा  0493",
                "village_id": 49,
                "village_name": "सैदपुर खालसा "
            },
            {
                "label": "सफापुर 0515",
                "village_id": 50,
                "village_name": "सफापुर"
            },
            {
                "label": "सिंहपुर 0486",
                "village_id": 51,
                "village_name": "सिंहपुर"
            },
            {
                "label": "सिहमा करारी  0427",
                "village_id": 52,
                "village_name": "सिहमा करारी "
            },
            {
                "label": "सिहमा बरारी 0428",
                "village_id": 53,
                "village_name": "सिहमा बरारी"
            },
            {
                "label": "सिहमा वन्‍दोवस्‍ती  0426",
                "village_id": 54,
                "village_name": "सिहमा वन्‍दोवस्‍ती "
            },
            {
                "label": "सोनापुर 0510",
                "village_id": 55,
                "village_name": "सोनापुर"
            },
            {
                "label": "हेतमपुर 0537",
                "village_id": 56,
                "village_name": "हेतमपुर"
            },
            {
                "label": "हेतमपुर 0538",
                "village_id": 57,
                "village_name": "हेतमपुर"
            },
            {
                "label": "हेतमपुर 0543",
                "village_id": 58,
                "village_name": "हेतमपुर"
            },
            {
                "label": "हांसपुर 0509",
                "village_id": 59,
                "village_name": "हांसपुर"
            }
        ],
        "block_id": "4",
        "block_no": 3,
        "block_name": "मटिहानी [004]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अकबरपुर 0458",
                "village_id": 2,
                "village_name": "अकबरपुर"
            },
            {
                "label": "अकबरपुर 0460",
                "village_id": 3,
                "village_name": "अकबरपुर"
            },
            {
                "label": "अकबरपुर 0462",
                "village_id": 4,
                "village_name": "अकबरपुर"
            },
            {
                "label": "अकबरपुर 0465",
                "village_id": 5,
                "village_name": "अकबरपुर"
            },
            {
                "label": "अकबरपुर करारी 0437",
                "village_id": 6,
                "village_name": "अकबरपुर करारी"
            },
            {
                "label": "अकबरपुर बरारी 0434",
                "village_id": 7,
                "village_name": "अकबरपुर बरारी"
            },
            {
                "label": "अकबरपुर बरारी 0435",
                "village_id": 8,
                "village_name": "अकबरपुर बरारी"
            },
            {
                "label": "गुहिया 0446",
                "village_id": 9,
                "village_name": "गुहिया"
            },
            {
                "label": "जगन सैदपुर 0432",
                "village_id": 10,
                "village_name": "जगन सैदपुर"
            },
            {
                "label": "जगन सैदपुर 0475",
                "village_id": 11,
                "village_name": "जगन सैदपुर"
            },
            {
                "label": "जगनसेदपुर 0471",
                "village_id": 12,
                "village_name": "जगनसेदपुर"
            },
            {
                "label": "जगनसेदपुर 0438",
                "village_id": 13,
                "village_name": "जगनसेदपुर"
            },
            {
                "label": "जगनसेदपुर 0443",
                "village_id": 14,
                "village_name": "जगनसेदपुर"
            },
            {
                "label": "जगनसेदपुर 0444",
                "village_id": 15,
                "village_name": "जगनसेदपुर"
            },
            {
                "label": "जगनसेदपुर 0457",
                "village_id": 16,
                "village_name": "जगनसेदपुर"
            },
            {
                "label": "जगनसेदपुर 0485",
                "village_id": 17,
                "village_name": "जगनसेदपुर"
            },
            {
                "label": "जगनसेदपुर 0449",
                "village_id": 18,
                "village_name": "जगनसेदपुर"
            },
            {
                "label": "जगनसेदपुर 0456",
                "village_id": 19,
                "village_name": "जगनसेदपुर"
            },
            {
                "label": "जगनसेदपुर 0463",
                "village_id": 20,
                "village_name": "जगनसेदपुर"
            },
            {
                "label": "जगनसेदपुर 0464",
                "village_id": 21,
                "village_name": "जगनसेदपुर"
            },
            {
                "label": "जगनसेदपुर 0467",
                "village_id": 22,
                "village_name": "जगनसेदपुर"
            },
            {
                "label": "जीतवारपुर 0452",
                "village_id": 23,
                "village_name": "जीतवारपुर"
            },
            {
                "label": "पथुआ 0481",
                "village_id": 24,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0482",
                "village_id": 25,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0483",
                "village_id": 26,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0484",
                "village_id": 27,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0472",
                "village_id": 28,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0476",
                "village_id": 29,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0479",
                "village_id": 30,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0468",
                "village_id": 31,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0470",
                "village_id": 32,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0451",
                "village_id": 33,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0453",
                "village_id": 34,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0466",
                "village_id": 35,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ 0441",
                "village_id": 36,
                "village_name": "पथुआ"
            },
            {
                "label": "पथुआ़ 0454",
                "village_id": 37,
                "village_name": "पथुआ़"
            },
            {
                "label": "पिपरिया 0473",
                "village_id": 38,
                "village_name": "पिपरिया"
            },
            {
                "label": "बादशाहपुर 0442",
                "village_id": 39,
                "village_name": "बादशाहपुर"
            },
            {
                "label": "लघौना 0459",
                "village_id": 40,
                "village_name": "लघौना"
            },
            {
                "label": "शाम्‍हो 0440",
                "village_id": 41,
                "village_name": "शाम्‍हो"
            },
            {
                "label": "शाम्‍हो 0448",
                "village_id": 42,
                "village_name": "शाम्‍हो"
            },
            {
                "label": "सतकुरवा 0433",
                "village_id": 43,
                "village_name": "सतकुरवा"
            },
            {
                "label": "सतकुरवा 0550",
                "village_id": 44,
                "village_name": "सतकुरवा"
            },
            {
                "label": "सैदपुर सलहा 0474",
                "village_id": 45,
                "village_name": "सैदपुर सलहा"
            },
            {
                "label": "सैदपुर सलहा 0480",
                "village_id": 46,
                "village_name": "सैदपुर सलहा"
            },
            {
                "label": "सैदपुर सलहा 0477",
                "village_id": 47,
                "village_name": "सैदपुर सलहा"
            },
            {
                "label": "सैदपुर सलहा 0450",
                "village_id": 48,
                "village_name": "सैदपुर सलहा"
            },
            {
                "label": "सैदपुर सलहा बरारी 0436",
                "village_id": 49,
                "village_name": "सैदपुर सलहा बरारी"
            },
            {
                "label": "सलहा सैदपुर कारारी 0551",
                "village_id": 50,
                "village_name": "सलहा सैदपुर कारारी"
            },
            {
                "label": "सोनवर्षा 0552",
                "village_id": 51,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "सोनवर्षा 0455",
                "village_id": 52,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "सोनवर्षा 0447",
                "village_id": 53,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "सोनवर्षा 0461",
                "village_id": 54,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "सोनवर्षा 0445",
                "village_id": 55,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "सोनवर्षा 0439",
                "village_id": 56,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "सोनवर्षा 0469",
                "village_id": 57,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "सोनवर्षा 0478",
                "village_id": 58,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "हेमरपुर 0487",
                "village_id": 59,
                "village_name": "हेमरपुर"
            }
        ],
        "block_id": "5",
        "block_no": 4,
        "block_name": "शाम्‍हो अकहा कुरहा [005]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अराजी भगतपुर 0620",
                "village_id": 2,
                "village_name": "अराजी भगतपुर"
            },
            {
                "label": "कुतलुपुर 0528",
                "village_id": 3,
                "village_name": "कुतलुपुर"
            },
            {
                "label": "कदम रसुल 0322",
                "village_id": 4,
                "village_name": "कदम रसुल"
            },
            {
                "label": "कदम रसुल 0323",
                "village_id": 5,
                "village_name": "कदम रसुल"
            },
            {
                "label": "कदम रसुल 0324",
                "village_id": 6,
                "village_name": "कदम रसुल"
            },
            {
                "label": "कदम रसुल 0319",
                "village_id": 7,
                "village_name": "कदम रसुल"
            },
            {
                "label": "कमलापुर 0601",
                "village_id": 8,
                "village_name": "कमलापुर"
            },
            {
                "label": "काजीचक 0596",
                "village_id": 9,
                "village_name": "काजीचक"
            },
            {
                "label": "काजीचक 0619",
                "village_id": 10,
                "village_name": "काजीचक"
            },
            {
                "label": "काजीचक 0615",
                "village_id": 11,
                "village_name": "काजीचक"
            },
            {
                "label": "काजीचक 0566",
                "village_id": 12,
                "village_name": "काजीचक"
            },
            {
                "label": "काजीचक 0585",
                "village_id": 13,
                "village_name": "काजीचक"
            },
            {
                "label": "काजीचक 0591",
                "village_id": 14,
                "village_name": "काजीचक"
            },
            {
                "label": "काजीचक 0557",
                "village_id": 15,
                "village_name": "काजीचक"
            },
            {
                "label": "काशीमपुर 0607",
                "village_id": 16,
                "village_name": "काशीमपुर"
            },
            {
                "label": "किशनपुर 0598",
                "village_id": 17,
                "village_name": "किशनपुर"
            },
            {
                "label": "किशनपुर 0602",
                "village_id": 18,
                "village_name": "किशनपुर"
            },
            {
                "label": "किशनपुर 0595",
                "village_id": 19,
                "village_name": "किशनपुर"
            },
            {
                "label": "कोहवा 0569",
                "village_id": 20,
                "village_name": "कोहवा"
            },
            {
                "label": "गोविन्‍दपुर मुशहरी 0559",
                "village_id": 21,
                "village_name": "गोविन्‍दपुर मुशहरी"
            },
            {
                "label": "गोविन्‍दपुर हजारी 0564",
                "village_id": 22,
                "village_name": "गोविन्‍दपुर हजारी"
            },
            {
                "label": "चक कुतबुद्धीन 0306",
                "village_id": 23,
                "village_name": "चक कुतबुद्धीन"
            },
            {
                "label": "चक कमालपुर 0604",
                "village_id": 24,
                "village_name": "चक कमालपुर"
            },
            {
                "label": "चकैमा 0594",
                "village_id": 25,
                "village_name": "चकैमा"
            },
            {
                "label": "चकशरीफ 0329",
                "village_id": 26,
                "village_name": "चकशरीफ"
            },
            {
                "label": "छौरसिया 0563",
                "village_id": 27,
                "village_name": "छौरसिया"
            },
            {
                "label": "जनार्दनपुर 0612",
                "village_id": 28,
                "village_name": "जनार्दनपुर"
            },
            {
                "label": "ताजपुर 0609",
                "village_id": 29,
                "village_name": "ताजपुर"
            },
            {
                "label": "दनौली 0333",
                "village_id": 30,
                "village_name": "दनौली"
            },
            {
                "label": "दौन्‍दी चक 0309",
                "village_id": 31,
                "village_name": "दौन्‍दी चक"
            },
            {
                "label": "नगरगामा 0614",
                "village_id": 32,
                "village_name": "नगरगामा"
            },
            {
                "label": "नथुलापुर 0571",
                "village_id": 33,
                "village_name": "नथुलापुर"
            },
            {
                "label": "नुरजमापुर 0307",
                "village_id": 34,
                "village_name": "नुरजमापुर"
            },
            {
                "label": "परमानन्‍दपुर 0584",
                "village_id": 35,
                "village_name": "परमानन्‍दपुर"
            },
            {
                "label": "पहाड़पुर 0575",
                "village_id": 36,
                "village_name": "पहाड़पुर"
            },
            {
                "label": "पहाड़पुर 0616",
                "village_id": 37,
                "village_name": "पहाड़पुर"
            },
            {
                "label": "पोखरिया 0330",
                "village_id": 38,
                "village_name": "पोखरिया"
            },
            {
                "label": "फतेहपुर 0532",
                "village_id": 39,
                "village_name": "फतेहपुर"
            },
            {
                "label": "फुलवरिया 0327",
                "village_id": 40,
                "village_name": "फुलवरिया"
            },
            {
                "label": "बड़ी बलिया 0325",
                "village_id": 41,
                "village_name": "बड़ी बलिया"
            },
            {
                "label": "बरियारपुर 0533",
                "village_id": 42,
                "village_name": "बरियारपुर"
            },
            {
                "label": "बलिया बुजुर्ग 0313",
                "village_id": 43,
                "village_name": "बलिया बुजुर्ग"
            },
            {
                "label": "बलिया-लखमिनियां 0627",
                "village_id": 44,
                "village_name": "बलिया-लखमिनियां"
            },
            {
                "label": "बहाउदीनपुर 0623",
                "village_id": 45,
                "village_name": "बहाउदीनपुर"
            },
            {
                "label": "बहाउदीनपुर मिल्‍की 0622",
                "village_id": 46,
                "village_name": "बहाउदीनपुर मिल्‍की"
            },
            {
                "label": "बालाचक 0320",
                "village_id": 47,
                "village_name": "बालाचक"
            },
            {
                "label": "भगतपुर 0621",
                "village_id": 48,
                "village_name": "भगतपुर"
            },
            {
                "label": "भगवानपुर 0574",
                "village_id": 49,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भवानन्‍दपुर 0576",
                "village_id": 50,
                "village_name": "भवानन्‍दपुर"
            },
            {
                "label": "मकसपुर 0534",
                "village_id": 51,
                "village_name": "मकसपुर"
            },
            {
                "label": "मझनपुर 0332",
                "village_id": 52,
                "village_name": "मझनपुर"
            },
            {
                "label": "मनसेरपुर 0618",
                "village_id": 53,
                "village_name": "मनसेरपुर"
            },
            {
                "label": "ममरेजपुर 0573",
                "village_id": 54,
                "village_name": "ममरेजपुर"
            },
            {
                "label": "मसूदनपुर 0670",
                "village_id": 55,
                "village_name": "मसूदनपुर"
            },
            {
                "label": "मुसहरी 0560",
                "village_id": 56,
                "village_name": "मुसहरी"
            },
            {
                "label": "मिर सिकारचक 0328",
                "village_id": 57,
                "village_name": "मिर सिकारचक"
            },
            {
                "label": "मिरअलीपुर 0611",
                "village_id": 58,
                "village_name": "मिरअलीपुर"
            },
            {
                "label": "मिर्जापुर 0597",
                "village_id": 59,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "मिरसिकारचक 0318",
                "village_id": 60,
                "village_name": "मिरसिकारचक"
            },
            {
                "label": "मोहनपुर 0668",
                "village_id": 61,
                "village_name": "मोहनपुर"
            },
            {
                "label": "मोही बुल्‍लाह चक 0316",
                "village_id": 62,
                "village_name": "मोही बुल्‍लाह चक"
            },
            {
                "label": "रुद्रपुर 0314",
                "village_id": 63,
                "village_name": "रुद्रपुर"
            },
            {
                "label": "रुद्रपुर 0312",
                "village_id": 64,
                "village_name": "रुद्रपुर"
            },
            {
                "label": "रसुलपुर 0610",
                "village_id": 65,
                "village_name": "रसुलपुर"
            },
            {
                "label": "रसीदपुर 0321",
                "village_id": 66,
                "village_name": "रसीदपुर"
            },
            {
                "label": "रामदतपुर 0572",
                "village_id": 67,
                "village_name": "रामदतपुर"
            },
            {
                "label": "रामनाथपुर 0568",
                "village_id": 68,
                "village_name": "रामनाथपुर"
            },
            {
                "label": "रामपुर 0531",
                "village_id": 69,
                "village_name": "रामपुर"
            },
            {
                "label": "राहटपुर 0535",
                "village_id": 70,
                "village_name": "राहटपुर"
            },
            {
                "label": "वाजीतपुर 0556",
                "village_id": 71,
                "village_name": "वाजीतपुर"
            },
            {
                "label": "वासुदेवपुर 0561",
                "village_id": 72,
                "village_name": "वासुदेवपुर"
            },
            {
                "label": "वासुदेवपुर 0565",
                "village_id": 73,
                "village_name": "वासुदेवपुर"
            },
            {
                "label": "वासुदेवपुर 0579",
                "village_id": 74,
                "village_name": "वासुदेवपुर"
            },
            {
                "label": "वासुदेवपुर 0580",
                "village_id": 75,
                "village_name": "वासुदेवपुर"
            },
            {
                "label": "वासुदेवपुर 0590",
                "village_id": 76,
                "village_name": "वासुदेवपुर"
            },
            {
                "label": "विशनपुर 0605",
                "village_id": 77,
                "village_name": "विशनपुर"
            },
            {
                "label": "वीरपुर 0570",
                "village_id": 78,
                "village_name": "वीरपुर"
            },
            {
                "label": "शेख डीह कसवा 0558",
                "village_id": 79,
                "village_name": "शेख डीह कसवा"
            },
            {
                "label": "शेख डीह कसवा 0562",
                "village_id": 80,
                "village_name": "शेख डीह कसवा"
            },
            {
                "label": "शेख डीह कसवा 0586",
                "village_id": 81,
                "village_name": "शेख डीह कसवा"
            },
            {
                "label": "शेख डीह कसवा 0589",
                "village_id": 82,
                "village_name": "शेख डीह कसवा"
            },
            {
                "label": "शेख डीह कसवा 0592",
                "village_id": 83,
                "village_name": "शेख डीह कसवा"
            },
            {
                "label": "शेख डीह कसवा 0578",
                "village_id": 84,
                "village_name": "शेख डीह कसवा"
            },
            {
                "label": "शाहपुर 0669",
                "village_id": 85,
                "village_name": "शाहपुर"
            },
            {
                "label": "सैदपुर 0613",
                "village_id": 86,
                "village_name": "सैदपुर"
            },
            {
                "label": "सदरपुर 0624",
                "village_id": 87,
                "village_name": "सदरपुर"
            },
            {
                "label": "सदानन्‍दपुर 0530",
                "village_id": 88,
                "village_name": "सदानन्‍दपुर"
            },
            {
                "label": "सादीपुर 0608",
                "village_id": 89,
                "village_name": "सादीपुर"
            },
            {
                "label": "सालेहचक 0317",
                "village_id": 90,
                "village_name": "सालेहचक"
            },
            {
                "label": "सालेहचक 0310",
                "village_id": 91,
                "village_name": "सालेहचक"
            },
            {
                "label": "सालेहचक 0308",
                "village_id": 92,
                "village_name": "सालेहचक"
            },
            {
                "label": "साहवेगपुर 0577",
                "village_id": 93,
                "village_name": "साहवेगपुर"
            },
            {
                "label": "सिराजाबाद 0326",
                "village_id": 94,
                "village_name": "सिराजाबाद"
            },
            {
                "label": "सोनवर्षा 0582",
                "village_id": 95,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "सोनवर्षा 0587",
                "village_id": 96,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "सोहिलपुर 0529",
                "village_id": 97,
                "village_name": "सोहिलपुर"
            },
            {
                "label": "हेतमपुर 0617",
                "village_id": 98,
                "village_name": "हेतमपुर"
            },
            {
                "label": "हेतमपुर 0599",
                "village_id": 99,
                "village_name": "हेतमपुर"
            },
            {
                "label": "हनुमान नगर 0600",
                "village_id": 100,
                "village_name": "हनुमान नगर"
            },
            {
                "label": "हुसैन पत्‍ती 0588",
                "village_id": 101,
                "village_name": "हुसैन पत्‍ती"
            },
            {
                "label": "हसनपुर 0603",
                "village_id": 102,
                "village_name": "हसनपुर"
            },
            {
                "label": "हुसैनपुर 0581",
                "village_id": 103,
                "village_name": "हुसैनपुर"
            },
            {
                "label": "हुसैनपुर 0583",
                "village_id": 104,
                "village_name": "हुसैनपुर"
            },
            {
                "label": "हुसैना 0567",
                "village_id": 105,
                "village_name": "हुसैना"
            },
            {
                "label": "हुसैना 0593",
                "village_id": 106,
                "village_name": "हुसैना"
            },
            {
                "label": "हुसैना रामदीरी 0606",
                "village_id": 107,
                "village_name": "हुसैना रामदीरी"
            },
            {
                "label": "हुसैनी चक 0311",
                "village_id": 108,
                "village_name": "हुसैनी चक"
            },
            {
                "label": "हुसैनी चक 0315",
                "village_id": 109,
                "village_name": "हुसैनी चक"
            }
        ],
        "block_id": "6",
        "block_no": 5,
        "block_name": "बलिया [006]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अच्‍छेचक 0646",
                "village_id": 2,
                "village_name": "अच्‍छेचक"
            },
            {
                "label": "अराजी रजौड़ा 0633",
                "village_id": 3,
                "village_name": "अराजी रजौड़ा"
            },
            {
                "label": "कदम रसुल 0659",
                "village_id": 4,
                "village_name": "कदम रसुल"
            },
            {
                "label": "कल्‍याणपुर 0642",
                "village_id": 5,
                "village_name": "कल्‍याणपुर"
            },
            {
                "label": "गोविन्‍दपुर 0636",
                "village_id": 6,
                "village_name": "गोविन्‍दपुर"
            },
            {
                "label": "चौकी 0638",
                "village_id": 7,
                "village_name": "चौकी"
            },
            {
                "label": "जियानन्‍दपुर 0658",
                "village_id": 8,
                "village_name": "जियानन्‍दपुर"
            },
            {
                "label": "पंचवीर 0664",
                "village_id": 9,
                "village_name": "पंचवीर"
            },
            {
                "label": "परोड़ा 0671",
                "village_id": 10,
                "village_name": "परोड़ा"
            },
            {
                "label": "फुलमलिक 0655",
                "village_id": 11,
                "village_name": "फुलमलिक"
            },
            {
                "label": "मल्‍हदीपुर करारी 0654",
                "village_id": 12,
                "village_name": "मल्‍हदीपुर करारी"
            },
            {
                "label": "मल्‍हीपुर बरारी 0651",
                "village_id": 13,
                "village_name": "मल्‍हीपुर बरारी"
            },
            {
                "label": "मल्‍हीपुर बरारी 0652",
                "village_id": 14,
                "village_name": "मल्‍हीपुर बरारी"
            },
            {
                "label": "मोगलसराय 0665",
                "village_id": 15,
                "village_name": "मोगलसराय"
            },
            {
                "label": "रघुनाथ बरारी 0649",
                "village_id": 16,
                "village_name": "रघुनाथ बरारी"
            },
            {
                "label": "रघुनाथपुर करारी 0641",
                "village_id": 17,
                "village_name": "रघुनाथपुर करारी"
            },
            {
                "label": "रघुनाथपुर बरारी 0650",
                "village_id": 18,
                "village_name": "रघुनाथपुर बरारी"
            },
            {
                "label": "रजौड़ा फतेह उल्‍लाह 0634",
                "village_id": 19,
                "village_name": "रजौड़ा फतेह उल्‍लाह"
            },
            {
                "label": "रसलपुर 0657",
                "village_id": 20,
                "village_name": "रसलपुर"
            },
            {
                "label": "रहुआ 0640",
                "village_id": 21,
                "village_name": "रहुआ"
            },
            {
                "label": "रामपुर भीमल उर्फ बखड्डा 0626",
                "village_id": 22,
                "village_name": "रामपुर भीमल उर्फ बखड्डा"
            },
            {
                "label": "विशनपुर आहोक 0637",
                "village_id": 23,
                "village_name": "विशनपुर आहोक"
            },
            {
                "label": "शेरपुर 0666",
                "village_id": 24,
                "village_name": "शेरपुर"
            },
            {
                "label": "श्री चन्‍दपुर 0645",
                "village_id": 25,
                "village_name": "श्री चन्‍दपुर"
            },
            {
                "label": "शालीग्रामी 0648",
                "village_id": 26,
                "village_name": "शालीग्रामी"
            },
            {
                "label": "सैदपुर 0660",
                "village_id": 27,
                "village_name": "सैदपुर"
            },
            {
                "label": "सैदपुर 0662",
                "village_id": 28,
                "village_name": "सैदपुर"
            },
            {
                "label": "संदलपुर 0656",
                "village_id": 29,
                "village_name": "संदलपुर"
            },
            {
                "label": "सबदलपुर 0647",
                "village_id": 30,
                "village_name": "सबदलपुर"
            },
            {
                "label": "समस्‍तीपुर 0661",
                "village_id": 31,
                "village_name": "समस्‍तीपुर"
            },
            {
                "label": "सलेमावाद 0672",
                "village_id": 32,
                "village_name": "सलेमावाद"
            },
            {
                "label": "सेवाय खाश 0644",
                "village_id": 33,
                "village_name": "सेवाय खाश"
            },
            {
                "label": "सा0 कमाल 0663",
                "village_id": 34,
                "village_name": "सा0 कमाल"
            },
            {
                "label": "सादपुर 0632",
                "village_id": 35,
                "village_name": "सादपुर"
            },
            {
                "label": "सादपुर 0635",
                "village_id": 36,
                "village_name": "सादपुर"
            },
            {
                "label": "सानहा 0625",
                "village_id": 37,
                "village_name": "सानहा"
            },
            {
                "label": "सिरैया 0639",
                "village_id": 38,
                "village_name": "सिरैया"
            },
            {
                "label": "हरपुर 0667",
                "village_id": 39,
                "village_name": "हरपुर"
            },
            {
                "label": "हरवलचक 0643",
                "village_id": 40,
                "village_name": "हरवलचक"
            }
        ],
        "block_id": "7",
        "block_no": 6,
        "block_name": "सा0 कमाल [007]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अख्तियारपुर 0628",
                "village_id": 2,
                "village_name": "अख्तियारपुर"
            },
            {
                "label": "कटरमाल 0245",
                "village_id": 3,
                "village_name": "कटरमाल"
            },
            {
                "label": "कटहरी 0629",
                "village_id": 4,
                "village_name": "कटहरी"
            },
            {
                "label": "कामेचक 0631",
                "village_id": 5,
                "village_name": "कामेचक"
            },
            {
                "label": "कोदरा 0250",
                "village_id": 6,
                "village_name": "कोदरा"
            },
            {
                "label": "चक पथरा 0292",
                "village_id": 7,
                "village_name": "चक पथरा"
            },
            {
                "label": "डंडारी 0303",
                "village_id": 8,
                "village_name": "डंडारी"
            },
            {
                "label": "तेतरी 0297",
                "village_id": 9,
                "village_name": "तेतरी"
            },
            {
                "label": "तेतरी करारी 0298",
                "village_id": 10,
                "village_name": "तेतरी करारी"
            },
            {
                "label": "तेतरी बरारी 0299",
                "village_id": 11,
                "village_name": "तेतरी बरारी"
            },
            {
                "label": "तुर्किया 0251",
                "village_id": 12,
                "village_name": "तुर्किया"
            },
            {
                "label": "नुरपुर डुमरा 0281",
                "village_id": 13,
                "village_name": "नुरपुर डुमरा"
            },
            {
                "label": "पचरूखी 0304",
                "village_id": 14,
                "village_name": "पचरूखी"
            },
            {
                "label": "प्रतारपुर 0305",
                "village_id": 15,
                "village_name": "प्रतारपुर"
            },
            {
                "label": "बाँक 0301",
                "village_id": 16,
                "village_name": "बाँक"
            },
            {
                "label": "बिशनपुर 0300",
                "village_id": 17,
                "village_name": "बिशनपुर"
            },
            {
                "label": "महिपाटोल 0630",
                "village_id": 18,
                "village_name": "महिपाटोल"
            },
            {
                "label": "मेंहॉं 0280",
                "village_id": 19,
                "village_name": "मेंहॉं"
            },
            {
                "label": "मिल्‍की 0246",
                "village_id": 20,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मिल्‍की 0284",
                "village_id": 21,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मिल्‍की 0282",
                "village_id": 22,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मिल्‍की 0291",
                "village_id": 23,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मोहनपुर करारी 0290",
                "village_id": 24,
                "village_name": "मोहनपुर करारी"
            },
            {
                "label": "मोहनपुर बरारी 0283",
                "village_id": 25,
                "village_name": "मोहनपुर बरारी"
            },
            {
                "label": "राजोपुर 0243",
                "village_id": 26,
                "village_name": "राजोपुर"
            },
            {
                "label": "श्रीनाथपुर 0302",
                "village_id": 27,
                "village_name": "श्रीनाथपुर"
            },
            {
                "label": "सुघरन 0244",
                "village_id": 28,
                "village_name": "सुघरन"
            },
            {
                "label": "सोहेलपुर 0293",
                "village_id": 29,
                "village_name": "सोहेलपुर"
            }
        ],
        "block_id": "8",
        "block_no": 7,
        "block_name": "डंडारी [008]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अकहा 0172",
                "village_id": 2,
                "village_name": "अकहा"
            },
            {
                "label": "अदमपुर 0164",
                "village_id": 3,
                "village_name": "अदमपुर"
            },
            {
                "label": "अभुआर 0173",
                "village_id": 4,
                "village_name": "अभुआर"
            },
            {
                "label": "उदनचक 0277",
                "village_id": 5,
                "village_name": "उदनचक"
            },
            {
                "label": "उदयपुर 0168",
                "village_id": 6,
                "village_name": "उदयपुर"
            },
            {
                "label": "उरहा हरि सिंह 0264",
                "village_id": 7,
                "village_name": "उरहा हरि सिंह"
            },
            {
                "label": "करकौली 0167",
                "village_id": 8,
                "village_name": "करकौली"
            },
            {
                "label": "करणपुर 0255",
                "village_id": 9,
                "village_name": "करणपुर"
            },
            {
                "label": "कुरनामा 0269",
                "village_id": 10,
                "village_name": "कुरनामा"
            },
            {
                "label": "खगड़चक 0147",
                "village_id": 11,
                "village_name": "खगड़चक"
            },
            {
                "label": "खजुरवन 0276",
                "village_id": 12,
                "village_name": "खजुरवन"
            },
            {
                "label": "गंगरहो 0165",
                "village_id": 13,
                "village_name": "गंगरहो"
            },
            {
                "label": "गंडक बरार 0285",
                "village_id": 14,
                "village_name": "गंडक बरार"
            },
            {
                "label": "गम्‍हरिया 0185",
                "village_id": 15,
                "village_name": "गम्‍हरिया"
            },
            {
                "label": "घाघड़ा 0166",
                "village_id": 16,
                "village_name": "घाघड़ा"
            },
            {
                "label": "चक चनरपत 0268",
                "village_id": 17,
                "village_name": "चक चनरपत"
            },
            {
                "label": "चक वनवार 0270",
                "village_id": 18,
                "village_name": "चक वनवार"
            },
            {
                "label": "चक्‍की 0272",
                "village_id": 19,
                "village_name": "चक्‍की"
            },
            {
                "label": "चमराही 0289",
                "village_id": 20,
                "village_name": "चमराही"
            },
            {
                "label": "जयलख अभिमान 0156",
                "village_id": 21,
                "village_name": "जयलख अभिमान"
            },
            {
                "label": "डरहा थान सिंह 0265",
                "village_id": 22,
                "village_name": "डरहा थान सिंह"
            },
            {
                "label": "तुलसीचक 0171",
                "village_id": 23,
                "village_name": "तुलसीचक"
            },
            {
                "label": "दुलहचक 0271",
                "village_id": 24,
                "village_name": "दुलहचक"
            },
            {
                "label": "ध्‍यान चक्‍की 0262",
                "village_id": 25,
                "village_name": "ध्‍यान चक्‍की"
            },
            {
                "label": "नदैल मनी 0150",
                "village_id": 26,
                "village_name": "नदैल मनी"
            },
            {
                "label": "नदैल विक्रम 0148",
                "village_id": 27,
                "village_name": "नदैल विक्रम"
            },
            {
                "label": "निशहरा 0273",
                "village_id": 28,
                "village_name": "निशहरा"
            },
            {
                "label": "पकहमीद 0149",
                "village_id": 29,
                "village_name": "पकहमीद"
            },
            {
                "label": "परिहार 0287",
                "village_id": 30,
                "village_name": "परिहार"
            },
            {
                "label": "बखरी 0157",
                "village_id": 31,
                "village_name": "बखरी"
            },
            {
                "label": "बगरस हरिसिंह 0263",
                "village_id": 32,
                "village_name": "बगरस हरिसिंह"
            },
            {
                "label": "बुडवन 0274",
                "village_id": 33,
                "village_name": "बुडवन"
            },
            {
                "label": "बभाईन 0275",
                "village_id": 34,
                "village_name": "बभाईन"
            },
            {
                "label": "बहुआरा 0294",
                "village_id": 35,
                "village_name": "बहुआरा"
            },
            {
                "label": "बहुआरा 0295",
                "village_id": 36,
                "village_name": "बहुआरा"
            },
            {
                "label": "बहुआरा 0296",
                "village_id": 37,
                "village_name": "बहुआरा"
            },
            {
                "label": "बहोर चक 0151",
                "village_id": 38,
                "village_name": "बहोर चक"
            },
            {
                "label": "बागवन 0256",
                "village_id": 39,
                "village_name": "बागवन"
            },
            {
                "label": "भवानीपुर 0278",
                "village_id": 40,
                "village_name": "भवानीपुर"
            },
            {
                "label": "मक्‍खाचक 0162",
                "village_id": 41,
                "village_name": "मक्‍खाचक"
            },
            {
                "label": "मदनपुर 0137",
                "village_id": 42,
                "village_name": "मदनपुर"
            },
            {
                "label": "महादेवचक 0154",
                "village_id": 43,
                "village_name": "महादेवचक"
            },
            {
                "label": "मिरकलापुर 0159",
                "village_id": 44,
                "village_name": "मिरकलापुर"
            },
            {
                "label": "मोहनपुर 0169",
                "village_id": 45,
                "village_name": "मोहनपुर"
            },
            {
                "label": "राटन 0279",
                "village_id": 46,
                "village_name": "राटन"
            },
            {
                "label": "रामपुर 0161",
                "village_id": 47,
                "village_name": "रामपुर"
            },
            {
                "label": "लौछे 0163",
                "village_id": 48,
                "village_name": "लौछे"
            },
            {
                "label": "वैरवा 0152",
                "village_id": 49,
                "village_name": "वैरवा"
            },
            {
                "label": "विजय लख 0153",
                "village_id": 50,
                "village_name": "विजय लख"
            },
            {
                "label": "शंकरपुरा 0155",
                "village_id": 51,
                "village_name": "शंकरपुरा"
            },
            {
                "label": "शादीपुर 0160",
                "village_id": 52,
                "village_name": "शादीपुर"
            },
            {
                "label": "सुग्‍गा 0266",
                "village_id": 53,
                "village_name": "सुग्‍गा"
            },
            {
                "label": "सुग्‍गी 0267",
                "village_id": 54,
                "village_name": "सुग्‍गी"
            },
            {
                "label": "सलौना 0158",
                "village_id": 55,
                "village_name": "सलौना"
            },
            {
                "label": "साखु 0288",
                "village_id": 56,
                "village_name": "साखु"
            },
            {
                "label": "सिनुआरी 0258",
                "village_id": 57,
                "village_name": "सिनुआरी"
            },
            {
                "label": "सिमरी 0138",
                "village_id": 58,
                "village_name": "सिमरी"
            },
            {
                "label": "सिसौनी 0257",
                "village_id": 59,
                "village_name": "सिसौनी"
            },
            {
                "label": "सोनवर्षा चक्‍की 0286",
                "village_id": 60,
                "village_name": "सोनवर्षा चक्‍की"
            },
            {
                "label": "हेमनपुर 0170",
                "village_id": 61,
                "village_name": "हेमनपुर"
            }
        ],
        "block_id": "9",
        "block_no": 8,
        "block_name": "बखरी [009]",
        "sub_div_code": "3"
    },
    {
        "village": [
            {
                "label": "ईमादपुर 0145",
                "village_id": 2,
                "village_name": "ईमादपुर"
            },
            {
                "label": "कनौसी 0128",
                "village_id": 3,
                "village_name": "कनौसी"
            },
            {
                "label": "कुम्‍हारसी 0136",
                "village_id": 4,
                "village_name": "कुम्‍हारसी"
            },
            {
                "label": "कोरैया 0049",
                "village_id": 5,
                "village_name": "कोरैया"
            },
            {
                "label": "कोरियाम 0132",
                "village_id": 6,
                "village_name": "कोरियाम"
            },
            {
                "label": "खखरुआ 0144",
                "village_id": 7,
                "village_name": "खखरुआ"
            },
            {
                "label": "खेराज 0133",
                "village_id": 8,
                "village_name": "खेराज"
            },
            {
                "label": "गढ 0126",
                "village_id": 9,
                "village_name": "गढ"
            },
            {
                "label": "गढ़पुरा 0122",
                "village_id": 10,
                "village_name": "गढ़पुरा"
            },
            {
                "label": "जगदिशपुर नरोतर 0125",
                "village_id": 11,
                "village_name": "जगदिशपुर नरोतर"
            },
            {
                "label": "जगरनाथपुर 0047",
                "village_id": 12,
                "village_name": "जगरनाथपुर"
            },
            {
                "label": "जगरनाथपुर 0044",
                "village_id": 13,
                "village_name": "जगरनाथपुर"
            },
            {
                "label": "जगरनाथपुर 0045",
                "village_id": 14,
                "village_name": "जगरनाथपुर"
            },
            {
                "label": "दुनही 0127",
                "village_id": 15,
                "village_name": "दुनही"
            },
            {
                "label": "पटराहावाद 0123",
                "village_id": 16,
                "village_name": "पटराहावाद"
            },
            {
                "label": "प्राणपुर 0146",
                "village_id": 17,
                "village_name": "प्राणपुर"
            },
            {
                "label": "बुजुगार्वाद 0134",
                "village_id": 18,
                "village_name": "बुजुगार्वाद"
            },
            {
                "label": "बेजहा 0131",
                "village_id": 19,
                "village_name": "बेजहा"
            },
            {
                "label": "बरियारपुर 0143",
                "village_id": 20,
                "village_name": "बरियारपुर"
            },
            {
                "label": "भईधारा 0186",
                "village_id": 21,
                "village_name": "भईधारा"
            },
            {
                "label": "भंसी 0135",
                "village_id": 22,
                "village_name": "भंसी"
            },
            {
                "label": "मणिकपुर 0129",
                "village_id": 23,
                "village_name": "मणिकपुर"
            },
            {
                "label": "मुसेपुर 0048",
                "village_id": 24,
                "village_name": "मुसेपुर"
            },
            {
                "label": "मालपुर 0043",
                "village_id": 25,
                "village_name": "मालपुर"
            },
            {
                "label": "मालीपुर 0046",
                "village_id": 26,
                "village_name": "मालीपुर"
            },
            {
                "label": "मोरतर 0042",
                "village_id": 27,
                "village_name": "मोरतर"
            },
            {
                "label": "मौजा हरि सिंह 0141",
                "village_id": 28,
                "village_name": "मौजा हरि सिंह"
            },
            {
                "label": "मौजी थान सिंह 0142",
                "village_id": 29,
                "village_name": "मौजी थान सिंह"
            },
            {
                "label": "रजौड़ 0130",
                "village_id": 30,
                "village_name": "रजौड़"
            },
            {
                "label": "विजय नारायण 0124",
                "village_id": 31,
                "village_name": "विजय नारायण"
            },
            {
                "label": "शीतलरामपुर 0140",
                "village_id": 32,
                "village_name": "शीतलरामपुर"
            },
            {
                "label": "सकड़ा 0187",
                "village_id": 33,
                "village_name": "सकड़ा"
            },
            {
                "label": "सुजानपुर 0121",
                "village_id": 34,
                "village_name": "सुजानपुर"
            },
            {
                "label": "सिलथाडोम 0050",
                "village_id": 35,
                "village_name": "सिलथाडोम"
            },
            {
                "label": "सोनमा 0139",
                "village_id": 36,
                "village_name": "सोनमा"
            },
            {
                "label": "हरखपुरा 0051",
                "village_id": 37,
                "village_name": "हरखपुरा"
            }
        ],
        "block_id": "10",
        "block_no": 9,
        "block_name": "गढ़पुरा [010]",
        "sub_div_code": "3"
    },
    {
        "village": [
            {
                "label": "अव्‍वुपुर 0206",
                "village_id": 2,
                "village_name": "अव्‍वुपुर"
            },
            {
                "label": "अव्‍वुपुर 0207",
                "village_id": 3,
                "village_name": "अव्‍वुपुर"
            },
            {
                "label": "इसफा 0177",
                "village_id": 4,
                "village_name": "इसफा"
            },
            {
                "label": "ईब्राहिमपुर 0208",
                "village_id": 5,
                "village_name": "ईब्राहिमपुर"
            },
            {
                "label": "कमलपुर 0209",
                "village_id": 6,
                "village_name": "कमलपुर"
            },
            {
                "label": "कमलपुर 0205",
                "village_id": 7,
                "village_name": "कमलपुर"
            },
            {
                "label": "करैयटार 0259",
                "village_id": 8,
                "village_name": "करैयटार"
            },
            {
                "label": "गम्‍हरिया 0184",
                "village_id": 9,
                "village_name": "गम्‍हरिया"
            },
            {
                "label": "गुरुदान 0182",
                "village_id": 10,
                "village_name": "गुरुदान"
            },
            {
                "label": "गौड़ीपुर 0175",
                "village_id": 11,
                "village_name": "गौड़ीपुर"
            },
            {
                "label": "चक मुजफ्फर 0176",
                "village_id": 12,
                "village_name": "चक मुजफ्फर"
            },
            {
                "label": "छतौना 0180",
                "village_id": 13,
                "village_name": "छतौना"
            },
            {
                "label": "टेकनपुरा 0213",
                "village_id": 14,
                "village_name": "टेकनपुरा"
            },
            {
                "label": "डफरपुर 0210",
                "village_id": 15,
                "village_name": "डफरपुर"
            },
            {
                "label": "देवड़ा 0188",
                "village_id": 16,
                "village_name": "देवड़ा"
            },
            {
                "label": "देवपुरा 0248",
                "village_id": 17,
                "village_name": "देवपुरा"
            },
            {
                "label": "नावकोठी 0179",
                "village_id": 18,
                "village_name": "नावकोठी"
            },
            {
                "label": "नीरपुर 0252",
                "village_id": 19,
                "village_name": "नीरपुर"
            },
            {
                "label": "पहसारा 0183",
                "village_id": 20,
                "village_name": "पहसारा"
            },
            {
                "label": "फकीरना 0261",
                "village_id": 21,
                "village_name": "फकीरना"
            },
            {
                "label": "बेगमपुर 0181",
                "village_id": 22,
                "village_name": "बेगमपुर"
            },
            {
                "label": "बगरसथान सिंह  0260",
                "village_id": 23,
                "village_name": "बगरसथान सिंह "
            },
            {
                "label": "बभनगामा 0197",
                "village_id": 24,
                "village_name": "बभनगामा"
            },
            {
                "label": "बहादुरपुर 0203",
                "village_id": 25,
                "village_name": "बहादुरपुर"
            },
            {
                "label": "बहादुरपुर 0211",
                "village_id": 26,
                "village_name": "बहादुरपुर"
            },
            {
                "label": "बहादुरपुर 0212",
                "village_id": 27,
                "village_name": "बहादुरपुर"
            },
            {
                "label": "मथुरापुर 0254",
                "village_id": 28,
                "village_name": "मथुरापुर"
            },
            {
                "label": "मनेरपुर 0249",
                "village_id": 29,
                "village_name": "मनेरपुर"
            },
            {
                "label": "महेशवाड़ा 0194",
                "village_id": 30,
                "village_name": "महेशवाड़ा"
            },
            {
                "label": "रजाकपुर 0174",
                "village_id": 31,
                "village_name": "रजाकपुर"
            },
            {
                "label": "वृन्‍दावन 0204",
                "village_id": 32,
                "village_name": "वृन्‍दावन"
            },
            {
                "label": "वृन्‍दावन 0202",
                "village_id": 33,
                "village_name": "वृन्‍दावन"
            },
            {
                "label": "सैदपुर विष्‍णुपुर 0247",
                "village_id": 34,
                "village_name": "सैदपुर विष्‍णुपुर"
            },
            {
                "label": "समसा 0253",
                "village_id": 35,
                "village_name": "समसा"
            },
            {
                "label": "हसनपुर बागर 0178",
                "village_id": 36,
                "village_name": "हसनपुर बागर"
            }
        ],
        "block_id": "11",
        "block_no": 10,
        "block_name": "नावकोठी [011]",
        "sub_div_code": "3"
    },
    {
        "village": [
            {
                "label": "अधारपुर 0129",
                "village_id": 2,
                "village_name": "अधारपुर"
            },
            {
                "label": "अब्‍दुल रहीम चक 0399",
                "village_id": 3,
                "village_name": "अब्‍दुल रहीम चक"
            },
            {
                "label": "अमजदपुर पिढ़ौली 0087",
                "village_id": 4,
                "village_name": "अमजदपुर पिढ़ौली"
            },
            {
                "label": "अमरपुर 0356",
                "village_id": 5,
                "village_name": "अमरपुर"
            },
            {
                "label": "अम्‍वा 0407",
                "village_id": 6,
                "village_name": "अम्‍वा"
            },
            {
                "label": "अराजी तुलसीपुर 0471",
                "village_id": 7,
                "village_name": "अराजी तुलसीपुर"
            },
            {
                "label": "अहियापुर 0366",
                "village_id": 8,
                "village_name": "अहियापुर"
            },
            {
                "label": "आधारपुर 0127",
                "village_id": 9,
                "village_name": "आधारपुर"
            },
            {
                "label": "आलापुर 0394",
                "village_id": 10,
                "village_name": "आलापुर"
            },
            {
                "label": "कगजनियां 0347",
                "village_id": 11,
                "village_name": "कगजनियां"
            },
            {
                "label": "कसवा 0348",
                "village_id": 12,
                "village_name": "कसवा"
            },
            {
                "label": "कादिरचक 0375",
                "village_id": 13,
                "village_name": "कादिरचक"
            },
            {
                "label": "किरतौल 0400",
                "village_id": 14,
                "village_name": "किरतौल"
            },
            {
                "label": "खिजिर चक 0398",
                "village_id": 15,
                "village_name": "खिजिर चक"
            },
            {
                "label": "गोधना 0084",
                "village_id": 16,
                "village_name": "गोधना"
            },
            {
                "label": "गोधना 0085",
                "village_id": 17,
                "village_name": "गोधना"
            },
            {
                "label": "गोधना 0083",
                "village_id": 18,
                "village_name": "गोधना"
            },
            {
                "label": "गौड़ा 0329",
                "village_id": 19,
                "village_name": "गौड़ा"
            },
            {
                "label": "चक अबाबरक 0374",
                "village_id": 20,
                "village_name": "चक अबाबरक"
            },
            {
                "label": "चक अलावक्‍स 0397",
                "village_id": 21,
                "village_name": "चक अलावक्‍स"
            },
            {
                "label": "चक जर्नादन 0101",
                "village_id": 22,
                "village_name": "चक जर्नादन"
            },
            {
                "label": "चक दयाल 0344",
                "village_id": 23,
                "village_name": "चक दयाल"
            },
            {
                "label": "चक भीखन 0117",
                "village_id": 24,
                "village_name": "चक भीखन"
            },
            {
                "label": "चक भीखन 0111",
                "village_id": 25,
                "village_name": "चक भीखन"
            },
            {
                "label": "चक सूर्या 0371",
                "village_id": 26,
                "village_name": "चक सूर्या"
            },
            {
                "label": "चक्‍कीपुर 0358",
                "village_id": 27,
                "village_name": "चक्‍कीपुर"
            },
            {
                "label": "चकजर्नादन 0103",
                "village_id": 28,
                "village_name": "चकजर्नादन"
            },
            {
                "label": "चकजर्नादन 0113",
                "village_id": 29,
                "village_name": "चकजर्नादन"
            },
            {
                "label": "चकदयाल 0352",
                "village_id": 30,
                "village_name": "चकदयाल"
            },
            {
                "label": "चकदाद 0336",
                "village_id": 31,
                "village_name": "चकदाद"
            },
            {
                "label": "चकदाद 0337",
                "village_id": 32,
                "village_name": "चकदाद"
            },
            {
                "label": "चकदाद 0351",
                "village_id": 33,
                "village_name": "चकदाद"
            },
            {
                "label": "चकसेदु 0342",
                "village_id": 34,
                "village_name": "चकसेदु"
            },
            {
                "label": "चकहाफिज तुलसीपुर 0403",
                "village_id": 35,
                "village_name": "चकहाफिज तुलसीपुर"
            },
            {
                "label": "तुलसीपुर 0402",
                "village_id": 36,
                "village_name": "तुलसीपुर"
            },
            {
                "label": "तुलसीपुर 0404",
                "village_id": 37,
                "village_name": "तुलसीपुर"
            },
            {
                "label": "तुलसीपुर 0401",
                "village_id": 38,
                "village_name": "तुलसीपुर"
            },
            {
                "label": "तुलसीपुर 0331",
                "village_id": 39,
                "village_name": "तुलसीपुर"
            },
            {
                "label": "तुलसीपुर 0333",
                "village_id": 40,
                "village_name": "तुलसीपुर"
            },
            {
                "label": "ताजपुर 0102",
                "village_id": 41,
                "village_name": "ताजपुर"
            },
            {
                "label": "ताजपुर 0104",
                "village_id": 42,
                "village_name": "ताजपुर"
            },
            {
                "label": "ताजपुर 0357",
                "village_id": 43,
                "village_name": "ताजपुर"
            },
            {
                "label": "धनकौल 0393",
                "village_id": 44,
                "village_name": "धनकौल"
            },
            {
                "label": "नन्‍दपुर स्‍वरुप 0120",
                "village_id": 45,
                "village_name": "नन्‍दपुर स्‍वरुप"
            },
            {
                "label": "नयानगर 0123",
                "village_id": 46,
                "village_name": "नयानगर"
            },
            {
                "label": "निपनियां 0367",
                "village_id": 47,
                "village_name": "निपनियां"
            },
            {
                "label": "निपनियां 0365",
                "village_id": 48,
                "village_name": "निपनियां"
            },
            {
                "label": "नोनपुर 0470",
                "village_id": 49,
                "village_name": "नोनपुर"
            },
            {
                "label": "नोनपुर स्‍वरुप 0121",
                "village_id": 50,
                "village_name": "नोनपुर स्‍वरुप"
            },
            {
                "label": "पकठौल 0405",
                "village_id": 51,
                "village_name": "पकठौल"
            },
            {
                "label": "पैगम्‍बरपुर 0128",
                "village_id": 52,
                "village_name": "पैगम्‍बरपुर"
            },
            {
                "label": "पैगम्‍बरपुर 0130",
                "village_id": 53,
                "village_name": "पैगम्‍बरपुर"
            },
            {
                "label": "पैगम्‍वरपुर 0355",
                "village_id": 54,
                "village_name": "पैगम्‍वरपुर"
            },
            {
                "label": "पैगम्‍वरपुर 0354",
                "village_id": 55,
                "village_name": "पैगम्‍वरपुर"
            },
            {
                "label": "प्रतापपुर 0108",
                "village_id": 56,
                "village_name": "प्रतापपुर"
            },
            {
                "label": "प्रतापपुर 0094",
                "village_id": 57,
                "village_name": "प्रतापपुर"
            },
            {
                "label": "प्रतापपुर 0095",
                "village_id": 58,
                "village_name": "प्रतापपुर"
            },
            {
                "label": "प्रतापपुर 0097",
                "village_id": 59,
                "village_name": "प्रतापपुर"
            },
            {
                "label": "प्रतापपुर सिकन्‍दर 0099",
                "village_id": 60,
                "village_name": "प्रतापपुर सिकन्‍दर"
            },
            {
                "label": "पुरुषोतमपुर 0391",
                "village_id": 61,
                "village_name": "पुरुषोतमपुर"
            },
            {
                "label": "पिपरा दोदराज 0468",
                "village_id": 62,
                "village_name": "पिपरा दोदराज"
            },
            {
                "label": "फजलीपुर 0353",
                "village_id": 63,
                "village_name": "फजलीपुर"
            },
            {
                "label": "फरदी 0463",
                "village_id": 64,
                "village_name": "फरदी"
            },
            {
                "label": "फुलवरिया 0390",
                "village_id": 65,
                "village_name": "फुलवरिया"
            },
            {
                "label": "बनहारा 0396",
                "village_id": 66,
                "village_name": "बनहारा"
            },
            {
                "label": "बरियारपुर 0330",
                "village_id": 67,
                "village_name": "बरियारपुर"
            },
            {
                "label": "बरौनी 0335",
                "village_id": 68,
                "village_name": "बरौनी"
            },
            {
                "label": "बाजीदपुर राउत 0360",
                "village_id": 69,
                "village_name": "बाजीदपुर राउत"
            },
            {
                "label": "बारो 0373",
                "village_id": 70,
                "village_name": "बारो"
            },
            {
                "label": "बिनलपुर 0118",
                "village_id": 71,
                "village_name": "बिनलपुर"
            },
            {
                "label": "बिनलपुर 0116",
                "village_id": 72,
                "village_name": "बिनलपुर"
            },
            {
                "label": "बिनलपुर 0109",
                "village_id": 73,
                "village_name": "बिनलपुर"
            },
            {
                "label": "बिसौआ 0093",
                "village_id": 74,
                "village_name": "बिसौआ"
            },
            {
                "label": "भगवानपुर सिकन्‍दर 0096",
                "village_id": 75,
                "village_name": "भगवानपुर सिकन्‍दर"
            },
            {
                "label": "भगवानपुर सिकन्‍दर 0098",
                "village_id": 76,
                "village_name": "भगवानपुर सिकन्‍दर"
            },
            {
                "label": "भगवानपुर सिकन्‍दर 0100",
                "village_id": 77,
                "village_name": "भगवानपुर सिकन्‍दर"
            },
            {
                "label": "भजनपुर नवादा 0086",
                "village_id": 78,
                "village_name": "भजनपुर नवादा"
            },
            {
                "label": "भीखनचक 0110",
                "village_id": 79,
                "village_name": "भीखनचक"
            },
            {
                "label": "मकदुमपुर 0338",
                "village_id": 80,
                "village_name": "मकदुमपुर"
            },
            {
                "label": "मखदमपुर 0341",
                "village_id": 81,
                "village_name": "मखदमपुर"
            },
            {
                "label": "मखदमपुर 0343",
                "village_id": 82,
                "village_name": "मखदमपुर"
            },
            {
                "label": "मुतलुपुर 0339",
                "village_id": 83,
                "village_name": "मुतलुपुर"
            },
            {
                "label": "मुतलुपुर 0346",
                "village_id": 84,
                "village_name": "मुतलुपुर"
            },
            {
                "label": "मुतलुपुर 0349",
                "village_id": 85,
                "village_name": "मुतलुपुर"
            },
            {
                "label": "मुतलुपुर 0350",
                "village_id": 86,
                "village_name": "मुतलुपुर"
            },
            {
                "label": "मधुरापुर 0361",
                "village_id": 87,
                "village_name": "मधुरापुर"
            },
            {
                "label": "मधुरापुर 0363",
                "village_id": 88,
                "village_name": "मधुरापुर"
            },
            {
                "label": "मधुरापुर 0362",
                "village_id": 89,
                "village_name": "मधुरापुर"
            },
            {
                "label": "महमदपुर सागर 0105",
                "village_id": 90,
                "village_name": "महमदपुर सागर"
            },
            {
                "label": "मालती 0388",
                "village_id": 91,
                "village_name": "मालती"
            },
            {
                "label": "मिर्जापुर अनमन 0370",
                "village_id": 92,
                "village_name": "मिर्जापुर अनमन"
            },
            {
                "label": "रसलपुर 0332",
                "village_id": 93,
                "village_name": "रसलपुर"
            },
            {
                "label": "राजदेवपुर 0376",
                "village_id": 94,
                "village_name": "राजदेवपुर"
            },
            {
                "label": "रातगांव 0124",
                "village_id": 95,
                "village_name": "रातगांव"
            },
            {
                "label": "रातगांव 0125",
                "village_id": 96,
                "village_name": "रातगांव"
            },
            {
                "label": "रातगांव 0119",
                "village_id": 97,
                "village_name": "रातगांव"
            },
            {
                "label": "रातगांव 0122",
                "village_id": 98,
                "village_name": "रातगांव"
            },
            {
                "label": "रातगांव 0115",
                "village_id": 99,
                "village_name": "रातगांव"
            },
            {
                "label": "रामपुर गोपी 0092",
                "village_id": 100,
                "village_name": "रामपुर गोपी"
            },
            {
                "label": "रामपुर गोपी 0090",
                "village_id": 101,
                "village_name": "रामपुर गोपी"
            },
            {
                "label": "रामपुर गोपी 0106",
                "village_id": 102,
                "village_name": "रामपुर गोपी"
            },
            {
                "label": "रामपुर गोपी 0107",
                "village_id": 103,
                "village_name": "रामपुर गोपी"
            },
            {
                "label": "रामपुर चिल्‍हाई 0406",
                "village_id": 104,
                "village_name": "रामपुर चिल्‍हाई"
            },
            {
                "label": "वाजितपुर 0114",
                "village_id": 105,
                "village_name": "वाजितपुर"
            },
            {
                "label": "वाजीदपुर जानकी राम 0091",
                "village_id": 106,
                "village_name": "वाजीदपुर जानकी राम"
            },
            {
                "label": "शईस्‍ता दाद 0385",
                "village_id": 107,
                "village_name": "शईस्‍ता दाद"
            },
            {
                "label": "शईस्‍ता दाद 0386",
                "village_id": 108,
                "village_name": "शईस्‍ता दाद"
            },
            {
                "label": "शईस्‍ता दाद 0387",
                "village_id": 109,
                "village_name": "शईस्‍ता दाद"
            },
            {
                "label": "शोकहारा 0389",
                "village_id": 110,
                "village_name": "शोकहारा"
            },
            {
                "label": "शोकावतपुर 0359",
                "village_id": 111,
                "village_name": "शोकावतपुर"
            },
            {
                "label": "स्‍नातनपुर 0340",
                "village_id": 112,
                "village_name": "स्‍नातनपुर"
            },
            {
                "label": "सुलतानपुर 0345",
                "village_id": 113,
                "village_name": "सुलतानपुर"
            },
            {
                "label": "सलेमपुर बारो 0372",
                "village_id": 114,
                "village_name": "सलेमपुर बारो"
            },
            {
                "label": "सादीकपुर 0334",
                "village_id": 115,
                "village_name": "सादीकपुर"
            },
            {
                "label": "हरपुर 0392",
                "village_id": 116,
                "village_name": "हरपुर"
            },
            {
                "label": "हरेरामपुर 0364",
                "village_id": 117,
                "village_name": "हरेरामपुर"
            },
            {
                "label": "हिसरामपुर 0112",
                "village_id": 118,
                "village_name": "हिसरामपुर"
            }
        ],
        "block_id": "12",
        "block_no": 11,
        "block_name": "तेघड़ा [012]",
        "sub_div_code": "4"
    },
    {
        "village": [
            {
                "label": "अजीतपुर 0057",
                "village_id": 2,
                "village_name": "अजीतपुर"
            },
            {
                "label": "अमजदपुर पिठौली 0082",
                "village_id": 3,
                "village_name": "अमजदपुर पिठौली"
            },
            {
                "label": "अमजदपुर पिढौली 0081",
                "village_id": 4,
                "village_name": "अमजदपुर पिढौली"
            },
            {
                "label": "अमजदपुर पिढौली 0079",
                "village_id": 5,
                "village_name": "अमजदपुर पिढौली"
            },
            {
                "label": "अरवा 0223",
                "village_id": 6,
                "village_name": "अरवा"
            },
            {
                "label": "आलमपुर 0027",
                "village_id": 7,
                "village_name": "आलमपुर"
            },
            {
                "label": "कादरावाद 0219",
                "village_id": 8,
                "village_name": "कादरावाद"
            },
            {
                "label": "खजुलिया 0042",
                "village_id": 9,
                "village_name": "खजुलिया"
            },
            {
                "label": "खैरपुर सराय 0019",
                "village_id": 10,
                "village_name": "खैरपुर सराय"
            },
            {
                "label": "गदैयाचक 0216",
                "village_id": 11,
                "village_name": "गदैयाचक"
            },
            {
                "label": "गुर चकिया 0048",
                "village_id": 12,
                "village_name": "गुर चकिया"
            },
            {
                "label": "गरायगांव 0009",
                "village_id": 13,
                "village_name": "गरायगांव"
            },
            {
                "label": "गेहूनी 0215",
                "village_id": 14,
                "village_name": "गेहूनी"
            },
            {
                "label": "गोधना 0080",
                "village_id": 15,
                "village_name": "गोधना"
            },
            {
                "label": "गोपालपुर 0062",
                "village_id": 16,
                "village_name": "गोपालपुर"
            },
            {
                "label": "गोविन्‍ददासपुर 0067",
                "village_id": 17,
                "village_name": "गोविन्‍ददासपुर"
            },
            {
                "label": "गोविन्‍दपुर 0028",
                "village_id": 18,
                "village_name": "गोविन्‍दपुर"
            },
            {
                "label": "गोसाईपुर भगवानपुर 0023",
                "village_id": 19,
                "village_name": "गोसाईपुर भगवानपुर"
            },
            {
                "label": "चक गंगा 0069",
                "village_id": 20,
                "village_name": "चक गंगा"
            },
            {
                "label": "चक दिलार 0005",
                "village_id": 21,
                "village_name": "चक दिलार"
            },
            {
                "label": "चक हुसैनावाद 0218",
                "village_id": 22,
                "village_name": "चक हुसैनावाद"
            },
            {
                "label": "चक्‍करीमा 0225",
                "village_id": 23,
                "village_name": "चक्‍करीमा"
            },
            {
                "label": "चक्‍का 0007",
                "village_id": 24,
                "village_name": "चक्‍का"
            },
            {
                "label": "चकगुदा 0140",
                "village_id": 25,
                "village_name": "चकगुदा"
            },
            {
                "label": "चकफिरा 0051",
                "village_id": 26,
                "village_name": "चकफिरा"
            },
            {
                "label": "चकभेला 0068",
                "village_id": 27,
                "village_name": "चकभेला"
            },
            {
                "label": "चकौली 0012",
                "village_id": 28,
                "village_name": "चकौली"
            },
            {
                "label": "चकौली 0016",
                "village_id": 29,
                "village_name": "चकौली"
            },
            {
                "label": "चगाजी 0071",
                "village_id": 30,
                "village_name": "चगाजी"
            },
            {
                "label": "चमथा 0063",
                "village_id": 31,
                "village_name": "चमथा"
            },
            {
                "label": "चिरंजीवीपुर 0013",
                "village_id": 32,
                "village_name": "चिरंजीवीपुर"
            },
            {
                "label": "चिरैया टोक 0064",
                "village_id": 33,
                "village_name": "चिरैया टोक"
            },
            {
                "label": "चिरैया टोक 0061",
                "village_id": 34,
                "village_name": "चिरैया टोक"
            },
            {
                "label": "जहानपुर 0224",
                "village_id": 35,
                "village_name": "जहानपुर"
            },
            {
                "label": "जीतवाजीतपुर 0054",
                "village_id": 36,
                "village_name": "जीतवाजीतपुर"
            },
            {
                "label": "झमटिया 0070",
                "village_id": 37,
                "village_name": "झमटिया"
            },
            {
                "label": "झमटिया 0045",
                "village_id": 38,
                "village_name": "झमटिया"
            },
            {
                "label": "तेमुहा 0011",
                "village_id": 39,
                "village_name": "तेमुहा"
            },
            {
                "label": "दरगहपुर 0072",
                "village_id": 40,
                "village_name": "दरगहपुर"
            },
            {
                "label": "दरगहपुर 0073",
                "village_id": 41,
                "village_name": "दरगहपुर"
            },
            {
                "label": "दरगहपुर 0074",
                "village_id": 42,
                "village_name": "दरगहपुर"
            },
            {
                "label": "दरगहपुर 0075",
                "village_id": 43,
                "village_name": "दरगहपुर"
            },
            {
                "label": "दरगहपुर 0076",
                "village_id": 44,
                "village_name": "दरगहपुर"
            },
            {
                "label": "दरगहपुर 0077",
                "village_id": 45,
                "village_name": "दरगहपुर"
            },
            {
                "label": "दरगहपुर 0078",
                "village_id": 46,
                "village_name": "दरगहपुर"
            },
            {
                "label": "दुलारपुर 0089",
                "village_id": 47,
                "village_name": "दुलारपुर"
            },
            {
                "label": "दादुपुर 0049",
                "village_id": 48,
                "village_name": "दादुपुर"
            },
            {
                "label": "दामुसराय 0018",
                "village_id": 49,
                "village_name": "दामुसराय"
            },
            {
                "label": "फतेरा 0017",
                "village_id": 50,
                "village_name": "फतेरा"
            },
            {
                "label": "बेगम सराय 0044",
                "village_id": 51,
                "village_name": "बेगम सराय"
            },
            {
                "label": "बछवाड़ा 0041",
                "village_id": 52,
                "village_name": "बछवाड़ा"
            },
            {
                "label": "बेलतर 0059",
                "village_id": 53,
                "village_name": "बेलतर"
            },
            {
                "label": "बलभद्र वन 0020",
                "village_id": 54,
                "village_name": "बलभद्र वन"
            },
            {
                "label": "बहरामपुर 0226",
                "village_id": 55,
                "village_name": "बहरामपुर"
            },
            {
                "label": "भगवानपुर 0021",
                "village_id": 56,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भगवानपुर 0022",
                "village_id": 57,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भगवानपुर 0024",
                "village_id": 58,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भगवानपुर 0025",
                "village_id": 59,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भगवानपुर 0046",
                "village_id": 60,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भरौल 0222",
                "village_id": 61,
                "village_name": "भरौल"
            },
            {
                "label": "भीखमचक 0227",
                "village_id": 62,
                "village_name": "भीखमचक"
            },
            {
                "label": "भीखमचक 0233",
                "village_id": 63,
                "village_name": "भीखमचक"
            },
            {
                "label": "मजनुपुर नवादा 0141",
                "village_id": 64,
                "village_name": "मजनुपुर नवादा"
            },
            {
                "label": "मरांची 0234",
                "village_id": 65,
                "village_name": "मरांची"
            },
            {
                "label": "मरांची 0040",
                "village_id": 66,
                "village_name": "मरांची"
            },
            {
                "label": "महम्‍मदपुर फत्‍ता 0088",
                "village_id": 67,
                "village_name": "महम्‍मदपुर फत्‍ता"
            },
            {
                "label": "महमिल्‍लापुर 0058",
                "village_id": 68,
                "village_name": "महमिल्‍लापुर"
            },
            {
                "label": "मिल्‍की 0010",
                "village_id": 69,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मिल्‍की 0008",
                "village_id": 70,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मोसीनपुर 0014",
                "village_id": 71,
                "village_name": "मोसीनपुर"
            },
            {
                "label": "रतुलहपुर 0066",
                "village_id": 72,
                "village_name": "रतुलहपुर"
            },
            {
                "label": "रुदौली 0221",
                "village_id": 73,
                "village_name": "रुदौली"
            },
            {
                "label": "रुपसवाज 0052",
                "village_id": 74,
                "village_name": "रुपसवाज"
            },
            {
                "label": "रसीदपुर 0006",
                "village_id": 75,
                "village_name": "रसीदपुर"
            },
            {
                "label": "राजापुरराघो 0015",
                "village_id": 76,
                "village_name": "राजापुरराघो"
            },
            {
                "label": "रानी 0043",
                "village_id": 77,
                "village_name": "रानी"
            },
            {
                "label": "रानीटोल 0047",
                "village_id": 78,
                "village_name": "रानीटोल"
            },
            {
                "label": "रानीटोल 0053",
                "village_id": 79,
                "village_name": "रानीटोल"
            },
            {
                "label": "रामचन्‍द्रपुर 0228",
                "village_id": 80,
                "village_name": "रामचन्‍द्रपुर"
            },
            {
                "label": "रामपुर भीमपुर 0236",
                "village_id": 81,
                "village_name": "रामपुर भीमपुर"
            },
            {
                "label": "रामभीमपुर 0235",
                "village_id": 82,
                "village_name": "रामभीमपुर"
            },
            {
                "label": "लोदियाही 0055",
                "village_id": 83,
                "village_name": "लोदियाही"
            },
            {
                "label": "वरिसनगर 0050",
                "village_id": 84,
                "village_name": "वरिसनगर"
            },
            {
                "label": "विशनपुर 0060",
                "village_id": 85,
                "village_name": "विशनपुर"
            },
            {
                "label": "समसीपुर 0065",
                "village_id": 86,
                "village_name": "समसीपुर"
            },
            {
                "label": "सुरो 0026",
                "village_id": 87,
                "village_name": "सुरो"
            },
            {
                "label": "सलेमपुर 0056",
                "village_id": 88,
                "village_name": "सलेमपुर"
            },
            {
                "label": "हादीपुर 0209",
                "village_id": 89,
                "village_name": "हादीपुर"
            },
            {
                "label": "हादीपुर 0220",
                "village_id": 90,
                "village_name": "हादीपुर"
            },
            {
                "label": "हादीपुर 0212",
                "village_id": 91,
                "village_name": "हादीपुर"
            },
            {
                "label": "हादीपुर 0214",
                "village_id": 92,
                "village_name": "हादीपुर"
            },
            {
                "label": "हादीपुर 0217",
                "village_id": 93,
                "village_name": "हादीपुर"
            }
        ],
        "block_id": "13",
        "block_no": 12,
        "block_name": "बछवाड़ा [013]",
        "sub_div_code": "4"
    },
    {
        "village": [
            {
                "label": "अतरुआ 0189",
                "village_id": 2,
                "village_name": "अतरुआ"
            },
            {
                "label": "अराजी पुरखोतमपुर 0414",
                "village_id": 3,
                "village_name": "अराजी पुरखोतमपुर"
            },
            {
                "label": "अराजी पुरखोतिमपुर 0321",
                "village_id": 4,
                "village_name": "अराजी पुरखोतिमपुर"
            },
            {
                "label": "अराजी पालीडीह 0306",
                "village_id": 5,
                "village_name": "अराजी पालीडीह"
            },
            {
                "label": "अराजी रसलपुर 0415",
                "village_id": 6,
                "village_name": "अराजी रसलपुर"
            },
            {
                "label": "अराजी वगरस 0302",
                "village_id": 7,
                "village_name": "अराजी वगरस"
            },
            {
                "label": "इशापुर 0285",
                "village_id": 8,
                "village_name": "इशापुर"
            },
            {
                "label": "उसीचक 0279",
                "village_id": 9,
                "village_name": "उसीचक"
            },
            {
                "label": "ए0 औगान 0411",
                "village_id": 10,
                "village_name": "ए0 औगान"
            },
            {
                "label": "ए0 औगान 0413",
                "village_id": 11,
                "village_name": "ए0 औगान"
            },
            {
                "label": "ए0 किरतपुर 0287",
                "village_id": 12,
                "village_name": "ए0 किरतपुर"
            },
            {
                "label": "ए0 रसलपुर 0412",
                "village_id": 13,
                "village_name": "ए0 रसलपुर"
            },
            {
                "label": "ए0 सूर्यपुरा 0282",
                "village_id": 14,
                "village_name": "ए0 सूर्यपुरा"
            },
            {
                "label": "एराजी भगवानपुर 0315",
                "village_id": 15,
                "village_name": "एराजी भगवानपुर"
            },
            {
                "label": "एराजी भगवानपुर 0316",
                "village_id": 16,
                "village_name": "एराजी भगवानपुर"
            },
            {
                "label": "एराजी भगवानपुर 0317",
                "village_id": 17,
                "village_name": "एराजी भगवानपुर"
            },
            {
                "label": "एराजी भगवानपुर 0318",
                "village_id": 18,
                "village_name": "एराजी भगवानपुर"
            },
            {
                "label": "एराजी भगवानपुर 0319",
                "village_id": 19,
                "village_name": "एराजी भगवानपुर"
            },
            {
                "label": "एराजी मधेपुरा 0312",
                "village_id": 20,
                "village_name": "एराजी मधेपुरा"
            },
            {
                "label": "एराजी मधेपुरा 0314",
                "village_id": 21,
                "village_name": "एराजी मधेपुरा"
            },
            {
                "label": "एराजी मधेपुरा 0297",
                "village_id": 22,
                "village_name": "एराजी मधेपुरा"
            },
            {
                "label": "एराजी लखनपुर 0301",
                "village_id": 23,
                "village_name": "एराजी लखनपुर"
            },
            {
                "label": "एराजी विशनपुर 0286",
                "village_id": 24,
                "village_name": "एराजी विशनपुर"
            },
            {
                "label": "एराजी सुर्यपुरा 0288",
                "village_id": 25,
                "village_name": "एराजी सुर्यपुरा"
            },
            {
                "label": "कटरिया 0395",
                "village_id": 26,
                "village_name": "कटरिया"
            },
            {
                "label": "कविया 0277",
                "village_id": 27,
                "village_name": "कविया"
            },
            {
                "label": "काजी रसुलपुर 0167",
                "village_id": 28,
                "village_name": "काजी रसुलपुर"
            },
            {
                "label": "काजी रसुलपुर 0170",
                "village_id": 29,
                "village_name": "काजी रसुलपुर"
            },
            {
                "label": "किरतपुर 0281",
                "village_id": 30,
                "village_name": "किरतपुर"
            },
            {
                "label": "खाजेजहांपुर पाली 0308",
                "village_id": 31,
                "village_name": "खाजेजहांपुर पाली"
            },
            {
                "label": "खांजहांपुर पाली 0307",
                "village_id": 32,
                "village_name": "खांजहांपुर पाली"
            },
            {
                "label": "गुलाम मोहम्‍मद 0197",
                "village_id": 33,
                "village_name": "गुलाम मोहम्‍मद"
            },
            {
                "label": "गेहूनी 0209",
                "village_id": 34,
                "village_name": "गेहूनी"
            },
            {
                "label": "चक अमला 0134",
                "village_id": 35,
                "village_name": "चक अमला"
            },
            {
                "label": "चक अमला 0135",
                "village_id": 36,
                "village_name": "चक अमला"
            },
            {
                "label": "चक अमानतुल्‍लाह 0181",
                "village_id": 37,
                "village_name": "चक अमानतुल्‍लाह"
            },
            {
                "label": "चक इनामत  0153",
                "village_id": 38,
                "village_name": "चक इनामत "
            },
            {
                "label": "चक दुलम 0199",
                "village_id": 39,
                "village_name": "चक दुलम"
            },
            {
                "label": "चक मुन्‍नी 0139",
                "village_id": 40,
                "village_name": "चक मुन्‍नी"
            },
            {
                "label": "चक मेहरो 0138",
                "village_id": 41,
                "village_name": "चक मेहरो"
            },
            {
                "label": "चक यदुपत 0169",
                "village_id": 42,
                "village_name": "चक यदुपत"
            },
            {
                "label": "चक यदुपत 0155",
                "village_id": 43,
                "village_name": "चक यदुपत"
            },
            {
                "label": "चक सदात 0188",
                "village_id": 44,
                "village_name": "चक सदात"
            },
            {
                "label": "चक सदात 0190",
                "village_id": 45,
                "village_name": "चक सदात"
            },
            {
                "label": "चकइसुफ 0146",
                "village_id": 46,
                "village_name": "चकइसुफ"
            },
            {
                "label": "चकदुलम 0200",
                "village_id": 47,
                "village_name": "चकदुलम"
            },
            {
                "label": "चकनाईत 0126",
                "village_id": 48,
                "village_name": "चकनाईत"
            },
            {
                "label": "चकपर 0187",
                "village_id": 49,
                "village_name": "चकपर"
            },
            {
                "label": "चकमदसूदन 0160",
                "village_id": 50,
                "village_name": "चकमदसूदन"
            },
            {
                "label": "चकमदसूदन 0161",
                "village_id": 51,
                "village_name": "चकमदसूदन"
            },
            {
                "label": "चकमसुदनपुर 0143",
                "village_id": 52,
                "village_name": "चकमसुदनपुर"
            },
            {
                "label": "चकयदुपत 0147",
                "village_id": 53,
                "village_name": "चकयदुपत"
            },
            {
                "label": "चकयदुपत 0162",
                "village_id": 54,
                "village_name": "चकयदुपत"
            },
            {
                "label": "चकयदुपत 0163",
                "village_id": 55,
                "village_name": "चकयदुपत"
            },
            {
                "label": "चकयदुपत 0166",
                "village_id": 56,
                "village_name": "चकयदुपत"
            },
            {
                "label": "चकयदुपत 0164",
                "village_id": 57,
                "village_name": "चकयदुपत"
            },
            {
                "label": "चकयुसुफ 0152",
                "village_id": 58,
                "village_name": "चकयुसुफ"
            },
            {
                "label": "चकरायर 0195",
                "village_id": 59,
                "village_name": "चकरायर"
            },
            {
                "label": "चकसदात 0182",
                "village_id": 60,
                "village_name": "चकसदात"
            },
            {
                "label": "चकससपुर 0185",
                "village_id": 61,
                "village_name": "चकससपुर"
            },
            {
                "label": "चकायम 0136",
                "village_id": 62,
                "village_name": "चकायम"
            },
            {
                "label": "चकायम 0137",
                "village_id": 63,
                "village_name": "चकायम"
            },
            {
                "label": "चन्‍दौर 0293",
                "village_id": 64,
                "village_name": "चन्‍दौर"
            },
            {
                "label": "चुरामनचक 0325",
                "village_id": 65,
                "village_name": "चुरामनचक"
            },
            {
                "label": "चेरिया 0421",
                "village_id": 66,
                "village_name": "चेरिया"
            },
            {
                "label": "चौधरी पट्टी 0178",
                "village_id": 67,
                "village_name": "चौधरी पट्टी"
            },
            {
                "label": "छतरी टोला 0179",
                "village_id": 68,
                "village_name": "छतरी टोला"
            },
            {
                "label": "जगदिशपुर 0292",
                "village_id": 69,
                "village_name": "जगदिशपुर"
            },
            {
                "label": "जयरामपुर 0420",
                "village_id": 70,
                "village_name": "जयरामपुर"
            },
            {
                "label": "जयरामपुर 0309",
                "village_id": 71,
                "village_name": "जयरामपुर"
            },
            {
                "label": "जानकीपुर 0304",
                "village_id": 72,
                "village_name": "जानकीपुर"
            },
            {
                "label": "जोकिया 0409",
                "village_id": 73,
                "village_name": "जोकिया"
            },
            {
                "label": "डोहटा 0276",
                "village_id": 74,
                "village_name": "डोहटा"
            },
            {
                "label": "तकिया 0154",
                "village_id": 75,
                "village_name": "तकिया"
            },
            {
                "label": "तेयाय 0159",
                "village_id": 76,
                "village_name": "तेयाय"
            },
            {
                "label": "तेलन 0428",
                "village_id": 77,
                "village_name": "तेलन"
            },
            {
                "label": "तेलन 0429",
                "village_id": 78,
                "village_name": "तेलन"
            },
            {
                "label": "ताजपुर 0291",
                "village_id": 79,
                "village_name": "ताजपुर"
            },
            {
                "label": "दहिया 0416",
                "village_id": 80,
                "village_name": "दहिया"
            },
            {
                "label": "दादपुर 0151",
                "village_id": 81,
                "village_name": "दादपुर"
            },
            {
                "label": "दादपुर 0142",
                "village_id": 82,
                "village_name": "दादपुर"
            },
            {
                "label": "दामोदरपुर 0326",
                "village_id": 83,
                "village_name": "दामोदरपुर"
            },
            {
                "label": "नुरपुर बनहारा 0328",
                "village_id": 84,
                "village_name": "नुरपुर बनहारा"
            },
            {
                "label": "नरहरिपुर 0418",
                "village_id": 85,
                "village_name": "नरहरिपुर"
            },
            {
                "label": "पैगम्‍बरपुर 0132",
                "village_id": 86,
                "village_name": "पैगम्‍बरपुर"
            },
            {
                "label": "पालीडीह 0305",
                "village_id": 87,
                "village_name": "पालीडीह"
            },
            {
                "label": "पासोपुर 0327",
                "village_id": 88,
                "village_name": "पासोपुर"
            },
            {
                "label": "फतेहपुर 0133",
                "village_id": 89,
                "village_name": "फतेहपुर"
            },
            {
                "label": "बगरस 0204",
                "village_id": 90,
                "village_name": "बगरस"
            },
            {
                "label": "बगरस 0205",
                "village_id": 91,
                "village_name": "बगरस"
            },
            {
                "label": "बूढ़ीवन 0156",
                "village_id": 92,
                "village_name": "बूढ़ीवन"
            },
            {
                "label": "भगवानपुर 0323",
                "village_id": 93,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भगवानपुर तेयाय 0145",
                "village_id": 94,
                "village_name": "भगवानपुर तेयाय"
            },
            {
                "label": "भडीहा 0311",
                "village_id": 95,
                "village_name": "भडीहा"
            },
            {
                "label": "भागवतपुर  0144",
                "village_id": 96,
                "village_name": "भागवतपुर "
            },
            {
                "label": "भागवतपुर तेयाय 0174",
                "village_id": 97,
                "village_name": "भागवतपुर तेयाय"
            },
            {
                "label": "भागवतपुर तेयाय 0175",
                "village_id": 98,
                "village_name": "भागवतपुर तेयाय"
            },
            {
                "label": "भागवतपुर तियाय  0172",
                "village_id": 99,
                "village_name": "भागवतपुर तियाय "
            },
            {
                "label": "भागवतपुर तियाय 0148",
                "village_id": 100,
                "village_name": "भागवतपुर तियाय"
            },
            {
                "label": "भागवतपुर तियाय 0149",
                "village_id": 101,
                "village_name": "भागवतपुर तियाय"
            },
            {
                "label": "भागवतपुर तियाय 0150",
                "village_id": 102,
                "village_name": "भागवतपुर तियाय"
            },
            {
                "label": "भागवतपुर तियाय 0157",
                "village_id": 103,
                "village_name": "भागवतपुर तियाय"
            },
            {
                "label": "भीठसाड़ी 0422",
                "village_id": 104,
                "village_name": "भीठसाड़ी"
            },
            {
                "label": "मकसपुर 0176",
                "village_id": 105,
                "village_name": "मकसपुर"
            },
            {
                "label": "मधेपुरा 0294",
                "village_id": 106,
                "village_name": "मधेपुरा"
            },
            {
                "label": "मननपुर 0198",
                "village_id": 107,
                "village_name": "मननपुर"
            },
            {
                "label": "मनसेरपुर 0184",
                "village_id": 108,
                "village_name": "मनसेरपुर"
            },
            {
                "label": "मलयातरी 0425",
                "village_id": 109,
                "village_name": "मलयातरी"
            },
            {
                "label": "मलहीपुर 0192",
                "village_id": 110,
                "village_name": "मलहीपुर"
            },
            {
                "label": "मलहीपुर 0213",
                "village_id": 111,
                "village_name": "मलहीपुर"
            },
            {
                "label": "मेहदौली 0320",
                "village_id": 112,
                "village_name": "मेहदौली"
            },
            {
                "label": "महम्‍दपुर मासुम 0180",
                "village_id": 113,
                "village_name": "महम्‍दपुर मासुम"
            },
            {
                "label": "महम्‍मदपुर मासुम 0183",
                "village_id": 114,
                "village_name": "महम्‍मदपुर मासुम"
            },
            {
                "label": "महेशपुर 0193",
                "village_id": 115,
                "village_name": "महेशपुर"
            },
            {
                "label": "महेशपुर 0194",
                "village_id": 116,
                "village_name": "महेशपुर"
            },
            {
                "label": "महेशपुर 0196",
                "village_id": 117,
                "village_name": "महेशपुर"
            },
            {
                "label": "महेशपुर 0191",
                "village_id": 118,
                "village_name": "महेशपुर"
            },
            {
                "label": "मानोपुर 0274",
                "village_id": 119,
                "village_name": "मानोपुर"
            },
            {
                "label": "मामापारी 0423",
                "village_id": 120,
                "village_name": "मामापारी"
            },
            {
                "label": "मिर्जापुर महेन्‍द्र  0426",
                "village_id": 121,
                "village_name": "मिर्जापुर महेन्‍द्र "
            },
            {
                "label": "मिलकी 0322",
                "village_id": 122,
                "village_name": "मिलकी"
            },
            {
                "label": "मिसरपुरा 0298",
                "village_id": 123,
                "village_name": "मिसरपुरा"
            },
            {
                "label": "मोखातियारपुर 0275",
                "village_id": 124,
                "village_name": "मोखातियारपुर"
            },
            {
                "label": "मोजाहीदपुर 0202",
                "village_id": 125,
                "village_name": "मोजाहीदपुर"
            },
            {
                "label": "मोजाहीदपुर 0207",
                "village_id": 126,
                "village_name": "मोजाहीदपुर"
            },
            {
                "label": "मोजाहीदपुर 0208",
                "village_id": 127,
                "village_name": "मोजाहीदपुर"
            },
            {
                "label": "मोवारकपुर 0295",
                "village_id": 128,
                "village_name": "मोवारकपुर"
            },
            {
                "label": "रघुनन्‍दनपुर 0131",
                "village_id": 129,
                "village_name": "रघुनन्‍दनपुर"
            },
            {
                "label": "रघुनन्‍दनपुर 0171",
                "village_id": 130,
                "village_name": "रघुनन्‍दनपुर"
            },
            {
                "label": "रघुनन्‍दनपुर 0168",
                "village_id": 131,
                "village_name": "रघुनन्‍दनपुर"
            },
            {
                "label": "रघुनन्‍दनपुर 0165",
                "village_id": 132,
                "village_name": "रघुनन्‍दनपुर"
            },
            {
                "label": "रघुनन्‍दनपुर 0173",
                "village_id": 133,
                "village_name": "रघुनन्‍दनपुर"
            },
            {
                "label": "रसलपुर 0410",
                "village_id": 134,
                "village_name": "रसलपुर"
            },
            {
                "label": "राजारामदादपुर 0158",
                "village_id": 135,
                "village_name": "राजारामदादपुर"
            },
            {
                "label": "लखनपुर 0299",
                "village_id": 136,
                "village_name": "लखनपुर"
            },
            {
                "label": "लखनपुर 0177",
                "village_id": 137,
                "village_name": "लखनपुर"
            },
            {
                "label": "वगरस 0310",
                "village_id": 138,
                "village_name": "वगरस"
            },
            {
                "label": "वुचौली 0284",
                "village_id": 139,
                "village_name": "वुचौली"
            },
            {
                "label": "वदलपुरा 0427",
                "village_id": 140,
                "village_name": "वदलपुरा"
            },
            {
                "label": "वदिया 0211",
                "village_id": 141,
                "village_name": "वदिया"
            },
            {
                "label": "वनवारीपुर 0203",
                "village_id": 142,
                "village_name": "वनवारीपुर"
            },
            {
                "label": "वनवारीपुर 0206",
                "village_id": 143,
                "village_name": "वनवारीपुर"
            },
            {
                "label": "वनवारीपुर 0296",
                "village_id": 144,
                "village_name": "वनवारीपुर"
            },
            {
                "label": "वनवारीपुर 0201",
                "village_id": 145,
                "village_name": "वनवारीपुर"
            },
            {
                "label": "वसही 0324",
                "village_id": 146,
                "village_name": "वसही"
            },
            {
                "label": "विशनपुर 0289",
                "village_id": 147,
                "village_name": "विशनपुर"
            },
            {
                "label": "शेरपुर 0290",
                "village_id": 148,
                "village_name": "शेरपुर"
            },
            {
                "label": "संजात 0280",
                "village_id": 149,
                "village_name": "संजात"
            },
            {
                "label": "सतराजेपुर 0303",
                "village_id": 150,
                "village_name": "सतराजेपुर"
            },
            {
                "label": "सतराजेपुर 0300",
                "village_id": 151,
                "village_name": "सतराजेपुर"
            },
            {
                "label": "सूर्यपुरा 0283",
                "village_id": 152,
                "village_name": "सूर्यपुरा"
            },
            {
                "label": "हण्‍डालपुर 0313",
                "village_id": 153,
                "village_name": "हण्‍डालपुर"
            },
            {
                "label": "हरिचक 0278",
                "village_id": 154,
                "village_name": "हरिचक"
            },
            {
                "label": "हादीपुर मीरा 0186",
                "village_id": 155,
                "village_name": "हादीपुर मीरा"
            }
        ],
        "block_id": "14",
        "block_no": 13,
        "block_name": "भगवानपुर [014]",
        "sub_div_code": "4"
    },
    {
        "village": [
            {
                "label": "आगापुर 0268",
                "village_id": 2,
                "village_name": "आगापुर"
            },
            {
                "label": "आगापुर 0266",
                "village_id": 3,
                "village_name": "आगापुर"
            },
            {
                "label": "आगापुर 0272",
                "village_id": 4,
                "village_name": "आगापुर"
            },
            {
                "label": "आलगचक 0035",
                "village_id": 5,
                "village_name": "आलगचक"
            },
            {
                "label": "आलमचक 0037",
                "village_id": 6,
                "village_name": "आलमचक"
            },
            {
                "label": "काजीचक 0258",
                "village_id": 7,
                "village_name": "काजीचक"
            },
            {
                "label": "गणपतौल 0247",
                "village_id": 8,
                "village_name": "गणपतौल"
            },
            {
                "label": "गुरुदासपुर 0003",
                "village_id": 9,
                "village_name": "गुरुदासपुर"
            },
            {
                "label": "गोविन्‍दपुर 0028",
                "village_id": 10,
                "village_name": "गोविन्‍दपुर"
            },
            {
                "label": "गोविन्‍दपुर 0028",
                "village_id": 11,
                "village_name": "गोविन्‍दपुर"
            },
            {
                "label": "गोसपुर 0261",
                "village_id": 12,
                "village_name": "गोसपुर"
            },
            {
                "label": "चक अल्‍लदादपुर 0255",
                "village_id": 13,
                "village_name": "चक अल्‍लदादपुर"
            },
            {
                "label": "चक अल्‍लादादपुर 0257",
                "village_id": 14,
                "village_name": "चक अल्‍लादादपुर"
            },
            {
                "label": "चक अल्‍हदादपुर 0260",
                "village_id": 15,
                "village_name": "चक अल्‍हदादपुर"
            },
            {
                "label": "चक अल्‍हादादपुर 0251",
                "village_id": 16,
                "village_name": "चक अल्‍हादादपुर"
            },
            {
                "label": "चक अल्‍हादादपुर 0252",
                "village_id": 17,
                "village_name": "चक अल्‍हादादपुर"
            },
            {
                "label": "चकमनसुर 0267",
                "village_id": 18,
                "village_name": "चकमनसुर"
            },
            {
                "label": "छविलापुर 0002",
                "village_id": 19,
                "village_name": "छविलापुर"
            },
            {
                "label": "जैपुर 0229",
                "village_id": 20,
                "village_name": "जैपुर"
            },
            {
                "label": "जमालपुर 0241",
                "village_id": 21,
                "village_name": "जमालपुर"
            },
            {
                "label": "दशरथपुर 0030",
                "village_id": 22,
                "village_name": "दशरथपुर"
            },
            {
                "label": "नयाटोल 0273",
                "village_id": 23,
                "village_name": "नयाटोल"
            },
            {
                "label": "नयाटोल 0265",
                "village_id": 24,
                "village_name": "नयाटोल"
            },
            {
                "label": "नयाटोल 0269",
                "village_id": 25,
                "village_name": "नयाटोल"
            },
            {
                "label": "नयाटोल 0270",
                "village_id": 26,
                "village_name": "नयाटोल"
            },
            {
                "label": "पुरानीचक 0256",
                "village_id": 27,
                "village_name": "पुरानीचक"
            },
            {
                "label": "बहरामपुर 0253",
                "village_id": 28,
                "village_name": "बहरामपुर"
            },
            {
                "label": "बहरामपुर 0232",
                "village_id": 29,
                "village_name": "बहरामपुर"
            },
            {
                "label": "बहरामपुर 0249",
                "village_id": 30,
                "village_name": "बहरामपुर"
            },
            {
                "label": "बहरामपुर 0250",
                "village_id": 31,
                "village_name": "बहरामपुर"
            },
            {
                "label": "बहरामपुर 0244",
                "village_id": 32,
                "village_name": "बहरामपुर"
            },
            {
                "label": "बहरामपुर 0246",
                "village_id": 33,
                "village_name": "बहरामपुर"
            },
            {
                "label": "भवानीपुर 0031",
                "village_id": 34,
                "village_name": "भवानीपुर"
            },
            {
                "label": "मकसन दरगाह 0239",
                "village_id": 35,
                "village_name": "मकसन दरगाह"
            },
            {
                "label": "मनसुरचक 0242",
                "village_id": 36,
                "village_name": "मनसुरचक"
            },
            {
                "label": "मनसुरचक 0036",
                "village_id": 37,
                "village_name": "मनसुरचक"
            },
            {
                "label": "मनसुरचक 0034",
                "village_id": 38,
                "village_name": "मनसुरचक"
            },
            {
                "label": "मराची 0029",
                "village_id": 39,
                "village_name": "मराची"
            },
            {
                "label": "महेन्‍द्रगंज 0248",
                "village_id": 40,
                "village_name": "महेन्‍द्रगंज"
            },
            {
                "label": "महम्‍मदपुर मासुम 0230",
                "village_id": 41,
                "village_name": "महम्‍मदपुर मासुम"
            },
            {
                "label": "मानोपुर 0271",
                "village_id": 42,
                "village_name": "मानोपुर"
            },
            {
                "label": "मानोपुर 0264",
                "village_id": 43,
                "village_name": "मानोपुर"
            },
            {
                "label": "मिल्‍की 0001",
                "village_id": 44,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मोमिनावाद 0032",
                "village_id": 45,
                "village_name": "मोमिनावाद"
            },
            {
                "label": "मोमिनावाद 0033",
                "village_id": 46,
                "village_name": "मोमिनावाद"
            },
            {
                "label": "मोमिनावाद 0038",
                "village_id": 47,
                "village_name": "मोमिनावाद"
            },
            {
                "label": "मोहीउदीनपुर 0240",
                "village_id": 48,
                "village_name": "मोहीउदीनपुर"
            },
            {
                "label": "रसलपुर 0254",
                "village_id": 49,
                "village_name": "रसलपुर"
            },
            {
                "label": "रसलापुर 0259",
                "village_id": 50,
                "village_name": "रसलापुर"
            },
            {
                "label": "श्रीरामपुर 0238",
                "village_id": 51,
                "village_name": "श्रीरामपुर"
            },
            {
                "label": "श्रीरामपुर 0243",
                "village_id": 52,
                "village_name": "श्रीरामपुर"
            },
            {
                "label": "समसा 0263",
                "village_id": 53,
                "village_name": "समसा"
            },
            {
                "label": "सराय नुरनगर 0231",
                "village_id": 54,
                "village_name": "सराय नुरनगर"
            },
            {
                "label": "सलेमपुर 0237",
                "village_id": 55,
                "village_name": "सलेमपुर"
            },
            {
                "label": "साढा 0004",
                "village_id": 56,
                "village_name": "साढा"
            },
            {
                "label": "सोहिलवारा 0262",
                "village_id": 57,
                "village_name": "सोहिलवारा"
            },
            {
                "label": "हवासपुर 0245",
                "village_id": 58,
                "village_name": "हवासपुर"
            }
        ],
        "block_id": "15",
        "block_no": 14,
        "block_name": "मंसूरचक [015]",
        "sub_div_code": "4"
    },
    {
        "village": [
            {
                "label": "आकोपुर 0089",
                "village_id": 2,
                "village_name": "आकोपुर"
            },
            {
                "label": "आलमपुर उदय 0108",
                "village_id": 3,
                "village_name": "आलमपुर उदय"
            },
            {
                "label": "औरे 0097",
                "village_id": 4,
                "village_name": "औरे"
            },
            {
                "label": "कुंभी 0081",
                "village_id": 5,
                "village_name": "कुंभी"
            },
            {
                "label": "करोड़ 0102",
                "village_id": 6,
                "village_name": "करोड़"
            },
            {
                "label": "काजीचक 0192",
                "village_id": 7,
                "village_name": "काजीचक"
            },
            {
                "label": "कोरजाना 0098",
                "village_id": 8,
                "village_name": "कोरजाना"
            },
            {
                "label": "खसरुपुर 0113",
                "village_id": 9,
                "village_name": "खसरुपुर"
            },
            {
                "label": "खांजहांपुर 0115",
                "village_id": 10,
                "village_name": "खांजहांपुर"
            },
            {
                "label": "गोपालपुर 0090",
                "village_id": 11,
                "village_name": "गोपालपुर"
            },
            {
                "label": "चेरिया बख्तियारपुर 0104",
                "village_id": 12,
                "village_name": "चेरिया बख्तियारपुर"
            },
            {
                "label": "जगदिशपुर 0112",
                "village_id": 13,
                "village_name": "जगदिशपुर"
            },
            {
                "label": "जय मंगलगढ़ 0189",
                "village_id": 14,
                "village_name": "जय मंगलगढ़"
            },
            {
                "label": "जय मंगलपुर 0190",
                "village_id": 15,
                "village_name": "जय मंगलपुर"
            },
            {
                "label": "ढाढा 0110",
                "village_id": 16,
                "village_name": "ढाढा"
            },
            {
                "label": "धर्मपुर 0105",
                "village_id": 17,
                "village_name": "धर्मपुर"
            },
            {
                "label": "पवड़ा 0193",
                "village_id": 18,
                "village_name": "पवड़ा"
            },
            {
                "label": "बसही 0094",
                "village_id": 19,
                "village_name": "बसही"
            },
            {
                "label": "बिक्रमपुर 0099",
                "village_id": 20,
                "village_name": "बिक्रमपुर"
            },
            {
                "label": "मंझौल 0191",
                "village_id": 21,
                "village_name": "मंझौल"
            },
            {
                "label": "मेहदा 0106",
                "village_id": 22,
                "village_name": "मेहदा"
            },
            {
                "label": "मेहदा शाहपुर 0114",
                "village_id": 23,
                "village_name": "मेहदा शाहपुर"
            },
            {
                "label": "मिल्‍क मेहदा 0111",
                "village_id": 24,
                "village_name": "मिल्‍क मेहदा"
            },
            {
                "label": "मिल्‍की 0109",
                "village_id": 25,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मिल्‍की 0093",
                "village_id": 26,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मोहनपुर 0095",
                "village_id": 27,
                "village_name": "मोहनपुर"
            },
            {
                "label": "रुद्रपुर 0107",
                "village_id": 28,
                "village_name": "रुद्रपुर"
            },
            {
                "label": "रमौली 0103",
                "village_id": 29,
                "village_name": "रमौली"
            },
            {
                "label": "रामपुर 0091",
                "village_id": 30,
                "village_name": "रामपुर"
            },
            {
                "label": "विशनपुर 0100",
                "village_id": 31,
                "village_name": "विशनपुर"
            },
            {
                "label": "श्रीपुर 0101",
                "village_id": 32,
                "village_name": "श्रीपुर"
            },
            {
                "label": "सकरवासा 0096",
                "village_id": 33,
                "village_name": "सकरवासा"
            },
            {
                "label": "सकरौली 0092",
                "village_id": 34,
                "village_name": "सकरौली"
            },
            {
                "label": "सोनवर्षा 0080",
                "village_id": 35,
                "village_name": "सोनवर्षा"
            }
        ],
        "block_id": "16",
        "block_no": 15,
        "block_name": "चे0 बरियारपुर [016]",
        "sub_div_code": "5"
    },
    {
        "village": [
            {
                "label": "कफौत 0082",
                "village_id": 2,
                "village_name": "कफौत"
            },
            {
                "label": "कुरशाहा 0075",
                "village_id": 3,
                "village_name": "कुरशाहा"
            },
            {
                "label": "खोदावन्‍दपुर 0086",
                "village_id": 4,
                "village_name": "खोदावन्‍दपुर"
            },
            {
                "label": "चकयदु 0083",
                "village_id": 5,
                "village_name": "चकयदु"
            },
            {
                "label": "तेतराही 0074",
                "village_id": 6,
                "village_name": "तेतराही"
            },
            {
                "label": "दौलतपुर 0071",
                "village_id": 7,
                "village_name": "दौलतपुर"
            },
            {
                "label": "नुरुलाहपुर 0065",
                "village_id": 8,
                "village_name": "नुरुलाहपुर"
            },
            {
                "label": "नारायणपुर 0063",
                "village_id": 9,
                "village_name": "नारायणपुर"
            },
            {
                "label": "फतेहपुर 0064",
                "village_id": 10,
                "village_name": "फतेहपुर"
            },
            {
                "label": "बेगमपुर 0068",
                "village_id": 11,
                "village_name": "बेगमपुर"
            },
            {
                "label": "बरियारपुर 0077",
                "village_id": 12,
                "village_name": "बरियारपुर"
            },
            {
                "label": "बाड़ा 0069",
                "village_id": 13,
                "village_name": "बाड़ा"
            },
            {
                "label": "मेघौल 0088",
                "village_id": 14,
                "village_name": "मेघौल"
            },
            {
                "label": "मटिहानी 0085",
                "village_id": 15,
                "village_name": "मटिहानी"
            },
            {
                "label": "महदीचक 0067",
                "village_id": 16,
                "village_name": "महदीचक"
            },
            {
                "label": "मालपुर 0084",
                "village_id": 17,
                "village_name": "मालपुर"
            },
            {
                "label": "मिर्जापुर 0076",
                "village_id": 18,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "मिल्‍की 0070",
                "village_id": 19,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मिल्‍की 0072",
                "village_id": 20,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मोहनपुर 0066",
                "village_id": 21,
                "village_name": "मोहनपुर"
            },
            {
                "label": "विदुलिया 0087",
                "village_id": 22,
                "village_name": "विदुलिया"
            },
            {
                "label": "सागी 0062",
                "village_id": 23,
                "village_name": "सागी"
            }
        ],
        "block_id": "17",
        "block_no": 16,
        "block_name": "खोदावंदपुर [017]",
        "sub_div_code": "5"
    },
    {
        "village": [
            {
                "label": "अमारी 0059",
                "village_id": 2,
                "village_name": "अमारी"
            },
            {
                "label": "इब्राहिमपुर 0013",
                "village_id": 3,
                "village_name": "इब्राहिमपुर"
            },
            {
                "label": "इब्राहिमपुर 0036",
                "village_id": 4,
                "village_name": "इब्राहिमपुर"
            },
            {
                "label": "एकम्‍बा 0116",
                "village_id": 5,
                "village_name": "एकम्‍बा"
            },
            {
                "label": "ऐजनी 0052",
                "village_id": 6,
                "village_name": "ऐजनी"
            },
            {
                "label": "कोला हसन 0033",
                "village_id": 7,
                "village_name": "कोला हसन"
            },
            {
                "label": "कोला हसन 0031",
                "village_id": 8,
                "village_name": "कोला हसन"
            },
            {
                "label": "गौरी डीह 0012",
                "village_id": 9,
                "village_name": "गौरी डीह"
            },
            {
                "label": "जाना 0078",
                "village_id": 10,
                "village_name": "जाना"
            },
            {
                "label": "झराही 0005",
                "village_id": 11,
                "village_name": "झराही"
            },
            {
                "label": "डुमरी 0118",
                "village_id": 12,
                "village_name": "डुमरी"
            },
            {
                "label": "डीही 0120",
                "village_id": 13,
                "village_name": "डीही"
            },
            {
                "label": "दरहाबाद 0020",
                "village_id": 14,
                "village_name": "दरहाबाद"
            },
            {
                "label": "देवनियां 0019",
                "village_id": 15,
                "village_name": "देवनियां"
            },
            {
                "label": "नारायण पीपर 0117",
                "village_id": 16,
                "village_name": "नारायण पीपर"
            },
            {
                "label": "पताही 0037",
                "village_id": 17,
                "village_name": "पताही"
            },
            {
                "label": "पनसल्‍ला 0079",
                "village_id": 18,
                "village_name": "पनसल्‍ला"
            },
            {
                "label": "पुरपथार 0009",
                "village_id": 19,
                "village_name": "पुरपथार"
            },
            {
                "label": "प्राणपुर 0002",
                "village_id": 20,
                "village_name": "प्राणपुर"
            },
            {
                "label": "परोड़ा 0119",
                "village_id": 21,
                "village_name": "परोड़ा"
            },
            {
                "label": "पिढिया 0015",
                "village_id": 22,
                "village_name": "पिढिया"
            },
            {
                "label": "बखड्डा 0056",
                "village_id": 23,
                "village_name": "बखड्डा"
            },
            {
                "label": "बेगा 0054",
                "village_id": 24,
                "village_name": "बेगा"
            },
            {
                "label": "बझमोतरा 0041",
                "village_id": 25,
                "village_name": "बझमोतरा"
            },
            {
                "label": "बथौल 0023",
                "village_id": 26,
                "village_name": "बथौल"
            },
            {
                "label": "बरैपुरा 0022",
                "village_id": 27,
                "village_name": "बरैपुरा"
            },
            {
                "label": "बाद बखड्डा 0055",
                "village_id": 28,
                "village_name": "बाद बखड्डा"
            },
            {
                "label": "बांध कमरौली 0008",
                "village_id": 29,
                "village_name": "बांध कमरौली"
            },
            {
                "label": "भोजा 0073",
                "village_id": 30,
                "village_name": "भोजा"
            },
            {
                "label": "मझवरिया 0039",
                "village_id": 31,
                "village_name": "मझवरिया"
            },
            {
                "label": "मटिहानी 0058",
                "village_id": 32,
                "village_name": "मटिहानी"
            },
            {
                "label": "मुरलीडीह 0018",
                "village_id": 33,
                "village_name": "मुरलीडीह"
            },
            {
                "label": "महिशाहा 0011",
                "village_id": 34,
                "village_name": "महिशाहा"
            },
            {
                "label": "मांगी जपुनी 0010",
                "village_id": 35,
                "village_name": "मांगी जपुनी"
            },
            {
                "label": "मालपुर 0024",
                "village_id": 36,
                "village_name": "मालपुर"
            },
            {
                "label": "मिल्‍की 0061",
                "village_id": 37,
                "village_name": "मिल्‍की"
            },
            {
                "label": "मोहना 0035",
                "village_id": 38,
                "village_name": "मोहना"
            },
            {
                "label": "मोहनियां 0004",
                "village_id": 39,
                "village_name": "मोहनियां"
            },
            {
                "label": "राजोपुर 0040",
                "village_id": 40,
                "village_name": "राजोपुर"
            },
            {
                "label": "रामपुर कचहरी 0030",
                "village_id": 41,
                "village_name": "रामपुर कचहरी"
            },
            {
                "label": "रायगाछी 0027",
                "village_id": 42,
                "village_name": "रायगाछी"
            },
            {
                "label": "लखनपट्टी 0028",
                "village_id": 43,
                "village_name": "लखनपट्टी"
            },
            {
                "label": "वाजितपुर 0003",
                "village_id": 44,
                "village_name": "वाजितपुर"
            },
            {
                "label": "विशनपुर 0007",
                "village_id": 45,
                "village_name": "विशनपुर"
            },
            {
                "label": "विशनपुर 0038",
                "village_id": 46,
                "village_name": "विशनपुर"
            },
            {
                "label": "विष्‍णुपुर 0029",
                "village_id": 47,
                "village_name": "विष्‍णुपुर"
            },
            {
                "label": "श्‍यामपुर 0025",
                "village_id": 48,
                "village_name": "श्‍यामपुर"
            },
            {
                "label": "शाहपुर 0060",
                "village_id": 49,
                "village_name": "शाहपुर"
            },
            {
                "label": "सनहुआ 0017",
                "village_id": 50,
                "village_name": "सनहुआ"
            },
            {
                "label": "सपहुआ 0016",
                "village_id": 51,
                "village_name": "सपहुआ"
            },
            {
                "label": "सहुरी 0021",
                "village_id": 52,
                "village_name": "सहुरी"
            },
            {
                "label": "सावंत 0057",
                "village_id": 53,
                "village_name": "सावंत"
            },
            {
                "label": "सिरपाली 0014",
                "village_id": 54,
                "village_name": "सिरपाली"
            },
            {
                "label": "सिहमा 0032",
                "village_id": 55,
                "village_name": "सिहमा"
            },
            {
                "label": "सोनवर्षा 0034",
                "village_id": 56,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "हजराहा 0006",
                "village_id": 57,
                "village_name": "हजराहा"
            },
            {
                "label": "हरपुर 0026",
                "village_id": 58,
                "village_name": "हरपुर"
            },
            {
                "label": "हरपुर इब्राहिमपुर 0001",
                "village_id": 59,
                "village_name": "हरपुर इब्राहिमपुर"
            },
            {
                "label": "हरेरामपुर 0053",
                "village_id": 60,
                "village_name": "हरेरामपुर"
            }
        ],
        "block_id": "18",
        "block_no": 17,
        "block_name": "छौड़ाही [018]",
        "sub_div_code": "5"
    }
],
    "district_id": "20",
    "district_name": "बेगुसराय"
},
{
    "block": [
    {
        "village": [
            {
                "label": "अकुजली 0193",
                "village_id": 2,
                "village_name": "अकुजली"
            },
            {
                "label": "अकौर 0197",
                "village_id": 3,
                "village_name": "अकौर"
            },
            {
                "label": "अगैय 0122",
                "village_id": 4,
                "village_name": "अगैय"
            },
            {
                "label": "अग्रपट्टी 0117",
                "village_id": 5,
                "village_name": "अग्रपट्टी"
            },
            {
                "label": "अतरौली 0189",
                "village_id": 6,
                "village_name": "अतरौली"
            },
            {
                "label": "अंधरी 0180",
                "village_id": 7,
                "village_name": "अंधरी"
            },
            {
                "label": "अधवारी 0040",
                "village_id": 8,
                "village_name": "अधवारी"
            },
            {
                "label": "अरेर 0223",
                "village_id": 9,
                "village_name": "अरेर"
            },
            {
                "label": "आह 0142",
                "village_id": 10,
                "village_name": "आह"
            },
            {
                "label": "उचैठ 0089",
                "village_id": 11,
                "village_name": "उचैठ"
            },
            {
                "label": "उरेन 0135",
                "village_id": 12,
                "village_name": "उरेन"
            },
            {
                "label": "एकतारा 0203",
                "village_id": 13,
                "village_name": "एकतारा"
            },
            {
                "label": "एरुआ 0187",
                "village_id": 14,
                "village_name": "एरुआ"
            },
            {
                "label": "एराजी अरेर 0222",
                "village_id": 15,
                "village_name": "एराजी अरेर"
            },
            {
                "label": "एराजी उचैठ 0088",
                "village_id": 16,
                "village_name": "एराजी उचैठ"
            },
            {
                "label": "एराजी जगत 0091",
                "village_id": 17,
                "village_name": "एराजी जगत"
            },
            {
                "label": "एराजी परौल 0210",
                "village_id": 18,
                "village_name": "एराजी परौल"
            },
            {
                "label": "एराजी परौल 0211",
                "village_id": 19,
                "village_name": "एराजी परौल"
            },
            {
                "label": "एराजी पंरौल 0206",
                "village_id": 20,
                "village_name": "एराजी पंरौल"
            },
            {
                "label": "एराजी वसैठ 0132",
                "village_id": 21,
                "village_name": "एराजी वसैठ"
            },
            {
                "label": "कछरा 0092",
                "village_id": 22,
                "village_name": "कछरा"
            },
            {
                "label": "कटैया 0182",
                "village_id": 23,
                "village_name": "कटैया"
            },
            {
                "label": "कपसिया 0215",
                "village_id": 24,
                "village_name": "कपसिया"
            },
            {
                "label": "कमराही 0240",
                "village_id": 25,
                "village_name": "कमराही"
            },
            {
                "label": "करहारा 0134",
                "village_id": 26,
                "village_name": "करहारा"
            },
            {
                "label": "करही 0201",
                "village_id": 27,
                "village_name": "करही"
            },
            {
                "label": "करही 0199",
                "village_id": 28,
                "village_name": "करही"
            },
            {
                "label": "कुसमौल 0220",
                "village_id": 29,
                "village_name": "कुसमौल"
            },
            {
                "label": "केसुली 0034",
                "village_id": 30,
                "village_name": "केसुली"
            },
            {
                "label": "खुटौना 0042",
                "village_id": 31,
                "village_name": "खुटौना"
            },
            {
                "label": "गंगुली 0143",
                "village_id": 32,
                "village_name": "गंगुली"
            },
            {
                "label": "गेंदौल 0177",
                "village_id": 33,
                "village_name": "गेंदौल"
            },
            {
                "label": "चतभेदी 0041",
                "village_id": 34,
                "village_name": "चतभेदी"
            },
            {
                "label": "चतरा 0192",
                "village_id": 35,
                "village_name": "चतरा"
            },
            {
                "label": "चननपुरा 0191",
                "village_id": 36,
                "village_name": "चननपुरा"
            },
            {
                "label": "चाँदपुरपट्टी 0110",
                "village_id": 37,
                "village_name": "चाँदपुरपट्टी"
            },
            {
                "label": "चाँदपुरपट्टी 0111",
                "village_id": 38,
                "village_name": "चाँदपुरपट्टी"
            },
            {
                "label": "चानपुरा 0112",
                "village_id": 39,
                "village_name": "चानपुरा"
            },
            {
                "label": "चानपुरा 0109",
                "village_id": 40,
                "village_name": "चानपुरा"
            },
            {
                "label": "छोलकारा 0127",
                "village_id": 41,
                "village_name": "छोलकारा"
            },
            {
                "label": "जगत 0090",
                "village_id": 42,
                "village_name": "जगत"
            },
            {
                "label": "झोंझी 0212",
                "village_id": 43,
                "village_name": "झोंझी"
            },
            {
                "label": "डुमरा 0196",
                "village_id": 44,
                "village_name": "डुमरा"
            },
            {
                "label": "ढंगा 0209",
                "village_id": 45,
                "village_name": "ढंगा"
            },
            {
                "label": "तेयुथ 0039",
                "village_id": 46,
                "village_name": "तेयुथ"
            },
            {
                "label": "देपुरा 0194",
                "village_id": 47,
                "village_name": "देपुरा"
            },
            {
                "label": "देवरी 0086",
                "village_id": 48,
                "village_name": "देवरी"
            },
            {
                "label": "दामोदरपुर 0141",
                "village_id": 49,
                "village_name": "दामोदरपुर"
            },
            {
                "label": "धकजरी 0190",
                "village_id": 50,
                "village_name": "धकजरी"
            },
            {
                "label": "धनुखी 0113",
                "village_id": 51,
                "village_name": "धनुखी"
            },
            {
                "label": "धनौजा 0087",
                "village_id": 52,
                "village_name": "धनौजा"
            },
            {
                "label": "नजरा 0137",
                "village_id": 53,
                "village_name": "नजरा"
            },
            {
                "label": "नवकरही 0200",
                "village_id": 54,
                "village_name": "नवकरही"
            },
            {
                "label": "नागदह बलैन 0219",
                "village_id": 55,
                "village_name": "नागदह बलैन"
            },
            {
                "label": "परकौली टिकुली 0188",
                "village_id": 56,
                "village_name": "परकौली टिकुली"
            },
            {
                "label": "परजुआर 0208",
                "village_id": 57,
                "village_name": "परजुआर"
            },
            {
                "label": "परसौना 0186",
                "village_id": 58,
                "village_name": "परसौना"
            },
            {
                "label": "परौल 0207",
                "village_id": 59,
                "village_name": "परौल"
            },
            {
                "label": "पाली 0140",
                "village_id": 60,
                "village_name": "पाली"
            },
            {
                "label": "पौना 0218",
                "village_id": 61,
                "village_name": "पौना"
            },
            {
                "label": "बढमोतरा लक्ष्मीपुर 0221",
                "village_id": 62,
                "village_name": "बढमोतरा लक्ष्मीपुर"
            },
            {
                "label": "बेतौना 0093",
                "village_id": 63,
                "village_name": "बेतौना"
            },
            {
                "label": "बनकट्टा 0183",
                "village_id": 64,
                "village_name": "बनकट्टा"
            },
            {
                "label": "बन्दोलवा 0126",
                "village_id": 65,
                "village_name": "बन्दोलवा"
            },
            {
                "label": "बेनीपट्टी 0185",
                "village_id": 66,
                "village_name": "बेनीपट्टी"
            },
            {
                "label": "ब्रम्हपुरा 0195",
                "village_id": 67,
                "village_name": "ब्रम्हपुरा"
            },
            {
                "label": "बररी चौगामा 0115",
                "village_id": 68,
                "village_name": "बररी चौगामा"
            },
            {
                "label": "बराठपुर 0038",
                "village_id": 69,
                "village_name": "बराठपुर"
            },
            {
                "label": "बलिया 0144",
                "village_id": 70,
                "village_name": "बलिया"
            },
            {
                "label": "बसैठ 0131",
                "village_id": 71,
                "village_name": "बसैठ"
            },
            {
                "label": "बेहटा 0184",
                "village_id": 72,
                "village_name": "बेहटा"
            },
            {
                "label": "बिजलपुरा 0214",
                "village_id": 73,
                "village_name": "बिजलपुरा"
            },
            {
                "label": "भगवतीपुर 0121",
                "village_id": 74,
                "village_name": "भगवतीपुर"
            },
            {
                "label": "भदुली 0224",
                "village_id": 75,
                "village_name": "भदुली"
            },
            {
                "label": "मकिया 0118",
                "village_id": 76,
                "village_name": "मकिया"
            },
            {
                "label": "मतरहरि 0130",
                "village_id": 77,
                "village_name": "मतरहरि"
            },
            {
                "label": "मतरहरी 0133",
                "village_id": 78,
                "village_name": "मतरहरी"
            },
            {
                "label": "मेधवन 0138",
                "village_id": 79,
                "village_name": "मेधवन"
            },
            {
                "label": "मनपौर 0035",
                "village_id": 80,
                "village_name": "मनपौर"
            },
            {
                "label": "मुरहेठ 0213",
                "village_id": 81,
                "village_name": "मुरहेठ"
            },
            {
                "label": "महुआ 0204",
                "village_id": 82,
                "village_name": "महुआ"
            },
            {
                "label": "माधोपुर 0114",
                "village_id": 83,
                "village_name": "माधोपुर"
            },
            {
                "label": "मिर्जापुर 0216",
                "village_id": 84,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "मोहम्मदपुर 0037",
                "village_id": 85,
                "village_name": "मोहम्मदपुर"
            },
            {
                "label": "रजबन 0139",
                "village_id": 86,
                "village_name": "रजबन"
            },
            {
                "label": "रानीपुरा 0136",
                "village_id": 87,
                "village_name": "रानीपुरा"
            },
            {
                "label": "रामपुर 0125",
                "village_id": 88,
                "village_name": "रामपुर"
            },
            {
                "label": "लगवास 0198",
                "village_id": 89,
                "village_name": "लगवास"
            },
            {
                "label": "लदौत 0036",
                "village_id": 90,
                "village_name": "लदौत"
            },
            {
                "label": "लरोगाँवां 0120",
                "village_id": 91,
                "village_name": "लरोगाँवां"
            },
            {
                "label": "लोरिका 0095",
                "village_id": 92,
                "village_name": "लोरिका"
            },
            {
                "label": "वगवासा 0123",
                "village_id": 93,
                "village_name": "वगवासा"
            },
            {
                "label": "वैजलपुर 0239",
                "village_id": 94,
                "village_name": "वैजलपुर"
            },
            {
                "label": "विशनपुर 0116",
                "village_id": 95,
                "village_name": "विशनपुर"
            },
            {
                "label": "विसोलरगाँवां 0119",
                "village_id": 96,
                "village_name": "विसोलरगाँवां"
            },
            {
                "label": "शिवनगर 0128",
                "village_id": 97,
                "village_name": "शिवनगर"
            },
            {
                "label": "समदा 0094",
                "village_id": 98,
                "village_name": "समदा"
            },
            {
                "label": "सलहा 0043",
                "village_id": 99,
                "village_name": "सलहा"
            },
            {
                "label": "साहपुर 0124",
                "village_id": 100,
                "village_name": "साहपुर"
            },
            {
                "label": "सोनहौली 0129",
                "village_id": 101,
                "village_name": "सोनहौली"
            },
            {
                "label": "सोहरौल 0108",
                "village_id": 102,
                "village_name": "सोहरौल"
            },
            {
                "label": "हकिराना 0205",
                "village_id": 103,
                "village_name": "हकिराना"
            },
            {
                "label": "हिबा 0202",
                "village_id": 104,
                "village_name": "हिबा"
            }
        ],
        "block_id": "1",
        "block_no": 0,
        "block_name": "बेनीपट्टी [001]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अजनौली 0227",
                "village_id": 2,
                "village_name": "अजनौली"
            },
            {
                "label": "अजरकबेजफर 0231",
                "village_id": 3,
                "village_name": "अजरकबेजफर"
            },
            {
                "label": "अंवसीबभनगॉवां 0243",
                "village_id": 4,
                "village_name": "अंवसीबभनगॉवां"
            },
            {
                "label": "इनामात 0246",
                "village_id": 5,
                "village_name": "इनामात"
            },
            {
                "label": "इनायत 0238",
                "village_id": 6,
                "village_name": "इनायत"
            },
            {
                "label": "उसरीह 0176",
                "village_id": 7,
                "village_name": "उसरीह"
            },
            {
                "label": "उसौठ 0165",
                "village_id": 8,
                "village_name": "उसौठ"
            },
            {
                "label": "एकराही 0173",
                "village_id": 9,
                "village_name": "एकराही"
            },
            {
                "label": "एराजी उसौठ 0166",
                "village_id": 10,
                "village_name": "एराजी उसौठ"
            },
            {
                "label": "कुरवार 0230",
                "village_id": 11,
                "village_name": "कुरवार"
            },
            {
                "label": "खैरीबाका 0241",
                "village_id": 12,
                "village_name": "खैरीबाका"
            },
            {
                "label": "गढिया 0237",
                "village_id": 13,
                "village_name": "गढिया"
            },
            {
                "label": "गनौर 0229",
                "village_id": 14,
                "village_name": "गनौर"
            },
            {
                "label": "गोरहएल 0160",
                "village_id": 15,
                "village_name": "गोरहएल"
            },
            {
                "label": "चनुबांहा 0235",
                "village_id": 16,
                "village_name": "चनुबांहा"
            },
            {
                "label": "चपरिया 0169",
                "village_id": 17,
                "village_name": "चपरिया"
            },
            {
                "label": "चौहटा 0159",
                "village_id": 18,
                "village_name": "चौहटा"
            },
            {
                "label": "जगवन 0149",
                "village_id": 19,
                "village_name": "जगवन"
            },
            {
                "label": "जफरा 0232",
                "village_id": 20,
                "village_name": "जफरा"
            },
            {
                "label": "जयरामपुर 0161",
                "village_id": 21,
                "village_name": "जयरामपुर"
            },
            {
                "label": "जानीपुर 0151",
                "village_id": 22,
                "village_name": "जानीपुर"
            },
            {
                "label": "तीसीनरसाम 0154",
                "village_id": 23,
                "village_name": "तीसीनरसाम"
            },
            {
                "label": "दुधैल 0163",
                "village_id": 24,
                "village_name": "दुधैल"
            },
            {
                "label": "दमला 0155",
                "village_id": 25,
                "village_name": "दमला"
            },
            {
                "label": "धेपुर 0242",
                "village_id": 26,
                "village_name": "धेपुर"
            },
            {
                "label": "नूरचौक 0170",
                "village_id": 27,
                "village_name": "नूरचौक"
            },
            {
                "label": "नाहसरूपौली 0217",
                "village_id": 28,
                "village_name": "नाहसरूपौली"
            },
            {
                "label": "पतौना 0175",
                "village_id": 29,
                "village_name": "पतौना"
            },
            {
                "label": "परसौनी 0178",
                "village_id": 30,
                "village_name": "परसौनी"
            },
            {
                "label": "परोही 0174",
                "village_id": 31,
                "village_name": "परोही"
            },
            {
                "label": "बेंगरा 0156",
                "village_id": 32,
                "village_name": "बेंगरा"
            },
            {
                "label": "बजराहा 0228",
                "village_id": 33,
                "village_name": "बजराहा"
            },
            {
                "label": "बरदाहा 0147",
                "village_id": 34,
                "village_name": "बरदाहा"
            },
            {
                "label": "बलहा 0157",
                "village_id": 35,
                "village_name": "बलहा"
            },
            {
                "label": "बेलौज 0234",
                "village_id": 36,
                "village_name": "बेलौज"
            },
            {
                "label": "भगौती 0236",
                "village_id": 37,
                "village_name": "भगौती"
            },
            {
                "label": "भैरवा 0172",
                "village_id": 38,
                "village_name": "भैरवा"
            },
            {
                "label": "भोजपंडौल 0244",
                "village_id": 39,
                "village_name": "भोजपंडौल"
            },
            {
                "label": "मधपुर 0148",
                "village_id": 40,
                "village_name": "मधपुर"
            },
            {
                "label": "मरवा 0158",
                "village_id": 41,
                "village_name": "मरवा"
            },
            {
                "label": "माधोपुर 0152",
                "village_id": 42,
                "village_name": "माधोपुर"
            },
            {
                "label": "मोकरम 0233",
                "village_id": 43,
                "village_name": "मोकरम"
            },
            {
                "label": "रकौस 0146",
                "village_id": 44,
                "village_name": "रकौस"
            },
            {
                "label": "रघौली 0164",
                "village_id": 45,
                "village_name": "रघौली"
            },
            {
                "label": "रधेपुरा 0226",
                "village_id": 46,
                "village_name": "रधेपुरा"
            },
            {
                "label": "राघोपुर 0181",
                "village_id": 47,
                "village_name": "राघोपुर"
            },
            {
                "label": "राजीपरसौनी 0179",
                "village_id": 48,
                "village_name": "राजीपरसौनी"
            },
            {
                "label": "विस्फी 0171",
                "village_id": 49,
                "village_name": "विस्फी"
            },
            {
                "label": "शिवौल 0145",
                "village_id": 50,
                "village_name": "शिवौल"
            },
            {
                "label": "सरस्वती 0153",
                "village_id": 51,
                "village_name": "सरस्वती"
            },
            {
                "label": "सुलभपुर 0243",
                "village_id": 52,
                "village_name": "सुलभपुर"
            },
            {
                "label": "सहदुल्लापुर 0162",
                "village_id": 53,
                "village_name": "सहदुल्लापुर"
            },
            {
                "label": "सिंगहासो 0168",
                "village_id": 54,
                "village_name": "सिंगहासो"
            },
            {
                "label": "सिधी 0167",
                "village_id": 55,
                "village_name": "सिधी"
            },
            {
                "label": "सिमरी 0245",
                "village_id": 56,
                "village_name": "सिमरी"
            },
            {
                "label": "सोहास 0225",
                "village_id": 57,
                "village_name": "सोहास"
            },
            {
                "label": "हीरोपट्टी 0150",
                "village_id": 58,
                "village_name": "हीरोपट्टी"
            }
        ],
        "block_id": "2",
        "block_no": 1,
        "block_name": "विस्फी [002]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अकहा 0071",
                "village_id": 2,
                "village_name": "अकहा"
            },
            {
                "label": "अन्दौली 0099",
                "village_id": 3,
                "village_name": "अन्दौली"
            },
            {
                "label": "आवान 0098",
                "village_id": 4,
                "village_name": "आवान"
            },
            {
                "label": "आवारी इमनात 0075",
                "village_id": 5,
                "village_name": "आवारी इमनात"
            },
            {
                "label": "उत्तरा 0078",
                "village_id": 6,
                "village_name": "उत्तरा"
            },
            {
                "label": "एकारी 0102",
                "village_id": 7,
                "village_name": "एकारी"
            },
            {
                "label": "औरा 0064",
                "village_id": 8,
                "village_name": "औरा"
            },
            {
                "label": "करहुआ 0074",
                "village_id": 9,
                "village_name": "करहुआ"
            },
            {
                "label": "डुमरा 0000",
                "village_id": 10,
                "village_name": "डुमरा"
            },
            {
                "label": "तरैया 0105",
                "village_id": 11,
                "village_name": "तरैया"
            },
            {
                "label": "दुर्गापुरपट्टी 0062",
                "village_id": 12,
                "village_name": "दुर्गापुरपट्टी"
            },
            {
                "label": "पकड़शाम 0097",
                "village_id": 13,
                "village_name": "पकड़शाम"
            },
            {
                "label": "पकड़ी 0106",
                "village_id": 14,
                "village_name": "पकड़ी"
            },
            {
                "label": "पतार 0100",
                "village_id": 15,
                "village_name": "पतार"
            },
            {
                "label": "परसा 0059",
                "village_id": 16,
                "village_name": "परसा"
            },
            {
                "label": "पिरोखा 0101",
                "village_id": 17,
                "village_name": "पिरोखा"
            },
            {
                "label": "पोखरौनी 0083",
                "village_id": 18,
                "village_name": "पोखरौनी"
            },
            {
                "label": "पोखरौनी वृत्त 0084",
                "village_id": 19,
                "village_name": "पोखरौनी वृत्त"
            },
            {
                "label": "बेंगरा 0076",
                "village_id": 20,
                "village_name": "बेंगरा"
            },
            {
                "label": "बछौना 0104",
                "village_id": 21,
                "village_name": "बछौना"
            },
            {
                "label": "बम्बरिया 0070",
                "village_id": 22,
                "village_name": "बम्बरिया"
            },
            {
                "label": "बलवा 0065",
                "village_id": 23,
                "village_name": "बलवा"
            },
            {
                "label": "बासुली विहारी 0061",
                "village_id": 24,
                "village_name": "बासुली विहारी"
            },
            {
                "label": "मुखियापट्टी 0068",
                "village_id": 25,
                "village_name": "मुखियापट्टी"
            },
            {
                "label": "मछुआ 0080",
                "village_id": 26,
                "village_name": "मछुआ"
            },
            {
                "label": "मधुडी 0066",
                "village_id": 27,
                "village_name": "मधुडी"
            },
            {
                "label": "मधवापुर 0057",
                "village_id": 28,
                "village_name": "मधवापुर"
            },
            {
                "label": "मरहट सोगरौली 0073",
                "village_id": 29,
                "village_name": "मरहट सोगरौली"
            },
            {
                "label": "मिन्ती 0096",
                "village_id": 30,
                "village_name": "मिन्ती"
            },
            {
                "label": "रतौली 0082",
                "village_id": 31,
                "village_name": "रतौली"
            },
            {
                "label": "रैयमा 0049",
                "village_id": 32,
                "village_name": "रैयमा"
            },
            {
                "label": "रामनगर वगरा 0060",
                "village_id": 33,
                "village_name": "रामनगर वगरा"
            },
            {
                "label": "रामपुर 0058",
                "village_id": 34,
                "village_name": "रामपुर"
            },
            {
                "label": "रामपुरवृत 0056",
                "village_id": 35,
                "village_name": "रामपुरवृत"
            },
            {
                "label": "लौमा 0079",
                "village_id": 36,
                "village_name": "लौमा"
            },
            {
                "label": "वैरवा 0067",
                "village_id": 37,
                "village_name": "वैरवा"
            },
            {
                "label": "विसनपुर 0107",
                "village_id": 38,
                "village_name": "विसनपुर"
            },
            {
                "label": "सुजातपुर 0103",
                "village_id": 39,
                "village_name": "सुजातपुर"
            },
            {
                "label": "सलेमपुर 0081",
                "village_id": 40,
                "village_name": "सलेमपुर"
            },
            {
                "label": "साहर 0069",
                "village_id": 41,
                "village_name": "साहर"
            },
            {
                "label": "सिंघवारा 0077",
                "village_id": 42,
                "village_name": "सिंघवारा"
            },
            {
                "label": "सोगरौली 0072",
                "village_id": 43,
                "village_name": "सोगरौली"
            },
            {
                "label": "हनुमान नगर 0063",
                "village_id": 44,
                "village_name": "हनुमान नगर"
            }
        ],
        "block_id": "3",
        "block_no": 2,
        "block_name": "मधवापुर [003]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "उमगाँव 0009",
                "village_id": 2,
                "village_name": "उमगाँव"
            },
            {
                "label": "कुन्डल गढ़िया 0017",
                "village_id": 3,
                "village_name": "कुन्डल गढ़िया"
            },
            {
                "label": "कमतौल 0052",
                "village_id": 4,
                "village_name": "कमतौल"
            },
            {
                "label": "कमतौल 0053",
                "village_id": 5,
                "village_name": "कमतौल"
            },
            {
                "label": "कमलावरपट्टी 0001",
                "village_id": 6,
                "village_name": "कमलावरपट्टी"
            },
            {
                "label": "करुणा 0002",
                "village_id": 7,
                "village_name": "करुणा"
            },
            {
                "label": "कलना 0019",
                "village_id": 8,
                "village_name": "कलना"
            },
            {
                "label": "कसेरा 0007",
                "village_id": 9,
                "village_name": "कसेरा"
            },
            {
                "label": "कौवाहा बरही 0026",
                "village_id": 10,
                "village_name": "कौवाहा बरही"
            },
            {
                "label": "खिरहर 0033",
                "village_id": 11,
                "village_name": "खिरहर"
            },
            {
                "label": "गंगौर 0055",
                "village_id": 12,
                "village_name": "गंगौर"
            },
            {
                "label": "गांपालपुर 0054",
                "village_id": 13,
                "village_name": "गांपालपुर"
            },
            {
                "label": "जिरौल 0046",
                "village_id": 14,
                "village_name": "जिरौल"
            },
            {
                "label": "झिटकी 0032",
                "village_id": 15,
                "village_name": "झिटकी"
            },
            {
                "label": "दुबेपट्टी 0010",
                "village_id": 16,
                "village_name": "दुबेपट्टी"
            },
            {
                "label": "नहरनियाँ 0006",
                "village_id": 17,
                "village_name": "नहरनियाँ"
            },
            {
                "label": "पहरा 0030",
                "village_id": 18,
                "village_name": "पहरा"
            },
            {
                "label": "पिपरौन 0022",
                "village_id": 19,
                "village_name": "पिपरौन"
            },
            {
                "label": "पोतहाह 0012",
                "village_id": 20,
                "village_name": "पोतहाह"
            },
            {
                "label": "फूलहर 0023",
                "village_id": 21,
                "village_name": "फूलहर"
            },
            {
                "label": "बेंगरा 0045",
                "village_id": 22,
                "village_name": "बेंगरा"
            },
            {
                "label": "बेतापरसा 0024",
                "village_id": 23,
                "village_name": "बेतापरसा"
            },
            {
                "label": "बलुवाहा 0018",
                "village_id": 24,
                "village_name": "बलुवाहा"
            },
            {
                "label": "बिसौल 0003",
                "village_id": 25,
                "village_name": "बिसौल"
            },
            {
                "label": "बोरहर 0047",
                "village_id": 26,
                "village_name": "बोरहर"
            },
            {
                "label": "मंगरहट्टा 0050",
                "village_id": 27,
                "village_name": "मंगरहट्टा"
            },
            {
                "label": "मनोहरपुर 0051",
                "village_id": 28,
                "village_name": "मनोहरपुर"
            },
            {
                "label": "महादेवपट्टी 0008",
                "village_id": 29,
                "village_name": "महादेवपट्टी"
            },
            {
                "label": "माँगपट्टी 0014",
                "village_id": 30,
                "village_name": "माँगपट्टी"
            },
            {
                "label": "मोहनपुर 0013",
                "village_id": 31,
                "village_name": "मोहनपुर"
            },
            {
                "label": "रामपुर 0016",
                "village_id": 32,
                "village_name": "रामपुर"
            },
            {
                "label": "विर्त्ता 0005",
                "village_id": 33,
                "village_name": "विर्त्ता"
            },
            {
                "label": "विरहर 0020",
                "village_id": 34,
                "village_name": "विरहर"
            },
            {
                "label": "शेम्ली 0044",
                "village_id": 35,
                "village_name": "शेम्ली"
            },
            {
                "label": "सुखवासी 0028",
                "village_id": 36,
                "village_name": "सुखवासी"
            },
            {
                "label": "सिसौनी 0027",
                "village_id": 37,
                "village_name": "सिसौनी"
            },
            {
                "label": "सोढगांव 0021",
                "village_id": 38,
                "village_name": "सोढगांव"
            },
            {
                "label": "सोनई 0048",
                "village_id": 39,
                "village_name": "सोनई"
            },
            {
                "label": "सोहपुर 0015",
                "village_id": 40,
                "village_name": "सोहपुर"
            },
            {
                "label": "हटबरिया 0011",
                "village_id": 41,
                "village_name": "हटबरिया"
            },
            {
                "label": "हरराही 0025",
                "village_id": 42,
                "village_name": "हरराही"
            },
            {
                "label": "हरलाखी 0004",
                "village_id": 43,
                "village_name": "हरलाखी"
            },
            {
                "label": "हरूसवार 0029",
                "village_id": 44,
                "village_name": "हरूसवार"
            },
            {
                "label": "हिसार 0031",
                "village_id": 45,
                "village_name": "हिसार"
            }
        ],
        "block_id": "4",
        "block_no": 3,
        "block_name": "हरलाखी [004]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अजरकवे शंकरपुर 0085",
                "village_id": 2,
                "village_name": "अजरकवे शंकरपुर"
            },
            {
                "label": "आले 0130",
                "village_id": 3,
                "village_name": "आले"
            },
            {
                "label": "इजरा 0019",
                "village_id": 4,
                "village_name": "इजरा"
            },
            {
                "label": "इजरापट्टी जगत 0011",
                "village_id": 5,
                "village_name": "इजरापट्टी जगत"
            },
            {
                "label": "इजरापट्टी जगत 0012",
                "village_id": 6,
                "village_name": "इजरापट्टी जगत"
            },
            {
                "label": "इजरापट्टी जगत 0013",
                "village_id": 7,
                "village_name": "इजरापट्टी जगत"
            },
            {
                "label": "इजरापट्टी जगत 0014",
                "village_id": 8,
                "village_name": "इजरापट्टी जगत"
            },
            {
                "label": "एराजी बसौली 0015",
                "village_id": 9,
                "village_name": "एराजी बसौली"
            },
            {
                "label": "ककरौड़ 0008",
                "village_id": 10,
                "village_name": "ककरौड़"
            },
            {
                "label": "कजीयाना 0069",
                "village_id": 11,
                "village_name": "कजीयाना"
            },
            {
                "label": "कटाई 0064",
                "village_id": 12,
                "village_name": "कटाई"
            },
            {
                "label": "कनैल 0040",
                "village_id": 13,
                "village_name": "कनैल"
            },
            {
                "label": "कमलपुर 0007",
                "village_id": 14,
                "village_name": "कमलपुर"
            },
            {
                "label": "केशोपुर 0072",
                "village_id": 15,
                "village_name": "केशोपुर"
            },
            {
                "label": "काजीयाना 0089",
                "village_id": 16,
                "village_name": "काजीयाना"
            },
            {
                "label": "कामत भवानीपुर 0034",
                "village_id": 17,
                "village_name": "कामत भवानीपुर"
            },
            {
                "label": "कोकड़ी 0023",
                "village_id": 18,
                "village_name": "कोकड़ी"
            },
            {
                "label": "खजुरी 0067",
                "village_id": 19,
                "village_name": "खजुरी"
            },
            {
                "label": "खरौआ 0009",
                "village_id": 20,
                "village_name": "खरौआ"
            },
            {
                "label": "खाजीपुर 0065",
                "village_id": 21,
                "village_name": "खाजीपुर"
            },
            {
                "label": "गजवा वसौली 0016",
                "village_id": 22,
                "village_name": "गजवा वसौली"
            },
            {
                "label": "गधवार 0088",
                "village_id": 23,
                "village_name": "गधवार"
            },
            {
                "label": "गनेशपुर 0070",
                "village_id": 24,
                "village_name": "गनेशपुर"
            },
            {
                "label": "चकदह मधुवनी 0033",
                "village_id": 25,
                "village_name": "चकदह मधुवनी"
            },
            {
                "label": "चन्द्रसैनपुर 0001",
                "village_id": 26,
                "village_name": "चन्द्रसैनपुर"
            },
            {
                "label": "जगत 0010",
                "village_id": 27,
                "village_name": "जगत"
            },
            {
                "label": "जगतपुर 0027",
                "village_id": 28,
                "village_name": "जगतपुर"
            },
            {
                "label": "जितवारपुर 0036",
                "village_id": 29,
                "village_name": "जितवारपुर"
            },
            {
                "label": "डगरभीट्टी 0075",
                "village_id": 30,
                "village_name": "डगरभीट्टी"
            },
            {
                "label": "डुमरी 0021",
                "village_id": 31,
                "village_name": "डुमरी"
            },
            {
                "label": "ढ़ेलफोरवा 0002",
                "village_id": 32,
                "village_name": "ढ़ेलफोरवा"
            },
            {
                "label": "ताराचन्द्रपुर 0093",
                "village_id": 33,
                "village_name": "ताराचन्द्रपुर"
            },
            {
                "label": "धनुषीवाँध जगतपुर 0028",
                "village_id": 34,
                "village_name": "धनुषीवाँध जगतपुर"
            },
            {
                "label": "नाजीरपुर 0041",
                "village_id": 35,
                "village_name": "नाजीरपुर"
            },
            {
                "label": "नीमा 0081",
                "village_id": 36,
                "village_name": "नीमा"
            },
            {
                "label": "पुराजी बसौली 0018",
                "village_id": 37,
                "village_name": "पुराजी बसौली"
            },
            {
                "label": "पोखरौनी 0025",
                "village_id": 38,
                "village_name": "पोखरौनी"
            },
            {
                "label": "फतेपुर 0071",
                "village_id": 39,
                "village_name": "फतेपुर"
            },
            {
                "label": "बलिया 0083",
                "village_id": 40,
                "village_name": "बलिया"
            },
            {
                "label": "बलीया बिजौली 0076",
                "village_id": 41,
                "village_name": "बलीया बिजौली"
            },
            {
                "label": "बसुआरा 0066",
                "village_id": 42,
                "village_name": "बसुआरा"
            },
            {
                "label": "बसौली 0017",
                "village_id": 43,
                "village_name": "बसौली"
            },
            {
                "label": "बहरबन 0043",
                "village_id": 44,
                "village_name": "बहरबन"
            },
            {
                "label": "भागरथपुर 0092",
                "village_id": 45,
                "village_name": "भागरथपुर"
            },
            {
                "label": "भौआड़ा 0062",
                "village_id": 46,
                "village_name": "भौआड़ा"
            },
            {
                "label": "मकसुदा 0095",
                "village_id": 47,
                "village_name": "मकसुदा"
            },
            {
                "label": "मखरौली 0063",
                "village_id": 48,
                "village_name": "मखरौली"
            },
            {
                "label": "मच्की 0030",
                "village_id": 49,
                "village_name": "मच्की"
            },
            {
                "label": "मलंगीया 0020",
                "village_id": 50,
                "village_name": "मलंगीया"
            },
            {
                "label": "महराजगंज 0032",
                "village_id": 51,
                "village_name": "महराजगंज"
            },
            {
                "label": "मोमीनपुर 0090",
                "village_id": 52,
                "village_name": "मोमीनपुर"
            },
            {
                "label": "मोरहा 0037",
                "village_id": 53,
                "village_name": "मोरहा"
            },
            {
                "label": "मोहीउद्दीनपुर 0091",
                "village_id": 54,
                "village_name": "मोहीउद्दीनपुर"
            },
            {
                "label": "रजहन 0068",
                "village_id": 55,
                "village_name": "रजहन"
            },
            {
                "label": "रहिका 0024",
                "village_id": 56,
                "village_name": "रहिका"
            },
            {
                "label": "रामखेतारी 0038",
                "village_id": 57,
                "village_name": "रामखेतारी"
            },
            {
                "label": "लक्ष्मीनगर 0031",
                "village_id": 58,
                "village_name": "लक्ष्मीनगर"
            },
            {
                "label": "लक्ष्मीपुर 0094",
                "village_id": 59,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लकसार 0004",
                "village_id": 60,
                "village_name": "लकसार"
            },
            {
                "label": "लखनपट्टी 0080",
                "village_id": 61,
                "village_name": "लखनपट्टी"
            },
            {
                "label": "वेलाम 0082",
                "village_id": 62,
                "village_name": "वेलाम"
            },
            {
                "label": "विरसार 0077",
                "village_id": 63,
                "village_name": "विरसार"
            },
            {
                "label": "शंकरपुर 0086",
                "village_id": 64,
                "village_name": "शंकरपुर"
            },
            {
                "label": "शैदपुर 0073",
                "village_id": 65,
                "village_name": "शैदपुर"
            },
            {
                "label": "शेरमा 0039",
                "village_id": 66,
                "village_name": "शेरमा"
            },
            {
                "label": "श्रीचनपुर 0042",
                "village_id": 67,
                "village_name": "श्रीचनपुर"
            },
            {
                "label": "श्रीरामपुर 0005",
                "village_id": 68,
                "village_name": "श्रीरामपुर"
            },
            {
                "label": "सतलखा 0003",
                "village_id": 69,
                "village_name": "सतलखा"
            },
            {
                "label": "सुन्दरपुर भीट्ठी 0079",
                "village_id": 70,
                "village_name": "सुन्दरपुर भीट्ठी"
            },
            {
                "label": "सनौर 0084",
                "village_id": 71,
                "village_name": "सनौर"
            },
            {
                "label": "सप्ता 0029",
                "village_id": 72,
                "village_name": "सप्ता"
            },
            {
                "label": "सप्तावाँध 29/2",
                "village_id": 73,
                "village_name": "सप्तावाँध"
            },
            {
                "label": "समयुआर 0074",
                "village_id": 74,
                "village_name": "समयुआर"
            },
            {
                "label": "समौल 0078",
                "village_id": 75,
                "village_name": "समौल"
            },
            {
                "label": "सलेमपुर सलहा 0129",
                "village_id": 76,
                "village_name": "सलेमपुर सलहा"
            },
            {
                "label": "सलहा 0087",
                "village_id": 77,
                "village_name": "सलहा"
            },
            {
                "label": "सौराठ 0026",
                "village_id": 78,
                "village_name": "सौराठ"
            },
            {
                "label": "हरेरामपट्टी 0022",
                "village_id": 79,
                "village_name": "हरेरामपट्टी"
            },
            {
                "label": "हुसैनपुर 0006",
                "village_id": 80,
                "village_name": "हुसैनपुर"
            }
        ],
        "block_id": "1",
        "block_no": 4,
        "block_name": "रहिका [001]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अजरकवे 0383",
                "village_id": 2,
                "village_name": "अजरकवे"
            },
            {
                "label": "अन्दह 0109",
                "village_id": 3,
                "village_name": "अन्दह"
            },
            {
                "label": "अनदह खजूरी 0113",
                "village_id": 4,
                "village_name": "अनदह खजूरी"
            },
            {
                "label": "अनदह खजूरी 113/",
                "village_id": 5,
                "village_name": "अनदह खजूरी"
            },
            {
                "label": "अनदह मनोहरपुर 109/",
                "village_id": 6,
                "village_name": "अनदह मनोहरपुर"
            },
            {
                "label": "अयोध्या 0097",
                "village_id": 7,
                "village_name": "अयोध्या"
            },
            {
                "label": "इमामात 0177",
                "village_id": 8,
                "village_name": "इमामात"
            },
            {
                "label": "इसहपुर 0151",
                "village_id": 9,
                "village_name": "इसहपुर"
            },
            {
                "label": "उदयपुर विठुआर 0388",
                "village_id": 10,
                "village_name": "उदयपुर विठुआर"
            },
            {
                "label": "एराजी भवानी 0107",
                "village_id": 11,
                "village_name": "एराजी भवानी"
            },
            {
                "label": "एराजी मौड़ 0118",
                "village_id": 12,
                "village_name": "एराजी मौड़"
            },
            {
                "label": "एराजी रामपुर 0119",
                "village_id": 13,
                "village_name": "एराजी रामपुर"
            },
            {
                "label": "कनकपुर 0100",
                "village_id": 14,
                "village_name": "कनकपुर"
            },
            {
                "label": "कमला वाड़ी 0138",
                "village_id": 15,
                "village_name": "कमला वाड़ी"
            },
            {
                "label": "कल्याणपुर 0115",
                "village_id": 16,
                "village_name": "कल्याणपुर"
            },
            {
                "label": "कल्याणपुर 0105",
                "village_id": 17,
                "village_name": "कल्याणपुर"
            },
            {
                "label": "खचौल 0386",
                "village_id": 18,
                "village_name": "खचौल"
            },
            {
                "label": "खनगाँव 0385",
                "village_id": 19,
                "village_name": "खनगाँव"
            },
            {
                "label": "गंगौली 0153",
                "village_id": 20,
                "village_name": "गंगौली"
            },
            {
                "label": "गुरमाहा 0117",
                "village_id": 21,
                "village_name": "गुरमाहा"
            },
            {
                "label": "गुरमाहा 0128",
                "village_id": 22,
                "village_name": "गुरमाहा"
            },
            {
                "label": "डमारी 0392",
                "village_id": 23,
                "village_name": "डमारी"
            },
            {
                "label": "दहिवत माधोपुर 0127",
                "village_id": 24,
                "village_name": "दहिवत माधोपुर"
            },
            {
                "label": "नरपति नगर 0099",
                "village_id": 25,
                "village_name": "नरपति नगर"
            },
            {
                "label": "नवहथ 0384",
                "village_id": 26,
                "village_name": "नवहथ"
            },
            {
                "label": "नाहर 0175",
                "village_id": 27,
                "village_name": "नाहर"
            },
            {
                "label": "पचाढ़ी 0126",
                "village_id": 28,
                "village_name": "पचाढ़ी"
            },
            {
                "label": "पण्डौल 0108",
                "village_id": 29,
                "village_name": "पण्डौल"
            },
            {
                "label": "पहल वाद 0152",
                "village_id": 30,
                "village_name": "पहल वाद"
            },
            {
                "label": "फतेपुर एकडीहा 0125",
                "village_id": 31,
                "village_name": "फतेपुर एकडीहा"
            },
            {
                "label": "बरगोरिया 0390",
                "village_id": 32,
                "village_name": "बरगोरिया"
            },
            {
                "label": "बहेगरा 0104",
                "village_id": 33,
                "village_name": "बहेगरा"
            },
            {
                "label": "बिरौल 0174",
                "village_id": 34,
                "village_name": "बिरौल"
            },
            {
                "label": "भगवतीपुर 0176",
                "village_id": 35,
                "village_name": "भगवतीपुर"
            },
            {
                "label": "भवानीपुर 0106",
                "village_id": 36,
                "village_name": "भवानीपुर"
            },
            {
                "label": "भिट्टी सलेमपुर 75/2",
                "village_id": 37,
                "village_name": "भिट्टी सलेमपुर"
            },
            {
                "label": "भोरे 0122",
                "village_id": 38,
                "village_name": "भोरे"
            },
            {
                "label": "भोरे 122/",
                "village_id": 39,
                "village_name": "भोरे"
            },
            {
                "label": "भोरे 122/",
                "village_id": 40,
                "village_name": "भोरे"
            },
            {
                "label": "मेधौल 0114",
                "village_id": 41,
                "village_name": "मेधौल"
            },
            {
                "label": "माधोपुर 0150",
                "village_id": 42,
                "village_name": "माधोपुर"
            },
            {
                "label": "माधोपुर नरोत्तम 0138",
                "village_id": 43,
                "village_name": "माधोपुर नरोत्तम"
            },
            {
                "label": "मोकरमपुर 0098",
                "village_id": 44,
                "village_name": "मोकरमपुर"
            },
            {
                "label": "मोहन 0061",
                "village_id": 45,
                "village_name": "मोहन"
            },
            {
                "label": "मोहन बढ़ियाम 0101",
                "village_id": 46,
                "village_name": "मोहन बढ़ियाम"
            },
            {
                "label": "राम खेतारी 0131",
                "village_id": 47,
                "village_name": "राम खेतारी"
            },
            {
                "label": "रामपुर 0148",
                "village_id": 48,
                "village_name": "रामपुर"
            },
            {
                "label": "लक्ष्मीपुर 0149",
                "village_id": 49,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लक्ष्मीपुर 0389",
                "village_id": 50,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "वटुरी 108/",
                "village_id": 51,
                "village_name": "वटुरी"
            },
            {
                "label": "वथने 0387",
                "village_id": 52,
                "village_name": "वथने"
            },
            {
                "label": "वलुआहा 0142",
                "village_id": 53,
                "village_name": "वलुआहा"
            },
            {
                "label": "वेलाही 124/",
                "village_id": 54,
                "village_name": "वेलाही"
            },
            {
                "label": "वेलाही 0124",
                "village_id": 55,
                "village_name": "वेलाही"
            },
            {
                "label": "वागवान 0121",
                "village_id": 56,
                "village_name": "वागवान"
            },
            {
                "label": "विक्रमपुर बलिया 0103",
                "village_id": 57,
                "village_name": "विक्रमपुर बलिया"
            },
            {
                "label": "विजय 0179",
                "village_id": 58,
                "village_name": "विजय"
            },
            {
                "label": "विनगर 0143",
                "village_id": 59,
                "village_name": "विनगर"
            },
            {
                "label": "विशनपुर मुदलियाही 126/",
                "village_id": 60,
                "village_name": "विशनपुर मुदलियाही"
            },
            {
                "label": "शंकर सराय 0146",
                "village_id": 61,
                "village_name": "शंकर सराय"
            },
            {
                "label": "शंकरपुर 0111",
                "village_id": 62,
                "village_name": "शंकरपुर"
            },
            {
                "label": "शंकरपुर डीह 111/",
                "village_id": 63,
                "village_name": "शंकरपुर डीह"
            },
            {
                "label": "शेख जोड़ 0116",
                "village_id": 64,
                "village_name": "शेख जोड़"
            },
            {
                "label": "शेखपुर जोड़ 0391",
                "village_id": 65,
                "village_name": "शेखपुर जोड़"
            },
            {
                "label": "शभंकरपुर 0110",
                "village_id": 66,
                "village_name": "शभंकरपुर"
            },
            {
                "label": "श्यामपुर 0120",
                "village_id": 67,
                "village_name": "श्यामपुर"
            },
            {
                "label": "श्रीपुर हाटी 0132",
                "village_id": 68,
                "village_name": "श्रीपुर हाटी"
            },
            {
                "label": "सकड़ी 0102",
                "village_id": 69,
                "village_name": "सकड़ी"
            },
            {
                "label": "संकोर्थ 0147",
                "village_id": 70,
                "village_name": "संकोर्थ"
            },
            {
                "label": "सरिसव 0154",
                "village_id": 71,
                "village_name": "सरिसव"
            },
            {
                "label": "सलेमपुर 0173",
                "village_id": 72,
                "village_name": "सलेमपुर"
            },
            {
                "label": "सागरपुर 0096",
                "village_id": 73,
                "village_name": "सागरपुर"
            },
            {
                "label": "हैदरपुर 0178",
                "village_id": 74,
                "village_name": "हैदरपुर"
            },
            {
                "label": "हरिपुर 0112",
                "village_id": 75,
                "village_name": "हरिपुर"
            },
            {
                "label": "हरिपुर 112/",
                "village_id": 76,
                "village_name": "हरिपुर"
            }
        ],
        "block_id": "2",
        "block_no": 5,
        "block_name": "पंडौल [002]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अजरकवे गंगरौनी 0057",
                "village_id": 2,
                "village_name": "अजरकवे गंगरौनी"
            },
            {
                "label": "अजरकवे सिमरी 0196",
                "village_id": 3,
                "village_name": "अजरकवे सिमरी"
            },
            {
                "label": "अण्डीपट्टी 0047",
                "village_id": 4,
                "village_name": "अण्डीपट्टी"
            },
            {
                "label": "एकम्मा 0223",
                "village_id": 5,
                "village_name": "एकम्मा"
            },
            {
                "label": "कैथाही 0187",
                "village_id": 6,
                "village_name": "कैथाही"
            },
            {
                "label": "कुनवार 0202",
                "village_id": 7,
                "village_name": "कुनवार"
            },
            {
                "label": "करहीया 0053",
                "village_id": 8,
                "village_name": "करहीया"
            },
            {
                "label": "केवान 0221",
                "village_id": 9,
                "village_name": "केवान"
            },
            {
                "label": "केहुनियां 0055",
                "village_id": 10,
                "village_name": "केहुनियां"
            },
            {
                "label": "कोईलख 0182",
                "village_id": 11,
                "village_name": "कोईलख"
            },
            {
                "label": "कोलपार 0219",
                "village_id": 12,
                "village_name": "कोलपार"
            },
            {
                "label": "खातो खरिका 0207",
                "village_id": 13,
                "village_name": "खातो खरिका"
            },
            {
                "label": "गंगरपट्टी 0058",
                "village_id": 14,
                "village_name": "गंगरपट्टी"
            },
            {
                "label": "गझोरा 0215",
                "village_id": 15,
                "village_name": "गझोरा"
            },
            {
                "label": "गरिया विशनपुर 0191",
                "village_id": 16,
                "village_name": "गरिया विशनपुर"
            },
            {
                "label": "गौड़ी भेरन 0050",
                "village_id": 17,
                "village_name": "गौड़ी भेरन"
            },
            {
                "label": "घिवाही 0199",
                "village_id": 18,
                "village_name": "घिवाही"
            },
            {
                "label": "चिठ कानुनगी 0220",
                "village_id": 19,
                "village_name": "चिठ कानुनगी"
            },
            {
                "label": "चिठ बुजुर्ग 0198",
                "village_id": 20,
                "village_name": "चिठ बुजुर्ग"
            },
            {
                "label": "चौधरामा 0184",
                "village_id": 21,
                "village_name": "चौधरामा"
            },
            {
                "label": "ठिका क्योटा 0045",
                "village_id": 22,
                "village_name": "ठिका क्योटा"
            },
            {
                "label": "ठीकहा 0218",
                "village_id": 23,
                "village_name": "ठीकहा"
            },
            {
                "label": "नकटी 0204",
                "village_id": 24,
                "village_name": "नकटी"
            },
            {
                "label": "नवटोल 0192",
                "village_id": 25,
                "village_name": "नवटोल"
            },
            {
                "label": "निजामत 0230",
                "village_id": 26,
                "village_name": "निजामत"
            },
            {
                "label": "पचदही 0203",
                "village_id": 27,
                "village_name": "पचदही"
            },
            {
                "label": "परसा 0185",
                "village_id": 28,
                "village_name": "परसा"
            },
            {
                "label": "परिहारपुर 0054",
                "village_id": 29,
                "village_name": "परिहारपुर"
            },
            {
                "label": "पलवारा 0222",
                "village_id": 30,
                "village_name": "पलवारा"
            },
            {
                "label": "पाली खुर्द 0052",
                "village_id": 31,
                "village_name": "पाली खुर्द"
            },
            {
                "label": "पिलखवार 0059",
                "village_id": 32,
                "village_name": "पिलखवार"
            },
            {
                "label": "बड़हारा गौड़ 0214",
                "village_id": 33,
                "village_name": "बड़हारा गौड़"
            },
            {
                "label": "बड़हारा जब्दी 0213",
                "village_id": 34,
                "village_name": "बड़हारा जब्दी"
            },
            {
                "label": "बड़हारा दाखिला 0212",
                "village_id": 35,
                "village_name": "बड़हारा दाखिला"
            },
            {
                "label": "ब्रह्मोत्तरा 0194",
                "village_id": 36,
                "village_name": "ब्रह्मोत्तरा"
            },
            {
                "label": "ब्रह्मोत्तरा 0211",
                "village_id": 37,
                "village_name": "ब्रह्मोत्तरा"
            },
            {
                "label": "भटसिगर 0209",
                "village_id": 38,
                "village_name": "भटसिगर"
            },
            {
                "label": "भेरन 0049",
                "village_id": 39,
                "village_name": "भेरन"
            },
            {
                "label": "भौआही 0206",
                "village_id": 40,
                "village_name": "भौआही"
            },
            {
                "label": "मंगरौनी 0035",
                "village_id": 41,
                "village_name": "मंगरौनी"
            },
            {
                "label": "मधुवनी टोल 0048",
                "village_id": 42,
                "village_name": "मधुवनी टोल"
            },
            {
                "label": "महिनाथपुर 0189",
                "village_id": 43,
                "village_name": "महिनाथपुर"
            },
            {
                "label": "मिर्जापुर 0198",
                "village_id": 44,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "रघुनी देहट 0056",
                "village_id": 45,
                "village_name": "रघुनी देहट"
            },
            {
                "label": "रघुवीरचक 0188",
                "village_id": 46,
                "village_name": "रघुवीरचक"
            },
            {
                "label": "रतिकर 0205",
                "village_id": 47,
                "village_name": "रतिकर"
            },
            {
                "label": "रमपुरा 0183",
                "village_id": 48,
                "village_name": "रमपुरा"
            },
            {
                "label": "राघोपुर व्लाठ 0190",
                "village_id": 49,
                "village_name": "राघोपुर व्लाठ"
            },
            {
                "label": "रामपट्टी 0186",
                "village_id": 50,
                "village_name": "रामपट्टी"
            },
            {
                "label": "रौटी 0060",
                "village_id": 51,
                "village_name": "रौटी"
            },
            {
                "label": "लग्गाडीह 0216",
                "village_id": 52,
                "village_name": "लग्गाडीह"
            },
            {
                "label": "लडुगाँव 0210",
                "village_id": 53,
                "village_name": "लडुगाँव"
            },
            {
                "label": "लालापुर 0051",
                "village_id": 54,
                "village_name": "लालापुर"
            },
            {
                "label": "वेराघाट 0224",
                "village_id": 55,
                "village_name": "वेराघाट"
            },
            {
                "label": "ववुआर 0217",
                "village_id": 56,
                "village_name": "ववुआर"
            },
            {
                "label": "विद्यानगर 0225",
                "village_id": 57,
                "village_name": "विद्यानगर"
            },
            {
                "label": "विद्यानगर 0208",
                "village_id": 58,
                "village_name": "विद्यानगर"
            },
            {
                "label": "वोहकर 0044",
                "village_id": 59,
                "village_name": "वोहकर"
            },
            {
                "label": "सतधारा 0197",
                "village_id": 60,
                "village_name": "सतधारा"
            },
            {
                "label": "सुर्गोना 0226",
                "village_id": 61,
                "village_name": "सुर्गोना"
            },
            {
                "label": "सिगगौन 0201",
                "village_id": 62,
                "village_name": "सिगगौन"
            },
            {
                "label": "सिमरी 0200",
                "village_id": 63,
                "village_name": "सिमरी"
            },
            {
                "label": "सिवीपट्टी 0046",
                "village_id": 64,
                "village_name": "सिवीपट्टी"
            },
            {
                "label": "सोनवारी 0193",
                "village_id": 65,
                "village_name": "सोनवारी"
            }
        ],
        "block_id": "3",
        "block_no": 6,
        "block_name": "राजनगर [003]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अकसपुरा 0095",
                "village_id": 2,
                "village_name": "अकसपुरा"
            },
            {
                "label": "इनरवा 0091",
                "village_id": 3,
                "village_name": "इनरवा"
            },
            {
                "label": "कजियाना 0049",
                "village_id": 4,
                "village_name": "कजियाना"
            },
            {
                "label": "कन्हौली 0097",
                "village_id": 5,
                "village_name": "कन्हौली"
            },
            {
                "label": "कपरिया 0067",
                "village_id": 6,
                "village_name": "कपरिया"
            },
            {
                "label": "कुमरखत 0057",
                "village_id": 7,
                "village_name": "कुमरखत"
            },
            {
                "label": "करपुर 0066",
                "village_id": 8,
                "village_name": "करपुर"
            },
            {
                "label": "करमौली 0060",
                "village_id": 9,
                "village_name": "करमौली"
            },
            {
                "label": "कलुआही 0051",
                "village_id": 10,
                "village_name": "कलुआही"
            },
            {
                "label": "केवटा 0053",
                "village_id": 11,
                "village_name": "केवटा"
            },
            {
                "label": "कालिकापुर 0076",
                "village_id": 12,
                "village_name": "कालिकापुर"
            },
            {
                "label": "कोठिया 0065",
                "village_id": 13,
                "village_name": "कोठिया"
            },
            {
                "label": "खजौली 0092",
                "village_id": 14,
                "village_name": "खजौली"
            },
            {
                "label": "चतरा गोवरौरा 0115",
                "village_id": 15,
                "village_name": "चतरा गोवरौरा"
            },
            {
                "label": "चन्द्रडीह 0110",
                "village_id": 16,
                "village_name": "चन्द्रडीह"
            },
            {
                "label": "ठाहर 0072",
                "village_id": 17,
                "village_name": "ठाहर"
            },
            {
                "label": "डाढ़ा 0070",
                "village_id": 18,
                "village_name": "डाढ़ा"
            },
            {
                "label": "डोकहर 0058",
                "village_id": 19,
                "village_name": "डोकहर"
            },
            {
                "label": "ढ़ेंगहा 0071",
                "village_id": 20,
                "village_name": "ढ़ेंगहा"
            },
            {
                "label": "दतुआर 0086",
                "village_id": 21,
                "village_name": "दतुआर"
            },
            {
                "label": "दयालपाली 0068",
                "village_id": 22,
                "village_name": "दयालपाली"
            },
            {
                "label": "धोरम 0062",
                "village_id": 23,
                "village_name": "धोरम"
            },
            {
                "label": "नरार 0077",
                "village_id": 24,
                "village_name": "नरार"
            },
            {
                "label": "पुरसौलिया 0032",
                "village_id": 25,
                "village_name": "पुरसौलिया"
            },
            {
                "label": "पाली मोहन 0069",
                "village_id": 26,
                "village_name": "पाली मोहन"
            },
            {
                "label": "ब्रह्मोत्तर 0031",
                "village_id": 27,
                "village_name": "ब्रह्मोत्तर"
            },
            {
                "label": "ब्रह्मोत्तर 0063",
                "village_id": 28,
                "village_name": "ब्रह्मोत्तर"
            },
            {
                "label": "बेलाही 0056",
                "village_id": 29,
                "village_name": "बेलाही"
            },
            {
                "label": "बेहथ 0087",
                "village_id": 30,
                "village_name": "बेहथ"
            },
            {
                "label": "बिरौल 0084",
                "village_id": 31,
                "village_name": "बिरौल"
            },
            {
                "label": "भकुआ 0116",
                "village_id": 32,
                "village_name": "भकुआ"
            },
            {
                "label": "मदना 0093",
                "village_id": 33,
                "village_name": "मदना"
            },
            {
                "label": "मधेपुर 0059",
                "village_id": 34,
                "village_name": "मधेपुर"
            },
            {
                "label": "मैना 0089",
                "village_id": 35,
                "village_name": "मैना"
            },
            {
                "label": "मनियरवा 0090",
                "village_id": 36,
                "village_name": "मनियरवा"
            },
            {
                "label": "मलमल 0050",
                "village_id": 37,
                "village_name": "मलमल"
            },
            {
                "label": "महुआ एकडारा 0078",
                "village_id": 38,
                "village_name": "महुआ एकडारा"
            },
            {
                "label": "महराजपुर 0099",
                "village_id": 39,
                "village_name": "महराजपुर"
            },
            {
                "label": "रसीदपुर 0061",
                "village_id": 40,
                "village_name": "रसीदपुर"
            },
            {
                "label": "लक्ष्मीपुर 0075",
                "village_id": 41,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लक्ष्मीपुर 0094",
                "village_id": 42,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लोहा 0055",
                "village_id": 43,
                "village_name": "लोहा"
            },
            {
                "label": "वेता ककरघट्टी 0064",
                "village_id": 44,
                "village_name": "वेता ककरघट्टी"
            },
            {
                "label": "शुभंकरपुर 0054",
                "village_id": 45,
                "village_name": "शुभंकरपुर"
            },
            {
                "label": "सुक्की 0088",
                "village_id": 46,
                "village_name": "सुक्की"
            },
            {
                "label": "सतेर 0074",
                "village_id": 47,
                "village_name": "सतेर"
            },
            {
                "label": "सरावे 0073",
                "village_id": 48,
                "village_name": "सरावे"
            },
            {
                "label": "हथियाही 0085",
                "village_id": 49,
                "village_name": "हथियाही"
            },
            {
                "label": "हरिपुर 0052",
                "village_id": 50,
                "village_name": "हरिपुर"
            },
            {
                "label": "हरिशवाड़ा 0096",
                "village_id": 51,
                "village_name": "हरिशवाड़ा"
            }
        ],
        "block_id": "4",
        "block_no": 7,
        "block_name": "खजौली [004]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अगारी 0162",
                "village_id": 2,
                "village_name": "अगारी"
            },
            {
                "label": "अगारी 0163",
                "village_id": 3,
                "village_name": "अगारी"
            },
            {
                "label": "एराजी बरहारा 0187",
                "village_id": 4,
                "village_name": "एराजी बरहारा"
            },
            {
                "label": "कुलहरिया 0188",
                "village_id": 5,
                "village_name": "कुलहरिया"
            },
            {
                "label": "केला 0150",
                "village_id": 6,
                "village_name": "केला"
            },
            {
                "label": "खरक वोनी 0170",
                "village_id": 7,
                "village_name": "खरक वोनी"
            },
            {
                "label": "खोजपुर 0164",
                "village_id": 8,
                "village_name": "खोजपुर"
            },
            {
                "label": "गोसाईचोड़ी 0161",
                "village_id": 9,
                "village_name": "गोसाईचोड़ी"
            },
            {
                "label": "घंघौर 0101",
                "village_id": 10,
                "village_name": "घंघौर"
            },
            {
                "label": "चनौरा 0178",
                "village_id": 11,
                "village_name": "चनौरा"
            },
            {
                "label": "जगरनाथपुर 0193",
                "village_id": 12,
                "village_name": "जगरनाथपुर"
            },
            {
                "label": "जरही 0159",
                "village_id": 13,
                "village_name": "जरही"
            },
            {
                "label": "जवदी 0179",
                "village_id": 14,
                "village_name": "जवदी"
            },
            {
                "label": "तेघरा 0156",
                "village_id": 15,
                "village_name": "तेघरा"
            },
            {
                "label": "तिरहुता 0111",
                "village_id": 16,
                "village_name": "तिरहुता"
            },
            {
                "label": "दुबाही 0174",
                "village_id": 17,
                "village_name": "दुबाही"
            },
            {
                "label": "देवरा 0149",
                "village_id": 18,
                "village_name": "देवरा"
            },
            {
                "label": "धनरही 0114",
                "village_id": 19,
                "village_name": "धनरही"
            },
            {
                "label": "नवनगर 0166",
                "village_id": 20,
                "village_name": "नवनगर"
            },
            {
                "label": "पचरूखी 0184",
                "village_id": 21,
                "village_name": "पचरूखी"
            },
            {
                "label": "पनाही 0176",
                "village_id": 22,
                "village_name": "पनाही"
            },
            {
                "label": "परसा 0189",
                "village_id": 23,
                "village_name": "परसा"
            },
            {
                "label": "पिरदी 0171",
                "village_id": 24,
                "village_name": "पिरदी"
            },
            {
                "label": "बगौल 0100",
                "village_id": 25,
                "village_name": "बगौल"
            },
            {
                "label": "बरूआर 0125",
                "village_id": 26,
                "village_name": "बरूआर"
            },
            {
                "label": "बरकी मआही 0175",
                "village_id": 27,
                "village_name": "बरकी मआही"
            },
            {
                "label": "बरदाही 0191",
                "village_id": 28,
                "village_name": "बरदाही"
            },
            {
                "label": "बरदाही 0190",
                "village_id": 29,
                "village_name": "बरदाही"
            },
            {
                "label": "बरैल 0157",
                "village_id": 30,
                "village_name": "बरैल"
            },
            {
                "label": "ब्रह्मोत्तर 0127",
                "village_id": 31,
                "village_name": "ब्रह्मोत्तर"
            },
            {
                "label": "बरहारा 0186",
                "village_id": 32,
                "village_name": "बरहारा"
            },
            {
                "label": "बरही 0173",
                "village_id": 33,
                "village_name": "बरही"
            },
            {
                "label": "बलहा चोड़ी 0158",
                "village_id": 34,
                "village_name": "बलहा चोड़ी"
            },
            {
                "label": "बेला 0109",
                "village_id": 35,
                "village_name": "बेला"
            },
            {
                "label": "बलिराज गढ़ 0168",
                "village_id": 36,
                "village_name": "बलिराज गढ़"
            },
            {
                "label": "बलिराजपुर 0167",
                "village_id": 37,
                "village_name": "बलिराजपुर"
            },
            {
                "label": "बांसा 0192",
                "village_id": 38,
                "village_name": "बांसा"
            },
            {
                "label": "भरगामा 0103",
                "village_id": 39,
                "village_name": "भरगामा"
            },
            {
                "label": "भरचौरा 0124",
                "village_id": 40,
                "village_name": "भरचौरा"
            },
            {
                "label": "भरहा 0185",
                "village_id": 41,
                "village_name": "भरहा"
            },
            {
                "label": "भाठ़खुदर खला 0107",
                "village_id": 42,
                "village_name": "भाठ़खुदर खला"
            },
            {
                "label": "भोजपट्टी 0165",
                "village_id": 43,
                "village_name": "भोजपट्टी"
            },
            {
                "label": "मदन धोवा 0160",
                "village_id": 44,
                "village_name": "मदन धोवा"
            },
            {
                "label": "मधवापुर 0104",
                "village_id": 45,
                "village_name": "मधवापुर"
            },
            {
                "label": "मुरहदी 0108",
                "village_id": 46,
                "village_name": "मुरहदी"
            },
            {
                "label": "महेशवाड़ा 0098",
                "village_id": 47,
                "village_name": "महेशवाड़ा"
            },
            {
                "label": "मिश्ररौलिया 0181",
                "village_id": 48,
                "village_name": "मिश्ररौलिया"
            },
            {
                "label": "मौआही 0176",
                "village_id": 49,
                "village_name": "मौआही"
            },
            {
                "label": "यसराजपुर 0182",
                "village_id": 50,
                "village_name": "यसराजपुर"
            },
            {
                "label": "रहिकपुर 0183",
                "village_id": 51,
                "village_name": "रहिकपुर"
            },
            {
                "label": "रामनीपट्टी 0179",
                "village_id": 52,
                "village_name": "रामनीपट्टी"
            },
            {
                "label": "लोहा पीपल 0180",
                "village_id": 53,
                "village_name": "लोहा पीपल"
            },
            {
                "label": "वेरिया 0105",
                "village_id": 54,
                "village_name": "वेरिया"
            },
            {
                "label": "विक्रमशेर 0177",
                "village_id": 55,
                "village_name": "विक्रमशेर"
            },
            {
                "label": "विशनपुर 0126",
                "village_id": 56,
                "village_name": "विशनपुर"
            },
            {
                "label": "वौली 0112",
                "village_id": 57,
                "village_name": "वौली"
            },
            {
                "label": "श्याम सिंह 0102",
                "village_id": 58,
                "village_name": "श्याम सिंह"
            },
            {
                "label": "सतधारा 0106",
                "village_id": 59,
                "village_name": "सतधारा"
            },
            {
                "label": "सोनपट्टी 0172",
                "village_id": 60,
                "village_name": "सोनपट्टी"
            },
            {
                "label": "सोनपतही 0194",
                "village_id": 61,
                "village_name": "सोनपतही"
            },
            {
                "label": "सोनमती 0151",
                "village_id": 62,
                "village_name": "सोनमती"
            },
            {
                "label": "सोनरा 0195",
                "village_id": 63,
                "village_name": "सोनरा"
            },
            {
                "label": "हनुमान नगर 0128",
                "village_id": 64,
                "village_name": "हनुमान नगर"
            }
        ],
        "block_id": "5",
        "block_no": 8,
        "block_name": "बाबूबरही [005]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अजरकवे चौपला 0256",
                "village_id": 2,
                "village_name": "अजरकवे चौपला"
            },
            {
                "label": "अजरकवे सिरखटिया 0154",
                "village_id": 3,
                "village_name": "अजरकवे सिरखटिया"
            },
            {
                "label": "अमराहा रैयाम 0160",
                "village_id": 4,
                "village_name": "अमराहा रैयाम"
            },
            {
                "label": "अररिया 0145",
                "village_id": 5,
                "village_name": "अररिया"
            },
            {
                "label": "इटवापट्टी 0281",
                "village_id": 6,
                "village_name": "इटवापट्टी"
            },
            {
                "label": "इमादपट्टी 0264",
                "village_id": 7,
                "village_name": "इमादपट्टी"
            },
            {
                "label": "एराजी भवानीपुर 0163",
                "village_id": 8,
                "village_name": "एराजी भवानीपुर"
            },
            {
                "label": "ओझौल 0171",
                "village_id": 9,
                "village_name": "ओझौल"
            },
            {
                "label": "ओलीपुर 0263",
                "village_id": 10,
                "village_name": "ओलीपुर"
            },
            {
                "label": "कनकपुरा 0302",
                "village_id": 11,
                "village_name": "कनकपुरा"
            },
            {
                "label": "कन्हौली 0274",
                "village_id": 12,
                "village_name": "कन्हौली"
            },
            {
                "label": "कांको 0257",
                "village_id": 13,
                "village_name": "कांको"
            },
            {
                "label": "किशनपुर खरसाम 0164",
                "village_id": 14,
                "village_name": "किशनपुर खरसाम"
            },
            {
                "label": "किशनपुर छीट 0166",
                "village_id": 15,
                "village_name": "किशनपुर छीट"
            },
            {
                "label": "कोठिया 0170",
                "village_id": 16,
                "village_name": "कोठिया"
            },
            {
                "label": "खड़रख 0155",
                "village_id": 17,
                "village_name": "खड़रख"
            },
            {
                "label": "खैरा 0261",
                "village_id": 18,
                "village_name": "खैरा"
            },
            {
                "label": "खैरा शिवोत्तर 0262",
                "village_id": 19,
                "village_name": "खैरा शिवोत्तर"
            },
            {
                "label": "खरौआ 0150",
                "village_id": 20,
                "village_name": "खरौआ"
            },
            {
                "label": "गोवर्धनपुर 0310",
                "village_id": 21,
                "village_name": "गोवर्धनपुर"
            },
            {
                "label": "चनौरागंज 0304",
                "village_id": 22,
                "village_name": "चनौरागंज"
            },
            {
                "label": "चोरा महरौल 0299",
                "village_id": 23,
                "village_name": "चोरा महरौल"
            },
            {
                "label": "चौपला 0255",
                "village_id": 24,
                "village_name": "चौपला"
            },
            {
                "label": "जमघट 0165",
                "village_id": 25,
                "village_name": "जमघट"
            },
            {
                "label": "झांझारपुर 0309",
                "village_id": 26,
                "village_name": "झांझारपुर"
            },
            {
                "label": "झौआ 0181",
                "village_id": 27,
                "village_name": "झौआ"
            },
            {
                "label": "ढ़ोलहा 0274",
                "village_id": 28,
                "village_name": "ढ़ोलहा"
            },
            {
                "label": "ताजपुर 0298",
                "village_id": 29,
                "village_name": "ताजपुर"
            },
            {
                "label": "दुबौली खौरा 0158",
                "village_id": 30,
                "village_name": "दुबौली खौरा"
            },
            {
                "label": "देवानवानी 0223",
                "village_id": 31,
                "village_name": "देवानवानी"
            },
            {
                "label": "धेपुरा 0147",
                "village_id": 32,
                "village_name": "धेपुरा"
            },
            {
                "label": "नरूआर 0159",
                "village_id": 33,
                "village_name": "नरूआर"
            },
            {
                "label": "नवानी 0225",
                "village_id": 34,
                "village_name": "नवानी"
            },
            {
                "label": "नारायणपुर 0172",
                "village_id": 35,
                "village_name": "नारायणपुर"
            },
            {
                "label": "निर्भापुर 0180",
                "village_id": 36,
                "village_name": "निर्भापुर"
            },
            {
                "label": "परतापुर 0273",
                "village_id": 37,
                "village_name": "परतापुर"
            },
            {
                "label": "परमानन्दपुर 0224",
                "village_id": 38,
                "village_name": "परमानन्दपुर"
            },
            {
                "label": "परसा 0153",
                "village_id": 39,
                "village_name": "परसा"
            },
            {
                "label": "पिपरौलिया 0146",
                "village_id": 40,
                "village_name": "पिपरौलिया"
            },
            {
                "label": "पौराम 0303",
                "village_id": 41,
                "village_name": "पौराम"
            },
            {
                "label": "बनौर 0265",
                "village_id": 42,
                "village_name": "बनौर"
            },
            {
                "label": "बलनी मेहथ 0271",
                "village_id": 43,
                "village_name": "बलनी मेहथ"
            },
            {
                "label": "बाघ सनगही 0270",
                "village_id": 44,
                "village_name": "बाघ सनगही"
            },
            {
                "label": "मछघी 0307",
                "village_id": 45,
                "village_name": "मछघी"
            },
            {
                "label": "मझौरा 0305",
                "village_id": 46,
                "village_name": "मझौरा"
            },
            {
                "label": "मलिछाम 0280",
                "village_id": 47,
                "village_name": "मलिछाम"
            },
            {
                "label": "महेशपुरा 0276",
                "village_id": 48,
                "village_name": "महेशपुरा"
            },
            {
                "label": "महिनाथपुर 0161",
                "village_id": 49,
                "village_name": "महिनाथपुर"
            },
            {
                "label": "मोतीपुर 0266",
                "village_id": 50,
                "village_name": "मोतीपुर"
            },
            {
                "label": "मोहनपुर 0168",
                "village_id": 51,
                "village_name": "मोहनपुर"
            },
            {
                "label": "मोहना 0275",
                "village_id": 52,
                "village_name": "मोहना"
            },
            {
                "label": "रघुनन्दनपुर 0149",
                "village_id": 53,
                "village_name": "रघुनन्दनपुर"
            },
            {
                "label": "रूपौली 0167",
                "village_id": 54,
                "village_name": "रूपौली"
            },
            {
                "label": "रैयाम 0169",
                "village_id": 55,
                "village_name": "रैयाम"
            },
            {
                "label": "रेवाड़ी 0300",
                "village_id": 56,
                "village_name": "रेवाड़ी"
            },
            {
                "label": "रामखेतारी 0260",
                "village_id": 57,
                "village_name": "रामखेतारी"
            },
            {
                "label": "लोहना 0156",
                "village_id": 58,
                "village_name": "लोहना"
            },
            {
                "label": "विश्रौल 0148",
                "village_id": 59,
                "village_name": "विश्रौल"
            },
            {
                "label": "शंकरपुर 0258",
                "village_id": 60,
                "village_name": "शंकरपुर"
            },
            {
                "label": "सुखेत 0308",
                "village_id": 61,
                "village_name": "सुखेत"
            },
            {
                "label": "संतनगर 0259",
                "village_id": 62,
                "village_name": "संतनगर"
            },
            {
                "label": "समियां 0272",
                "village_id": 63,
                "village_name": "समियां"
            },
            {
                "label": "सर्वशिमा 0157",
                "village_id": 64,
                "village_name": "सर्वशिमा"
            },
            {
                "label": "सिंगदाह 0151",
                "village_id": 65,
                "village_name": "सिंगदाह"
            },
            {
                "label": "सिन्दुरपुरा 0301",
                "village_id": 66,
                "village_name": "सिन्दुरपुरा"
            },
            {
                "label": "सिमरा 0306",
                "village_id": 67,
                "village_name": "सिमरा"
            },
            {
                "label": "सिरखटिया 0155",
                "village_id": 68,
                "village_name": "सिरखटिया"
            },
            {
                "label": "सोहपुर 0152",
                "village_id": 69,
                "village_name": "सोहपुर"
            },
            {
                "label": "हैठीवाली 0162",
                "village_id": 70,
                "village_name": "हैठीवाली"
            }
        ],
        "block_id": "1",
        "block_no": 9,
        "block_name": "झंझारपुर [001]",
        "sub_div_code": "3"
    },
    {
        "village": [
            {
                "label": "अ.देशाम 0204",
                "village_id": 2,
                "village_name": "अ.देशाम"
            },
            {
                "label": "अ.बहेरा वीरपुर 0287",
                "village_id": 3,
                "village_name": "अ.बहेरा वीरपुर"
            },
            {
                "label": "अ.सीद्वीताजपुर 0276",
                "village_id": 4,
                "village_name": "अ.सीद्वीताजपुर"
            },
            {
                "label": "अजरकवे पौनी 0200",
                "village_id": 5,
                "village_name": "अजरकवे पौनी"
            },
            {
                "label": "अजरकवे भीठ भगवानपुर 0281",
                "village_id": 6,
                "village_name": "अजरकवे भीठ भगवानपुर"
            },
            {
                "label": "आदित्यडीह 0256",
                "village_id": 7,
                "village_name": "आदित्यडीह"
            },
            {
                "label": "आसम 0261",
                "village_id": 8,
                "village_name": "आसम"
            },
            {
                "label": "इसौली कल्याणपुर 0280",
                "village_id": 9,
                "village_name": "इसौली कल्याणपुर"
            },
            {
                "label": "कपड़फोड़ा 0265",
                "village_id": 10,
                "village_name": "कपड़फोड़ा"
            },
            {
                "label": "कुर्सोथ 0268",
                "village_id": 11,
                "village_name": "कुर्सोथ"
            },
            {
                "label": "करहारा 0291",
                "village_id": 12,
                "village_name": "करहारा"
            },
            {
                "label": "कलिकापुर 0193",
                "village_id": 13,
                "village_name": "कलिकापुर"
            },
            {
                "label": "खजूरा 0274",
                "village_id": 14,
                "village_name": "खजूरा"
            },
            {
                "label": "खजूरा 0299",
                "village_id": 15,
                "village_name": "खजूरा"
            },
            {
                "label": "खरीक 0293",
                "village_id": 16,
                "village_name": "खरीक"
            },
            {
                "label": "गढ़वारी 0320",
                "village_id": 17,
                "village_name": "गढ़वारी"
            },
            {
                "label": "गैनीमठ प्रतिमा 0306",
                "village_id": 18,
                "village_name": "गैनीमठ प्रतिमा"
            },
            {
                "label": "गोपालपट्टी 0206",
                "village_id": 19,
                "village_name": "गोपालपट्टी"
            },
            {
                "label": "गोबरगढ़ा 0318",
                "village_id": 20,
                "village_name": "गोबरगढ़ा"
            },
            {
                "label": "चन्द्रदीप 0273",
                "village_id": 21,
                "village_name": "चन्द्रदीप"
            },
            {
                "label": "चुनी 0270",
                "village_id": 22,
                "village_name": "चुनी"
            },
            {
                "label": "चपराम 0201",
                "village_id": 23,
                "village_name": "चपराम"
            },
            {
                "label": "छतौनी 0310",
                "village_id": 24,
                "village_name": "छतौनी"
            },
            {
                "label": "जागीर संग्राम 0295",
                "village_id": 25,
                "village_name": "जागीर संग्राम"
            },
            {
                "label": "टेंगराहा 0305",
                "village_id": 26,
                "village_name": "टेंगराहा"
            },
            {
                "label": "टेंगरी 0266",
                "village_id": 27,
                "village_name": "टेंगरी"
            },
            {
                "label": "डारह विशनपुर 0324",
                "village_id": 28,
                "village_name": "डारह विशनपुर"
            },
            {
                "label": "तरडीहा 0205",
                "village_id": 29,
                "village_name": "तरडीहा"
            },
            {
                "label": "दलदल 0283",
                "village_id": 30,
                "village_name": "दलदल"
            },
            {
                "label": "द्वाल़ख 0292",
                "village_id": 31,
                "village_name": "द्वाल़ख"
            },
            {
                "label": "देशाम 0203",
                "village_id": 32,
                "village_name": "देशाम"
            },
            {
                "label": "नरही जगरनाथ 0290",
                "village_id": 33,
                "village_name": "नरही जगरनाथ"
            },
            {
                "label": "नवादा 0265",
                "village_id": 34,
                "village_name": "नवादा"
            },
            {
                "label": "पखौल 0269",
                "village_id": 35,
                "village_name": "पखौल"
            },
            {
                "label": "पचही 0257",
                "village_id": 36,
                "village_name": "पचही"
            },
            {
                "label": "पटियाही 0316",
                "village_id": 37,
                "village_name": "पटियाही"
            },
            {
                "label": "परवलपुर 0302",
                "village_id": 38,
                "village_name": "परवलपुर"
            },
            {
                "label": "प्रसाद 0275",
                "village_id": 39,
                "village_name": "प्रसाद"
            },
            {
                "label": "परसौनी 0300",
                "village_id": 40,
                "village_name": "परसौनी"
            },
            {
                "label": "परसौनी 0308",
                "village_id": 41,
                "village_name": "परसौनी"
            },
            {
                "label": "पौनी 0199",
                "village_id": 42,
                "village_name": "पौनी"
            },
            {
                "label": "बकूआ 0303",
                "village_id": 43,
                "village_name": "बकूआ"
            },
            {
                "label": "बेला 0325",
                "village_id": 44,
                "village_name": "बेला"
            },
            {
                "label": "बलीपद 0321",
                "village_id": 45,
                "village_name": "बलीपद"
            },
            {
                "label": "बहेरा 0286",
                "village_id": 46,
                "village_name": "बहेरा"
            },
            {
                "label": "भूईयाही 0322",
                "village_id": 47,
                "village_name": "भूईयाही"
            },
            {
                "label": "भखराईन 0271",
                "village_id": 48,
                "village_name": "भखराईन"
            },
            {
                "label": "भगता 0323",
                "village_id": 49,
                "village_name": "भगता"
            },
            {
                "label": "भरगामा 0304",
                "village_id": 50,
                "village_name": "भरगामा"
            },
            {
                "label": "भवानीपुर 0312",
                "village_id": 51,
                "village_name": "भवानीपुर"
            },
            {
                "label": "भीठ भगवानपुर 0279",
                "village_id": 52,
                "village_name": "भीठ भगवानपुर"
            },
            {
                "label": "भीमपुर 0264",
                "village_id": 53,
                "village_name": "भीमपुर"
            },
            {
                "label": "मेजा 0296",
                "village_id": 54,
                "village_name": "मेजा"
            },
            {
                "label": "मटरस 0194",
                "village_id": 55,
                "village_name": "मटरस"
            },
            {
                "label": "मदनपुर 0284",
                "village_id": 56,
                "village_name": "मदनपुर"
            },
            {
                "label": "मधेपुर 0259",
                "village_id": 57,
                "village_name": "मधेपुर"
            },
            {
                "label": "मेधुसंग्राम 0294",
                "village_id": 58,
                "village_name": "मेधुसंग्राम"
            },
            {
                "label": "मेनाही 0315",
                "village_id": 59,
                "village_name": "मेनाही"
            },
            {
                "label": "महपतिया 0309",
                "village_id": 60,
                "village_name": "महपतिया"
            },
            {
                "label": "मेहसा 0311",
                "village_id": 61,
                "village_name": "मेहसा"
            },
            {
                "label": "महासिंह हसौली 0282",
                "village_id": 62,
                "village_name": "महासिंह हसौली"
            },
            {
                "label": "महिखाम 0262",
                "village_id": 63,
                "village_name": "महिखाम"
            },
            {
                "label": "रतुआर 0289",
                "village_id": 64,
                "village_name": "रतुआर"
            },
            {
                "label": "रूपौली 0267",
                "village_id": 65,
                "village_name": "रूपौली"
            },
            {
                "label": "रवाड़ 0285",
                "village_id": 66,
                "village_name": "रवाड़"
            },
            {
                "label": "रहुआ 0288",
                "village_id": 67,
                "village_name": "रहुआ"
            },
            {
                "label": "रामपुर 0314",
                "village_id": 68,
                "village_name": "रामपुर"
            },
            {
                "label": "रामपुर गोचालपट्टी 0327",
                "village_id": 69,
                "village_name": "रामपुर गोचालपट्टी"
            },
            {
                "label": "लूचवनी 0317",
                "village_id": 70,
                "village_name": "लूचवनी"
            },
            {
                "label": "लीलमा 0307",
                "village_id": 71,
                "village_name": "लीलमा"
            },
            {
                "label": "वगेवा 0313",
                "village_id": 72,
                "village_name": "वगेवा"
            },
            {
                "label": "वेतोना 0272",
                "village_id": 73,
                "village_name": "वेतोना"
            },
            {
                "label": "वरसाम 0297",
                "village_id": 74,
                "village_name": "वरसाम"
            },
            {
                "label": "वल्थी 0301",
                "village_id": 75,
                "village_name": "वल्थी"
            },
            {
                "label": "वसूलगढ़ा 0319",
                "village_id": 76,
                "village_name": "वसूलगढ़ा"
            },
            {
                "label": "वाथ 0208",
                "village_id": 77,
                "village_name": "वाथ"
            },
            {
                "label": "विशनपुर 0198",
                "village_id": 78,
                "village_name": "विशनपुर"
            },
            {
                "label": "वीरपुर 0278",
                "village_id": 79,
                "village_name": "वीरपुर"
            },
            {
                "label": "शंकरपुर 0202",
                "village_id": 80,
                "village_name": "शंकरपुर"
            },
            {
                "label": "सुन्दर विराजित 0213",
                "village_id": 81,
                "village_name": "सुन्दर विराजित"
            },
            {
                "label": "सरौनी 0263",
                "village_id": 82,
                "village_name": "सरौनी"
            },
            {
                "label": "सिकरिया 0260",
                "village_id": 83,
                "village_name": "सिकरिया"
            },
            {
                "label": "सिझौल 0298",
                "village_id": 84,
                "village_name": "सिझौल"
            },
            {
                "label": "सीधीताजपुर 0277",
                "village_id": 85,
                "village_name": "सीधीताजपुर"
            },
            {
                "label": "सीमराहा 0207",
                "village_id": 86,
                "village_name": "सीमराहा"
            },
            {
                "label": "हरिशंकरी 0326",
                "village_id": 87,
                "village_name": "हरिशंकरी"
            }
        ],
        "block_id": "2",
        "block_no": 10,
        "block_name": "मधेपुर [002]",
        "sub_div_code": "3"
    },
    {
        "village": [
            {
                "label": "अंकुशी 0248",
                "village_id": 2,
                "village_name": "अंकुशी"
            },
            {
                "label": "अजरकवे दीप 0236",
                "village_id": 3,
                "village_name": "अजरकवे दीप"
            },
            {
                "label": "अजरकवे शिवैसीपुर 0234",
                "village_id": 4,
                "village_name": "अजरकवे शिवैसीपुर"
            },
            {
                "label": "अमारूपी 0212",
                "village_id": 5,
                "village_name": "अमारूपी"
            },
            {
                "label": "उमरी 0254",
                "village_id": 6,
                "village_name": "उमरी"
            },
            {
                "label": "कछुआ 0230",
                "village_id": 7,
                "village_name": "कछुआ"
            },
            {
                "label": "कछुवी 0226",
                "village_id": 8,
                "village_name": "कछुवी"
            },
            {
                "label": "कैथिनियां 0239",
                "village_id": 9,
                "village_name": "कैथिनियां"
            },
            {
                "label": "कसियाम 0251",
                "village_id": 10,
                "village_name": "कसियाम"
            },
            {
                "label": "कोलाही 0243",
                "village_id": 11,
                "village_name": "कोलाही"
            },
            {
                "label": "खोहराय 0242",
                "village_id": 12,
                "village_name": "खोहराय"
            },
            {
                "label": "गंगापुर 0245",
                "village_id": 13,
                "village_name": "गंगापुर"
            },
            {
                "label": "गढ़ा 0249",
                "village_id": 14,
                "village_name": "गढ़ा"
            },
            {
                "label": "गुणाकरपुर 0250",
                "village_id": 15,
                "village_name": "गुणाकरपुर"
            },
            {
                "label": "तमुरिया 0220",
                "village_id": 16,
                "village_name": "तमुरिया"
            },
            {
                "label": "दैया खरवार 0244",
                "village_id": 17,
                "village_name": "दैया खरवार"
            },
            {
                "label": "दीप 0235",
                "village_id": 18,
                "village_name": "दीप"
            },
            {
                "label": "दीप राघोनगर 0237",
                "village_id": 19,
                "village_name": "दीप राघोनगर"
            },
            {
                "label": "नेमुआ 0215",
                "village_id": 20,
                "village_name": "नेमुआ"
            },
            {
                "label": "पूरे 0258",
                "village_id": 21,
                "village_name": "पूरे"
            },
            {
                "label": "प्रयागपुर 0016",
                "village_id": 22,
                "village_name": "प्रयागपुर"
            },
            {
                "label": "बिजलीपुर 0229",
                "village_id": 23,
                "village_name": "बिजलीपुर"
            },
            {
                "label": "मखरौली 0228",
                "village_id": 24,
                "village_name": "मखरौली"
            },
            {
                "label": "मदनपुर 0241",
                "village_id": 25,
                "village_name": "मदनपुर"
            },
            {
                "label": "मनमोहन 0209",
                "village_id": 26,
                "village_name": "मनमोहन"
            },
            {
                "label": "मैवी 0231",
                "village_id": 27,
                "village_name": "मैवी"
            },
            {
                "label": "रतौल 0240",
                "village_id": 28,
                "village_name": "रतौल"
            },
            {
                "label": "रमौली 0219",
                "village_id": 29,
                "village_name": "रमौली"
            },
            {
                "label": "लखनौर 0247",
                "village_id": 30,
                "village_name": "लखनौर"
            },
            {
                "label": "लखनौर ब्रह्मोत्तर 0252",
                "village_id": 31,
                "village_name": "लखनौर ब्रह्मोत्तर"
            },
            {
                "label": "लौचा 0255",
                "village_id": 32,
                "village_name": "लौचा"
            },
            {
                "label": "वेरमा 0227",
                "village_id": 33,
                "village_name": "वेरमा"
            },
            {
                "label": "वलिया 0253",
                "village_id": 34,
                "village_name": "वलिया"
            },
            {
                "label": "वेलौचा 0232",
                "village_id": 35,
                "village_name": "वेलौचा"
            },
            {
                "label": "वसवन 0217",
                "village_id": 36,
                "village_name": "वसवन"
            },
            {
                "label": "वेहट 0238",
                "village_id": 37,
                "village_name": "वेहट"
            },
            {
                "label": "वेहरा 0211",
                "village_id": 38,
                "village_name": "वेहरा"
            },
            {
                "label": "विशनपट्टी 0222",
                "village_id": 39,
                "village_name": "विशनपट्टी"
            },
            {
                "label": "विहारपुर 0214",
                "village_id": 40,
                "village_name": "विहारपुर"
            },
            {
                "label": "वोढ़ई 0216",
                "village_id": 41,
                "village_name": "वोढ़ई"
            },
            {
                "label": "शिवैसीपुर 0233",
                "village_id": 42,
                "village_name": "शिवैसीपुर"
            },
            {
                "label": "सिरखरिया 0218",
                "village_id": 43,
                "village_name": "सिरखरिया"
            },
            {
                "label": "सोनरे 0221",
                "village_id": 44,
                "village_name": "सोनरे"
            },
            {
                "label": "सोनवर्षा 0210",
                "village_id": 45,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "सोनारपट्टी 0246",
                "village_id": 46,
                "village_name": "सोनारपट्टी"
            }
        ],
        "block_id": "3",
        "block_no": 11,
        "block_name": "लखनौर [003]",
        "sub_div_code": "3"
    },
    {
        "village": [
            {
                "label": "अ.मैनी 0292",
                "village_id": 2,
                "village_name": "अ.मैनी"
            },
            {
                "label": "अजरकवे चपाही 0228",
                "village_id": 3,
                "village_name": "अजरकवे चपाही"
            },
            {
                "label": "अंधराठाढ़ी 0289",
                "village_id": 4,
                "village_name": "अंधराठाढ़ी"
            },
            {
                "label": "अलपुरा 0297",
                "village_id": 5,
                "village_name": "अलपुरा"
            },
            {
                "label": "अवाम 0285",
                "village_id": 6,
                "village_name": "अवाम"
            },
            {
                "label": "एराजी डुमरा 0288",
                "village_id": 7,
                "village_name": "एराजी डुमरा"
            },
            {
                "label": "कर्णपुर 0279",
                "village_id": 8,
                "village_name": "कर्णपुर"
            },
            {
                "label": "कसमागौड़ 0236",
                "village_id": 9,
                "village_name": "कसमागौड़"
            },
            {
                "label": "गंगहार 0231",
                "village_id": 10,
                "village_name": "गंगहार"
            },
            {
                "label": "गंधराईन 0283",
                "village_id": 11,
                "village_name": "गंधराईन"
            },
            {
                "label": "गनौली 0235",
                "village_id": 12,
                "village_name": "गनौली"
            },
            {
                "label": "गौड़ अंधरा 0242",
                "village_id": 13,
                "village_name": "गौड़ अंधरा"
            },
            {
                "label": "घोघरिया 0250",
                "village_id": 14,
                "village_name": "घोघरिया"
            },
            {
                "label": "चपाही 0229",
                "village_id": 15,
                "village_name": "चपाही"
            },
            {
                "label": "जमैला 0291",
                "village_id": 16,
                "village_name": "जमैला"
            },
            {
                "label": "जलसैन 0294",
                "village_id": 17,
                "village_name": "जलसैन"
            },
            {
                "label": "डुमरा 0245",
                "village_id": 18,
                "village_name": "डुमरा"
            },
            {
                "label": "तिलई 0252",
                "village_id": 19,
                "village_name": "तिलई"
            },
            {
                "label": "देवहार 0234",
                "village_id": 20,
                "village_name": "देवहार"
            },
            {
                "label": "नन्दनधकजरी 0240",
                "village_id": 21,
                "village_name": "नन्दनधकजरी"
            },
            {
                "label": "ननौर 0296",
                "village_id": 22,
                "village_name": "ननौर"
            },
            {
                "label": "नवनगर 0287",
                "village_id": 23,
                "village_name": "नवनगर"
            },
            {
                "label": "निकठा 0237",
                "village_id": 24,
                "village_name": "निकठा"
            },
            {
                "label": "पस्टन 0241",
                "village_id": 25,
                "village_name": "पस्टन"
            },
            {
                "label": "फुलवरिया 0243",
                "village_id": 26,
                "village_name": "फुलवरिया"
            },
            {
                "label": "ब्र.गंधराईन 0284",
                "village_id": 27,
                "village_name": "ब्र.गंधराईन"
            },
            {
                "label": "बरसाम 0246",
                "village_id": 28,
                "village_name": "बरसाम"
            },
            {
                "label": "भदुआर 0267",
                "village_id": 29,
                "village_name": "भदुआर"
            },
            {
                "label": "मंगरौना 0248",
                "village_id": 30,
                "village_name": "मंगरौना"
            },
            {
                "label": "मदना 0295",
                "village_id": 31,
                "village_name": "मदना"
            },
            {
                "label": "मदना 0290",
                "village_id": 32,
                "village_name": "मदना"
            },
            {
                "label": "मैनी 0293",
                "village_id": 33,
                "village_name": "मैनी"
            },
            {
                "label": "मैलाम 0253",
                "village_id": 34,
                "village_name": "मैलाम"
            },
            {
                "label": "मसकिया 0239",
                "village_id": 35,
                "village_name": "मसकिया"
            },
            {
                "label": "महरैल 0269",
                "village_id": 36,
                "village_name": "महरैल"
            },
            {
                "label": "रखवारी 0247",
                "village_id": 37,
                "village_name": "रखवारी"
            },
            {
                "label": "रजनपुरा 0251",
                "village_id": 38,
                "village_name": "रजनपुरा"
            },
            {
                "label": "रूद्रपुर 0286",
                "village_id": 39,
                "village_name": "रूद्रपुर"
            },
            {
                "label": "वसवा 0244",
                "village_id": 40,
                "village_name": "वसवा"
            },
            {
                "label": "विठौनी 0227",
                "village_id": 41,
                "village_name": "विठौनी"
            },
            {
                "label": "विदुलिया 0233",
                "village_id": 42,
                "village_name": "विदुलिया"
            },
            {
                "label": "शिवा 0249",
                "village_id": 43,
                "village_name": "शिवा"
            },
            {
                "label": "सहुरिया 0232",
                "village_id": 44,
                "village_name": "सहुरिया"
            },
            {
                "label": "सिंगिचौन 0238",
                "village_id": 45,
                "village_name": "सिंगिचौन"
            },
            {
                "label": "सिजौल 0254",
                "village_id": 46,
                "village_name": "सिजौल"
            },
            {
                "label": "सिसौनी 0277",
                "village_id": 47,
                "village_name": "सिसौनी"
            },
            {
                "label": "हरड़ी 0282",
                "village_id": 48,
                "village_name": "हरड़ी"
            },
            {
                "label": "हरना 0268",
                "village_id": 49,
                "village_name": "हरना"
            }
        ],
        "block_id": "4",
        "block_no": 12,
        "block_name": "अंधराठाढ़ी [004]",
        "sub_div_code": "3"
    },
    {
        "village": [
            {
                "label": "इनरवा 0016",
                "village_id": 2,
                "village_name": "इनरवा"
            },
            {
                "label": "उसराही देवधा 0014",
                "village_id": 3,
                "village_name": "उसराही देवधा"
            },
            {
                "label": "कुआठ 0079",
                "village_id": 4,
                "village_name": "कुआठ"
            },
            {
                "label": "कोरहिमा 0083",
                "village_id": 5,
                "village_name": "कोरहिमा"
            },
            {
                "label": "गाढ़ा 0009",
                "village_id": 6,
                "village_name": "गाढ़ा"
            },
            {
                "label": "चातर 0027",
                "village_id": 7,
                "village_name": "चातर"
            },
            {
                "label": "जयनगर 0080",
                "village_id": 8,
                "village_name": "जयनगर"
            },
            {
                "label": "डोड़वार 0081",
                "village_id": 9,
                "village_name": "डोड़वार"
            },
            {
                "label": "धमियापट्टी 0012",
                "village_id": 10,
                "village_name": "धमियापट्टी"
            },
            {
                "label": "पचहर 0123",
                "village_id": 11,
                "village_name": "पचहर"
            },
            {
                "label": "परवा बेलही 0118",
                "village_id": 12,
                "village_name": "परवा बेलही"
            },
            {
                "label": "परसा 0013",
                "village_id": 13,
                "village_name": "परसा"
            },
            {
                "label": "ब्रह्मोत्तर 0082",
                "village_id": 14,
                "village_name": "ब्रह्मोत्तर"
            },
            {
                "label": "बरही 0026",
                "village_id": 15,
                "village_name": "बरही"
            },
            {
                "label": "बैरा 0017",
                "village_id": 16,
                "village_name": "बैरा"
            },
            {
                "label": "बेला बेलही 0119",
                "village_id": 17,
                "village_name": "बेला बेलही"
            },
            {
                "label": "भदौर 0015",
                "village_id": 18,
                "village_name": "भदौर"
            },
            {
                "label": "रजौली 0010",
                "village_id": 19,
                "village_name": "रजौली"
            },
            {
                "label": "शिलानाथ दुल्लीपट्टी 0028",
                "village_id": 20,
                "village_name": "शिलानाथ दुल्लीपट्टी"
            },
            {
                "label": "सुड़ीयाही 0018",
                "village_id": 21,
                "village_name": "सुड़ीयाही"
            },
            {
                "label": "सलेरा 0117",
                "village_id": 22,
                "village_name": "सलेरा"
            }
        ],
        "block_id": "1",
        "block_no": 13,
        "block_name": "जयनगर [001]",
        "sub_div_code": "4"
    },
    {
        "village": [
            {
                "label": "अरधावा 0033",
                "village_id": 2,
                "village_name": "अरधावा"
            },
            {
                "label": "कटैया 0020",
                "village_id": 3,
                "village_name": "कटैया"
            },
            {
                "label": "कसेरा 0041",
                "village_id": 4,
                "village_name": "कसेरा"
            },
            {
                "label": "खौना 0001",
                "village_id": 5,
                "village_name": "खौना"
            },
            {
                "label": "चानन 0040",
                "village_id": 6,
                "village_name": "चानन"
            },
            {
                "label": "छतौनी 0006",
                "village_id": 7,
                "village_name": "छतौनी"
            },
            {
                "label": "जदुलिया 0005",
                "village_id": 8,
                "village_name": "जदुलिया"
            },
            {
                "label": "जसो 0022",
                "village_id": 9,
                "village_name": "जसो"
            },
            {
                "label": "जौकी 0007",
                "village_id": 10,
                "village_name": "जौकी"
            },
            {
                "label": "डामू 0039",
                "village_id": 11,
                "village_name": "डामू"
            },
            {
                "label": "नरकटिया 0034",
                "village_id": 12,
                "village_name": "नरकटिया"
            },
            {
                "label": "पचरतन 0036",
                "village_id": 13,
                "village_name": "पचरतन"
            },
            {
                "label": "पतौना 0043",
                "village_id": 14,
                "village_name": "पतौना"
            },
            {
                "label": "फेर 0044",
                "village_id": 15,
                "village_name": "फेर"
            },
            {
                "label": "भईयापट्टी 0047",
                "village_id": 16,
                "village_name": "भईयापट्टी"
            },
            {
                "label": "भदिया 0035",
                "village_id": 17,
                "village_name": "भदिया"
            },
            {
                "label": "भोआहा 0023",
                "village_id": 18,
                "village_name": "भोआहा"
            },
            {
                "label": "मटिनाजा 0008",
                "village_id": 19,
                "village_name": "मटिनाजा"
            },
            {
                "label": "मनमोहन 0045",
                "village_id": 20,
                "village_name": "मनमोहन"
            },
            {
                "label": "ममगैरा 0003",
                "village_id": 21,
                "village_name": "ममगैरा"
            },
            {
                "label": "महथौर 0021",
                "village_id": 22,
                "village_name": "महथौर"
            },
            {
                "label": "महिनाथपुर 0004",
                "village_id": 23,
                "village_name": "महिनाथपुर"
            },
            {
                "label": "राधाकान्त 0029",
                "village_id": 24,
                "village_name": "राधाकान्त"
            },
            {
                "label": "लेठिवा 0002",
                "village_id": 25,
                "village_name": "लेठिवा"
            },
            {
                "label": "वेलौना 0048",
                "village_id": 26,
                "village_name": "वेलौना"
            },
            {
                "label": "वासोपट्टी 0038",
                "village_id": 27,
                "village_name": "वासोपट्टी"
            },
            {
                "label": "विराटपुर 0046",
                "village_id": 28,
                "village_name": "विराटपुर"
            },
            {
                "label": "वीरपुर 0024",
                "village_id": 29,
                "village_name": "वीरपुर"
            },
            {
                "label": "सलोवेली 0030",
                "village_id": 30,
                "village_name": "सलोवेली"
            },
            {
                "label": "सिमाराढ़ी 0011",
                "village_id": 31,
                "village_name": "सिमाराढ़ी"
            },
            {
                "label": "सिराही 0019",
                "village_id": 32,
                "village_name": "सिराही"
            },
            {
                "label": "सिरियापुर 0042",
                "village_id": 33,
                "village_name": "सिरियापुर"
            },
            {
                "label": "हलथापुर 0037",
                "village_id": 34,
                "village_name": "हलथापुर"
            },
            {
                "label": "हवेरवेकी 0025",
                "village_id": 35,
                "village_name": "हवेरवेकी"
            }
        ],
        "block_id": "2",
        "block_no": 14,
        "block_name": "बासोपट्टी [002]",
        "sub_div_code": "4"
    },
    {
        "village": [
            {
                "label": "एकहरी 0148",
                "village_id": 2,
                "village_name": "एकहरी"
            },
            {
                "label": "कुमखत 0136",
                "village_id": 3,
                "village_name": "कुमखत"
            },
            {
                "label": "कमतौलिया 0137",
                "village_id": 4,
                "village_name": "कमतौलिया"
            },
            {
                "label": "खाजेडीह 0134",
                "village_id": 5,
                "village_name": "खाजेडीह"
            },
            {
                "label": "गजराहा 0152",
                "village_id": 6,
                "village_name": "गजराहा"
            },
            {
                "label": "गरहा 0144",
                "village_id": 7,
                "village_name": "गरहा"
            },
            {
                "label": "गिदवास 0132",
                "village_id": 8,
                "village_name": "गिदवास"
            },
            {
                "label": "चिकनौरवा 0139",
                "village_id": 9,
                "village_name": "चिकनौरवा"
            },
            {
                "label": "डलोखर 0173",
                "village_id": 10,
                "village_name": "डलोखर"
            },
            {
                "label": "धर्मवन 0147",
                "village_id": 11,
                "village_name": "धर्मवन"
            },
            {
                "label": "नेनदरही 0131",
                "village_id": 12,
                "village_name": "नेनदरही"
            },
            {
                "label": "पथराही 0155",
                "village_id": 13,
                "village_name": "पथराही"
            },
            {
                "label": "पदमा 0121",
                "village_id": 14,
                "village_name": "पदमा"
            },
            {
                "label": "पिन्डरौन 0153",
                "village_id": 15,
                "village_name": "पिन्डरौन"
            },
            {
                "label": "पिपराही 0143",
                "village_id": 16,
                "village_name": "पिपराही"
            },
            {
                "label": "ब्रह्मोत्तर 0154",
                "village_id": 17,
                "village_name": "ब्रह्मोत्तर"
            },
            {
                "label": "बेलाही 0145",
                "village_id": 18,
                "village_name": "बेलाही"
            },
            {
                "label": "बौरहा 0135",
                "village_id": 19,
                "village_name": "बौरहा"
            },
            {
                "label": "भुतहा 0133",
                "village_id": 20,
                "village_name": "भुतहा"
            },
            {
                "label": "महुआ 0138",
                "village_id": 21,
                "village_name": "महुआ"
            },
            {
                "label": "महथा 0141",
                "village_id": 22,
                "village_name": "महथा"
            },
            {
                "label": "मिर्जापुर 0129",
                "village_id": 23,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "योगिया 0120",
                "village_id": 24,
                "village_name": "योगिया"
            },
            {
                "label": "लदनियां 0142",
                "village_id": 25,
                "village_name": "लदनियां"
            },
            {
                "label": "विशुनपुर 0140",
                "village_id": 26,
                "village_name": "विशुनपुर"
            },
            {
                "label": "सिधयकला 0122",
                "village_id": 27,
                "village_name": "सिधयकला"
            },
            {
                "label": "सिधया 0146",
                "village_id": 28,
                "village_name": "सिधया"
            },
            {
                "label": "सिपहगिरी 0130",
                "village_id": 29,
                "village_name": "सिपहगिरी"
            }
        ],
        "block_id": "3",
        "block_no": 15,
        "block_name": "लदनियाँ [003]",
        "sub_div_code": "4"
    },
    {
        "village": [
            {
                "label": "अजरकवे महिन्दरवार 0144",
                "village_id": 2,
                "village_name": "अजरकवे महिन्दरवार"
            },
            {
                "label": "अमौजा 0127",
                "village_id": 3,
                "village_name": "अमौजा"
            },
            {
                "label": "इनामत 0110",
                "village_id": 4,
                "village_name": "इनामत"
            },
            {
                "label": "एराजी सैनी 0129",
                "village_id": 5,
                "village_name": "एराजी सैनी"
            },
            {
                "label": "कालापट्टी 0114",
                "village_id": 6,
                "village_name": "कालापट्टी"
            },
            {
                "label": "खरगामा 0133",
                "village_id": 7,
                "village_name": "खरगामा"
            },
            {
                "label": "गंगधारा 0111",
                "village_id": 8,
                "village_name": "गंगधारा"
            },
            {
                "label": "गंगधारा ब्रह्मोत्तर 0112",
                "village_id": 9,
                "village_name": "गंगधारा ब्रह्मोत्तर"
            },
            {
                "label": "गरीपट्टी 0117",
                "village_id": 10,
                "village_name": "गरीपट्टी"
            },
            {
                "label": "गैहुया बैरिया 0123",
                "village_id": 11,
                "village_name": "गैहुया बैरिया"
            },
            {
                "label": "गोअरगामा 0102",
                "village_id": 12,
                "village_name": "गोअरगामा"
            },
            {
                "label": "गौढ़ियारी 0120",
                "village_id": 13,
                "village_name": "गौढ़ियारी"
            },
            {
                "label": "जगतपुर 0132",
                "village_id": 14,
                "village_name": "जगतपुर"
            },
            {
                "label": "झखराही 0113",
                "village_id": 15,
                "village_name": "झखराही"
            },
            {
                "label": "तौफीर 0126",
                "village_id": 16,
                "village_name": "तौफीर"
            },
            {
                "label": "दाहापट्टी 0115",
                "village_id": 17,
                "village_name": "दाहापट्टी"
            },
            {
                "label": "धनौजा 0119",
                "village_id": 18,
                "village_name": "धनौजा"
            },
            {
                "label": "धर्मडीहा 0130",
                "village_id": 19,
                "village_name": "धर्मडीहा"
            },
            {
                "label": "फुलपरास 0105",
                "village_id": 20,
                "village_name": "फुलपरास"
            },
            {
                "label": "बैका 0141",
                "village_id": 21,
                "village_name": "बैका"
            },
            {
                "label": "बछौनी 0131",
                "village_id": 22,
                "village_name": "बछौनी"
            },
            {
                "label": "बबनाहा 0012",
                "village_id": 23,
                "village_name": "बबनाहा"
            },
            {
                "label": "बलुआ 0116",
                "village_id": 24,
                "village_name": "बलुआ"
            },
            {
                "label": "भरहा 0014",
                "village_id": 25,
                "village_name": "भरहा"
            },
            {
                "label": "भरही 0013",
                "village_id": 26,
                "village_name": "भरही"
            },
            {
                "label": "मुड़वाला 0125",
                "village_id": 27,
                "village_name": "मुड़वाला"
            },
            {
                "label": "मैनहा 0015",
                "village_id": 28,
                "village_name": "मैनहा"
            },
            {
                "label": "मुरली 0107",
                "village_id": 29,
                "village_name": "मुरली"
            },
            {
                "label": "मुशहरनियां 0099",
                "village_id": 30,
                "village_name": "मुशहरनियां"
            },
            {
                "label": "महथौर खुर्द 0108",
                "village_id": 31,
                "village_name": "महथौर खुर्द"
            },
            {
                "label": "महिन्दवार 0143",
                "village_id": 32,
                "village_name": "महिन्दवार"
            },
            {
                "label": "रामनगर 0101",
                "village_id": 33,
                "village_name": "रामनगर"
            },
            {
                "label": "लकसेना 0192",
                "village_id": 34,
                "village_name": "लकसेना"
            },
            {
                "label": "वैरियाही 0098",
                "village_id": 35,
                "village_name": "वैरियाही"
            },
            {
                "label": "सुगापट्टी 0122",
                "village_id": 36,
                "village_name": "सुगापट्टी"
            },
            {
                "label": "सैनी 0128",
                "village_id": 37,
                "village_name": "सैनी"
            },
            {
                "label": "सिकवा बरही 0106",
                "village_id": 38,
                "village_name": "सिकवा बरही"
            },
            {
                "label": "सिजौलिया 0134",
                "village_id": 39,
                "village_name": "सिजौलिया"
            },
            {
                "label": "सिसवार 0018",
                "village_id": 40,
                "village_name": "सिसवार"
            },
            {
                "label": "सिसौनी 0124",
                "village_id": 41,
                "village_name": "सिसौनी"
            },
            {
                "label": "हरिनाथपट्टी 0118",
                "village_id": 42,
                "village_name": "हरिनाथपट्टी"
            },
            {
                "label": "हरियरी 0121",
                "village_id": 43,
                "village_name": "हरियरी"
            }
        ],
        "block_id": "1",
        "block_no": 16,
        "block_name": "फुलपरास [001]",
        "sub_div_code": "5"
    },
    {
        "village": [
            {
                "label": "अजरकवे सुढ़ौल 0159",
                "village_id": 2,
                "village_name": "अजरकवे सुढ़ौल"
            },
            {
                "label": "अजरकवे सुढ़ौल 0160",
                "village_id": 3,
                "village_name": "अजरकवे सुढ़ौल"
            },
            {
                "label": "अमही 0179",
                "village_id": 4,
                "village_name": "अमही"
            },
            {
                "label": "अलोला 0175",
                "village_id": 5,
                "village_name": "अलोला"
            },
            {
                "label": "इनरवा 0168",
                "village_id": 6,
                "village_name": "इनरवा"
            },
            {
                "label": "एकदरा 0140",
                "village_id": 7,
                "village_name": "एकदरा"
            },
            {
                "label": "केवरना 0188",
                "village_id": 8,
                "village_name": "केवरना"
            },
            {
                "label": "काशीरामपट्टी 0164",
                "village_id": 9,
                "village_name": "काशीरामपट्टी"
            },
            {
                "label": "किरतपुर 0158",
                "village_id": 10,
                "village_name": "किरतपुर"
            },
            {
                "label": "किशनीपट्टी 0172",
                "village_id": 11,
                "village_name": "किशनीपट्टी"
            },
            {
                "label": "कोनार 0138",
                "village_id": 12,
                "village_name": "कोनार"
            },
            {
                "label": "खोपा 0139",
                "village_id": 13,
                "village_name": "खोपा"
            },
            {
                "label": "चिकना 0190",
                "village_id": 14,
                "village_name": "चिकना"
            },
            {
                "label": "ठरविरिया 0165",
                "village_id": 15,
                "village_name": "ठरविरिया"
            },
            {
                "label": "तिलाठ 0166",
                "village_id": 16,
                "village_name": "तिलाठ"
            },
            {
                "label": "दुलासपट्टी 0169",
                "village_id": 17,
                "village_name": "दुलासपट्टी"
            },
            {
                "label": "देवढ़ 0171",
                "village_id": 18,
                "village_name": "देवढ़"
            },
            {
                "label": "देवनाथपट्टी 0180",
                "village_id": 19,
                "village_name": "देवनाथपट्टी"
            },
            {
                "label": "धनपत बरदी 0184",
                "village_id": 20,
                "village_name": "धनपत बरदी"
            },
            {
                "label": "धराना 0096",
                "village_id": 21,
                "village_name": "धराना"
            },
            {
                "label": "धावधाट 0197",
                "village_id": 22,
                "village_name": "धावधाट"
            },
            {
                "label": "धोधरडिहा 0186",
                "village_id": 23,
                "village_name": "धोधरडिहा"
            },
            {
                "label": "नाथोपुर 0137",
                "village_id": 24,
                "village_name": "नाथोपुर"
            },
            {
                "label": "निधमा 0182",
                "village_id": 25,
                "village_name": "निधमा"
            },
            {
                "label": "नौवा वाखर 0183",
                "village_id": 26,
                "village_name": "नौवा वाखर"
            },
            {
                "label": "परसा 0100",
                "village_id": 27,
                "village_name": "परसा"
            },
            {
                "label": "पिपरा कमलपुर 0174",
                "village_id": 28,
                "village_name": "पिपरा कमलपुर"
            },
            {
                "label": "पिपरौलिया 0156",
                "village_id": 29,
                "village_name": "पिपरौलिया"
            },
            {
                "label": "पिरैल 0192",
                "village_id": 30,
                "village_name": "पिरैल"
            },
            {
                "label": "पिरोजगढ़ 0187",
                "village_id": 31,
                "village_name": "पिरोजगढ़"
            },
            {
                "label": "बथनाहा 0103",
                "village_id": 32,
                "village_name": "बथनाहा"
            },
            {
                "label": "ब्रह्मपुर 0104",
                "village_id": 33,
                "village_name": "ब्रह्मपुर"
            },
            {
                "label": "ब्रह्मपुरा 0170",
                "village_id": 34,
                "village_name": "ब्रह्मपुरा"
            },
            {
                "label": "बेलमोहन 0162",
                "village_id": 35,
                "village_name": "बेलमोहन"
            },
            {
                "label": "बिपहरिया 0189",
                "village_id": 36,
                "village_name": "बिपहरिया"
            },
            {
                "label": "भपरियाही 0097",
                "village_id": 37,
                "village_name": "भपरियाही"
            },
            {
                "label": "मैनही 0177",
                "village_id": 38,
                "village_name": "मैनही"
            },
            {
                "label": "महदेवा 0167",
                "village_id": 39,
                "village_name": "महदेवा"
            },
            {
                "label": "मिरदर 0191",
                "village_id": 40,
                "village_name": "मिरदर"
            },
            {
                "label": "रजुआही 0195",
                "village_id": 41,
                "village_name": "रजुआही"
            },
            {
                "label": "लक्ष्मीनीयां 0095",
                "village_id": 42,
                "village_name": "लक्ष्मीनीयां"
            },
            {
                "label": "वेदरारी 0157",
                "village_id": 43,
                "village_name": "वेदरारी"
            },
            {
                "label": "वनरझुला 0178",
                "village_id": 44,
                "village_name": "वनरझुला"
            },
            {
                "label": "वलानशेर 0135",
                "village_id": 45,
                "village_name": "वलानशेर"
            },
            {
                "label": "वसुआरी 0173",
                "village_id": 46,
                "village_name": "वसुआरी"
            },
            {
                "label": "विशनपुर 0136",
                "village_id": 47,
                "village_name": "विशनपुर"
            },
            {
                "label": "सुदैम रतैली 0161",
                "village_id": 48,
                "village_name": "सुदैम रतैली"
            },
            {
                "label": "सरैती 0181",
                "village_id": 49,
                "village_name": "सरैती"
            },
            {
                "label": "सांगी 0163",
                "village_id": 50,
                "village_name": "सांगी"
            },
            {
                "label": "सोहरवा 0196",
                "village_id": 51,
                "village_name": "सोहरवा"
            },
            {
                "label": "हरनी 0185",
                "village_id": 52,
                "village_name": "हरनी"
            },
            {
                "label": "हररी 0176",
                "village_id": 53,
                "village_name": "हररी"
            }
        ],
        "block_id": "2",
        "block_no": 17,
        "block_name": "घोघरडीहा [002]",
        "sub_div_code": "5"
    },
    {
        "village": [
            {
                "label": "अंधाखन 0024",
                "village_id": 2,
                "village_name": "अंधाखन"
            },
            {
                "label": "एकहत्था आलापुर 0019",
                "village_id": 3,
                "village_name": "एकहत्था आलापुर"
            },
            {
                "label": "एकहत्था जब्दी 23/2",
                "village_id": 4,
                "village_name": "एकहत्था जब्दी"
            },
            {
                "label": "कलरीपट्टी 23/2",
                "village_id": 5,
                "village_name": "कलरीपट्टी"
            },
            {
                "label": "कारमेध 0009",
                "village_id": 6,
                "village_name": "कारमेध"
            },
            {
                "label": "खुटौना 0010",
                "village_id": 7,
                "village_name": "खुटौना"
            },
            {
                "label": "चतुर्भुज 0027",
                "village_id": 8,
                "village_name": "चतुर्भुज"
            },
            {
                "label": "झगरूआ 0006",
                "village_id": 9,
                "village_name": "झगरूआ"
            },
            {
                "label": "झांझपट्टी आशा 23/2",
                "village_id": 10,
                "village_name": "झांझपट्टी आशा"
            },
            {
                "label": "झांझपट्टी डोमन 23/1",
                "village_id": 11,
                "village_name": "झांझपट्टी डोमन"
            },
            {
                "label": "झांझरा 23/6",
                "village_id": 12,
                "village_name": "झांझरा"
            },
            {
                "label": "टेंगरार 0109",
                "village_id": 13,
                "village_name": "टेंगरार"
            },
            {
                "label": "दुर्गापट्टी 23/2",
                "village_id": 14,
                "village_name": "दुर्गापट्टी"
            },
            {
                "label": "नारायणपुर 23/3",
                "village_id": 15,
                "village_name": "नारायणपुर"
            },
            {
                "label": "पथराही 23/5",
                "village_id": 16,
                "village_name": "पथराही"
            },
            {
                "label": "परसाही 0020",
                "village_id": 17,
                "village_name": "परसाही"
            },
            {
                "label": "पीपराही 0026",
                "village_id": 18,
                "village_name": "पीपराही"
            },
            {
                "label": "बघकुसमार 0017",
                "village_id": 19,
                "village_name": "बघकुसमार"
            },
            {
                "label": "बुढ़वेरा 23/1",
                "village_id": 20,
                "village_name": "बुढ़वेरा"
            },
            {
                "label": "बधमरिया 23/1",
                "village_id": 21,
                "village_name": "बधमरिया"
            },
            {
                "label": "बनरझुला 23/2",
                "village_id": 22,
                "village_name": "बनरझुला"
            },
            {
                "label": "बरकौड़ 0029",
                "village_id": 23,
                "village_name": "बरकौड़"
            },
            {
                "label": "ब्रह्मोत्तर 0007",
                "village_id": 24,
                "village_name": "ब्रह्मोत्तर"
            },
            {
                "label": "ब्रह्मोत्तर 0008",
                "village_id": 25,
                "village_name": "ब्रह्मोत्तर"
            },
            {
                "label": "ब्रह्मोत्तर 0004",
                "village_id": 26,
                "village_name": "ब्रह्मोत्तर"
            },
            {
                "label": "ब्रह्मोत्तरा 0022",
                "village_id": 27,
                "village_name": "ब्रह्मोत्तरा"
            },
            {
                "label": "बलानपट्टी 23/8",
                "village_id": 28,
                "village_name": "बलानपट्टी"
            },
            {
                "label": "बासदेवपुर 0025",
                "village_id": 29,
                "village_name": "बासदेवपुर"
            },
            {
                "label": "बौरठा 23/1",
                "village_id": 30,
                "village_name": "बौरठा"
            },
            {
                "label": "भजनाहा 23/7",
                "village_id": 31,
                "village_name": "भजनाहा"
            },
            {
                "label": "मझौरा 23/4",
                "village_id": 32,
                "village_name": "मझौरा"
            },
            {
                "label": "महरी 23/1",
                "village_id": 33,
                "village_name": "महरी"
            },
            {
                "label": "माधोपुर 23/1",
                "village_id": 34,
                "village_name": "माधोपुर"
            },
            {
                "label": "रामचन्द्र 0005",
                "village_id": 35,
                "village_name": "रामचन्द्र"
            },
            {
                "label": "लक्ष्मीपुर 23/1",
                "village_id": 36,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लसनियां 23/9",
                "village_id": 37,
                "village_name": "लसनियां"
            },
            {
                "label": "लौकहा 0002",
                "village_id": 38,
                "village_name": "लौकहा"
            },
            {
                "label": "वीरपुर 0003",
                "village_id": 39,
                "village_name": "वीरपुर"
            },
            {
                "label": "सलमनियां 0001",
                "village_id": 40,
                "village_name": "सलमनियां"
            },
            {
                "label": "सिकटियाही 0011",
                "village_id": 41,
                "village_name": "सिकटियाही"
            },
            {
                "label": "सिसवाड़ 23/1",
                "village_id": 42,
                "village_name": "सिसवाड़"
            },
            {
                "label": "सिहुला 23/1",
                "village_id": 43,
                "village_name": "सिहुला"
            },
            {
                "label": "हुदरा 23/2",
                "village_id": 44,
                "village_name": "हुदरा"
            },
            {
                "label": "हिंवा 0021",
                "village_id": 45,
                "village_name": "हिंवा"
            },
            {
                "label": "होरा (उड़वा) 23/1",
                "village_id": 46,
                "village_name": "होरा (उड़वा)"
            }
        ],
        "block_id": "3",
        "block_no": 18,
        "block_name": "खुटौना [003]",
        "sub_div_code": "5"
    },
    {
        "village": [
            {
                "label": "अंधरामठ 0050",
                "village_id": 2,
                "village_name": "अंधरामठ"
            },
            {
                "label": "औराहा 0090",
                "village_id": 3,
                "village_name": "औराहा"
            },
            {
                "label": "कुकुरदौड़ा 0052",
                "village_id": 4,
                "village_name": "कुकुरदौड़ा"
            },
            {
                "label": "कचनरवा 0084",
                "village_id": 5,
                "village_name": "कचनरवा"
            },
            {
                "label": "कुड़ीवन 0087",
                "village_id": 6,
                "village_name": "कुड़ीवन"
            },
            {
                "label": "करहरवा 0083",
                "village_id": 7,
                "village_name": "करहरवा"
            },
            {
                "label": "करियौत 0048",
                "village_id": 8,
                "village_name": "करियौत"
            },
            {
                "label": "कलुआही 0046",
                "village_id": 9,
                "village_name": "कलुआही"
            },
            {
                "label": "कुलहरिया 0057",
                "village_id": 10,
                "village_name": "कुलहरिया"
            },
            {
                "label": "कुशमाही 0078",
                "village_id": 11,
                "village_name": "कुशमाही"
            },
            {
                "label": "कोरिहर लोकही 0070",
                "village_id": 12,
                "village_name": "कोरिहर लोकही"
            },
            {
                "label": "खड़कपुर 0079",
                "village_id": 13,
                "village_name": "खड़कपुर"
            },
            {
                "label": "गिदराही 0065",
                "village_id": 14,
                "village_name": "गिदराही"
            },
            {
                "label": "चन्द्रपट्टी 0055",
                "village_id": 15,
                "village_name": "चन्द्रपट्टी"
            },
            {
                "label": "चिचोढ़वा 0043",
                "village_id": 16,
                "village_name": "चिचोढ़वा"
            },
            {
                "label": "जमसर 0036",
                "village_id": 17,
                "village_name": "जमसर"
            },
            {
                "label": "जरौली 0076",
                "village_id": 18,
                "village_name": "जरौली"
            },
            {
                "label": "जिरोगा 0058",
                "village_id": 19,
                "village_name": "जिरोगा"
            },
            {
                "label": "झझहिया 0042",
                "village_id": 20,
                "village_name": "झझहिया"
            },
            {
                "label": "झहुरी 0038",
                "village_id": 21,
                "village_name": "झहुरी"
            },
            {
                "label": "ठाढ़ी 0044",
                "village_id": 22,
                "village_name": "ठाढ़ी"
            },
            {
                "label": "डकही 0053",
                "village_id": 23,
                "village_name": "डकही"
            },
            {
                "label": "तुलासियाही 0033",
                "village_id": 24,
                "village_name": "तुलासियाही"
            },
            {
                "label": "थरूआही 0032",
                "village_id": 25,
                "village_name": "थरूआही"
            },
            {
                "label": "धनधोहा 0068",
                "village_id": 26,
                "village_name": "धनधोहा"
            },
            {
                "label": "धऱहारा 0056",
                "village_id": 27,
                "village_name": "धऱहारा"
            },
            {
                "label": "नेउर 0060",
                "village_id": 28,
                "village_name": "नेउर"
            },
            {
                "label": "नरेन्द्रपुर 0062",
                "village_id": 29,
                "village_name": "नरेन्द्रपुर"
            },
            {
                "label": "नरेन्द्रपुर ब्रह्मोत्तर 0063",
                "village_id": 30,
                "village_name": "नरेन्द्रपुर ब्रह्मोत्तर"
            },
            {
                "label": "नरहरिया 0088",
                "village_id": 31,
                "village_name": "नरहरिया"
            },
            {
                "label": "नरही योगियाही 0073",
                "village_id": 32,
                "village_name": "नरही योगियाही"
            },
            {
                "label": "नवटोली 0092",
                "village_id": 33,
                "village_name": "नवटोली"
            },
            {
                "label": "नारी 0034",
                "village_id": 34,
                "village_name": "नारी"
            },
            {
                "label": "पिपराही 0041",
                "village_id": 35,
                "village_name": "पिपराही"
            },
            {
                "label": "पीपरौन 0047",
                "village_id": 36,
                "village_name": "पीपरौन"
            },
            {
                "label": "बुढ़विको 0061",
                "village_id": 37,
                "village_name": "बुढ़विको"
            },
            {
                "label": "बनगामा 0094",
                "village_id": 38,
                "village_name": "बनगामा"
            },
            {
                "label": "बसुआर 0071",
                "village_id": 39,
                "village_name": "बसुआर"
            },
            {
                "label": "बाजुबंद 0074",
                "village_id": 40,
                "village_name": "बाजुबंद"
            },
            {
                "label": "बिक्रमशेर 0051",
                "village_id": 41,
                "village_name": "बिक्रमशेर"
            },
            {
                "label": "भुतहा 0093",
                "village_id": 42,
                "village_name": "भुतहा"
            },
            {
                "label": "भरफौरी 0059",
                "village_id": 43,
                "village_name": "भरफौरी"
            },
            {
                "label": "भरही 0075",
                "village_id": 44,
                "village_name": "भरही"
            },
            {
                "label": "मखनाहा 0049",
                "village_id": 45,
                "village_name": "मखनाहा"
            },
            {
                "label": "मनसापुर 0085",
                "village_id": 46,
                "village_name": "मनसापुर"
            },
            {
                "label": "महथौर 0066",
                "village_id": 47,
                "village_name": "महथौर"
            },
            {
                "label": "महदेवा 0035",
                "village_id": 48,
                "village_name": "महदेवा"
            },
            {
                "label": "महदेवा 0089",
                "village_id": 49,
                "village_name": "महदेवा"
            },
            {
                "label": "महराजगंज 0030",
                "village_id": 50,
                "village_name": "महराजगंज"
            },
            {
                "label": "महादेव मठ 0064",
                "village_id": 51,
                "village_name": "महादेव मठ"
            },
            {
                "label": "रजौड़ा महथौरा 0067",
                "village_id": 52,
                "village_name": "रजौड़ा महथौरा"
            },
            {
                "label": "राजारामपट्टी 0072",
                "village_id": 53,
                "village_name": "राजारामपट्टी"
            },
            {
                "label": "लक्ष्मीपुर 0086",
                "village_id": 54,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लदनियां 0045",
                "village_id": 55,
                "village_name": "लदनियां"
            },
            {
                "label": "लौकही 0037",
                "village_id": 56,
                "village_name": "लौकही"
            },
            {
                "label": "वेलही भवानीपुर 0082",
                "village_id": 57,
                "village_name": "वेलही भवानीपुर"
            },
            {
                "label": "वोलहर 0054",
                "village_id": 58,
                "village_name": "वोलहर"
            },
            {
                "label": "सनपतहा 0040",
                "village_id": 59,
                "village_name": "सनपतहा"
            },
            {
                "label": "सातुआ 0080",
                "village_id": 60,
                "village_name": "सातुआ"
            },
            {
                "label": "साननपट्टी 0091",
                "village_id": 61,
                "village_name": "साननपट्टी"
            },
            {
                "label": "सिंगराही 0028",
                "village_id": 62,
                "village_name": "सिंगराही"
            },
            {
                "label": "सोनवर्षा 0039",
                "village_id": 63,
                "village_name": "सोनवर्षा"
            },
            {
                "label": "हरद्वार लोकही 0069",
                "village_id": 64,
                "village_name": "हरद्वार लोकही"
            },
            {
                "label": "हरभंगा 0031",
                "village_id": 65,
                "village_name": "हरभंगा"
            },
            {
                "label": "हरिराहा 0077",
                "village_id": 66,
                "village_name": "हरिराहा"
            },
            {
                "label": "हरिराहा 0081",
                "village_id": 67,
                "village_name": "हरिराहा"
            }
        ],
        "block_id": "4",
        "block_no": 19,
        "block_name": "लौकही [004]",
        "sub_div_code": "5"
    }
],
    "district_id": "05",
    "district_name": "मधुबनी"
},
{
    "block": [
    {
        "village": [
            {
                "label": "अन्धवाकोल 0003",
                "village_id": 2,
                "village_name": "अन्धवाकोल"
            },
            {
                "label": "अन्धवाकोल 0024",
                "village_id": 3,
                "village_name": "अन्धवाकोल"
            },
            {
                "label": "कठलिया 0073",
                "village_id": 4,
                "village_name": "कठलिया"
            },
            {
                "label": "कपरबन्धा 0084",
                "village_id": 5,
                "village_name": "कपरबन्धा"
            },
            {
                "label": "काठमोहन 0010",
                "village_id": 6,
                "village_name": "काठमोहन"
            },
            {
                "label": "काशीपुर 0008",
                "village_id": 7,
                "village_name": "काशीपुर"
            },
            {
                "label": "कोल्हा 0034",
                "village_id": 8,
                "village_name": "कोल्हा"
            },
            {
                "label": "खिरदा समदा 0067",
                "village_id": 9,
                "village_name": "खिरदा समदा"
            },
            {
                "label": "ग्वाल़टोली 0016",
                "village_id": 10,
                "village_name": "ग्वाल़टोली"
            },
            {
                "label": "गाछपाड़ा 0021",
                "village_id": 11,
                "village_name": "गाछपाड़ा"
            },
            {
                "label": "गाछपाड़ा 0022",
                "village_id": 12,
                "village_name": "गाछपाड़ा"
            },
            {
                "label": "गोविन्दपुर 0061",
                "village_id": 13,
                "village_name": "गोविन्दपुर"
            },
            {
                "label": "घाटबाभन टोली 0081",
                "village_id": 14,
                "village_name": "घाटबाभन टोली"
            },
            {
                "label": "घोड़ामारा 0045",
                "village_id": 15,
                "village_name": "घोड़ामारा"
            },
            {
                "label": "चकला 0028",
                "village_id": 16,
                "village_name": "चकला"
            },
            {
                "label": "चौन्दी 0020",
                "village_id": 17,
                "village_name": "चौन्दी"
            },
            {
                "label": "छगलिया 0012",
                "village_id": 18,
                "village_name": "छगलिया"
            },
            {
                "label": "छगलिया 0009",
                "village_id": 19,
                "village_name": "छगलिया"
            },
            {
                "label": "जनमजय 0079",
                "village_id": 20,
                "village_name": "जनमजय"
            },
            {
                "label": "जियागाछी 0086",
                "village_id": 21,
                "village_name": "जियागाछी"
            },
            {
                "label": "झाड़वाड़ी 0036",
                "village_id": 22,
                "village_name": "झाड़वाड़ी"
            },
            {
                "label": "टेउसा 0039",
                "village_id": 23,
                "village_name": "टेउसा"
            },
            {
                "label": "टेंगऱमारी 0007",
                "village_id": 24,
                "village_name": "टेंगऱमारी"
            },
            {
                "label": "टुपामारी 0027",
                "village_id": 25,
                "village_name": "टुपामारी"
            },
            {
                "label": "ठौआपाड़ा 0038",
                "village_id": 26,
                "village_name": "ठौआपाड़ा"
            },
            {
                "label": "ठौआपाड़ा 0035",
                "village_id": 27,
                "village_name": "ठौआपाड़ा"
            },
            {
                "label": "ढ़ेकसारा 0044",
                "village_id": 28,
                "village_name": "ढ़ेकसारा"
            },
            {
                "label": "तेलिया पोखर 0017",
                "village_id": 29,
                "village_name": "तेलिया पोखर"
            },
            {
                "label": "दौला 0075",
                "village_id": 30,
                "village_name": "दौला"
            },
            {
                "label": "नुनियां 0063",
                "village_id": 31,
                "village_name": "नुनियां"
            },
            {
                "label": "पानीशाल 0014",
                "village_id": 32,
                "village_name": "पानीशाल"
            },
            {
                "label": "पिछला 0085",
                "village_id": 33,
                "village_name": "पिछला"
            },
            {
                "label": "पियाकुड़ी 0018",
                "village_id": 34,
                "village_name": "पियाकुड़ी"
            },
            {
                "label": "पिरानी 0046",
                "village_id": 35,
                "village_name": "पिरानी"
            },
            {
                "label": "पोरलाबाड़ी 0074",
                "village_id": 36,
                "village_name": "पोरलाबाड़ी"
            },
            {
                "label": "फरसाडांगी 0064",
                "village_id": 37,
                "village_name": "फरसाडांगी"
            },
            {
                "label": "फुलवाड़ी 0030",
                "village_id": 38,
                "village_name": "फुलवाड़ी"
            },
            {
                "label": "फुलवाड़ी 0031",
                "village_id": 39,
                "village_name": "फुलवाड़ी"
            },
            {
                "label": "फुलवाड़ी 0071",
                "village_id": 40,
                "village_name": "फुलवाड़ी"
            },
            {
                "label": "बैरगाछी 0037",
                "village_id": 41,
                "village_name": "बैरगाछी"
            },
            {
                "label": "बरचूना 0082",
                "village_id": 42,
                "village_name": "बरचूना"
            },
            {
                "label": "बरारो 0080",
                "village_id": 43,
                "village_name": "बरारो"
            },
            {
                "label": "बेलवा 0060",
                "village_id": 44,
                "village_name": "बेलवा"
            },
            {
                "label": "बेलवा मिलिक 0059",
                "village_id": 45,
                "village_name": "बेलवा मिलिक"
            },
            {
                "label": "बेलवाकाशीपुर 0004",
                "village_id": 46,
                "village_name": "बेलवाकाशीपुर"
            },
            {
                "label": "बलिया 0076",
                "village_id": 47,
                "village_name": "बलिया"
            },
            {
                "label": "बसंतपुर मिलिक 0065",
                "village_id": 48,
                "village_name": "बसंतपुर मिलिक"
            },
            {
                "label": "बसंतपुर मिलिक 0070",
                "village_id": 49,
                "village_name": "बसंतपुर मिलिक"
            },
            {
                "label": "बसंतपुऱमरुवा टोली 0066",
                "village_id": 50,
                "village_name": "बसंतपुऱमरुवा टोली"
            },
            {
                "label": "बस्ताडाँगी 0087",
                "village_id": 51,
                "village_name": "बस्ताडाँगी"
            },
            {
                "label": "भेड़िया डांगी 0026",
                "village_id": 52,
                "village_name": "भेड़िया डांगी"
            },
            {
                "label": "भागकठलिया 0072",
                "village_id": 53,
                "village_name": "भागकठलिया"
            },
            {
                "label": "मंझोक 0077",
                "village_id": 54,
                "village_name": "मंझोक"
            },
            {
                "label": "मैदा 0013",
                "village_id": 55,
                "village_name": "मैदा"
            },
            {
                "label": "महेशबथना 0023",
                "village_id": 56,
                "village_name": "महेशबथना"
            },
            {
                "label": "महेशबथना 0032",
                "village_id": 57,
                "village_name": "महेशबथना"
            },
            {
                "label": "महेशबथना 0033",
                "village_id": 58,
                "village_name": "महेशबथना"
            },
            {
                "label": "महीनगाँव 0069",
                "village_id": 59,
                "village_name": "महीनगाँव"
            },
            {
                "label": "महीनगाँव मिलिक 0068",
                "village_id": 60,
                "village_name": "महीनगाँव मिलिक"
            },
            {
                "label": "मोतीहारा 0002",
                "village_id": 61,
                "village_name": "मोतीहारा"
            },
            {
                "label": "मोतीहारा तालुका 0011",
                "village_id": 62,
                "village_name": "मोतीहारा तालुका"
            },
            {
                "label": "मोहम्मदपुर 0083",
                "village_id": 63,
                "village_name": "मोहम्मदपुर"
            },
            {
                "label": "लखीमारो 0062",
                "village_id": 64,
                "village_name": "लखीमारो"
            },
            {
                "label": "लालबाड़ी 0078",
                "village_id": 65,
                "village_name": "लालबाड़ी"
            },
            {
                "label": "वार्ड न.01 0000",
                "village_id": 66,
                "village_name": "वार्ड न.01"
            },
            {
                "label": "वार्ड न.02 0000",
                "village_id": 67,
                "village_name": "वार्ड न.02"
            },
            {
                "label": "वार्ड न.03 0000",
                "village_id": 68,
                "village_name": "वार्ड न.03"
            },
            {
                "label": "वार्ड न.04 0000",
                "village_id": 69,
                "village_name": "वार्ड न.04"
            },
            {
                "label": "वार्ड न.05 0000",
                "village_id": 70,
                "village_name": "वार्ड न.05"
            },
            {
                "label": "वार्ड न.06 0000",
                "village_id": 71,
                "village_name": "वार्ड न.06"
            },
            {
                "label": "वार्ड न.07 0000",
                "village_id": 72,
                "village_name": "वार्ड न.07"
            },
            {
                "label": "वार्ड न.08 0000",
                "village_id": 73,
                "village_name": "वार्ड न.08"
            },
            {
                "label": "वार्ड न.09 0000",
                "village_id": 74,
                "village_name": "वार्ड न.09"
            },
            {
                "label": "वार्ड न.10 0000",
                "village_id": 75,
                "village_name": "वार्ड न.10"
            },
            {
                "label": "वार्ड न.11 0000",
                "village_id": 76,
                "village_name": "वार्ड न.11"
            },
            {
                "label": "वार्ड न.12 0000",
                "village_id": 77,
                "village_name": "वार्ड न.12"
            },
            {
                "label": "वार्ड न.13 0000",
                "village_id": 78,
                "village_name": "वार्ड न.13"
            },
            {
                "label": "वार्ड न.14 0000",
                "village_id": 79,
                "village_name": "वार्ड न.14"
            },
            {
                "label": "वार्ड न.15 0000",
                "village_id": 80,
                "village_name": "वार्ड न.15"
            },
            {
                "label": "वार्ड न.16 0000",
                "village_id": 81,
                "village_name": "वार्ड न.16"
            },
            {
                "label": "वार्ड न.17 0000",
                "village_id": 82,
                "village_name": "वार्ड न.17"
            },
            {
                "label": "वार्ड न.18 0000",
                "village_id": 83,
                "village_name": "वार्ड न.18"
            },
            {
                "label": "वार्ड न.19 0000",
                "village_id": 84,
                "village_name": "वार्ड न.19"
            },
            {
                "label": "वार्ड न.20 0000",
                "village_id": 85,
                "village_name": "वार्ड न.20"
            },
            {
                "label": "वार्ड न.21 0000",
                "village_id": 86,
                "village_name": "वार्ड न.21"
            },
            {
                "label": "वार्ड न.22 0000",
                "village_id": 87,
                "village_name": "वार्ड न.22"
            },
            {
                "label": "वार्ड न.23 0000",
                "village_id": 88,
                "village_name": "वार्ड न.23"
            },
            {
                "label": "वार्ड न.24 0000",
                "village_id": 89,
                "village_name": "वार्ड न.24"
            },
            {
                "label": "वार्ड न.25 0000",
                "village_id": 90,
                "village_name": "वार्ड न.25"
            },
            {
                "label": "वार्ड न.26 0000",
                "village_id": 91,
                "village_name": "वार्ड न.26"
            },
            {
                "label": "वार्ड न.27 0000",
                "village_id": 92,
                "village_name": "वार्ड न.27"
            },
            {
                "label": "वार्ड न.28 0000",
                "village_id": 93,
                "village_name": "वार्ड न.28"
            },
            {
                "label": "वार्ड न.29 0000",
                "village_id": 94,
                "village_name": "वार्ड न.29"
            },
            {
                "label": "वार्ड न.30 0000",
                "village_id": 95,
                "village_name": "वार्ड न.30"
            },
            {
                "label": "वार्ड न.31 0000",
                "village_id": 96,
                "village_name": "वार्ड न.31"
            },
            {
                "label": "वार्ड न.32 0000",
                "village_id": 97,
                "village_name": "वार्ड न.32"
            },
            {
                "label": "सतखम्‍हार 0019",
                "village_id": 98,
                "village_name": "सतखम्‍हार"
            },
            {
                "label": "सुल्तानपुर 0029",
                "village_id": 99,
                "village_name": "सुल्तानपुर"
            },
            {
                "label": "सालकी 0005",
                "village_id": 100,
                "village_name": "सालकी"
            },
            {
                "label": "सालकी 0006",
                "village_id": 101,
                "village_name": "सालकी"
            },
            {
                "label": "सिंघारी 0056",
                "village_id": 102,
                "village_name": "सिंघारी"
            },
            {
                "label": "सिंघिया 0058",
                "village_id": 103,
                "village_name": "सिंघिया"
            },
            {
                "label": "सिंघिया 0015",
                "village_id": 104,
                "village_name": "सिंघिया"
            },
            {
                "label": "सिमलबाड़ी 0025",
                "village_id": 105,
                "village_name": "सिमलबाड़ी"
            },
            {
                "label": "हालामाला 0001",
                "village_id": 106,
                "village_name": "हालामाला"
            }
        ],
        "block_id": "1",
        "block_no": 0,
        "block_name": "किशनगंज [001]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अलताबाड़ी 0009",
                "village_id": 2,
                "village_name": "अलताबाड़ी"
            },
            {
                "label": "कटहल बाड़ी 0338",
                "village_id": 3,
                "village_name": "कटहल बाड़ी"
            },
            {
                "label": "कटहल बाड़ी 0339",
                "village_id": 4,
                "village_name": "कटहल बाड़ी"
            },
            {
                "label": "कुढ़ैला 0370",
                "village_id": 5,
                "village_name": "कुढ़ैला"
            },
            {
                "label": "कुम्हार टोली 0006",
                "village_id": 6,
                "village_name": "कुम्हार टोली"
            },
            {
                "label": "कसबा खोदागंज 0001",
                "village_id": 7,
                "village_name": "कसबा खोदागंज"
            },
            {
                "label": "कोइमारी 0345",
                "village_id": 8,
                "village_name": "कोइमारी"
            },
            {
                "label": "कोइमारी मिलिक 0344",
                "village_id": 9,
                "village_name": "कोइमारी मिलिक"
            },
            {
                "label": "कोकट बनगामा 0341",
                "village_id": 10,
                "village_name": "कोकट बनगामा"
            },
            {
                "label": "खरसेल 0002",
                "village_id": 11,
                "village_name": "खरसेल"
            },
            {
                "label": "खारिज कुम्हिया 0332",
                "village_id": 12,
                "village_name": "खारिज कुम्हिया"
            },
            {
                "label": "गुंजनबारी 0362",
                "village_id": 13,
                "village_name": "गुंजनबारी"
            },
            {
                "label": "गुणा 0357",
                "village_id": 14,
                "village_name": "गुणा"
            },
            {
                "label": "गुणाचौरासी 0356",
                "village_id": 15,
                "village_name": "गुणाचौरासी"
            },
            {
                "label": "गुणासमेसर 0289",
                "village_id": 16,
                "village_name": "गुणासमेसर"
            },
            {
                "label": "गरगाँव 0295",
                "village_id": 17,
                "village_name": "गरगाँव"
            },
            {
                "label": "गरगाँव मिलिक 0294",
                "village_id": 18,
                "village_name": "गरगाँव मिलिक"
            },
            {
                "label": "गुवाबाड़ी 0111",
                "village_id": 19,
                "village_name": "गुवाबाड़ी"
            },
            {
                "label": "गुवाबाड़ी 0365",
                "village_id": 20,
                "village_name": "गुवाबाड़ी"
            },
            {
                "label": "गुवाबाड़ी 0369",
                "village_id": 21,
                "village_name": "गुवाबाड़ी"
            },
            {
                "label": "गांगी 0016",
                "village_id": 22,
                "village_name": "गांगी"
            },
            {
                "label": "गोपालपुर 0351",
                "village_id": 23,
                "village_name": "गोपालपुर"
            },
            {
                "label": "गोपालपुर बैरगाछी 0368",
                "village_id": 24,
                "village_name": "गोपालपुर बैरगाछी"
            },
            {
                "label": "चन्द्रगाँव 0183",
                "village_id": 25,
                "village_name": "चन्द्रगाँव"
            },
            {
                "label": "चन्दरवार 0342",
                "village_id": 26,
                "village_name": "चन्दरवार"
            },
            {
                "label": "चन्दरवार 0343",
                "village_id": 27,
                "village_name": "चन्दरवार"
            },
            {
                "label": "चरकपाड़ा 0185",
                "village_id": 28,
                "village_name": "चरकपाड़ा"
            },
            {
                "label": "चिकाबाड़ी 0005",
                "village_id": 29,
                "village_name": "चिकाबाड़ी"
            },
            {
                "label": "चोरकट्टा 0367",
                "village_id": 30,
                "village_name": "चोरकट्टा"
            },
            {
                "label": "छोटालौचा 0186",
                "village_id": 31,
                "village_name": "छोटालौचा"
            },
            {
                "label": "जुरैल 0358",
                "village_id": 32,
                "village_name": "जुरैल"
            },
            {
                "label": "झिंगाकाटा 0201",
                "village_id": 33,
                "village_name": "झिंगाकाटा"
            },
            {
                "label": "झिलझिली 0280",
                "village_id": 34,
                "village_name": "झिलझिली"
            },
            {
                "label": "झिलझीली 0366",
                "village_id": 35,
                "village_name": "झिलझीली"
            },
            {
                "label": "झींगाकाटा 0203",
                "village_id": 36,
                "village_name": "झींगाकाटा"
            },
            {
                "label": "टंगटंगी 0216",
                "village_id": 37,
                "village_name": "टंगटंगी"
            },
            {
                "label": "टंगटंगी 0225",
                "village_id": 38,
                "village_name": "टंगटंगी"
            },
            {
                "label": "टंगटंगी 0226",
                "village_id": 39,
                "village_name": "टंगटंगी"
            },
            {
                "label": "डुवाडांगी 0107",
                "village_id": 40,
                "village_name": "डुवाडांगी"
            },
            {
                "label": "डाबर 0003",
                "village_id": 41,
                "village_name": "डाबर"
            },
            {
                "label": "डोला 0007",
                "village_id": 42,
                "village_name": "डोला"
            },
            {
                "label": "डोला मोहीउद्दीनपुर 0008",
                "village_id": 43,
                "village_name": "डोला मोहीउद्दीनपुर"
            },
            {
                "label": "डोहर 0279",
                "village_id": 44,
                "village_name": "डोहर"
            },
            {
                "label": "तेघरिया 0211",
                "village_id": 45,
                "village_name": "तेघरिया"
            },
            {
                "label": "तुलसिया 0110",
                "village_id": 46,
                "village_name": "तुलसिया"
            },
            {
                "label": "तौकीर झींगाकाटा 0202",
                "village_id": 47,
                "village_name": "तौकीर झींगाकाटा"
            },
            {
                "label": "दमदमा 0023",
                "village_id": 48,
                "village_name": "दमदमा"
            },
            {
                "label": "दुर्गापुर 0218",
                "village_id": 49,
                "village_name": "दुर्गापुर"
            },
            {
                "label": "दुर्गापुर बनगामा 0177",
                "village_id": 50,
                "village_name": "दुर्गापुर बनगामा"
            },
            {
                "label": "दुर्गापुर सोहदी 0176",
                "village_id": 51,
                "village_name": "दुर्गापुर सोहदी"
            },
            {
                "label": "दुलाली 0335",
                "village_id": 52,
                "village_name": "दुलाली"
            },
            {
                "label": "दुलाली 0336",
                "village_id": 53,
                "village_name": "दुलाली"
            },
            {
                "label": "दुलाली मिलिक 0337",
                "village_id": 54,
                "village_name": "दुलाली मिलिक"
            },
            {
                "label": "देवोत्तर विरनियां 0287",
                "village_id": 55,
                "village_name": "देवोत्तर विरनियां"
            },
            {
                "label": "देसिया टोली 0010",
                "village_id": 56,
                "village_name": "देसिया टोली"
            },
            {
                "label": "दहगाँव 0204",
                "village_id": 57,
                "village_name": "दहगाँव"
            },
            {
                "label": "दहगाँव 0282",
                "village_id": 58,
                "village_name": "दहगाँव"
            },
            {
                "label": "दोगच्छी 0198",
                "village_id": 59,
                "village_name": "दोगच्छी"
            },
            {
                "label": "दोमोहनी 0340",
                "village_id": 60,
                "village_name": "दोमोहनी"
            },
            {
                "label": "धरकचपुर 0346",
                "village_id": 61,
                "village_name": "धरकचपुर"
            },
            {
                "label": "धरहर 0222",
                "village_id": 62,
                "village_name": "धरहर"
            },
            {
                "label": "धोकऱपाट 0355",
                "village_id": 63,
                "village_name": "धोकऱपाट"
            },
            {
                "label": "नटुआपारा 0350",
                "village_id": 64,
                "village_name": "नटुआपारा"
            },
            {
                "label": "नटुआपारा 0381",
                "village_id": 65,
                "village_name": "नटुआपारा"
            },
            {
                "label": "निसदरा 0227",
                "village_id": 66,
                "village_name": "निसदरा"
            },
            {
                "label": "पलास मनी 0281",
                "village_id": 67,
                "village_name": "पलास मनी"
            },
            {
                "label": "पहटगाँव 0197",
                "village_id": 68,
                "village_name": "पहटगाँव"
            },
            {
                "label": "पिपरा गछ 0109",
                "village_id": 69,
                "village_name": "पिपरा गछ"
            },
            {
                "label": "फुलवाड़ी 0283",
                "village_id": 70,
                "village_name": "फुलवाड़ी"
            },
            {
                "label": "बेतबाड़ी 0371",
                "village_id": 71,
                "village_name": "बेतबाड़ी"
            },
            {
                "label": "बेनी 0360",
                "village_id": 72,
                "village_name": "बेनी"
            },
            {
                "label": "ब्रह्मोत्तर गछ 0108",
                "village_id": 73,
                "village_name": "ब्रह्मोत्तर गछ"
            },
            {
                "label": "बेलबाड़ी 0014",
                "village_id": 74,
                "village_name": "बेलबाड़ी"
            },
            {
                "label": "बैसा 0206",
                "village_id": 75,
                "village_name": "बैसा"
            },
            {
                "label": "बैसा गोपालगंज 0210",
                "village_id": 76,
                "village_name": "बैसा गोपालगंज"
            },
            {
                "label": "बैसाजुरैल 0286",
                "village_id": 77,
                "village_name": "बैसाजुरैल"
            },
            {
                "label": "बहादुऱगंज 0359",
                "village_id": 78,
                "village_name": "बहादुऱगंज"
            },
            {
                "label": "बाभनटोली 0184",
                "village_id": 79,
                "village_name": "बाभनटोली"
            },
            {
                "label": "बाराडांगा 0352",
                "village_id": 80,
                "village_name": "बाराडांगा"
            },
            {
                "label": "बांसबाड़ी 0285",
                "village_id": 81,
                "village_name": "बांसबाड़ी"
            },
            {
                "label": "बिरनियाँ 0288",
                "village_id": 82,
                "village_name": "बिरनियाँ"
            },
            {
                "label": "बिरनियाँ 0113",
                "village_id": 83,
                "village_name": "बिरनियाँ"
            },
            {
                "label": "बिशनपुर 0015",
                "village_id": 84,
                "village_name": "बिशनपुर"
            },
            {
                "label": "बीरपुर 0205",
                "village_id": 85,
                "village_name": "बीरपुर"
            },
            {
                "label": "भदेसर 0348",
                "village_id": 86,
                "village_name": "भदेसर"
            },
            {
                "label": "भाटाबाड़ी 0284",
                "village_id": 87,
                "village_name": "भाटाबाड़ी"
            },
            {
                "label": "भोपला 0347",
                "village_id": 88,
                "village_name": "भोपला"
            },
            {
                "label": "भौरादह 0290",
                "village_id": 89,
                "village_name": "भौरादह"
            },
            {
                "label": "मुक्ताराम देवरी 0178",
                "village_id": 90,
                "village_name": "मुक्ताराम देवरी"
            },
            {
                "label": "मुरमाला 0022",
                "village_id": 91,
                "village_name": "मुरमाला"
            },
            {
                "label": "मुसलडांगा 0221",
                "village_id": 92,
                "village_name": "मुसलडांगा"
            },
            {
                "label": "महम्मद नगर 0333",
                "village_id": 93,
                "village_name": "महम्मद नगर"
            },
            {
                "label": "महेश बथना 0199",
                "village_id": 94,
                "village_name": "महेश बथना"
            },
            {
                "label": "महादेव दिग्धी 0277",
                "village_id": 95,
                "village_name": "महादेव दिग्धी"
            },
            {
                "label": "मोहनसिंह गछ 0349",
                "village_id": 96,
                "village_name": "मोहनसिंह गछ"
            },
            {
                "label": "रूईधासा 0214",
                "village_id": 97,
                "village_name": "रूईधासा"
            },
            {
                "label": "रंगामनी 0012",
                "village_id": 98,
                "village_name": "रंगामनी"
            },
            {
                "label": "रूपनी 0217",
                "village_id": 99,
                "village_name": "रूपनी"
            },
            {
                "label": "रामपुर 0379",
                "village_id": 100,
                "village_name": "रामपुर"
            },
            {
                "label": "लहसुरा 0196",
                "village_id": 101,
                "village_name": "लहसुरा"
            },
            {
                "label": "लोहिया कान्दर 0013",
                "village_id": 102,
                "village_name": "लोहिया कान्दर"
            },
            {
                "label": "लोहिया कान्दर 0004",
                "village_id": 103,
                "village_name": "लोहिया कान्दर"
            },
            {
                "label": "लौचा 0200",
                "village_id": 104,
                "village_name": "लौचा"
            },
            {
                "label": "वनगामा 0293",
                "village_id": 105,
                "village_name": "वनगामा"
            },
            {
                "label": "वनगामा मिलिक 0292",
                "village_id": 106,
                "village_name": "वनगामा मिलिक"
            },
            {
                "label": "वोचागाड़ी 0213",
                "village_id": 107,
                "village_name": "वोचागाड़ी"
            },
            {
                "label": "सकौर 0380",
                "village_id": 108,
                "village_name": "सकौर"
            },
            {
                "label": "सुखानी 0011",
                "village_id": 109,
                "village_name": "सुखानी"
            },
            {
                "label": "सतभेरी 0212",
                "village_id": 110,
                "village_name": "सतभेरी"
            },
            {
                "label": "सताल 0361",
                "village_id": 111,
                "village_name": "सताल"
            },
            {
                "label": "सताल इसामरार 0364",
                "village_id": 112,
                "village_name": "सताल इसामरार"
            },
            {
                "label": "सताल निहाल 0363",
                "village_id": 113,
                "village_name": "सताल निहाल"
            },
            {
                "label": "समेसर 0112",
                "village_id": 114,
                "village_name": "समेसर"
            },
            {
                "label": "सरण्डा 0354",
                "village_id": 115,
                "village_name": "सरण्डा"
            },
            {
                "label": "सिकटीहार 0372",
                "village_id": 116,
                "village_name": "सिकटीहार"
            },
            {
                "label": "सिकटीहार 0377",
                "village_id": 117,
                "village_name": "सिकटीहार"
            },
            {
                "label": "सिकमी बनगाँव 0291",
                "village_id": 118,
                "village_name": "सिकमी बनगाँव"
            },
            {
                "label": "सिंधिया 0334",
                "village_id": 119,
                "village_name": "सिंधिया"
            },
            {
                "label": "सोनादिघी 0353",
                "village_id": 120,
                "village_name": "सोनादिघी"
            },
            {
                "label": "हरिभाषा 0219",
                "village_id": 121,
                "village_name": "हरिभाषा"
            },
            {
                "label": "हरिभाषा 0220",
                "village_id": 122,
                "village_name": "हरिभाषा"
            }
        ],
        "block_id": "2",
        "block_no": 1,
        "block_name": "बहादुरगंज [002]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अलीगंज 120/",
                "village_id": 2,
                "village_name": "अलीगंज"
            },
            {
                "label": "आठ गछिया 0325",
                "village_id": 3,
                "village_name": "आठ गछिया"
            },
            {
                "label": "ईकड़ा 0296",
                "village_id": 4,
                "village_name": "ईकड़ा"
            },
            {
                "label": "ईकड़ा मिलिक 0328",
                "village_id": 5,
                "village_name": "ईकड़ा मिलिक"
            },
            {
                "label": "ईकड़ा मिलिक 0329",
                "village_id": 6,
                "village_name": "ईकड़ा मिलिक"
            },
            {
                "label": "ईकड़ा मिलिक 0330",
                "village_id": 7,
                "village_name": "ईकड़ा मिलिक"
            },
            {
                "label": "ईचामारी 0324",
                "village_id": 8,
                "village_name": "ईचामारी"
            },
            {
                "label": "कच्चूनाला 0106",
                "village_id": 9,
                "village_name": "कच्चूनाला"
            },
            {
                "label": "कच्चूनाला मिलिक 0103",
                "village_id": 10,
                "village_name": "कच्चूनाला मिलिक"
            },
            {
                "label": "कच्चूमाला मिलिक 0104",
                "village_id": 11,
                "village_name": "कच्चूमाला मिलिक"
            },
            {
                "label": "कच्चूमाला मिलिक 0105",
                "village_id": 12,
                "village_name": "कच्चूमाला मिलिक"
            },
            {
                "label": "कंचन बाड़ी 0081",
                "village_id": 13,
                "village_name": "कंचन बाड़ी"
            },
            {
                "label": "कुढ़ैली 0195",
                "village_id": 14,
                "village_name": "कुढ़ैली"
            },
            {
                "label": "कमरखोद 0194",
                "village_id": 15,
                "village_name": "कमरखोद"
            },
            {
                "label": "कुम्हिया 0331",
                "village_id": 16,
                "village_name": "कुम्हिया"
            },
            {
                "label": "कमाती 0327",
                "village_id": 17,
                "village_name": "कमाती"
            },
            {
                "label": "करूवा मनी 0299",
                "village_id": 18,
                "village_name": "करूवा मनी"
            },
            {
                "label": "कामत 0314",
                "village_id": 19,
                "village_name": "कामत"
            },
            {
                "label": "कालपीर पथरधट्टी 0127",
                "village_id": 20,
                "village_name": "कालपीर पथरधट्टी"
            },
            {
                "label": "कास्त कर्म अली 0092",
                "village_id": 21,
                "village_name": "कास्त कर्म अली"
            },
            {
                "label": "कास्त तुलसिया 0115",
                "village_id": 22,
                "village_name": "कास्त तुलसिया"
            },
            {
                "label": "गरमन डांगा 0301",
                "village_id": 23,
                "village_name": "गरमन डांगा"
            },
            {
                "label": "गोसमारा 0128",
                "village_id": 24,
                "village_name": "गोसमारा"
            },
            {
                "label": "छोटा भीसमारा 0322",
                "village_id": 25,
                "village_name": "छोटा भीसमारा"
            },
            {
                "label": "जिया पोखर 0428",
                "village_id": 26,
                "village_name": "जिया पोखर"
            },
            {
                "label": "टप्पू 0427",
                "village_id": 27,
                "village_name": "टप्पू"
            },
            {
                "label": "टांधन टधू 0099",
                "village_id": 28,
                "village_name": "टांधन टधू"
            },
            {
                "label": "डोरिया 0315",
                "village_id": 29,
                "village_name": "डोरिया"
            },
            {
                "label": "तलवार बन्धा 0318",
                "village_id": 30,
                "village_name": "तलवार बन्धा"
            },
            {
                "label": "तुलसिया 0116",
                "village_id": 31,
                "village_name": "तुलसिया"
            },
            {
                "label": "तेली भीठा 0304",
                "village_id": 32,
                "village_name": "तेली भीठा"
            },
            {
                "label": "ताराबाड़ी जागीर 0326",
                "village_id": 33,
                "village_name": "ताराबाड़ी जागीर"
            },
            {
                "label": "दुबड़ी 0097",
                "village_id": 34,
                "village_name": "दुबड़ी"
            },
            {
                "label": "दुब़ड़ी 0096",
                "village_id": 35,
                "village_name": "दुब़ड़ी"
            },
            {
                "label": "दरगाह अलीगंज 121/",
                "village_id": 36,
                "village_name": "दरगाह अलीगंज"
            },
            {
                "label": "दरगाह कंचन बाड़ी 0083",
                "village_id": 37,
                "village_name": "दरगाह कंचन बाड़ी"
            },
            {
                "label": "दरगाह रसूलगंज 0114",
                "village_id": 38,
                "village_name": "दरगाह रसूलगंज"
            },
            {
                "label": "दहीभात 0101",
                "village_id": 39,
                "village_name": "दहीभात"
            },
            {
                "label": "दहीभात कला 0117",
                "village_id": 40,
                "village_name": "दहीभात कला"
            },
            {
                "label": "दहीभात खुर्द 0118",
                "village_id": 41,
                "village_name": "दहीभात खुर्द"
            },
            {
                "label": "दिघलबैंक 0306",
                "village_id": 42,
                "village_name": "दिघलबैंक"
            },
            {
                "label": "दिधल बांक 0308",
                "village_id": 43,
                "village_name": "दिधल बांक"
            },
            {
                "label": "दिधी बाड़ी 0320",
                "village_id": 44,
                "village_name": "दिधी बाड़ी"
            },
            {
                "label": "दो गिरजा 0093",
                "village_id": 45,
                "village_name": "दो गिरजा"
            },
            {
                "label": "धनगढ़ा 0122",
                "village_id": 46,
                "village_name": "धनगढ़ा"
            },
            {
                "label": "धनतोला 0312",
                "village_id": 47,
                "village_name": "धनतोला"
            },
            {
                "label": "पक्का मूड़ी 0100",
                "village_id": 48,
                "village_name": "पक्का मूड़ी"
            },
            {
                "label": "पथरधट्टी 0130",
                "village_id": 49,
                "village_name": "पथरधट्टी"
            },
            {
                "label": "पथरधट्टी 0131",
                "village_id": 50,
                "village_name": "पथरधट्टी"
            },
            {
                "label": "पदमपुर 0297",
                "village_id": 51,
                "village_name": "पदमपुर"
            },
            {
                "label": "पदमपुर जागीर 0298",
                "village_id": 52,
                "village_name": "पदमपुर जागीर"
            },
            {
                "label": "पलसा 0088",
                "village_id": 53,
                "village_name": "पलसा"
            },
            {
                "label": "पलसा मिलिक 0086",
                "village_id": 54,
                "village_name": "पलसा मिलिक"
            },
            {
                "label": "पाँचगाछी 0311",
                "village_id": 55,
                "village_name": "पाँचगाछी"
            },
            {
                "label": "बुआलदह 0098",
                "village_id": 56,
                "village_name": "बुआलदह"
            },
            {
                "label": "बैजनाथ पलसा 0087",
                "village_id": 57,
                "village_name": "बैजनाथ पलसा"
            },
            {
                "label": "बनबरिया 0129",
                "village_id": 58,
                "village_name": "बनबरिया"
            },
            {
                "label": "बांसबाड़ी 0319",
                "village_id": 59,
                "village_name": "बांसबाड़ी"
            },
            {
                "label": "भालटोली 0309",
                "village_id": 60,
                "village_name": "भालटोली"
            },
            {
                "label": "भालडांगी 0323",
                "village_id": 61,
                "village_name": "भालडांगी"
            },
            {
                "label": "भीसमारा 0321",
                "village_id": 62,
                "village_name": "भीसमारा"
            },
            {
                "label": "मंगुरा 0102",
                "village_id": 63,
                "village_name": "मंगुरा"
            },
            {
                "label": "मुरली बार मांग 0317",
                "village_id": 64,
                "village_name": "मुरली बार मांग"
            },
            {
                "label": "मुरली भीठा 0303",
                "village_id": 65,
                "village_name": "मुरली भीठा"
            },
            {
                "label": "मलमली 0085",
                "village_id": 66,
                "village_name": "मलमली"
            },
            {
                "label": "मुल्लाबाड़ी 0313",
                "village_id": 67,
                "village_name": "मुल्लाबाड़ी"
            },
            {
                "label": "मुस्तफागंज 0119",
                "village_id": 68,
                "village_name": "मुस्तफागंज"
            },
            {
                "label": "मालटोली 0305",
                "village_id": 69,
                "village_name": "मालटोली"
            },
            {
                "label": "मोहमारी 0310",
                "village_id": 70,
                "village_name": "मोहमारी"
            },
            {
                "label": "लक्ष्मीपुर 0123",
                "village_id": 71,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लोहा गाड़ा 0084",
                "village_id": 72,
                "village_name": "लोहा गाड़ा"
            },
            {
                "label": "लोहागाड़ा 0082",
                "village_id": 73,
                "village_name": "लोहागाड़ा"
            },
            {
                "label": "सतकौआ 0094",
                "village_id": 74,
                "village_name": "सतकौआ"
            },
            {
                "label": "सतमेढ़ी 0302",
                "village_id": 75,
                "village_name": "सतमेढ़ी"
            },
            {
                "label": "सूरी भीठा 0316",
                "village_id": 76,
                "village_name": "सूरी भीठा"
            },
            {
                "label": "सिंधीमारी 0089",
                "village_id": 77,
                "village_name": "सिंधीमारी"
            },
            {
                "label": "सिंधीमारी मिलिक 0090",
                "village_id": 78,
                "village_name": "सिंधीमारी मिलिक"
            },
            {
                "label": "हड़वा डांगा 0095",
                "village_id": 79,
                "village_name": "हड़वा डांगा"
            },
            {
                "label": "हुबली डांगी 0300",
                "village_id": 80,
                "village_name": "हुबली डांगी"
            },
            {
                "label": "हाड़ी भीठा 0307",
                "village_id": 81,
                "village_name": "हाड़ी भीठा"
            },
            {
                "label": "हाड़ी मीठा 0091",
                "village_id": 82,
                "village_name": "हाड़ी मीठा"
            }
        ],
        "block_id": "3",
        "block_no": 2,
        "block_name": "दिघलबैंक [003]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "आशा 0151",
                "village_id": 2,
                "village_name": "आशा"
            },
            {
                "label": "कंचनबाड़ी 0124",
                "village_id": 3,
                "village_name": "कंचनबाड़ी"
            },
            {
                "label": "कंचनबाड़ी इस्तमरार 0079",
                "village_id": 4,
                "village_name": "कंचनबाड़ी इस्तमरार"
            },
            {
                "label": "कदलेटा 0143",
                "village_id": 5,
                "village_name": "कदलेटा"
            },
            {
                "label": "कमाती ननकार 0147",
                "village_id": 6,
                "village_name": "कमाती ननकार"
            },
            {
                "label": "कुंवाड़ी 0161",
                "village_id": 7,
                "village_name": "कुंवाड़ी"
            },
            {
                "label": "कालपीर 0126",
                "village_id": 8,
                "village_name": "कालपीर"
            },
            {
                "label": "कास्तखरी 0165",
                "village_id": 9,
                "village_name": "कास्तखरी"
            },
            {
                "label": "खजूरबाड़ी 0160",
                "village_id": 10,
                "village_name": "खजूरबाड़ी"
            },
            {
                "label": "खुनियाटोली 0076",
                "village_id": 11,
                "village_name": "खुनियाटोली"
            },
            {
                "label": "खनियाबाद 0073",
                "village_id": 12,
                "village_name": "खनियाबाद"
            },
            {
                "label": "खरी 0138",
                "village_id": 13,
                "village_name": "खरी"
            },
            {
                "label": "खरी सुटिया 0166",
                "village_id": 14,
                "village_name": "खरी सुटिया"
            },
            {
                "label": "खारिज खनियाबाद 0070",
                "village_id": 15,
                "village_name": "खारिज खनियाबाद"
            },
            {
                "label": "गच्छ बेलवाड़ी 0167",
                "village_id": 16,
                "village_name": "गच्छ बेलवाड़ी"
            },
            {
                "label": "गच्छमहुआ 0180",
                "village_id": 17,
                "village_name": "गच्छमहुआ"
            },
            {
                "label": "गम्हरिया 0158",
                "village_id": 18,
                "village_name": "गम्हरिया"
            },
            {
                "label": "गरगाँव 0172",
                "village_id": 19,
                "village_name": "गरगाँव"
            },
            {
                "label": "गिलहनी 0171",
                "village_id": 20,
                "village_name": "गिलहनी"
            },
            {
                "label": "चऱघरिया 0156",
                "village_id": 21,
                "village_name": "चऱघरिया"
            },
            {
                "label": "चिचौड़ा 0078",
                "village_id": 22,
                "village_name": "चिचौड़ा"
            },
            {
                "label": "चिल्हनियां 0164",
                "village_id": 23,
                "village_name": "चिल्हनियां"
            },
            {
                "label": "झुनकी मुशहरा 0141",
                "village_id": 24,
                "village_name": "झुनकी मुशहरा"
            },
            {
                "label": "झाला 0155",
                "village_id": 25,
                "village_name": "झाला"
            },
            {
                "label": "टोकाभाषा 0072",
                "village_id": 26,
                "village_name": "टोकाभाषा"
            },
            {
                "label": "डाकपोखर 0191",
                "village_id": 27,
                "village_name": "डाकपोखर"
            },
            {
                "label": "डोरिया 0149",
                "village_id": 28,
                "village_name": "डोरिया"
            },
            {
                "label": "तेधरिया 0173",
                "village_id": 29,
                "village_name": "तेधरिया"
            },
            {
                "label": "देवरी 0174",
                "village_id": 30,
                "village_name": "देवरी"
            },
            {
                "label": "दहीभात 0068",
                "village_id": 31,
                "village_name": "दहीभात"
            },
            {
                "label": "धनीफुलसरा 0175",
                "village_id": 32,
                "village_name": "धनीफुलसरा"
            },
            {
                "label": "धबैली 0154",
                "village_id": 33,
                "village_name": "धबैली"
            },
            {
                "label": "धोकड़झाड़ी 63/2",
                "village_id": 34,
                "village_name": "धोकड़झाड़ी"
            },
            {
                "label": "नरनियां 0192",
                "village_id": 35,
                "village_name": "नरनियां"
            },
            {
                "label": "पांचगाछी 0139",
                "village_id": 36,
                "village_name": "पांचगाछी"
            },
            {
                "label": "पीपरा 0133",
                "village_id": 37,
                "village_name": "पीपरा"
            },
            {
                "label": "पीपरा 0067",
                "village_id": 38,
                "village_name": "पीपरा"
            },
            {
                "label": "पोखरिया 0150",
                "village_id": 39,
                "village_name": "पोखरिया"
            },
            {
                "label": "पोखरिया 0153",
                "village_id": 40,
                "village_name": "पोखरिया"
            },
            {
                "label": "फरहाबाड़ी ननकार 0142",
                "village_id": 41,
                "village_name": "फरहाबाड़ी ननकार"
            },
            {
                "label": "फरहाबाड़ी माल 0140",
                "village_id": 42,
                "village_name": "फरहाबाड़ी माल"
            },
            {
                "label": "फुलबाड़ी 0069",
                "village_id": 43,
                "village_name": "फुलबाड़ी"
            },
            {
                "label": "फुलबाड़ी 0188",
                "village_id": 44,
                "village_name": "फुलबाड़ी"
            },
            {
                "label": "फुलबारी 0071",
                "village_id": 45,
                "village_name": "फुलबारी"
            },
            {
                "label": "फुलबारी 0064",
                "village_id": 46,
                "village_name": "फुलबारी"
            },
            {
                "label": "फुलबारी 0066",
                "village_id": 47,
                "village_name": "फुलबारी"
            },
            {
                "label": "फाराबाड़ी मिलिक 120/",
                "village_id": 48,
                "village_name": "फाराबाड़ी मिलिक"
            },
            {
                "label": "बैगना 0215",
                "village_id": 49,
                "village_name": "बैगना"
            },
            {
                "label": "बेतबाड़ी 0163",
                "village_id": 50,
                "village_name": "बेतबाड़ी"
            },
            {
                "label": "बेनुगढ़ 0136",
                "village_id": 51,
                "village_name": "बेनुगढ़"
            },
            {
                "label": "बभनगामा 0162",
                "village_id": 52,
                "village_name": "बभनगामा"
            },
            {
                "label": "बैरिया 0077",
                "village_id": 53,
                "village_name": "बैरिया"
            },
            {
                "label": "बलुआडांगी 0193",
                "village_id": 54,
                "village_name": "बलुआडांगी"
            },
            {
                "label": "बेलबाड़ी खरी 0137",
                "village_id": 55,
                "village_name": "बेलबाड़ी खरी"
            },
            {
                "label": "बलुवा जागीर 0157",
                "village_id": 56,
                "village_name": "बलुवा जागीर"
            },
            {
                "label": "बासबाड़ी 0187",
                "village_id": 57,
                "village_name": "बासबाड़ी"
            },
            {
                "label": "भेरभेरी 0189",
                "village_id": 58,
                "village_name": "भेरभेरी"
            },
            {
                "label": "भेलागुड़ी 0132",
                "village_id": 59,
                "village_name": "भेलागुड़ी"
            },
            {
                "label": "भेलागुड़ी 0125",
                "village_id": 60,
                "village_name": "भेलागुड़ी"
            },
            {
                "label": "भाग कदलेटा 0144",
                "village_id": 61,
                "village_name": "भाग कदलेटा"
            },
            {
                "label": "भाग झुनकी 121/",
                "village_id": 62,
                "village_name": "भाग झुनकी"
            },
            {
                "label": "भोरहा 63/1",
                "village_id": 63,
                "village_name": "भोरहा"
            },
            {
                "label": "मटियारी 0134",
                "village_id": 64,
                "village_name": "मटियारी"
            },
            {
                "label": "महुआ 0179",
                "village_id": 65,
                "village_name": "महुआ"
            },
            {
                "label": "मियांपुर 0181",
                "village_id": 66,
                "village_name": "मियांपुर"
            },
            {
                "label": "मियांपुर 0182",
                "village_id": 67,
                "village_name": "मियांपुर"
            },
            {
                "label": "रहमतपुर 0169",
                "village_id": 68,
                "village_name": "रहमतपुर"
            },
            {
                "label": "रामपुर 0152",
                "village_id": 69,
                "village_name": "रामपुर"
            },
            {
                "label": "लोधाबाड़ी 0148",
                "village_id": 70,
                "village_name": "लोधाबाड़ी"
            },
            {
                "label": "शर्माटोली 65/1",
                "village_id": 71,
                "village_name": "शर्माटोली"
            },
            {
                "label": "शर्माटोली 0080",
                "village_id": 72,
                "village_name": "शर्माटोली"
            },
            {
                "label": "सुटिया 0170",
                "village_id": 73,
                "village_name": "सुटिया"
            },
            {
                "label": "सुटिया गोपालनगर 0168",
                "village_id": 74,
                "village_name": "सुटिया गोपालनगर"
            },
            {
                "label": "सरतीकमाती 0146",
                "village_id": 75,
                "village_name": "सरतीकमाती"
            },
            {
                "label": "सिरनियां 0135",
                "village_id": 76,
                "village_name": "सिरनियां"
            },
            {
                "label": "सिरनियां 0190",
                "village_id": 77,
                "village_name": "सिरनियां"
            },
            {
                "label": "हवाकोल 0145",
                "village_id": 78,
                "village_name": "हवाकोल"
            },
            {
                "label": "हवाकोल खुर्द 0159",
                "village_id": 79,
                "village_name": "हवाकोल खुर्द"
            },
            {
                "label": "हाटगाँव 65/2",
                "village_id": 80,
                "village_name": "हाटगाँव"
            },
            {
                "label": "हाटगाँव 0074",
                "village_id": 81,
                "village_name": "हाटगाँव"
            },
            {
                "label": "हाटगाँव 0075",
                "village_id": 82,
                "village_name": "हाटगाँव"
            }
        ],
        "block_id": "4",
        "block_no": 3,
        "block_name": "टेढ़ागाछ [004]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अन्धासूर 0250",
                "village_id": 2,
                "village_name": "अन्धासूर"
            },
            {
                "label": "अनाराकली 0391",
                "village_id": 3,
                "village_name": "अनाराकली"
            },
            {
                "label": "अरुगाँव 0269",
                "village_id": 4,
                "village_name": "अरुगाँव"
            },
            {
                "label": "अलतावाड़ी खुर्द 09/2",
                "village_id": 5,
                "village_name": "अलतावाड़ी खुर्द"
            },
            {
                "label": "असुरा 0224",
                "village_id": 6,
                "village_name": "असुरा"
            },
            {
                "label": "कुआरी 0207",
                "village_id": 7,
                "village_name": "कुआरी"
            },
            {
                "label": "कजरमामनी 0391",
                "village_id": 8,
                "village_name": "कजरमामनी"
            },
            {
                "label": "कट्टा मट्टा 0266",
                "village_id": 9,
                "village_name": "कट्टा मट्टा"
            },
            {
                "label": "कुट्टी 0426",
                "village_id": 10,
                "village_name": "कुट्टी"
            },
            {
                "label": "कटलवाड़ी 0228",
                "village_id": 11,
                "village_name": "कटलवाड़ी"
            },
            {
                "label": "कढ़ाईवाड़ी 0036",
                "village_id": 12,
                "village_name": "कढ़ाईवाड़ी"
            },
            {
                "label": "कन्हैया वाड़ी 0208",
                "village_id": 13,
                "village_name": "कन्हैया वाड़ी"
            },
            {
                "label": "कन्हैयावाड़ी 0385",
                "village_id": 14,
                "village_name": "कन्हैयावाड़ी"
            },
            {
                "label": "कमलपुर 0053",
                "village_id": 15,
                "village_name": "कमलपुर"
            },
            {
                "label": "कैरी वीरपुर 0278",
                "village_id": 16,
                "village_name": "कैरी वीरपुर"
            },
            {
                "label": "कलकली 0039",
                "village_id": 17,
                "village_name": "कलकली"
            },
            {
                "label": "कवौया 0247",
                "village_id": 18,
                "village_name": "कवौया"
            },
            {
                "label": "कुशपाड़ा 0242",
                "village_id": 19,
                "village_name": "कुशपाड़ा"
            },
            {
                "label": "कालानागिन 0418",
                "village_id": 20,
                "village_name": "कालानागिन"
            },
            {
                "label": "काशिवाड़ी 0044",
                "village_id": 21,
                "village_name": "काशिवाड़ी"
            },
            {
                "label": "कोइतोर 0399",
                "village_id": 22,
                "village_name": "कोइतोर"
            },
            {
                "label": "कोयाधामन 0397",
                "village_id": 23,
                "village_name": "कोयाधामन"
            },
            {
                "label": "कोल्हा 0235",
                "village_id": 24,
                "village_name": "कोल्हा"
            },
            {
                "label": "गरगाँव 0396",
                "village_id": 25,
                "village_name": "गरगाँव"
            },
            {
                "label": "गांधीखुर्द 0017",
                "village_id": 26,
                "village_name": "गांधीखुर्द"
            },
            {
                "label": "गौरामनी 0019",
                "village_id": 27,
                "village_name": "गौरामनी"
            },
            {
                "label": "घुरना 0027",
                "village_id": 28,
                "village_name": "घुरना"
            },
            {
                "label": "चुड़ाकुही 0029",
                "village_id": 29,
                "village_name": "चुड़ाकुही"
            },
            {
                "label": "चुनामारी 0405",
                "village_id": 30,
                "village_name": "चुनामारी"
            },
            {
                "label": "चपड़ा वखाड़ी 0232",
                "village_id": 31,
                "village_name": "चपड़ा वखाड़ी"
            },
            {
                "label": "चरैया 0060",
                "village_id": 32,
                "village_name": "चरैया"
            },
            {
                "label": "जधौल 0251",
                "village_id": 33,
                "village_name": "जधौल"
            },
            {
                "label": "जिवनपुर 0239",
                "village_id": 34,
                "village_name": "जिवनपुर"
            },
            {
                "label": "झांगी दिघी 0374",
                "village_id": 35,
                "village_name": "झांगी दिघी"
            },
            {
                "label": "झांटीवाड़ी अनारकली 0395",
                "village_id": 36,
                "village_name": "झांटीवाड़ी अनारकली"
            },
            {
                "label": "टेडीभाषा 0398",
                "village_id": 37,
                "village_name": "टेडीभाषा"
            },
            {
                "label": "टुपामारी 0390",
                "village_id": 38,
                "village_name": "टुपामारी"
            },
            {
                "label": "टुपामारी 0236",
                "village_id": 39,
                "village_name": "टुपामारी"
            },
            {
                "label": "टीटीहा 0234",
                "village_id": 40,
                "village_name": "टीटीहा"
            },
            {
                "label": "ठुट्ठी पाखर 0026",
                "village_id": 41,
                "village_name": "ठुट्ठी पाखर"
            },
            {
                "label": "डेरामारी 0035",
                "village_id": 42,
                "village_name": "डेरामारी"
            },
            {
                "label": "डलिया 0414",
                "village_id": 43,
                "village_name": "डलिया"
            },
            {
                "label": "डहुआवाड़ी 0231",
                "village_id": 44,
                "village_name": "डहुआवाड़ी"
            },
            {
                "label": "डोउआ 0054",
                "village_id": 45,
                "village_name": "डोउआ"
            },
            {
                "label": "डोगिया 0209",
                "village_id": 46,
                "village_name": "डोगिया"
            },
            {
                "label": "तेघरीया 0382",
                "village_id": 47,
                "village_name": "तेघरीया"
            },
            {
                "label": "तेतलिया 0401",
                "village_id": 48,
                "village_name": "तेतलिया"
            },
            {
                "label": "थुर्रा 0415",
                "village_id": 49,
                "village_name": "थुर्रा"
            },
            {
                "label": "दूर्गापुर 0376",
                "village_id": 50,
                "village_name": "दूर्गापुर"
            },
            {
                "label": "दुवरा 0422",
                "village_id": 51,
                "village_name": "दुवरा"
            },
            {
                "label": "दाँती 0272",
                "village_id": 52,
                "village_name": "दाँती"
            },
            {
                "label": "दो पोखरिया 0061",
                "village_id": 53,
                "village_name": "दो पोखरिया"
            },
            {
                "label": "दोघरिया 0253",
                "village_id": 54,
                "village_name": "दोघरिया"
            },
            {
                "label": "धनपूरा 0033",
                "village_id": 55,
                "village_name": "धनपूरा"
            },
            {
                "label": "धनपूरा खाड़ी 0032",
                "village_id": 56,
                "village_name": "धनपूरा खाड़ी"
            },
            {
                "label": "धनसोना 0420",
                "village_id": 57,
                "village_name": "धनसोना"
            },
            {
                "label": "धानसोना 0062",
                "village_id": 58,
                "village_name": "धानसोना"
            },
            {
                "label": "नजरपुर 0394",
                "village_id": 59,
                "village_name": "नजरपुर"
            },
            {
                "label": "नडुआपाड़ा 0265",
                "village_id": 60,
                "village_name": "नडुआपाड़ा"
            },
            {
                "label": "निहाल़भाग 0276",
                "village_id": 61,
                "village_name": "निहाल़भाग"
            },
            {
                "label": "नौरंगा 0423",
                "village_id": 62,
                "village_name": "नौरंगा"
            },
            {
                "label": "पचहरा 0252",
                "village_id": 63,
                "village_name": "पचहरा"
            },
            {
                "label": "पड़वा 0261",
                "village_id": 64,
                "village_name": "पड़वा"
            },
            {
                "label": "पुरन्दाहा 0255",
                "village_id": 65,
                "village_name": "पुरन्दाहा"
            },
            {
                "label": "परिहालपूर मिलिक 0041",
                "village_id": 66,
                "village_name": "परिहालपूर मिलिक"
            },
            {
                "label": "परीहालपूर 0031",
                "village_id": 67,
                "village_name": "परीहालपूर"
            },
            {
                "label": "पाटकोइ मिलिक 0025",
                "village_id": 68,
                "village_name": "पाटकोइ मिलिक"
            },
            {
                "label": "पाटकोइकला 0021",
                "village_id": 69,
                "village_name": "पाटकोइकला"
            },
            {
                "label": "पाटकोई खुर्द 0024",
                "village_id": 70,
                "village_name": "पाटकोई खुर्द"
            },
            {
                "label": "पिपला 0417",
                "village_id": 71,
                "village_name": "पिपला"
            },
            {
                "label": "पीपरा 0393",
                "village_id": 72,
                "village_name": "पीपरा"
            },
            {
                "label": "पोखड़िया 0240",
                "village_id": 73,
                "village_name": "पोखड़िया"
            },
            {
                "label": "फुलवाड़ी 0419",
                "village_id": 74,
                "village_name": "फुलवाड़ी"
            },
            {
                "label": "बलिया 0248",
                "village_id": 75,
                "village_name": "बलिया"
            },
            {
                "label": "बस्ता कोला 0057",
                "village_id": 76,
                "village_name": "बस्ता कोला"
            },
            {
                "label": "भगाल 0411",
                "village_id": 77,
                "village_name": "भगाल"
            },
            {
                "label": "भजकुरी 0241",
                "village_id": 78,
                "village_name": "भजकुरी"
            },
            {
                "label": "भुजावारी 0051",
                "village_id": 79,
                "village_name": "भुजावारी"
            },
            {
                "label": "भवानी गंज 0421",
                "village_id": 80,
                "village_name": "भवानी गंज"
            },
            {
                "label": "भागपुनास 0274",
                "village_id": 81,
                "village_name": "भागपुनास"
            },
            {
                "label": "भागवैसा 0229",
                "village_id": 82,
                "village_name": "भागवैसा"
            },
            {
                "label": "भोधो 0400",
                "village_id": 83,
                "village_name": "भोधो"
            },
            {
                "label": "भौरा 0271",
                "village_id": 84,
                "village_name": "भौरा"
            },
            {
                "label": "मकराहा 0258",
                "village_id": 85,
                "village_name": "मकराहा"
            },
            {
                "label": "मकराहा 0259",
                "village_id": 86,
                "village_name": "मकराहा"
            },
            {
                "label": "मगरी 0262",
                "village_id": 87,
                "village_name": "मगरी"
            },
            {
                "label": "मेमड़ा 0403",
                "village_id": 88,
                "village_name": "मेमड़ा"
            },
            {
                "label": "मेमाल 0267",
                "village_id": 89,
                "village_name": "मेमाल"
            },
            {
                "label": "मलगोभा 0387",
                "village_id": 90,
                "village_name": "मलगोभा"
            },
            {
                "label": "मशनगाँव 0268",
                "village_id": 91,
                "village_name": "मशनगाँव"
            },
            {
                "label": "मस्जिदगढ़ दरगाह 0042",
                "village_id": 92,
                "village_name": "मस्जिदगढ़ दरगाह"
            },
            {
                "label": "मस्तलीया 0424",
                "village_id": 93,
                "village_name": "मस्तलीया"
            },
            {
                "label": "मस्दिगढ़ पोखर कोना 0045",
                "village_id": 94,
                "village_name": "मस्दिगढ़ पोखर कोना"
            },
            {
                "label": "मस्‍दिगढ़ मिलिक 0043",
                "village_id": 95,
                "village_name": "मस्‍दिगढ़ मिलिक"
            },
            {
                "label": "महुआ 0408",
                "village_id": 96,
                "village_name": "महुआ"
            },
            {
                "label": "मेहदीपुर 0243",
                "village_id": 97,
                "village_name": "मेहदीपुर"
            },
            {
                "label": "महियापूर 0383",
                "village_id": 98,
                "village_name": "महियापूर"
            },
            {
                "label": "मालिनगाँव 0256",
                "village_id": 99,
                "village_name": "मालिनगाँव"
            },
            {
                "label": "मोहम्द महीउद्दीनपुर 0046",
                "village_id": 100,
                "village_name": "मोहम्द महीउद्दीनपुर"
            },
            {
                "label": "मोहरमारी 0050",
                "village_id": 101,
                "village_name": "मोहरमारी"
            },
            {
                "label": "मोहरमारी खुर्द 0034",
                "village_id": 102,
                "village_name": "मोहरमारी खुर्द"
            },
            {
                "label": "रंगामनी 0384",
                "village_id": 103,
                "village_name": "रंगामनी"
            },
            {
                "label": "रसुलगंज 0402",
                "village_id": 104,
                "village_name": "रसुलगंज"
            },
            {
                "label": "रुहीया 0388",
                "village_id": 105,
                "village_name": "रुहीया"
            },
            {
                "label": "रानी 0270",
                "village_id": 106,
                "village_name": "रानी"
            },
            {
                "label": "रोहनियाँ 0264",
                "village_id": 107,
                "village_name": "रोहनियाँ"
            },
            {
                "label": "रोहनिया मिल्लीक 0257",
                "village_id": 108,
                "village_name": "रोहनिया मिल्लीक"
            },
            {
                "label": "ललतोर 0223",
                "village_id": 109,
                "village_name": "ललतोर"
            },
            {
                "label": "लोघना 0037",
                "village_id": 110,
                "village_name": "लोघना"
            },
            {
                "label": "लोघना खारजी 0038",
                "village_id": 111,
                "village_name": "लोघना खारजी"
            },
            {
                "label": "वुआलदह 0389",
                "village_id": 112,
                "village_name": "वुआलदह"
            },
            {
                "label": "वगलवाड़ी 0048",
                "village_id": 113,
                "village_name": "वगलवाड़ी"
            },
            {
                "label": "वड़ीजान दूर्गापुर 0375",
                "village_id": 114,
                "village_name": "वड़ीजान दूर्गापुर"
            },
            {
                "label": "वड़ीजान पोठी मारीजाली 0378",
                "village_id": 115,
                "village_name": "वड़ीजान पोठी मारीजाली"
            },
            {
                "label": "वूढ़ीमारी 0047",
                "village_id": 116,
                "village_name": "वूढ़ीमारी"
            },
            {
                "label": "वरवट्टा 0263",
                "village_id": 117,
                "village_name": "वरवट्टा"
            },
            {
                "label": "वस्ताकोला 0049",
                "village_id": 118,
                "village_name": "वस्ताकोला"
            },
            {
                "label": "वहिकोल 0260",
                "village_id": 119,
                "village_name": "वहिकोल"
            },
            {
                "label": "वहीयाकुही 0030",
                "village_id": 120,
                "village_name": "वहीयाकुही"
            },
            {
                "label": "वाभनगाँव 0052",
                "village_id": 121,
                "village_name": "वाभनगाँव"
            },
            {
                "label": "वारह भसिया 0059",
                "village_id": 122,
                "village_name": "वारह भसिया"
            },
            {
                "label": "वालूवाडी 0058",
                "village_id": 123,
                "village_name": "वालूवाडी"
            },
            {
                "label": "विरवा कलकली 0040",
                "village_id": 124,
                "village_name": "विरवा कलकली"
            },
            {
                "label": "विशनपुर 0230",
                "village_id": 125,
                "village_name": "विशनपुर"
            },
            {
                "label": "विशनपुर 0244",
                "village_id": 126,
                "village_name": "विशनपुर"
            },
            {
                "label": "वीरुआ चुड़ाकुही 0028",
                "village_id": 127,
                "village_name": "वीरुआ चुड़ाकुही"
            },
            {
                "label": "वोहिता 0273",
                "village_id": 128,
                "village_name": "वोहिता"
            },
            {
                "label": "शुकुलरानी 0406",
                "village_id": 129,
                "village_name": "शुकुलरानी"
            },
            {
                "label": "शनमारा 0410",
                "village_id": 130,
                "village_name": "शनमारा"
            },
            {
                "label": "सतभीठा 0386",
                "village_id": 131,
                "village_name": "सतभीठा"
            },
            {
                "label": "सुन्दर पुछी 0425",
                "village_id": 132,
                "village_name": "सुन्दर पुछी"
            },
            {
                "label": "सुन्दरवाड़ी 0233",
                "village_id": 133,
                "village_name": "सुन्दरवाड़ी"
            },
            {
                "label": "सपृया 0246",
                "village_id": 134,
                "village_name": "सपृया"
            },
            {
                "label": "सुरंग 0249",
                "village_id": 135,
                "village_name": "सुरंग"
            },
            {
                "label": "सराय 0377",
                "village_id": 136,
                "village_name": "सराय"
            },
            {
                "label": "साहपुर 0412",
                "village_id": 137,
                "village_name": "साहपुर"
            },
            {
                "label": "साहपुर इस्तम्वरार 0413",
                "village_id": 138,
                "village_name": "साहपुर इस्तम्वरार"
            },
            {
                "label": "सिंघारी 0238",
                "village_id": 139,
                "village_name": "सिंघारी"
            },
            {
                "label": "सिधीया खाड़ी 0055",
                "village_id": 140,
                "village_name": "सिधीया खाड़ी"
            },
            {
                "label": "सिधीया चकन्दरा 0056",
                "village_id": 141,
                "village_name": "सिधीया चकन्दरा"
            },
            {
                "label": "सिरार 0254",
                "village_id": 142,
                "village_name": "सिरार"
            },
            {
                "label": "सोथा 0275",
                "village_id": 143,
                "village_name": "सोथा"
            },
            {
                "label": "सोहन गाँव 0245",
                "village_id": 144,
                "village_name": "सोहन गाँव"
            },
            {
                "label": "हरुआडांगा 0018",
                "village_id": 145,
                "village_name": "हरुआडांगा"
            },
            {
                "label": "हरखुगड़िया 0404",
                "village_id": 146,
                "village_name": "हरखुगड़िया"
            },
            {
                "label": "हल्दीखोड़ा 0237",
                "village_id": 147,
                "village_name": "हल्दीखोड़ा"
            },
            {
                "label": "हसन डुमरिय 0407",
                "village_id": 148,
                "village_name": "हसन डुमरिय"
            },
            {
                "label": "हाटगाछी 0409",
                "village_id": 149,
                "village_name": "हाटगाछी"
            },
            {
                "label": "हाटगाछी 0020",
                "village_id": 150,
                "village_name": "हाटगाछी"
            },
            {
                "label": "हिम्मत नगर 0416",
                "village_id": 151,
                "village_name": "हिम्मत नगर"
            }
        ],
        "block_id": "5",
        "block_no": 4,
        "block_name": "कोचाधामन [005]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अंडावाड़ी 0034",
                "village_id": 2,
                "village_name": "अंडावाड़ी"
            },
            {
                "label": "अधिकारी 0266",
                "village_id": 3,
                "village_name": "अधिकारी"
            },
            {
                "label": "अर्रा बाड़ी 0176",
                "village_id": 4,
                "village_name": "अर्रा बाड़ी"
            },
            {
                "label": "अराजी गोगमती 0126",
                "village_id": 5,
                "village_name": "अराजी गोगमती"
            },
            {
                "label": "इन्दरपुर 0180",
                "village_id": 6,
                "village_name": "इन्दरपुर"
            },
            {
                "label": "इन्दरपुर खुर्द 0179",
                "village_id": 7,
                "village_name": "इन्दरपुर खुर्द"
            },
            {
                "label": "उदगड़ा 0142",
                "village_id": 8,
                "village_name": "उदगड़ा"
            },
            {
                "label": "कुल्थी बाड़ी 0254",
                "village_id": 9,
                "village_name": "कुल्थी बाड़ी"
            },
            {
                "label": "कलियागंज 0087",
                "village_id": 10,
                "village_name": "कलियागंज"
            },
            {
                "label": "केशोझाड़ा 0250",
                "village_id": 11,
                "village_name": "केशोझाड़ा"
            },
            {
                "label": "कसवा कलियागंज 0087",
                "village_id": 12,
                "village_name": "कसवा कलियागंज"
            },
            {
                "label": "कुसियारी 0124",
                "village_id": 13,
                "village_name": "कुसियारी"
            },
            {
                "label": "कारीगाँव 0133",
                "village_id": 14,
                "village_name": "कारीगाँव"
            },
            {
                "label": "कालूपाठा 0112",
                "village_id": 15,
                "village_name": "कालूपाठा"
            },
            {
                "label": "काला कच्चू 0270",
                "village_id": 16,
                "village_name": "काला कच्चू"
            },
            {
                "label": "काला सिधिया 0257",
                "village_id": 17,
                "village_name": "काला सिधिया"
            },
            {
                "label": "कालीदास 0097",
                "village_id": 18,
                "village_name": "कालीदास"
            },
            {
                "label": "कालीदास एराजी 0099",
                "village_id": 19,
                "village_name": "कालीदास एराजी"
            },
            {
                "label": "कालीदास किस्मत 0100",
                "village_id": 20,
                "village_name": "कालीदास किस्मत"
            },
            {
                "label": "किसमत 0000",
                "village_id": 21,
                "village_name": "किसमत"
            },
            {
                "label": "कोईमारी 0111",
                "village_id": 22,
                "village_name": "कोईमारी"
            },
            {
                "label": "कोल्था 0162",
                "village_id": 23,
                "village_name": "कोल्था"
            },
            {
                "label": "खरखरी 0172",
                "village_id": 24,
                "village_name": "खरखरी"
            },
            {
                "label": "खरिमा पिघला 0144",
                "village_id": 25,
                "village_name": "खरिमा पिघला"
            },
            {
                "label": "गेरामाड़ी 0177",
                "village_id": 26,
                "village_name": "गेरामाड़ी"
            },
            {
                "label": "गलगलिया 0153",
                "village_id": 27,
                "village_name": "गलगलिया"
            },
            {
                "label": "गिल्लावाड़ी 0037",
                "village_id": 28,
                "village_name": "गिल्लावाड़ी"
            },
            {
                "label": "गोगनाती 0128",
                "village_id": 29,
                "village_name": "गोगनाती"
            },
            {
                "label": "गोरुखाल 0096",
                "village_id": 30,
                "village_name": "गोरुखाल"
            },
            {
                "label": "गोवावाड़ी 0148",
                "village_id": 31,
                "village_name": "गोवावाड़ी"
            },
            {
                "label": "गोविन्दा 0272",
                "village_id": 32,
                "village_name": "गोविन्दा"
            },
            {
                "label": "चरखाकाटी 0137",
                "village_id": 33,
                "village_name": "चरखाकाटी"
            },
            {
                "label": "चिचुआवाड़ी 0086",
                "village_id": 34,
                "village_name": "चिचुआवाड़ी"
            },
            {
                "label": "चितमा 0041",
                "village_id": 35,
                "village_name": "चितमा"
            },
            {
                "label": "चितमा 0043",
                "village_id": 36,
                "village_name": "चितमा"
            },
            {
                "label": "चिल्लामारी 0120",
                "village_id": 37,
                "village_name": "चिल्लामारी"
            },
            {
                "label": "चोरोगाछी 0157",
                "village_id": 38,
                "village_name": "चोरोगाछी"
            },
            {
                "label": "छगलीया 0253",
                "village_id": 39,
                "village_name": "छगलीया"
            },
            {
                "label": "छत्तरगाछ 0165",
                "village_id": 40,
                "village_name": "छत्तरगाछ"
            },
            {
                "label": "छमटिया 0154",
                "village_id": 41,
                "village_name": "छमटिया"
            },
            {
                "label": "जगीया बाड़ी 0279",
                "village_id": 42,
                "village_name": "जगीया बाड़ी"
            },
            {
                "label": "जलालपुर 0088",
                "village_id": 43,
                "village_name": "जलालपुर"
            },
            {
                "label": "जलालपुर 0260",
                "village_id": 44,
                "village_name": "जलालपुर"
            },
            {
                "label": "जहाँगीर पुर 0263",
                "village_id": 45,
                "village_name": "जहाँगीर पुर"
            },
            {
                "label": "जागीरगछ 0098",
                "village_id": 46,
                "village_name": "जागीरगछ"
            },
            {
                "label": "जोगागढ़ 0108",
                "village_id": 47,
                "village_name": "जोगागढ़"
            },
            {
                "label": "जोगीहरा 0102",
                "village_id": 48,
                "village_name": "जोगीहरा"
            },
            {
                "label": "झखवाडांगी 0161",
                "village_id": 49,
                "village_name": "झखवाडांगी"
            },
            {
                "label": "टोपीझाड़ी 0134",
                "village_id": 50,
                "village_name": "टोपीझाड़ी"
            },
            {
                "label": "डूबा नोभी 0030",
                "village_id": 51,
                "village_name": "डूबा नोभी"
            },
            {
                "label": "डूबानोची 0035",
                "village_id": 52,
                "village_name": "डूबानोची"
            },
            {
                "label": "डोगरा 0139",
                "village_id": 53,
                "village_name": "डोगरा"
            },
            {
                "label": "ढ़ेकिपाड़ा 0278",
                "village_id": 54,
                "village_name": "ढ़ेकिपाड़ा"
            },
            {
                "label": "तैयबपुर 0085",
                "village_id": 55,
                "village_name": "तैयबपुर"
            },
            {
                "label": "तरीया 0261",
                "village_id": 56,
                "village_name": "तरीया"
            },
            {
                "label": "तीरणी 0033",
                "village_id": 57,
                "village_name": "तीरणी"
            },
            {
                "label": "थैकलवाड़ी 0032",
                "village_id": 58,
                "village_name": "थैकलवाड़ी"
            },
            {
                "label": "दरी गाँव 0070",
                "village_id": 59,
                "village_name": "दरी गाँव"
            },
            {
                "label": "देवी गंज 0074",
                "village_id": 60,
                "village_name": "देवी गंज"
            },
            {
                "label": "दामल बाड़ी 0259",
                "village_id": 61,
                "village_name": "दामल बाड़ी"
            },
            {
                "label": "दामल वाड़ी 0255",
                "village_id": 62,
                "village_name": "दामल वाड़ी"
            },
            {
                "label": "धनतोला 0140",
                "village_id": 63,
                "village_name": "धनतोला"
            },
            {
                "label": "धागीपोखर 0125",
                "village_id": 64,
                "village_name": "धागीपोखर"
            },
            {
                "label": "धियागाँव 0145",
                "village_id": 65,
                "village_name": "धियागाँव"
            },
            {
                "label": "धोबनिया 0251",
                "village_id": 66,
                "village_name": "धोबनिया"
            },
            {
                "label": "नन्हाकुड़ी 0166",
                "village_id": 67,
                "village_name": "नन्हाकुड़ी"
            },
            {
                "label": "नवनदी 0159",
                "village_id": 68,
                "village_name": "नवनदी"
            },
            {
                "label": "नवनदी 0160",
                "village_id": 69,
                "village_name": "नवनदी"
            },
            {
                "label": "नारी वासर 0271",
                "village_id": 70,
                "village_name": "नारी वासर"
            },
            {
                "label": "निमलागाँव 0110",
                "village_id": 71,
                "village_name": "निमलागाँव"
            },
            {
                "label": "नौकहा 0121",
                "village_id": 72,
                "village_name": "नौकहा"
            },
            {
                "label": "पक्का मोलना 0114",
                "village_id": 73,
                "village_name": "पक्का मोलना"
            },
            {
                "label": "पतीला भाषा 0117",
                "village_id": 74,
                "village_name": "पतीला भाषा"
            },
            {
                "label": "पनासी 0275",
                "village_id": 75,
                "village_name": "पनासी"
            },
            {
                "label": "पनासी 0131",
                "village_id": 76,
                "village_name": "पनासी"
            },
            {
                "label": "पनासी 0132",
                "village_id": 77,
                "village_name": "पनासी"
            },
            {
                "label": "पुरनदाहा 0044",
                "village_id": 78,
                "village_name": "पुरनदाहा"
            },
            {
                "label": "परला बाड़ी 0256",
                "village_id": 79,
                "village_name": "परला बाड़ी"
            },
            {
                "label": "परला बाड़ी 0258",
                "village_id": 80,
                "village_name": "परला बाड़ी"
            },
            {
                "label": "पहाड़कट्टा 0274",
                "village_id": 81,
                "village_name": "पहाड़कट्टा"
            },
            {
                "label": "पाका मोलना 0093",
                "village_id": 82,
                "village_name": "पाका मोलना"
            },
            {
                "label": "पाका मोलना 0094",
                "village_id": 83,
                "village_name": "पाका मोलना"
            },
            {
                "label": "पानवारा 0107",
                "village_id": 84,
                "village_name": "पानवारा"
            },
            {
                "label": "पानीघटा 0036",
                "village_id": 85,
                "village_name": "पानीघटा"
            },
            {
                "label": "पामल 0127",
                "village_id": 86,
                "village_name": "पामल"
            },
            {
                "label": "पारी पौखर 0136",
                "village_id": 87,
                "village_name": "पारी पौखर"
            },
            {
                "label": "पियाकुड़ी 0147",
                "village_id": 88,
                "village_name": "पियाकुड़ी"
            },
            {
                "label": "पोखरिया 0122",
                "village_id": 89,
                "village_name": "पोखरिया"
            },
            {
                "label": "पोठिया 0118",
                "village_id": 90,
                "village_name": "पोठिया"
            },
            {
                "label": "पौवार काली 0119",
                "village_id": 91,
                "village_name": "पौवार काली"
            },
            {
                "label": "फर्रा बाड़ी 0169",
                "village_id": 92,
                "village_name": "फर्रा बाड़ी"
            },
            {
                "label": "फूलहरा 0106",
                "village_id": 93,
                "village_name": "फूलहरा"
            },
            {
                "label": "फुलहरा मिलिक 0101",
                "village_id": 94,
                "village_name": "फुलहरा मिलिक"
            },
            {
                "label": "फुलहरागढ़ 0105",
                "village_id": 95,
                "village_name": "फुलहरागढ़"
            },
            {
                "label": "फाला 0042",
                "village_id": 96,
                "village_name": "फाला"
            },
            {
                "label": "बक्सा 0156",
                "village_id": 97,
                "village_name": "बक्सा"
            },
            {
                "label": "बगल बाड़ी 0280",
                "village_id": 98,
                "village_name": "बगल बाड़ी"
            },
            {
                "label": "बगल बाड़ी 0281",
                "village_id": 99,
                "village_name": "बगल बाड़ी"
            },
            {
                "label": "बगलबाड़ी मि0 0262",
                "village_id": 100,
                "village_name": "बगलबाड़ी मि0"
            },
            {
                "label": "बुढ़नई 0079",
                "village_id": 101,
                "village_name": "बुढ़नई"
            },
            {
                "label": "बुधरा 0146",
                "village_id": 102,
                "village_name": "बुधरा"
            },
            {
                "label": "बाखोनाला 0155",
                "village_id": 103,
                "village_name": "बाखोनाला"
            },
            {
                "label": "बारहकोनिया 0149",
                "village_id": 104,
                "village_name": "बारहकोनिया"
            },
            {
                "label": "बीरपुर 0091",
                "village_id": 105,
                "village_name": "बीरपुर"
            },
            {
                "label": "भतकुन्डा 0129",
                "village_id": 106,
                "village_name": "भतकुन्डा"
            },
            {
                "label": "भतकुन्डा 0130",
                "village_id": 107,
                "village_name": "भतकुन्डा"
            },
            {
                "label": "भांगुरजान 0135",
                "village_id": 108,
                "village_name": "भांगुरजान"
            },
            {
                "label": "भोटाथाना 0171",
                "village_id": 109,
                "village_name": "भोटाथाना"
            },
            {
                "label": "भोलगाछी 0040",
                "village_id": 110,
                "village_name": "भोलगाछी"
            },
            {
                "label": "मरिया 0164",
                "village_id": 111,
                "village_name": "मरिया"
            },
            {
                "label": "मुसलडांगा 0092",
                "village_id": 112,
                "village_name": "मुसलडांगा"
            },
            {
                "label": "महसूल 0163",
                "village_id": 113,
                "village_name": "महसूल"
            },
            {
                "label": "माखन पोखर 0089",
                "village_id": 114,
                "village_name": "माखन पोखर"
            },
            {
                "label": "माखन पौखर 0089",
                "village_id": 115,
                "village_name": "माखन पौखर"
            },
            {
                "label": "मांगुऱजान 0095",
                "village_id": 116,
                "village_name": "मांगुऱजान"
            },
            {
                "label": "मामुभगिना 0069",
                "village_id": 117,
                "village_name": "मामुभगिना"
            },
            {
                "label": "मिर्जापुर 0113",
                "village_id": 118,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "मीरा मनी 0178",
                "village_id": 119,
                "village_name": "मीरा मनी"
            },
            {
                "label": "मोलाना पाड़ा 0138",
                "village_id": 120,
                "village_name": "मोलाना पाड़ा"
            },
            {
                "label": "मोहगढ़ 0170",
                "village_id": 121,
                "village_name": "मोहगढ़"
            },
            {
                "label": "मोहनियां 0277",
                "village_id": 122,
                "village_name": "मोहनियां"
            },
            {
                "label": "रतुआ 0264",
                "village_id": 123,
                "village_name": "रतुआ"
            },
            {
                "label": "रतनपुर 0276",
                "village_id": 124,
                "village_name": "रतनपुर"
            },
            {
                "label": "रमनिया पौखर 0116",
                "village_id": 125,
                "village_name": "रमनिया पौखर"
            },
            {
                "label": "रसिया डांरी 0168",
                "village_id": 126,
                "village_name": "रसिया डांरी"
            },
            {
                "label": "रायपुर 0249",
                "village_id": 127,
                "village_name": "रायपुर"
            },
            {
                "label": "लोधा बाड़ी 0171",
                "village_id": 128,
                "village_name": "लोधा बाड़ी"
            },
            {
                "label": "वेलासन 0181",
                "village_id": 129,
                "village_name": "वेलासन"
            },
            {
                "label": "वागरानी 0123",
                "village_id": 130,
                "village_name": "वागरानी"
            },
            {
                "label": "वाड़ा पोखर 0045",
                "village_id": 131,
                "village_name": "वाड़ा पोखर"
            },
            {
                "label": "विमलगांव 0129",
                "village_id": 132,
                "village_name": "विमलगांव"
            },
            {
                "label": "विसानी 0282",
                "village_id": 133,
                "village_name": "विसानी"
            },
            {
                "label": "वीरपुर 0090",
                "village_id": 134,
                "village_name": "वीरपुर"
            },
            {
                "label": "वोदालोटी 0104",
                "village_id": 135,
                "village_name": "वोदालोटी"
            },
            {
                "label": "शेखपुरा 0150",
                "village_id": 136,
                "village_name": "शेखपुरा"
            },
            {
                "label": "शीतल़गढ़ 0103",
                "village_id": 137,
                "village_name": "शीतल़गढ़"
            },
            {
                "label": "शीतलपुर 0269",
                "village_id": 138,
                "village_name": "शीतलपुर"
            },
            {
                "label": "सेठावाड़ी 0152",
                "village_id": 139,
                "village_name": "सेठावाड़ी"
            },
            {
                "label": "सतवोलिया 0072",
                "village_id": 140,
                "village_name": "सतवोलिया"
            },
            {
                "label": "सतवोलिया खुर्द 0173",
                "village_id": 141,
                "village_name": "सतवोलिया खुर्द"
            },
            {
                "label": "सारादीघि 0265",
                "village_id": 142,
                "village_name": "सारादीघि"
            },
            {
                "label": "सारो गोरा 0115",
                "village_id": 143,
                "village_name": "सारो गोरा"
            },
            {
                "label": "सिधारी गोविनपुर 0143",
                "village_id": 144,
                "village_name": "सिधारी गोविनपुर"
            },
            {
                "label": "सिधीमारी 0167",
                "village_id": 145,
                "village_name": "सिधीमारी"
            },
            {
                "label": "सीताझाड़ी 0268",
                "village_id": 146,
                "village_name": "सीताझाड़ी"
            },
            {
                "label": "सीताझाड़ी 0267",
                "village_id": 147,
                "village_name": "सीताझाड़ी"
            },
            {
                "label": "सोनापुर 0031",
                "village_id": 148,
                "village_name": "सोनापुर"
            },
            {
                "label": "सोहागी 0158",
                "village_id": 149,
                "village_name": "सोहागी"
            },
            {
                "label": "हलदा 0141",
                "village_id": 150,
                "village_name": "हलदा"
            },
            {
                "label": "हल्दा गाँव 0252",
                "village_id": 151,
                "village_name": "हल्दा गाँव"
            },
            {
                "label": "हाम्दावाड़ी 0151",
                "village_id": 152,
                "village_name": "हाम्दावाड़ी"
            }
        ],
        "block_id": "6",
        "block_no": 5,
        "block_name": "पोठिया [006]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अदरागुड़ी 0052",
                "village_id": 2,
                "village_name": "अदरागुड़ी"
            },
            {
                "label": "अमलझाड़ी 0066",
                "village_id": 3,
                "village_name": "अमलझाड़ी"
            },
            {
                "label": "आमबाड़ी 0196",
                "village_id": 4,
                "village_name": "आमबाड़ी"
            },
            {
                "label": "कुकुरवाघी 0003",
                "village_id": 5,
                "village_name": "कुकुरवाघी"
            },
            {
                "label": "कुकुरवाघी 0005",
                "village_id": 6,
                "village_name": "कुकुरवाघी"
            },
            {
                "label": "कचूदह 0081",
                "village_id": 7,
                "village_name": "कचूदह"
            },
            {
                "label": "कठारो 0237",
                "village_id": 8,
                "village_name": "कठारो"
            },
            {
                "label": "कठारो 0239",
                "village_id": 9,
                "village_name": "कठारो"
            },
            {
                "label": "कुडीमनी 0186",
                "village_id": 10,
                "village_name": "कुडीमनी"
            },
            {
                "label": "कनकपुर 0017",
                "village_id": 11,
                "village_name": "कनकपुर"
            },
            {
                "label": "कुन्जीमारी 0184",
                "village_id": 12,
                "village_name": "कुन्जीमारी"
            },
            {
                "label": "करुआमनी 0051",
                "village_id": 13,
                "village_name": "करुआमनी"
            },
            {
                "label": "करूआमनी 0175",
                "village_id": 14,
                "village_name": "करूआमनी"
            },
            {
                "label": "करुवामनी 0248",
                "village_id": 15,
                "village_name": "करुवामनी"
            },
            {
                "label": "कोईया 0204",
                "village_id": 16,
                "village_name": "कोईया"
            },
            {
                "label": "कोदाल छाड़ा 0065",
                "village_id": 17,
                "village_name": "कोदाल छाड़ा"
            },
            {
                "label": "खड़ना 0048",
                "village_id": 18,
                "village_name": "खड़ना"
            },
            {
                "label": "खड़ना 0049",
                "village_id": 19,
                "village_name": "खड़ना"
            },
            {
                "label": "खड़ना 0084",
                "village_id": 20,
                "village_name": "खड़ना"
            },
            {
                "label": "खानावाड़ी 0235",
                "village_id": 21,
                "village_name": "खानावाड़ी"
            },
            {
                "label": "खारन्दह 0173",
                "village_id": 22,
                "village_name": "खारन्दह"
            },
            {
                "label": "खारन्दह 0174",
                "village_id": 23,
                "village_name": "खारन्दह"
            },
            {
                "label": "खारन्दह 0247",
                "village_id": 24,
                "village_name": "खारन्दह"
            },
            {
                "label": "खोदरा 0233",
                "village_id": 25,
                "village_name": "खोदरा"
            },
            {
                "label": "गुंजरमारी 0018",
                "village_id": 26,
                "village_name": "गुंजरमारी"
            },
            {
                "label": "गंभीरगढ़ 0191",
                "village_id": 27,
                "village_name": "गंभीरगढ़"
            },
            {
                "label": "ग्वालटोली 0082",
                "village_id": 28,
                "village_name": "ग्वालटोली"
            },
            {
                "label": "गिथिनकोला 0019",
                "village_id": 29,
                "village_name": "गिथिनकोला"
            },
            {
                "label": "गिल्हाबाड़ी 0202",
                "village_id": 30,
                "village_name": "गिल्हाबाड़ी"
            },
            {
                "label": "गोथरा 0016",
                "village_id": 31,
                "village_name": "गोथरा"
            },
            {
                "label": "चकचकी 0007",
                "village_id": 32,
                "village_name": "चकचकी"
            },
            {
                "label": "चपराबखारी 0199",
                "village_id": 33,
                "village_name": "चपराबखारी"
            },
            {
                "label": "चपाती 0230",
                "village_id": 34,
                "village_name": "चपाती"
            },
            {
                "label": "चुरली 0014",
                "village_id": 35,
                "village_name": "चुरली"
            },
            {
                "label": "चुरली 0015",
                "village_id": 36,
                "village_name": "चुरली"
            },
            {
                "label": "छत्तर कठारो 0238",
                "village_id": 37,
                "village_name": "छत्तर कठारो"
            },
            {
                "label": "छैतल 0062",
                "village_id": 38,
                "village_name": "छैतल"
            },
            {
                "label": "जिया पोखर 0205",
                "village_id": 39,
                "village_name": "जिया पोखर"
            },
            {
                "label": "जिया पोखर 0206",
                "village_id": 40,
                "village_name": "जिया पोखर"
            },
            {
                "label": "जिया पोखर 0209",
                "village_id": 41,
                "village_name": "जिया पोखर"
            },
            {
                "label": "जोरनगछ 0068",
                "village_id": 42,
                "village_name": "जोरनगछ"
            },
            {
                "label": "ठाकपाड़ा 0009",
                "village_id": 43,
                "village_name": "ठाकपाड़ा"
            },
            {
                "label": "ठाकपाड़ा 0028",
                "village_id": 44,
                "village_name": "ठाकपाड़ा"
            },
            {
                "label": "ठाकपाड़ा 0029",
                "village_id": 45,
                "village_name": "ठाकपाड़ा"
            },
            {
                "label": "ठाकपाड़ा 0006",
                "village_id": 46,
                "village_name": "ठाकपाड़ा"
            },
            {
                "label": "डुमरिया 0219",
                "village_id": 47,
                "village_name": "डुमरिया"
            },
            {
                "label": "डेरामारी 0207",
                "village_id": 48,
                "village_name": "डेरामारी"
            },
            {
                "label": "तेधारी 0218",
                "village_id": 49,
                "village_name": "तेधारी"
            },
            {
                "label": "तातपौआ 0192",
                "village_id": 50,
                "village_name": "तातपौआ"
            },
            {
                "label": "दुधमंजर 0053",
                "village_id": 51,
                "village_name": "दुधमंजर"
            },
            {
                "label": "दुधौरी 0075",
                "village_id": 52,
                "village_name": "दुधौरी"
            },
            {
                "label": "दल्लेगाँव 0057",
                "village_id": 53,
                "village_name": "दल्लेगाँव"
            },
            {
                "label": "दल्लेगांव 0056",
                "village_id": 54,
                "village_name": "दल्लेगांव"
            },
            {
                "label": "दस्तुर 0240",
                "village_id": 55,
                "village_name": "दस्तुर"
            },
            {
                "label": "दोगछी 0055",
                "village_id": 56,
                "village_name": "दोगछी"
            },
            {
                "label": "नजरपुर 0246",
                "village_id": 57,
                "village_name": "नजरपुर"
            },
            {
                "label": "नेजागछ 0013",
                "village_id": 58,
                "village_name": "नेजागछ"
            },
            {
                "label": "नुनियाटारी 0059",
                "village_id": 59,
                "village_name": "नुनियाटारी"
            },
            {
                "label": "निकरवाड़ी 0189",
                "village_id": 60,
                "village_name": "निकरवाड़ी"
            },
            {
                "label": "पैकपाडा 0231",
                "village_id": 61,
                "village_name": "पैकपाडा"
            },
            {
                "label": "पैकपाडा 0232",
                "village_id": 62,
                "village_name": "पैकपाडा"
            },
            {
                "label": "पथरीया 0012",
                "village_id": 63,
                "village_name": "पथरीया"
            },
            {
                "label": "पेम्मरी 0223",
                "village_id": 64,
                "village_name": "पेम्मरी"
            },
            {
                "label": "पेम्मरी 0224",
                "village_id": 65,
                "village_name": "पेम्मरी"
            },
            {
                "label": "पेम्मरी 0225",
                "village_id": 66,
                "village_name": "पेम्मरी"
            },
            {
                "label": "परेश्वरी 0050",
                "village_id": 67,
                "village_name": "परेश्वरी"
            },
            {
                "label": "पवना 0234",
                "village_id": 68,
                "village_name": "पवना"
            },
            {
                "label": "पाँचगाछी 0220",
                "village_id": 69,
                "village_name": "पाँचगाछी"
            },
            {
                "label": "पौआखाली 0221",
                "village_id": 70,
                "village_name": "पौआखाली"
            },
            {
                "label": "पौआखाली 0222",
                "village_id": 71,
                "village_name": "पौआखाली"
            },
            {
                "label": "फतेहजंगपुर 0038",
                "village_id": 72,
                "village_name": "फतेहजंगपुर"
            },
            {
                "label": "फारामारी 0083",
                "village_id": 73,
                "village_name": "फारामारी"
            },
            {
                "label": "बुढ़नई बहादुरपुर 0078",
                "village_id": 74,
                "village_name": "बुढ़नई बहादुरपुर"
            },
            {
                "label": "बतकादोमा 0228",
                "village_id": 75,
                "village_name": "बतकादोमा"
            },
            {
                "label": "बन्दरझुला 0208",
                "village_id": 76,
                "village_name": "बन्दरझुला"
            },
            {
                "label": "बन्दरझुला 0212",
                "village_id": 77,
                "village_name": "बन्दरझुला"
            },
            {
                "label": "बन्दरझुला 0213",
                "village_id": 78,
                "village_name": "बन्दरझुला"
            },
            {
                "label": "बन्दरझुला 0214",
                "village_id": 79,
                "village_name": "बन्दरझुला"
            },
            {
                "label": "बन्दरझुला 0215",
                "village_id": 80,
                "village_name": "बन्दरझुला"
            },
            {
                "label": "बरचौन्दी 0185",
                "village_id": 81,
                "village_name": "बरचौन्दी"
            },
            {
                "label": "बरचौन्दी 0183",
                "village_id": 82,
                "village_name": "बरचौन्दी"
            },
            {
                "label": "बेसरवारी 0002",
                "village_id": 83,
                "village_name": "बेसरवारी"
            },
            {
                "label": "बहादुरपुर 0064",
                "village_id": 84,
                "village_name": "बहादुरपुर"
            },
            {
                "label": "बाबनगाँव 0182",
                "village_id": 85,
                "village_name": "बाबनगाँव"
            },
            {
                "label": "बाभनगाँव 0236",
                "village_id": 86,
                "village_name": "बाभनगाँव"
            },
            {
                "label": "बारह पोठिया 0187",
                "village_id": 87,
                "village_name": "बारह पोठिया"
            },
            {
                "label": "बारह पोठिया 0188",
                "village_id": 88,
                "village_name": "बारह पोठिया"
            },
            {
                "label": "बाँसबाड़ी 0217",
                "village_id": 89,
                "village_name": "बाँसबाड़ी"
            },
            {
                "label": "भेन्डरानी 0211",
                "village_id": 90,
                "village_name": "भेन्डरानी"
            },
            {
                "label": "भेन्डरानी 0203",
                "village_id": 91,
                "village_name": "भेन्डरानी"
            },
            {
                "label": "भेलागुड़ी 0226",
                "village_id": 92,
                "village_name": "भेलागुड़ी"
            },
            {
                "label": "भातगाँव 0001",
                "village_id": 93,
                "village_name": "भातगाँव"
            },
            {
                "label": "भोगडावर 0061",
                "village_id": 94,
                "village_name": "भोगडावर"
            },
            {
                "label": "भोटाथाना 0060",
                "village_id": 95,
                "village_name": "भोटाथाना"
            },
            {
                "label": "भोटाथाना 0077",
                "village_id": 96,
                "village_name": "भोटाथाना"
            },
            {
                "label": "भोलमारा 0242",
                "village_id": 97,
                "village_name": "भोलमारा"
            },
            {
                "label": "भोलमारा 0243",
                "village_id": 98,
                "village_name": "भोलमारा"
            },
            {
                "label": "भोलमारा 0244",
                "village_id": 99,
                "village_name": "भोलमारा"
            },
            {
                "label": "भोलमारा 0245",
                "village_id": 100,
                "village_name": "भोलमारा"
            },
            {
                "label": "मदाती 0004",
                "village_id": 101,
                "village_name": "मदाती"
            },
            {
                "label": "मालीन गाँव 0197",
                "village_id": 102,
                "village_name": "मालीन गाँव"
            },
            {
                "label": "मिलिक कुकुरवाघी 0010",
                "village_id": 103,
                "village_name": "मिलिक कुकुरवाघी"
            },
            {
                "label": "मौजीडांगी 0198",
                "village_id": 104,
                "village_name": "मौजीडांगी"
            },
            {
                "label": "युधमंजर 0054",
                "village_id": 105,
                "village_name": "युधमंजर"
            },
            {
                "label": "रुईधासा 0063",
                "village_id": 106,
                "village_name": "रुईधासा"
            },
            {
                "label": "रसिया 0229",
                "village_id": 107,
                "village_name": "रसिया"
            },
            {
                "label": "राजा गाँव 0200",
                "village_id": 108,
                "village_name": "राजा गाँव"
            },
            {
                "label": "राजागाँव 0216",
                "village_id": 109,
                "village_name": "राजागाँव"
            },
            {
                "label": "राजागाँव मिलिक 0201",
                "village_id": 110,
                "village_name": "राजागाँव मिलिक"
            },
            {
                "label": "लन्घड़ा 0046",
                "village_id": 111,
                "village_name": "लन्घड़ा"
            },
            {
                "label": "लन्घड़ा 0047",
                "village_id": 112,
                "village_name": "लन्घड़ा"
            },
            {
                "label": "लाट कुकुरवाघी 0011",
                "village_id": 113,
                "village_name": "लाट कुकुरवाघी"
            },
            {
                "label": "लाहुगाँव 0027",
                "village_id": 114,
                "village_name": "लाहुगाँव"
            },
            {
                "label": "वारह पोठिया 0190",
                "village_id": 115,
                "village_name": "वारह पोठिया"
            },
            {
                "label": "विधिभीठा 0227",
                "village_id": 116,
                "village_name": "विधिभीठा"
            },
            {
                "label": "सतवोलिया 0076",
                "village_id": 117,
                "village_name": "सतवोलिया"
            },
            {
                "label": "सन्यासी 0008",
                "village_id": 118,
                "village_name": "सन्यासी"
            },
            {
                "label": "सुरवानी 0193",
                "village_id": 119,
                "village_name": "सुरवानी"
            },
            {
                "label": "सरायकुड़ी 0241",
                "village_id": 120,
                "village_name": "सरायकुड़ी"
            },
            {
                "label": "सालगुड़ी 0195",
                "village_id": 121,
                "village_name": "सालगुड़ी"
            },
            {
                "label": "सावोगांगी 0194",
                "village_id": 122,
                "village_name": "सावोगांगी"
            },
            {
                "label": "सिंधीमारी 0210",
                "village_id": 123,
                "village_name": "सिंधीमारी"
            },
            {
                "label": "हखुआडाली 0039",
                "village_id": 124,
                "village_name": "हखुआडाली"
            },
            {
                "label": "हजारी 0058",
                "village_id": 125,
                "village_name": "हजारी"
            },
            {
                "label": "हरिनडूबल 0067",
                "village_id": 126,
                "village_name": "हरिनडूबल"
            },
            {
                "label": "हुलहुली 0020",
                "village_id": 127,
                "village_name": "हुलहुली"
            },
            {
                "label": "हुलहुली 0021",
                "village_id": 128,
                "village_name": "हुलहुली"
            },
            {
                "label": "हुलहुली 0022",
                "village_id": 129,
                "village_name": "हुलहुली"
            },
            {
                "label": "हुलहुली 0024",
                "village_id": 130,
                "village_name": "हुलहुली"
            },
            {
                "label": "हुलहुली 0023",
                "village_id": 131,
                "village_name": "हुलहुली"
            },
            {
                "label": "हुलहुली 0025",
                "village_id": 132,
                "village_name": "हुलहुली"
            },
            {
                "label": "हुलहुली 0026",
                "village_id": 133,
                "village_name": "हुलहुली"
            },
            {
                "label": "हसनपुर 0080",
                "village_id": 134,
                "village_name": "हसनपुर"
            }
        ],
        "block_id": "7",
        "block_no": 6,
        "block_name": "ठाकुरगंज [007]",
        "sub_div_code": "1"
    }
],
    "district_id": "08",
    "district_name": "किशनगंज"
},
{
    "block": [
    {
        "village": [
            {
                "label": "अजमतपुर 215/",
                "village_id": 2,
                "village_name": "अजमतपुर"
            },
            {
                "label": "अररिया बस्ति 214/",
                "village_id": 3,
                "village_name": "अररिया बस्ति"
            },
            {
                "label": "आजम नगर 0287",
                "village_id": 4,
                "village_name": "आजम नगर"
            },
            {
                "label": "इटहरा 0205",
                "village_id": 5,
                "village_name": "इटहरा"
            },
            {
                "label": "ककोड़वा 0302",
                "village_id": 6,
                "village_name": "ककोड़वा"
            },
            {
                "label": "कमलदाहा 0309",
                "village_id": 7,
                "village_name": "कमलदाहा"
            },
            {
                "label": "कसैला 0156",
                "village_id": 8,
                "village_name": "कसैला"
            },
            {
                "label": "कुसियारगाँव 0286",
                "village_id": 9,
                "village_name": "कुसियारगाँव"
            },
            {
                "label": "किस्मत खवासपुर 0186",
                "village_id": 10,
                "village_name": "किस्मत खवासपुर"
            },
            {
                "label": "किस्मत जमुआ 0169",
                "village_id": 11,
                "village_name": "किस्मत जमुआ"
            },
            {
                "label": "कोचगामा 0000",
                "village_id": 12,
                "village_name": "कोचगामा"
            },
            {
                "label": "खमगढ़ा 0171",
                "village_id": 13,
                "village_name": "खमगढ़ा"
            },
            {
                "label": "खरहर 0212",
                "village_id": 14,
                "village_name": "खरहर"
            },
            {
                "label": "गैड़ा 0315",
                "village_id": 15,
                "village_name": "गैड़ा"
            },
            {
                "label": "गम्हरिया 0155",
                "village_id": 16,
                "village_name": "गम्हरिया"
            },
            {
                "label": "गैयारी 0000",
                "village_id": 17,
                "village_name": "गैयारी"
            },
            {
                "label": "गिलहवाड़ी 0170",
                "village_id": 18,
                "village_name": "गिलहवाड़ी"
            },
            {
                "label": "चन्द्रदेई 0308",
                "village_id": 19,
                "village_name": "चन्द्रदेई"
            },
            {
                "label": "चरारनी 0189",
                "village_id": 20,
                "village_name": "चरारनी"
            },
            {
                "label": "चातर 0320",
                "village_id": 21,
                "village_name": "चातर"
            },
            {
                "label": "चिकनी 0311",
                "village_id": 22,
                "village_name": "चिकनी"
            },
            {
                "label": "चौराखाल 0323",
                "village_id": 23,
                "village_name": "चौराखाल"
            },
            {
                "label": "जमुआ 0172",
                "village_id": 24,
                "village_name": "जमुआ"
            },
            {
                "label": "जितवारपुर 0183",
                "village_id": 25,
                "village_name": "जितवारपुर"
            },
            {
                "label": "झमटा 0191",
                "village_id": 26,
                "village_name": "झमटा"
            },
            {
                "label": "डोमाई 0318",
                "village_id": 27,
                "village_name": "डोमाई"
            },
            {
                "label": "ढ़किया 0312",
                "village_id": 28,
                "village_name": "ढ़किया"
            },
            {
                "label": "तरौना 0158",
                "village_id": 29,
                "village_name": "तरौना"
            },
            {
                "label": "ताराबाड़ी 0181",
                "village_id": 30,
                "village_name": "ताराबाड़ी"
            },
            {
                "label": "तिरहुत भीटा 0317",
                "village_id": 31,
                "village_name": "तिरहुत भीटा"
            },
            {
                "label": "तिरहुत भीठा 0321",
                "village_id": 32,
                "village_name": "तिरहुत भीठा"
            },
            {
                "label": "दभड़ा 0180",
                "village_id": 33,
                "village_name": "दभड़ा"
            },
            {
                "label": "दमड़ा जागीर 0179",
                "village_id": 34,
                "village_name": "दमड़ा जागीर"
            },
            {
                "label": "देवरिया 0314",
                "village_id": 35,
                "village_name": "देवरिया"
            },
            {
                "label": "दियारी 0288",
                "village_id": 36,
                "village_name": "दियारी"
            },
            {
                "label": "पैकटोला 0313",
                "village_id": 37,
                "village_name": "पैकटोला"
            },
            {
                "label": "पटेगना 0185",
                "village_id": 38,
                "village_name": "पटेगना"
            },
            {
                "label": "पोखरिया 0151",
                "village_id": 39,
                "village_name": "पोखरिया"
            },
            {
                "label": "फरासूत 0213",
                "village_id": 40,
                "village_name": "फरासूत"
            },
            {
                "label": "फुलवाड़ी 0168",
                "village_id": 41,
                "village_name": "फुलवाड़ी"
            },
            {
                "label": "बैजनाथपुर 0198",
                "village_id": 42,
                "village_name": "बैजनाथपुर"
            },
            {
                "label": "बटुरबाड़ी 0188",
                "village_id": 43,
                "village_name": "बटुरबाड़ी"
            },
            {
                "label": "बनगामा 0310",
                "village_id": 44,
                "village_name": "बनगामा"
            },
            {
                "label": "बैरगाछी 0300",
                "village_id": 45,
                "village_name": "बैरगाछी"
            },
            {
                "label": "बेलवा 0297",
                "village_id": 46,
                "village_name": "बेलवा"
            },
            {
                "label": "बेलवाड़ी 0216",
                "village_id": 47,
                "village_name": "बेलवाड़ी"
            },
            {
                "label": "बसंतपुर 0206",
                "village_id": 48,
                "village_name": "बसंतपुर"
            },
            {
                "label": "बाँसवाड़ी 0193",
                "village_id": 49,
                "village_name": "बाँसवाड़ी"
            },
            {
                "label": "बोची 0154",
                "village_id": 50,
                "village_name": "बोची"
            },
            {
                "label": "बोची 0307",
                "village_id": 51,
                "village_name": "बोची"
            },
            {
                "label": "भाग पुरैनी 0184",
                "village_id": 52,
                "village_name": "भाग पुरैनी"
            },
            {
                "label": "भाग मोहब्बत 0200",
                "village_id": 53,
                "village_name": "भाग मोहब्बत"
            },
            {
                "label": "भाग सहदुल्ला 0209",
                "village_id": 54,
                "village_name": "भाग सहदुल्ला"
            },
            {
                "label": "मजकूरी 0201",
                "village_id": 55,
                "village_name": "मजकूरी"
            },
            {
                "label": "मजगामा 0296",
                "village_id": 56,
                "village_name": "मजगामा"
            },
            {
                "label": "मेटन 0190",
                "village_id": 57,
                "village_name": "मेटन"
            },
            {
                "label": "मुड़बल्ला 0195",
                "village_id": 58,
                "village_name": "मुड़बल्ला"
            },
            {
                "label": "मदनपुर 0153",
                "village_id": 59,
                "village_name": "मदनपुर"
            },
            {
                "label": "महिसा कोल 0192",
                "village_id": 60,
                "village_name": "महिसा कोल"
            },
            {
                "label": "मिर्जापुर 0316",
                "village_id": 61,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "रंगदाहा 0211",
                "village_id": 62,
                "village_name": "रंगदाहा"
            },
            {
                "label": "रजोखर 0202",
                "village_id": 63,
                "village_name": "रजोखर"
            },
            {
                "label": "रहिकपुर 0197",
                "village_id": 64,
                "village_name": "रहिकपुर"
            },
            {
                "label": "रामपुर कोदर कट्टी 0000",
                "village_id": 65,
                "village_name": "रामपुर कोदर कट्टी"
            },
            {
                "label": "रामपुर मोहनपुर 0208",
                "village_id": 66,
                "village_name": "रामपुर मोहनपुर"
            },
            {
                "label": "लहटोरा 0000",
                "village_id": 67,
                "village_name": "लहटोरा"
            },
            {
                "label": "लहटोरा 0322",
                "village_id": 68,
                "village_name": "लहटोरा"
            },
            {
                "label": "लहना 0306",
                "village_id": 69,
                "village_name": "लहना"
            },
            {
                "label": "लोदीपुर 0000",
                "village_id": 70,
                "village_name": "लोदीपुर"
            },
            {
                "label": "वड़ा कामत 0207",
                "village_id": 71,
                "village_name": "वड़ा कामत"
            },
            {
                "label": "वेलवाड़ी मिलिक 0217",
                "village_id": 72,
                "village_name": "वेलवाड़ी मिलिक"
            },
            {
                "label": "वैहगी 0152",
                "village_id": 73,
                "village_name": "वैहगी"
            },
            {
                "label": "विशनपुर 0284",
                "village_id": 74,
                "village_name": "विशनपुर"
            },
            {
                "label": "शरणपुर 0178",
                "village_id": 75,
                "village_name": "शरणपुर"
            },
            {
                "label": "सगुना 0187",
                "village_id": 76,
                "village_name": "सगुना"
            },
            {
                "label": "संदलपुर 0319",
                "village_id": 77,
                "village_name": "संदलपुर"
            },
            {
                "label": "समदा 0194",
                "village_id": 78,
                "village_name": "समदा"
            },
            {
                "label": "सुर्यापुर 0301",
                "village_id": 79,
                "village_name": "सुर्यापुर"
            },
            {
                "label": "सलाईगढ़ 0210",
                "village_id": 80,
                "village_name": "सलाईगढ़"
            },
            {
                "label": "सहसमल 0157",
                "village_id": 81,
                "village_name": "सहसमल"
            },
            {
                "label": "सिसौना 0303",
                "village_id": 82,
                "village_name": "सिसौना"
            },
            {
                "label": "हड़िया 0196",
                "village_id": 83,
                "village_name": "हड़िया"
            },
            {
                "label": "ह्रदयपुर 0199",
                "village_id": 84,
                "village_name": "ह्रदयपुर"
            },
            {
                "label": "हसनपुर 0204",
                "village_id": 85,
                "village_name": "हसनपुर"
            },
            {
                "label": "हियातपुर 0203",
                "village_id": 86,
                "village_name": "हियातपुर"
            }
        ],
        "block_id": "1",
        "block_no": 0,
        "block_name": "अररिया [001]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अरतिया 0240",
                "village_id": 2,
                "village_name": "अरतिया"
            },
            {
                "label": "उखवा 0271",
                "village_id": 3,
                "village_name": "उखवा"
            },
            {
                "label": "उदा 0273",
                "village_id": 4,
                "village_name": "उदा"
            },
            {
                "label": "ककोढ़ा 0324",
                "village_id": 5,
                "village_name": "ककोढ़ा"
            },
            {
                "label": "कचनहर 0000",
                "village_id": 6,
                "village_name": "कचनहर"
            },
            {
                "label": "कजलेय 0252",
                "village_id": 7,
                "village_name": "कजलेय"
            },
            {
                "label": "कटहल बाड़ी 0251",
                "village_id": 8,
                "village_name": "कटहल बाड़ी"
            },
            {
                "label": "कुरसैला 0267",
                "village_id": 9,
                "village_name": "कुरसैला"
            },
            {
                "label": "करहारा 0269",
                "village_id": 10,
                "village_name": "करहारा"
            },
            {
                "label": "करहारा छपरैल 0000",
                "village_id": 11,
                "village_name": "करहारा छपरैल"
            },
            {
                "label": "करियात 0327",
                "village_id": 12,
                "village_name": "करियात"
            },
            {
                "label": "केसर्रा 0102",
                "village_id": 13,
                "village_name": "केसर्रा"
            },
            {
                "label": "काकन 0228",
                "village_id": 14,
                "village_name": "काकन"
            },
            {
                "label": "कामत सतवीटा 0220",
                "village_id": 15,
                "village_name": "कामत सतवीटा"
            },
            {
                "label": "किशनपुर 0272",
                "village_id": 16,
                "village_name": "किशनपुर"
            },
            {
                "label": "खुद्दी खेरैया 0104",
                "village_id": 17,
                "village_name": "खुद्दी खेरैया"
            },
            {
                "label": "खिरदाहा 0237",
                "village_id": 18,
                "village_name": "खिरदाहा"
            },
            {
                "label": "गच्छ महदेवा 0245",
                "village_id": 19,
                "village_name": "गच्छ महदेवा"
            },
            {
                "label": "गम्हरिया 0227",
                "village_id": 20,
                "village_name": "गम्हरिया"
            },
            {
                "label": "गैरकी 0223",
                "village_id": 21,
                "village_name": "गैरकी"
            },
            {
                "label": "गिरदा 0261",
                "village_id": 22,
                "village_name": "गिरदा"
            },
            {
                "label": "गोगरा 215/",
                "village_id": 23,
                "village_name": "गोगरा"
            },
            {
                "label": "घोड़मारा 0294",
                "village_id": 24,
                "village_name": "घोड़मारा"
            },
            {
                "label": "चकई 0266",
                "village_id": 25,
                "village_name": "चकई"
            },
            {
                "label": "चैनपुर 0278",
                "village_id": 26,
                "village_name": "चैनपुर"
            },
            {
                "label": "चिकनियाँ 0290",
                "village_id": 27,
                "village_name": "चिकनियाँ"
            },
            {
                "label": "चिरह 0274",
                "village_id": 28,
                "village_name": "चिरह"
            },
            {
                "label": "चिल्हनियाँ 0331",
                "village_id": 29,
                "village_name": "चिल्हनियाँ"
            },
            {
                "label": "चौकता 0000",
                "village_id": 30,
                "village_name": "चौकता"
            },
            {
                "label": "छोटी मटियारी 0224",
                "village_id": 31,
                "village_name": "छोटी मटियारी"
            },
            {
                "label": "जहानपुर 0106",
                "village_id": 32,
                "village_name": "जहानपुर"
            },
            {
                "label": "जोकीहाट 0241",
                "village_id": 33,
                "village_name": "जोकीहाट"
            },
            {
                "label": "डूब्बा 0230",
                "village_id": 34,
                "village_name": "डूब्बा"
            },
            {
                "label": "डुमरिया 0276",
                "village_id": 35,
                "village_name": "डुमरिया"
            },
            {
                "label": "तुरकैली 0298",
                "village_id": 36,
                "village_name": "तुरकैली"
            },
            {
                "label": "ताऱण 0229",
                "village_id": 37,
                "village_name": "ताऱण"
            },
            {
                "label": "दभरा 0257",
                "village_id": 38,
                "village_name": "दभरा"
            },
            {
                "label": "दुर्गापुर 0000",
                "village_id": 39,
                "village_name": "दुर्गापुर"
            },
            {
                "label": "दर्शना 0264",
                "village_id": 40,
                "village_name": "दर्शना"
            },
            {
                "label": "दोमाहना 0328",
                "village_id": 41,
                "village_name": "दोमाहना"
            },
            {
                "label": "धनपुरा 0242",
                "village_id": 42,
                "village_name": "धनपुरा"
            },
            {
                "label": "धमेश्वर गच्छ 0103",
                "village_id": 43,
                "village_name": "धमेश्वर गच्छ"
            },
            {
                "label": "धोबनियां 0236",
                "village_id": 44,
                "village_name": "धोबनियां"
            },
            {
                "label": "नौवा ननकार 0250",
                "village_id": 45,
                "village_name": "नौवा ननकार"
            },
            {
                "label": "पुखारी फुलपुर 0275",
                "village_id": 46,
                "village_name": "पुखारी फुलपुर"
            },
            {
                "label": "पचैली 0000",
                "village_id": 47,
                "village_name": "पचैली"
            },
            {
                "label": "पछियारी पीपरा 0000",
                "village_id": 48,
                "village_name": "पछियारी पीपरा"
            },
            {
                "label": "पछियारी फूलपुर 0291",
                "village_id": 49,
                "village_name": "पछियारी फूलपुर"
            },
            {
                "label": "पथराबाड़ी 0247",
                "village_id": 50,
                "village_name": "पथराबाड़ी"
            },
            {
                "label": "पदमपुर 0292",
                "village_id": 51,
                "village_name": "पदमपुर"
            },
            {
                "label": "प्रसादपुर 0289",
                "village_id": 52,
                "village_name": "प्रसादपुर"
            },
            {
                "label": "प्रसादपुर 0279",
                "village_id": 53,
                "village_name": "प्रसादपुर"
            },
            {
                "label": "पाड़ा 0333",
                "village_id": 54,
                "village_name": "पाड़ा"
            },
            {
                "label": "पीरगंज 0260",
                "village_id": 55,
                "village_name": "पीरगंज"
            },
            {
                "label": "बैगडहरा 0226",
                "village_id": 56,
                "village_name": "बैगडहरा"
            },
            {
                "label": "बनकोरा 0246",
                "village_id": 57,
                "village_name": "बनकोरा"
            },
            {
                "label": "बैरगाछी 0101",
                "village_id": 58,
                "village_name": "बैरगाछी"
            },
            {
                "label": "बरडेंगा 0268",
                "village_id": 59,
                "village_name": "बरडेंगा"
            },
            {
                "label": "बरहुआ 214/",
                "village_id": 60,
                "village_name": "बरहुआ"
            },
            {
                "label": "बहरबाड़ी 0258",
                "village_id": 61,
                "village_name": "बहरबाड़ी"
            },
            {
                "label": "बागनगर 0280",
                "village_id": 62,
                "village_name": "बागनगर"
            },
            {
                "label": "बागमारा 0235",
                "village_id": 63,
                "village_name": "बागमारा"
            },
            {
                "label": "बागेश्वरी 0222",
                "village_id": 64,
                "village_name": "बागेश्वरी"
            },
            {
                "label": "बारा इस्तम्बरार 0253",
                "village_id": 65,
                "village_name": "बारा इस्तम्बरार"
            },
            {
                "label": "भगवानपुर 0218",
                "village_id": 66,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भूना 0000",
                "village_id": 67,
                "village_name": "भूना"
            },
            {
                "label": "भुना मजगामा 0338",
                "village_id": 68,
                "village_name": "भुना मजगामा"
            },
            {
                "label": "भंसिया 0293",
                "village_id": 69,
                "village_name": "भंसिया"
            },
            {
                "label": "भाग तुरकैली 0299",
                "village_id": 70,
                "village_name": "भाग तुरकैली"
            },
            {
                "label": "मछेला 0000",
                "village_id": 71,
                "village_name": "मछेला"
            },
            {
                "label": "मजगामा 0330",
                "village_id": 72,
                "village_name": "मजगामा"
            },
            {
                "label": "मटियारी 0325",
                "village_id": 73,
                "village_name": "मटियारी"
            },
            {
                "label": "मटियारी 0225",
                "village_id": 74,
                "village_name": "मटियारी"
            },
            {
                "label": "मड़वा 0255",
                "village_id": 75,
                "village_name": "मड़वा"
            },
            {
                "label": "मल्हरिया 0281",
                "village_id": 76,
                "village_name": "मल्हरिया"
            },
            {
                "label": "मसुरिया 0000",
                "village_id": 77,
                "village_name": "मसुरिया"
            },
            {
                "label": "मसुरिया 0221",
                "village_id": 78,
                "village_name": "मसुरिया"
            },
            {
                "label": "महजाली 0295",
                "village_id": 79,
                "village_name": "महजाली"
            },
            {
                "label": "महदेवा 0244",
                "village_id": 80,
                "village_name": "महदेवा"
            },
            {
                "label": "मेहदीनगर 0256",
                "village_id": 81,
                "village_name": "मेहदीनगर"
            },
            {
                "label": "महलगाँव 0270",
                "village_id": 82,
                "village_name": "महलगाँव"
            },
            {
                "label": "मालध्डी 0248",
                "village_id": 83,
                "village_name": "मालध्डी"
            },
            {
                "label": "रहिकपुर 0265",
                "village_id": 84,
                "village_name": "रहिकपुर"
            },
            {
                "label": "रानी 0112",
                "village_id": 85,
                "village_name": "रानी"
            },
            {
                "label": "रानी इस्‍तमवरा 0243",
                "village_id": 86,
                "village_name": "रानी इस्‍तमवरा"
            },
            {
                "label": "रामगंज 0263",
                "village_id": 87,
                "village_name": "रामगंज"
            },
            {
                "label": "ललुवाबाड़ी 0259",
                "village_id": 88,
                "village_name": "ललुवाबाड़ी"
            },
            {
                "label": "ललिया 0261",
                "village_id": 89,
                "village_name": "ललिया"
            },
            {
                "label": "वाजिदपुर 0277",
                "village_id": 90,
                "village_name": "वाजिदपुर"
            },
            {
                "label": "शाहपुर 0249",
                "village_id": 91,
                "village_name": "शाहपुर"
            },
            {
                "label": "सतधरा 0111",
                "village_id": 92,
                "village_name": "सतधरा"
            },
            {
                "label": "सतवीटा 0219",
                "village_id": 93,
                "village_name": "सतवीटा"
            },
            {
                "label": "सैफुल्ला 0326",
                "village_id": 94,
                "village_name": "सैफुल्ला"
            },
            {
                "label": "सिंगार मोहनी 0238",
                "village_id": 95,
                "village_name": "सिंगार मोहनी"
            },
            {
                "label": "सिमरिय़ा 0116",
                "village_id": 96,
                "village_name": "सिमरिय़ा"
            },
            {
                "label": "सिसवा 0254",
                "village_id": 97,
                "village_name": "सिसवा"
            },
            {
                "label": "सिसौना 0239",
                "village_id": 98,
                "village_name": "सिसौना"
            },
            {
                "label": "सोपा 0329",
                "village_id": 99,
                "village_name": "सोपा"
            },
            {
                "label": "हरदार 0105",
                "village_id": 100,
                "village_name": "हरदार"
            }
        ],
        "block_id": "2",
        "block_no": 1,
        "block_name": "जोकीहाट [002]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "उरलाहा 0127",
                "village_id": 2,
                "village_name": "उरलाहा"
            },
            {
                "label": "एराजी पकड़ी 123/",
                "village_id": 3,
                "village_name": "एराजी पकड़ी"
            },
            {
                "label": "कुंजरी 0072",
                "village_id": 4,
                "village_name": "कुंजरी"
            },
            {
                "label": "कठोरा 0082",
                "village_id": 5,
                "village_name": "कठोरा"
            },
            {
                "label": "कन खुदिया 0059",
                "village_id": 6,
                "village_name": "कन खुदिया"
            },
            {
                "label": "कुमर खुर्द 0141",
                "village_id": 7,
                "village_name": "कुमर खुर्द"
            },
            {
                "label": "करोड़ 0051",
                "village_id": 8,
                "village_name": "करोड़"
            },
            {
                "label": "करोड़ दिघली 0050",
                "village_id": 9,
                "village_name": "करोड़ दिघली"
            },
            {
                "label": "कलाही 0068",
                "village_id": 10,
                "village_name": "कलाही"
            },
            {
                "label": "कामत पकड़ी 123/",
                "village_id": 11,
                "village_name": "कामत पकड़ी"
            },
            {
                "label": "काशीवाड़ी 0092",
                "village_id": 12,
                "village_name": "काशीवाड़ी"
            },
            {
                "label": "कोढैली 0160",
                "village_id": 13,
                "village_name": "कोढैली"
            },
            {
                "label": "खुट्टी 0093",
                "village_id": 14,
                "village_name": "खुट्टी"
            },
            {
                "label": "खपड़ा 0089",
                "village_id": 15,
                "village_name": "खपड़ा"
            },
            {
                "label": "गंगाझली 0060",
                "village_id": 16,
                "village_name": "गंगाझली"
            },
            {
                "label": "गच्छ मियाँपुर 0065",
                "village_id": 17,
                "village_name": "गच्छ मियाँपुर"
            },
            {
                "label": "ग्यासपुर 0121",
                "village_id": 18,
                "village_name": "ग्यासपुर"
            },
            {
                "label": "गरहरा 0073",
                "village_id": 19,
                "village_name": "गरहरा"
            },
            {
                "label": "गेरारी 0138",
                "village_id": 20,
                "village_name": "गेरारी"
            },
            {
                "label": "गोपालनगर 0134",
                "village_id": 21,
                "village_name": "गोपालनगर"
            },
            {
                "label": "गोसाईपुर 0094",
                "village_id": 22,
                "village_name": "गोसाईपुर"
            },
            {
                "label": "गोहास 0070",
                "village_id": 23,
                "village_name": "गोहास"
            },
            {
                "label": "घुरगाँव 0100",
                "village_id": 24,
                "village_name": "घुरगाँव"
            },
            {
                "label": "चखना 0052",
                "village_id": 25,
                "village_name": "चखना"
            },
            {
                "label": "चंडीपुर 0077",
                "village_id": 26,
                "village_name": "चंडीपुर"
            },
            {
                "label": "चहटपुर 0135",
                "village_id": 27,
                "village_name": "चहटपुर"
            },
            {
                "label": "चौरी 0048",
                "village_id": 28,
                "village_name": "चौरी"
            },
            {
                "label": "छपनियाँ 0054",
                "village_id": 29,
                "village_name": "छपनियाँ"
            },
            {
                "label": "जुरैल 0234",
                "village_id": 30,
                "village_name": "जुरैल"
            },
            {
                "label": "जहानपुर 0132",
                "village_id": 31,
                "village_name": "जहानपुर"
            },
            {
                "label": "डकैता 0133",
                "village_id": 32,
                "village_name": "डकैता"
            },
            {
                "label": "डकैता 0067",
                "village_id": 33,
                "village_name": "डकैता"
            },
            {
                "label": "डेंगा 0117",
                "village_id": 34,
                "village_name": "डेंगा"
            },
            {
                "label": "डुमरिया 0107",
                "village_id": 35,
                "village_name": "डुमरिया"
            },
            {
                "label": "डेहटी 0159",
                "village_id": 36,
                "village_name": "डेहटी"
            },
            {
                "label": "डाला 0125",
                "village_id": 37,
                "village_name": "डाला"
            },
            {
                "label": "डोबा 0086",
                "village_id": 38,
                "village_name": "डोबा"
            },
            {
                "label": "तरबी 0108",
                "village_id": 39,
                "village_name": "तरबी"
            },
            {
                "label": "द्वारगाँव 0076",
                "village_id": 40,
                "village_name": "द्वारगाँव"
            },
            {
                "label": "दिघली 0049",
                "village_id": 41,
                "village_name": "दिघली"
            },
            {
                "label": "दीपनगर 0079",
                "village_id": 42,
                "village_name": "दीपनगर"
            },
            {
                "label": "दोगच्छी 0064",
                "village_id": 43,
                "village_name": "दोगच्छी"
            },
            {
                "label": "दौलतपुर 0081",
                "village_id": 44,
                "village_name": "दौलतपुर"
            },
            {
                "label": "धनगावाँ 0136",
                "village_id": 45,
                "village_name": "धनगावाँ"
            },
            {
                "label": "धनतोला 0129",
                "village_id": 46,
                "village_name": "धनतोला"
            },
            {
                "label": "धर्मगंज 0057",
                "village_id": 47,
                "village_name": "धर्मगंज"
            },
            {
                "label": "नकटा खुर्द 0090",
                "village_id": 48,
                "village_name": "नकटा खुर्द"
            },
            {
                "label": "नकटाकला 0087",
                "village_id": 49,
                "village_name": "नकटाकला"
            },
            {
                "label": "नरंगा 0084",
                "village_id": 50,
                "village_name": "नरंगा"
            },
            {
                "label": "पकड़ी 123/",
                "village_id": 51,
                "village_name": "पकड़ी"
            },
            {
                "label": "पचैली 0149",
                "village_id": 52,
                "village_name": "पचैली"
            },
            {
                "label": "पनडुब्बी 0124",
                "village_id": 53,
                "village_name": "पनडुब्बी"
            },
            {
                "label": "पुरन्दाहा 0145",
                "village_id": 54,
                "village_name": "पुरन्दाहा"
            },
            {
                "label": "पेरवाखुरी 0147",
                "village_id": 55,
                "village_name": "पेरवाखुरी"
            },
            {
                "label": "पिपरा वीजवार 0053",
                "village_id": 56,
                "village_name": "पिपरा वीजवार"
            },
            {
                "label": "पीपरा 0078",
                "village_id": 57,
                "village_name": "पीपरा"
            },
            {
                "label": "पोठिया 0140",
                "village_id": 58,
                "village_name": "पोठिया"
            },
            {
                "label": "फरहारा 0118",
                "village_id": 59,
                "village_name": "फरहारा"
            },
            {
                "label": "फुलसरा 0047",
                "village_id": 60,
                "village_name": "फुलसरा"
            },
            {
                "label": "बकैनियाँ 0161",
                "village_id": 61,
                "village_name": "बकैनियाँ"
            },
            {
                "label": "बड़गाँव 0083",
                "village_id": 62,
                "village_name": "बड़गाँव"
            },
            {
                "label": "बढ़ौली 0099",
                "village_id": 63,
                "village_name": "बढ़ौली"
            },
            {
                "label": "बनगामा 0044",
                "village_id": 64,
                "village_name": "बनगामा"
            },
            {
                "label": "बेनी 0122",
                "village_id": 65,
                "village_name": "बेनी"
            },
            {
                "label": "बरदबट्टा 0061",
                "village_id": 66,
                "village_name": "बरदबट्टा"
            },
            {
                "label": "ब्रह्कुम्बा 0080",
                "village_id": 67,
                "village_name": "ब्रह्कुम्बा"
            },
            {
                "label": "बलुआ 0142",
                "village_id": 68,
                "village_name": "बलुआ"
            },
            {
                "label": "बलुआ 0043",
                "village_id": 69,
                "village_name": "बलुआ"
            },
            {
                "label": "बेलवाड़ी 0075",
                "village_id": 70,
                "village_name": "बेलवाड़ी"
            },
            {
                "label": "बेलवाड़ी 0109",
                "village_id": 71,
                "village_name": "बेलवाड़ी"
            },
            {
                "label": "बेलसरी 0045",
                "village_id": 72,
                "village_name": "बेलसरी"
            },
            {
                "label": "बिहारी 0085",
                "village_id": 73,
                "village_name": "बिहारी"
            },
            {
                "label": "भट्टा बाड़ी 0056",
                "village_id": 74,
                "village_name": "भट्टा बाड़ी"
            },
            {
                "label": "भटनियां 0098",
                "village_id": 75,
                "village_name": "भटनियां"
            },
            {
                "label": "भटवाऱ 0074",
                "village_id": 76,
                "village_name": "भटवाऱ"
            },
            {
                "label": "भदोना 0097",
                "village_id": 77,
                "village_name": "भदोना"
            },
            {
                "label": "भीखा 0150",
                "village_id": 78,
                "village_name": "भीखा"
            },
            {
                "label": "भीमा 0146",
                "village_id": 79,
                "village_name": "भीमा"
            },
            {
                "label": "मघेल 0232",
                "village_id": 80,
                "village_name": "मघेल"
            },
            {
                "label": "मजलिशपुर 0126",
                "village_id": 81,
                "village_name": "मजलिशपुर"
            },
            {
                "label": "मझुआ 0143",
                "village_id": 82,
                "village_name": "मझुआ"
            },
            {
                "label": "मुंडमाला 0139",
                "village_id": 83,
                "village_name": "मुंडमाला"
            },
            {
                "label": "मतोंजा 0062",
                "village_id": 84,
                "village_name": "मतोंजा"
            },
            {
                "label": "मनबोध टण्डा 0058",
                "village_id": 85,
                "village_name": "मनबोध टण्डा"
            },
            {
                "label": "मैना 0119",
                "village_id": 86,
                "village_name": "मैना"
            },
            {
                "label": "मरीचगाँव 0110",
                "village_id": 87,
                "village_name": "मरीचगाँव"
            },
            {
                "label": "महादेव कोल 0046",
                "village_id": 88,
                "village_name": "महादेव कोल"
            },
            {
                "label": "मायाखोड़ी 0113",
                "village_id": 89,
                "village_name": "मायाखोड़ी"
            },
            {
                "label": "मालद्वार 0066",
                "village_id": 90,
                "village_name": "मालद्वार"
            },
            {
                "label": "मियाँपुर 0063",
                "village_id": 91,
                "village_name": "मियाँपुर"
            },
            {
                "label": "मोहनियाँ 0144",
                "village_id": 92,
                "village_name": "मोहनियाँ"
            },
            {
                "label": "रंगदाहा 0120",
                "village_id": 93,
                "village_name": "रंगदाहा"
            },
            {
                "label": "रुपैल 0231",
                "village_id": 94,
                "village_name": "रुपैल"
            },
            {
                "label": "राम नगर 0115",
                "village_id": 95,
                "village_name": "राम नगर"
            },
            {
                "label": "राम नगर 0071",
                "village_id": 96,
                "village_name": "राम नगर"
            },
            {
                "label": "लोखड़ा 0137",
                "village_id": 97,
                "village_name": "लोखड़ा"
            },
            {
                "label": "वुआलदाती 0069",
                "village_id": 98,
                "village_name": "वुआलदाती"
            },
            {
                "label": "वुदिद 0095",
                "village_id": 99,
                "village_name": "वुदिद"
            },
            {
                "label": "श्याम पुर 0148",
                "village_id": 100,
                "village_name": "श्याम पुर"
            },
            {
                "label": "सुखसैना 0233",
                "village_id": 101,
                "village_name": "सुखसैना"
            },
            {
                "label": "सोहन्दर 0096",
                "village_id": 102,
                "village_name": "सोहन्दर"
            },
            {
                "label": "सोहागपुर 0128",
                "village_id": 103,
                "village_name": "सोहागपुर"
            },
            {
                "label": "सौना कान्दर 0088",
                "village_id": 104,
                "village_name": "सौना कान्दर"
            },
            {
                "label": "हड़वा 0114",
                "village_id": 105,
                "village_name": "हड़वा"
            },
            {
                "label": "हरिभाषा 0091",
                "village_id": 106,
                "village_name": "हरिभाषा"
            },
            {
                "label": "हसनपुर 0130",
                "village_id": 107,
                "village_name": "हसनपुर"
            },
            {
                "label": "हसनपुर 0131",
                "village_id": 108,
                "village_name": "हसनपुर"
            }
        ],
        "block_id": "3",
        "block_no": 2,
        "block_name": "पलासी [003]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "आमगाछी 03/1",
                "village_id": 2,
                "village_name": "आमगाछी"
            },
            {
                "label": "आमगाछी मिलिक 0004",
                "village_id": 3,
                "village_name": "आमगाछी मिलिक"
            },
            {
                "label": "एराजी आमगाछी 03/2",
                "village_id": 4,
                "village_name": "एराजी आमगाछी"
            },
            {
                "label": "औलाबाड़ी 0042",
                "village_id": 5,
                "village_name": "औलाबाड़ी"
            },
            {
                "label": "कुआँ पोखर 0008",
                "village_id": 6,
                "village_name": "कुआँ पोखर"
            },
            {
                "label": "कुचहा 30/2",
                "village_id": 7,
                "village_name": "कुचहा"
            },
            {
                "label": "करहवाड़ी 0166",
                "village_id": 8,
                "village_name": "करहवाड़ी"
            },
            {
                "label": "कवैया 0013",
                "village_id": 9,
                "village_name": "कवैया"
            },
            {
                "label": "कौआकोह 0011",
                "village_id": 10,
                "village_name": "कौआकोह"
            },
            {
                "label": "खुटहरा मसरकी 0007",
                "village_id": 11,
                "village_name": "खुटहरा मसरकी"
            },
            {
                "label": "खोरागाछ 0020",
                "village_id": 12,
                "village_name": "खोरागाछ"
            },
            {
                "label": "खोरागाछ 0021",
                "village_id": 13,
                "village_name": "खोरागाछ"
            },
            {
                "label": "खोरागाछ मिलिक 0041",
                "village_id": 14,
                "village_name": "खोरागाछ मिलिक"
            },
            {
                "label": "छपनियाँ 0055",
                "village_id": 15,
                "village_name": "छपनियाँ"
            },
            {
                "label": "डेटुआ 0163",
                "village_id": 16,
                "village_name": "डेटुआ"
            },
            {
                "label": "डैनियाँ 0006",
                "village_id": 17,
                "village_name": "डैनियाँ"
            },
            {
                "label": "डिठौरा 0023",
                "village_id": 18,
                "village_name": "डिठौरा"
            },
            {
                "label": "ढेगरी 0017",
                "village_id": 19,
                "village_name": "ढेगरी"
            },
            {
                "label": "ढेगापुर पीपरा 12/2",
                "village_id": 20,
                "village_name": "ढेगापुर पीपरा"
            },
            {
                "label": "ढेगापुर पीपरा मिलिक 0015",
                "village_id": 21,
                "village_name": "ढेगापुर पीपरा मिलिक"
            },
            {
                "label": "ढेंगापुर मिलिक 0014",
                "village_id": 22,
                "village_name": "ढेंगापुर मिलिक"
            },
            {
                "label": "ढ़ेंगापुर मिलिक एराजी 12/1",
                "village_id": 23,
                "village_name": "ढ़ेंगापुर मिलिक एराजी"
            },
            {
                "label": "तीरा खारदह 09/2",
                "village_id": 24,
                "village_name": "तीरा खारदह"
            },
            {
                "label": "दहगामा 0038",
                "village_id": 25,
                "village_name": "दहगामा"
            },
            {
                "label": "देहपुर खारदह मिलिक 09/1",
                "village_id": 26,
                "village_name": "देहपुर खारदह मिलिक"
            },
            {
                "label": "दहीपोरा 0005",
                "village_id": 27,
                "village_name": "दहीपोरा"
            },
            {
                "label": "दहीपोरा मिलिक 0002",
                "village_id": 28,
                "village_name": "दहीपोरा मिलिक"
            },
            {
                "label": "दीप नगर 0036",
                "village_id": 29,
                "village_name": "दीप नगर"
            },
            {
                "label": "पड़रिया 0039",
                "village_id": 30,
                "village_name": "पड़रिया"
            },
            {
                "label": "पड़रिया रानी कट्टा 0037",
                "village_id": 31,
                "village_name": "पड़रिया रानी कट्टा"
            },
            {
                "label": "परड़िया 0010",
                "village_id": 32,
                "village_name": "परड़िया"
            },
            {
                "label": "पलासमनी 0024",
                "village_id": 33,
                "village_name": "पलासमनी"
            },
            {
                "label": "पहड़ा 0025",
                "village_id": 34,
                "village_name": "पहड़ा"
            },
            {
                "label": "पहड़ा सिकटी 0028",
                "village_id": 35,
                "village_name": "पहड़ा सिकटी"
            },
            {
                "label": "पीपरा 0175",
                "village_id": 36,
                "village_name": "पीपरा"
            },
            {
                "label": "पोठिया 0173",
                "village_id": 37,
                "village_name": "पोठिया"
            },
            {
                "label": "बेंगा 0167",
                "village_id": 38,
                "village_name": "बेंगा"
            },
            {
                "label": "बैरगाछी 0164",
                "village_id": 39,
                "village_name": "बैरगाछी"
            },
            {
                "label": "बरदाहा 0018",
                "village_id": 40,
                "village_name": "बरदाहा"
            },
            {
                "label": "बरमसिया 0033",
                "village_id": 41,
                "village_name": "बरमसिया"
            },
            {
                "label": "बरमसिया अस्तम्बरार 0025",
                "village_id": 42,
                "village_name": "बरमसिया अस्तम्बरार"
            },
            {
                "label": "बरमसिया सिमलमनी 0034",
                "village_id": 43,
                "village_name": "बरमसिया सिमलमनी"
            },
            {
                "label": "बैलवाड़ी 0177",
                "village_id": 44,
                "village_name": "बैलवाड़ी"
            },
            {
                "label": "भपटिया 0019",
                "village_id": 45,
                "village_name": "भपटिया"
            },
            {
                "label": "मजरख 0001",
                "village_id": 46,
                "village_name": "मजरख"
            },
            {
                "label": "मुरारीपुर 0026",
                "village_id": 47,
                "village_name": "मुरारीपुर"
            },
            {
                "label": "मसुण्डा 0016",
                "village_id": 48,
                "village_name": "मसुण्डा"
            },
            {
                "label": "मिरमिरी 0022",
                "village_id": 49,
                "village_name": "मिरमिरी"
            },
            {
                "label": "राम नगर 0174",
                "village_id": 50,
                "village_name": "राम नगर"
            },
            {
                "label": "वरमसिया रानी कट्टा 0032",
                "village_id": 51,
                "village_name": "वरमसिया रानी कट्टा"
            },
            {
                "label": "वोकन्तरी 0029",
                "village_id": 52,
                "village_name": "वोकन्तरी"
            },
            {
                "label": "सैदपोखर 0031",
                "village_id": 53,
                "village_name": "सैदपोखर"
            },
            {
                "label": "सैदावाद 30/1",
                "village_id": 54,
                "village_name": "सैदावाद"
            },
            {
                "label": "सिकटी 0027",
                "village_id": 55,
                "village_name": "सिकटी"
            },
            {
                "label": "सिंघिया 0040",
                "village_id": 56,
                "village_name": "सिंघिया"
            },
            {
                "label": "सोहदी 0162",
                "village_id": 57,
                "village_name": "सोहदी"
            },
            {
                "label": "सोहागमाड़ो 0165",
                "village_id": 58,
                "village_name": "सोहागमाड़ो"
            }
        ],
        "block_id": "4",
        "block_no": 3,
        "block_name": "सिकटी [004]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "असराहा 0085",
                "village_id": 2,
                "village_name": "असराहा"
            },
            {
                "label": "आशा भाग 0094",
                "village_id": 3,
                "village_name": "आशा भाग"
            },
            {
                "label": "इटाही 0053",
                "village_id": 4,
                "village_name": "इटाही"
            },
            {
                "label": "कुआड़ी 0086",
                "village_id": 5,
                "village_name": "कुआड़ी"
            },
            {
                "label": "कटफर 87/1",
                "village_id": 6,
                "village_name": "कटफर"
            },
            {
                "label": "कमलदाहा 0066",
                "village_id": 7,
                "village_name": "कमलदाहा"
            },
            {
                "label": "कुमारीपुर 0048",
                "village_id": 8,
                "village_name": "कुमारीपुर"
            },
            {
                "label": "कुर्साकांटा 72/2",
                "village_id": 9,
                "village_name": "कुर्साकांटा"
            },
            {
                "label": "करहिया 0103",
                "village_id": 10,
                "village_name": "करहिया"
            },
            {
                "label": "कोल्हुआ 0241",
                "village_id": 11,
                "village_name": "कोल्हुआ"
            },
            {
                "label": "कौतहपुर 0240",
                "village_id": 12,
                "village_name": "कौतहपुर"
            },
            {
                "label": "खजुरवाड़ी 0063",
                "village_id": 13,
                "village_name": "खजुरवाड़ी"
            },
            {
                "label": "खुटहरा भगरवी 0071",
                "village_id": 14,
                "village_name": "खुटहरा भगरवी"
            },
            {
                "label": "गोस नगर 0074",
                "village_id": 15,
                "village_name": "गोस नगर"
            },
            {
                "label": "गोसाईपुर 0060",
                "village_id": 16,
                "village_name": "गोसाईपुर"
            },
            {
                "label": "घावावाड़ी 0069",
                "village_id": 17,
                "village_name": "घावावाड़ी"
            },
            {
                "label": "चैता 0093",
                "village_id": 18,
                "village_name": "चैता"
            },
            {
                "label": "चिकनी 0097",
                "village_id": 19,
                "village_name": "चिकनी"
            },
            {
                "label": "चिकनी 0049",
                "village_id": 20,
                "village_name": "चिकनी"
            },
            {
                "label": "जागीर महेशखुट 0056",
                "village_id": 21,
                "village_name": "जागीर महेशखुट"
            },
            {
                "label": "डूमरिया 0092",
                "village_id": 22,
                "village_name": "डूमरिया"
            },
            {
                "label": "डेहरा पीपरा 0081",
                "village_id": 23,
                "village_name": "डेहरा पीपरा"
            },
            {
                "label": "डोरिया 0052",
                "village_id": 24,
                "village_name": "डोरिया"
            },
            {
                "label": "तमकुड़ा 0104",
                "village_id": 25,
                "village_name": "तमकुड़ा"
            },
            {
                "label": "पकड़ी 0050",
                "village_id": 26,
                "village_name": "पकड़ी"
            },
            {
                "label": "पगडेरा 0098",
                "village_id": 27,
                "village_name": "पगडेरा"
            },
            {
                "label": "पड़रिया 0105",
                "village_id": 28,
                "village_name": "पड़रिया"
            },
            {
                "label": "परबत्ता 0054",
                "village_id": 29,
                "village_name": "परबत्ता"
            },
            {
                "label": "परासी 0114",
                "village_id": 30,
                "village_name": "परासी"
            },
            {
                "label": "पलासमनी 0102",
                "village_id": 31,
                "village_name": "पलासमनी"
            },
            {
                "label": "पहूँसी 0078",
                "village_id": 32,
                "village_name": "पहूँसी"
            },
            {
                "label": "फुलवाड़ी 0101",
                "village_id": 33,
                "village_name": "फुलवाड़ी"
            },
            {
                "label": "बखरी फुलवाड़ी 68/1",
                "village_id": 34,
                "village_name": "बखरी फुलवाड़ी"
            },
            {
                "label": "बखरी मिलिक 68/2",
                "village_id": 35,
                "village_name": "बखरी मिलिक"
            },
            {
                "label": "बटराहा 0095",
                "village_id": 36,
                "village_name": "बटराहा"
            },
            {
                "label": "बनमती 0080",
                "village_id": 37,
                "village_name": "बनमती"
            },
            {
                "label": "बलचंदा 0073",
                "village_id": 38,
                "village_name": "बलचंदा"
            },
            {
                "label": "बीरबन 0106",
                "village_id": 39,
                "village_name": "बीरबन"
            },
            {
                "label": "भुमपोखर 0083",
                "village_id": 40,
                "village_name": "भुमपोखर"
            },
            {
                "label": "भलुआ 87/2",
                "village_id": 41,
                "village_name": "भलुआ"
            },
            {
                "label": "भाग परासी 0115",
                "village_id": 42,
                "village_name": "भाग परासी"
            },
            {
                "label": "भागतीरा 0070",
                "village_id": 43,
                "village_name": "भागतीरा"
            },
            {
                "label": "भोड़हा 0057",
                "village_id": 44,
                "village_name": "भोड़हा"
            },
            {
                "label": "मैगरा 0061",
                "village_id": 45,
                "village_name": "मैगरा"
            },
            {
                "label": "मधुबनी 0091",
                "village_id": 46,
                "village_name": "मधुबनी"
            },
            {
                "label": "मरातीपुर 72/1",
                "village_id": 47,
                "village_name": "मरातीपुर"
            },
            {
                "label": "मूसापुर 0099",
                "village_id": 48,
                "village_name": "मूसापुर"
            },
            {
                "label": "महदीपुर 0062",
                "village_id": 49,
                "village_name": "महदीपुर"
            },
            {
                "label": "महेशखुट 0055",
                "village_id": 50,
                "village_name": "महेशखुट"
            },
            {
                "label": "माल परासी 0112",
                "village_id": 51,
                "village_name": "माल परासी"
            },
            {
                "label": "रजौला 0076",
                "village_id": 52,
                "village_name": "रजौला"
            },
            {
                "label": "रहटमीना 0051",
                "village_id": 53,
                "village_name": "रहटमीना"
            },
            {
                "label": "लक्ष्मीपुर 0059",
                "village_id": 54,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "ललोखर 0090",
                "village_id": 55,
                "village_name": "ललोखर"
            },
            {
                "label": "विशनपुर 0084",
                "village_id": 56,
                "village_name": "विशनपुर"
            },
            {
                "label": "शंकरपुर 0064",
                "village_id": 57,
                "village_name": "शंकरपुर"
            },
            {
                "label": "शीशावाड़ी 0082",
                "village_id": 58,
                "village_name": "शीशावाड़ी"
            },
            {
                "label": "सुखसैना 0080",
                "village_id": 59,
                "village_name": "सुखसैना"
            },
            {
                "label": "सुन्दरी 0077",
                "village_id": 60,
                "village_name": "सुन्दरी"
            },
            {
                "label": "सिकटिया 0096",
                "village_id": 61,
                "village_name": "सिकटिया"
            },
            {
                "label": "सिझुआ मिलिक 65/2",
                "village_id": 62,
                "village_name": "सिझुआ मिलिक"
            },
            {
                "label": "सिझुवा 65/1",
                "village_id": 63,
                "village_name": "सिझुवा"
            },
            {
                "label": "सिनुवाड़ी 0111",
                "village_id": 64,
                "village_name": "सिनुवाड़ी"
            },
            {
                "label": "सोनापुर 0058",
                "village_id": 65,
                "village_name": "सोनापुर"
            },
            {
                "label": "सौरगाँव 0039",
                "village_id": 66,
                "village_name": "सौरगाँव"
            },
            {
                "label": "हत्ता बखरी 0067",
                "village_id": 67,
                "village_name": "हत्ता बखरी"
            },
            {
                "label": "हरिपुर 0079",
                "village_id": 68,
                "village_name": "हरिपुर"
            },
            {
                "label": "हरीरा 0075",
                "village_id": 69,
                "village_name": "हरीरा"
            },
            {
                "label": "हसनपुर 0100",
                "village_id": 70,
                "village_name": "हसनपुर"
            }
        ],
        "block_id": "5",
        "block_no": 4,
        "block_name": "कुर्साकांटा [005]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अड़राहा 0001",
                "village_id": 2,
                "village_name": "अड़राहा"
            },
            {
                "label": "अड़राहा मदारगंज 22/1",
                "village_id": 3,
                "village_name": "अड़राहा मदारगंज"
            },
            {
                "label": "अम्हारा 0108",
                "village_id": 4,
                "village_name": "अम्हारा"
            },
            {
                "label": "अमौना 0168",
                "village_id": 5,
                "village_name": "अमौना"
            },
            {
                "label": "अहमदपुर 0025",
                "village_id": 6,
                "village_name": "अहमदपुर"
            },
            {
                "label": "आमगाछी 0118",
                "village_id": 7,
                "village_name": "आमगाछी"
            },
            {
                "label": "इदगह 0013",
                "village_id": 8,
                "village_name": "इदगह"
            },
            {
                "label": "उत्तर महेश्वरी 0176",
                "village_id": 9,
                "village_name": "उत्तर महेश्वरी"
            },
            {
                "label": "औराही 0023",
                "village_id": 10,
                "village_name": "औराही"
            },
            {
                "label": "औसरी 0122",
                "village_id": 11,
                "village_name": "औसरी"
            },
            {
                "label": "कुड़वा 0020",
                "village_id": 12,
                "village_name": "कुड़वा"
            },
            {
                "label": "कुड़वा लक्ष्मीपुर 0016",
                "village_id": 13,
                "village_name": "कुड़वा लक्ष्मीपुर"
            },
            {
                "label": "कुसमाहा 0120",
                "village_id": 14,
                "village_name": "कुसमाहा"
            },
            {
                "label": "किरकिचिया 0151",
                "village_id": 15,
                "village_name": "किरकिचिया"
            },
            {
                "label": "खबासपुर 0038",
                "village_id": 16,
                "village_name": "खबासपुर"
            },
            {
                "label": "खमकोल 0109",
                "village_id": 17,
                "village_name": "खमकोल"
            },
            {
                "label": "खैरखाँह 0131",
                "village_id": 18,
                "village_name": "खैरखाँह"
            },
            {
                "label": "गच्छ लक्ष्मीपुर 0034",
                "village_id": 19,
                "village_name": "गच्छ लक्ष्मीपुर"
            },
            {
                "label": "गंजमारा 0003",
                "village_id": 20,
                "village_name": "गंजमारा"
            },
            {
                "label": "गड़हा 0123",
                "village_id": 21,
                "village_name": "गड़हा"
            },
            {
                "label": "गुरम्ही 0035",
                "village_id": 22,
                "village_name": "गुरम्ही"
            },
            {
                "label": "गोगी 0135",
                "village_id": 23,
                "village_name": "गोगी"
            },
            {
                "label": "गोपालपुर 0129",
                "village_id": 24,
                "village_name": "गोपालपुर"
            },
            {
                "label": "घीवहा 0228",
                "village_id": 25,
                "village_name": "घीवहा"
            },
            {
                "label": "घोड़ाघाट 0067",
                "village_id": 26,
                "village_name": "घोड़ाघाट"
            },
            {
                "label": "जागीर कुल्हरिया 0117",
                "village_id": 27,
                "village_name": "जागीर कुल्हरिया"
            },
            {
                "label": "जागीर शहवाजपुर 0149",
                "village_id": 28,
                "village_name": "जागीर शहवाजपुर"
            },
            {
                "label": "जागीर हलहलिया 0032",
                "village_id": 29,
                "village_name": "जागीर हलहलिया"
            },
            {
                "label": "जोगबनी 0177",
                "village_id": 30,
                "village_name": "जोगबनी"
            },
            {
                "label": "डुमरिया 0164",
                "village_id": 31,
                "village_name": "डुमरिया"
            },
            {
                "label": "डुमरिया 0125",
                "village_id": 32,
                "village_name": "डुमरिया"
            },
            {
                "label": "डाक 0139",
                "village_id": 33,
                "village_name": "डाक"
            },
            {
                "label": "डाकहरिपुर 0012",
                "village_id": 34,
                "village_name": "डाकहरिपुर"
            },
            {
                "label": "डोरिया सोनापुर 0029",
                "village_id": 35,
                "village_name": "डोरिया सोनापुर"
            },
            {
                "label": "ढ़ोलबज्जा 0011",
                "village_id": 36,
                "village_name": "ढ़ोलबज्जा"
            },
            {
                "label": "तिरसकुण्ड 0045",
                "village_id": 37,
                "village_name": "तिरसकुण्ड"
            },
            {
                "label": "तीरा खारदह 0157",
                "village_id": 38,
                "village_name": "तीरा खारदह"
            },
            {
                "label": "थरिया वकिया 0028",
                "village_id": 39,
                "village_name": "थरिया वकिया"
            },
            {
                "label": "दक्षिण महेश्वरी 0175",
                "village_id": 40,
                "village_name": "दक्षिण महेश्वरी"
            },
            {
                "label": "देवपुरा 0036",
                "village_id": 41,
                "village_name": "देवपुरा"
            },
            {
                "label": "दामादिकी 0159",
                "village_id": 42,
                "village_name": "दामादिकी"
            },
            {
                "label": "दीपौल 0172",
                "village_id": 43,
                "village_name": "दीपौल"
            },
            {
                "label": "धनपुरा 0153",
                "village_id": 44,
                "village_name": "धनपुरा"
            },
            {
                "label": "धनपुरा खुर्द 0146",
                "village_id": 45,
                "village_name": "धनपुरा खुर्द"
            },
            {
                "label": "निज कुल्हरिया 0116",
                "village_id": 46,
                "village_name": "निज कुल्हरिया"
            },
            {
                "label": "नीरपुर 0173",
                "village_id": 47,
                "village_name": "नीरपुर"
            },
            {
                "label": "पछियारी झिरवा 0019",
                "village_id": 48,
                "village_name": "पछियारी झिरवा"
            },
            {
                "label": "पुरवारी झिरुआ 0021",
                "village_id": 49,
                "village_name": "पुरवारी झिरुआ"
            },
            {
                "label": "परवाहा 0140",
                "village_id": 50,
                "village_name": "परवाहा"
            },
            {
                "label": "परवाहा 0128",
                "village_id": 51,
                "village_name": "परवाहा"
            },
            {
                "label": "पीपरा 0156",
                "village_id": 52,
                "village_name": "पीपरा"
            },
            {
                "label": "पोखरिया 0119",
                "village_id": 53,
                "village_name": "पोखरिया"
            },
            {
                "label": "पोटरी 0126",
                "village_id": 54,
                "village_name": "पोटरी"
            },
            {
                "label": "पोठिया 136/",
                "village_id": 55,
                "village_name": "पोठिया"
            },
            {
                "label": "फेना 0144",
                "village_id": 56,
                "village_name": "फेना"
            },
            {
                "label": "बघुआ 0161",
                "village_id": 57,
                "village_name": "बघुआ"
            },
            {
                "label": "बैजनाथपुर 0160",
                "village_id": 58,
                "village_name": "बैजनाथपुर"
            },
            {
                "label": "बैजनाथपुर 0137",
                "village_id": 59,
                "village_name": "बैजनाथपुर"
            },
            {
                "label": "बथनाहा 0165",
                "village_id": 60,
                "village_name": "बथनाहा"
            },
            {
                "label": "बेलैय 0132",
                "village_id": 61,
                "village_name": "बेलैय"
            },
            {
                "label": "बलुवा 0040",
                "village_id": 62,
                "village_name": "बलुवा"
            },
            {
                "label": "बाघमारा 0107",
                "village_id": 63,
                "village_name": "बाघमारा"
            },
            {
                "label": "बाराटपुर 0162",
                "village_id": 64,
                "village_name": "बाराटपुर"
            },
            {
                "label": "बोकड़ा 0027",
                "village_id": 65,
                "village_name": "बोकड़ा"
            },
            {
                "label": "भट्टावाड़ी 0148",
                "village_id": 66,
                "village_name": "भट्टावाड़ी"
            },
            {
                "label": "भटियाही 0166",
                "village_id": 67,
                "village_name": "भटियाही"
            },
            {
                "label": "भेड़ियारी 0174",
                "village_id": 68,
                "village_name": "भेड़ियारी"
            },
            {
                "label": "भद्रेश्वर 0145",
                "village_id": 69,
                "village_name": "भद्रेश्वर"
            },
            {
                "label": "भवानीपुर 0170",
                "village_id": 70,
                "village_name": "भवानीपुर"
            },
            {
                "label": "भवानीपुर 0171",
                "village_id": 71,
                "village_name": "भवानीपुर"
            },
            {
                "label": "भाग कोहलिया 0152",
                "village_id": 72,
                "village_name": "भाग कोहलिया"
            },
            {
                "label": "भाग चौरा 0154",
                "village_id": 73,
                "village_name": "भाग चौरा"
            },
            {
                "label": "भाग परवाहा 0008",
                "village_id": 74,
                "village_name": "भाग परवाहा"
            },
            {
                "label": "भाग पोखर 0150",
                "village_id": 75,
                "village_name": "भाग पोखर"
            },
            {
                "label": "भाग हलहलिया 0033",
                "village_id": 76,
                "village_name": "भाग हलहलिया"
            },
            {
                "label": "मझुवा 0127",
                "village_id": 77,
                "village_name": "मझुवा"
            },
            {
                "label": "मटियारी 0147",
                "village_id": 78,
                "village_name": "मटियारी"
            },
            {
                "label": "मधुरा 0042",
                "village_id": 79,
                "village_name": "मधुरा"
            },
            {
                "label": "मुसहरी 0009",
                "village_id": 80,
                "village_name": "मुसहरी"
            },
            {
                "label": "महिचन्दा 0004",
                "village_id": 81,
                "village_name": "महिचन्दा"
            },
            {
                "label": "मानिकपुर हलहलिया 0031",
                "village_id": 82,
                "village_name": "मानिकपुर हलहलिया"
            },
            {
                "label": "मिर्जापुर 0005",
                "village_id": 83,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "मीरगंज 0169",
                "village_id": 84,
                "village_name": "मीरगंज"
            },
            {
                "label": "रंगदाहा 0110",
                "village_id": 85,
                "village_name": "रंगदाहा"
            },
            {
                "label": "रमई 0041",
                "village_id": 86,
                "village_name": "रमई"
            },
            {
                "label": "रहिकपुर 0037",
                "village_id": 87,
                "village_name": "रहिकपुर"
            },
            {
                "label": "रहिकपुर ढिलामोहन 0030",
                "village_id": 88,
                "village_name": "रहिकपुर ढिलामोहन"
            },
            {
                "label": "रामगंज 0121",
                "village_id": 89,
                "village_name": "रामगंज"
            },
            {
                "label": "रामपुर 0142",
                "village_id": 90,
                "village_name": "रामपुर"
            },
            {
                "label": "रामपुर 0017",
                "village_id": 91,
                "village_name": "रामपुर"
            },
            {
                "label": "लक्ष्मीपुर 0002",
                "village_id": 92,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लहशनगंज 0043",
                "village_id": 93,
                "village_name": "लहशनगंज"
            },
            {
                "label": "वसगड़ा 0018",
                "village_id": 94,
                "village_name": "वसगड़ा"
            },
            {
                "label": "वार्ड संख्या 1 0000",
                "village_id": 95,
                "village_name": "वार्ड संख्या 1"
            },
            {
                "label": "वार्ड संख्या. 10 0000",
                "village_id": 96,
                "village_name": "वार्ड संख्या. 10"
            },
            {
                "label": "वार्ड संख्या. 11 0000",
                "village_id": 97,
                "village_name": "वार्ड संख्या. 11"
            },
            {
                "label": "वार्ड संख्या. 12 0000",
                "village_id": 98,
                "village_name": "वार्ड संख्या. 12"
            },
            {
                "label": "वार्ड संख्या. 13 0000",
                "village_id": 99,
                "village_name": "वार्ड संख्या. 13"
            },
            {
                "label": "वार्ड संख्या. 14 0000",
                "village_id": 100,
                "village_name": "वार्ड संख्या. 14"
            },
            {
                "label": "वार्ड संख्या. 15 0000",
                "village_id": 101,
                "village_name": "वार्ड संख्या. 15"
            },
            {
                "label": "वार्ड संख़्या. 16 0000",
                "village_id": 102,
                "village_name": "वार्ड संख़्या. 16"
            },
            {
                "label": "वार्ड संख्या. 2 0000",
                "village_id": 103,
                "village_name": "वार्ड संख्या. 2"
            },
            {
                "label": "वार्ड संख्या. 3 0000",
                "village_id": 104,
                "village_name": "वार्ड संख्या. 3"
            },
            {
                "label": "वार्ड संखया. 4 0000",
                "village_id": 105,
                "village_name": "वार्ड संखया. 4"
            },
            {
                "label": "वार्ड संख्या. 5 0000",
                "village_id": 106,
                "village_name": "वार्ड संख्या. 5"
            },
            {
                "label": "वार्ड संख्या. 6 0000",
                "village_id": 107,
                "village_name": "वार्ड संख्या. 6"
            },
            {
                "label": "वार्ड संख्या. 7 0000",
                "village_id": 108,
                "village_name": "वार्ड संख्या. 7"
            },
            {
                "label": "वार्ड संख्या. 8 0000",
                "village_id": 109,
                "village_name": "वार्ड संख्या. 8"
            },
            {
                "label": "वार्ड संख्या. 9 0000",
                "village_id": 110,
                "village_name": "वार्ड संख्या. 9"
            },
            {
                "label": "वारा 0133",
                "village_id": 111,
                "village_name": "वारा"
            },
            {
                "label": "विनोदपुर 0130",
                "village_id": 112,
                "village_name": "विनोदपुर"
            },
            {
                "label": "विशनपुर 0138",
                "village_id": 113,
                "village_name": "विशनपुर"
            },
            {
                "label": "शंकरपुर 0007",
                "village_id": 114,
                "village_name": "शंकरपुर"
            },
            {
                "label": "शंकरपुर 0024",
                "village_id": 115,
                "village_name": "शंकरपुर"
            },
            {
                "label": "शहवाजपुर 0163",
                "village_id": 116,
                "village_name": "शहवाजपुर"
            },
            {
                "label": "सुखी 0015",
                "village_id": 117,
                "village_name": "सुखी"
            },
            {
                "label": "सुखीरामपुर 0010",
                "village_id": 118,
                "village_name": "सुखीरामपुर"
            },
            {
                "label": "सैफगंज 0006",
                "village_id": 119,
                "village_name": "सैफगंज"
            },
            {
                "label": "समौल 0046",
                "village_id": 120,
                "village_name": "समौल"
            },
            {
                "label": "सिमराहा 0134",
                "village_id": 121,
                "village_name": "सिमराहा"
            },
            {
                "label": "सिरसिया 0014",
                "village_id": 122,
                "village_name": "सिरसिया"
            },
            {
                "label": "हठेवा 0158",
                "village_id": 123,
                "village_name": "हठेवा"
            },
            {
                "label": "हरिपुर 0141",
                "village_id": 124,
                "village_name": "हरिपुर"
            },
            {
                "label": "हल्दिया 0026",
                "village_id": 125,
                "village_name": "हल्दिया"
            },
            {
                "label": "हलहलिया 0044",
                "village_id": 126,
                "village_name": "हलहलिया"
            },
            {
                "label": "हंशकोला 0155",
                "village_id": 127,
                "village_name": "हंशकोला"
            },
            {
                "label": "हंसकोषा खुर्द 0124",
                "village_id": 128,
                "village_name": "हंसकोषा खुर्द"
            },
            {
                "label": "हसनपुर खुर्द 0113",
                "village_id": 129,
                "village_name": "हसनपुर खुर्द"
            },
            {
                "label": "हाट पोठिया 136/",
                "village_id": 130,
                "village_name": "हाट पोठिया"
            }
        ],
        "block_id": "1",
        "block_no": 5,
        "block_name": "फारिबिसगंज [001]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "इकड़ा 0050",
                "village_id": 2,
                "village_name": "इकड़ा"
            },
            {
                "label": "इन्द्रपुर 0069",
                "village_id": 3,
                "village_name": "इन्द्रपुर"
            },
            {
                "label": "ऊफरैल 0072",
                "village_id": 4,
                "village_name": "ऊफरैल"
            },
            {
                "label": "कजरा 0060",
                "village_id": 5,
                "village_name": "कजरा"
            },
            {
                "label": "कदवा 0016",
                "village_id": 6,
                "village_name": "कदवा"
            },
            {
                "label": "कुपाड़ी 0009",
                "village_id": 7,
                "village_name": "कुपाड़ी"
            },
            {
                "label": "कमलपुर 61/2",
                "village_id": 8,
                "village_name": "कमलपुर"
            },
            {
                "label": "कमलपुर 61/2",
                "village_id": 9,
                "village_name": "कमलपुर"
            },
            {
                "label": "कुम्हरा 0040",
                "village_id": 10,
                "village_name": "कुम्हरा"
            },
            {
                "label": "करंकिया 0045",
                "village_id": 11,
                "village_name": "करंकिया"
            },
            {
                "label": "करहिया 0041",
                "village_id": 12,
                "village_name": "करहिया"
            },
            {
                "label": "काला बलुआ 0075",
                "village_id": 13,
                "village_name": "काला बलुआ"
            },
            {
                "label": "कोशकापुर 0148",
                "village_id": 14,
                "village_name": "कोशकापुर"
            },
            {
                "label": "कोहवारा विशनपुर 0074",
                "village_id": 15,
                "village_name": "कोहवारा विशनपुर"
            },
            {
                "label": "खरसाही 0086",
                "village_id": 16,
                "village_name": "खरसाही"
            },
            {
                "label": "खरहट 0011",
                "village_id": 17,
                "village_name": "खरहट"
            },
            {
                "label": "गढ़हा 0023",
                "village_id": 18,
                "village_name": "गढ़हा"
            },
            {
                "label": "गुणवन्ती 0056",
                "village_id": 19,
                "village_name": "गुणवन्ती"
            },
            {
                "label": "गिदवास 0057",
                "village_id": 20,
                "village_name": "गिदवास"
            },
            {
                "label": "गोपालपुर 0145",
                "village_id": 21,
                "village_name": "गोपालपुर"
            },
            {
                "label": "घघरी 0039",
                "village_id": 22,
                "village_name": "घघरी"
            },
            {
                "label": "छतियोना 0024",
                "village_id": 23,
                "village_name": "छतियोना"
            },
            {
                "label": "जगता 0087",
                "village_id": 24,
                "village_name": "जगता"
            },
            {
                "label": "डुमरा 0048",
                "village_id": 25,
                "village_name": "डुमरा"
            },
            {
                "label": "डाढा भीठा 0030",
                "village_id": 26,
                "village_name": "डाढा भीठा"
            },
            {
                "label": "डोरियारे 0033",
                "village_id": 27,
                "village_name": "डोरियारे"
            },
            {
                "label": "तमघट्टी 0071",
                "village_id": 28,
                "village_name": "तमघट्टी"
            },
            {
                "label": "देवस्थल 0038",
                "village_id": 29,
                "village_name": "देवस्थल"
            },
            {
                "label": "धनियाईन 0014",
                "village_id": 30,
                "village_name": "धनियाईन"
            },
            {
                "label": "धामा 0019",
                "village_id": 31,
                "village_name": "धामा"
            },
            {
                "label": "धोविनियां 0044",
                "village_id": 32,
                "village_name": "धोविनियां"
            },
            {
                "label": "नन्दनपुर 0036",
                "village_id": 33,
                "village_name": "नन्दनपुर"
            },
            {
                "label": "नारायणपुर 0012",
                "village_id": 34,
                "village_name": "नारायणपुर"
            },
            {
                "label": "पचीरा 0059",
                "village_id": 35,
                "village_name": "पचीरा"
            },
            {
                "label": "परमानन्दपुर 0006",
                "village_id": 36,
                "village_name": "परमानन्दपुर"
            },
            {
                "label": "परसाहट 0080",
                "village_id": 37,
                "village_name": "परसाहट"
            },
            {
                "label": "परिहारी 0130",
                "village_id": 38,
                "village_name": "परिहारी"
            },
            {
                "label": "पहसरा 0070",
                "village_id": 39,
                "village_name": "पहसरा"
            },
            {
                "label": "पाही अहमदपुर 0015",
                "village_id": 40,
                "village_name": "पाही अहमदपुर"
            },
            {
                "label": "फरकिया 0051",
                "village_id": 41,
                "village_name": "फरकिया"
            },
            {
                "label": "बगुलाहा 0079",
                "village_id": 42,
                "village_name": "बगुलाहा"
            },
            {
                "label": "बैजनाथपुर 0076",
                "village_id": 43,
                "village_name": "बैजनाथपुर"
            },
            {
                "label": "बड़हारा 0077",
                "village_id": 44,
                "village_name": "बड़हारा"
            },
            {
                "label": "बढ़ेपारा 0068",
                "village_id": 45,
                "village_name": "बढ़ेपारा"
            },
            {
                "label": "बेतौना 0025",
                "village_id": 46,
                "village_name": "बेतौना"
            },
            {
                "label": "बनभाग 0005",
                "village_id": 47,
                "village_name": "बनभाग"
            },
            {
                "label": "बरबन्ना 0065",
                "village_id": 48,
                "village_name": "बरबन्ना"
            },
            {
                "label": "बेलवा 0059",
                "village_id": 49,
                "village_name": "बेलवा"
            },
            {
                "label": "बेलसरा 0129",
                "village_id": 50,
                "village_name": "बेलसरा"
            },
            {
                "label": "बसगाड़ी 0013",
                "village_id": 51,
                "village_name": "बसगाड़ी"
            },
            {
                "label": "बसैठी 0054",
                "village_id": 52,
                "village_name": "बसैठी"
            },
            {
                "label": "बाड़ी लतहारी 0066",
                "village_id": 53,
                "village_name": "बाड़ी लतहारी"
            },
            {
                "label": "बिस्टोरिया 0002",
                "village_id": 54,
                "village_name": "बिस्टोरिया"
            },
            {
                "label": "बौंसी 0052",
                "village_id": 55,
                "village_name": "बौंसी"
            },
            {
                "label": "भरैली 0043",
                "village_id": 56,
                "village_name": "भरैली"
            },
            {
                "label": "भवानी नगर 0053",
                "village_id": 57,
                "village_name": "भवानी नगर"
            },
            {
                "label": "भाग फलिया 0001",
                "village_id": 58,
                "village_name": "भाग फलिया"
            },
            {
                "label": "भोरहा 0067",
                "village_id": 59,
                "village_name": "भोरहा"
            },
            {
                "label": "भोलापुर 0008",
                "village_id": 60,
                "village_name": "भोलापुर"
            },
            {
                "label": "मझुआ 0073",
                "village_id": 61,
                "village_name": "मझुआ"
            },
            {
                "label": "मटियारी 0017",
                "village_id": 62,
                "village_name": "मटियारी"
            },
            {
                "label": "मठ बसैठी 0055",
                "village_id": 63,
                "village_name": "मठ बसैठी"
            },
            {
                "label": "मधेपुरा 0146",
                "village_id": 64,
                "village_name": "मधेपुरा"
            },
            {
                "label": "मधेपुरा 0147",
                "village_id": 65,
                "village_name": "मधेपुरा"
            },
            {
                "label": "महसैली 0028",
                "village_id": 66,
                "village_name": "महसैली"
            },
            {
                "label": "महसैली गिदवास 0273",
                "village_id": 67,
                "village_name": "महसैली गिदवास"
            },
            {
                "label": "मिर्जापुर 0034",
                "village_id": 68,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "मिर्जापुर 0037",
                "village_id": 69,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "मोहनी 0042",
                "village_id": 70,
                "village_name": "मोहनी"
            },
            {
                "label": "रघुनाथपुर 0020",
                "village_id": 71,
                "village_name": "रघुनाथपुर"
            },
            {
                "label": "रजवेली 0029",
                "village_id": 72,
                "village_name": "रजवेली"
            },
            {
                "label": "रूपैली 0022",
                "village_id": 73,
                "village_name": "रूपैली"
            },
            {
                "label": "रेहुआ 0026",
                "village_id": 74,
                "village_name": "रेहुआ"
            },
            {
                "label": "राधोपुर 0003",
                "village_id": 75,
                "village_name": "राधोपुर"
            },
            {
                "label": "रानीगंज 0078",
                "village_id": 76,
                "village_name": "रानीगंज"
            },
            {
                "label": "रामपुर 0062",
                "village_id": 77,
                "village_name": "रामपुर"
            },
            {
                "label": "लक्ष्मीपुर 0010",
                "village_id": 78,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लतहारी 0047",
                "village_id": 79,
                "village_name": "लतहारी"
            },
            {
                "label": "वेलगाछी 0063",
                "village_id": 80,
                "village_name": "वेलगाछी"
            },
            {
                "label": "विनोदपुर 0035",
                "village_id": 81,
                "village_name": "विनोदपुर"
            },
            {
                "label": "विशनपुर 0004",
                "village_id": 82,
                "village_name": "विशनपुर"
            },
            {
                "label": "सकरैली 0032",
                "village_id": 83,
                "village_name": "सकरैली"
            },
            {
                "label": "संझैली 0021",
                "village_id": 84,
                "village_name": "संझैली"
            },
            {
                "label": "सैदपुर 0046",
                "village_id": 85,
                "village_name": "सैदपुर"
            },
            {
                "label": "हृदयपुर 0018",
                "village_id": 86,
                "village_name": "हृदयपुर"
            },
            {
                "label": "हरपुर 0031",
                "village_id": 87,
                "village_name": "हरपुर"
            },
            {
                "label": "हसनपुर 0064",
                "village_id": 88,
                "village_name": "हसनपुर"
            },
            {
                "label": "हांसा 0058",
                "village_id": 89,
                "village_name": "हांसा"
            },
            {
                "label": "हिंगना 0007",
                "village_id": 90,
                "village_name": "हिंगना"
            }
        ],
        "block_id": "2",
        "block_no": 6,
        "block_name": "रानीगंज [002]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अचरा 0203",
                "village_id": 2,
                "village_name": "अचरा"
            },
            {
                "label": "अजीत नगर 0235",
                "village_id": 3,
                "village_name": "अजीत नगर"
            },
            {
                "label": "अमरौरी 0199",
                "village_id": 4,
                "village_name": "अमरौरी"
            },
            {
                "label": "कन्हैली 0224",
                "village_id": 5,
                "village_name": "कन्हैली"
            },
            {
                "label": "कामत पथराहा 0195",
                "village_id": 6,
                "village_name": "कामत पथराहा"
            },
            {
                "label": "कोचगामा 0179",
                "village_id": 7,
                "village_name": "कोचगामा"
            },
            {
                "label": "कोचगामा 0236",
                "village_id": 8,
                "village_name": "कोचगामा"
            },
            {
                "label": "कोराकापुर 0215",
                "village_id": 9,
                "village_name": "कोराकापुर"
            },
            {
                "label": "खैरा 0217",
                "village_id": 10,
                "village_name": "खैरा"
            },
            {
                "label": "खाबदह 0225",
                "village_id": 11,
                "village_name": "खाबदह"
            },
            {
                "label": "गुआर पुछड़ी 0181",
                "village_id": 12,
                "village_name": "गुआर पुछड़ी"
            },
            {
                "label": "गढ़गामा 0229",
                "village_id": 13,
                "village_name": "गढ़गामा"
            },
            {
                "label": "गोखलापुर 0234",
                "village_id": 14,
                "village_name": "गोखलापुर"
            },
            {
                "label": "गोड़राहा विशनपुर 0231",
                "village_id": 15,
                "village_name": "गोड़राहा विशनपुर"
            },
            {
                "label": "घुरना 0188",
                "village_id": 16,
                "village_name": "घुरना"
            },
            {
                "label": "चकौड़वा 0183",
                "village_id": 17,
                "village_name": "चकौड़वा"
            },
            {
                "label": "डूमराबन्ना 0190",
                "village_id": 18,
                "village_name": "डूमराबन्ना"
            },
            {
                "label": "डुमरिया 0202",
                "village_id": 19,
                "village_name": "डुमरिया"
            },
            {
                "label": "डिमिया 0212",
                "village_id": 20,
                "village_name": "डिमिया"
            },
            {
                "label": "तामगंज 0232",
                "village_id": 21,
                "village_name": "तामगंज"
            },
            {
                "label": "तोप नवावगंज 0200",
                "village_id": 22,
                "village_name": "तोप नवावगंज"
            },
            {
                "label": "तौफीर तामगंज 0233",
                "village_id": 23,
                "village_name": "तौफीर तामगंज"
            },
            {
                "label": "दरगाही गंज 0220",
                "village_id": 24,
                "village_name": "दरगाही गंज"
            },
            {
                "label": "देवीगंज 0221",
                "village_id": 25,
                "village_name": "देवीगंज"
            },
            {
                "label": "धरहरा 0205",
                "village_id": 26,
                "village_name": "धरहरा"
            },
            {
                "label": "नवावगंज 0185",
                "village_id": 27,
                "village_name": "नवावगंज"
            },
            {
                "label": "नाथपुर 0209",
                "village_id": 28,
                "village_name": "नाथपुर"
            },
            {
                "label": "नाथपुर एराजी 209/",
                "village_id": 29,
                "village_name": "नाथपुर एराजी"
            },
            {
                "label": "पथराहा 0187",
                "village_id": 30,
                "village_name": "पथराहा"
            },
            {
                "label": "पथराहा 0222",
                "village_id": 31,
                "village_name": "पथराहा"
            },
            {
                "label": "पलासी 0143",
                "village_id": 32,
                "village_name": "पलासी"
            },
            {
                "label": "पिठौरा 0214",
                "village_id": 33,
                "village_name": "पिठौरा"
            },
            {
                "label": "पोखरिया 0180",
                "village_id": 34,
                "village_name": "पोखरिया"
            },
            {
                "label": "पोसदाहा 0217",
                "village_id": 35,
                "village_name": "पोसदाहा"
            },
            {
                "label": "फतेहपुर 213/",
                "village_id": 36,
                "village_name": "फतेहपुर"
            },
            {
                "label": "फतेहपुर एराजी 213/",
                "village_id": 37,
                "village_name": "फतेहपुर एराजी"
            },
            {
                "label": "फरही 0223",
                "village_id": 38,
                "village_name": "फरही"
            },
            {
                "label": "बड़हारा 0211",
                "village_id": 39,
                "village_name": "बड़हारा"
            },
            {
                "label": "बढ़ेपारा 0219",
                "village_id": 40,
                "village_name": "बढ़ेपारा"
            },
            {
                "label": "बबुआन 0194",
                "village_id": 41,
                "village_name": "बबुआन"
            },
            {
                "label": "बरदाहा 0210",
                "village_id": 42,
                "village_name": "बरदाहा"
            },
            {
                "label": "बरियारपुर 0230",
                "village_id": 43,
                "village_name": "बरियारपुर"
            },
            {
                "label": "बलुआ 0239",
                "village_id": 44,
                "village_name": "बलुआ"
            },
            {
                "label": "बेलसंड़ी 0237",
                "village_id": 45,
                "village_name": "बेलसंड़ी"
            },
            {
                "label": "बेला 0193",
                "village_id": 46,
                "village_name": "बेला"
            },
            {
                "label": "बसमतिया 0191",
                "village_id": 47,
                "village_name": "बसमतिया"
            },
            {
                "label": "भंगही 0218",
                "village_id": 48,
                "village_name": "भंगही"
            },
            {
                "label": "भेड़वार 0198",
                "village_id": 49,
                "village_name": "भेड़वार"
            },
            {
                "label": "भेड़वार वरहमोतरा 0197",
                "village_id": 50,
                "village_name": "भेड़वार वरहमोतरा"
            },
            {
                "label": "भेरोगंज 0192",
                "village_id": 51,
                "village_name": "भेरोगंज"
            },
            {
                "label": "भवानीपुर 0196",
                "village_id": 52,
                "village_name": "भवानीपुर"
            },
            {
                "label": "भोड़हर 0184",
                "village_id": 53,
                "village_name": "भोड़हर"
            },
            {
                "label": "मधुबनी 0178",
                "village_id": 54,
                "village_name": "मधुबनी"
            },
            {
                "label": "मधुरा 0208",
                "village_id": 55,
                "village_name": "मधुरा"
            },
            {
                "label": "महेशपट्टी 0189",
                "village_id": 56,
                "village_name": "महेशपट्टी"
            },
            {
                "label": "मानिकपुर 0186",
                "village_id": 57,
                "village_name": "मानिकपुर"
            },
            {
                "label": "मिरदौल 0238",
                "village_id": 58,
                "village_name": "मिरदौल"
            },
            {
                "label": "रेवाही 0226",
                "village_id": 59,
                "village_name": "रेवाही"
            },
            {
                "label": "राजगंज 0207",
                "village_id": 60,
                "village_name": "राजगंज"
            },
            {
                "label": "लक्ष्मीपुर 0201",
                "village_id": 61,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "श्यामनगर 0167",
                "village_id": 62,
                "village_name": "श्यामनगर"
            },
            {
                "label": "स्वालदह 0227",
                "village_id": 63,
                "village_name": "स्वालदह"
            },
            {
                "label": "साहेबगंज 0206",
                "village_id": 64,
                "village_name": "साहेबगंज"
            },
            {
                "label": "सोनापुर 0182",
                "village_id": 65,
                "village_name": "सोनापुर"
            },
            {
                "label": "हनुमान नगर 0204",
                "village_id": 66,
                "village_name": "हनुमान नगर"
            }
        ],
        "block_id": "3",
        "block_no": 7,
        "block_name": "नरपतगंज [003]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अकर थापा 0115",
                "village_id": 2,
                "village_name": "अकर थापा"
            },
            {
                "label": "कुशमौल 0091",
                "village_id": 3,
                "village_name": "कुशमौल"
            },
            {
                "label": "खजुरी 0104",
                "village_id": 4,
                "village_name": "खजुरी"
            },
            {
                "label": "खजुरी 0106",
                "village_id": 5,
                "village_name": "खजुरी"
            },
            {
                "label": "खुटहा वैजनाथ 0109",
                "village_id": 6,
                "village_name": "खुटहा वैजनाथ"
            },
            {
                "label": "गम्हरिया 0101",
                "village_id": 7,
                "village_name": "गम्हरिया"
            },
            {
                "label": "गम्हरिया मिलिक 0102",
                "village_id": 8,
                "village_name": "गम्हरिया मिलिक"
            },
            {
                "label": "गोपीपुर 0141",
                "village_id": 9,
                "village_name": "गोपीपुर"
            },
            {
                "label": "चरैया 0112",
                "village_id": 10,
                "village_name": "चरैया"
            },
            {
                "label": "चांदनी घासी 0125",
                "village_id": 11,
                "village_name": "चांदनी घासी"
            },
            {
                "label": "चांदनी घासी 0126",
                "village_id": 12,
                "village_name": "चांदनी घासी"
            },
            {
                "label": "चांदनी घासी 0128",
                "village_id": 13,
                "village_name": "चांदनी घासी"
            },
            {
                "label": "चौरा 0084",
                "village_id": 14,
                "village_name": "चौरा"
            },
            {
                "label": "जयनगर 0093",
                "village_id": 15,
                "village_name": "जयनगर"
            },
            {
                "label": "झुमकलाल चकला 0098",
                "village_id": 16,
                "village_name": "झुमकलाल चकला"
            },
            {
                "label": "टेंगराहा 111/",
                "village_id": 17,
                "village_name": "टेंगराहा"
            },
            {
                "label": "तोनहा ईसराईन 0124",
                "village_id": 18,
                "village_name": "तोनहा ईसराईन"
            },
            {
                "label": "दुन्द बहादुर चकला 0099",
                "village_id": 19,
                "village_name": "दुन्द बहादुर चकला"
            },
            {
                "label": "धनेश्वरी 122/",
                "village_id": 20,
                "village_name": "धनेश्वरी"
            },
            {
                "label": "धनेश्वरी 122/",
                "village_id": 21,
                "village_name": "धनेश्वरी"
            },
            {
                "label": "धनेश्वरी मिलिक 0123",
                "village_id": 22,
                "village_name": "धनेश्वरी मिलिक"
            },
            {
                "label": "धरूआ पट्टी 0108",
                "village_id": 23,
                "village_name": "धरूआ पट्टी"
            },
            {
                "label": "नदवा दियरा 0095",
                "village_id": 24,
                "village_name": "नदवा दियरा"
            },
            {
                "label": "नया भरगामा 0120",
                "village_id": 25,
                "village_name": "नया भरगामा"
            },
            {
                "label": "नवलगंज नैनपुर 0149",
                "village_id": 26,
                "village_name": "नवलगंज नैनपुर"
            },
            {
                "label": "नवलगंज पीताम्बर 0113",
                "village_id": 27,
                "village_name": "नवलगंज पीताम्बर"
            },
            {
                "label": "पैकपार 0103",
                "village_id": 28,
                "village_name": "पैकपार"
            },
            {
                "label": "पट्टी प्राणपत 0092",
                "village_id": 29,
                "village_name": "पट्टी प्राणपत"
            },
            {
                "label": "प्यारेलाल चकला 0116",
                "village_id": 30,
                "village_name": "प्यारेलाल चकला"
            },
            {
                "label": "बैजुपट्टी 114/",
                "village_id": 31,
                "village_name": "बैजुपट्टी"
            },
            {
                "label": "बैजुपट्टी 0127",
                "village_id": 32,
                "village_name": "बैजुपट्टी"
            },
            {
                "label": "बरमोत्तरा चकला 0105",
                "village_id": 33,
                "village_name": "बरमोत्तरा चकला"
            },
            {
                "label": "बरमोतरा 0142",
                "village_id": 34,
                "village_name": "बरमोतरा"
            },
            {
                "label": "भईया राम विशनपुर 0100",
                "village_id": 35,
                "village_name": "भईया राम विशनपुर"
            },
            {
                "label": "भरगामा 0085",
                "village_id": 36,
                "village_name": "भरगामा"
            },
            {
                "label": "भरगामा कामत 111/",
                "village_id": 37,
                "village_name": "भरगामा कामत"
            },
            {
                "label": "भरगामा चकला 0132",
                "village_id": 38,
                "village_name": "भरगामा चकला"
            },
            {
                "label": "मनरही चकला 0119",
                "village_id": 39,
                "village_name": "मनरही चकला"
            },
            {
                "label": "मनुलहपट्टी 0081",
                "village_id": 40,
                "village_name": "मनुलहपट्टी"
            },
            {
                "label": "मेवालाल चकला 0117",
                "village_id": 41,
                "village_name": "मेवालाल चकला"
            },
            {
                "label": "यशोदापुर 0134",
                "village_id": 42,
                "village_name": "यशोदापुर"
            },
            {
                "label": "रघुनाथपुर 0096",
                "village_id": 43,
                "village_name": "रघुनाथपुर"
            },
            {
                "label": "रामपुर आदि 0082",
                "village_id": 44,
                "village_name": "रामपुर आदि"
            },
            {
                "label": "लक्ष्मीपुर 0133",
                "village_id": 45,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लक्ष्मीपुर वग्घा 0107",
                "village_id": 46,
                "village_name": "लक्ष्मीपुर वग्घा"
            },
            {
                "label": "वैजुपट्टी 114/",
                "village_id": 47,
                "village_name": "वैजुपट्टी"
            },
            {
                "label": "वरमोतरा हिगंवा 0143",
                "village_id": 48,
                "village_name": "वरमोतरा हिगंवा"
            },
            {
                "label": "विषहरिया 118/",
                "village_id": 49,
                "village_name": "विषहरिया"
            },
            {
                "label": "विषहरिया पट्टी 118/",
                "village_id": 50,
                "village_name": "विषहरिया पट्टी"
            },
            {
                "label": "वीरनगर 111/",
                "village_id": 51,
                "village_name": "वीरनगर"
            },
            {
                "label": "वीरनगर मिलिक 111/",
                "village_id": 52,
                "village_name": "वीरनगर मिलिक"
            },
            {
                "label": "वीरनगर मिलिक 0131",
                "village_id": 53,
                "village_name": "वीरनगर मिलिक"
            },
            {
                "label": "शंकरपुर 0090",
                "village_id": 54,
                "village_name": "शंकरपुर"
            },
            {
                "label": "शेखपुरा 0088",
                "village_id": 55,
                "village_name": "शेखपुरा"
            },
            {
                "label": "सुकैला 0083",
                "village_id": 56,
                "village_name": "सुकैला"
            },
            {
                "label": "सिमलबनी 0089",
                "village_id": 57,
                "village_name": "सिमलबनी"
            },
            {
                "label": "सिरसिया कलां 0097",
                "village_id": 58,
                "village_name": "सिरसिया कलां"
            },
            {
                "label": "सिरसिया हनुमानगंज 0094",
                "village_id": 59,
                "village_name": "सिरसिया हनुमानगंज"
            },
            {
                "label": "हरिपुर कला 0138",
                "village_id": 60,
                "village_name": "हरिपुर कला"
            },
            {
                "label": "हरिपुर कलां 0140",
                "village_id": 61,
                "village_name": "हरिपुर कलां"
            },
            {
                "label": "हारीवाला 0121",
                "village_id": 62,
                "village_name": "हारीवाला"
            },
            {
                "label": "हिगंवा 0137",
                "village_id": 63,
                "village_name": "हिगंवा"
            },
            {
                "label": "हिगंवा गोठ 135/",
                "village_id": 64,
                "village_name": "हिगंवा गोठ"
            },
            {
                "label": "हिंगवा चकला 0139",
                "village_id": 65,
                "village_name": "हिंगवा चकला"
            },
            {
                "label": "हिगंवा पट्टी 135/",
                "village_id": 66,
                "village_name": "हिगंवा पट्टी"
            },
            {
                "label": "हिंगवा मिलिक 0136",
                "village_id": 67,
                "village_name": "हिंगवा मिलिक"
            },
            {
                "label": "हिंगवा मिलिक 0144",
                "village_id": 68,
                "village_name": "हिंगवा मिलिक"
            }
        ],
        "block_id": "4",
        "block_no": 8,
        "block_name": "भरगामा [004]",
        "sub_div_code": "2"
    }
],
    "district_id": "07",
    "district_name": "अररिया"
},
{
    "block": [
    {
        "village": [
            {
                "label": "अमानत उत्तरवाड़ी 0068",
                "village_id": 2,
                "village_name": "अमानत उत्तरवाड़ी"
            },
            {
                "label": "अमानत दक्षिणवाड़ी 0069",
                "village_id": 3,
                "village_name": "अमानत दक्षिणवाड़ी"
            },
            {
                "label": "खोपैती 0083",
                "village_id": 4,
                "village_name": "खोपैती"
            },
            {
                "label": "गढिया 0087",
                "village_id": 5,
                "village_name": "गढिया"
            },
            {
                "label": "गढ़िया 0087",
                "village_id": 6,
                "village_name": "गढ़िया"
            },
            {
                "label": "गैर स्थान 90/2",
                "village_id": 7,
                "village_name": "गैर स्थान"
            },
            {
                "label": "गोठ खोपैती 0082",
                "village_id": 8,
                "village_name": "गोठ खोपैती"
            },
            {
                "label": "गोढ़ खोपेती 0082",
                "village_id": 9,
                "village_name": "गोढ़ खोपेती"
            },
            {
                "label": "गोढ़ैला 0195",
                "village_id": 10,
                "village_name": "गोढ़ैला"
            },
            {
                "label": "गोढियारी 0059",
                "village_id": 11,
                "village_name": "गोढियारी"
            },
            {
                "label": "चकला लक्ष्मीरामपुर उत्तर 0070",
                "village_id": 12,
                "village_name": "चकला लक्ष्मीरामपुर उत्तर"
            },
            {
                "label": "चौड़ा 0249",
                "village_id": 13,
                "village_name": "चौड़ा"
            },
            {
                "label": "चौरा 0249",
                "village_id": 14,
                "village_name": "चौरा"
            },
            {
                "label": "तुनियाही उत्तरवाड़ी 0067",
                "village_id": 15,
                "village_name": "तुनियाही उत्तरवाड़ी"
            },
            {
                "label": "तुनियाही दक्षिणवाड़ी 0072",
                "village_id": 16,
                "village_name": "तुनियाही दक्षिणवाड़ी"
            },
            {
                "label": "दुबियाही 0053",
                "village_id": 17,
                "village_name": "दुबियाही"
            },
            {
                "label": "दुवियाही 0053",
                "village_id": 18,
                "village_name": "दुवियाही"
            },
            {
                "label": "धुरगाँव 0251",
                "village_id": 19,
                "village_name": "धुरगाँव"
            },
            {
                "label": "धुरगाँव 0251",
                "village_id": 20,
                "village_name": "धुरगाँव"
            },
            {
                "label": "पड़रिया 0193",
                "village_id": 21,
                "village_name": "पड़रिया"
            },
            {
                "label": "पिठाही 0055",
                "village_id": 22,
                "village_name": "पिठाही"
            },
            {
                "label": "बखरी 0250",
                "village_id": 23,
                "village_name": "बखरी"
            },
            {
                "label": "बखरी 0250",
                "village_id": 24,
                "village_name": "बखरी"
            },
            {
                "label": "बेतौना 0197",
                "village_id": 25,
                "village_name": "बेतौना"
            },
            {
                "label": "बुधमा 0191",
                "village_id": 26,
                "village_name": "बुधमा"
            },
            {
                "label": "बुनियादसिंह चकला 0080",
                "village_id": 27,
                "village_name": "बुनियादसिंह चकला"
            },
            {
                "label": "बराही 0029",
                "village_id": 28,
                "village_name": "बराही"
            },
            {
                "label": "बराही 0029",
                "village_id": 29,
                "village_name": "बराही"
            },
            {
                "label": "बहादुरसिंह चकला उत्तर 0078",
                "village_id": 30,
                "village_name": "बहादुरसिंह चकला उत्तर"
            },
            {
                "label": "बाध खोपैती 0077",
                "village_id": 31,
                "village_name": "बाध खोपैती"
            },
            {
                "label": "बालम 0086",
                "village_id": 32,
                "village_name": "बालम"
            },
            {
                "label": "बालम 0086",
                "village_id": 33,
                "village_name": "बालम"
            },
            {
                "label": "भदौल 0192",
                "village_id": 34,
                "village_name": "भदौल"
            },
            {
                "label": "भेलवा 0058",
                "village_id": 35,
                "village_name": "भेलवा"
            },
            {
                "label": "भिरखी उत्तरवाड़ी 0065",
                "village_id": 36,
                "village_name": "भिरखी उत्तरवाड़ी"
            },
            {
                "label": "भीरखी दक्षिणवाड़ी 0066",
                "village_id": 37,
                "village_name": "भीरखी दक्षिणवाड़ी"
            },
            {
                "label": "मछबखरा 0194",
                "village_id": 38,
                "village_name": "मछबखरा"
            },
            {
                "label": "मठाही 0054",
                "village_id": 39,
                "village_name": "मठाही"
            },
            {
                "label": "मदनपुर 0064",
                "village_id": 40,
                "village_name": "मदनपुर"
            },
            {
                "label": "मधेपुरा 0061",
                "village_id": 41,
                "village_name": "मधेपुरा"
            },
            {
                "label": "मधुबन 0063",
                "village_id": 42,
                "village_name": "मधुबन"
            },
            {
                "label": "मुरबल्ला उत्तर 0084",
                "village_id": 43,
                "village_name": "मुरबल्ला उत्तर"
            },
            {
                "label": "मुरबल्ला दक्षिण 0085",
                "village_id": 44,
                "village_name": "मुरबल्ला दक्षिण"
            },
            {
                "label": "मुरहो 0180",
                "village_id": 45,
                "village_name": "मुरहो"
            },
            {
                "label": "मुरहो लखराज 0181",
                "village_id": 46,
                "village_name": "मुरहो लखराज"
            },
            {
                "label": "महेशुआ 0198",
                "village_id": 47,
                "village_name": "महेशुआ"
            },
            {
                "label": "महेशुआ 0198",
                "village_id": 48,
                "village_name": "महेशुआ"
            },
            {
                "label": "मानिकपुर 0062",
                "village_id": 49,
                "village_name": "मानिकपुर"
            },
            {
                "label": "मिठाही 0054",
                "village_id": 50,
                "village_name": "मिठाही"
            },
            {
                "label": "लक्ष्मीपुर उत्तर चकला 0070",
                "village_id": 51,
                "village_name": "लक्ष्मीपुर उत्तर चकला"
            },
            {
                "label": "लक्ष्मीपुर दक्षिण चकला 0071",
                "village_id": 52,
                "village_name": "लक्ष्मीपुर दक्षिण चकला"
            },
            {
                "label": "वहादुर सिंह चकला दक्षिण 0079",
                "village_id": 53,
                "village_name": "वहादुर सिंह चकला दक्षिण"
            },
            {
                "label": "वाढ़ खोपेती 0077",
                "village_id": 54,
                "village_name": "वाढ़ खोपेती"
            },
            {
                "label": "श्रीपुर उत्तरवाड़ी 0074",
                "village_id": 55,
                "village_name": "श्रीपुर उत्तरवाड़ी"
            },
            {
                "label": "श्रीपुर उत्तरवाड़ी 0074",
                "village_id": 56,
                "village_name": "श्रीपुर उत्तरवाड़ी"
            },
            {
                "label": "श्रीपुर दक्षिणवाड़ी 0075",
                "village_id": 57,
                "village_name": "श्रीपुर दक्षिणवाड़ी"
            },
            {
                "label": "सकरपुरा 0196",
                "village_id": 58,
                "village_name": "सकरपुरा"
            },
            {
                "label": "सुखासन उत्तरवाड़ी 0073",
                "village_id": 59,
                "village_name": "सुखासन उत्तरवाड़ी"
            },
            {
                "label": "सुखासन दक्षिणवाड़ी 0076",
                "village_id": 60,
                "village_name": "सुखासन दक्षिणवाड़ी"
            },
            {
                "label": "साहुगढ़ 0060",
                "village_id": 61,
                "village_name": "साहुगढ़"
            },
            {
                "label": "हनुमाननगर चकला 0081",
                "village_id": 62,
                "village_name": "हनुमाननगर चकला"
            }
        ],
        "block_id": "1",
        "block_no": 0,
        "block_name": "मधेपुरा [001]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "कन्हुआ गोढ़ियारी 0002",
                "village_id": 2,
                "village_name": "कन्हुआ गोढ़ियारी"
            },
            {
                "label": "कन्हुआ गोढ़ियारी 0002",
                "village_id": 3,
                "village_name": "कन्हुआ गोढ़ियारी"
            },
            {
                "label": "कमरगामा 0012",
                "village_id": 4,
                "village_name": "कमरगामा"
            },
            {
                "label": "गुरुतारा 0209",
                "village_id": 5,
                "village_name": "गुरुतारा"
            },
            {
                "label": "गेहुमन्नी 0199",
                "village_id": 6,
                "village_name": "गेहुमन्नी"
            },
            {
                "label": "गोपालपुर 0011",
                "village_id": 7,
                "village_name": "गोपालपुर"
            },
            {
                "label": "गोपालपुर 0011",
                "village_id": 8,
                "village_name": "गोपालपुर"
            },
            {
                "label": "गौरीपुर 0203",
                "village_id": 9,
                "village_name": "गौरीपुर"
            },
            {
                "label": "जजहट 0200",
                "village_id": 10,
                "village_name": "जजहट"
            },
            {
                "label": "जलुआर 0003",
                "village_id": 11,
                "village_name": "जलुआर"
            },
            {
                "label": "जलुवार 0003",
                "village_id": 12,
                "village_name": "जलुवार"
            },
            {
                "label": "जोगवनी 0023",
                "village_id": 13,
                "village_name": "जोगवनी"
            },
            {
                "label": "डंडारी 0014",
                "village_id": 14,
                "village_name": "डंडारी"
            },
            {
                "label": "डंडारी 0014",
                "village_id": 15,
                "village_name": "डंडारी"
            },
            {
                "label": "दुलार 0017",
                "village_id": 16,
                "village_name": "दुलार"
            },
            {
                "label": "पटौरी 0205",
                "village_id": 17,
                "village_name": "पटौरी"
            },
            {
                "label": "पिपराही 0007",
                "village_id": 18,
                "village_name": "पिपराही"
            },
            {
                "label": "बड़हरी 0013",
                "village_id": 19,
                "village_name": "बड़हरी"
            },
            {
                "label": "बैरबन्ना 0016",
                "village_id": 20,
                "village_name": "बैरबन्ना"
            },
            {
                "label": "मजरहट 0201",
                "village_id": 21,
                "village_name": "मजरहट"
            },
            {
                "label": "मानपुर 0009",
                "village_id": 22,
                "village_name": "मानपुर"
            },
            {
                "label": "मानपुर 0009",
                "village_id": 23,
                "village_name": "मानपुर"
            },
            {
                "label": "मोहली 0010",
                "village_id": 24,
                "village_name": "मोहली"
            },
            {
                "label": "मोहली 0010",
                "village_id": 25,
                "village_name": "मोहली"
            },
            {
                "label": "रुपौली 0022",
                "village_id": 26,
                "village_name": "रुपौली"
            },
            {
                "label": "रुपौली 0022",
                "village_id": 27,
                "village_name": "रुपौली"
            },
            {
                "label": "रामपट्टी 0020",
                "village_id": 28,
                "village_name": "रामपट्टी"
            },
            {
                "label": "रामपुर 0202",
                "village_id": 29,
                "village_name": "रामपुर"
            },
            {
                "label": "लतराही 0008",
                "village_id": 30,
                "village_name": "लतराही"
            },
            {
                "label": "लालपुर 0208",
                "village_id": 31,
                "village_name": "लालपुर"
            },
            {
                "label": "वड़हरी 0013",
                "village_id": 32,
                "village_name": "वड़हरी"
            },
            {
                "label": "वुढ़ावे 0019",
                "village_id": 33,
                "village_name": "वुढ़ावे"
            },
            {
                "label": "वैरवन्ना 0016",
                "village_id": 34,
                "village_name": "वैरवन्ना"
            },
            {
                "label": "वसंतपुर 0248",
                "village_id": 35,
                "village_name": "वसंतपुर"
            },
            {
                "label": "वैहरी 0018",
                "village_id": 36,
                "village_name": "वैहरी"
            },
            {
                "label": "सुखासन 0028",
                "village_id": 37,
                "village_name": "सुखासन"
            },
            {
                "label": "सरोपट्टी 0204",
                "village_id": 38,
                "village_name": "सरोपट्टी"
            },
            {
                "label": "सिंहेश्वर 0021",
                "village_id": 39,
                "village_name": "सिंहेश्वर"
            }
        ],
        "block_id": "2",
        "block_no": 1,
        "block_name": "सिंहेश्वर [002]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "गिद्धा 0210",
                "village_id": 2,
                "village_name": "गिद्धा"
            },
            {
                "label": "चम्पानगर 0015",
                "village_id": 3,
                "village_name": "चम्पानगर"
            },
            {
                "label": "जिरवा 0207",
                "village_id": 4,
                "village_name": "जिरवा"
            },
            {
                "label": "मौरा 0213",
                "village_id": 5,
                "village_name": "मौरा"
            },
            {
                "label": "रायभीर 0206",
                "village_id": 6,
                "village_name": "रायभीर"
            },
            {
                "label": "वेहरारी 0211",
                "village_id": 7,
                "village_name": "वेहरारी"
            },
            {
                "label": "वारी 0212",
                "village_id": 8,
                "village_name": "वारी"
            }
        ],
        "block_id": "9",
        "block_no": 2,
        "block_name": "शंकरपुर [009]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अमारी धरहरा 0307",
                "village_id": 2,
                "village_name": "अमारी धरहरा"
            },
            {
                "label": "खुसरू पट्टी 0271",
                "village_id": 3,
                "village_name": "खुसरू पट्टी"
            },
            {
                "label": "खाड़ी 0175",
                "village_id": 4,
                "village_name": "खाड़ी"
            },
            {
                "label": "गंगापुर 0162",
                "village_id": 5,
                "village_name": "गंगापुर"
            },
            {
                "label": "गुदर चकला 0304",
                "village_id": 6,
                "village_name": "गुदर चकला"
            },
            {
                "label": "गम्हरिया 0269",
                "village_id": 7,
                "village_name": "गम्हरिया"
            },
            {
                "label": "गोराली टोला 0168",
                "village_id": 8,
                "village_name": "गोराली टोला"
            },
            {
                "label": "घाघा 0262",
                "village_id": 9,
                "village_name": "घाघा"
            },
            {
                "label": "जीतपुर एराजी 0190",
                "village_id": 10,
                "village_name": "जीतपुर एराजी"
            },
            {
                "label": "जीतापुर 0183",
                "village_id": 11,
                "village_name": "जीतापुर"
            },
            {
                "label": "जोरगामा 0260",
                "village_id": 12,
                "village_name": "जोरगामा"
            },
            {
                "label": "तमोट परसा 0252",
                "village_id": 13,
                "village_name": "तमोट परसा"
            },
            {
                "label": "तिलकोरा 0169",
                "village_id": 14,
                "village_name": "तिलकोरा"
            },
            {
                "label": "दिग्धी 0300",
                "village_id": 15,
                "village_name": "दिग्धी"
            },
            {
                "label": "दीना पट्टी 0270",
                "village_id": 16,
                "village_name": "दीना पट्टी"
            },
            {
                "label": "नरहा 0188",
                "village_id": 17,
                "village_name": "नरहा"
            },
            {
                "label": "नवटोल 0186",
                "village_id": 18,
                "village_name": "नवटोल"
            },
            {
                "label": "पड़वा 0187",
                "village_id": 19,
                "village_name": "पड़वा"
            },
            {
                "label": "परमानन्दपुर 0174",
                "village_id": 20,
                "village_name": "परमानन्दपुर"
            },
            {
                "label": "परमानन्दपुर मिल्लिक 0167",
                "village_id": 21,
                "village_name": "परमानन्दपुर मिल्लिक"
            },
            {
                "label": "पाकिलपार 0299",
                "village_id": 22,
                "village_name": "पाकिलपार"
            },
            {
                "label": "पोखराम 0166",
                "village_id": 23,
                "village_name": "पोखराम"
            },
            {
                "label": "पोखराम मिलिक 0165",
                "village_id": 24,
                "village_name": "पोखराम मिलिक"
            },
            {
                "label": "बृन्दावन 0163",
                "village_id": 25,
                "village_name": "बृन्दावन"
            },
            {
                "label": "बेलो 0179",
                "village_id": 26,
                "village_name": "बेलो"
            },
            {
                "label": "भतखौड़ा 0189",
                "village_id": 27,
                "village_name": "भतखौड़ा"
            },
            {
                "label": "भैरवपट्टी 0184",
                "village_id": 28,
                "village_name": "भैरवपट्टी"
            },
            {
                "label": "मुरलीगंज वार्ड न. 03 0000",
                "village_id": 29,
                "village_name": "मुरलीगंज वार्ड न. 03"
            },
            {
                "label": "मुरलीगंज वार्ड न.01 0000",
                "village_id": 30,
                "village_name": "मुरलीगंज वार्ड न.01"
            },
            {
                "label": "मुरलीगंज वार्ड न.02 0000",
                "village_id": 31,
                "village_name": "मुरलीगंज वार्ड न.02"
            },
            {
                "label": "मुरलीगंज वार्ड न.04 0000",
                "village_id": 32,
                "village_name": "मुरलीगंज वार्ड न.04"
            },
            {
                "label": "मुरलीगंज वार्ड न.05 0000",
                "village_id": 33,
                "village_name": "मुरलीगंज वार्ड न.05"
            },
            {
                "label": "मुरलीगंज वार्ड न.06 0000",
                "village_id": 34,
                "village_name": "मुरलीगंज वार्ड न.06"
            },
            {
                "label": "मुरलीगंज वार्ड न.07 0000",
                "village_id": 35,
                "village_name": "मुरलीगंज वार्ड न.07"
            },
            {
                "label": "मुरलीगंज वार्ड न.08 0000",
                "village_id": 36,
                "village_name": "मुरलीगंज वार्ड न.08"
            },
            {
                "label": "मुरलीगंज वार्ड न.09 0000",
                "village_id": 37,
                "village_name": "मुरलीगंज वार्ड न.09"
            },
            {
                "label": "मुरलीगंज वार्ड न.10 0000",
                "village_id": 38,
                "village_name": "मुरलीगंज वार्ड न.10"
            },
            {
                "label": "मुरलीगंज वार्ड न.11 0000",
                "village_id": 39,
                "village_name": "मुरलीगंज वार्ड न.11"
            },
            {
                "label": "मुरलीगंज वार्ड न.12 0000",
                "village_id": 40,
                "village_name": "मुरलीगंज वार्ड न.12"
            },
            {
                "label": "मुरलीगंज वार्ड न.13 0000",
                "village_id": 41,
                "village_name": "मुरलीगंज वार्ड न.13"
            },
            {
                "label": "मीरगंज 0267",
                "village_id": 42,
                "village_name": "मीरगंज"
            },
            {
                "label": "रघुनाथपुर 0301",
                "village_id": 43,
                "village_name": "रघुनाथपुर"
            },
            {
                "label": "रजनी 0308",
                "village_id": 44,
                "village_name": "रजनी"
            },
            {
                "label": "रजनी मिलिक 0309",
                "village_id": 45,
                "village_name": "रजनी मिलिक"
            },
            {
                "label": "रानीपट्टी 0306",
                "village_id": 46,
                "village_name": "रानीपट्टी"
            },
            {
                "label": "रामपुर 0268",
                "village_id": 47,
                "village_name": "रामपुर"
            },
            {
                "label": "विशनपुर 0185",
                "village_id": 48,
                "village_name": "विशनपुर"
            },
            {
                "label": "विशनपुर कोड़लाही 247/",
                "village_id": 49,
                "village_name": "विशनपुर कोड़लाही"
            },
            {
                "label": "विशनपुर कोड़लाही मिलिक 253/",
                "village_id": 50,
                "village_name": "विशनपुर कोड़लाही मिलिक"
            },
            {
                "label": "सखुआ 0164",
                "village_id": 51,
                "village_name": "सखुआ"
            },
            {
                "label": "साहेबगंज ईटहरी 0302",
                "village_id": 52,
                "village_name": "साहेबगंज ईटहरी"
            },
            {
                "label": "सिंगियान 0305",
                "village_id": 53,
                "village_name": "सिंगियान"
            },
            {
                "label": "हरीपुर 0303",
                "village_id": 54,
                "village_name": "हरीपुर"
            }
        ],
        "block_id": "3",
        "block_no": 3,
        "block_name": "मुरलीगंज [003]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "इसरायन खुर्द 0235",
                "village_id": 2,
                "village_name": "इसरायन खुर्द"
            },
            {
                "label": "ईसराईन कला खाप 0275",
                "village_id": 3,
                "village_name": "ईसराईन कला खाप"
            },
            {
                "label": "ईसरायन कला 0233",
                "village_id": 4,
                "village_name": "ईसरायन कला"
            },
            {
                "label": "कुमारखण्‍ड 0240",
                "village_id": 5,
                "village_name": "कुमारखण्‍ड"
            },
            {
                "label": "केवटगामा 0236",
                "village_id": 6,
                "village_name": "केवटगामा"
            },
            {
                "label": "कांक.ड 0215",
                "village_id": 7,
                "village_name": "कांक.ड"
            },
            {
                "label": "खुटहा रजई 0294",
                "village_id": 8,
                "village_name": "खुटहा रजई"
            },
            {
                "label": "गुड़िया 0231",
                "village_id": 9,
                "village_name": "गुड़िया"
            },
            {
                "label": "गुड़िया 0230",
                "village_id": 10,
                "village_name": "गुड़िया"
            },
            {
                "label": "गुडिया मिल्‍लीक 0227",
                "village_id": 11,
                "village_name": "गुडिया मिल्‍लीक"
            },
            {
                "label": "गुड़िया मिलीक 0232",
                "village_id": 12,
                "village_name": "गुड़िया मिलीक"
            },
            {
                "label": "गढ़िया 0223",
                "village_id": 13,
                "village_name": "गढ़िया"
            },
            {
                "label": "गढिया मिल्‍लीक 0224",
                "village_id": 14,
                "village_name": "गढिया मिल्‍लीक"
            },
            {
                "label": "गढ़िया मिलिक 0225",
                "village_id": 15,
                "village_name": "गढ़िया मिलिक"
            },
            {
                "label": "गोपीपुर 0276",
                "village_id": 16,
                "village_name": "गोपीपुर"
            },
            {
                "label": "घो.डदौर 0278",
                "village_id": 17,
                "village_name": "घो.डदौर"
            },
            {
                "label": "चकला गुलाम अब्‍बास 0292",
                "village_id": 18,
                "village_name": "चकला गुलाम अब्‍बास"
            },
            {
                "label": "चैनपुर 0289",
                "village_id": 19,
                "village_name": "चैनपुर"
            },
            {
                "label": "चैनपुर मिल्‍लीक 0290",
                "village_id": 20,
                "village_name": "चैनपुर मिल्‍लीक"
            },
            {
                "label": "जोरावरगंज 0263",
                "village_id": 21,
                "village_name": "जोरावरगंज"
            },
            {
                "label": "दत्ता सरदार चकला 0221",
                "village_id": 22,
                "village_name": "दत्ता सरदार चकला"
            },
            {
                "label": "नैनपट़टी 0287",
                "village_id": 23,
                "village_name": "नैनपट़टी"
            },
            {
                "label": "नवलगंज मैनपुर 280/",
                "village_id": 24,
                "village_name": "नवलगंज मैनपुर"
            },
            {
                "label": "पुरैनी 0217",
                "village_id": 25,
                "village_name": "पुरैनी"
            },
            {
                "label": "पुरैनी क‍ररिया 0284",
                "village_id": 26,
                "village_name": "पुरैनी क‍ररिया"
            },
            {
                "label": "परमानंदपुर 0288",
                "village_id": 27,
                "village_name": "परमानंदपुर"
            },
            {
                "label": "परमानंदपुर चकला 0282",
                "village_id": 28,
                "village_name": "परमानंदपुर चकला"
            },
            {
                "label": "बभनटोली 0246",
                "village_id": 29,
                "village_name": "बभनटोली"
            },
            {
                "label": "बेलारी 247/",
                "village_id": 30,
                "village_name": "बेलारी"
            },
            {
                "label": "बेलासदी 0226",
                "village_id": 31,
                "village_name": "बेलासदी"
            },
            {
                "label": "बेलासदी मिल्‍लीक 0279",
                "village_id": 32,
                "village_name": "बेलासदी मिल्‍लीक"
            },
            {
                "label": "बाघमारा 0285",
                "village_id": 33,
                "village_name": "बाघमारा"
            },
            {
                "label": "भवानीपुर 0257",
                "village_id": 34,
                "village_name": "भवानीपुर"
            },
            {
                "label": "भवानीपुर जागीर 0258",
                "village_id": 35,
                "village_name": "भवानीपुर जागीर"
            },
            {
                "label": "मंगरतारा मिल्‍लीक 0277",
                "village_id": 36,
                "village_name": "मंगरतारा मिल्‍लीक"
            },
            {
                "label": "मंगरवारा 274/",
                "village_id": 37,
                "village_name": "मंगरवारा"
            },
            {
                "label": "मंगरवारा खास 274/",
                "village_id": 38,
                "village_name": "मंगरवारा खास"
            },
            {
                "label": "मझोल 0238",
                "village_id": 39,
                "village_name": "मझोल"
            },
            {
                "label": "रंगपट़टी 280/",
                "village_id": 40,
                "village_name": "रंगपट़टी"
            },
            {
                "label": "रहटा 0259",
                "village_id": 41,
                "village_name": "रहटा"
            },
            {
                "label": "रहटा चकला 0281",
                "village_id": 42,
                "village_name": "रहटा चकला"
            },
            {
                "label": "रामचौरा 0244",
                "village_id": 43,
                "village_name": "रामचौरा"
            },
            {
                "label": "रामनगर महेश 0222",
                "village_id": 44,
                "village_name": "रामनगर महेश"
            },
            {
                "label": "रामनगर महेश 0283",
                "village_id": 45,
                "village_name": "रामनगर महेश"
            },
            {
                "label": "रामपट्टी 0216",
                "village_id": 46,
                "village_name": "रामपट्टी"
            },
            {
                "label": "लक्ष्मीपुर चंडीस्थान 0256",
                "village_id": 47,
                "village_name": "लक्ष्मीपुर चंडीस्थान"
            },
            {
                "label": "लक्ष्‍मीपुर भगवती 0296",
                "village_id": 48,
                "village_name": "लक्ष्‍मीपुर भगवती"
            },
            {
                "label": "लक्ष्‍मीपुर भगवती 0297",
                "village_id": 49,
                "village_name": "लक्ष्‍मीपुर भगवती"
            },
            {
                "label": "लक्ष्‍मीपुर भगवती 0298",
                "village_id": 50,
                "village_name": "लक्ष्‍मीपुर भगवती"
            },
            {
                "label": "लक्ष्मीपुर मिल्लीक 0255",
                "village_id": 51,
                "village_name": "लक्ष्मीपुर मिल्लीक"
            },
            {
                "label": "वैसाढ़ 0234",
                "village_id": 52,
                "village_name": "वैसाढ़"
            },
            {
                "label": "विशनपुर को.डलाही 0254",
                "village_id": 53,
                "village_name": "विशनपुर को.डलाही"
            },
            {
                "label": "विशनपुर सुन्‍दर 0241",
                "village_id": 54,
                "village_name": "विशनपुर सुन्‍दर"
            },
            {
                "label": "विसनपुर एराजी 253/",
                "village_id": 55,
                "village_name": "विसनपुर एराजी"
            },
            {
                "label": "विसनपुर को.डलाही 253/",
                "village_id": 56,
                "village_name": "विसनपुर को.डलाही"
            },
            {
                "label": "श्रीपुर 0243",
                "village_id": 57,
                "village_name": "श्रीपुर"
            },
            {
                "label": "श्रीपुर मिल्‍लीक 0242",
                "village_id": 58,
                "village_name": "श्रीपुर मिल्‍लीक"
            },
            {
                "label": "सुखासन 0245",
                "village_id": 59,
                "village_name": "सुखासन"
            },
            {
                "label": "सरहद 0214",
                "village_id": 60,
                "village_name": "सरहद"
            },
            {
                "label": "सरहदगति 0218",
                "village_id": 61,
                "village_name": "सरहदगति"
            },
            {
                "label": "सरहदगति 0219",
                "village_id": 62,
                "village_name": "सरहदगति"
            },
            {
                "label": "सरहदगति 0220",
                "village_id": 63,
                "village_name": "सरहदगति"
            },
            {
                "label": "सहोरिया वाला     ",
                "village_id": 64,
                "village_name": "सहोरिया वाला"
            },
            {
                "label": "सिकरहट़टी 0239",
                "village_id": 65,
                "village_name": "सिकरहट़टी"
            },
            {
                "label": "सिकरहट़टी मिल्‍लीक 0237",
                "village_id": 66,
                "village_name": "सिकरहट़टी मिल्‍लीक"
            },
            {
                "label": "सिहपुर 0229",
                "village_id": 67,
                "village_name": "सिहपुर"
            },
            {
                "label": "सिंहपुर चकला 0228",
                "village_id": 68,
                "village_name": "सिंहपुर चकला"
            },
            {
                "label": "हनुमान नगर 0286",
                "village_id": 69,
                "village_name": "हनुमान नगर"
            },
            {
                "label": "हनुमान नगर मिल्‍लीक 0291",
                "village_id": 70,
                "village_name": "हनुमान नगर मिल्‍लीक"
            },
            {
                "label": "हनुमाननगर चकला 0261",
                "village_id": 71,
                "village_name": "हनुमाननगर चकला"
            },
            {
                "label": "हीरापट़टी 0293",
                "village_id": 72,
                "village_name": "हीरापट़टी"
            }
        ],
        "block_id": "10",
        "block_no": 4,
        "block_name": "कुमारखंड [010]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अर्राहा 0057",
                "village_id": 2,
                "village_name": "अर्राहा"
            },
            {
                "label": "गोढ़ बरदाहा 0036",
                "village_id": 3,
                "village_name": "गोढ़ बरदाहा"
            },
            {
                "label": "घैलाड़ 0032",
                "village_id": 4,
                "village_name": "घैलाड़"
            },
            {
                "label": "चित्ती 0034",
                "village_id": 5,
                "village_name": "चित्ती"
            },
            {
                "label": "झिटकिया 0043",
                "village_id": 6,
                "village_name": "झिटकिया"
            },
            {
                "label": "पथराहा 0041",
                "village_id": 7,
                "village_name": "पथराहा"
            },
            {
                "label": "पथराहा 0042",
                "village_id": 8,
                "village_name": "पथराहा"
            },
            {
                "label": "पिपराही चकला 0039",
                "village_id": 9,
                "village_name": "पिपराही चकला"
            },
            {
                "label": "बनचोलहा 0045",
                "village_id": 10,
                "village_name": "बनचोलहा"
            },
            {
                "label": "बरदाहा 0038",
                "village_id": 11,
                "village_name": "बरदाहा"
            },
            {
                "label": "बरदाहा एराजी 0037",
                "village_id": 12,
                "village_name": "बरदाहा एराजी"
            },
            {
                "label": "बसुदेवा 0044",
                "village_id": 13,
                "village_name": "बसुदेवा"
            },
            {
                "label": "भतरंधा 0035",
                "village_id": 14,
                "village_name": "भतरंधा"
            },
            {
                "label": "भानटेकठी 0056",
                "village_id": 15,
                "village_name": "भानटेकठी"
            },
            {
                "label": "रतनपुरा 0030",
                "village_id": 16,
                "village_name": "रतनपुरा"
            },
            {
                "label": "वासुदेवा 0044",
                "village_id": 17,
                "village_name": "वासुदेवा"
            },
            {
                "label": "श्रीनगर 0033",
                "village_id": 18,
                "village_name": "श्रीनगर"
            }
        ],
        "block_id": "13",
        "block_no": 5,
        "block_name": "घैलाढ़ [013]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "औराही 0006",
                "village_id": 2,
                "village_name": "औराही"
            },
            {
                "label": "कोढ़ियार 0026",
                "village_id": 3,
                "village_name": "कोढ़ियार"
            },
            {
                "label": "गम्हिरिया 0005",
                "village_id": 4,
                "village_name": "गम्हिरिया"
            },
            {
                "label": "चिकनी 0027",
                "village_id": 5,
                "village_name": "चिकनी"
            },
            {
                "label": "जिवछपुर 0031",
                "village_id": 6,
                "village_name": "जिवछपुर"
            },
            {
                "label": "जोगवनी 0023",
                "village_id": 7,
                "village_name": "जोगवनी"
            },
            {
                "label": "तरावे 0025",
                "village_id": 8,
                "village_name": "तरावे"
            },
            {
                "label": "दाहा 0001",
                "village_id": 9,
                "village_name": "दाहा"
            },
            {
                "label": "परवाहा 0024",
                "village_id": 10,
                "village_name": "परवाहा"
            },
            {
                "label": "वभनी 0004",
                "village_id": 11,
                "village_name": "वभनी"
            }
        ],
        "block_id": "12",
        "block_no": 6,
        "block_name": "गम्हरिया [012]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "आनन्दपुरा 0205",
                "village_id": 2,
                "village_name": "आनन्दपुरा"
            },
            {
                "label": "आनन्दपुरा मिलिक 0204",
                "village_id": 3,
                "village_name": "आनन्दपुरा मिलिक"
            },
            {
                "label": "उदा 0195",
                "village_id": 4,
                "village_name": "उदा"
            },
            {
                "label": "किसुनगंज 0192",
                "village_id": 5,
                "village_name": "किसुनगंज"
            },
            {
                "label": "खाड़ा 0061",
                "village_id": 6,
                "village_name": "खाड़ा"
            },
            {
                "label": "गोपालपुर 72/2",
                "village_id": 7,
                "village_name": "गोपालपुर"
            },
            {
                "label": "चकफजुल्ला 0185",
                "village_id": 8,
                "village_name": "चकफजुल्ला"
            },
            {
                "label": "जोतैली 0150",
                "village_id": 9,
                "village_name": "जोतैली"
            },
            {
                "label": "तेलडीहा 0173",
                "village_id": 10,
                "village_name": "तेलडीहा"
            },
            {
                "label": "तिरासी 0169",
                "village_id": 11,
                "village_name": "तिरासी"
            },
            {
                "label": "देवैल 0163",
                "village_id": 12,
                "village_name": "देवैल"
            },
            {
                "label": "नया नगर एराजी 0050",
                "village_id": 13,
                "village_name": "नया नगर एराजी"
            },
            {
                "label": "नयानगर 0188",
                "village_id": 14,
                "village_name": "नयानगर"
            },
            {
                "label": "फनहन 0190",
                "village_id": 15,
                "village_name": "फनहन"
            },
            {
                "label": "बुधमा 0062",
                "village_id": 16,
                "village_name": "बुधमा"
            },
            {
                "label": "बराही 0198",
                "village_id": 17,
                "village_name": "बराही"
            },
            {
                "label": "बीड़ी रणपाल 0168",
                "village_id": 18,
                "village_name": "बीड़ी रणपाल"
            },
            {
                "label": "भलुआही 0186",
                "village_id": 19,
                "village_name": "भलुआही"
            },
            {
                "label": "भवानन्दपुर उर्फ कुँअर गंज 0189",
                "village_id": 20,
                "village_name": "भवानन्दपुर उर्फ कुँअर गंज"
            },
            {
                "label": "मंजौरा 0160",
                "village_id": 21,
                "village_name": "मंजौरा"
            },
            {
                "label": "मधुबन 0206",
                "village_id": 22,
                "village_name": "मधुबन"
            },
            {
                "label": "मुरली चन्दवा 0157",
                "village_id": 23,
                "village_name": "मुरली चन्दवा"
            },
            {
                "label": "महेशुआ 0172",
                "village_id": 24,
                "village_name": "महेशुआ"
            },
            {
                "label": "मो.दानिश इंगलिश 184/",
                "village_id": 25,
                "village_name": "मो.दानिश इंगलिश"
            },
            {
                "label": "रघुनाथपुर पकड़िया 0170",
                "village_id": 26,
                "village_name": "रघुनाथपुर पकड़िया"
            },
            {
                "label": "रसलपुर कासीम 0176",
                "village_id": 27,
                "village_name": "रसलपुर कासीम"
            },
            {
                "label": "रहुआ 0151",
                "village_id": 28,
                "village_name": "रहुआ"
            },
            {
                "label": "रहटा 0191",
                "village_id": 29,
                "village_name": "रहटा"
            },
            {
                "label": "राम सहाय मिलिक 0196",
                "village_id": 30,
                "village_name": "राम सहाय मिलिक"
            },
            {
                "label": "राम सहाय मिलिक 0197",
                "village_id": 31,
                "village_name": "राम सहाय मिलिक"
            },
            {
                "label": "रामपुर खोड़ा 0193",
                "village_id": 32,
                "village_name": "रामपुर खोड़ा"
            },
            {
                "label": "रामपुरडेहरू 0159",
                "village_id": 33,
                "village_name": "रामपुरडेहरू"
            },
            {
                "label": "रायमीरा 0161",
                "village_id": 34,
                "village_name": "रायमीरा"
            },
            {
                "label": "रोशन अली मिलिक 181/",
                "village_id": 35,
                "village_name": "रोशन अली मिलिक"
            },
            {
                "label": "लक्ष्मीपुर 0174",
                "village_id": 36,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लक्ष्मीपुर मिल्लिक 0175",
                "village_id": 37,
                "village_name": "लक्ष्मीपुर मिल्लिक"
            },
            {
                "label": "लशकरी 0177",
                "village_id": 38,
                "village_name": "लशकरी"
            },
            {
                "label": "वारोटेनी 0183",
                "village_id": 39,
                "village_name": "वारोटेनी"
            },
            {
                "label": "शेखपुर चमन 0187",
                "village_id": 40,
                "village_name": "शेखपुर चमन"
            },
            {
                "label": "शाहजादपुर 0063",
                "village_id": 41,
                "village_name": "शाहजादपुर"
            },
            {
                "label": "सिंगारपुर 0051",
                "village_id": 42,
                "village_name": "सिंगारपुर"
            },
            {
                "label": "हरैली 0194",
                "village_id": 43,
                "village_name": "हरैली"
            }
        ],
        "block_id": "6",
        "block_no": 7,
        "block_name": "उदाकिशुनगंज [006]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "  0000",
                "village_id": 2,
                "village_name": " "
            },
            {
                "label": "अमौआ 0214",
                "village_id": 3,
                "village_name": "अमौआ"
            },
            {
                "label": "एराजी भलुआही 0209",
                "village_id": 4,
                "village_name": "एराजी भलुआही"
            },
            {
                "label": "कल्याणपट्टी 0316",
                "village_id": 5,
                "village_name": "कल्याणपट्टी"
            },
            {
                "label": "कलौतहा 0170",
                "village_id": 6,
                "village_name": "कलौतहा"
            },
            {
                "label": "खोखशी मिल्लीक(शरणपुर) 0201",
                "village_id": 7,
                "village_name": "खोखशी मिल्लीक(शरणपुर)"
            },
            {
                "label": "खोखसी मिल्लीक(शरणपुर) 0201",
                "village_id": 8,
                "village_name": "खोखसी मिल्लीक(शरणपुर)"
            },
            {
                "label": "खोखसी शरणपुर उर्फ रामगंज 0200",
                "village_id": 9,
                "village_name": "खोखसी शरणपुर उर्फ रामगंज"
            },
            {
                "label": "ग्वालपाड़ा 0217",
                "village_id": 10,
                "village_name": "ग्वालपाड़ा"
            },
            {
                "label": "चक चतरा 0157",
                "village_id": 11,
                "village_name": "चक चतरा"
            },
            {
                "label": "चकला गोपाल 0319",
                "village_id": 12,
                "village_name": "चकला गोपाल"
            },
            {
                "label": "चकला पिताम्‍बर 0315",
                "village_id": 13,
                "village_name": "चकला पिताम्‍बर"
            },
            {
                "label": "जगतपुर 0317",
                "village_id": 14,
                "village_name": "जगतपुर"
            },
            {
                "label": "जयराम परसी 0173",
                "village_id": 15,
                "village_name": "जयराम परसी"
            },
            {
                "label": "जोत मनोहर 0211",
                "village_id": 16,
                "village_name": "जोत मनोहर"
            },
            {
                "label": "झंझड़ी 0215",
                "village_id": 17,
                "village_name": "झंझड़ी"
            },
            {
                "label": "झलाड़ी 0208",
                "village_id": 18,
                "village_name": "झलाड़ी"
            },
            {
                "label": "झिटकिया हाड़ी 0171",
                "village_id": 19,
                "village_name": "झिटकिया हाड़ी"
            },
            {
                "label": "झिटकिया हारी 0172",
                "village_id": 20,
                "village_name": "झिटकिया हारी"
            },
            {
                "label": "टेका एराजी 0153",
                "village_id": 21,
                "village_name": "टेका एराजी"
            },
            {
                "label": "टेमा भेला 0313",
                "village_id": 22,
                "village_name": "टेमा भेला"
            },
            {
                "label": "डेफरा 0148",
                "village_id": 23,
                "village_name": "डेफरा"
            },
            {
                "label": "नौहर 0219",
                "village_id": 24,
                "village_name": "नौहर"
            },
            {
                "label": "प.डोकिया 0318",
                "village_id": 25,
                "village_name": "प.डोकिया"
            },
            {
                "label": "परसाहा 0314",
                "village_id": 26,
                "village_name": "परसाहा"
            },
            {
                "label": "पीरनगर 0199",
                "village_id": 27,
                "village_name": "पीरनगर"
            },
            {
                "label": "फकीरना 0224",
                "village_id": 28,
                "village_name": "फकीरना"
            },
            {
                "label": "बभनगामा 0151",
                "village_id": 29,
                "village_name": "बभनगामा"
            },
            {
                "label": "बाध चतरा 158/",
                "village_id": 30,
                "village_name": "बाध चतरा"
            },
            {
                "label": "बाँध-चतरा 158/",
                "village_id": 31,
                "village_name": "बाँध-चतरा"
            },
            {
                "label": "बिसवाड़ी 0216",
                "village_id": 32,
                "village_name": "बिसवाड़ी"
            },
            {
                "label": "भलुआही 0220",
                "village_id": 33,
                "village_name": "भलुआही"
            },
            {
                "label": "महम्मदपुर 0212",
                "village_id": 34,
                "village_name": "महम्मदपुर"
            },
            {
                "label": "महराजगंज 311/",
                "village_id": 35,
                "village_name": "महराजगंज"
            },
            {
                "label": "महराजगंज 0154",
                "village_id": 36,
                "village_name": "महराजगंज"
            },
            {
                "label": "महराजगंज बॉध 311/",
                "village_id": 37,
                "village_name": "महराजगंज बॉध"
            },
            {
                "label": "रकियापट़टी 0156",
                "village_id": 38,
                "village_name": "रकियापट़टी"
            },
            {
                "label": "रेसना 0150",
                "village_id": 39,
                "village_name": "रेसना"
            },
            {
                "label": "राजपुर सरसंडी 0210",
                "village_id": 40,
                "village_name": "राजपुर सरसंडी"
            },
            {
                "label": "विशनपुर अमौना 0202",
                "village_id": 41,
                "village_name": "विशनपुर अमौना"
            },
            {
                "label": "विशनपुर अरार 0149",
                "village_id": 42,
                "village_name": "विशनपुर अरार"
            },
            {
                "label": "विशनपुर कनटाही 0312",
                "village_id": 43,
                "village_name": "विशनपुर कनटाही"
            },
            {
                "label": "वीरगॉव 0159",
                "village_id": 44,
                "village_name": "वीरगॉव"
            },
            {
                "label": "वीरगॉव 0160",
                "village_id": 45,
                "village_name": "वीरगॉव"
            },
            {
                "label": "वीरगॉव 0161",
                "village_id": 46,
                "village_name": "वीरगॉव"
            },
            {
                "label": "श्याम 0203",
                "village_id": 47,
                "village_name": "श्याम"
            },
            {
                "label": "शाहपुर 0218",
                "village_id": 48,
                "village_name": "शाहपुर"
            },
            {
                "label": "शिशवापट़टी 155/",
                "village_id": 49,
                "village_name": "शिशवापट़टी"
            },
            {
                "label": "शिशवापट़टी 155/",
                "village_id": 50,
                "village_name": "शिशवापट़टी"
            },
            {
                "label": "सुखासन 152/",
                "village_id": 51,
                "village_name": "सुखासन"
            },
            {
                "label": "सुखासन एराजी 152/",
                "village_id": 52,
                "village_name": "सुखासन एराजी"
            },
            {
                "label": "सरौनी कला 0222",
                "village_id": 53,
                "village_name": "सरौनी कला"
            },
            {
                "label": "सिन्‍दुआरी 0223",
                "village_id": 54,
                "village_name": "सिन्‍दुआरी"
            }
        ],
        "block_id": "4",
        "block_no": 8,
        "block_name": "ग्वालपाड़ा [004]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "कठोतिया 0322",
                "village_id": 2,
                "village_name": "कठोतिया"
            },
            {
                "label": "कुमारपुर 0158",
                "village_id": 3,
                "village_name": "कुमारपुर"
            },
            {
                "label": "कुस्थन 0233",
                "village_id": 4,
                "village_name": "कुस्थन"
            },
            {
                "label": "गंगौरा 0207",
                "village_id": 5,
                "village_name": "गंगौरा"
            },
            {
                "label": "गमैल 0167",
                "village_id": 6,
                "village_name": "गमैल"
            },
            {
                "label": "गोरपार 0171",
                "village_id": 7,
                "village_name": "गोरपार"
            },
            {
                "label": "तुलसिया 0321",
                "village_id": 8,
                "village_name": "तुलसिया"
            },
            {
                "label": "पड़रिया 0225",
                "village_id": 9,
                "village_name": "पड़रिया"
            },
            {
                "label": "परिहारपुर 0162",
                "village_id": 10,
                "village_name": "परिहारपुर"
            },
            {
                "label": "फतेहपुर 0320",
                "village_id": 11,
                "village_name": "फतेहपुर"
            },
            {
                "label": "बैजनाथपुर 0324",
                "village_id": 12,
                "village_name": "बैजनाथपुर"
            },
            {
                "label": "बढ़ैया 0230",
                "village_id": 13,
                "village_name": "बढ़ैया"
            },
            {
                "label": "बेलाही 0221",
                "village_id": 14,
                "village_name": "बेलाही"
            },
            {
                "label": "बिहारीगंज 0231",
                "village_id": 15,
                "village_name": "बिहारीगंज"
            },
            {
                "label": "मधुकर चक 0166",
                "village_id": 16,
                "village_name": "मधुकर चक"
            },
            {
                "label": "मोहनपुर चौमुख 0228",
                "village_id": 17,
                "village_name": "मोहनपुर चौमुख"
            },
            {
                "label": "मोहनपुर निस्क 0227",
                "village_id": 18,
                "village_name": "मोहनपुर निस्क"
            },
            {
                "label": "रहीजगतपुर 0165",
                "village_id": 19,
                "village_name": "रहीजगतपुर"
            },
            {
                "label": "राजगंज 0234",
                "village_id": 20,
                "village_name": "राजगंज"
            },
            {
                "label": "लक्ष्मीपुर 0226",
                "village_id": 21,
                "village_name": "लक्ष्मीपुर"
            },
            {
                "label": "लक्ष्मीपुर लाल चन्द 0164",
                "village_id": 22,
                "village_name": "लक्ष्मीपुर लाल चन्द"
            },
            {
                "label": "वभनगामा 0310",
                "village_id": 23,
                "village_name": "वभनगामा"
            },
            {
                "label": "विसनपुर जय सिंह 0229",
                "village_id": 24,
                "village_name": "विसनपुर जय सिंह"
            },
            {
                "label": "शेखपुरा 0323",
                "village_id": 25,
                "village_name": "शेखपुरा"
            },
            {
                "label": "हतिऔन्धा 0232",
                "village_id": 26,
                "village_name": "हतिऔन्धा"
            }
        ],
        "block_id": "5",
        "block_no": 9,
        "block_name": "बिहारीगंज [005]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अमानत सरकार 0145",
                "village_id": 2,
                "village_name": "अमानत सरकार"
            },
            {
                "label": "अमानत सरकार 0122",
                "village_id": 3,
                "village_name": "अमानत सरकार"
            },
            {
                "label": "औराय 0136",
                "village_id": 4,
                "village_name": "औराय"
            },
            {
                "label": "कड़ामा 0141",
                "village_id": 5,
                "village_name": "कड़ामा"
            },
            {
                "label": "कनुआरही 131/",
                "village_id": 6,
                "village_name": "कनुआरही"
            },
            {
                "label": "कुरसंडी 0181",
                "village_id": 7,
                "village_name": "कुरसंडी"
            },
            {
                "label": "काले खाँ नायक जागीर 0135",
                "village_id": 8,
                "village_name": "काले खाँ नायक जागीर"
            },
            {
                "label": "खेरहो 0121",
                "village_id": 9,
                "village_name": "खेरहो"
            },
            {
                "label": "गणेशपुर 0142",
                "village_id": 10,
                "village_name": "गणेशपुर"
            },
            {
                "label": "डुमरैल 0155",
                "village_id": 11,
                "village_name": "डुमरैल"
            },
            {
                "label": "दुर्गापुर 0134",
                "village_id": 12,
                "village_name": "दुर्गापुर"
            },
            {
                "label": "दुर्गापुर एराजी 148/",
                "village_id": 13,
                "village_name": "दुर्गापुर एराजी"
            },
            {
                "label": "नजरअली मिलिक 148/",
                "village_id": 14,
                "village_name": "नजरअली मिलिक"
            },
            {
                "label": "नरदह 152/",
                "village_id": 15,
                "village_name": "नरदह"
            },
            {
                "label": "नरदह छींट 152/",
                "village_id": 16,
                "village_name": "नरदह छींट"
            },
            {
                "label": "पुरैनी 0146",
                "village_id": 17,
                "village_name": "पुरैनी"
            },
            {
                "label": "पुरैनी मिलिक 0144",
                "village_id": 18,
                "village_name": "पुरैनी मिलिक"
            },
            {
                "label": "पुरैनी वंश गोपाल 0149",
                "village_id": 19,
                "village_name": "पुरैनी वंश गोपाल"
            },
            {
                "label": "प्राण सिंह जमादार 0125",
                "village_id": 20,
                "village_name": "प्राण सिंह जमादार"
            },
            {
                "label": "म.वलिया 0178",
                "village_id": 21,
                "village_name": "म.वलिया"
            },
            {
                "label": "मकदमपुर 131/",
                "village_id": 22,
                "village_name": "मकदमपुर"
            },
            {
                "label": "मेघुसिंह हवलदार जागीर 0132",
                "village_id": 23,
                "village_name": "मेघुसिंह हवलदार जागीर"
            },
            {
                "label": "मदन ठाकुर मिलिक 0143",
                "village_id": 24,
                "village_name": "मदन ठाकुर मिलिक"
            },
            {
                "label": "योगीराज 0153",
                "village_id": 25,
                "village_name": "योगीराज"
            },
            {
                "label": "रौता 0179",
                "village_id": 26,
                "village_name": "रौता"
            },
            {
                "label": "वघरा 0147",
                "village_id": 27,
                "village_name": "वघरा"
            },
            {
                "label": "वासुदेवपुर 0180",
                "village_id": 28,
                "village_name": "वासुदेवपुर"
            },
            {
                "label": "सपरदह 0137",
                "village_id": 29,
                "village_name": "सपरदह"
            },
            {
                "label": "हमीदपुर 156/",
                "village_id": 30,
                "village_name": "हमीदपुर"
            },
            {
                "label": "हमीदपुर छींट 156/",
                "village_id": 31,
                "village_name": "हमीदपुर छींट"
            },
            {
                "label": "हरिवंश सिंह नायक 0133",
                "village_id": 32,
                "village_name": "हरिवंश सिंह नायक"
            }
        ],
        "block_id": "7",
        "block_no": 10,
        "block_name": "पुरेनी [007]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अकहा 0064",
                "village_id": 2,
                "village_name": "अकहा"
            },
            {
                "label": "आलमनगर 72/1",
                "village_id": 3,
                "village_name": "आलमनगर"
            },
            {
                "label": "ईटहरी 0085",
                "village_id": 4,
                "village_name": "ईटहरी"
            },
            {
                "label": "कुंजौड़ी 0139",
                "village_id": 5,
                "village_name": "कुंजौड़ी"
            },
            {
                "label": "कपसिया 0081",
                "village_id": 6,
                "village_name": "कपसिया"
            },
            {
                "label": "केशोपुर 0071",
                "village_id": 7,
                "village_name": "केशोपुर"
            },
            {
                "label": "किशनपुर रतवारा 0082",
                "village_id": 8,
                "village_name": "किशनपुर रतवारा"
            },
            {
                "label": "खुरहान माल 0076",
                "village_id": 9,
                "village_name": "खुरहान माल"
            },
            {
                "label": "गंगापुर 0083",
                "village_id": 10,
                "village_name": "गंगापुर"
            },
            {
                "label": "गौछी डीह 86/1",
                "village_id": 11,
                "village_name": "गौछी डीह"
            },
            {
                "label": "चकसनौरी 0078",
                "village_id": 12,
                "village_name": "चकसनौरी"
            },
            {
                "label": "तिलकपुर 70/1",
                "village_id": 13,
                "village_name": "तिलकपुर"
            },
            {
                "label": "पड़ैल 0079",
                "village_id": 14,
                "village_name": "पड़ैल"
            },
            {
                "label": "फुलौत इंगलिश 0138",
                "village_id": 15,
                "village_name": "फुलौत इंगलिश"
            },
            {
                "label": "बठौना 0074",
                "village_id": 16,
                "village_name": "बठौना"
            },
            {
                "label": "बसनबा.डा 0066",
                "village_id": 17,
                "village_name": "बसनबा.डा"
            },
            {
                "label": "भागीपुर नरथुआ 0140",
                "village_id": 18,
                "village_name": "भागीपुर नरथुआ"
            },
            {
                "label": "मुजफ्फरपुर इस्माइल 0065",
                "village_id": 19,
                "village_name": "मुजफ्फरपुर इस्माइल"
            },
            {
                "label": "मुरौत 70/2",
                "village_id": 20,
                "village_name": "मुरौत"
            },
            {
                "label": "मोहदीपुर 0069",
                "village_id": 21,
                "village_name": "मोहदीपुर"
            },
            {
                "label": "रामनगर खावन 0084",
                "village_id": 22,
                "village_name": "रामनगर खावन"
            },
            {
                "label": "लदमा 0073",
                "village_id": 23,
                "village_name": "लदमा"
            },
            {
                "label": "लुरहान मिलिक 0067",
                "village_id": 24,
                "village_name": "लुरहान मिलिक"
            },
            {
                "label": "वड़गाँव 0077",
                "village_id": 25,
                "village_name": "वड़गाँव"
            },
            {
                "label": "विसपट्टी 0068",
                "village_id": 26,
                "village_name": "विसपट्टी"
            },
            {
                "label": "शंकरपुर मधैली 0182",
                "village_id": 27,
                "village_name": "शंकरपुर मधैली"
            },
            {
                "label": "सिंधार 86/2",
                "village_id": 28,
                "village_name": "सिंधार"
            },
            {
                "label": "हरिहरपुर वजराहा 0087",
                "village_id": 29,
                "village_name": "हरिहरपुर वजराहा"
            },
            {
                "label": "हारु डुमरा 0075",
                "village_id": 30,
                "village_name": "हारु डुमरा"
            }
        ],
        "block_id": "8",
        "block_no": 11,
        "block_name": "आलमनगर [008]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अमानत मुंगेर खां सुवेदार 0110",
                "village_id": 2,
                "village_name": "अमानत मुंगेर खां सुवेदार"
            },
            {
                "label": "अमानत मान तुल्लाह 0113",
                "village_id": 3,
                "village_name": "अमानत मान तुल्लाह"
            },
            {
                "label": "अमानत सरकार 93/1",
                "village_id": 4,
                "village_name": "अमानत सरकार"
            },
            {
                "label": "अमानत सरकार 0102",
                "village_id": 5,
                "village_name": "अमानत सरकार"
            },
            {
                "label": "अमानत हिदायतुल्लाह 0116",
                "village_id": 6,
                "village_name": "अमानत हिदायतुल्लाह"
            },
            {
                "label": "अमानत़मान उल्लाह सुवेदार 0108",
                "village_id": 7,
                "village_name": "अमानत़मान उल्लाह सुवेदार"
            },
            {
                "label": "अरजपुर 0100",
                "village_id": 8,
                "village_name": "अरजपुर"
            },
            {
                "label": "इंगलिश घोषई 0127",
                "village_id": 9,
                "village_name": "इंगलिश घोषई"
            },
            {
                "label": "इंगलिश घोषई 0104",
                "village_id": 10,
                "village_name": "इंगलिश घोषई"
            },
            {
                "label": "केवटराम सुबेदार जागीर 0123",
                "village_id": 11,
                "village_name": "केवटराम सुबेदार जागीर"
            },
            {
                "label": "गोविन्‍दराम कुमेदान 0129",
                "village_id": 12,
                "village_name": "गोविन्‍दराम कुमेदान"
            },
            {
                "label": "चौसा 0103",
                "village_id": 13,
                "village_name": "चौसा"
            },
            {
                "label": "जागीर एतवारी जमादार 0114",
                "village_id": 14,
                "village_name": "जागीर एतवारी जमादार"
            },
            {
                "label": "जागीर चमरु हवलदार 0126",
                "village_id": 15,
                "village_name": "जागीर चमरु हवलदार"
            },
            {
                "label": "जागीर जाफर सुबेदार 101/",
                "village_id": 16,
                "village_name": "जागीर जाफर सुबेदार"
            },
            {
                "label": "जागीर झबर सिंह हवलदार 0097",
                "village_id": 17,
                "village_name": "जागीर झबर सिंह हवलदार"
            },
            {
                "label": "जागीर बादल सिंह कुमेदान 0109",
                "village_id": 18,
                "village_name": "जागीर बादल सिंह कुमेदान"
            },
            {
                "label": "जागीर भोला सिंह 0112",
                "village_id": 19,
                "village_name": "जागीर भोला सिंह"
            },
            {
                "label": "जागीर मुहम्मद जाफर सुवेदार 101/",
                "village_id": 20,
                "village_name": "जागीर मुहम्मद जाफर सुवेदार"
            },
            {
                "label": "जागीर मानउल्‍लाह सुबेदार 0111",
                "village_id": 21,
                "village_name": "जागीर मानउल्‍लाह सुबेदार"
            },
            {
                "label": "जागीर मोहम्‍मद हनीफ कुमेदान 0120",
                "village_id": 22,
                "village_name": "जागीर मोहम्‍मद हनीफ कुमेदान"
            },
            {
                "label": "जागीर रसुल सुवेदार 94/2",
                "village_id": 23,
                "village_name": "जागीर रसुल सुवेदार"
            },
            {
                "label": "जागीर सुमानी सुवेदार 0119",
                "village_id": 24,
                "village_name": "जागीर सुमानी सुवेदार"
            },
            {
                "label": "जागीर सहादत खॉ 0096",
                "village_id": 25,
                "village_name": "जागीर सहादत खॉ"
            },
            {
                "label": "जागीर हसनशरीफ जमादार 0128",
                "village_id": 26,
                "village_name": "जागीर हसनशरीफ जमादार"
            },
            {
                "label": "जागीर हिदायत कुमेदार 94/1",
                "village_id": 27,
                "village_name": "जागीर हिदायत कुमेदार"
            },
            {
                "label": "जागीर हियामनुल्‍लाह सुबेदार 0117",
                "village_id": 28,
                "village_name": "जागीर हियामनुल्‍लाह सुबेदार"
            },
            {
                "label": "जागीऱजीत सिंह सुवेदार 93/2",
                "village_id": 29,
                "village_name": "जागीऱजीत सिंह सुवेदार"
            },
            {
                "label": "जागीरदीन मुहम्मद सुवेदार 95/2",
                "village_id": 30,
                "village_name": "जागीरदीन मुहम्मद सुवेदार"
            },
            {
                "label": "दीन मुहम्‍मद जागीर सुबेदार 95/1",
                "village_id": 31,
                "village_name": "दीन मुहम्‍मद जागीर सुबेदार"
            },
            {
                "label": "धनेशपुर 0106",
                "village_id": 32,
                "village_name": "धनेशपुर"
            },
            {
                "label": "धोबई 0105",
                "village_id": 33,
                "village_name": "धोबई"
            },
            {
                "label": "नियामुनुल्‍लाह सिपाही जागीर 0124",
                "village_id": 34,
                "village_name": "नियामुनुल्‍लाह सिपाही जागीर"
            },
            {
                "label": "पंचरासी 0091",
                "village_id": 35,
                "village_name": "पंचरासी"
            },
            {
                "label": "पंचरासी अमानत सरकार 0090",
                "village_id": 36,
                "village_name": "पंचरासी अमानत सरकार"
            },
            {
                "label": "पैना 0107",
                "village_id": 37,
                "village_name": "पैना"
            },
            {
                "label": "पहाड़ी सुवैदार 0115",
                "village_id": 38,
                "village_name": "पहाड़ी सुवैदार"
            },
            {
                "label": "फुलौत 0088",
                "village_id": 39,
                "village_name": "फुलौत"
            },
            {
                "label": "भटगामा 0098",
                "village_id": 40,
                "village_name": "भटगामा"
            },
            {
                "label": "मोरसंडा 0089",
                "village_id": 41,
                "village_name": "मोरसंडा"
            },
            {
                "label": "रसूलपुर धूरिया 0130",
                "village_id": 42,
                "village_name": "रसूलपुर धूरिया"
            },
            {
                "label": "रामाकांत सुबेदार 0118",
                "village_id": 43,
                "village_name": "रामाकांत सुबेदार"
            },
            {
                "label": "लौआ लगान 0092",
                "village_id": 44,
                "village_name": "लौआ लगान"
            }
        ],
        "block_id": "11",
        "block_no": 12,
        "block_name": "चौसा [011]",
        "sub_div_code": "2"
    }
],
    "district_id": "11",
    "district_name": "मधेपुरा"
},
{
    "block": [
    {
        "village": [
            {
                "label": "अकबर पुर 0688",
                "village_id": 2,
                "village_name": "अकबर पुर"
            },
            {
                "label": "अतररदह 0342",
                "village_id": 3,
                "village_name": "अतररदह"
            },
            {
                "label": "अब्दुलनगर माधोपुर 0684",
                "village_id": 4,
                "village_name": "अब्दुलनगर माधोपुर"
            },
            {
                "label": "अहियापुर 0666",
                "village_id": 5,
                "village_name": "अहियापुर"
            },
            {
                "label": "कन्हौली अजरकवे 0412",
                "village_id": 6,
                "village_name": "कन्हौली अजरकवे"
            },
            {
                "label": "कन्हौली विशुनदत्त 0411",
                "village_id": 7,
                "village_name": "कन्हौली विशुनदत्त"
            },
            {
                "label": "कोठिया दाखिली 0413",
                "village_id": 8,
                "village_name": "कोठिया दाखिली"
            },
            {
                "label": "खबङा 0334",
                "village_id": 9,
                "village_name": "खबङा"
            },
            {
                "label": "गणेशपुर 0667",
                "village_id": 10,
                "village_name": "गणेशपुर"
            },
            {
                "label": "गोपालपुर तरौरा 0416",
                "village_id": 11,
                "village_name": "गोपालपुर तरौरा"
            },
            {
                "label": "गोवर्धनपुर 0354",
                "village_id": 12,
                "village_name": "गोवर्धनपुर"
            },
            {
                "label": "चक् अहमद 0339",
                "village_id": 13,
                "village_name": "चक् अहमद"
            },
            {
                "label": "चक मोहब्बत 0670",
                "village_id": 14,
                "village_name": "चक मोहब्बत"
            },
            {
                "label": "चकअहलेयाद 0273",
                "village_id": 15,
                "village_name": "चकअहलेयाद"
            },
            {
                "label": "चकसलेम 0693",
                "village_id": 16,
                "village_name": "चकसलेम"
            },
            {
                "label": "चतुरी पुनास 0683",
                "village_id": 17,
                "village_name": "चतुरी पुनास"
            },
            {
                "label": "चनदबारा 0476",
                "village_id": 18,
                "village_name": "चनदबारा"
            },
            {
                "label": "चौसीमा 0356",
                "village_id": 19,
                "village_name": "चौसीमा"
            },
            {
                "label": "छपड़ा मेघ 0421",
                "village_id": 20,
                "village_name": "छपड़ा मेघ"
            },
            {
                "label": "छपरा मेघ 0421",
                "village_id": 21,
                "village_name": "छपरा मेघ"
            },
            {
                "label": "जमालाबाद 0511",
                "village_id": 22,
                "village_name": "जमालाबाद"
            },
            {
                "label": "जलालपुर 0433",
                "village_id": 23,
                "village_name": "जलालपुर"
            },
            {
                "label": "जलालपुर बलभद्र 0437",
                "village_id": 24,
                "village_name": "जलालपुर बलभद्र"
            },
            {
                "label": "टुपामारी 0027",
                "village_id": 25,
                "village_name": "टुपामारी"
            },
            {
                "label": "टरवा 0682",
                "village_id": 26,
                "village_name": "टरवा"
            },
            {
                "label": "डूमरी 0332",
                "village_id": 27,
                "village_name": "डूमरी"
            },
            {
                "label": "द्वारीका नगर 0432",
                "village_id": 28,
                "village_name": "द्वारीका नगर"
            },
            {
                "label": "दामोदरपुर 0674",
                "village_id": 29,
                "village_name": "दामोदरपुर"
            },
            {
                "label": "दीघड़ा पट्टी 0275",
                "village_id": 30,
                "village_name": "दीघड़ा पट्टी"
            },
            {
                "label": "धर्मपुर 0327",
                "village_id": 31,
                "village_name": "धर्मपुर"
            },
            {
                "label": "धीरन छपड़ा 0280",
                "village_id": 32,
                "village_name": "धीरन छपड़ा"
            },
            {
                "label": "धोबहाँ 0697",
                "village_id": 33,
                "village_name": "धोबहाँ"
            },
            {
                "label": "नगर पालिका मुजफफरपुर वार्ड न.8 0000",
                "village_id": 34,
                "village_name": "नगर पालिका मुजफफरपुर वार्ड न.8"
            },
            {
                "label": "नगर पालिका वार्ड न. 19 0000",
                "village_id": 35,
                "village_name": "नगर पालिका वार्ड न. 19"
            },
            {
                "label": "नगर पालिका वार्ड न.-19 0347",
                "village_id": 36,
                "village_name": "नगर पालिका वार्ड न.-19"
            },
            {
                "label": "नगर पालिका वार्ड न.6 0000",
                "village_id": 37,
                "village_name": "नगर पालिका वार्ड न.6"
            },
            {
                "label": "नगर पालिका वार्ड सं. 28 0000",
                "village_id": 38,
                "village_name": "नगर पालिका वार्ड सं. 28"
            },
            {
                "label": "नगर पालिकावार्ड न.18 0000",
                "village_id": 39,
                "village_name": "नगर पालिकावार्ड न.18"
            },
            {
                "label": "नगरपालिका वार्ड संख्या - 14 0000",
                "village_id": 40,
                "village_name": "नगरपालिका वार्ड संख्या - 14"
            },
            {
                "label": "नगरपालीका वार्ड न.7 0000",
                "village_id": 41,
                "village_name": "नगरपालीका वार्ड न.7"
            },
            {
                "label": "नया गांव 0423",
                "village_id": 42,
                "village_name": "नया गांव"
            },
            {
                "label": "नया सर्वे खतीयान वार्ड न. 20 0000",
                "village_id": 43,
                "village_name": "नया सर्वे खतीयान वार्ड न. 20"
            },
            {
                "label": "नरौली कल्याण 0429",
                "village_id": 44,
                "village_name": "नरौली कल्याण"
            },
            {
                "label": "नरौली डीह 0430",
                "village_id": 45,
                "village_name": "नरौली डीह"
            },
            {
                "label": "नरौली सेन 0428",
                "village_id": 46,
                "village_name": "नरौली सेन"
            },
            {
                "label": "नवादा 0419",
                "village_id": 47,
                "village_name": "नवादा"
            },
            {
                "label": "नाजिर पुर वार्ड 10 0000",
                "village_id": 48,
                "village_name": "नाजिर पुर वार्ड 10"
            },
            {
                "label": "नाजीरपुर 0669",
                "village_id": 49,
                "village_name": "नाजीरपुर"
            },
            {
                "label": "नान्हुचक 0692",
                "village_id": 50,
                "village_name": "नान्हुचक"
            },
            {
                "label": "पकड़ी इसमाइल 0330",
                "village_id": 51,
                "village_name": "पकड़ी इसमाइल"
            },
            {
                "label": "पताही 0355",
                "village_id": 52,
                "village_name": "पताही"
            },
            {
                "label": "परमानन्द पुर 0331",
                "village_id": 53,
                "village_name": "परमानन्द पुर"
            },
            {
                "label": "प्रहलादपुर 0417",
                "village_id": 54,
                "village_name": "प्रहलादपुर"
            },
            {
                "label": "पीर मोहम्मदपुर 0691",
                "village_id": 55,
                "village_name": "पीर मोहम्मदपुर"
            },
            {
                "label": "फतहपुर 0657",
                "village_id": 56,
                "village_name": "फतहपुर"
            },
            {
                "label": "बैकठ़पुर 0470",
                "village_id": 57,
                "village_name": "बैकठ़पुर"
            },
            {
                "label": "बखरी चन्दन 0656",
                "village_id": 58,
                "village_name": "बखरी चन्दन"
            },
            {
                "label": "बड़ा जगरनाथ 0675",
                "village_id": 59,
                "village_name": "बड़ा जगरनाथ"
            },
            {
                "label": "बुधनगरा जग्रनाथ 0701",
                "village_id": 60,
                "village_name": "बुधनगरा जग्रनाथ"
            },
            {
                "label": "बुधनगरा राधो 0700",
                "village_id": 61,
                "village_name": "बुधनगरा राधो"
            },
            {
                "label": "बेला छपड़ा 0281",
                "village_id": 62,
                "village_name": "बेला छपड़ा"
            },
            {
                "label": "बहादुर पुर 0690",
                "village_id": 63,
                "village_name": "बहादुर पुर"
            },
            {
                "label": "बारमतपुर 0355",
                "village_id": 64,
                "village_name": "बारमतपुर"
            },
            {
                "label": "बिन्दा 0472",
                "village_id": 65,
                "village_name": "बिन्दा"
            },
            {
                "label": "बिशुनपुर मनोहर 0427",
                "village_id": 66,
                "village_name": "बिशुनपुर मनोहर"
            },
            {
                "label": "भगवतीपुर 0671",
                "village_id": 67,
                "village_name": "भगवतीपुर"
            },
            {
                "label": "भगवतीपुर छींट     ",
                "village_id": 68,
                "village_name": "भगवतीपुर छींट"
            },
            {
                "label": "भटौलिया 0415",
                "village_id": 69,
                "village_name": "भटौलिया"
            },
            {
                "label": "भिखुनपुर डिह 0340",
                "village_id": 70,
                "village_name": "भिखुनपुर डिह"
            },
            {
                "label": "भीखनपुर 0770",
                "village_id": 71,
                "village_name": "भीखनपुर"
            },
            {
                "label": "भीखनपुर आदम 0341",
                "village_id": 72,
                "village_name": "भीखनपुर आदम"
            },
            {
                "label": "मुकुन्‍दपुर 0718",
                "village_id": 73,
                "village_name": "मुकुन्‍दपुर"
            },
            {
                "label": "मझौलिया 0333",
                "village_id": 74,
                "village_name": "मझौलिया"
            },
            {
                "label": "मझौली धर्मदास 0284",
                "village_id": 75,
                "village_name": "मझौली धर्मदास"
            },
            {
                "label": "मणिका विशुनपुर चाँद 0474",
                "village_id": 76,
                "village_name": "मणिका विशुनपुर चाँद"
            },
            {
                "label": "मेथुरा पुर 0361",
                "village_id": 77,
                "village_name": "मेथुरा पुर"
            },
            {
                "label": "मधुवनी 0357",
                "village_id": 78,
                "village_name": "मधुवनी"
            },
            {
                "label": "मनिका गाजी 0426",
                "village_id": 79,
                "village_name": "मनिका गाजी"
            },
            {
                "label": "मुरादपुर दुलह 0665",
                "village_id": 80,
                "village_name": "मुरादपुर दुलह"
            },
            {
                "label": "महमदपुर काजी 0344",
                "village_id": 81,
                "village_name": "महमदपुर काजी"
            },
            {
                "label": "महम्मदपुर डूमरी 0775",
                "village_id": 82,
                "village_name": "महम्मदपुर डूमरी"
            },
            {
                "label": "मादापुर चौबे 0358",
                "village_id": 83,
                "village_name": "मादापुर चौबे"
            },
            {
                "label": "माधोपुर 0469",
                "village_id": 84,
                "village_name": "माधोपुर"
            },
            {
                "label": "मानिकपुर बुधनगरा 0699",
                "village_id": 85,
                "village_name": "मानिकपुर बुधनगरा"
            },
            {
                "label": "मिठुनपुर लाला 0410",
                "village_id": 86,
                "village_name": "मिठुनपुर लाला"
            },
            {
                "label": "मीठनपुरा 0659",
                "village_id": 87,
                "village_name": "मीठनपुरा"
            },
            {
                "label": "मीरदाही 0686",
                "village_id": 88,
                "village_name": "मीरदाही"
            },
            {
                "label": "मीरदाही 0689",
                "village_id": 89,
                "village_name": "मीरदाही"
            },
            {
                "label": "मोमीनपुर 0473",
                "village_id": 90,
                "village_name": "मोमीनपुर"
            },
            {
                "label": "रघुनाथ पुर 0438",
                "village_id": 91,
                "village_name": "रघुनाथ पुर"
            },
            {
                "label": "रजवाङा भगवान 0698",
                "village_id": 92,
                "village_name": "रजवाङा भगवान"
            },
            {
                "label": "रजवाड़ा डिह 0695",
                "village_id": 93,
                "village_name": "रजवाड़ा डिह"
            },
            {
                "label": "रतबाड़ा 0285",
                "village_id": 94,
                "village_name": "रतबाड़ा"
            },
            {
                "label": "रसुलपुर शैयद वाजीद 0661",
                "village_id": 95,
                "village_name": "रसुलपुर शैयद वाजीद"
            },
            {
                "label": "रसुलपुर सैयद सलेम 0662",
                "village_id": 96,
                "village_name": "रसुलपुर सैयद सलेम"
            },
            {
                "label": "राघोपुर 0676",
                "village_id": 97,
                "village_name": "राघोपुर"
            },
            {
                "label": "राजा पुनास 0687",
                "village_id": 98,
                "village_name": "राजा पुनास"
            },
            {
                "label": "राधानगर उर्फ मुशहरी 0475",
                "village_id": 99,
                "village_name": "राधानगर उर्फ मुशहरी"
            },
            {
                "label": "रामपुर साह 0276",
                "village_id": 100,
                "village_name": "रामपुर साह"
            },
            {
                "label": "रोशनपुर 0694",
                "village_id": 101,
                "village_name": "रोशनपुर"
            },
            {
                "label": "रोहुआ आपुछ 0279",
                "village_id": 102,
                "village_name": "रोहुआ आपुछ"
            },
            {
                "label": "रोहुआ राजाराम 0414",
                "village_id": 103,
                "village_name": "रोहुआ राजाराम"
            },
            {
                "label": "रोहुआ विरनारायन 0277",
                "village_id": 104,
                "village_name": "रोहुआ विरनारायन"
            },
            {
                "label": "वैजनाथ पुर 0681",
                "village_id": 105,
                "village_name": "वैजनाथ पुर"
            },
            {
                "label": "वार्ड संख्या 1 0000",
                "village_id": 106,
                "village_name": "वार्ड संख्या 1"
            },
            {
                "label": "वार्ड संख्या 11 0000",
                "village_id": 107,
                "village_name": "वार्ड संख्या 11"
            },
            {
                "label": "वार्ड संख्या 12 0000",
                "village_id": 108,
                "village_name": "वार्ड संख्या 12"
            },
            {
                "label": "वार्ड संख्या 13 0000",
                "village_id": 109,
                "village_name": "वार्ड संख्या 13"
            },
            {
                "label": "वार्ड संख्या 14 0000",
                "village_id": 110,
                "village_name": "वार्ड संख्या 14"
            },
            {
                "label": "वार्ड संख्या 15 0000",
                "village_id": 111,
                "village_name": "वार्ड संख्या 15"
            },
            {
                "label": "वार्ड संख्या 16 0000",
                "village_id": 112,
                "village_name": "वार्ड संख्या 16"
            },
            {
                "label": "वार्ड संख्या 17 0000",
                "village_id": 113,
                "village_name": "वार्ड संख्या 17"
            },
            {
                "label": "वार्ड संख्या 2 0000",
                "village_id": 114,
                "village_name": "वार्ड संख्या 2"
            },
            {
                "label": "वार्ड संख्या 21 0000",
                "village_id": 115,
                "village_name": "वार्ड संख्या 21"
            },
            {
                "label": "वार्ड संख्या 22 0000",
                "village_id": 116,
                "village_name": "वार्ड संख्या 22"
            },
            {
                "label": "वार्ड संख्या 23 0000",
                "village_id": 117,
                "village_name": "वार्ड संख्या 23"
            },
            {
                "label": "वार्ड संख्या 24 0000",
                "village_id": 118,
                "village_name": "वार्ड संख्या 24"
            },
            {
                "label": "वार्ड संख्या 29 0000",
                "village_id": 119,
                "village_name": "वार्ड संख्या 29"
            },
            {
                "label": "वार्ड संख्या 3 0000",
                "village_id": 120,
                "village_name": "वार्ड संख्या 3"
            },
            {
                "label": "वार्ड संख्या 30 0000",
                "village_id": 121,
                "village_name": "वार्ड संख्या 30"
            },
            {
                "label": "वार्ड संख्या 31 0000",
                "village_id": 122,
                "village_name": "वार्ड संख्या 31"
            },
            {
                "label": "वार्ड संख्या 32 . 0000",
                "village_id": 123,
                "village_name": "वार्ड संख्या 32 ."
            },
            {
                "label": "वार्ड संख्या 4 0000",
                "village_id": 124,
                "village_name": "वार्ड संख्या 4"
            },
            {
                "label": "विशुनपुर मानशाही 0425",
                "village_id": 125,
                "village_name": "विशुनपुर मानशाही"
            },
            {
                "label": "शेखपुरा 0668",
                "village_id": 126,
                "village_name": "शेखपुरा"
            },
            {
                "label": "शेरपुर 0283",
                "village_id": 127,
                "village_name": "शेरपुर"
            },
            {
                "label": "शेरपुर 0282",
                "village_id": 128,
                "village_name": "शेरपुर"
            },
            {
                "label": "शाहपुर 0672",
                "village_id": 129,
                "village_name": "शाहपुर"
            },
            {
                "label": "सगहरी 0274",
                "village_id": 130,
                "village_name": "सगहरी"
            },
            {
                "label": "सुतिहार 0432",
                "village_id": 131,
                "village_name": "सुतिहार"
            },
            {
                "label": "सुतिहारा 0431",
                "village_id": 132,
                "village_name": "सुतिहारा"
            },
            {
                "label": "सभापुर 0420",
                "village_id": 133,
                "village_name": "सभापुर"
            },
            {
                "label": "सरैया सहदुल्लाहपुर 0677",
                "village_id": 134,
                "village_name": "सरैया सहदुल्लाहपुर"
            },
            {
                "label": "सरैयाचक उर्फ सिपाहपुर 0678",
                "village_id": 135,
                "village_name": "सरैयाचक उर्फ सिपाहपुर"
            },
            {
                "label": "सलहा 0471",
                "village_id": 136,
                "village_name": "सलहा"
            },
            {
                "label": "सुस्ता 0286",
                "village_id": 137,
                "village_name": "सुस्ता"
            },
            {
                "label": "सहदुल्ल्हपुर 0673",
                "village_id": 138,
                "village_name": "सहदुल्ल्हपुर"
            },
            {
                "label": "सहबाजपुर 0663",
                "village_id": 139,
                "village_name": "सहबाजपुर"
            },
            {
                "label": "सहवाजपुर सलेम 0658",
                "village_id": 140,
                "village_name": "सहवाजपुर सलेम"
            },
            {
                "label": "हरपुर 0685",
                "village_id": 141,
                "village_name": "हरपुर"
            },
            {
                "label": "हरपुर वखरी 0679",
                "village_id": 142,
                "village_name": "हरपुर वखरी"
            },
            {
                "label": "हसनचक 0680",
                "village_id": 143,
                "village_name": "हसनचक"
            },
            {
                "label": "हसनचक 0288",
                "village_id": 144,
                "village_name": "हसनचक"
            }
        ],
        "block_id": "1",
        "block_no": 0,
        "block_name": "मुशहरी [001]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अधवारा 0456",
                "village_id": 2,
                "village_name": "अधवारा"
            },
            {
                "label": "कटेसर 0846",
                "village_id": 3,
                "village_name": "कटेसर"
            },
            {
                "label": "केशोपुर 0955",
                "village_id": 4,
                "village_name": "केशोपुर"
            },
            {
                "label": "कान्ह मनियारी 0444",
                "village_id": 5,
                "village_name": "कान्ह मनियारी"
            },
            {
                "label": "खलीकनगर उर्फ गैरिहार 0822",
                "village_id": 6,
                "village_name": "खलीकनगर उर्फ गैरिहार"
            },
            {
                "label": "खानपुर प्यारे उर्फ पिआर 0966",
                "village_id": 7,
                "village_name": "खानपुर प्यारे उर्फ पिआर"
            },
            {
                "label": "खोमाईपट्टी  760",
                "village_id": 8,
                "village_name": "खोमाईपट्टी"
            },
            {
                "label": "गनियारी 0827",
                "village_id": 9,
                "village_name": "गनियारी"
            },
            {
                "label": "गोपालपुर खुर्द 0866",
                "village_id": 10,
                "village_name": "गोपालपुर खुर्द"
            },
            {
                "label": "गोपालपुर राजाराम 0836",
                "village_id": 11,
                "village_name": "गोपालपुर राजाराम"
            },
            {
                "label": "गोवर्धनपुर 0797",
                "village_id": 12,
                "village_name": "गोवर्धनपुर"
            },
            {
                "label": "चक फिरोज 0835",
                "village_id": 13,
                "village_name": "चक फिरोज"
            },
            {
                "label": "चकअजमेरी 0932",
                "village_id": 14,
                "village_name": "चकअजमेरी"
            },
            {
                "label": "चकदह पचदही 0452",
                "village_id": 15,
                "village_name": "चकदह पचदही"
            },
            {
                "label": "चकफिरोज 0835",
                "village_id": 16,
                "village_name": "चकफिरोज"
            },
            {
                "label": "चकरावे मनियारी 0931",
                "village_id": 17,
                "village_name": "चकरावे मनियारी"
            },
            {
                "label": "चन्दनपट्टी 0855",
                "village_id": 18,
                "village_name": "चन्दनपट्टी"
            },
            {
                "label": "चौधपुर चिकनौटा 0954",
                "village_id": 19,
                "village_name": "चौधपुर चिकनौटा"
            },
            {
                "label": "छपरा फरीद 0941",
                "village_id": 20,
                "village_name": "छपरा फरीद"
            },
            {
                "label": "छोटालौचा 0186",
                "village_id": 21,
                "village_name": "छोटालौचा"
            },
            {
                "label": "जगदीशपुर वघनगरी 0802",
                "village_id": 22,
                "village_name": "जगदीशपुर वघनगरी"
            },
            {
                "label": "जोगनी गंगा 0824",
                "village_id": 23,
                "village_name": "जोगनी गंगा"
            },
            {
                "label": "टंगटंगी 0216",
                "village_id": 24,
                "village_name": "टंगटंगी"
            },
            {
                "label": "डिहुली इसहाक 0963",
                "village_id": 25,
                "village_name": "डिहुली इसहाक"
            },
            {
                "label": "डिहुली बुजुर्ग 0958",
                "village_id": 26,
                "village_name": "डिहुली बुजुर्ग"
            },
            {
                "label": "तुलसी मोहनपुर 0869",
                "village_id": 27,
                "village_name": "तुलसी मोहनपुर"
            },
            {
                "label": "तारा विशुनपुर 0450",
                "village_id": 28,
                "village_name": "तारा विशुनपुर"
            },
            {
                "label": "थतिया 0798",
                "village_id": 29,
                "village_name": "थतिया"
            },
            {
                "label": "दुबहा बुजुर्ग 0964",
                "village_id": 30,
                "village_name": "दुबहा बुजुर्ग"
            },
            {
                "label": "नरसिंहपुर 0868",
                "village_id": 31,
                "village_name": "नरसिंहपुर"
            },
            {
                "label": "नवलपुर मिश्रौलिया 0801",
                "village_id": 32,
                "village_name": "नवलपुर मिश्रौलिया"
            },
            {
                "label": "नारोपट्टी 0962",
                "village_id": 33,
                "village_name": "नारोपट्टी"
            },
            {
                "label": "पैगम्बरपुर 0810",
                "village_id": 34,
                "village_name": "पैगम्बरपुर"
            },
            {
                "label": "पटशामा करियात 0843",
                "village_id": 35,
                "village_name": "पटशामा करियात"
            },
            {
                "label": "परसुराम पचदही 0453",
                "village_id": 36,
                "village_name": "परसुराम पचदही"
            },
            {
                "label": "पहाड़पुर 0167",
                "village_id": 37,
                "village_name": "पहाड़पुर"
            },
            {
                "label": "पिपरी 0959",
                "village_id": 38,
                "village_name": "पिपरी"
            },
            {
                "label": "फिरोजपुर 0832",
                "village_id": 39,
                "village_name": "फिरोजपुर"
            },
            {
                "label": "बखरी 0828",
                "village_id": 40,
                "village_name": "बखरी"
            },
            {
                "label": "बेरूआ डीह 0814",
                "village_id": 41,
                "village_name": "बेरूआ डीह"
            },
            {
                "label": "बसंतपुर गौस 0949",
                "village_id": 42,
                "village_name": "बसंतपुर गौस"
            },
            {
                "label": "बसन्तपुर झिटकाही 0851",
                "village_id": 43,
                "village_name": "बसन्तपुर झिटकाही"
            },
            {
                "label": "बहरामपुर 0965",
                "village_id": 44,
                "village_name": "बहरामपुर"
            },
            {
                "label": "बाजी राउत 0845",
                "village_id": 45,
                "village_name": "बाजी राउत"
            },
            {
                "label": "बीरपुर 0205",
                "village_id": 46,
                "village_name": "बीरपुर"
            },
            {
                "label": "भरवाडी 0809",
                "village_id": 47,
                "village_name": "भरवाडी"
            },
            {
                "label": "मकसुदनपुर फकीरा 0936",
                "village_id": 48,
                "village_name": "मकसुदनपुर फकीरा"
            },
            {
                "label": "मछदी 0945",
                "village_id": 49,
                "village_name": "मछदी"
            },
            {
                "label": "मझौलिया 0854",
                "village_id": 50,
                "village_name": "मझौलिया"
            },
            {
                "label": "मडई ख़ुर्द 0946",
                "village_id": 51,
                "village_name": "मडई ख़ुर्द"
            },
            {
                "label": "मेथौड़ा उर्फ मेथुरापुर 0813",
                "village_id": 52,
                "village_name": "मेथौड़ा उर्फ मेथुरापुर"
            },
            {
                "label": "मधुसूदनपुर माल 0937",
                "village_id": 53,
                "village_name": "मधुसूदनपुर माल"
            },
            {
                "label": "मरवन उर्फ अजीजनगर 0458",
                "village_id": 54,
                "village_name": "मरवन उर्फ अजीजनगर"
            },
            {
                "label": "मुरा हरलोचनपुर 0926",
                "village_id": 55,
                "village_name": "मुरा हरलोचनपुर"
            },
            {
                "label": "मुरियारी 0829",
                "village_id": 56,
                "village_name": "मुरियारी"
            },
            {
                "label": "मुशहरी नागीन 0870",
                "village_id": 57,
                "village_name": "मुशहरी नागीन"
            },
            {
                "label": "मुसहरी राम 0865",
                "village_id": 58,
                "village_name": "मुसहरी राम"
            },
            {
                "label": "महदमपुर कंढ 0460",
                "village_id": 59,
                "village_name": "महदमपुर कंढ"
            },
            {
                "label": "महनैली पंचदही 0454",
                "village_id": 60,
                "village_name": "महनैली पंचदही"
            },
            {
                "label": "महमदपुर धर्म उर्फ मरूआंहा 0838",
                "village_id": 61,
                "village_name": "महमदपुर धर्म उर्फ मरूआंहा"
            },
            {
                "label": "महमदपुर बनबारी 0834",
                "village_id": 62,
                "village_name": "महमदपुर बनबारी"
            },
            {
                "label": "महम्मत पुर भोपत 0833",
                "village_id": 63,
                "village_name": "महम्मत पुर भोपत"
            },
            {
                "label": "महम्‍मदपुर बुजुर्ग 0443",
                "village_id": 64,
                "village_name": "महम्‍मदपुर बुजुर्ग"
            },
            {
                "label": "मारकन 0463",
                "village_id": 65,
                "village_name": "मारकन"
            },
            {
                "label": "मिश्रौलिया 0939",
                "village_id": 66,
                "village_name": "मिश्रौलिया"
            },
            {
                "label": "मोहनपुर मुन्डा 0934",
                "village_id": 67,
                "village_name": "मोहनपुर मुन्डा"
            },
            {
                "label": "मोहीउद्दीनपुर ×³ÏÚ",
                "village_id": 68,
                "village_name": "मोहीउद्दीनपुर"
            },
            {
                "label": "रघुनाथपुर दोनवा 0850",
                "village_id": 69,
                "village_name": "रघुनाथपुर दोनवा"
            },
            {
                "label": "रघुवरपुर 0864",
                "village_id": 70,
                "village_name": "रघुवरपुर"
            },
            {
                "label": "रतनपुर केवल 0837",
                "village_id": 71,
                "village_name": "रतनपुर केवल"
            },
            {
                "label": "रुपन पट्टी मेथुरापुर 0799",
                "village_id": 72,
                "village_name": "रुपन पट्टी मेथुरापुर"
            },
            {
                "label": "रेपुरा 0461",
                "village_id": 73,
                "village_name": "रेपुरा"
            },
            {
                "label": "रसूलपुर ताजू 0861",
                "village_id": 74,
                "village_name": "रसूलपुर ताजू"
            },
            {
                "label": "रसुलपुर मनियारी 0856",
                "village_id": 75,
                "village_name": "रसुलपुर मनियारी"
            },
            {
                "label": "रहीमपुर जीवन उर्फ रक्सा 0948",
                "village_id": 76,
                "village_name": "रहीमपुर जीवन उर्फ रक्सा"
            },
            {
                "label": "राजा पाकड़ 0839",
                "village_id": 77,
                "village_name": "राजा पाकड़"
            },
            {
                "label": "राजापुर 0961",
                "village_id": 78,
                "village_name": "राजापुर"
            },
            {
                "label": "राजोरापुर उर्फ वनजरियाँ 0849",
                "village_id": 79,
                "village_name": "राजोरापुर उर्फ वनजरियाँ"
            },
            {
                "label": "रामनगर 0804",
                "village_id": 80,
                "village_name": "रामनगर"
            },
            {
                "label": "रामपुर बखरी 0925",
                "village_id": 81,
                "village_name": "रामपुर बखरी"
            },
            {
                "label": "रामपुर मनी 0451",
                "village_id": 82,
                "village_name": "रामपुर मनी"
            },
            {
                "label": "रामपुर मसोन 0840",
                "village_id": 83,
                "village_name": "रामपुर मसोन"
            },
            {
                "label": "रामपुर राजे उर्फ बनजरिया 0849",
                "village_id": 84,
                "village_name": "रामपुर राजे उर्फ बनजरिया"
            },
            {
                "label": "रामीरामपुर 0860",
                "village_id": 85,
                "village_name": "रामीरामपुर"
            },
            {
                "label": "रायपुर 0938",
                "village_id": 86,
                "village_name": "रायपुर"
            },
            {
                "label": "वरियारपुर 0823",
                "village_id": 87,
                "village_name": "वरियारपुर"
            },
            {
                "label": "वाजिदपुर अशोक 0841",
                "village_id": 88,
                "village_name": "वाजिदपुर अशोक"
            },
            {
                "label": "वाजी उर्फ मनहर पटी 0947",
                "village_id": 89,
                "village_name": "वाजी उर्फ मनहर पटी"
            },
            {
                "label": "वासदेवपुर वृत 0013",
                "village_id": 90,
                "village_name": "वासदेवपुर वृत"
            },
            {
                "label": "विद्धिपुर 0808",
                "village_id": 91,
                "village_name": "विद्धिपुर"
            },
            {
                "label": "विशुनपुर बघनगरी 0803",
                "village_id": 92,
                "village_name": "विशुनपुर बघनगरी"
            },
            {
                "label": "श्यामपुर 0842",
                "village_id": 93,
                "village_name": "श्यामपुर"
            },
            {
                "label": "शाहपुर जुनैद 0821",
                "village_id": 94,
                "village_name": "शाहपुर जुनैद"
            },
            {
                "label": "सकरा फरीदपुर 0853",
                "village_id": 95,
                "village_name": "सकरा फरीदपुर"
            },
            {
                "label": "सकरा मंसूरपुर 0862",
                "village_id": 96,
                "village_name": "सकरा मंसूरपुर"
            },
            {
                "label": "सकरा वाजिद 0863",
                "village_id": 97,
                "village_name": "सकरा वाजिद"
            },
            {
                "label": "सुजावलपुर 0943",
                "village_id": 98,
                "village_name": "सुजावलपुर"
            },
            {
                "label": "सुजावलपुर चौसीमा 0800",
                "village_id": 99,
                "village_name": "सुजावलपुर चौसीमा"
            },
            {
                "label": "सतपुरा बुजुर्ग 0857",
                "village_id": 100,
                "village_name": "सतपुरा बुजुर्ग"
            },
            {
                "label": "सताल 0361",
                "village_id": 101,
                "village_name": "सताल"
            },
            {
                "label": "सुन्दर पुर 0844",
                "village_id": 102,
                "village_name": "सुन्दर पुर"
            },
            {
                "label": "सपाही 0457",
                "village_id": 103,
                "village_name": "सपाही"
            },
            {
                "label": "सबहा महदेईया 0944",
                "village_id": 104,
                "village_name": "सबहा महदेईया"
            },
            {
                "label": "सेमरा 0956",
                "village_id": 105,
                "village_name": "सेमरा"
            },
            {
                "label": "सरमस्तपुर 0942",
                "village_id": 106,
                "village_name": "सरमस्तपुर"
            },
            {
                "label": "सरैया 0805",
                "village_id": 107,
                "village_name": "सरैया"
            },
            {
                "label": "सुस्‍ता 0455",
                "village_id": 108,
                "village_name": "सुस्‍ता"
            },
            {
                "label": "सादुल्लाहपुर 0940",
                "village_id": 109,
                "village_name": "सादुल्लाहपुर"
            },
            {
                "label": "सांधोपट्टी 0807",
                "village_id": 110,
                "village_name": "सांधोपट्टी"
            },
            {
                "label": "सिमरा 0956",
                "village_id": 111,
                "village_name": "सिमरा"
            },
            {
                "label": "सिमरी 0957",
                "village_id": 112,
                "village_name": "सिमरी"
            },
            {
                "label": "सिराजाबाद 0952",
                "village_id": 113,
                "village_name": "सिराजाबाद"
            },
            {
                "label": "सिहो 0793",
                "village_id": 114,
                "village_name": "सिहो"
            },
            {
                "label": "हरपुर कृष्ण 0792",
                "village_id": 115,
                "village_name": "हरपुर कृष्ण"
            },
            {
                "label": "हसनपुर बगाही 0867",
                "village_id": 116,
                "village_name": "हसनपुर बगाही"
            }
        ],
        "block_id": "2",
        "block_no": 1,
        "block_name": "सकरा [002]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अब्दुलपुर रैनी 0919",
                "village_id": 2,
                "village_name": "अब्दुलपुर रैनी"
            },
            {
                "label": "कालपीर पथरधट्टी 0127",
                "village_id": 3,
                "village_name": "कालपीर पथरधट्टी"
            },
            {
                "label": "कास्त तुलसिया 0115",
                "village_id": 4,
                "village_name": "कास्त तुलसिया"
            },
            {
                "label": "खरौना 0796",
                "village_id": 5,
                "village_name": "खरौना"
            },
            {
                "label": "खासपट्टी जदुनाथपुर 0791",
                "village_id": 6,
                "village_name": "खासपट्टी जदुनाथपुर"
            },
            {
                "label": "गोपालपुर लाला 0468",
                "village_id": 7,
                "village_name": "गोपालपुर लाला"
            },
            {
                "label": "गोपीनाथपुर 0466",
                "village_id": 8,
                "village_name": "गोपीनाथपुर"
            },
            {
                "label": "चकसेन 0873",
                "village_id": 9,
                "village_name": "चकसेन"
            },
            {
                "label": "चिमनापुर 0922",
                "village_id": 10,
                "village_name": "चिमनापुर"
            },
            {
                "label": "तितरा आशानन्द 0795",
                "village_id": 11,
                "village_name": "तितरा आशानन्द"
            },
            {
                "label": "दरघा चौसड़ा 0779",
                "village_id": 12,
                "village_name": "दरघा चौसड़ा"
            },
            {
                "label": "द्वारीकापुर 0928",
                "village_id": 13,
                "village_name": "द्वारीकापुर"
            },
            {
                "label": "धर्मागतपुर 0786",
                "village_id": 14,
                "village_name": "धर्मागतपुर"
            },
            {
                "label": "नेमोपुर 0920",
                "village_id": 15,
                "village_name": "नेमोपुर"
            },
            {
                "label": "नेमोंपुर 0920",
                "village_id": 16,
                "village_name": "नेमोंपुर"
            },
            {
                "label": "पखवाहां महेश 0042",
                "village_id": 17,
                "village_name": "पखवाहां महेश"
            },
            {
                "label": "पदमपुर 0297",
                "village_id": 18,
                "village_name": "पदमपुर"
            },
            {
                "label": "पिलखी टोले कोरीगामा 0874",
                "village_id": 19,
                "village_name": "पिलखी टोले कोरीगामा"
            },
            {
                "label": "पीलखी गजपती 0874",
                "village_id": 20,
                "village_name": "पीलखी गजपती"
            },
            {
                "label": "महमदपुर कथौली 0788",
                "village_id": 21,
                "village_name": "महमदपुर कथौली"
            },
            {
                "label": "महमदपुर गोखूल 0785",
                "village_id": 22,
                "village_name": "महमदपुर गोखूल"
            },
            {
                "label": "महमद़पुर भटौलीया 0787",
                "village_id": 23,
                "village_name": "महमद़पुर भटौलीया"
            },
            {
                "label": "महमदपुर लालसे 0782",
                "village_id": 24,
                "village_name": "महमदपुर लालसे"
            },
            {
                "label": "महम्मदपुर दामोदर 0781",
                "village_id": 25,
                "village_name": "महम्मदपुर दामोदर"
            },
            {
                "label": "महम्‍मदपुर मोहन 0784",
                "village_id": 26,
                "village_name": "महम्‍मदपुर मोहन"
            },
            {
                "label": "महम्‍मदपुर वदल 0780",
                "village_id": 27,
                "village_name": "महम्‍मदपुर वदल"
            },
            {
                "label": "महम्‍मदपुर शिवराम 0783",
                "village_id": 28,
                "village_name": "महम्‍मदपुर शिवराम"
            },
            {
                "label": "मालपुर अग्रैल 0927",
                "village_id": 29,
                "village_name": "मालपुर अग्रैल"
            },
            {
                "label": "मीरापुर 0872",
                "village_id": 30,
                "village_name": "मीरापुर"
            },
            {
                "label": "रसूलनगर इटहॉ 0929",
                "village_id": 31,
                "village_name": "रसूलनगर इटहॉ"
            },
            {
                "label": "वलुआ 0921",
                "village_id": 32,
                "village_name": "वलुआ"
            },
            {
                "label": "वसंत पुर वखरी 0923",
                "village_id": 33,
                "village_name": "वसंत पुर वखरी"
            },
            {
                "label": "विधाझाँप 0794",
                "village_id": 34,
                "village_name": "विधाझाँप"
            },
            {
                "label": "विशुनपुर वखरी 0924",
                "village_id": 35,
                "village_name": "विशुनपुर वखरी"
            },
            {
                "label": "विशुनपुर श्रीराम 0790",
                "village_id": 36,
                "village_name": "विशुनपुर श्रीराम"
            },
            {
                "label": "शम्भुनाथपुर ढ़ोली 0918",
                "village_id": 37,
                "village_name": "शम्भुनाथपुर ढ़ोली"
            },
            {
                "label": "सम्भा 0467",
                "village_id": 38,
                "village_name": "सम्भा"
            },
            {
                "label": "सादिकपुर मुरौल 0871",
                "village_id": 39,
                "village_name": "सादिकपुर मुरौल"
            },
            {
                "label": "हरसिंगपुर लौतन 0930",
                "village_id": 40,
                "village_name": "हरसिंगपुर लौतन"
            },
            {
                "label": "हसनपुर 0462",
                "village_id": 41,
                "village_name": "हसनपुर"
            }
        ],
        "block_id": "3",
        "block_no": 2,
        "block_name": "मुरौल [003]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अजितपुर हरपुर 0875",
                "village_id": 2,
                "village_name": "अजितपुर हरपुर"
            },
            {
                "label": "इनामतपुर 0878",
                "village_id": 3,
                "village_name": "इनामतपुर"
            },
            {
                "label": "इनामपुर 0878",
                "village_id": 4,
                "village_name": "इनामपुर"
            },
            {
                "label": "उसरा 0895",
                "village_id": 5,
                "village_name": "उसरा"
            },
            {
                "label": "एराजी ननकार 0912",
                "village_id": 6,
                "village_name": "एराजी ननकार"
            },
            {
                "label": "करैला 0886",
                "village_id": 7,
                "village_name": "करैला"
            },
            {
                "label": "कैला बखरी 0881",
                "village_id": 8,
                "village_name": "कैला बखरी"
            },
            {
                "label": "घोसरामा 0893",
                "village_id": 9,
                "village_name": "घोसरामा"
            },
            {
                "label": "चांदपुरा 0903",
                "village_id": 10,
                "village_name": "चांदपुरा"
            },
            {
                "label": "छपरा उर्फ गोविन्दपुर 0898",
                "village_id": 11,
                "village_name": "छपरा उर्फ गोविन्दपुर"
            },
            {
                "label": "जरंगी 0879",
                "village_id": 12,
                "village_name": "जरंगी"
            },
            {
                "label": "जरंगी 0879",
                "village_id": 13,
                "village_name": "जरंगी"
            },
            {
                "label": "तेपरी 0909",
                "village_id": 14,
                "village_name": "तेपरी"
            },
            {
                "label": "ननकार 0906",
                "village_id": 15,
                "village_name": "ननकार"
            },
            {
                "label": "नूनफारा 0897",
                "village_id": 16,
                "village_name": "नूनफारा"
            },
            {
                "label": "पचरुखी 0887",
                "village_id": 17,
                "village_name": "पचरुखी"
            },
            {
                "label": "पटसरा 0911",
                "village_id": 18,
                "village_name": "पटसरा"
            },
            {
                "label": "पीयर 0890",
                "village_id": 19,
                "village_name": "पीयर"
            },
            {
                "label": "पीरापुर 0889",
                "village_id": 20,
                "village_name": "पीरापुर"
            },
            {
                "label": "फतेहउल्लाहपुर 0885",
                "village_id": 21,
                "village_name": "फतेहउल्लाहपुर"
            },
            {
                "label": "फुलबारी 0066",
                "village_id": 22,
                "village_name": "फुलबारी"
            },
            {
                "label": "बैंगरा 0892",
                "village_id": 23,
                "village_name": "बैंगरा"
            },
            {
                "label": "बगाही 0880",
                "village_id": 24,
                "village_name": "बगाही"
            },
            {
                "label": "बडगांव 0878",
                "village_id": 25,
                "village_name": "बडगांव"
            },
            {
                "label": "भेलागुड़ी 0132",
                "village_id": 26,
                "village_name": "भेलागुड़ी"
            },
            {
                "label": "मेघ रतवारा 0914",
                "village_id": 27,
                "village_name": "मेघ रतवारा"
            },
            {
                "label": "मतलुपुर 0915",
                "village_id": 28,
                "village_name": "मतलुपुर"
            },
            {
                "label": "मधुरापुर लखराज 0917",
                "village_id": 29,
                "village_name": "मधुरापुर लखराज"
            },
            {
                "label": "मुन्नी बैगरी 0900",
                "village_id": 30,
                "village_name": "मुन्नी बैगरी"
            },
            {
                "label": "मनीपुर 0910",
                "village_id": 31,
                "village_name": "मनीपुर"
            },
            {
                "label": "महेशपुर 0913",
                "village_id": 32,
                "village_name": "महेशपुर"
            },
            {
                "label": "मोहनपुर 0882",
                "village_id": 33,
                "village_name": "मोहनपुर"
            },
            {
                "label": "रामपुर दयाल 0891",
                "village_id": 34,
                "village_name": "रामपुर दयाल"
            },
            {
                "label": "रामपुर महिनाथ 0876",
                "village_id": 35,
                "village_name": "रामपुर महिनाथ"
            },
            {
                "label": "वन्दरा 0899",
                "village_id": 36,
                "village_name": "वन्दरा"
            },
            {
                "label": "वरियारपुर 0883",
                "village_id": 37,
                "village_name": "वरियारपुर"
            },
            {
                "label": "वलहिया 0884",
                "village_id": 38,
                "village_name": "वलहिया"
            },
            {
                "label": "वलिगांमा 0896",
                "village_id": 39,
                "village_name": "वलिगांमा"
            },
            {
                "label": "विशुनपुर महेशी 0894",
                "village_id": 40,
                "village_name": "विशुनपुर महेशी"
            },
            {
                "label": "शंकरपुर 0908",
                "village_id": 41,
                "village_name": "शंकरपुर"
            },
            {
                "label": "शंकरपुर सकरी 905 ",
                "village_id": 42,
                "village_name": "शंकरपुर सकरी"
            },
            {
                "label": "सकरीमन 0905",
                "village_id": 43,
                "village_name": "सकरीमन"
            },
            {
                "label": "सुन्दरपुर रतवारा 0916",
                "village_id": 44,
                "village_name": "सुन्दरपुर रतवारा"
            },
            {
                "label": "सिमरा 0877",
                "village_id": 45,
                "village_name": "सिमरा"
            },
            {
                "label": "हथा 0902",
                "village_id": 46,
                "village_name": "हथा"
            }
        ],
        "block_id": "4",
        "block_no": 3,
        "block_name": "बंदरा [004]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अधीन चक 0599",
                "village_id": 2,
                "village_name": "अधीन चक"
            },
            {
                "label": "अलहदादपुर 0704",
                "village_id": 3,
                "village_name": "अलहदादपुर"
            },
            {
                "label": "आथर वंशवन 0777",
                "village_id": 4,
                "village_name": "आथर वंशवन"
            },
            {
                "label": "आदम छपड़ा 0652",
                "village_id": 5,
                "village_name": "आदम छपड़ा"
            },
            {
                "label": "आदिगोपालपुर 0707",
                "village_id": 6,
                "village_name": "आदिगोपालपुर"
            },
            {
                "label": "उनसर 0750",
                "village_id": 7,
                "village_name": "उनसर"
            },
            {
                "label": "एतवारपुर ताज 0761",
                "village_id": 8,
                "village_name": "एतवारपुर ताज"
            },
            {
                "label": "कन्हार रघु 0717",
                "village_id": 9,
                "village_name": "कन्हार रघु"
            },
            {
                "label": "कन्हारा हरदास 0716",
                "village_id": 10,
                "village_name": "कन्हारा हरदास"
            },
            {
                "label": "कफेन गोवर्धन 0616",
                "village_id": 11,
                "village_name": "कफेन गोवर्धन"
            },
            {
                "label": "कफेन चौधरी 0617",
                "village_id": 12,
                "village_name": "कफेन चौधरी"
            },
            {
                "label": "कफेन लतीफ 0619",
                "village_id": 13,
                "village_name": "कफेन लतीफ"
            },
            {
                "label": "कमले वलिया 0728",
                "village_id": 14,
                "village_name": "कमले वलिया"
            },
            {
                "label": "कर्णपुर 0642",
                "village_id": 15,
                "village_name": "कर्णपुर"
            },
            {
                "label": "करमनीया 0724",
                "village_id": 16,
                "village_name": "करमनीया"
            },
            {
                "label": "काशीरामपुर 0763",
                "village_id": 17,
                "village_name": "काशीरामपुर"
            },
            {
                "label": "खालीकपुर 0627",
                "village_id": 18,
                "village_name": "खालीकपुर"
            },
            {
                "label": "गुढमी 0772",
                "village_id": 19,
                "village_name": "गुढमी"
            },
            {
                "label": "गिद्धा उर्फ गरहा 0645",
                "village_id": 20,
                "village_name": "गिद्धा उर्फ गरहा"
            },
            {
                "label": "गोपालपुर गोपाल 0765",
                "village_id": 21,
                "village_name": "गोपालपुर गोपाल"
            },
            {
                "label": "चक अव्दुल रहमान 0705",
                "village_id": 22,
                "village_name": "चक अव्दुल रहमान"
            },
            {
                "label": "चक आछे 0711",
                "village_id": 23,
                "village_name": "चक आछे"
            },
            {
                "label": "चकजमालुद्दीन 0759",
                "village_id": 24,
                "village_name": "चकजमालुद्दीन"
            },
            {
                "label": "चकनुरन 0755",
                "village_id": 25,
                "village_name": "चकनुरन"
            },
            {
                "label": "चकबलथी 0760",
                "village_id": 26,
                "village_name": "चकबलथी"
            },
            {
                "label": "चकमुण्डी 0654",
                "village_id": 27,
                "village_name": "चकमुण्डी"
            },
            {
                "label": "चकरोसन 0618",
                "village_id": 28,
                "village_name": "चकरोसन"
            },
            {
                "label": "चकहेलाल 0769",
                "village_id": 29,
                "village_name": "चकहेलाल"
            },
            {
                "label": "चकहवीवुल्लाह 0653",
                "village_id": 30,
                "village_name": "चकहवीवुल्लाह"
            },
            {
                "label": "चौपार भरथ 0633",
                "village_id": 31,
                "village_name": "चौपार भरथ"
            },
            {
                "label": "चौमुख 0745",
                "village_id": 32,
                "village_name": "चौमुख"
            },
            {
                "label": "जग्रनाथ पुर 0609",
                "village_id": 33,
                "village_name": "जग्रनाथ पुर"
            },
            {
                "label": "जग्रनाथपुर 0608",
                "village_id": 34,
                "village_name": "जग्रनाथपुर"
            },
            {
                "label": "जग्रनाथपुर 0607",
                "village_id": 35,
                "village_name": "जग्रनाथपुर"
            },
            {
                "label": "जगाई मझौली 0719",
                "village_id": 36,
                "village_name": "जगाई मझौली"
            },
            {
                "label": "झपहा 0594",
                "village_id": 37,
                "village_name": "झपहा"
            },
            {
                "label": "तुरकी 0741",
                "village_id": 38,
                "village_name": "तुरकी"
            },
            {
                "label": "तामोलीया 0714",
                "village_id": 39,
                "village_name": "तामोलीया"
            },
            {
                "label": "देवगण 0742",
                "village_id": 40,
                "village_name": "देवगण"
            },
            {
                "label": "दहीवन 0709",
                "village_id": 41,
                "village_name": "दहीवन"
            },
            {
                "label": "धर्मपुर 0712",
                "village_id": 42,
                "village_name": "धर्मपुर"
            },
            {
                "label": "नरकटीया 0610",
                "village_id": 43,
                "village_name": "नरकटीया"
            },
            {
                "label": "नरमा 0600",
                "village_id": 44,
                "village_name": "नरमा"
            },
            {
                "label": "पकडी सुभंकर 0620",
                "village_id": 45,
                "village_name": "पकडी सुभंकर"
            },
            {
                "label": "पकडीवरखुदार 0612",
                "village_id": 46,
                "village_name": "पकडीवरखुदार"
            },
            {
                "label": "पटियासा 0648",
                "village_id": 47,
                "village_name": "पटियासा"
            },
            {
                "label": "पुनरवारा श्याम 0613",
                "village_id": 48,
                "village_name": "पुनरवारा श्याम"
            },
            {
                "label": "प्राणनाथपुर डुमरी 0774",
                "village_id": 49,
                "village_name": "प्राणनाथपुर डुमरी"
            },
            {
                "label": "परांती 0749",
                "village_id": 50,
                "village_name": "परांती"
            },
            {
                "label": "पिरख पुर 0768",
                "village_id": 51,
                "village_name": "पिरख पुर"
            },
            {
                "label": "फतेहपुर 0758",
                "village_id": 52,
                "village_name": "फतेहपुर"
            },
            {
                "label": "बुद्ध नगरा 0706",
                "village_id": 53,
                "village_name": "बुद्ध नगरा"
            },
            {
                "label": "बरहेटा गंगाराम 0743",
                "village_id": 54,
                "village_name": "बरहेटा गंगाराम"
            },
            {
                "label": "बलिया इन्द्रजीत 0740",
                "village_id": 55,
                "village_name": "बलिया इन्द्रजीत"
            },
            {
                "label": "बसौलि हिम्मत सिंह 0636",
                "village_id": 56,
                "village_name": "बसौलि हिम्मत सिंह"
            },
            {
                "label": "बसौली जग्रनाथ 0635",
                "village_id": 57,
                "village_name": "बसौली जग्रनाथ"
            },
            {
                "label": "बोरबार 0621",
                "village_id": 58,
                "village_name": "बोरबार"
            },
            {
                "label": "भटनी 0736",
                "village_id": 59,
                "village_name": "भटनी"
            },
            {
                "label": "भताने 0604",
                "village_id": 60,
                "village_name": "भताने"
            },
            {
                "label": "भुताने 0604",
                "village_id": 61,
                "village_name": "भुताने"
            },
            {
                "label": "भुसाही 0729",
                "village_id": 62,
                "village_name": "भुसाही"
            },
            {
                "label": "भागवानपुर 0735",
                "village_id": 63,
                "village_name": "भागवानपुर"
            },
            {
                "label": "भागवानपुर 0643",
                "village_id": 64,
                "village_name": "भागवानपुर"
            },
            {
                "label": "भागवानपुर घौचा 0734",
                "village_id": 65,
                "village_name": "भागवानपुर घौचा"
            },
            {
                "label": "भोरहा 0753",
                "village_id": 66,
                "village_name": "भोरहा"
            },
            {
                "label": "मैदापुर 0764",
                "village_id": 67,
                "village_name": "मैदापुर"
            },
            {
                "label": "मुरादपुर काशी 0639",
                "village_id": 68,
                "village_name": "मुरादपुर काशी"
            },
            {
                "label": "मुरादपुर भरत 0644",
                "village_id": 69,
                "village_name": "मुरादपुर भरत"
            },
            {
                "label": "महुली 0605",
                "village_id": 70,
                "village_name": "महुली"
            },
            {
                "label": "माधोपुर मोमी 0773",
                "village_id": 71,
                "village_name": "माधोपुर मोमी"
            },
            {
                "label": "मानपुर आनन्‍दी 0638",
                "village_id": 72,
                "village_name": "मानपुर आनन्‍दी"
            },
            {
                "label": "मानविशुनपुर 0703",
                "village_id": 73,
                "village_name": "मानविशुनपुर"
            },
            {
                "label": "माहपुर 0622",
                "village_id": 74,
                "village_name": "माहपुर"
            },
            {
                "label": "मिर्जापुर 0646",
                "village_id": 75,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "मिल्की चक 0632",
                "village_id": 76,
                "village_name": "मिल्की चक"
            },
            {
                "label": "मिश्रौलीया 0723",
                "village_id": 77,
                "village_name": "मिश्रौलीया"
            },
            {
                "label": "मोहीउद्दीनपुर 0655",
                "village_id": 78,
                "village_name": "मोहीउद्दीनपुर"
            },
            {
                "label": "यसफपट्टी उर्फ ईश्वर पट्टी 0650",
                "village_id": 79,
                "village_name": "यसफपट्टी उर्फ ईश्वर पट्टी"
            },
            {
                "label": "रघुनाथ पुर 0708",
                "village_id": 80,
                "village_name": "रघुनाथ पुर"
            },
            {
                "label": "रतनपुरा 0739",
                "village_id": 81,
                "village_name": "रतनपुरा"
            },
            {
                "label": "रूदहा 0710",
                "village_id": 82,
                "village_name": "रूदहा"
            },
            {
                "label": "रूदहा 0634",
                "village_id": 83,
                "village_name": "रूदहा"
            },
            {
                "label": "रहसी 0751",
                "village_id": 84,
                "village_name": "रहसी"
            },
            {
                "label": "राघोमझौली 0715",
                "village_id": 85,
                "village_name": "राघोमझौली"
            },
            {
                "label": "राधो मझौली 0727",
                "village_id": 86,
                "village_name": "राधो मझौली"
            },
            {
                "label": "राधोपट्टी 0725",
                "village_id": 87,
                "village_name": "राधोपट्टी"
            },
            {
                "label": "रामपुर जयपाल 0631",
                "village_id": 88,
                "village_name": "रामपुर जयपाल"
            },
            {
                "label": "रोशी 0754",
                "village_id": 89,
                "village_name": "रोशी"
            },
            {
                "label": "ललौना 0746",
                "village_id": 90,
                "village_name": "ललौना"
            },
            {
                "label": "लालपुर 0606",
                "village_id": 91,
                "village_name": "लालपुर"
            },
            {
                "label": "लोहसरी 0732",
                "village_id": 92,
                "village_name": "लोहसरी"
            },
            {
                "label": "वुधौली 0748",
                "village_id": 93,
                "village_name": "वुधौली"
            },
            {
                "label": "वेरइ हरदास 0726",
                "village_id": 94,
                "village_name": "वेरइ हरदास"
            },
            {
                "label": "वल्थी रसुलपुर 0752",
                "village_id": 95,
                "village_name": "वल्थी रसुलपुर"
            },
            {
                "label": "वशुनपुर हर नारायण 0637",
                "village_id": 96,
                "village_name": "वशुनपुर हर नारायण"
            },
            {
                "label": "वशौली नन्हकर 0628",
                "village_id": 97,
                "village_name": "वशौली नन्हकर"
            },
            {
                "label": "वशौली बाबु राम 0630",
                "village_id": 98,
                "village_name": "वशौली बाबु राम"
            },
            {
                "label": "वशौली हिमत सिह 0626",
                "village_id": 99,
                "village_name": "वशौली हिमत सिह"
            },
            {
                "label": "वसौली वावुराम 0625",
                "village_id": 100,
                "village_name": "वसौली वावुराम"
            },
            {
                "label": "वहरामपुर 0756",
                "village_id": 101,
                "village_name": "वहरामपुर"
            },
            {
                "label": "वाजितपुर 0744",
                "village_id": 102,
                "village_name": "वाजितपुर"
            },
            {
                "label": "वाजितपुर मिश्रौलीया 0722",
                "village_id": 103,
                "village_name": "वाजितपुर मिश्रौलीया"
            },
            {
                "label": "वाजीतपुर 0757",
                "village_id": 104,
                "village_name": "वाजीतपुर"
            },
            {
                "label": "वाजीतपुर उर्फ रामदास मझौली 0721",
                "village_id": 105,
                "village_name": "वाजीतपुर उर्फ रामदास मझौली"
            },
            {
                "label": "वाजीतपुर मझौली 0720",
                "village_id": 106,
                "village_name": "वाजीतपुर मझौली"
            },
            {
                "label": "वाजीतपुर रतनपुरा 0737",
                "village_id": 107,
                "village_name": "वाजीतपुर रतनपुरा"
            },
            {
                "label": "विशुनपुर खेतल 0623",
                "village_id": 108,
                "village_name": "विशुनपुर खेतल"
            },
            {
                "label": "विशुनपुर जगदीश 0776",
                "village_id": 109,
                "village_name": "विशुनपुर जगदीश"
            },
            {
                "label": "विशुनपुर नारायण 0624",
                "village_id": 110,
                "village_name": "विशुनपुर नारायण"
            },
            {
                "label": "वोरवारा 0738",
                "village_id": 111,
                "village_name": "वोरवारा"
            },
            {
                "label": "शेखपुर 0731",
                "village_id": 112,
                "village_name": "शेखपुर"
            },
            {
                "label": "शेखपुर दाखिली 0730",
                "village_id": 113,
                "village_name": "शेखपुर दाखिली"
            },
            {
                "label": "शरफुदीनपुर 0767",
                "village_id": 114,
                "village_name": "शरफुदीनपुर"
            },
            {
                "label": "शहिला रामपुर 603i",
                "village_id": 115,
                "village_name": "शहिला रामपुर"
            },
            {
                "label": "शहीला रामपुर 0603",
                "village_id": 116,
                "village_name": "शहीला रामपुर"
            },
            {
                "label": "शाहपुर 0762",
                "village_id": 117,
                "village_name": "शाहपुर"
            },
            {
                "label": "शिवाआथर 0778",
                "village_id": 118,
                "village_name": "शिवाआथर"
            },
            {
                "label": "सनाढी 0640",
                "village_id": 119,
                "village_name": "सनाढी"
            },
            {
                "label": "सुरीयाही 0733",
                "village_id": 120,
                "village_name": "सुरीयाही"
            },
            {
                "label": "सलहाँ 0771",
                "village_id": 121,
                "village_name": "सलहाँ"
            },
            {
                "label": "सहदुल्लाहपुर 0651",
                "village_id": 122,
                "village_name": "सहदुल्लाहपुर"
            },
            {
                "label": "सिंघैला 0629",
                "village_id": 123,
                "village_name": "सिंघैला"
            },
            {
                "label": "सिमरी फकीरा 0602",
                "village_id": 124,
                "village_name": "सिमरी फकीरा"
            },
            {
                "label": "सिमरी वशंत 0601",
                "village_id": 125,
                "village_name": "सिमरी वशंत"
            },
            {
                "label": "हमिदपुर उर्फ हेमंतपुर 0647",
                "village_id": 126,
                "village_name": "हमिदपुर उर्फ हेमंतपुर"
            },
            {
                "label": "हुसैनपुर 0702",
                "village_id": 127,
                "village_name": "हुसैनपुर"
            }
        ],
        "block_id": "5",
        "block_no": 4,
        "block_name": "बोचहाँ [005]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अजरकवे मधैयपुर 0007",
                "village_id": 2,
                "village_name": "अजरकवे मधैयपुर"
            },
            {
                "label": "अजरकवे हरहिया 0011",
                "village_id": 3,
                "village_name": "अजरकवे हरहिया"
            },
            {
                "label": "अली नेउरा 0517",
                "village_id": 4,
                "village_name": "अली नेउरा"
            },
            {
                "label": "उफरौलिया 0554",
                "village_id": 5,
                "village_name": "उफरौलिया"
            },
            {
                "label": "एराजीगोरीगामा 0565",
                "village_id": 6,
                "village_name": "एराजीगोरीगामा"
            },
            {
                "label": "करचौलिया 0023",
                "village_id": 7,
                "village_name": "करचौलिया"
            },
            {
                "label": "कर्मवारी 0001",
                "village_id": 8,
                "village_name": "कर्मवारी"
            },
            {
                "label": "कलयाणपुर 0045",
                "village_id": 9,
                "village_name": "कलयाणपुर"
            },
            {
                "label": "कोईली फकीराना 0574",
                "village_id": 10,
                "village_name": "कोईली फकीराना"
            },
            {
                "label": "कोदरीया 0030",
                "village_id": 11,
                "village_name": "कोदरीया"
            },
            {
                "label": "कोयली 0575",
                "village_id": 12,
                "village_name": "कोयली"
            },
            {
                "label": "खुटौना 0547",
                "village_id": 13,
                "village_name": "खुटौना"
            },
            {
                "label": "खेमकरण पकड़ी 0020",
                "village_id": 14,
                "village_name": "खेमकरण पकड़ी"
            },
            {
                "label": "खेमाई पट्टी 0528",
                "village_id": 15,
                "village_name": "खेमाई पट्टी"
            },
            {
                "label": "खरहर 0579",
                "village_id": 16,
                "village_name": "खरहर"
            },
            {
                "label": "खरार 0543",
                "village_id": 17,
                "village_name": "खरार"
            },
            {
                "label": "खरिका 0053",
                "village_id": 18,
                "village_name": "खरिका"
            },
            {
                "label": "खानेजातपुर 0518",
                "village_id": 19,
                "village_name": "खानेजातपुर"
            },
            {
                "label": "खोजा पकडी 0019",
                "village_id": 20,
                "village_name": "खोजा पकडी"
            },
            {
                "label": "गंगटी 0560",
                "village_id": 21,
                "village_name": "गंगटी"
            },
            {
                "label": "गंगावार पानापुर 0032",
                "village_id": 22,
                "village_name": "गंगावार पानापुर"
            },
            {
                "label": "गदाईचक 0535",
                "village_id": 23,
                "village_name": "गदाईचक"
            },
            {
                "label": "गोरीगामा 0564",
                "village_id": 24,
                "village_name": "गोरीगामा"
            },
            {
                "label": "गोसाई बनुआ 0557",
                "village_id": 25,
                "village_name": "गोसाई बनुआ"
            },
            {
                "label": "गोसाईपुर 0031",
                "village_id": 26,
                "village_name": "गोसाईपुर"
            },
            {
                "label": "गोसाईपुर टेंगराहा 0578",
                "village_id": 27,
                "village_name": "गोसाईपुर टेंगराहा"
            },
            {
                "label": "घोसौत 0035",
                "village_id": 28,
                "village_name": "घोसौत"
            },
            {
                "label": "चकईमाद 0502",
                "village_id": 29,
                "village_name": "चकईमाद"
            },
            {
                "label": "चकजमान 0533",
                "village_id": 30,
                "village_name": "चकजमान"
            },
            {
                "label": "चकढ़ोरिया 0582",
                "village_id": 31,
                "village_name": "चकढ़ोरिया"
            },
            {
                "label": "चकिया 0549",
                "village_id": 32,
                "village_name": "चकिया"
            },
            {
                "label": "चतुरसी 0018",
                "village_id": 33,
                "village_name": "चतुरसी"
            },
            {
                "label": "चाको छपड़ा 0555",
                "village_id": 34,
                "village_name": "चाको छपड़ा"
            },
            {
                "label": "चाँदपरना 0497",
                "village_id": 35,
                "village_name": "चाँदपरना"
            },
            {
                "label": "छेगन नेउरा 0513",
                "village_id": 36,
                "village_name": "छेगन नेउरा"
            },
            {
                "label": "छपरा मुवारा 0596",
                "village_id": 37,
                "village_name": "छपरा मुवारा"
            },
            {
                "label": "छितरपट्ट्री 0498",
                "village_id": 38,
                "village_name": "छितरपट्ट्री"
            },
            {
                "label": "जगरनाथ पकड़ी 0021",
                "village_id": 39,
                "village_name": "जगरनाथ पकड़ी"
            },
            {
                "label": "जामिन मठीया 0038",
                "village_id": 40,
                "village_name": "जामिन मठीया"
            },
            {
                "label": "झिटकाही 0541",
                "village_id": 41,
                "village_name": "झिटकाही"
            },
            {
                "label": "झिरकहीयां 0540",
                "village_id": 42,
                "village_name": "झिरकहीयां"
            },
            {
                "label": "टंगरारी 0027",
                "village_id": 43,
                "village_name": "टंगरारी"
            },
            {
                "label": "टेंगराहा गंसाईपुर 0576",
                "village_id": 44,
                "village_name": "टेंगराहा गंसाईपुर"
            },
            {
                "label": "डुमरिया 0046",
                "village_id": 45,
                "village_name": "डुमरिया"
            },
            {
                "label": "तुर्की खरारू 0559",
                "village_id": 46,
                "village_name": "तुर्की खरारू"
            },
            {
                "label": "तालीमपुर 0571",
                "village_id": 47,
                "village_name": "तालीमपुर"
            },
            {
                "label": "दुबहाँ 0551",
                "village_id": 48,
                "village_name": "दुबहाँ"
            },
            {
                "label": "दरहीपट्टी 0529",
                "village_id": 49,
                "village_name": "दरहीपट्टी"
            },
            {
                "label": "दरियापुर 0006",
                "village_id": 50,
                "village_name": "दरियापुर"
            },
            {
                "label": "दाउद छपड़ा 0515",
                "village_id": 51,
                "village_name": "दाउद छपड़ा"
            },
            {
                "label": "दाउद छपड़ा 0514",
                "village_id": 52,
                "village_name": "दाउद छपड़ा"
            },
            {
                "label": "धर्मपुर 0592",
                "village_id": 53,
                "village_name": "धर्मपुर"
            },
            {
                "label": "धर्मपुर 0550",
                "village_id": 54,
                "village_name": "धर्मपुर"
            },
            {
                "label": "धर्मपुर नारायण 0598",
                "village_id": 55,
                "village_name": "धर्मपुर नारायण"
            },
            {
                "label": "धर्मपुर नारायण  तिहाई 0586",
                "village_id": 56,
                "village_name": "धर्मपुर नारायण  तिहाई"
            },
            {
                "label": "धारपुर 0573",
                "village_id": 57,
                "village_name": "धारपुर"
            },
            {
                "label": "नेकनामचक 0028",
                "village_id": 58,
                "village_name": "नेकनामचक"
            },
            {
                "label": "नन्दना 0567",
                "village_id": 59,
                "village_name": "नन्दना"
            },
            {
                "label": "नुर छपरा 0522",
                "village_id": 60,
                "village_name": "नुर छपरा"
            },
            {
                "label": "नेहालपुर 0587",
                "village_id": 61,
                "village_name": "नेहालपुर"
            },
            {
                "label": "पखनाहा जीतवार 0043",
                "village_id": 62,
                "village_name": "पखनाहा जीतवार"
            },
            {
                "label": "पखनाहां श्री राम 0051",
                "village_id": 63,
                "village_name": "पखनाहां श्री राम"
            },
            {
                "label": "पैगम्बरपुर 0002",
                "village_id": 64,
                "village_name": "पैगम्बरपुर"
            },
            {
                "label": "पैगम्बरपुर टोला रामनगर 0003",
                "village_id": 65,
                "village_name": "पैगम्बरपुर टोला रामनगर"
            },
            {
                "label": "पनापुर 0054",
                "village_id": 66,
                "village_name": "पनापुर"
            },
            {
                "label": "पुरन पकड़ी 0017",
                "village_id": 67,
                "village_name": "पुरन पकड़ी"
            },
            {
                "label": "पुरैना 0060",
                "village_id": 68,
                "village_name": "पुरैना"
            },
            {
                "label": "पुरल पकडी 0017",
                "village_id": 69,
                "village_name": "पुरल पकडी"
            },
            {
                "label": "प्रसाद 0050",
                "village_id": 70,
                "village_name": "प्रसाद"
            },
            {
                "label": "पुरौनीया 0539",
                "village_id": 71,
                "village_name": "पुरौनीया"
            },
            {
                "label": "पीपरासेन 0570",
                "village_id": 72,
                "village_name": "पीपरासेन"
            },
            {
                "label": "पीपराहा असली 0056",
                "village_id": 73,
                "village_name": "पीपराहा असली"
            },
            {
                "label": "फरीद पटटी 0501",
                "village_id": 74,
                "village_name": "फरीद पटटी"
            },
            {
                "label": "फुलवरीया 0544",
                "village_id": 75,
                "village_name": "फुलवरीया"
            },
            {
                "label": "बखरी 0050",
                "village_id": 76,
                "village_name": "बखरी"
            },
            {
                "label": "बडाभारती 0036",
                "village_id": 77,
                "village_name": "बडाभारती"
            },
            {
                "label": "बनुआ लछन 0556",
                "village_id": 78,
                "village_name": "बनुआ लछन"
            },
            {
                "label": "बरहंडा 0525",
                "village_id": 79,
                "village_name": "बरहंडा"
            },
            {
                "label": "बहादुरपुर 0065",
                "village_id": 80,
                "village_name": "बहादुरपुर"
            },
            {
                "label": "भगवान छपड़ा 0015",
                "village_id": 81,
                "village_name": "भगवान छपड़ा"
            },
            {
                "label": "भेलाईपुर 0041",
                "village_id": 82,
                "village_name": "भेलाईपुर"
            },
            {
                "label": "भावछपरा 0566",
                "village_id": 83,
                "village_name": "भावछपरा"
            },
            {
                "label": "मकसुदपुर 0590",
                "village_id": 84,
                "village_name": "मकसुदपुर"
            },
            {
                "label": "मंगिया 0025",
                "village_id": 85,
                "village_name": "मंगिया"
            },
            {
                "label": "मछौलिया 0524",
                "village_id": 86,
                "village_name": "मछौलिया"
            },
            {
                "label": "मजनुपट्टी 0563",
                "village_id": 87,
                "village_name": "मजनुपट्टी"
            },
            {
                "label": "मजलीस माधो उर्फ छपड़ा 0583",
                "village_id": 88,
                "village_name": "मजलीस माधो उर्फ छपड़ा"
            },
            {
                "label": "मझौर 0580",
                "village_id": 89,
                "village_name": "मझौर"
            },
            {
                "label": "मेथनापुर 0505",
                "village_id": 90,
                "village_name": "मेथनापुर"
            },
            {
                "label": "मेथनापुर 0506",
                "village_id": 91,
                "village_name": "मेथनापुर"
            },
            {
                "label": "मदारीपुर कर्ण 0588",
                "village_id": 92,
                "village_name": "मदारीपुर कर्ण"
            },
            {
                "label": "मधैयपुर 0008",
                "village_id": 93,
                "village_name": "मधैयपुर"
            },
            {
                "label": "मधुवन काँटी 0037",
                "village_id": 94,
                "village_name": "मधुवन काँटी"
            },
            {
                "label": "मधुवनी 0534",
                "village_id": 95,
                "village_name": "मधुवनी"
            },
            {
                "label": "मुस्फागंज 0500",
                "village_id": 96,
                "village_name": "मुस्फागंज"
            },
            {
                "label": "मुस़लमानी चक 0530",
                "village_id": 97,
                "village_name": "मुस़लमानी चक"
            },
            {
                "label": "मुसा चक 0531",
                "village_id": 98,
                "village_name": "मुसा चक"
            },
            {
                "label": "महदेईया 0537",
                "village_id": 99,
                "village_name": "महदेईया"
            },
            {
                "label": "माधोपुर वशावन उर्फ वेलाही 0569",
                "village_id": 100,
                "village_name": "माधोपुर वशावन उर्फ वेलाही"
            },
            {
                "label": "मानिकपुर 0503",
                "village_id": 101,
                "village_name": "मानिकपुर"
            },
            {
                "label": "मानिकपुर 0589",
                "village_id": 102,
                "village_name": "मानिकपुर"
            },
            {
                "label": "मीनापूर 0542",
                "village_id": 103,
                "village_name": "मीनापूर"
            },
            {
                "label": "मोथहाँ फकीराणा 0520",
                "village_id": 104,
                "village_name": "मोथहाँ फकीराणा"
            },
            {
                "label": "मोथहा माल 0521",
                "village_id": 105,
                "village_name": "मोथहा माल"
            },
            {
                "label": "मोरसर सवालिया 0048",
                "village_id": 106,
                "village_name": "मोरसर सवालिया"
            },
            {
                "label": "मोहनपुर 0595",
                "village_id": 107,
                "village_name": "मोहनपुर"
            },
            {
                "label": "रघई 0545",
                "village_id": 108,
                "village_name": "रघई"
            },
            {
                "label": "रेपुरा उर्फ साहपुर लखन 0064",
                "village_id": 109,
                "village_name": "रेपुरा उर्फ साहपुर लखन"
            },
            {
                "label": "राघोपुर 0507",
                "village_id": 110,
                "village_name": "राघोपुर"
            },
            {
                "label": "राघोपुर उर्फ दामोदरपुर 0572",
                "village_id": 111,
                "village_name": "राघोपुर उर्फ दामोदरपुर"
            },
            {
                "label": "रानी खैरा उर्फ बनुआ 0552",
                "village_id": 112,
                "village_name": "रानी खैरा उर्फ बनुआ"
            },
            {
                "label": "राम सहाय छपरा 0519",
                "village_id": 113,
                "village_name": "राम सहाय छपरा"
            },
            {
                "label": "रामतोमहाँ 0052",
                "village_id": 114,
                "village_name": "रामतोमहाँ"
            },
            {
                "label": "रामपुर रतन 0014",
                "village_id": 115,
                "village_name": "रामपुर रतन"
            },
            {
                "label": "रामपुर हरी 0584",
                "village_id": 116,
                "village_name": "रामपुर हरी"
            },
            {
                "label": "रायपट्टी 0009",
                "village_id": 117,
                "village_name": "रायपट्टी"
            },
            {
                "label": "लखन छपरा 0516",
                "village_id": 118,
                "village_name": "लखन छपरा"
            },
            {
                "label": "लौतन 0562",
                "village_id": 119,
                "village_name": "लौतन"
            },
            {
                "label": "वडाहसन 0040",
                "village_id": 120,
                "village_name": "वडाहसन"
            },
            {
                "label": "वनघारा 0026",
                "village_id": 121,
                "village_name": "वनघारा"
            },
            {
                "label": "वेलाही लच्छी 0558",
                "village_id": 122,
                "village_name": "वेलाही लच्छी"
            },
            {
                "label": "वहवल बजार 0504",
                "village_id": 123,
                "village_name": "वहवल बजार"
            },
            {
                "label": "वाजर मुन्डिया 0034",
                "village_id": 124,
                "village_name": "वाजर मुन्डिया"
            },
            {
                "label": "वासदेव छपरा 0499",
                "village_id": 125,
                "village_name": "वासदेव छपरा"
            },
            {
                "label": "वासदेव छपरा 0538",
                "village_id": 126,
                "village_name": "वासदेव छपरा"
            },
            {
                "label": "वासुदेव वनुआ 0005",
                "village_id": 127,
                "village_name": "वासुदेव वनुआ"
            },
            {
                "label": "विशुनपुर कण्ठ 0532",
                "village_id": 128,
                "village_name": "विशुनपुर कण्ठ"
            },
            {
                "label": "विशुनपुर केशव 0047",
                "village_id": 129,
                "village_name": "विशुनपुर केशव"
            },
            {
                "label": "विशुनपुर पाण्डेय 0039",
                "village_id": 130,
                "village_name": "विशुनपुर पाण्डेय"
            },
            {
                "label": "विशुनपुर रूपौली 0527",
                "village_id": 131,
                "village_name": "विशुनपुर रूपौली"
            },
            {
                "label": "विशुनपुर राजे 0585",
                "village_id": 132,
                "village_name": "विशुनपुर राजे"
            },
            {
                "label": "शंकर पट्टी 0593",
                "village_id": 133,
                "village_name": "शंकर पट्टी"
            },
            {
                "label": "शाहपुर 0597",
                "village_id": 134,
                "village_name": "शाहपुर"
            },
            {
                "label": "शिवाई पट्टी 0012",
                "village_id": 135,
                "village_name": "शिवाई पट्टी"
            },
            {
                "label": "शीतलपुर 0269",
                "village_id": 136,
                "village_name": "शीतलपुर"
            },
            {
                "label": "सगहरी 0004",
                "village_id": 137,
                "village_name": "सगहरी"
            },
            {
                "label": "सुर्यजन पकडी 0022",
                "village_id": 138,
                "village_name": "सुर्यजन पकडी"
            },
            {
                "label": "सलेमापुर 0577",
                "village_id": 139,
                "village_name": "सलेमापुर"
            },
            {
                "label": "सहज पुर 0523",
                "village_id": 140,
                "village_name": "सहज पुर"
            },
            {
                "label": "सितपट्टी 0024",
                "village_id": 141,
                "village_name": "सितपट्टी"
            },
            {
                "label": "सोढना माधोपुर 0029",
                "village_id": 142,
                "village_name": "सोढना माधोपुर"
            },
            {
                "label": "हजरतपुर 0581",
                "village_id": 143,
                "village_name": "हजरतपुर"
            },
            {
                "label": "हेमनपुर 0591",
                "village_id": 144,
                "village_name": "हेमनपुर"
            },
            {
                "label": "हरका मानशाही 0553",
                "village_id": 145,
                "village_name": "हरका मानशाही"
            },
            {
                "label": "हरपुर वासदेव उर्फ मिल्की 0546",
                "village_id": 146,
                "village_name": "हरपुर वासदेव उर्फ मिल्की"
            },
            {
                "label": "हरपुरबक्स 0548",
                "village_id": 147,
                "village_name": "हरपुरबक्स"
            },
            {
                "label": "हरशेर 0033",
                "village_id": 148,
                "village_name": "हरशेर"
            },
            {
                "label": "हरहियां छपरा 0010",
                "village_id": 149,
                "village_name": "हरहियां छपरा"
            },
            {
                "label": "हरिया छपरा 0013",
                "village_id": 150,
                "village_name": "हरिया छपरा"
            }
        ],
        "block_id": "6",
        "block_no": 5,
        "block_name": "मीनापुर [006]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "असिया 0164",
                "village_id": 2,
                "village_name": "असिया"
            },
            {
                "label": "एराजी पिरहटी 0162",
                "village_id": 3,
                "village_name": "एराजी पिरहटी"
            },
            {
                "label": "ककरीया 0158",
                "village_id": 4,
                "village_name": "ककरीया"
            },
            {
                "label": "कमरथू 0165",
                "village_id": 5,
                "village_name": "कमरथू"
            },
            {
                "label": "कुम्‍हरौल 0264",
                "village_id": 6,
                "village_name": "कुम्‍हरौल"
            },
            {
                "label": "कल्‍याणी 0233",
                "village_id": 7,
                "village_name": "कल्‍याणी"
            },
            {
                "label": "केवरसा 0244",
                "village_id": 8,
                "village_name": "केवरसा"
            },
            {
                "label": "कॉटा पिरौछा 0253",
                "village_id": 9,
                "village_name": "कॉटा पिरौछा"
            },
            {
                "label": "कोठिया 0148",
                "village_id": 10,
                "village_name": "कोठिया"
            },
            {
                "label": "खजुरी 0263",
                "village_id": 11,
                "village_name": "खजुरी"
            },
            {
                "label": "खलीलपुर 0312",
                "village_id": 12,
                "village_name": "खलीलपुर"
            },
            {
                "label": "ग्यासुद्दीनपुर 0143",
                "village_id": 13,
                "village_name": "ग्यासुद्दीनपुर"
            },
            {
                "label": "गोदनपट्टी 0260",
                "village_id": 14,
                "village_name": "गोदनपट्टी"
            },
            {
                "label": "चउठ उर्फ जमालपुर 0229",
                "village_id": 15,
                "village_name": "चउठ उर्फ जमालपुर"
            },
            {
                "label": "चकवलुआ 0246",
                "village_id": 16,
                "village_name": "चकवलुआ"
            },
            {
                "label": "चिरैला 0146",
                "village_id": 17,
                "village_name": "चिरैला"
            },
            {
                "label": "चौथ 0229",
                "village_id": 18,
                "village_name": "चौथ"
            },
            {
                "label": "चौथ उर्फ जमालपुर   22",
                "village_id": 19,
                "village_name": "चौथ उर्फ जमालपुर"
            },
            {
                "label": "छतनवारा 0266",
                "village_id": 20,
                "village_name": "छतनवारा"
            },
            {
                "label": "जमालपुर कोदई 0231",
                "village_id": 21,
                "village_name": "जमालपुर कोदई"
            },
            {
                "label": "जाया 0166",
                "village_id": 22,
                "village_name": "जाया"
            },
            {
                "label": "जारंग 0163",
                "village_id": 23,
                "village_name": "जारंग"
            },
            {
                "label": "जॉताटोक 0265",
                "village_id": 24,
                "village_name": "जॉताटोक"
            },
            {
                "label": "ठीकापाही 0273",
                "village_id": 25,
                "village_name": "ठीकापाही"
            },
            {
                "label": "डुमरामा 0232",
                "village_id": 26,
                "village_name": "डुमरामा"
            },
            {
                "label": "तुर्क टोलीया 0243",
                "village_id": 27,
                "village_name": "तुर्क टोलीया"
            },
            {
                "label": "ताल बोआरी 0145",
                "village_id": 28,
                "village_name": "ताल बोआरी"
            },
            {
                "label": "दहिला पटशामा 0138",
                "village_id": 29,
                "village_name": "दहिला पटशामा"
            },
            {
                "label": "दौनी कनमा 0139",
                "village_id": 30,
                "village_name": "दौनी कनमा"
            },
            {
                "label": "धर्मपुर शीतल 0235",
                "village_id": 31,
                "village_name": "धर्मपुर शीतल"
            },
            {
                "label": "धोबौली  सोवे 0161",
                "village_id": 32,
                "village_name": "धोबौली  सोवे"
            },
            {
                "label": "धोवौली सुबे 0161",
                "village_id": 33,
                "village_name": "धोवौली सुबे"
            },
            {
                "label": "धौबौली लखनी 0160",
                "village_id": 34,
                "village_name": "धौबौली लखनी"
            },
            {
                "label": "पकड़ी 0154",
                "village_id": 35,
                "village_name": "पकड़ी"
            },
            {
                "label": "पागा 0267",
                "village_id": 36,
                "village_name": "पागा"
            },
            {
                "label": "बखरी 0153",
                "village_id": 37,
                "village_name": "बखरी"
            },
            {
                "label": "बठवारा 0237",
                "village_id": 38,
                "village_name": "बठवारा"
            },
            {
                "label": "बन्दरझुला 0212",
                "village_id": 39,
                "village_name": "बन्दरझुला"
            },
            {
                "label": "बेरुआ 0150",
                "village_id": 40,
                "village_name": "बेरुआ"
            },
            {
                "label": "बरूआरी 0272",
                "village_id": 41,
                "village_name": "बरूआरी"
            },
            {
                "label": "बलहा 0278",
                "village_id": 42,
                "village_name": "बलहा"
            },
            {
                "label": "बेला गोपी 0151",
                "village_id": 43,
                "village_name": "बेला गोपी"
            },
            {
                "label": "बलौर निधि 0268",
                "village_id": 44,
                "village_name": "बलौर निधि"
            },
            {
                "label": "बलौर निधि 0268",
                "village_id": 45,
                "village_name": "बलौर निधि"
            },
            {
                "label": "बहादुरपुर 0152",
                "village_id": 46,
                "village_name": "बहादुरपुर"
            },
            {
                "label": "बाघाखाल 0137",
                "village_id": 47,
                "village_name": "बाघाखाल"
            },
            {
                "label": "बिहारी उर्फ भटगावॉ 0270",
                "village_id": 48,
                "village_name": "बिहारी उर्फ भटगावॉ"
            },
            {
                "label": "भगवतपुर 0277",
                "village_id": 49,
                "village_name": "भगवतपुर"
            },
            {
                "label": "भरपुरा गरीब 0250",
                "village_id": 50,
                "village_name": "भरपुरा गरीब"
            },
            {
                "label": "भुसरा 0140",
                "village_id": 51,
                "village_name": "भुसरा"
            },
            {
                "label": "भागवतपुर 0277",
                "village_id": 52,
                "village_name": "भागवतपुर"
            },
            {
                "label": "मकरन्दपुर 0255",
                "village_id": 53,
                "village_name": "मकरन्दपुर"
            },
            {
                "label": "मैठी 0149",
                "village_id": 54,
                "village_name": "मैठी"
            },
            {
                "label": "मणिपुर दहिला 0136",
                "village_id": 55,
                "village_name": "मणिपुर दहिला"
            },
            {
                "label": "मधुरपट़टी 0269",
                "village_id": 56,
                "village_name": "मधुरपट़टी"
            },
            {
                "label": "मधुरपट्टी 0269",
                "village_id": 57,
                "village_name": "मधुरपट्टी"
            },
            {
                "label": "महुआरा 0254",
                "village_id": 58,
                "village_name": "महुआरा"
            },
            {
                "label": "महमदपुर सुरा 0259",
                "village_id": 59,
                "village_name": "महमदपुर सुरा"
            },
            {
                "label": "महेशवारा दाखिली 0245",
                "village_id": 60,
                "village_name": "महेशवारा दाखिली"
            },
            {
                "label": "महिषवाडा 0241",
                "village_id": 61,
                "village_name": "महिषवाडा"
            },
            {
                "label": "मिश्रौली 0248",
                "village_id": 62,
                "village_name": "मिश्रौली"
            },
            {
                "label": "रमौली 0271",
                "village_id": 63,
                "village_name": "रमौली"
            },
            {
                "label": "रैला नरायण 0155",
                "village_id": 64,
                "village_name": "रैला नरायण"
            },
            {
                "label": "रेला नारायणपुर 0155",
                "village_id": 65,
                "village_name": "रेला नारायणपुर"
            },
            {
                "label": "राघोपुर 0249",
                "village_id": 66,
                "village_name": "राघोपुर"
            },
            {
                "label": "राम पट्टी 0247",
                "village_id": 67,
                "village_name": "राम पट्टी"
            },
            {
                "label": "रामनगर 0142",
                "village_id": 68,
                "village_name": "रामनगर"
            },
            {
                "label": "रामपट्टी 0247",
                "village_id": 69,
                "village_name": "रामपट्टी"
            },
            {
                "label": "रोसड़ा 0256",
                "village_id": 70,
                "village_name": "रोसड़ा"
            },
            {
                "label": "लक्षुमण नगर 0257",
                "village_id": 71,
                "village_name": "लक्षुमण नगर"
            },
            {
                "label": "लदौर 0276",
                "village_id": 72,
                "village_name": "लदौर"
            },
            {
                "label": "लादी 0156",
                "village_id": 73,
                "village_name": "लादी"
            },
            {
                "label": "लादी 0156",
                "village_id": 74,
                "village_name": "लादी"
            },
            {
                "label": "लोमा 0167",
                "village_id": 75,
                "village_name": "लोमा"
            },
            {
                "label": "लोह बन्दरा 0261",
                "village_id": 76,
                "village_name": "लोह बन्दरा"
            },
            {
                "label": "वेरुआ 0162",
                "village_id": 77,
                "village_name": "वेरुआ"
            },
            {
                "label": "व्रमोत्रा 0157",
                "village_id": 78,
                "village_name": "व्रमोत्रा"
            },
            {
                "label": "वेलगोपी 0151",
                "village_id": 79,
                "village_name": "वेलगोपी"
            },
            {
                "label": "वोआरी डीह 0144",
                "village_id": 80,
                "village_name": "वोआरी डीह"
            },
            {
                "label": "शिवदाहॉ 0239",
                "village_id": 81,
                "village_name": "शिवदाहॉ"
            },
            {
                "label": "सकड़वारा नुर 0251",
                "village_id": 82,
                "village_name": "सकड़वारा नुर"
            },
            {
                "label": "सकरवारा साविक 0252",
                "village_id": 83,
                "village_name": "सकरवारा साविक"
            },
            {
                "label": "सेनिया 0272",
                "village_id": 84,
                "village_name": "सेनिया"
            },
            {
                "label": "सुभाष केनाइ 0274",
                "village_id": 85,
                "village_name": "सुभाष केनाइ"
            },
            {
                "label": "सुभाष केशो 0275",
                "village_id": 86,
                "village_name": "सुभाष केशो"
            },
            {
                "label": "सुस्‍ता 0280",
                "village_id": 87,
                "village_name": "सुस्‍ता"
            },
            {
                "label": "सुस्ता टोले चाँदपुर 0280",
                "village_id": 88,
                "village_name": "सुस्ता टोले चाँदपुर"
            },
            {
                "label": "साढा 0228",
                "village_id": 89,
                "village_name": "साढा"
            },
            {
                "label": "साढा 0228",
                "village_id": 90,
                "village_name": "साढा"
            },
            {
                "label": "हरखौली 0234",
                "village_id": 91,
                "village_name": "हरखौली"
            },
            {
                "label": "हरिनारायण चक 0258",
                "village_id": 92,
                "village_name": "हरिनारायण चक"
            },
            {
                "label": "हरिपुर 0227",
                "village_id": 93,
                "village_name": "हरिपुर"
            },
            {
                "label": "हसन गुडडी 0141",
                "village_id": 94,
                "village_name": "हसन गुडडी"
            },
            {
                "label": "हसनागडी 0141",
                "village_id": 95,
                "village_name": "हसनागडी"
            }
        ],
        "block_id": "7",
        "block_no": 6,
        "block_name": "गायघाट [007]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अजरकवे रामपुर 0111",
                "village_id": 2,
                "village_name": "अजरकवे रामपुर"
            },
            {
                "label": "अतरार 0060",
                "village_id": 3,
                "village_name": "अतरार"
            },
            {
                "label": "अन्ना वेहट 0068",
                "village_id": 4,
                "village_name": "अन्ना वेहट"
            },
            {
                "label": "अभीमानपुर 0092",
                "village_id": 5,
                "village_name": "अभीमानपुर"
            },
            {
                "label": "अमनौर 0069",
                "village_id": 6,
                "village_name": "अमनौर"
            },
            {
                "label": "असमानपुर 0042",
                "village_id": 7,
                "village_name": "असमानपुर"
            },
            {
                "label": "आदमपुर 0033",
                "village_id": 8,
                "village_name": "आदमपुर"
            },
            {
                "label": "आदमपुर 0034",
                "village_id": 9,
                "village_name": "आदमपुर"
            },
            {
                "label": "आनन्दपुर गन्नी 0046",
                "village_id": 10,
                "village_name": "आनन्दपुर गन्नी"
            },
            {
                "label": "आलमपुर सिमरी 0093",
                "village_id": 11,
                "village_name": "आलमपुर सिमरी"
            },
            {
                "label": "इंगलिश चक 0109",
                "village_id": 12,
                "village_name": "इंगलिश चक"
            },
            {
                "label": "एकमा 0048",
                "village_id": 13,
                "village_name": "एकमा"
            },
            {
                "label": "एतवारा 0105",
                "village_id": 14,
                "village_name": "एतवारा"
            },
            {
                "label": "एराजी घनश्यामपुर 0006",
                "village_id": 15,
                "village_name": "एराजी घनश्यामपुर"
            },
            {
                "label": "एराजी सहिला रामपुर 0038",
                "village_id": 16,
                "village_name": "एराजी सहिला रामपुर"
            },
            {
                "label": "औराई 0097",
                "village_id": 17,
                "village_name": "औराई"
            },
            {
                "label": "करहटटी 0055",
                "village_id": 18,
                "village_name": "करहटटी"
            },
            {
                "label": "कल्याणपुर 0036",
                "village_id": 19,
                "village_name": "कल्याणपुर"
            },
            {
                "label": "कल्याणपुर 0036",
                "village_id": 20,
                "village_name": "कल्याणपुर"
            },
            {
                "label": "किरतपुर 0018",
                "village_id": 21,
                "village_name": "किरतपुर"
            },
            {
                "label": "कोकीलवारा 0101",
                "village_id": 22,
                "village_name": "कोकीलवारा"
            },
            {
                "label": "खेतलपुर 0103",
                "village_id": 23,
                "village_name": "खेतलपुर"
            },
            {
                "label": "गंगुली 0127",
                "village_id": 24,
                "village_name": "गंगुली"
            },
            {
                "label": "गंगुली 0002",
                "village_id": 25,
                "village_name": "गंगुली"
            },
            {
                "label": "गोपालपुर 0022",
                "village_id": 26,
                "village_name": "गोपालपुर"
            },
            {
                "label": "गोपालपुर 0022",
                "village_id": 27,
                "village_name": "गोपालपुर"
            },
            {
                "label": "गोराई 0086",
                "village_id": 28,
                "village_name": "गोराई"
            },
            {
                "label": "घनश्यामपुर 0005",
                "village_id": 29,
                "village_name": "घनश्यामपुर"
            },
            {
                "label": "चक सरह चीया 0058",
                "village_id": 30,
                "village_name": "चक सरह चीया"
            },
            {
                "label": "चक सरहरिया 0058",
                "village_id": 31,
                "village_name": "चक सरहरिया"
            },
            {
                "label": "चैनपुर 0088",
                "village_id": 32,
                "village_name": "चैनपुर"
            },
            {
                "label": "चहुटा 0089",
                "village_id": 33,
                "village_name": "चहुटा"
            },
            {
                "label": "जगतपुर 0119",
                "village_id": 34,
                "village_name": "जगतपुर"
            },
            {
                "label": "जगौलीया 0106",
                "village_id": 35,
                "village_name": "जगौलीया"
            },
            {
                "label": "जनाढ 0019",
                "village_id": 36,
                "village_name": "जनाढ"
            },
            {
                "label": "जिउसर 0043",
                "village_id": 37,
                "village_name": "जिउसर"
            },
            {
                "label": "जोकी वुजुर्ग 0125",
                "village_id": 38,
                "village_name": "जोकी वुजुर्ग"
            },
            {
                "label": "जोकीकुर्ध 0124",
                "village_id": 39,
                "village_name": "जोकीकुर्ध"
            },
            {
                "label": "झिटकरिया 0040",
                "village_id": 40,
                "village_name": "झिटकरिया"
            },
            {
                "label": "डीहजीवर 0031",
                "village_id": 41,
                "village_name": "डीहजीवर"
            },
            {
                "label": "तेजौल 0126",
                "village_id": 42,
                "village_name": "तेजौल"
            },
            {
                "label": "तुलसी आनन्दपुर 0045",
                "village_id": 43,
                "village_name": "तुलसी आनन्दपुर"
            },
            {
                "label": "देव कुली खुर्द 0100",
                "village_id": 44,
                "village_name": "देव कुली खुर्द"
            },
            {
                "label": "देवकली वुजुर्ग 0099",
                "village_id": 45,
                "village_name": "देवकली वुजुर्ग"
            },
            {
                "label": "देवरा असरी 0129",
                "village_id": 46,
                "village_name": "देवरा असरी"
            },
            {
                "label": "धर्मपुर 0128",
                "village_id": 47,
                "village_name": "धर्मपुर"
            },
            {
                "label": "धरहरवा 0001",
                "village_id": 48,
                "village_name": "धरहरवा"
            },
            {
                "label": "धसना 0122",
                "village_id": 49,
                "village_name": "धसना"
            },
            {
                "label": "धारुपट्टी 0070",
                "village_id": 50,
                "village_name": "धारुपट्टी"
            },
            {
                "label": "ननकर रामपुर 0112",
                "village_id": 51,
                "village_name": "ननकर रामपुर"
            },
            {
                "label": "नयागाँव 0010",
                "village_id": 52,
                "village_name": "नयागाँव"
            },
            {
                "label": "नरहर 0039",
                "village_id": 53,
                "village_name": "नरहर"
            },
            {
                "label": "नरहर 0039",
                "village_id": 54,
                "village_name": "नरहर"
            },
            {
                "label": "पेकचौल 0041",
                "village_id": 55,
                "village_name": "पेकचौल"
            },
            {
                "label": "पटोरी 0056",
                "village_id": 56,
                "village_name": "पटोरी"
            },
            {
                "label": "परतापुर 0024",
                "village_id": 57,
                "village_name": "परतापुर"
            },
            {
                "label": "परमजीवर 0029",
                "village_id": 58,
                "village_name": "परमजीवर"
            },
            {
                "label": "प्रयाग चक 0104",
                "village_id": 59,
                "village_name": "प्रयाग चक"
            },
            {
                "label": "प्रयाग चक 0104",
                "village_id": 60,
                "village_name": "प्रयाग चक"
            },
            {
                "label": "फतेहपुर वेरौना 0053",
                "village_id": 61,
                "village_name": "फतेहपुर वेरौना"
            },
            {
                "label": "बैगना जागीर 0116",
                "village_id": 62,
                "village_name": "बैगना जागीर"
            },
            {
                "label": "बड़ा खुर्द 0090",
                "village_id": 63,
                "village_name": "बड़ा खुर्द"
            },
            {
                "label": "बेदौल असली 0021",
                "village_id": 64,
                "village_name": "बेदौल असली"
            },
            {
                "label": "बनौली 0049",
                "village_id": 65,
                "village_name": "बनौली"
            },
            {
                "label": "बभनगामा 0091",
                "village_id": 66,
                "village_name": "बभनगामा"
            },
            {
                "label": "बलभद्रपुर 0115",
                "village_id": 67,
                "village_name": "बलभद्रपुर"
            },
            {
                "label": "बहुआरा 0071",
                "village_id": 68,
                "village_name": "बहुआरा"
            },
            {
                "label": "बाड़ा बुजुर्ग 0059",
                "village_id": 69,
                "village_name": "बाड़ा बुजुर्ग"
            },
            {
                "label": "बोरवारा गरीब 0063",
                "village_id": 70,
                "village_name": "बोरवारा गरीब"
            },
            {
                "label": "भरत पट्टी 47/2",
                "village_id": 71,
                "village_name": "भरत पट्टी"
            },
            {
                "label": "भरतपट्टी उर्फ भऱथपट्टी 47 (",
                "village_id": 72,
                "village_name": "भरतपट्टी उर्फ भऱथपट्टी"
            },
            {
                "label": "भरथुआ 0012",
                "village_id": 73,
                "village_name": "भरथुआ"
            },
            {
                "label": "भलुरा 0108",
                "village_id": 74,
                "village_name": "भलुरा"
            },
            {
                "label": "भवानीपुर 0032",
                "village_id": 75,
                "village_name": "भवानीपुर"
            },
            {
                "label": "भादो 0067",
                "village_id": 76,
                "village_name": "भादो"
            },
            {
                "label": "मकसुदपुर 0095",
                "village_id": 77,
                "village_name": "मकसुदपुर"
            },
            {
                "label": "मेथुरापुर 0044",
                "village_id": 78,
                "village_name": "मेथुरापुर"
            },
            {
                "label": "मधुवन प्रताप 0057",
                "village_id": 79,
                "village_name": "मधुवन प्रताप"
            },
            {
                "label": "मधुवन वैशी 0007",
                "village_id": 80,
                "village_name": "मधुवन वैशी"
            },
            {
                "label": "महरौली 0087",
                "village_id": 81,
                "village_name": "महरौली"
            },
            {
                "label": "महेशवरा 0085",
                "village_id": 82,
                "village_name": "महेशवरा"
            },
            {
                "label": "महिसौथा 0130",
                "village_id": 83,
                "village_name": "महिसौथा"
            },
            {
                "label": "माधोपुर 0066",
                "village_id": 84,
                "village_name": "माधोपुर"
            },
            {
                "label": "रतनपुर 0123",
                "village_id": 85,
                "village_name": "रतनपुर"
            },
            {
                "label": "रसुलपुर 0065",
                "village_id": 86,
                "village_name": "रसुलपुर"
            },
            {
                "label": "राजखण्ड 0004",
                "village_id": 87,
                "village_name": "राजखण्ड"
            },
            {
                "label": "रामपुर 0028",
                "village_id": 88,
                "village_name": "रामपुर"
            },
            {
                "label": "रामपुर 0110",
                "village_id": 89,
                "village_name": "रामपुर"
            },
            {
                "label": "वखरी 0064",
                "village_id": 90,
                "village_name": "वखरी"
            },
            {
                "label": "वैगना निजामत 0117",
                "village_id": 91,
                "village_name": "वैगना निजामत"
            },
            {
                "label": "वैधनाथपुर 0013",
                "village_id": 92,
                "village_name": "वैधनाथपुर"
            },
            {
                "label": "वसुआ 0118",
                "village_id": 93,
                "village_name": "वसुआ"
            },
            {
                "label": "वेसी वैजनाथ 0008",
                "village_id": 94,
                "village_name": "वेसी वैजनाथ"
            },
            {
                "label": "विशुनपुर उमापत 0023",
                "village_id": 95,
                "village_name": "विशुनपुर उमापत"
            },
            {
                "label": "विशुनपुर जगदीश 0098",
                "village_id": 96,
                "village_name": "विशुनपुर जगदीश"
            },
            {
                "label": "विश्वनाथपुर 0113",
                "village_id": 97,
                "village_name": "विश्वनाथपुर"
            },
            {
                "label": "शाहपुर 0026",
                "village_id": 98,
                "village_name": "शाहपुर"
            },
            {
                "label": "शाही मीनापुर 0102",
                "village_id": 99,
                "village_name": "शाही मीनापुर"
            },
            {
                "label": "सधरी 0121",
                "village_id": 100,
                "village_name": "सधरी"
            },
            {
                "label": "सुन्दर खौली 0120",
                "village_id": 101,
                "village_name": "सुन्दर खौली"
            },
            {
                "label": "सरचिया 0054",
                "village_id": 102,
                "village_name": "सरचिया"
            },
            {
                "label": "ससौली 0094",
                "village_id": 103,
                "village_name": "ससौली"
            },
            {
                "label": "सहिला रामपुर 0138",
                "village_id": 104,
                "village_name": "सहिला रामपुर"
            },
            {
                "label": "सहिला वैजनाथ 0037",
                "village_id": 105,
                "village_name": "सहिला वैजनाथ"
            },
            {
                "label": "सहिलावली 0035",
                "village_id": 106,
                "village_name": "सहिलावली"
            },
            {
                "label": "हरपुर 0050",
                "village_id": 107,
                "village_name": "हरपुर"
            },
            {
                "label": "हरपुर वैसी 0009",
                "village_id": 108,
                "village_name": "हरपुर वैसी"
            },
            {
                "label": "हलीमपुर 0096",
                "village_id": 109,
                "village_name": "हलीमपुर"
            },
            {
                "label": "हंसवारा 0065",
                "village_id": 110,
                "village_name": "हंसवारा"
            }
        ],
        "block_id": "8",
        "block_no": 7,
        "block_name": "औराई [008]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अघरी 0219",
                "village_id": 2,
                "village_name": "अघरी"
            },
            {
                "label": "अजरकवे लखनपुर 0198",
                "village_id": 3,
                "village_name": "अजरकवे लखनपुर"
            },
            {
                "label": "अनखौली 0208",
                "village_id": 4,
                "village_name": "अनखौली"
            },
            {
                "label": "अनगोआ 0193",
                "village_id": 5,
                "village_name": "अनगोआ"
            },
            {
                "label": "अन्दामा 0223",
                "village_id": 6,
                "village_name": "अन्दामा"
            },
            {
                "label": "अम्मा 0082",
                "village_id": 7,
                "village_name": "अम्मा"
            },
            {
                "label": "इंगलिश अजरकवे 0199",
                "village_id": 8,
                "village_name": "इंगलिश अजरकवे"
            },
            {
                "label": "ईकड़ी 0170",
                "village_id": 9,
                "village_name": "ईकड़ी"
            },
            {
                "label": "उफरौली 0188",
                "village_id": 10,
                "village_name": "उफरौली"
            },
            {
                "label": "कटरा 0172",
                "village_id": 11,
                "village_name": "कटरा"
            },
            {
                "label": "कटाई 0190",
                "village_id": 12,
                "village_name": "कटाई"
            },
            {
                "label": "कटाई 0190",
                "village_id": 13,
                "village_name": "कटाई"
            },
            {
                "label": "कोपी 0134",
                "village_id": 14,
                "village_name": "कोपी"
            },
            {
                "label": "खॅगुराडीह 0206",
                "village_id": 15,
                "village_name": "खॅगुराडीह"
            },
            {
                "label": "गंगोया 0225",
                "village_id": 16,
                "village_name": "गंगोया"
            },
            {
                "label": "चकनवादा 0218",
                "village_id": 17,
                "village_name": "चकनवादा"
            },
            {
                "label": "चकहाजी 0210",
                "village_id": 18,
                "village_name": "चकहाजी"
            },
            {
                "label": "चंगेल 0184",
                "village_id": 19,
                "village_name": "चंगेल"
            },
            {
                "label": "चन्दौली 0236",
                "village_id": 20,
                "village_name": "चन्दौली"
            },
            {
                "label": "चीचरी 0215",
                "village_id": 21,
                "village_name": "चीचरी"
            },
            {
                "label": "जजुआर 0191",
                "village_id": 22,
                "village_name": "जजुआर"
            },
            {
                "label": "जितवारा 0084",
                "village_id": 23,
                "village_name": "जितवारा"
            },
            {
                "label": "टेकवारा 0187",
                "village_id": 24,
                "village_name": "टेकवारा"
            },
            {
                "label": "डरहौल 0212",
                "village_id": 25,
                "village_name": "डरहौल"
            },
            {
                "label": "तेहवारा 0217",
                "village_id": 26,
                "village_name": "तेहवारा"
            },
            {
                "label": "देवगन 0169",
                "village_id": 27,
                "village_name": "देवगन"
            },
            {
                "label": "देवमड़वा 0135",
                "village_id": 28,
                "village_name": "देवमड़वा"
            },
            {
                "label": "धनेहा 0080",
                "village_id": 29,
                "village_name": "धनेहा"
            },
            {
                "label": "धनौर 0173",
                "village_id": 30,
                "village_name": "धनौर"
            },
            {
                "label": "धोबौली 0181",
                "village_id": 31,
                "village_name": "धोबौली"
            },
            {
                "label": "नेउदी 0189",
                "village_id": 32,
                "village_name": "नेउदी"
            },
            {
                "label": "नगवारा 0201",
                "village_id": 33,
                "village_name": "नगवारा"
            },
            {
                "label": "नवादा 0221",
                "village_id": 34,
                "village_name": "नवादा"
            },
            {
                "label": "पंडौली शंकरपुर डुमरी 0180",
                "village_id": 35,
                "village_name": "पंडौली शंकरपुर डुमरी"
            },
            {
                "label": "पतारी 0222",
                "village_id": 36,
                "village_name": "पतारी"
            },
            {
                "label": "पतारी 0222",
                "village_id": 37,
                "village_name": "पतारी"
            },
            {
                "label": "पहसौल 0202",
                "village_id": 38,
                "village_name": "पहसौल"
            },
            {
                "label": "बकुची 0175",
                "village_id": 39,
                "village_name": "बकुची"
            },
            {
                "label": "बकौरी 0192",
                "village_id": 40,
                "village_name": "बकौरी"
            },
            {
                "label": "बैगनी 0209",
                "village_id": 41,
                "village_name": "बैगनी"
            },
            {
                "label": "बधपुरा 0213",
                "village_id": 42,
                "village_name": "बधपुरा"
            },
            {
                "label": "बेरई 0076",
                "village_id": 43,
                "village_name": "बेरई"
            },
            {
                "label": "बरैठा 0178",
                "village_id": 44,
                "village_name": "बरैठा"
            },
            {
                "label": "बरहद 0071",
                "village_id": 45,
                "village_name": "बरहद"
            },
            {
                "label": "बेलपकौना 0207",
                "village_id": 46,
                "village_name": "बेलपकौना"
            },
            {
                "label": "बसंत 0211",
                "village_id": 47,
                "village_name": "बसंत"
            },
            {
                "label": "बसंत 0132",
                "village_id": 48,
                "village_name": "बसंत"
            },
            {
                "label": "बिशम्भर पट्टी 0075",
                "village_id": 49,
                "village_name": "बिशम्भर पट्टी"
            },
            {
                "label": "भगवानपुर 0220",
                "village_id": 50,
                "village_name": "भगवानपुर"
            },
            {
                "label": "मदारीपुर 0072",
                "village_id": 51,
                "village_name": "मदारीपुर"
            },
            {
                "label": "मधेपुरा 0081",
                "village_id": 52,
                "village_name": "मधेपुरा"
            },
            {
                "label": "मधुरापुर चंगेल नन्हकार 0183",
                "village_id": 53,
                "village_name": "मधुरापुर चंगेल नन्हकार"
            },
            {
                "label": "मनकी 0078",
                "village_id": 54,
                "village_name": "मनकी"
            },
            {
                "label": "महरौल 0077",
                "village_id": 55,
                "village_name": "महरौल"
            },
            {
                "label": "माधोपुर 0194",
                "village_id": 56,
                "village_name": "माधोपुर"
            },
            {
                "label": "माधोपुर 0224",
                "village_id": 57,
                "village_name": "माधोपुर"
            },
            {
                "label": "माधोपुर ननकार 0195",
                "village_id": 58,
                "village_name": "माधोपुर ननकार"
            },
            {
                "label": "मोहनपुर 0177",
                "village_id": 59,
                "village_name": "मोहनपुर"
            },
            {
                "label": "राजखंड 0203",
                "village_id": 60,
                "village_name": "राजखंड"
            },
            {
                "label": "राजाडीह 0168",
                "village_id": 61,
                "village_name": "राजाडीह"
            },
            {
                "label": "रामखगुंरा 0205",
                "village_id": 62,
                "village_name": "रामखगुंरा"
            },
            {
                "label": "लखनपुर 0197",
                "village_id": 63,
                "village_name": "लखनपुर"
            },
            {
                "label": "वैगरा 0214",
                "village_id": 64,
                "village_name": "वैगरा"
            },
            {
                "label": "वैंगरा 0214",
                "village_id": 65,
                "village_name": "वैंगरा"
            },
            {
                "label": "वरुघट्टा 0176",
                "village_id": 66,
                "village_name": "वरुघट्टा"
            },
            {
                "label": "वर्दवारा 0179",
                "village_id": 67,
                "village_name": "वर्दवारा"
            },
            {
                "label": "वर्री 0226",
                "village_id": 68,
                "village_name": "वर्री"
            },
            {
                "label": "विशनपट्टी 0204",
                "village_id": 69,
                "village_name": "विशनपट्टी"
            },
            {
                "label": "विशुनपुर 0185",
                "village_id": 70,
                "village_name": "विशुनपुर"
            },
            {
                "label": "शैदपुर 0186",
                "village_id": 71,
                "village_name": "शैदपुर"
            },
            {
                "label": "शाहपुर 0196",
                "village_id": 72,
                "village_name": "शाहपुर"
            },
            {
                "label": "शिवदासपुर 0131",
                "village_id": 73,
                "village_name": "शिवदासपुर"
            },
            {
                "label": "सहनौली 0182",
                "village_id": 74,
                "village_name": "सहनौली"
            },
            {
                "label": "सहिलारामपुर पटटी 0073",
                "village_id": 75,
                "village_name": "सहिलारामपुर पटटी"
            },
            {
                "label": "सिधवारी 0200",
                "village_id": 76,
                "village_name": "सिधवारी"
            },
            {
                "label": "सोनपुर 0230",
                "village_id": 77,
                "village_name": "सोनपुर"
            },
            {
                "label": "सोहागपुर 0083",
                "village_id": 78,
                "village_name": "सोहागपुर"
            },
            {
                "label": "सोहागपुर 0083",
                "village_id": 79,
                "village_name": "सोहागपुर"
            },
            {
                "label": "सोहिजनडीह 0079",
                "village_id": 80,
                "village_name": "सोहिजनडीह"
            },
            {
                "label": "हथौ.डी 0074",
                "village_id": 81,
                "village_name": "हथौ.डी"
            }
        ],
        "block_id": "9",
        "block_no": 8,
        "block_name": "कटरा [009]",
        "sub_div_code": "1"
    },
    {
        "village": [
            {
                "label": "अकुराहा 0058",
                "village_id": 2,
                "village_name": "अकुराहा"
            },
            {
                "label": "अकुराहा हरिहर 0059",
                "village_id": 3,
                "village_name": "अकुराहा हरिहर"
            },
            {
                "label": "अख्तियारपुर 0142",
                "village_id": 4,
                "village_name": "अख्तियारपुर"
            },
            {
                "label": "अररा उर्फ हरपुर 0117",
                "village_id": 5,
                "village_name": "अररा उर्फ हरपुर"
            },
            {
                "label": "अहलादपुर 0375",
                "village_id": 6,
                "village_name": "अहलादपुर"
            },
            {
                "label": "आरिजपुर 0146",
                "village_id": 7,
                "village_name": "आरिजपुर"
            },
            {
                "label": "आरीजपुर 0146",
                "village_id": 8,
                "village_name": "आरीजपुर"
            },
            {
                "label": "कटहा उर्फ छपडा हुसैन 0128",
                "village_id": 9,
                "village_name": "कटहा उर्फ छपडा हुसैन"
            },
            {
                "label": "कैदराबाद 0512",
                "village_id": 10,
                "village_name": "कैदराबाद"
            },
            {
                "label": "कनवा 0102",
                "village_id": 11,
                "village_name": "कनवा"
            },
            {
                "label": "करजाडीह 0150",
                "village_id": 12,
                "village_name": "करजाडीह"
            },
            {
                "label": "करजान अनंत 0154",
                "village_id": 13,
                "village_name": "करजान अनंत"
            },
            {
                "label": "कसवा काँटी 0063",
                "village_id": 14,
                "village_name": "कसवा काँटी"
            },
            {
                "label": "कुसी उर्फ हरपुर 0078",
                "village_id": 15,
                "village_name": "कुसी उर्फ हरपुर"
            },
            {
                "label": "कुसी उर्फ हरपुर 0076",
                "village_id": 16,
                "village_name": "कुसी उर्फ हरपुर"
            },
            {
                "label": "काँटी खुर्द 0077",
                "village_id": 17,
                "village_name": "काँटी खुर्द"
            },
            {
                "label": "कान्ध करजा 0151",
                "village_id": 18,
                "village_name": "कान्ध करजा"
            },
            {
                "label": "काबिलपुर 0373",
                "village_id": 19,
                "village_name": "काबिलपुर"
            },
            {
                "label": "किसुनगर 0066",
                "village_id": 20,
                "village_name": "किसुनगर"
            },
            {
                "label": "कोदरिया निजामुद़दीन 0323",
                "village_id": 21,
                "village_name": "कोदरिया निजामुद़दीन"
            },
            {
                "label": "कोल्हुआ भिखनपुर 0483",
                "village_id": 22,
                "village_name": "कोल्हुआ भिखनपुर"
            },
            {
                "label": "खजुरी 0093",
                "village_id": 23,
                "village_name": "खजुरी"
            },
            {
                "label": "खलीलपुर 0312",
                "village_id": 24,
                "village_name": "खलीलपुर"
            },
            {
                "label": "खलीलपुर     ",
                "village_id": 25,
                "village_name": "खलीलपुर"
            },
            {
                "label": "गुलाम महम्द चक 0322",
                "village_id": 26,
                "village_name": "गुलाम महम्द चक"
            },
            {
                "label": "गवसरा 0141",
                "village_id": 27,
                "village_name": "गवसरा"
            },
            {
                "label": "गोपालपुर 0311",
                "village_id": 28,
                "village_name": "गोपालपुर"
            },
            {
                "label": "गोपालपुर 0109",
                "village_id": 29,
                "village_name": "गोपालपुर"
            },
            {
                "label": "गोरियार उर्फ 0319",
                "village_id": 30,
                "village_name": "गोरियार उर्फ"
            },
            {
                "label": "गोसाइपुर 0509",
                "village_id": 31,
                "village_name": "गोसाइपुर"
            },
            {
                "label": "चकमुरपुर 0397",
                "village_id": 32,
                "village_name": "चकमुरपुर"
            },
            {
                "label": "चकिया उर्फ चक्रफजल 0143",
                "village_id": 33,
                "village_name": "चकिया उर्फ चक्रफजल"
            },
            {
                "label": "चैनपुर  400",
                "village_id": 34,
                "village_name": "चैनपुर"
            },
            {
                "label": "चैनपुर उर्फ जैन 0132",
                "village_id": 35,
                "village_name": "चैनपुर उर्फ जैन"
            },
            {
                "label": "चिकनौटा उर्फ हरपुर 0389",
                "village_id": 36,
                "village_name": "चिकनौटा उर्फ हरपुर"
            },
            {
                "label": "छतरपट्टी 0119",
                "village_id": 37,
                "village_name": "छतरपट्टी"
            },
            {
                "label": "छपडा धरमपुर 0487",
                "village_id": 38,
                "village_name": "छपडा धरमपुर"
            },
            {
                "label": "छपडा मनोरथ 0071",
                "village_id": 39,
                "village_name": "छपडा मनोरथ"
            },
            {
                "label": "छपडा माधो 0111",
                "village_id": 40,
                "village_name": "छपडा माधो"
            },
            {
                "label": "जगदेवपट्टी 0324",
                "village_id": 41,
                "village_name": "जगदेवपट्टी"
            },
            {
                "label": "जैनपुर खुर्द 0136",
                "village_id": 42,
                "village_name": "जैनपुर खुर्द"
            },
            {
                "label": "जलगाँव उर्फ फुलकाहा 0116",
                "village_id": 43,
                "village_name": "जलगाँव उर्फ फुलकाहा"
            },
            {
                "label": "जसोदामठ 0102",
                "village_id": 44,
                "village_name": "जसोदामठ"
            },
            {
                "label": "जामीन किशुनगर 0062",
                "village_id": 45,
                "village_name": "जामीन किशुनगर"
            },
            {
                "label": "जोलगावा 0116",
                "village_id": 46,
                "village_name": "जोलगावा"
            },
            {
                "label": "झखरा शेख 0139",
                "village_id": 47,
                "village_name": "झखरा शेख"
            },
            {
                "label": "झिटकाही मधुबन 0106",
                "village_id": 48,
                "village_name": "झिटकाही मधुबन"
            },
            {
                "label": "द्वारिकानाथपुर 0155",
                "village_id": 49,
                "village_name": "द्वारिकानाथपुर"
            },
            {
                "label": "दाउदपुर 0479",
                "village_id": 50,
                "village_name": "दाउदपुर"
            },
            {
                "label": "दादर 0481",
                "village_id": 51,
                "village_name": "दादर"
            },
            {
                "label": "दादर टोले सिकन्दरपुर 0478",
                "village_id": 52,
                "village_name": "दादर टोले सिकन्दरपुर"
            },
            {
                "label": "दामोदरपुर 0396",
                "village_id": 53,
                "village_name": "दामोदरपुर"
            },
            {
                "label": "धमौली रामनाथ 0496",
                "village_id": 54,
                "village_name": "धमौली रामनाथ"
            },
            {
                "label": "धौसी छपडा 0057",
                "village_id": 55,
                "village_name": "धौसी छपडा"
            },
            {
                "label": "नुरूल्लहपुर 383.",
                "village_id": 56,
                "village_name": "नुरूल्लहपुर"
            },
            {
                "label": "नरसंडा 0495",
                "village_id": 57,
                "village_name": "नरसंडा"
            },
            {
                "label": "नरसंडा 0494",
                "village_id": 58,
                "village_name": "नरसंडा"
            },
            {
                "label": "नरहर सराय 0140",
                "village_id": 59,
                "village_name": "नरहर सराय"
            },
            {
                "label": "नवादा 0138",
                "village_id": 60,
                "village_name": "नवादा"
            },
            {
                "label": "नारायणपुर 0118",
                "village_id": 61,
                "village_name": "नारायणपुर"
            },
            {
                "label": "नावादा 0364",
                "village_id": 62,
                "village_name": "नावादा"
            },
            {
                "label": "नौरग शाहपुर 0153",
                "village_id": 63,
                "village_name": "नौरग शाहपुर"
            },
            {
                "label": "पकड़ी 0067",
                "village_id": 64,
                "village_name": "पकड़ी"
            },
            {
                "label": "पकडी जलाल 0398",
                "village_id": 65,
                "village_name": "पकडी जलाल"
            },
            {
                "label": "पकाही खास 0325",
                "village_id": 66,
                "village_name": "पकाही खास"
            },
            {
                "label": "पैगम्बरपुर कोल्हुआ 0482",
                "village_id": 67,
                "village_name": "पैगम्बरपुर कोल्हुआ"
            },
            {
                "label": "पचपकडी 0083",
                "village_id": 68,
                "village_name": "पचपकडी"
            },
            {
                "label": "पट्टी मीर साहेब 0095",
                "village_id": 69,
                "village_name": "पट्टी मीर साहेब"
            },
            {
                "label": "पट्टीसेन वृजलाल 0086",
                "village_id": 70,
                "village_name": "पट्टीसेन वृजलाल"
            },
            {
                "label": "पैनपुर 0400",
                "village_id": 71,
                "village_name": "पैनपुर"
            },
            {
                "label": "पेपी छपडा 0088",
                "village_id": 72,
                "village_name": "पेपी छपडा"
            },
            {
                "label": "पेमी छपडा 0089",
                "village_id": 73,
                "village_name": "पेमी छपडा"
            },
            {
                "label": "प्रतापपुर 0148",
                "village_id": 74,
                "village_name": "प्रतापपुर"
            },
            {
                "label": "पररी 0133",
                "village_id": 75,
                "village_name": "पररी"
            },
            {
                "label": "पानापुर 0131",
                "village_id": 76,
                "village_name": "पानापुर"
            },
            {
                "label": "पानापुर करियात 0370",
                "village_id": 77,
                "village_name": "पानापुर करियात"
            },
            {
                "label": "पानापुर खुर्द 0394",
                "village_id": 78,
                "village_name": "पानापुर खुर्द"
            },
            {
                "label": "पानापुर हवेली 0381",
                "village_id": 79,
                "village_name": "पानापुर हवेली"
            },
            {
                "label": "पानापुरर खुर्द 0394",
                "village_id": 80,
                "village_name": "पानापुरर खुर्द"
            },
            {
                "label": "पोखरैरा 0079",
                "village_id": 81,
                "village_name": "पोखरैरा"
            },
            {
                "label": "फतेहपुर 0068",
                "village_id": 82,
                "village_name": "फतेहपुर"
            },
            {
                "label": "फन्‍दा 0157",
                "village_id": 83,
                "village_name": "फन्‍दा"
            },
            {
                "label": "फुलकाहा गोदाई 0124",
                "village_id": 84,
                "village_name": "फुलकाहा गोदाई"
            },
            {
                "label": "फुलकाहा गोविन्द 0112",
                "village_id": 85,
                "village_name": "फुलकाहा गोविन्द"
            },
            {
                "label": "फुलकाहा मणी 0125",
                "village_id": 86,
                "village_name": "फुलकाहा मणी"
            },
            {
                "label": "बंगरा 0368",
                "village_id": 87,
                "village_name": "बंगरा"
            },
            {
                "label": "बंगरा टोला दयाल ठाकुर 0098",
                "village_id": 88,
                "village_name": "बंगरा टोला दयाल ठाकुर"
            },
            {
                "label": "बंगरा हरदास 0113",
                "village_id": 89,
                "village_name": "बंगरा हरदास"
            },
            {
                "label": "बंगरापट़टी साईन 0097",
                "village_id": 90,
                "village_name": "बंगरापट़टी साईन"
            },
            {
                "label": "बगही 0135",
                "village_id": 91,
                "village_name": "बगही"
            },
            {
                "label": "बडकागाँव 0129",
                "village_id": 92,
                "village_name": "बडकागाँव"
            },
            {
                "label": "बथना डीह 0321",
                "village_id": 93,
                "village_name": "बथना डीह"
            },
            {
                "label": "बथना राम 0320",
                "village_id": 94,
                "village_name": "बथना राम"
            },
            {
                "label": "बथनाडीह 0321",
                "village_id": 95,
                "village_name": "बथनाडीह"
            },
            {
                "label": "बथनाहा 0108",
                "village_id": 96,
                "village_name": "बथनाहा"
            },
            {
                "label": "बेराआही नारायण 0069",
                "village_id": 97,
                "village_name": "बेराआही नारायण"
            },
            {
                "label": "बैरिया 0395",
                "village_id": 98,
                "village_name": "बैरिया"
            },
            {
                "label": "बरियारपुर 0493",
                "village_id": 99,
                "village_name": "बरियारपुर"
            },
            {
                "label": "बरौना 0130",
                "village_id": 100,
                "village_name": "बरौना"
            },
            {
                "label": "बलहा 0374",
                "village_id": 101,
                "village_name": "बलहा"
            },
            {
                "label": "बंसतपुर 0110",
                "village_id": 102,
                "village_name": "बंसतपुर"
            },
            {
                "label": "बसंतपुर काँटी 0070",
                "village_id": 103,
                "village_name": "बसंतपुर काँटी"
            },
            {
                "label": "बहुआरा उर्फ हरपुर 0092",
                "village_id": 104,
                "village_name": "बहुआरा उर्फ हरपुर"
            },
            {
                "label": "बहोरा 0360",
                "village_id": 105,
                "village_name": "बहोरा"
            },
            {
                "label": "बारमत पुर 0393",
                "village_id": 106,
                "village_name": "बारमत पुर"
            },
            {
                "label": "बोड़बारा 0310",
                "village_id": 107,
                "village_name": "बोड़बारा"
            },
            {
                "label": "भगवानपुर     ",
                "village_id": 108,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भगवानपुर 0073",
                "village_id": 109,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भटौना करजा 0147",
                "village_id": 110,
                "village_name": "भटौना करजा"
            },
            {
                "label": "भेडिया उर्फ छपरा देवनन्‍दन 0084",
                "village_id": 111,
                "village_name": "भेडिया उर्फ छपरा देवनन्‍दन"
            },
            {
                "label": "भेडियाही सलोना 0115",
                "village_id": 112,
                "village_name": "भेडियाही सलोना"
            },
            {
                "label": "भेडीयाही 0072",
                "village_id": 113,
                "village_name": "भेडीयाही"
            },
            {
                "label": "भागवतपुर 0152",
                "village_id": 114,
                "village_name": "भागवतपुर"
            },
            {
                "label": "भागवतपुर 0152",
                "village_id": 115,
                "village_name": "भागवतपुर"
            },
            {
                "label": "मकदुमपुर 0314",
                "village_id": 116,
                "village_name": "मकदुमपुर"
            },
            {
                "label": "मधुकर छपडा 0080",
                "village_id": 117,
                "village_name": "मधुकर छपडा"
            },
            {
                "label": "मधुवन जगदीश 0391",
                "village_id": 118,
                "village_name": "मधुवन जगदीश"
            },
            {
                "label": "मनपुरा 0075",
                "village_id": 119,
                "village_name": "मनपुरा"
            },
            {
                "label": "मरबान खुर्द 0145",
                "village_id": 120,
                "village_name": "मरबान खुर्द"
            },
            {
                "label": "मरबांस भोज 0144",
                "village_id": 121,
                "village_name": "मरबांस भोज"
            },
            {
                "label": "मसुपुर चमरौर 0315",
                "village_id": 122,
                "village_name": "मसुपुर चमरौर"
            },
            {
                "label": "मैसाहान 0096",
                "village_id": 123,
                "village_name": "मैसाहान"
            },
            {
                "label": "महमदपुर 0122",
                "village_id": 124,
                "village_name": "महमदपुर"
            },
            {
                "label": "महमदपुर ऐमा 0137",
                "village_id": 125,
                "village_name": "महमदपुर ऐमा"
            },
            {
                "label": "महमदपुर काँटी 0122",
                "village_id": 126,
                "village_name": "महमदपुर काँटी"
            },
            {
                "label": "महमदपुर खाजे 0156",
                "village_id": 127,
                "village_name": "महमदपुर खाजे"
            },
            {
                "label": "महमदपुर सूबे 0318",
                "village_id": 128,
                "village_name": "महमदपुर सूबे"
            },
            {
                "label": "महरथा 0107",
                "village_id": 129,
                "village_name": "महरथा"
            },
            {
                "label": "मादपुर सलेपपुर उर्द फतेहपुर 0401",
                "village_id": 130,
                "village_name": "मादपुर सलेपपुर उर्द फतेहपुर"
            },
            {
                "label": "माधोपुर दुला 0061",
                "village_id": 131,
                "village_name": "माधोपुर दुला"
            },
            {
                "label": "माधोपुर मचिया 0365",
                "village_id": 132,
                "village_name": "माधोपुर मचिया"
            },
            {
                "label": "मानिकपुर नरोत्तम 0492",
                "village_id": 133,
                "village_name": "मानिकपुर नरोत्तम"
            },
            {
                "label": "मिठन सराय 0484",
                "village_id": 134,
                "village_name": "मिठन सराय"
            },
            {
                "label": "मिठनपुरा 0367",
                "village_id": 135,
                "village_name": "मिठनपुरा"
            },
            {
                "label": "मियां पकड़ी 0399",
                "village_id": 136,
                "village_name": "मियां पकड़ी"
            },
            {
                "label": "मिर्जापुर 0489",
                "village_id": 137,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "मोबारकपुर 0376",
                "village_id": 138,
                "village_name": "मोबारकपुर"
            },
            {
                "label": "रकसा 0127",
                "village_id": 139,
                "village_name": "रकसा"
            },
            {
                "label": "रतनपुर 0081",
                "village_id": 140,
                "village_name": "रतनपुर"
            },
            {
                "label": "रेपुरा 0384",
                "village_id": 141,
                "village_name": "रेपुरा"
            },
            {
                "label": "रूपवाडा 0386",
                "village_id": 142,
                "village_name": "रूपवाडा"
            },
            {
                "label": "रुपौली 0385",
                "village_id": 143,
                "village_name": "रुपौली"
            },
            {
                "label": "रसुलपुर आधार 0363",
                "village_id": 144,
                "village_name": "रसुलपुर आधार"
            },
            {
                "label": "रसुलपुर उर्फ जमाला 0123",
                "village_id": 145,
                "village_name": "रसुलपुर उर्फ जमाला"
            },
            {
                "label": "रसुलपुर करैट 0372",
                "village_id": 146,
                "village_name": "रसुलपुर करैट"
            },
            {
                "label": "रामपुर लक्षमी 0082",
                "village_id": 147,
                "village_name": "रामपुर लक्षमी"
            },
            {
                "label": "रामपुर लक्ष्मी 0082",
                "village_id": 148,
                "village_name": "रामपुर लक्ष्मी"
            },
            {
                "label": "रामपुर साह 0382",
                "village_id": 149,
                "village_name": "रामपुर साह"
            },
            {
                "label": "रोशनपुर 0371",
                "village_id": 150,
                "village_name": "रोशनपुर"
            },
            {
                "label": "लसकरीपुर 0486",
                "village_id": 151,
                "village_name": "लसकरीपुर"
            },
            {
                "label": "विमलपुर 0101",
                "village_id": 152,
                "village_name": "विमलपुर"
            },
            {
                "label": "विशुनदतपुर 0387",
                "village_id": 153,
                "village_name": "विशुनदतपुर"
            },
            {
                "label": "विशुनपुर 0134",
                "village_id": 154,
                "village_name": "विशुनपुर"
            },
            {
                "label": "विशुनपुर गंगा उर्फ बकटपुर 0104",
                "village_id": 155,
                "village_name": "विशुनपुर गंगा उर्फ बकटपुर"
            },
            {
                "label": "विशुनपुर गोविन्द 0316",
                "village_id": 156,
                "village_name": "विशुनपुर गोविन्द"
            },
            {
                "label": "विशुनपुर टोल पिरमियाँ 0091",
                "village_id": 157,
                "village_name": "विशुनपुर टोल पिरमियाँ"
            },
            {
                "label": "विशुनपुर महानन्द 0090",
                "village_id": 158,
                "village_name": "विशुनपुर महानन्द"
            },
            {
                "label": "विशुनपुरगंगा उर्फ बकरपुर 0105",
                "village_id": 159,
                "village_name": "विशुनपुरगंगा उर्फ बकरपुर"
            },
            {
                "label": "वीरपुर 0055",
                "village_id": 160,
                "village_name": "वीरपुर"
            },
            {
                "label": "शुभंकरपुर 0362",
                "village_id": 161,
                "village_name": "शुभंकरपुर"
            },
            {
                "label": "शेरपुर 0370",
                "village_id": 162,
                "village_name": "शेरपुर"
            },
            {
                "label": "श्रीसिया खुर्द 0490",
                "village_id": 163,
                "village_name": "श्रीसिया खुर्द"
            },
            {
                "label": "शहबाजपुर 0488",
                "village_id": 164,
                "village_name": "शहबाजपुर"
            },
            {
                "label": "शामपुर भोज 0377",
                "village_id": 165,
                "village_name": "शामपुर भोज"
            },
            {
                "label": "शाहपुर 0390",
                "village_id": 166,
                "village_name": "शाहपुर"
            },
            {
                "label": "सदातपुर 0392",
                "village_id": 167,
                "village_name": "सदातपुर"
            },
            {
                "label": "सेरूकाही 0121",
                "village_id": 168,
                "village_name": "सेरूकाही"
            },
            {
                "label": "सुरतपुर 0369",
                "village_id": 169,
                "village_name": "सुरतपुर"
            },
            {
                "label": "सुरतपुर 0485",
                "village_id": 170,
                "village_name": "सुरतपुर"
            },
            {
                "label": "सेरना उर्फ भेडियाही 0074",
                "village_id": 171,
                "village_name": "सेरना उर्फ भेडियाही"
            },
            {
                "label": "सलेमपुर    5",
                "village_id": 172,
                "village_name": "सलेमपुर"
            },
            {
                "label": "सलाहपुर 0317",
                "village_id": 173,
                "village_name": "सलाहपुर"
            },
            {
                "label": "साइन चॉद पाण्‍डे 0099",
                "village_id": 174,
                "village_name": "साइन चॉद पाण्‍डे"
            },
            {
                "label": "साइन छपरा देवानन्‍द 0087",
                "village_id": 175,
                "village_name": "साइन छपरा देवानन्‍द"
            },
            {
                "label": "साइन पट़टी शिवप्रसनसिंह 0094",
                "village_id": 176,
                "village_name": "साइन पट़टी शिवप्रसनसिंह"
            },
            {
                "label": "साइन रामराय 0085",
                "village_id": 177,
                "village_name": "साइन रामराय"
            },
            {
                "label": "सिगार फुलकाहां 0114",
                "village_id": 178,
                "village_name": "सिगार फुलकाहां"
            },
            {
                "label": "सिरसिया बुजुर्ग 0491",
                "village_id": 179,
                "village_name": "सिरसिया बुजुर्ग"
            },
            {
                "label": "सोनबरसा 0388",
                "village_id": 180,
                "village_name": "सोनबरसा"
            },
            {
                "label": "हरचन्दा 0126",
                "village_id": 181,
                "village_name": "हरचन्दा"
            },
            {
                "label": "हरदासपुर 0120",
                "village_id": 182,
                "village_name": "हरदासपुर"
            },
            {
                "label": "हरपुर गणेश 0366",
                "village_id": 183,
                "village_name": "हरपुर गणेश"
            },
            {
                "label": "हरपुर लहौड़ी 0480",
                "village_id": 184,
                "village_name": "हरपुर लहौड़ी"
            },
            {
                "label": "हिछडा 0380",
                "village_id": 185,
                "village_name": "हिछडा"
            }
        ],
        "block_id": "10",
        "block_no": 9,
        "block_name": "काँटी [010]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "आनन्‍दपुर खरौनी 0286",
                "village_id": 2,
                "village_name": "आनन्‍दपुर खरौनी"
            },
            {
                "label": "इन्‍दौलिया 0372",
                "village_id": 3,
                "village_name": "इन्‍दौलिया"
            },
            {
                "label": "उमवॉ 0576",
                "village_id": 4,
                "village_name": "उमवॉ"
            },
            {
                "label": "उस्ती 0304",
                "village_id": 5,
                "village_name": "उस्ती"
            },
            {
                "label": "एराजी 0299",
                "village_id": 6,
                "village_name": "एराजी"
            },
            {
                "label": "कुआरी 0354",
                "village_id": 7,
                "village_name": "कुआरी"
            },
            {
                "label": "कटारु 0317",
                "village_id": 8,
                "village_name": "कटारु"
            },
            {
                "label": "कटारु डीह 0364",
                "village_id": 9,
                "village_name": "कटारु डीह"
            },
            {
                "label": "कन्‍हौली बलराम 0325",
                "village_id": 10,
                "village_name": "कन्‍हौली बलराम"
            },
            {
                "label": "कन्हौली वलराम 0326",
                "village_id": 11,
                "village_name": "कन्हौली वलराम"
            },
            {
                "label": "कमलपुरा 0361",
                "village_id": 12,
                "village_name": "कमलपुरा"
            },
            {
                "label": "कर्णपुरा 0489",
                "village_id": 13,
                "village_name": "कर्णपुरा"
            },
            {
                "label": "करमवारी 0313",
                "village_id": 14,
                "village_name": "करमवारी"
            },
            {
                "label": "कल्‍याणपुर 0310",
                "village_id": 15,
                "village_name": "कल्‍याणपुर"
            },
            {
                "label": "कुवौली 0339",
                "village_id": 16,
                "village_name": "कुवौली"
            },
            {
                "label": "केशोपुर वभनगॉव 0389",
                "village_id": 17,
                "village_name": "केशोपुर वभनगॉव"
            },
            {
                "label": "कोईरिया निजामत 0418",
                "village_id": 18,
                "village_name": "कोईरिया निजामत"
            },
            {
                "label": "कोदरिया योगी 0384",
                "village_id": 19,
                "village_name": "कोदरिया योगी"
            },
            {
                "label": "कोदरिया लाल 0363",
                "village_id": 20,
                "village_name": "कोदरिया लाल"
            },
            {
                "label": "कोहरिया जागीर 0419",
                "village_id": 21,
                "village_name": "कोहरिया जागीर"
            },
            {
                "label": "कोहवारा 0355",
                "village_id": 22,
                "village_name": "कोहवारा"
            },
            {
                "label": "खुटांही 0366",
                "village_id": 23,
                "village_name": "खुटांही"
            },
            {
                "label": "खरिहनियां 0421",
                "village_id": 24,
                "village_name": "खरिहनियां"
            },
            {
                "label": "खिरजपुरा उर्फ खेदलपुरा 0368",
                "village_id": 25,
                "village_name": "खिरजपुरा उर्फ खेदलपुरा"
            },
            {
                "label": "खीमीरपुरा 0368",
                "village_id": 26,
                "village_name": "खीमीरपुरा"
            },
            {
                "label": "गंगोई 0492",
                "village_id": 27,
                "village_name": "गंगोई"
            },
            {
                "label": "गदोपुरा 0360",
                "village_id": 28,
                "village_name": "गदोपुरा"
            },
            {
                "label": "ग्यासपुर 0320",
                "village_id": 29,
                "village_name": "ग्यासपुर"
            },
            {
                "label": "गरीवा 0430",
                "village_id": 30,
                "village_name": "गरीवा"
            },
            {
                "label": "गाढा बहराम 0432",
                "village_id": 31,
                "village_name": "गाढा बहराम"
            },
            {
                "label": "गाढा हसन 0433",
                "village_id": 32,
                "village_name": "गाढा हसन"
            },
            {
                "label": "गाधोपुर बुजुर्ग 0301",
                "village_id": 33,
                "village_name": "गाधोपुर बुजुर्ग"
            },
            {
                "label": "गोखुला रुपौली 0308",
                "village_id": 34,
                "village_name": "गोखुला रुपौली"
            },
            {
                "label": "गोपालपुर 0497",
                "village_id": 35,
                "village_name": "गोपालपुर"
            },
            {
                "label": "चक्‍की सोहागपुर 0328",
                "village_id": 36,
                "village_name": "चक्‍की सोहागपुर"
            },
            {
                "label": "चकदेवरिया 0302",
                "village_id": 37,
                "village_name": "चकदेवरिया"
            },
            {
                "label": "चकनाथन 0493",
                "village_id": 38,
                "village_name": "चकनाथन"
            },
            {
                "label": "चकभरत पट़टी 0367",
                "village_id": 39,
                "village_name": "चकभरत पट़टी"
            },
            {
                "label": "चकमोरीद 0323",
                "village_id": 40,
                "village_name": "चकमोरीद"
            },
            {
                "label": "चकरोशन 0416",
                "village_id": 41,
                "village_name": "चकरोशन"
            },
            {
                "label": "चकिया 0386",
                "village_id": 42,
                "village_name": "चकिया"
            },
            {
                "label": "चतुरपट़टी 0490",
                "village_id": 43,
                "village_name": "चतुरपट़टी"
            },
            {
                "label": "चतुरपट्टी 0288",
                "village_id": 44,
                "village_name": "चतुरपट्टी"
            },
            {
                "label": "चैनपुर चिउटहॉ 0287",
                "village_id": 45,
                "village_name": "चैनपुर चिउटहॉ"
            },
            {
                "label": "चैनपुरा 0282",
                "village_id": 46,
                "village_name": "चैनपुरा"
            },
            {
                "label": "चाँद कुआरी 0123",
                "village_id": 47,
                "village_name": "चाँद कुआरी"
            },
            {
                "label": "चिंतावनपुर 0338",
                "village_id": 48,
                "village_name": "चिंतावनपुर"
            },
            {
                "label": "चीतु छपडा 0300",
                "village_id": 49,
                "village_name": "चीतु छपडा"
            },
            {
                "label": "चॉदपुरा 0281",
                "village_id": 50,
                "village_name": "चॉदपुरा"
            },
            {
                "label": "चोचाही छपडा 0285",
                "village_id": 51,
                "village_name": "चोचाही छपडा"
            },
            {
                "label": "छपरा आस 0382",
                "village_id": 52,
                "village_name": "छपरा आस"
            },
            {
                "label": "छाप 0357",
                "village_id": 53,
                "village_name": "छाप"
            },
            {
                "label": "छोलकार जैमल 0330",
                "village_id": 54,
                "village_name": "छोलकार जैमल"
            },
            {
                "label": "छोलकार विक्रम 0335",
                "village_id": 55,
                "village_name": "छोलकार विक्रम"
            },
            {
                "label": "जगदीशपुर धरम 0383",
                "village_id": 56,
                "village_name": "जगदीशपुर धरम"
            },
            {
                "label": "जगदीशपुर वाया 0385",
                "village_id": 57,
                "village_name": "जगदीशपुर वाया"
            },
            {
                "label": "जगन्नाथपुर नगमा 0359",
                "village_id": 58,
                "village_name": "जगन्नाथपुर नगमा"
            },
            {
                "label": "जगन्नाथपुर नगमा 0358",
                "village_id": 59,
                "village_name": "जगन्नाथपुर नगमा"
            },
            {
                "label": "जलील नगर 0423",
                "village_id": 60,
                "village_name": "जलील नगर"
            },
            {
                "label": "जलील नगर 0426",
                "village_id": 61,
                "village_name": "जलील नगर"
            },
            {
                "label": "जाफरपुर 0370",
                "village_id": 62,
                "village_name": "जाफरपुर"
            },
            {
                "label": "डेकुआ 0390",
                "village_id": 63,
                "village_name": "डेकुआ"
            },
            {
                "label": "डुमरी जयमाल 0341",
                "village_id": 64,
                "village_name": "डुमरी जयमाल"
            },
            {
                "label": "डुमरी परमानन्दपुर 0122",
                "village_id": 65,
                "village_name": "डुमरी परमानन्दपुर"
            },
            {
                "label": "डुमरी लक्षी दियरा 0336",
                "village_id": 66,
                "village_name": "डुमरी लक्षी दियरा"
            },
            {
                "label": "डुमरी शाहमहम्‍मद 0340",
                "village_id": 67,
                "village_name": "डुमरी शाहमहम्‍मद"
            },
            {
                "label": "ढेंगपुर 0380",
                "village_id": 68,
                "village_name": "ढेंगपुर"
            },
            {
                "label": "तेजाडुमरी 0331",
                "village_id": 69,
                "village_name": "तेजाडुमरी"
            },
            {
                "label": "तेजाडुमरी (वारहआना) 0332",
                "village_id": 70,
                "village_name": "तेजाडुमरी (वारहआना)"
            },
            {
                "label": "ताल खजुरी 0440",
                "village_id": 71,
                "village_name": "ताल खजुरी"
            },
            {
                "label": "दुखन सरैया 0527",
                "village_id": 72,
                "village_name": "दुखन सरैया"
            },
            {
                "label": "दुबौली 0125",
                "village_id": 73,
                "village_name": "दुबौली"
            },
            {
                "label": "देवरिया 0133",
                "village_id": 74,
                "village_name": "देवरिया"
            },
            {
                "label": "दाउदपुर 0369",
                "village_id": 75,
                "village_name": "दाउदपुर"
            },
            {
                "label": "दामोदपुर 0429",
                "village_id": 76,
                "village_name": "दामोदपुर"
            },
            {
                "label": "धरफरी 0121",
                "village_id": 77,
                "village_name": "धरफरी"
            },
            {
                "label": "नेफनागपुर 0297",
                "village_id": 78,
                "village_name": "नेफनागपुर"
            },
            {
                "label": "नयाटोला 0496",
                "village_id": 79,
                "village_name": "नयाटोला"
            },
            {
                "label": "नीमपट़टी 0495",
                "village_id": 80,
                "village_name": "नीमपट़टी"
            },
            {
                "label": "पन्देह 0314",
                "village_id": 81,
                "village_name": "पन्देह"
            },
            {
                "label": "पुलाढ 0362",
                "village_id": 82,
                "village_name": "पुलाढ"
            },
            {
                "label": "पारुखास 0431",
                "village_id": 83,
                "village_name": "पारुखास"
            },
            {
                "label": "पीचपुरा 0279",
                "village_id": 84,
                "village_name": "पीचपुरा"
            },
            {
                "label": "फतहपुर 0393",
                "village_id": 85,
                "village_name": "फतहपुर"
            },
            {
                "label": "फतेहाबाद 0321",
                "village_id": 86,
                "village_name": "फतेहाबाद"
            },
            {
                "label": "फंदा 0348",
                "village_id": 87,
                "village_name": "फंदा"
            },
            {
                "label": "फुलवरिया 0307",
                "village_id": 88,
                "village_name": "फुलवरिया"
            },
            {
                "label": "फिरोजपुर 0375",
                "village_id": 89,
                "village_name": "फिरोजपुर"
            },
            {
                "label": "ब्रहम्पुरा 0283",
                "village_id": 90,
                "village_name": "ब्रहम्पुरा"
            },
            {
                "label": "भटौलिया 0488",
                "village_id": 91,
                "village_name": "भटौलिया"
            },
            {
                "label": "भटौलिया 0365",
                "village_id": 92,
                "village_name": "भटौलिया"
            },
            {
                "label": "भेलाही 0394",
                "village_id": 93,
                "village_name": "भेलाही"
            },
            {
                "label": "भागवतपुर 0305",
                "village_id": 94,
                "village_name": "भागवतपुर"
            },
            {
                "label": "भीखनपुर 0349",
                "village_id": 95,
                "village_name": "भीखनपुर"
            },
            {
                "label": "भोजपट्टी 0289",
                "village_id": 96,
                "village_name": "भोजपट्टी"
            },
            {
                "label": "मंगु‍रहिया 0392",
                "village_id": 97,
                "village_name": "मंगु‍रहिया"
            },
            {
                "label": "मटीहनिया 0318",
                "village_id": 98,
                "village_name": "मटीहनिया"
            },
            {
                "label": "मेथुरापुर डाक 0378",
                "village_id": 99,
                "village_name": "मेथुरापुर डाक"
            },
            {
                "label": "मेथुरापुर दखली 0377",
                "village_id": 100,
                "village_name": "मेथुरापुर दखली"
            },
            {
                "label": "मेथुरापुर पटटी 0376",
                "village_id": 101,
                "village_name": "मेथुरापुर पटटी"
            },
            {
                "label": "मदन छपडा 0322",
                "village_id": 102,
                "village_name": "मदन छपडा"
            },
            {
                "label": "ममरेजपुर 0371",
                "village_id": 103,
                "village_name": "ममरेजपुर"
            },
            {
                "label": "मंशूरपुर गोंठा 0425",
                "village_id": 104,
                "village_name": "मंशूरपुर गोंठा"
            },
            {
                "label": "मंहब्‍बतपुर 0293",
                "village_id": 105,
                "village_name": "मंहब्‍बतपुर"
            },
            {
                "label": "महमदपुर परखी 0296",
                "village_id": 106,
                "village_name": "महमदपुर परखी"
            },
            {
                "label": "महमदपुर वढन 0353",
                "village_id": 107,
                "village_name": "महमदपुर वढन"
            },
            {
                "label": "महमदपुर शेख 0373",
                "village_id": 108,
                "village_name": "महमदपुर शेख"
            },
            {
                "label": "महम्मदपुर पारु 0417",
                "village_id": 109,
                "village_name": "महम्मदपुर पारु"
            },
            {
                "label": "माधोपुर बैकुण्‍ठ 0337",
                "village_id": 110,
                "village_name": "माधोपुर बैकुण्‍ठ"
            },
            {
                "label": "मानिकपुर 0309",
                "village_id": 111,
                "village_name": "मानिकपुर"
            },
            {
                "label": "मिर्जापुर 0491",
                "village_id": 112,
                "village_name": "मिर्जापुर"
            },
            {
                "label": "मोती छपरा 0427",
                "village_id": 113,
                "village_name": "मोती छपरा"
            },
            {
                "label": "मोहजम्मा 0379",
                "village_id": 114,
                "village_name": "मोहजम्मा"
            },
            {
                "label": "यमुना 0388",
                "village_id": 115,
                "village_name": "यमुना"
            },
            {
                "label": "रघुनाथपुर 0529",
                "village_id": 116,
                "village_name": "रघुनाथपुर"
            },
            {
                "label": "रतवारु डलू 0306",
                "village_id": 117,
                "village_name": "रतवारु डलू"
            },
            {
                "label": "रुपनारायणपुर 0298",
                "village_id": 118,
                "village_name": "रुपनारायणपुर"
            },
            {
                "label": "रूस्‍तमपुर 0312",
                "village_id": 119,
                "village_name": "रूस्‍तमपुर"
            },
            {
                "label": "रसूलपुर 0254",
                "village_id": 120,
                "village_name": "रसूलपुर"
            },
            {
                "label": "रामचन्‍द्रपुर 0295",
                "village_id": 121,
                "village_name": "रामचन्‍द्रपुर"
            },
            {
                "label": "रामपुर रेशो 0422",
                "village_id": 122,
                "village_name": "रामपुर रेशो"
            },
            {
                "label": "लक्ष्‍मी डुमरी 0333",
                "village_id": 123,
                "village_name": "लक्ष्‍मी डुमरी"
            },
            {
                "label": "लखनौरी 0292",
                "village_id": 124,
                "village_name": "लखनौरी"
            },
            {
                "label": "लालूछपडा 0424",
                "village_id": 125,
                "village_name": "लालूछपडा"
            },
            {
                "label": "वैजलपुर 0311",
                "village_id": 126,
                "village_name": "वैजलपुर"
            },
            {
                "label": "वडलोलपुर 0374",
                "village_id": 127,
                "village_name": "वडलोलपुर"
            },
            {
                "label": "वुढानपुर 0294",
                "village_id": 128,
                "village_name": "वुढानपुर"
            },
            {
                "label": "वसंतपुर पारु 0420",
                "village_id": 129,
                "village_name": "वसंतपुर पारु"
            },
            {
                "label": "वहदीनपुर 0381",
                "village_id": 130,
                "village_name": "वहदीनपुर"
            },
            {
                "label": "वाजीतपुर 0395",
                "village_id": 131,
                "village_name": "वाजीतपुर"
            },
            {
                "label": "विन्दी 0124",
                "village_id": 132,
                "village_name": "विन्दी"
            },
            {
                "label": "विशुनपुर गोपाल 0494",
                "village_id": 133,
                "village_name": "विशुनपुर गोपाल"
            },
            {
                "label": "विशुनपुर जयनारायण 0316",
                "village_id": 134,
                "village_name": "विशुनपुर जयनारायण"
            },
            {
                "label": "विशुनपुर पाठक 0487",
                "village_id": 135,
                "village_name": "विशुनपुर पाठक"
            },
            {
                "label": "विशुनपुर सरैया 0291",
                "village_id": 136,
                "village_name": "विशुनपुर सरैया"
            },
            {
                "label": "शाहपुर 0436",
                "village_id": 137,
                "village_name": "शाहपुर"
            },
            {
                "label": "सखडा 0530",
                "village_id": 138,
                "village_name": "सखडा"
            },
            {
                "label": "सरमस्तपुर 0319",
                "village_id": 139,
                "village_name": "सरमस्तपुर"
            },
            {
                "label": "सरैया 0284",
                "village_id": 140,
                "village_name": "सरैया"
            },
            {
                "label": "सलाहपुर 0127",
                "village_id": 141,
                "village_name": "सलाहपुर"
            },
            {
                "label": "सलाहपुर 0356",
                "village_id": 142,
                "village_name": "सलाहपुर"
            },
            {
                "label": "सारंगपुर 0577",
                "village_id": 143,
                "village_name": "सारंगपुर"
            },
            {
                "label": "सिंगाही 0303",
                "village_id": 144,
                "village_name": "सिंगाही"
            },
            {
                "label": "सिमरा भगवानापुर 0428",
                "village_id": 145,
                "village_name": "सिमरा भगवानापुर"
            },
            {
                "label": "सोशंसी 0119",
                "village_id": 146,
                "village_name": "सोशंसी"
            },
            {
                "label": "सोहागपुर 0327",
                "village_id": 147,
                "village_name": "सोहागपुर"
            },
            {
                "label": "सोहासा 0120",
                "village_id": 148,
                "village_name": "सोहासा"
            },
            {
                "label": "सौडिग्री 1 0334",
                "village_id": 149,
                "village_name": "सौडिग्री 1"
            },
            {
                "label": "सौडिगी 2 0329",
                "village_id": 150,
                "village_name": "सौडिगी 2"
            },
            {
                "label": "हड़पुर कपरफोरा 0290",
                "village_id": 151,
                "village_name": "हड़पुर कपरफोरा"
            },
            {
                "label": "हरपुर भीसी 0387",
                "village_id": 152,
                "village_name": "हरपुर भीसी"
            },
            {
                "label": "हरिशंकरपुर 0415",
                "village_id": 153,
                "village_name": "हरिशंकरपुर"
            },
            {
                "label": "हासीनपुर 0315",
                "village_id": 154,
                "village_name": "हासीनपुर"
            },
            {
                "label": "हीरापुर 0391",
                "village_id": 155,
                "village_name": "हीरापुर"
            }
        ],
        "block_id": "11",
        "block_no": 10,
        "block_name": "पारु [011]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अख्तीयारपुर 0162",
                "village_id": 2,
                "village_name": "अख्तीयारपुर"
            },
            {
                "label": "अण्डौल 0172",
                "village_id": 3,
                "village_name": "अण्डौल"
            },
            {
                "label": "अदलपुर 0206",
                "village_id": 4,
                "village_name": "अदलपुर"
            },
            {
                "label": "असवारी वंजरिया 0246",
                "village_id": 5,
                "village_name": "असवारी वंजरिया"
            },
            {
                "label": "अहिरौलीया 0160",
                "village_id": 6,
                "village_name": "अहिरौलीया"
            },
            {
                "label": "इब्राहीमपुर 0156",
                "village_id": 7,
                "village_name": "इब्राहीमपुर"
            },
            {
                "label": "एकौलिया 0251",
                "village_id": 8,
                "village_name": "एकौलिया"
            },
            {
                "label": "कुआही 0201",
                "village_id": 9,
                "village_name": "कुआही"
            },
            {
                "label": "कुड़िया 0270",
                "village_id": 10,
                "village_name": "कुड़िया"
            },
            {
                "label": "कथैया 0271",
                "village_id": 11,
                "village_name": "कथैया"
            },
            {
                "label": "कथौलिया 0158",
                "village_id": 12,
                "village_name": "कथौलिया"
            },
            {
                "label": "कमालपुर विशरौल 0165",
                "village_id": 13,
                "village_name": "कमालपुर विशरौल"
            },
            {
                "label": "कल्याणपुर हरौना 0205",
                "village_id": 14,
                "village_name": "कल्याणपुर हरौना"
            },
            {
                "label": "क्षत्रपट्टी 0166",
                "village_id": 15,
                "village_name": "क्षत्रपट्टी"
            },
            {
                "label": "कोड़ड़ा 0241",
                "village_id": 16,
                "village_name": "कोड़ड़ा"
            },
            {
                "label": "खन्तरी महांनन्द 0178",
                "village_id": 17,
                "village_name": "खन्तरी महांनन्द"
            },
            {
                "label": "खिरू छपरा 0188",
                "village_id": 18,
                "village_name": "खिरू छपरा"
            },
            {
                "label": "गोखुला 0173",
                "village_id": 19,
                "village_name": "गोखुला"
            },
            {
                "label": "गोपालपुर 0260",
                "village_id": 20,
                "village_name": "गोपालपुर"
            },
            {
                "label": "गोपीनाथ पुर 0145",
                "village_id": 21,
                "village_name": "गोपीनाथ पुर"
            },
            {
                "label": "गोपीनाथपुर 0243",
                "village_id": 22,
                "village_name": "गोपीनाथपुर"
            },
            {
                "label": "गोसाईपुर 0177",
                "village_id": 23,
                "village_name": "गोसाईपुर"
            },
            {
                "label": "गौसपुर 0247",
                "village_id": 24,
                "village_name": "गौसपुर"
            },
            {
                "label": "चकचुहर 0219",
                "village_id": 25,
                "village_name": "चकचुहर"
            },
            {
                "label": "चकवा 0152",
                "village_id": 26,
                "village_name": "चकवा"
            },
            {
                "label": "चैनपुर 0157",
                "village_id": 27,
                "village_name": "चैनपुर"
            },
            {
                "label": "चौरघटा 0199",
                "village_id": 28,
                "village_name": "चौरघटा"
            },
            {
                "label": "जगदौन छपर 0257",
                "village_id": 29,
                "village_name": "जगदौन छपर"
            },
            {
                "label": "जगरनाथपुर 0240",
                "village_id": 30,
                "village_name": "जगरनाथपुर"
            },
            {
                "label": "जुनेदा हरपुर 0225",
                "village_id": 31,
                "village_name": "जुनेदा हरपुर"
            },
            {
                "label": "जमालपुर 0261",
                "village_id": 32,
                "village_name": "जमालपुर"
            },
            {
                "label": "जमालपुर 0261",
                "village_id": 33,
                "village_name": "जमालपुर"
            },
            {
                "label": "जरौलीया 0138",
                "village_id": 34,
                "village_name": "जरौलीया"
            },
            {
                "label": "जसौली 0268",
                "village_id": 35,
                "village_name": "जसौली"
            },
            {
                "label": "जहाँगीरपुर 0232",
                "village_id": 36,
                "village_name": "जहाँगीरपुर"
            },
            {
                "label": "ठीकहाँ 0244",
                "village_id": 37,
                "village_name": "ठीकहाँ"
            },
            {
                "label": "डुमरीया 0169",
                "village_id": 38,
                "village_name": "डुमरीया"
            },
            {
                "label": "डोरा छपड़ा 0258",
                "village_id": 39,
                "village_name": "डोरा छपड़ा"
            },
            {
                "label": "तुलसी छपरा 0161",
                "village_id": 40,
                "village_name": "तुलसी छपरा"
            },
            {
                "label": "दिस्तौलीया 0256",
                "village_id": 41,
                "village_name": "दिस्तौलीया"
            },
            {
                "label": "धनौती 0276",
                "village_id": 42,
                "village_name": "धनौती"
            },
            {
                "label": "धर्मपुर इशहाक 0196",
                "village_id": 43,
                "village_name": "धर्मपुर इशहाक"
            },
            {
                "label": "नखारा काशी छपड़ा 0140",
                "village_id": 44,
                "village_name": "नखारा काशी छपड़ा"
            },
            {
                "label": "नरवारा 0139",
                "village_id": 45,
                "village_name": "नरवारा"
            },
            {
                "label": "नरियार 0210",
                "village_id": 46,
                "village_name": "नरियार"
            },
            {
                "label": "नारायणपुर कुशाही 0230",
                "village_id": 47,
                "village_name": "नारायणपुर कुशाही"
            },
            {
                "label": "पकडी 0147",
                "village_id": 48,
                "village_name": "पकडी"
            },
            {
                "label": "पगहीया रैती 0249",
                "village_id": 49,
                "village_name": "पगहीया रैती"
            },
            {
                "label": "पचरुखी 0228",
                "village_id": 50,
                "village_name": "पचरुखी"
            },
            {
                "label": "पट्टी असवारी 0245",
                "village_id": 51,
                "village_name": "पट्टी असवारी"
            },
            {
                "label": "प्यारेपुर 0259",
                "village_id": 52,
                "village_name": "प्यारेपुर"
            },
            {
                "label": "परसौनी कपुर 0252",
                "village_id": 53,
                "village_name": "परसौनी कपुर"
            },
            {
                "label": "परसौनी नाथ 0183",
                "village_id": 54,
                "village_name": "परसौनी नाथ"
            },
            {
                "label": "पाना छपड़ा 0235",
                "village_id": 55,
                "village_name": "पाना छपड़ा"
            },
            {
                "label": "पानापुर 0181",
                "village_id": 56,
                "village_name": "पानापुर"
            },
            {
                "label": "पिपरा विशो 0176",
                "village_id": 57,
                "village_name": "पिपरा विशो"
            },
            {
                "label": "फतेहाँ 0274",
                "village_id": 58,
                "village_name": "फतेहाँ"
            },
            {
                "label": "फुलवरीया 0179",
                "village_id": 59,
                "village_name": "फुलवरीया"
            },
            {
                "label": "बखरा 0221",
                "village_id": 60,
                "village_name": "बखरा"
            },
            {
                "label": "बंगरा पट्टी 0141",
                "village_id": 61,
                "village_name": "बंगरा पट्टी"
            },
            {
                "label": "बंगरी 0114",
                "village_id": 62,
                "village_name": "बंगरी"
            },
            {
                "label": "बंजरिया 0234",
                "village_id": 63,
                "village_name": "बंजरिया"
            },
            {
                "label": "ब्रह्मपुर कर्मणा 0237",
                "village_id": 64,
                "village_name": "ब्रह्मपुर कर्मणा"
            },
            {
                "label": "बुलबुलवा उर्फ छपरा चिंतामनपुर 0193",
                "village_id": 65,
                "village_name": "बुलबुलवा उर्फ छपरा चिंतामनपुर"
            },
            {
                "label": "बाड़ा बैजनाथ 0150",
                "village_id": 66,
                "village_name": "बाड़ा बैजनाथ"
            },
            {
                "label": "मंगुराहा 0153",
                "village_id": 67,
                "village_name": "मंगुराहा"
            },
            {
                "label": "मटैयां 0155",
                "village_id": 68,
                "village_name": "मटैयां"
            },
            {
                "label": "मठवां लक्षमण 0277",
                "village_id": 69,
                "village_name": "मठवां लक्षमण"
            },
            {
                "label": "मनोहर छपरा 0170",
                "village_id": 70,
                "village_name": "मनोहर छपरा"
            },
            {
                "label": "मुरारपुर 0168",
                "village_id": 71,
                "village_name": "मुरारपुर"
            },
            {
                "label": "मलकिना बगही 0233",
                "village_id": 72,
                "village_name": "मलकिना बगही"
            },
            {
                "label": "मुशहरी 0266",
                "village_id": 73,
                "village_name": "मुशहरी"
            },
            {
                "label": "मंसुरपुर 0238",
                "village_id": 74,
                "village_name": "मंसुरपुर"
            },
            {
                "label": "महमदपुर गंग 0159",
                "village_id": 75,
                "village_name": "महमदपुर गंग"
            },
            {
                "label": "महमदपुर बलमी 0227",
                "village_id": 76,
                "village_name": "महमदपुर बलमी"
            },
            {
                "label": "महमदपुर महमदा 0197",
                "village_id": 77,
                "village_name": "महमदपुर महमदा"
            },
            {
                "label": "महमदपुर राजे 0198",
                "village_id": 78,
                "village_name": "महमदपुर राजे"
            },
            {
                "label": "महवल 0202",
                "village_id": 79,
                "village_name": "महवल"
            },
            {
                "label": "माधोपुर मधु 0142",
                "village_id": 80,
                "village_name": "माधोपुर मधु"
            },
            {
                "label": "मानपुरा 0171",
                "village_id": 81,
                "village_name": "मानपुरा"
            },
            {
                "label": "मानिकपुर 0253",
                "village_id": 82,
                "village_name": "मानिकपुर"
            },
            {
                "label": "मानीकपुर 0164",
                "village_id": 83,
                "village_name": "मानीकपुर"
            },
            {
                "label": "माहिया गोपीनाथपुर 0236",
                "village_id": 84,
                "village_name": "माहिया गोपीनाथपुर"
            },
            {
                "label": "मिठनपुर श्रीराम 0146",
                "village_id": 85,
                "village_name": "मिठनपुर श्रीराम"
            },
            {
                "label": "मीनापुर 0190",
                "village_id": 86,
                "village_name": "मीनापुर"
            },
            {
                "label": "मोतीपुर 0222",
                "village_id": 87,
                "village_name": "मोतीपुर"
            },
            {
                "label": "मोरसंडी 0209",
                "village_id": 88,
                "village_name": "मोरसंडी"
            },
            {
                "label": "मोहदीपुर 0151",
                "village_id": 89,
                "village_name": "मोहदीपुर"
            },
            {
                "label": "मौना 0187",
                "village_id": 90,
                "village_name": "मौना"
            },
            {
                "label": "रतनपुरा 0213",
                "village_id": 91,
                "village_name": "रतनपुरा"
            },
            {
                "label": "रमपुरवा 0180",
                "village_id": 92,
                "village_name": "रमपुरवा"
            },
            {
                "label": "रसुलपुर 0217",
                "village_id": 93,
                "village_name": "रसुलपुर"
            },
            {
                "label": "रामपुर उगन 0218",
                "village_id": 94,
                "village_name": "रामपुर उगन"
            },
            {
                "label": "रामपुर भेडीयाही 0275",
                "village_id": 95,
                "village_name": "रामपुर भेडीयाही"
            },
            {
                "label": "लखन सेन 0184",
                "village_id": 96,
                "village_name": "लखन सेन"
            },
            {
                "label": "लोही नवलपुर 0267",
                "village_id": 97,
                "village_name": "लोही नवलपुर"
            },
            {
                "label": "वखरी 0214",
                "village_id": 98,
                "village_name": "वखरी"
            },
            {
                "label": "वंगरा फिरोज 0194",
                "village_id": 99,
                "village_name": "वंगरा फिरोज"
            },
            {
                "label": "वगवाडा 0276",
                "village_id": 100,
                "village_name": "वगवाडा"
            },
            {
                "label": "वथनाहा 0242",
                "village_id": 101,
                "village_name": "वथनाहा"
            },
            {
                "label": "वमनौलीया 0263",
                "village_id": 102,
                "village_name": "वमनौलीया"
            },
            {
                "label": "वर्जी 0203",
                "village_id": 103,
                "village_name": "वर्जी"
            },
            {
                "label": "वरुराज 0231",
                "village_id": 104,
                "village_name": "वरुराज"
            },
            {
                "label": "वरियापुर 0204",
                "village_id": 105,
                "village_name": "वरियापुर"
            },
            {
                "label": "ववुरवन 0154",
                "village_id": 106,
                "village_name": "ववुरवन"
            },
            {
                "label": "वासघाट 0192",
                "village_id": 107,
                "village_name": "वासघाट"
            },
            {
                "label": "विशुनपुर धर्म 0185",
                "village_id": 108,
                "village_name": "विशुनपुर धर्म"
            },
            {
                "label": "विशुनपुर होरील 0163",
                "village_id": 109,
                "village_name": "विशुनपुर होरील"
            },
            {
                "label": "विशुनपुऱधर्म 0185",
                "village_id": 110,
                "village_name": "विशुनपुऱधर्म"
            },
            {
                "label": "वोयरिया 0215",
                "village_id": 111,
                "village_name": "वोयरिया"
            },
            {
                "label": "श्री रामपुर 0274",
                "village_id": 112,
                "village_name": "श्री रामपुर"
            },
            {
                "label": "श्रीसिया जगदीश 0273",
                "village_id": 113,
                "village_name": "श्रीसिया जगदीश"
            },
            {
                "label": "सघनपुरा 0280",
                "village_id": 114,
                "village_name": "सघनपुरा"
            },
            {
                "label": "सेन्दुआरी गजसिह 0211",
                "village_id": 115,
                "village_name": "सेन्दुआरी गजसिह"
            },
            {
                "label": "सेन्दुआरी पुरुषोत्तम 0212",
                "village_id": 116,
                "village_name": "सेन्दुआरी पुरुषोत्तम"
            },
            {
                "label": "सुन्दर सराय 0223",
                "village_id": 117,
                "village_name": "सुन्दर सराय"
            },
            {
                "label": "सेमरा 0143",
                "village_id": 118,
                "village_name": "सेमरा"
            },
            {
                "label": "ससना 0195",
                "village_id": 119,
                "village_name": "ससना"
            },
            {
                "label": "सहमलवा 0186",
                "village_id": 120,
                "village_name": "सहमलवा"
            },
            {
                "label": "साढ़ा डम्बर 0220",
                "village_id": 121,
                "village_name": "साढ़ा डम्बर"
            },
            {
                "label": "सिंगैला 0224",
                "village_id": 122,
                "village_name": "सिंगैला"
            },
            {
                "label": "सिसवा 0182",
                "village_id": 123,
                "village_name": "सिसवा"
            },
            {
                "label": "सीतल सेमरा 0191",
                "village_id": 124,
                "village_name": "सीतल सेमरा"
            },
            {
                "label": "सोनवरसा 0148",
                "village_id": 125,
                "village_name": "सोनवरसा"
            },
            {
                "label": "हठीलवा 0149",
                "village_id": 126,
                "village_name": "हठीलवा"
            },
            {
                "label": "हढ़िल़वा 0149",
                "village_id": 127,
                "village_name": "हढ़िल़वा"
            },
            {
                "label": "हरदी 0278",
                "village_id": 128,
                "village_name": "हरदी"
            },
            {
                "label": "हरनाही 0167",
                "village_id": 129,
                "village_name": "हरनाही"
            },
            {
                "label": "हरपुर 0255",
                "village_id": 130,
                "village_name": "हरपुर"
            }
        ],
        "block_id": "12",
        "block_no": 11,
        "block_name": "मोतीपुर [012]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अहीयापुर 0007",
                "village_id": 2,
                "village_name": "अहीयापुर"
            },
            {
                "label": "आनन्दी छपरा 0056",
                "village_id": 3,
                "village_name": "आनन्दी छपरा"
            },
            {
                "label": "आशापट़टी 0003",
                "village_id": 4,
                "village_name": "आशापट़टी"
            },
            {
                "label": "एराजी जगदीश 0040",
                "village_id": 5,
                "village_name": "एराजी जगदीश"
            },
            {
                "label": "एराजी जगदीशपुर 0040",
                "village_id": 6,
                "village_name": "एराजी जगदीशपुर"
            },
            {
                "label": "एराजी जगदीशपुर 0040",
                "village_id": 7,
                "village_name": "एराजी जगदीशपुर"
            },
            {
                "label": "एराजी वन्दोवस्ती 0055",
                "village_id": 8,
                "village_name": "एराजी वन्दोवस्ती"
            },
            {
                "label": "करनौल चतुर्भूज 0027",
                "village_id": 9,
                "village_name": "करनौल चतुर्भूज"
            },
            {
                "label": "करनौल निलकंठ 0019",
                "village_id": 10,
                "village_name": "करनौल निलकंठ"
            },
            {
                "label": "खुर्शेदा 0010",
                "village_id": 11,
                "village_name": "खुर्शेदा"
            },
            {
                "label": "खोरी पाकड़ 0045",
                "village_id": 12,
                "village_name": "खोरी पाकड़"
            },
            {
                "label": "गुलावपट्टी 0002",
                "village_id": 13,
                "village_name": "गुलावपट्टी"
            },
            {
                "label": "गीधा 0067",
                "village_id": 14,
                "village_name": "गीधा"
            },
            {
                "label": "गोपीगंज 0017",
                "village_id": 15,
                "village_name": "गोपीगंज"
            },
            {
                "label": "चक अब्दुल बहाब 0035",
                "village_id": 16,
                "village_name": "चक अब्दुल बहाब"
            },
            {
                "label": "चक अहलाद 0109",
                "village_id": 17,
                "village_name": "चक अहलाद"
            },
            {
                "label": "चकअव्दुल वहाव 0035",
                "village_id": 18,
                "village_name": "चकअव्दुल वहाव"
            },
            {
                "label": "चकबाजी उर्फ बाजी 0036",
                "village_id": 19,
                "village_name": "चकबाजी उर्फ बाजी"
            },
            {
                "label": "चकरोटी तोड़ 0022",
                "village_id": 20,
                "village_name": "चकरोटी तोड़"
            },
            {
                "label": "जगदीशपुर 0039",
                "village_id": 21,
                "village_name": "जगदीशपुर"
            },
            {
                "label": "जगदीशपुर 0130",
                "village_id": 22,
                "village_name": "जगदीशपुर"
            },
            {
                "label": "जहुरा 0059",
                "village_id": 23,
                "village_name": "जहुरा"
            },
            {
                "label": "जीता छपरा 0136",
                "village_id": 24,
                "village_name": "जीता छपरा"
            },
            {
                "label": "तरावॉ 0060",
                "village_id": 25,
                "village_name": "तरावॉ"
            },
            {
                "label": "तेलिया छपरा 0051",
                "village_id": 26,
                "village_name": "तेलिया छपरा"
            },
            {
                "label": "तालगौड़ा 0015",
                "village_id": 27,
                "village_name": "तालगौड़ा"
            },
            {
                "label": "देव घाड़ा 0024",
                "village_id": 28,
                "village_name": "देव घाड़ा"
            },
            {
                "label": "देवसर असली 0021",
                "village_id": 29,
                "village_name": "देवसर असली"
            },
            {
                "label": "दाहा छपरा 0092",
                "village_id": 30,
                "village_name": "दाहा छपरा"
            },
            {
                "label": "दोस्‍तपुर 0069",
                "village_id": 31,
                "village_name": "दोस्‍तपुर"
            },
            {
                "label": "धर्मपुर 0131",
                "village_id": 32,
                "village_name": "धर्मपुर"
            },
            {
                "label": "धर्मपुर 0009",
                "village_id": 33,
                "village_name": "धर्मपुर"
            },
            {
                "label": "धारोपाली 0052",
                "village_id": 34,
                "village_name": "धारोपाली"
            },
            {
                "label": "नबादा 0116",
                "village_id": 35,
                "village_name": "नबादा"
            },
            {
                "label": "नेमोपुर 0920",
                "village_id": 36,
                "village_name": "नेमोपुर"
            },
            {
                "label": "नया टोला दोस्‍तपुर 0050",
                "village_id": 37,
                "village_name": "नया टोला दोस्‍तपुर"
            },
            {
                "label": "नया टोला मधुवनी 0048",
                "village_id": 38,
                "village_name": "नया टोला मधुवनी"
            },
            {
                "label": "नवादा 0072",
                "village_id": 39,
                "village_name": "नवादा"
            },
            {
                "label": "नवादा 0006",
                "village_id": 40,
                "village_name": "नवादा"
            },
            {
                "label": "नवानगर एराजी 0041",
                "village_id": 41,
                "village_name": "नवानगर एराजी"
            },
            {
                "label": "नवानगर निजामत 0028",
                "village_id": 42,
                "village_name": "नवानगर निजामत"
            },
            {
                "label": "नावानगर मिल्की 0042",
                "village_id": 43,
                "village_name": "नावानगर मिल्की"
            },
            {
                "label": "पकड़ी बसारथ 0132",
                "village_id": 44,
                "village_name": "पकड़ी बसारथ"
            },
            {
                "label": "पकरी असली 0103",
                "village_id": 45,
                "village_name": "पकरी असली"
            },
            {
                "label": "प्रतापपट़टी 0004",
                "village_id": 46,
                "village_name": "प्रतापपट़टी"
            },
            {
                "label": "परसा सदन 0089",
                "village_id": 47,
                "village_name": "परसा सदन"
            },
            {
                "label": "प्रसोनी जहांगीर 0011",
                "village_id": 48,
                "village_name": "प्रसोनी जहांगीर"
            },
            {
                "label": "परसौनी रईसी 0063",
                "village_id": 49,
                "village_name": "परसौनी रईसी"
            },
            {
                "label": "पहाड़पुर बरार 0110",
                "village_id": 50,
                "village_name": "पहाड़पुर बरार"
            },
            {
                "label": "पहाड़पुर मनोरथ 0112",
                "village_id": 51,
                "village_name": "पहाड़पुर मनोरथ"
            },
            {
                "label": "पीपरा एराजी 0174",
                "village_id": 52,
                "village_name": "पीपरा एराजी"
            },
            {
                "label": "पीपरा राधो 0175",
                "village_id": 53,
                "village_name": "पीपरा राधो"
            },
            {
                "label": "फूलबरिया 0037",
                "village_id": 54,
                "village_name": "फूलबरिया"
            },
            {
                "label": "फुलसकरा 0137",
                "village_id": 55,
                "village_name": "फुलसकरा"
            },
            {
                "label": "बंगरा बरार 0108",
                "village_id": 56,
                "village_name": "बंगरा बरार"
            },
            {
                "label": "बैधनाथपुर खाप 0012",
                "village_id": 57,
                "village_name": "बैधनाथपुर खाप"
            },
            {
                "label": "बेलकांटी धरहरा 0094",
                "village_id": 58,
                "village_name": "बेलकांटी धरहरा"
            },
            {
                "label": "बल्थी नरसिंग 0075",
                "village_id": 59,
                "village_name": "बल्थी नरसिंग"
            },
            {
                "label": "बसंतपुर चैनपुर 0061",
                "village_id": 60,
                "village_name": "बसंतपुर चैनपुर"
            },
            {
                "label": "बाड़ाडीह 0095",
                "village_id": 61,
                "village_name": "बाड़ाडीह"
            },
            {
                "label": "बासदेवपुर सराया 0018",
                "village_id": 62,
                "village_name": "बासदेवपुर सराया"
            },
            {
                "label": "भगवानपुर 0093",
                "village_id": 63,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भगवानपुर काशी 0053",
                "village_id": 64,
                "village_name": "भगवानपुर काशी"
            },
            {
                "label": "भलुही खान 0031",
                "village_id": 65,
                "village_name": "भलुही खान"
            },
            {
                "label": "भलुही रसुल 0030",
                "village_id": 66,
                "village_name": "भलुही रसुल"
            },
            {
                "label": "भलुही सिकन्दर 0034",
                "village_id": 67,
                "village_name": "भलुही सिकन्दर"
            },
            {
                "label": "भीमपुरा 0135",
                "village_id": 68,
                "village_name": "भीमपुरा"
            },
            {
                "label": "मठ़वाल गोविन्दपुर मठिया 0081",
                "village_id": 69,
                "village_name": "मठ़वाल गोविन्दपुर मठिया"
            },
            {
                "label": "मथुरापुर 0128",
                "village_id": 70,
                "village_name": "मथुरापुर"
            },
            {
                "label": "मधुवन सुरज 0077",
                "village_id": 71,
                "village_name": "मधुवन सुरज"
            },
            {
                "label": "मनाईन 0102",
                "village_id": 72,
                "village_name": "मनाईन"
            },
            {
                "label": "महदैया तालुका 0086",
                "village_id": 73,
                "village_name": "महदैया तालुका"
            },
            {
                "label": "महम्‍मदपुर मकसुदन 0065",
                "village_id": 74,
                "village_name": "महम्‍मदपुर मकसुदन"
            },
            {
                "label": "माधोपुर 0020",
                "village_id": 75,
                "village_name": "माधोपुर"
            },
            {
                "label": "माधोपुर 0071",
                "village_id": 76,
                "village_name": "माधोपुर"
            },
            {
                "label": "माधोपुर हजारी 0016",
                "village_id": 77,
                "village_name": "माधोपुर हजारी"
            },
            {
                "label": "मोरहर 0114",
                "village_id": 78,
                "village_name": "मोरहर"
            },
            {
                "label": "रजवारा 0025",
                "village_id": 79,
                "village_name": "रजवारा"
            },
            {
                "label": "रुप छपड़ा 0111",
                "village_id": 80,
                "village_name": "रुप छपड़ा"
            },
            {
                "label": "रूप छपरा उर्फ संग्रामपुर 0113",
                "village_id": 81,
                "village_name": "रूप छपरा उर्फ संग्रामपुर"
            },
            {
                "label": "रसुलपुर 0014",
                "village_id": 82,
                "village_name": "रसुलपुर"
            },
            {
                "label": "राजेपुर 0070",
                "village_id": 83,
                "village_name": "राजेपुर"
            },
            {
                "label": "रामपुर असली 0044",
                "village_id": 84,
                "village_name": "रामपुर असली"
            },
            {
                "label": "रामपुर खुर्द 0043",
                "village_id": 85,
                "village_name": "रामपुर खुर्द"
            },
            {
                "label": "रामपुर भिखनपुरा 0054",
                "village_id": 86,
                "village_name": "रामपुर भिखनपुरा"
            },
            {
                "label": "लखना 0066",
                "village_id": 87,
                "village_name": "लखना"
            },
            {
                "label": "लोदियां 0026",
                "village_id": 88,
                "village_name": "लोदियां"
            },
            {
                "label": "वैरिया 0032",
                "village_id": 89,
                "village_name": "वैरिया"
            },
            {
                "label": "वल्थी नरहर 0078",
                "village_id": 90,
                "village_name": "वल्थी नरहर"
            },
            {
                "label": "वल्थी रईसी 0074",
                "village_id": 91,
                "village_name": "वल्थी रईसी"
            },
            {
                "label": "वाजीदपुर 0126",
                "village_id": 92,
                "village_name": "वाजीदपुर"
            },
            {
                "label": "विशुनपट़टी 0100",
                "village_id": 93,
                "village_name": "विशुनपट़टी"
            },
            {
                "label": "विशुनपुर कल्यान 0058",
                "village_id": 94,
                "village_name": "विशुनपुर कल्यान"
            },
            {
                "label": "विशुनपुर चक 0578",
                "village_id": 95,
                "village_name": "विशुनपुर चक"
            },
            {
                "label": "शाहपुर पट्टी 0129",
                "village_id": 96,
                "village_name": "शाहपुर पट्टी"
            },
            {
                "label": "शिवनगर 0104",
                "village_id": 97,
                "village_name": "शिवनगर"
            },
            {
                "label": "संग्रामपुर 0082",
                "village_id": 98,
                "village_name": "संग्रामपुर"
            },
            {
                "label": "सेमरा नन्हकार 0101",
                "village_id": 99,
                "village_name": "सेमरा नन्हकार"
            },
            {
                "label": "सेमरा निजामत 0098",
                "village_id": 100,
                "village_name": "सेमरा निजामत"
            },
            {
                "label": "सरैया 0084",
                "village_id": 101,
                "village_name": "सरैया"
            },
            {
                "label": "सलेमपुर 0005",
                "village_id": 102,
                "village_name": "सलेमपुर"
            },
            {
                "label": "सितुआही 0049",
                "village_id": 103,
                "village_name": "सितुआही"
            },
            {
                "label": "सिमरा नन्कार 0099",
                "village_id": 104,
                "village_name": "सिमरा नन्कार"
            },
            {
                "label": "सिमरी 0068",
                "village_id": 105,
                "village_name": "सिमरी"
            },
            {
                "label": "सोमगढ़ 0057",
                "village_id": 106,
                "village_name": "सोमगढ़"
            },
            {
                "label": "हुसैनपुर नया टोला 0117",
                "village_id": 107,
                "village_name": "हुसैनपुर नया टोला"
            },
            {
                "label": "हस्‍सेपुर रत्‍ती 0118",
                "village_id": 108,
                "village_name": "हस्‍सेपुर रत्‍ती"
            },
            {
                "label": "हीमत पट्टी 0001",
                "village_id": 109,
                "village_name": "हीमत पट्टी"
            }
        ],
        "block_id": "13",
        "block_no": 12,
        "block_name": "साहेबगंज [013]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अख्तियारपुर मोहन 0182",
                "village_id": 2,
                "village_name": "अख्तियारपुर मोहन"
            },
            {
                "label": "अनंत कमतौल 0178",
                "village_id": 3,
                "village_name": "अनंत कमतौल"
            },
            {
                "label": "अमरख 0271",
                "village_id": 4,
                "village_name": "अमरख"
            },
            {
                "label": "आगानगर 0269",
                "village_id": 5,
                "village_name": "आगानगर"
            },
            {
                "label": "आरिजपुर 0187",
                "village_id": 6,
                "village_name": "आरिजपुर"
            },
            {
                "label": "आशापुर भवानी 0446",
                "village_id": 7,
                "village_name": "आशापुर भवानी"
            },
            {
                "label": "इनायतपुर 0164",
                "village_id": 8,
                "village_name": "इनायतपुर"
            },
            {
                "label": "ककड़ा 0256",
                "village_id": 9,
                "village_name": "ककड़ा"
            },
            {
                "label": "कुढनी 0204",
                "village_id": 10,
                "village_name": "कुढनी"
            },
            {
                "label": "कमतौल गाजी 0183",
                "village_id": 11,
                "village_name": "कमतौल गाजी"
            },
            {
                "label": "कर्मचन्द बलरा 0213",
                "village_id": 12,
                "village_name": "कर्मचन्द बलरा"
            },
            {
                "label": "केरमाडीह 0247",
                "village_id": 13,
                "village_name": "केरमाडीह"
            },
            {
                "label": "केरमारघुराम 0246",
                "village_id": 14,
                "village_name": "केरमारघुराम"
            },
            {
                "label": "कुविया 0170",
                "village_id": 15,
                "village_name": "कुविया"
            },
            {
                "label": "केशरामाडीह 0197",
                "village_id": 16,
                "village_name": "केशरामाडीह"
            },
            {
                "label": "केशोपुर 0216",
                "village_id": 17,
                "village_name": "केशोपुर"
            },
            {
                "label": "काशीमपुर 0464",
                "village_id": 18,
                "village_name": "काशीमपुर"
            },
            {
                "label": "किनारु 0232",
                "village_id": 19,
                "village_name": "किनारु"
            },
            {
                "label": "किशुन वलौर 0181",
                "village_id": 20,
                "village_name": "किशुन वलौर"
            },
            {
                "label": "किशनपुर मधुवन 0241",
                "village_id": 21,
                "village_name": "किशनपुर मधुवन"
            },
            {
                "label": "किशनपुर मोहनी 0167",
                "village_id": 22,
                "village_name": "किशनपुर मोहनी"
            },
            {
                "label": "खखरा 0337",
                "village_id": 23,
                "village_name": "खखरा"
            },
            {
                "label": "खरौना 0326",
                "village_id": 24,
                "village_name": "खरौना"
            },
            {
                "label": "गजपति 0435",
                "village_id": 25,
                "village_name": "गजपति"
            },
            {
                "label": "गोरैया 0158",
                "village_id": 26,
                "village_name": "गोरैया"
            },
            {
                "label": "गोरिहारी 0267",
                "village_id": 27,
                "village_name": "गोरिहारी"
            },
            {
                "label": "गौसरा 0261",
                "village_id": 28,
                "village_name": "गौसरा"
            },
            {
                "label": "गौसी खान 0207",
                "village_id": 29,
                "village_name": "गौसी खान"
            },
            {
                "label": "गौसीभागीरथ 0206",
                "village_id": 30,
                "village_name": "गौसीभागीरथ"
            },
            {
                "label": "च.ढूआ 0265",
                "village_id": 31,
                "village_name": "च.ढूआ"
            },
            {
                "label": "चकडोरा 0198",
                "village_id": 32,
                "village_name": "चकडोरा"
            },
            {
                "label": "चकबीर उर्फ मादापुर गौस 0193",
                "village_id": 33,
                "village_name": "चकबीर उर्फ मादापुर गौस"
            },
            {
                "label": "चकमेंहसी 0293",
                "village_id": 34,
                "village_name": "चकमेंहसी"
            },
            {
                "label": "चकमाखन 0221",
                "village_id": 35,
                "village_name": "चकमाखन"
            },
            {
                "label": "चकमाठी 0191",
                "village_id": 36,
                "village_name": "चकमाठी"
            },
            {
                "label": "चकमीखी 0296",
                "village_id": 37,
                "village_name": "चकमीखी"
            },
            {
                "label": "चकिया 0328",
                "village_id": 38,
                "village_name": "चकिया"
            },
            {
                "label": "चकिया 0300",
                "village_id": 39,
                "village_name": "चकिया"
            },
            {
                "label": "चन्द्रहट्टी 0166",
                "village_id": 40,
                "village_name": "चन्द्रहट्टी"
            },
            {
                "label": "चन्द्रहीया 0264",
                "village_id": 41,
                "village_name": "चन्द्रहीया"
            },
            {
                "label": "चैनपुर 0272",
                "village_id": 42,
                "village_name": "चैनपुर"
            },
            {
                "label": "छपकी 0226",
                "village_id": 43,
                "village_name": "छपकी"
            },
            {
                "label": "छपकी 0226",
                "village_id": 44,
                "village_name": "छपकी"
            },
            {
                "label": "छाजन 0161",
                "village_id": 45,
                "village_name": "छाजन"
            },
            {
                "label": "छितरौली 0449",
                "village_id": 46,
                "village_name": "छितरौली"
            },
            {
                "label": "जगदीश कमतौल 0184",
                "village_id": 47,
                "village_name": "जगदीश कमतौल"
            },
            {
                "label": "जगदीशपुर 0255",
                "village_id": 48,
                "village_name": "जगदीशपुर"
            },
            {
                "label": "जगरनाथ पुर 0212",
                "village_id": 49,
                "village_name": "जगरनाथ पुर"
            },
            {
                "label": "जमहरुआ 0258",
                "village_id": 50,
                "village_name": "जमहरुआ"
            },
            {
                "label": "जमीन कमतौल 0173",
                "village_id": 51,
                "village_name": "जमीन कमतौल"
            },
            {
                "label": "झिकटी 0253",
                "village_id": 52,
                "village_name": "झिकटी"
            },
            {
                "label": "ढ़ोढ़ी पशुराम 0196",
                "village_id": 53,
                "village_name": "ढ़ोढ़ी पशुराम"
            },
            {
                "label": "ढ़ोढ़ी रतन 0199",
                "village_id": 54,
                "village_name": "ढ़ोढ़ी रतन"
            },
            {
                "label": "ढ़ोढ़ी लाला 0194",
                "village_id": 55,
                "village_name": "ढ़ोढ़ी लाला"
            },
            {
                "label": "तुरकी 0163",
                "village_id": 56,
                "village_name": "तुरकी"
            },
            {
                "label": "तलगढ़वा 0239",
                "village_id": 57,
                "village_name": "तलगढ़वा"
            },
            {
                "label": "तेलिया 0162",
                "village_id": 58,
                "village_name": "तेलिया"
            },
            {
                "label": "तारसन 0308",
                "village_id": 59,
                "village_name": "तारसन"
            },
            {
                "label": "ताल रक्सा 0189",
                "village_id": 60,
                "village_name": "ताल रक्सा"
            },
            {
                "label": "थतिया 0168",
                "village_id": 61,
                "village_name": "थतिया"
            },
            {
                "label": "थुहवाँ 0165",
                "village_id": 62,
                "village_name": "थुहवाँ"
            },
            {
                "label": "दूबियाही 0160",
                "village_id": 63,
                "village_name": "दूबियाही"
            },
            {
                "label": "दरियापुर कफैन 0299",
                "village_id": 64,
                "village_name": "दरियापुर कफैन"
            },
            {
                "label": "देवगन 0201",
                "village_id": 65,
                "village_name": "देवगन"
            },
            {
                "label": "दामोदरपुर 0211",
                "village_id": 66,
                "village_name": "दामोदरपुर"
            },
            {
                "label": "दामोऱपुर डुमरी 0209",
                "village_id": 67,
                "village_name": "दामोऱपुर डुमरी"
            },
            {
                "label": "दाहाचक 0294",
                "village_id": 68,
                "village_name": "दाहाचक"
            },
            {
                "label": "धरमुहाँ 0266",
                "village_id": 69,
                "village_name": "धरमुहाँ"
            },
            {
                "label": "नीर पुर 0250",
                "village_id": 70,
                "village_name": "नीर पुर"
            },
            {
                "label": "प.डेया 0237",
                "village_id": 71,
                "village_name": "प.डेया"
            },
            {
                "label": "पकाही 0816",
                "village_id": 72,
                "village_name": "पकाही"
            },
            {
                "label": "पदमौल 210/",
                "village_id": 73,
                "village_name": "पदमौल"
            },
            {
                "label": "पदमौल 210/",
                "village_id": 74,
                "village_name": "पदमौल"
            },
            {
                "label": "पुपरी 0301",
                "village_id": 75,
                "village_name": "पुपरी"
            },
            {
                "label": "परुषोत्तमपुर 0268",
                "village_id": 76,
                "village_name": "परुषोत्तमपुर"
            },
            {
                "label": "पहारपुर 0252",
                "village_id": 77,
                "village_name": "पहारपुर"
            },
            {
                "label": "पोखरैरा 0244",
                "village_id": 78,
                "village_name": "पोखरैरा"
            },
            {
                "label": "फकुली 0192",
                "village_id": 79,
                "village_name": "फकुली"
            },
            {
                "label": "फतहपुर अतिबल 0185",
                "village_id": 80,
                "village_name": "फतहपुर अतिबल"
            },
            {
                "label": "फतहपुर कस्तूरी 0186",
                "village_id": 81,
                "village_name": "फतहपुर कस्तूरी"
            },
            {
                "label": "फतहपुर भगवान 0217",
                "village_id": 82,
                "village_name": "फतहपुर भगवान"
            },
            {
                "label": "फुलवरिया 0245",
                "village_id": 83,
                "village_name": "फुलवरिया"
            },
            {
                "label": "बंगरा वाजीद 0291",
                "village_id": 84,
                "village_name": "बंगरा वाजीद"
            },
            {
                "label": "बछुवन 0242",
                "village_id": 85,
                "village_name": "बछुवन"
            },
            {
                "label": "बड़ाकपूर 0260",
                "village_id": 86,
                "village_name": "बड़ाकपूर"
            },
            {
                "label": "बथना पूर्वी 0235",
                "village_id": 87,
                "village_name": "बथना पूर्वी"
            },
            {
                "label": "बथना पशिचारी 0236",
                "village_id": 88,
                "village_name": "बथना पशिचारी"
            },
            {
                "label": "बलिया 0202",
                "village_id": 89,
                "village_name": "बलिया"
            },
            {
                "label": "भगवानपुर 0208",
                "village_id": 90,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भवानी पुर 0309",
                "village_id": 91,
                "village_name": "भवानी पुर"
            },
            {
                "label": "भवानीपुर 0229",
                "village_id": 92,
                "village_name": "भवानीपुर"
            },
            {
                "label": "भीखनपुर 0290",
                "village_id": 93,
                "village_name": "भीखनपुर"
            },
            {
                "label": "भीखनपुर सैफ 0290",
                "village_id": 94,
                "village_name": "भीखनपुर सैफ"
            },
            {
                "label": "भोथहाँ 0313",
                "village_id": 95,
                "village_name": "भोथहाँ"
            },
            {
                "label": "मकसुदपुर 0251",
                "village_id": 96,
                "village_name": "मकसुदपुर"
            },
            {
                "label": "मुजफफरा कमतौल 0180",
                "village_id": 97,
                "village_name": "मुजफफरा कमतौल"
            },
            {
                "label": "मझन पटटी 0171",
                "village_id": 98,
                "village_name": "मझन पटटी"
            },
            {
                "label": "मुड़ौल  263",
                "village_id": 99,
                "village_name": "मुड़ौल"
            },
            {
                "label": "मेथुरापुर 0214",
                "village_id": 100,
                "village_name": "मेथुरापुर"
            },
            {
                "label": "मैदापुर 0336",
                "village_id": 101,
                "village_name": "मैदापुर"
            },
            {
                "label": "मधौल 0254",
                "village_id": 102,
                "village_name": "मधौल"
            },
            {
                "label": "मनकौली 0200",
                "village_id": 103,
                "village_name": "मनकौली"
            },
            {
                "label": "मुरादपुर 0448",
                "village_id": 104,
                "village_name": "मुरादपुर"
            },
            {
                "label": "मुरौल 0257",
                "village_id": 105,
                "village_name": "मुरौल"
            },
            {
                "label": "महंथ मनियारी छींट 0253",
                "village_id": 106,
                "village_name": "महंथ मनियारी छींट"
            },
            {
                "label": "महमदपुर आलम 0812",
                "village_id": 107,
                "village_name": "महमदपुर आलम"
            },
            {
                "label": "महेश मनीयारी 0253",
                "village_id": 108,
                "village_name": "महेश मनीयारी"
            },
            {
                "label": "माधोपुर 0287",
                "village_id": 109,
                "village_name": "माधोपुर"
            },
            {
                "label": "माधोपुर कपुर 0203",
                "village_id": 110,
                "village_name": "माधोपुर कपुर"
            },
            {
                "label": "माधोपुर चिकनी 0218",
                "village_id": 111,
                "village_name": "माधोपुर चिकनी"
            },
            {
                "label": "मिश्र मनियारी 0445",
                "village_id": 112,
                "village_name": "मिश्र मनियारी"
            },
            {
                "label": "मोरनिस्फ 0248",
                "village_id": 113,
                "village_name": "मोरनिस्फ"
            },
            {
                "label": "मोहनपुर 0174",
                "village_id": 114,
                "village_name": "मोहनपुर"
            },
            {
                "label": "मोहमम्दपुर मुवारक 0295",
                "village_id": 115,
                "village_name": "मोहमम्दपुर मुवारक"
            },
            {
                "label": "रघुनाथपुर मधुवन 0439",
                "village_id": 116,
                "village_name": "रघुनाथपुर मधुवन"
            },
            {
                "label": "रघुनाथपुर वंशत 0222",
                "village_id": 117,
                "village_name": "रघुनाथपुर वंशत"
            },
            {
                "label": "रजला 0195",
                "village_id": 118,
                "village_name": "रजला"
            },
            {
                "label": "रतनौली 0249",
                "village_id": 119,
                "village_name": "रतनौली"
            },
            {
                "label": "रामपुर काशी 0447",
                "village_id": 120,
                "village_name": "रामपुर काशी"
            },
            {
                "label": "रामपुर बलरा 0215",
                "village_id": 121,
                "village_name": "रामपुर बलरा"
            },
            {
                "label": "लुक्की नन्दलालपुर 0306",
                "village_id": 122,
                "village_name": "लुक्की नन्दलालपुर"
            },
            {
                "label": "लदवरीया 0220",
                "village_id": 123,
                "village_name": "लदवरीया"
            },
            {
                "label": "लदौरा 0220",
                "village_id": 124,
                "village_name": "लदौरा"
            },
            {
                "label": "वंगरा हरदास 0000",
                "village_id": 125,
                "village_name": "वंगरा हरदास"
            },
            {
                "label": "वंगरावंशीधर 0205",
                "village_id": 126,
                "village_name": "वंगरावंशीधर"
            },
            {
                "label": "वलरा इसमाईल 0224",
                "village_id": 127,
                "village_name": "वलरा इसमाईल"
            },
            {
                "label": "वलरा किशुन 0234",
                "village_id": 128,
                "village_name": "वलरा किशुन"
            },
            {
                "label": "वेलहीया 0270",
                "village_id": 129,
                "village_name": "वेलहीया"
            },
            {
                "label": "वलौरडीह 0172",
                "village_id": 130,
                "village_name": "वलौरडीह"
            },
            {
                "label": "वसौली 0259",
                "village_id": 131,
                "village_name": "वसौली"
            },
            {
                "label": "वाकर पुर 0303",
                "village_id": 132,
                "village_name": "वाकर पुर"
            },
            {
                "label": "वाघी गोपीनाथ 0819",
                "village_id": 133,
                "village_name": "वाघी गोपीनाथ"
            },
            {
                "label": "वाजितपुर केदरिया 0359",
                "village_id": 134,
                "village_name": "वाजितपुर केदरिया"
            },
            {
                "label": "वाजीदपुर 0176",
                "village_id": 135,
                "village_name": "वाजीदपुर"
            },
            {
                "label": "वाँधी मनियार 0818",
                "village_id": 136,
                "village_name": "वाँधी मनियार"
            },
            {
                "label": "वाँधी हरनारायण 0817",
                "village_id": 137,
                "village_name": "वाँधी हरनारायण"
            },
            {
                "label": "विशनपुर गिद्धा 0298",
                "village_id": 138,
                "village_name": "विशनपुर गिद्धा"
            },
            {
                "label": "विशुनपुर जयनारायण 0442",
                "village_id": 139,
                "village_name": "विशुनपुर जयनारायण"
            },
            {
                "label": "विशनपुर धनराज 0238",
                "village_id": 140,
                "village_name": "विशनपुर धनराज"
            },
            {
                "label": "विशनपुर मंगल 0188",
                "village_id": 141,
                "village_name": "विशनपुर मंगल"
            },
            {
                "label": "विशनपुर माधो 0820",
                "village_id": 142,
                "village_name": "विशनपुर माधो"
            },
            {
                "label": "श्रीपुर 0159",
                "village_id": 143,
                "village_name": "श्रीपुर"
            },
            {
                "label": "शाहपुर दखसी 0169",
                "village_id": 144,
                "village_name": "शाहपुर दखसी"
            },
            {
                "label": "शाहपुर मर्चा 0815",
                "village_id": 145,
                "village_name": "शाहपुर मर्चा"
            },
            {
                "label": "शिवन पट्टी 0297",
                "village_id": 146,
                "village_name": "शिवन पट्टी"
            },
            {
                "label": "सकरी सरैया 0302",
                "village_id": 147,
                "village_name": "सकरी सरैया"
            },
            {
                "label": "सुदवारा 0177",
                "village_id": 148,
                "village_name": "सुदवारा"
            },
            {
                "label": "सुबधिया विशुनपुर राम 0227",
                "village_id": 149,
                "village_name": "सुबधिया विशुनपुर राम"
            },
            {
                "label": "सुमेरा 0306",
                "village_id": 150,
                "village_name": "सुमेरा"
            },
            {
                "label": "सुमेरा अफजलपुर 0329",
                "village_id": 151,
                "village_name": "सुमेरा अफजलपुर"
            },
            {
                "label": "सुमेरा नुर नगर 0305",
                "village_id": 152,
                "village_name": "सुमेरा नुर नगर"
            },
            {
                "label": "सुमेरा मुस्तुफा 0307",
                "village_id": 153,
                "village_name": "सुमेरा मुस्तुफा"
            },
            {
                "label": "सेरपुर रामनाथ 0219",
                "village_id": 154,
                "village_name": "सेरपुर रामनाथ"
            },
            {
                "label": "सुवधिया नथन 0225",
                "village_id": 155,
                "village_name": "सुवधिया नथन"
            },
            {
                "label": "सिलोठ बासुदेव 0434",
                "village_id": 156,
                "village_name": "सिलोठ बासुदेव"
            },
            {
                "label": "सिलौत बैजनाथ 0465",
                "village_id": 157,
                "village_name": "सिलौत बैजनाथ"
            },
            {
                "label": "सिलौत मीमल 0441",
                "village_id": 158,
                "village_name": "सिलौत मीमल"
            },
            {
                "label": "सिलौत मीमल 0441",
                "village_id": 159,
                "village_name": "सिलौत मीमल"
            },
            {
                "label": "सिलौत हरिवंश 0436",
                "village_id": 160,
                "village_name": "सिलौत हरिवंश"
            },
            {
                "label": "सोनबरसा इमाद 0231",
                "village_id": 161,
                "village_name": "सोनबरसा इमाद"
            },
            {
                "label": "सोनवरसा डीह 0230",
                "village_id": 162,
                "village_name": "सोनवरसा डीह"
            },
            {
                "label": "सोनवरसा साह 0811",
                "village_id": 163,
                "village_name": "सोनवरसा साह"
            },
            {
                "label": "हबीब पट्टी 0228",
                "village_id": 164,
                "village_name": "हबीब पट्टी"
            },
            {
                "label": "हरनारायणपुर 0179",
                "village_id": 165,
                "village_name": "हरनारायणपुर"
            },
            {
                "label": "हरनारायणपुर 0179",
                "village_id": 166,
                "village_name": "हरनारायणपुर"
            },
            {
                "label": "हरपुर वलरा 0223",
                "village_id": 167,
                "village_name": "हरपुर वलरा"
            },
            {
                "label": "हरिशंकर मनियारी छीट 0440",
                "village_id": 168,
                "village_name": "हरिशंकर मनियारी छीट"
            }
        ],
        "block_id": "14",
        "block_no": 13,
        "block_name": "कुढ्नी [014]",
        "sub_div_code": "2"
    },
    {
        "village": [
            {
                "label": "अजीजपुर 0569",
                "village_id": 2,
                "village_name": "अजीजपुर"
            },
            {
                "label": "अतरौलिया 0519",
                "village_id": 3,
                "village_name": "अतरौलिया"
            },
            {
                "label": "अमुचक 0472",
                "village_id": 4,
                "village_name": "अमुचक"
            },
            {
                "label": "अमैठा 0409",
                "village_id": 5,
                "village_name": "अमैठा"
            },
            {
                "label": "अम्‍बारा चौबे 0461",
                "village_id": 6,
                "village_name": "अम्‍बारा चौबे"
            },
            {
                "label": "अम्बारा तेजसिह 0463",
                "village_id": 7,
                "village_name": "अम्बारा तेजसिह"
            },
            {
                "label": "अमीर छपरा 0479",
                "village_id": 8,
                "village_name": "अमीर छपरा"
            },
            {
                "label": "अयोध्यापुर 0344",
                "village_id": 9,
                "village_name": "अयोध्यापुर"
            },
            {
                "label": "आनंदपुर गंगौलिया 0572",
                "village_id": 10,
                "village_name": "आनंदपुर गंगौलिया"
            },
            {
                "label": "आनन्दपुर 0509",
                "village_id": 11,
                "village_name": "आनन्दपुर"
            },
            {
                "label": "आनन्दपुर गंगोलिया 0572",
                "village_id": 12,
                "village_name": "आनन्दपुर गंगोलिया"
            },
            {
                "label": "आनन्दपुर गंगौलिया 0572",
                "village_id": 13,
                "village_name": "आनन्दपुर गंगौलिया"
            },
            {
                "label": "आरापुर 0535",
                "village_id": 14,
                "village_name": "आरापुर"
            },
            {
                "label": "इब्राहीमपुर 0511",
                "village_id": 15,
                "village_name": "इब्राहीमपुर"
            },
            {
                "label": "उदयपुर 0501",
                "village_id": 16,
                "village_name": "उदयपुर"
            },
            {
                "label": "उफरौल 0475",
                "village_id": 17,
                "village_name": "उफरौल"
            },
            {
                "label": "एराजी रुपौली 0545",
                "village_id": 18,
                "village_name": "एराजी रुपौली"
            },
            {
                "label": "कुइया 0507",
                "village_id": 19,
                "village_name": "कुइया"
            },
            {
                "label": "कुकरिया 0452",
                "village_id": 20,
                "village_name": "कुकरिया"
            },
            {
                "label": "कुकुरिया 0447",
                "village_id": 21,
                "village_name": "कुकुरिया"
            },
            {
                "label": "करिहारा 0505",
                "village_id": 22,
                "village_name": "करिहारा"
            },
            {
                "label": "कुलदीप छपरा 0451",
                "village_id": 23,
                "village_name": "कुलदीप छपरा"
            },
            {
                "label": "कैला पट़टी 0454",
                "village_id": 24,
                "village_name": "कैला पट़टी"
            },
            {
                "label": "कुसरुप कालदुआ 0473",
                "village_id": 25,
                "village_name": "कुसरुप कालदुआ"
            },
            {
                "label": "कोलवारा 0406",
                "village_id": 26,
                "village_name": "कोलवारा"
            },
            {
                "label": "कोल्हुआ 0477",
                "village_id": 27,
                "village_name": "कोल्हुआ"
            },
            {
                "label": "खैरा 0399",
                "village_id": 28,
                "village_name": "खैरा"
            },
            {
                "label": "गहिलो 0398",
                "village_id": 29,
                "village_name": "गहिलो"
            },
            {
                "label": "गिद्धा 0554",
                "village_id": 30,
                "village_name": "गिद्धा"
            },
            {
                "label": "गीजास 0396",
                "village_id": 31,
                "village_name": "गीजास"
            },
            {
                "label": "गोपाल नेउरा 0559",
                "village_id": 32,
                "village_name": "गोपाल नेउरा"
            },
            {
                "label": "गोपी दोकडा 0521",
                "village_id": 33,
                "village_name": "गोपी दोकडा"
            },
            {
                "label": "गोपी धनपत 0543",
                "village_id": 34,
                "village_name": "गोपी धनपत"
            },
            {
                "label": "गोरीगाँवा 0342",
                "village_id": 35,
                "village_name": "गोरीगाँवा"
            },
            {
                "label": "गोरीगावा डीह 0570",
                "village_id": 36,
                "village_name": "गोरीगावा डीह"
            },
            {
                "label": "गोविन्दपुर 0481",
                "village_id": 37,
                "village_name": "गोविन्दपुर"
            },
            {
                "label": "गोविन्दपुर 0411",
                "village_id": 38,
                "village_name": "गोविन्दपुर"
            },
            {
                "label": "गोसाई छपरा 0482",
                "village_id": 39,
                "village_name": "गोसाई छपरा"
            },
            {
                "label": "चकना 0517",
                "village_id": 40,
                "village_name": "चकना"
            },
            {
                "label": "चकमछुआ 0464",
                "village_id": 41,
                "village_name": "चकमछुआ"
            },
            {
                "label": "चकमाल 0550",
                "village_id": 42,
                "village_name": "चकमाल"
            },
            {
                "label": "चकयोगलानी 0449",
                "village_id": 43,
                "village_name": "चकयोगलानी"
            },
            {
                "label": "चकरहथ 0460",
                "village_id": 44,
                "village_name": "चकरहथ"
            },
            {
                "label": "चकवाजो 0438",
                "village_id": 45,
                "village_name": "चकवाजो"
            },
            {
                "label": "चकवाजो 0439",
                "village_id": 46,
                "village_name": "चकवाजो"
            },
            {
                "label": "चकवाजो 0437",
                "village_id": 47,
                "village_name": "चकवाजो"
            },
            {
                "label": "चकिया 0548",
                "village_id": 48,
                "village_name": "चकिया"
            },
            {
                "label": "चकिया 0474",
                "village_id": 49,
                "village_name": "चकिया"
            },
            {
                "label": "चकिया 0474",
                "village_id": 50,
                "village_name": "चकिया"
            },
            {
                "label": "चैन पकडी 0539",
                "village_id": 51,
                "village_name": "चैन पकडी"
            },
            {
                "label": "चाको छपडा 0551",
                "village_id": 52,
                "village_name": "चाको छपडा"
            },
            {
                "label": "चोचहां 0563",
                "village_id": 53,
                "village_name": "चोचहां"
            },
            {
                "label": "छितरी 0557",
                "village_id": 54,
                "village_name": "छितरी"
            },
            {
                "label": "जगत दोकरा 0520",
                "village_id": 55,
                "village_name": "जगत दोकरा"
            },
            {
                "label": "जुझारपुर 0453",
                "village_id": 56,
                "village_name": "जुझारपुर"
            },
            {
                "label": "जैतपुर 0414",
                "village_id": 57,
                "village_name": "जैतपुर"
            },
            {
                "label": "जलालपुर 0549",
                "village_id": 58,
                "village_name": "जलालपुर"
            },
            {
                "label": "जलालपुर 0561",
                "village_id": 59,
                "village_name": "जलालपुर"
            },
            {
                "label": "तिल बिहटा 0250",
                "village_id": 60,
                "village_name": "तिल बिहटा"
            },
            {
                "label": "तिलक पकडी 0351",
                "village_id": 61,
                "village_name": "तिलक पकडी"
            },
            {
                "label": "दातापुर 0345",
                "village_id": 62,
                "village_name": "दातापुर"
            },
            {
                "label": "दातापुर 0345",
                "village_id": 63,
                "village_name": "दातापुर"
            },
            {
                "label": "दामोदर छपरा 0443",
                "village_id": 64,
                "village_name": "दामोदर छपरा"
            },
            {
                "label": "धनुपरा 0405",
                "village_id": 65,
                "village_name": "धनुपरा"
            },
            {
                "label": "धनराजपुर 0486",
                "village_id": 66,
                "village_name": "धनराजपुर"
            },
            {
                "label": "धोधराहा 0518",
                "village_id": 67,
                "village_name": "धोधराहा"
            },
            {
                "label": "नरगी जगदीश 0408",
                "village_id": 68,
                "village_name": "नरगी जगदीश"
            },
            {
                "label": "नरगी जीवनाथ 0407",
                "village_id": 69,
                "village_name": "नरगी जीवनाथ"
            },
            {
                "label": "नवादा 0531",
                "village_id": 70,
                "village_name": "नवादा"
            },
            {
                "label": "नारायणपुर 0515",
                "village_id": 71,
                "village_name": "नारायणपुर"
            },
            {
                "label": "नारायणपुर माल 0507",
                "village_id": 72,
                "village_name": "नारायणपुर माल"
            },
            {
                "label": "पैगम्बरपुर 0514",
                "village_id": 73,
                "village_name": "पैगम्बरपुर"
            },
            {
                "label": "पगहिया ऐमा 0248",
                "village_id": 74,
                "village_name": "पगहिया ऐमा"
            },
            {
                "label": "पगहीया रामपुर 0403",
                "village_id": 75,
                "village_name": "पगहीया रामपुर"
            },
            {
                "label": "पटेढी 0346",
                "village_id": 76,
                "village_name": "पटेढी"
            },
            {
                "label": "पटेढ़ी 0346",
                "village_id": 77,
                "village_name": "पटेढ़ी"
            },
            {
                "label": "पटरहीया 0532",
                "village_id": 78,
                "village_name": "पटरहीया"
            },
            {
                "label": "परहियां 0516",
                "village_id": 79,
                "village_name": "परहियां"
            },
            {
                "label": "परहियां 0516",
                "village_id": 80,
                "village_name": "परहियां"
            },
            {
                "label": "पहाडपुर 0526",
                "village_id": 81,
                "village_name": "पहाडपुर"
            },
            {
                "label": "पिपरा गौस 0508",
                "village_id": 82,
                "village_name": "पिपरा गौस"
            },
            {
                "label": "पोखरेरा 0552",
                "village_id": 83,
                "village_name": "पोखरेरा"
            },
            {
                "label": "बंगरा रती 0404",
                "village_id": 84,
                "village_name": "बंगरा रती"
            },
            {
                "label": "बैघा टोला 0455",
                "village_id": 85,
                "village_name": "बैघा टोला"
            },
            {
                "label": "बडेवा 0538",
                "village_id": 86,
                "village_name": "बडेवा"
            },
            {
                "label": "बधनगरी 0573",
                "village_id": 87,
                "village_name": "बधनगरी"
            },
            {
                "label": "बैधा टोला 0455",
                "village_id": 88,
                "village_name": "बैधा टोला"
            },
            {
                "label": "बनिया 0470",
                "village_id": 89,
                "village_name": "बनिया"
            },
            {
                "label": "बेरुआ 0402",
                "village_id": 90,
                "village_name": "बेरुआ"
            },
            {
                "label": "बरदहाँ 0546",
                "village_id": 91,
                "village_name": "बरदहाँ"
            },
            {
                "label": "ब्रह्मपुरा 0400",
                "village_id": 92,
                "village_name": "ब्रह्मपुरा"
            },
            {
                "label": "बेली सरैया 0468",
                "village_id": 93,
                "village_name": "बेली सरैया"
            },
            {
                "label": "बसरा काजी 0397",
                "village_id": 94,
                "village_name": "बसरा काजी"
            },
            {
                "label": "बसरा सुकुल 0401",
                "village_id": 95,
                "village_name": "बसरा सुकुल"
            },
            {
                "label": "बहि गोविन्द 0567",
                "village_id": 96,
                "village_name": "बहि गोविन्द"
            },
            {
                "label": "बाधा टोला 0455",
                "village_id": 97,
                "village_name": "बाधा टोला"
            },
            {
                "label": "बायाडिह 0525",
                "village_id": 98,
                "village_name": "बायाडिह"
            },
            {
                "label": "बासदेव पट्टी 0513",
                "village_id": 99,
                "village_name": "बासदेव पट्टी"
            },
            {
                "label": "बासोकुन्द 0512",
                "village_id": 100,
                "village_name": "बासोकुन्द"
            },
            {
                "label": "बीरपुर 0510",
                "village_id": 101,
                "village_name": "बीरपुर"
            },
            {
                "label": "भगवानपुर 0456",
                "village_id": 102,
                "village_name": "भगवानपुर"
            },
            {
                "label": "भटौलिया 0555",
                "village_id": 103,
                "village_name": "भटौलिया"
            },
            {
                "label": "मुंगोली 0522",
                "village_id": 104,
                "village_name": "मुंगोली"
            },
            {
                "label": "मुजा पटरहीया 0533",
                "village_id": 105,
                "village_name": "मुजा पटरहीया"
            },
            {
                "label": "मडवा पाकड 0450",
                "village_id": 106,
                "village_name": "मडवा पाकड"
            },
            {
                "label": "मधुबन 0476",
                "village_id": 107,
                "village_name": "मधुबन"
            },
            {
                "label": "मधुरापुर 0483",
                "village_id": 108,
                "village_name": "मधुरापुर"
            },
            {
                "label": "मधौल 0347",
                "village_id": 109,
                "village_name": "मधौल"
            },
            {
                "label": "मनिकपुर 0503",
                "village_id": 110,
                "village_name": "मनिकपुर"
            },
            {
                "label": "महमदपुर वाया 0524",
                "village_id": 111,
                "village_name": "महमदपुर वाया"
            },
            {
                "label": "माधोपुर 0558",
                "village_id": 112,
                "village_name": "माधोपुर"
            },
            {
                "label": "मानिकपुर 0471",
                "village_id": 113,
                "village_name": "मानिकपुर"
            },
            {
                "label": "रघुनाथपुर 0467",
                "village_id": 114,
                "village_name": "रघुनाथपुर"
            },
            {
                "label": "रतनपुरडीह 0343",
                "village_id": 115,
                "village_name": "रतनपुरडीह"
            },
            {
                "label": "रतनपुरा 0544",
                "village_id": 116,
                "village_name": "रतनपुरा"
            },
            {
                "label": "रतवारा चन्दन 0434",
                "village_id": 117,
                "village_name": "रतवारा चन्दन"
            },
            {
                "label": "रधवा छपरा 0480",
                "village_id": 118,
                "village_name": "रधवा छपरा"
            },
            {
                "label": "रुपौली 0523",
                "village_id": 119,
                "village_name": "रुपौली"
            },
            {
                "label": "रेवाडीह 0462",
                "village_id": 120,
                "village_name": "रेवाडीह"
            },
            {
                "label": "रहमतपुर 0459",
                "village_id": 121,
                "village_name": "रहमतपुर"
            },
            {
                "label": "राजाराम पुर 0562",
                "village_id": 122,
                "village_name": "राजाराम पुर"
            },
            {
                "label": "रामदौली 0448",
                "village_id": 123,
                "village_name": "रामदौली"
            },
            {
                "label": "रामपुर दुबीयाही 0542",
                "village_id": 124,
                "village_name": "रामपुर दुबीयाही"
            },
            {
                "label": "रामपुर फांगो 0499",
                "village_id": 125,
                "village_name": "रामपुर फांगो"
            },
            {
                "label": "रामपुर बल्ली 0413",
                "village_id": 126,
                "village_name": "रामपुर बल्ली"
            },
            {
                "label": "रामपुर भागो 0498",
                "village_id": 127,
                "village_name": "रामपुर भागो"
            },
            {
                "label": "रामपुर महादेव 0354",
                "village_id": 128,
                "village_name": "रामपुर महादेव"
            },
            {
                "label": "रामपुर मांगो 0498",
                "village_id": 129,
                "village_name": "रामपुर मांगो"
            },
            {
                "label": "रामपुर विश्वनाथ 0412",
                "village_id": 130,
                "village_name": "रामपुर विश्वनाथ"
            },
            {
                "label": "लरूखीपुर अधार 0485",
                "village_id": 131,
                "village_name": "लरूखीपुर अधार"
            },
            {
                "label": "लालपुरा 0458",
                "village_id": 132,
                "village_name": "लालपुरा"
            },
            {
                "label": "वखरा 0478",
                "village_id": 133,
                "village_name": "वखरा"
            },
            {
                "label": "वतरौलिया 0466",
                "village_id": 134,
                "village_name": "वतरौलिया"
            },
            {
                "label": "वदनपुरा 0484",
                "village_id": 135,
                "village_name": "वदनपुरा"
            },
            {
                "label": "वनौली 0352",
                "village_id": 136,
                "village_name": "वनौली"
            },
            {
                "label": "वसैढा 0442",
                "village_id": 137,
                "village_name": "वसैढा"
            },
            {
                "label": "वसंतपुर पट्टी 0445",
                "village_id": 138,
                "village_name": "वसंतपुर पट्टी"
            },
            {
                "label": "वसतीसलौनी 0444",
                "village_id": 139,
                "village_name": "वसतीसलौनी"
            },
            {
                "label": "वहि भुआल 0568",
                "village_id": 140,
                "village_name": "वहि भुआल"
            },
            {
                "label": "वहिलवारा रुपनाथ 0564",
                "village_id": 141,
                "village_name": "वहिलवारा रुपनाथ"
            },
            {
                "label": "वासदेवा 0540",
                "village_id": 142,
                "village_name": "वासदेवा"
            },
            {
                "label": "वासोचक 0502",
                "village_id": 143,
                "village_name": "वासोचक"
            },
            {
                "label": "विशुनपुर अनंत 0452",
                "village_id": 144,
                "village_name": "विशुनपुर अनंत"
            },
            {
                "label": "विशुनपुर अनंत 0452",
                "village_id": 145,
                "village_name": "विशुनपुर अनंत"
            },
            {
                "label": "विशुनपुर दुबीयाही 0541",
                "village_id": 146,
                "village_name": "विशुनपुर दुबीयाही"
            },
            {
                "label": "विशुनपुर दुवियाही 0541",
                "village_id": 147,
                "village_name": "विशुनपुर दुवियाही"
            },
            {
                "label": "विशुनपुरा 0469",
                "village_id": 148,
                "village_name": "विशुनपुरा"
            },
            {
                "label": "विशरपट्टी 0528",
                "village_id": 149,
                "village_name": "विशरपट्टी"
            },
            {
                "label": "विसम्भरपुर 0536",
                "village_id": 150,
                "village_name": "विसम्भरपुर"
            },
            {
                "label": "शेख धनवत 0547",
                "village_id": 151,
                "village_name": "शेख धनवत"
            },
            {
                "label": "श्रीकृष्‍णपुर 0465",
                "village_id": 152,
                "village_name": "श्रीकृष्‍णपुर"
            },
            {
                "label": "शादीक पुर 0504",
                "village_id": 153,
                "village_name": "शादीक पुर"
            },
            {
                "label": "शिउरी ऐमा 0565",
                "village_id": 154,
                "village_name": "शिउरी ऐमा"
            },
            {
                "label": "सुजावलपुर 0560",
                "village_id": 155,
                "village_name": "सुजावलपुर"
            },
            {
                "label": "सुपना 0571",
                "village_id": 156,
                "village_name": "सुपना"
            },
            {
                "label": "सरैया 0506",
                "village_id": 157,
                "village_name": "सरैया"
            },
            {
                "label": "सहदानी 0500",
                "village_id": 158,
                "village_name": "सहदानी"
            },
            {
                "label": "सहिलापट्टी 0446",
                "village_id": 159,
                "village_name": "सहिलापट्टी"
            },
            {
                "label": "सादीपुर 0504",
                "village_id": 160,
                "village_name": "सादीपुर"
            },
            {
                "label": "सिउरी गोपीनाथपुर 0560",
                "village_id": 161,
                "village_name": "सिउरी गोपीनाथपुर"
            },
            {
                "label": "सिरकोहीया 0410",
                "village_id": 162,
                "village_name": "सिरकोहीया"
            },
            {
                "label": "हरपुर 0556",
                "village_id": 163,
                "village_name": "हरपुर"
            },
            {
                "label": "हरपुर 0556",
                "village_id": 164,
                "village_name": "हरपुर"
            },
            {
                "label": "हरपुर गौस 0435",
                "village_id": 165,
                "village_name": "हरपुर गौस"
            },
            {
                "label": "हरपुर बेनी 0553",
                "village_id": 166,
                "village_name": "हरपुर बेनी"
            },
            {
                "label": "हरिहर पुर 0534",
                "village_id": 167,
                "village_name": "हरिहर पुर"
            }
        ],
        "block_id": "15",
        "block_no": 14,
        "block_name": "सरैया [015]",
        "sub_div_code": "2"
    }
],
    "district_id": "14",
    "district_name": "मुज़फ्फरपुर"
}
];

export default districtsData;