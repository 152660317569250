import React, { useEffect, useState } from "react";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  Typography,
  Skeleton,
} from "@mui/material";
import baseUrl from "../../baseurl";

const TableComponent = ({ formData }) => {
  const [khataData, setKhataData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setKhataData([]);
      setError(null);

      let url = "http://localhost:3000/api/search/village_level_search";

      let params = {
        village_no: formData.village,
        district_no: formData.district,
        block_no: formData.block,
        sub_div_code: formData.sub_div_code,
      };

      if (formData.khata_no) {
        params.khata_no = formData.khata_no;
      } else if (formData.khesra_no) {
        params.khesra_no = formData.khesra_no;
      }

      let finalUrl1 = baseUrl + "/village_level_search?" + new URLSearchParams(params).toString();
      
      console.log(finalUrl1);
      //let finalUrl = url + "?" + new URLSearchParams(params).toString();
      try {
        const response = await Axios.get(finalUrl1);

        if (response.status === 200) {
          if (response.data.json_data && response.data.json_data.length > 0) {
            setKhataData(response.data.json_data.slice(1));
          } else {
            setError("No data available");
          }
        } else {
          setError("Something went wrong");
        }
      } catch (error) {
        console.error("Error:", error);
        setError("Something went wrong");
      } finally {
        setLoading(false);
      }
    };

    fetchData().catch((error) => console.error(error));
  }, [formData]);

  const tableContainerHeight = khataData.length > 0 ? "100vh" : "auto";

  
  return (
    <Box m={2}>
      <TableContainer
        component={Paper}
        style={{ overflowY: "hidden" }}
        height={tableContainerHeight}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <TableHead style={{ fontStyle: "italic", fontWeight: "bolder" }}>
            <TableRow>
              <TableCell>Khata No</TableCell>
              <TableCell>Khesra No</TableCell>
              <TableCell>Owner Name</TableCell>
              <TableCell>Father Name</TableCell>
              <TableCell>---</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Skeleton animation="wave" height={40} />
                  <Skeleton animation="wave" height={40} />
                  <Skeleton animation="wave" height={40} />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={13}>
                  <Typography variant="body1" textAlign={"center"}>
                    {error}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              khataData.map((item) => (
                <TableRow key={item.khataNo}>
                  <TableCell>
                    {!item.khataNo || item.khataNo.trim().length === 0
                      ? "-"
                      : item.khataNo}
                  </TableCell>
                  <TableCell>
                    {!item.khesraNo || item.khesraNo.trim().length === 0
                      ? "-"
                      : item.khesraNo}
                  </TableCell>
                  <TableCell>
                    {!item.ownerName || item.ownerName.trim().length === 0
                      ? "-"
                      : item.ownerName}
                  </TableCell>
                  <TableCell>
                    {!item.fatherName || item.fatherName.trim().length === 0
                      ? "-"
                      : item.fatherName}
                  </TableCell>
                  <TableCell>
                    <NavLink
                      to={`/plots/details/${item.ownerName}`}
                      state={item.url}
                    >
                      See Details
                    </NavLink>
                    
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TableComponent;

