import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import Table from '../components/Table/table';
import khatap from '../components/datab/content'
import { Typography,GlobalStyles, Grid,Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PlotDetailsForm1 from '../components/form/plotDetailsForm';
import data from '../assets/data'



const Village = () => {

  const isSmallScreen = useMediaQuery('(max-width:700px)');
  const isHorizontal = useMediaQuery('(max-width: 1199px)')
  const tableRef = useRef(null);

  const { districtid,districtN, blocknum, blockN, villageid, villageN } = useParams();
  const { state } = useLocation();
  const currentState = state || { khata: [] };
  const khata1 = currentState.khata;

  const dataDetail = {
    district: districtid,
    blockNum:blocknum,
    village: villageid,
  }


  console.log(dataDetail)

  const [formData, setFormData] = useState(null);

  const handleFormSubmit = (data) => {
    setFormData(data);
    // useEffect(() => {
    // }, [formData]);
    animateScroll.scrollTo(tableRef.current.offsetTop, { duration: 2000 });
  };
  
  return (
    <>
     <GlobalStyles
        styles={{
            body: { backgroundColor: "#F0F4F8",
              margin :0,
              padding:0
            }
          }}
      />
      

      <Typography variant={isSmallScreen ? 'h5' : 'h3'} fontWeight={'900'} textAlign={'center'} backgroundColor={'#243B53'} paddingY={'10px'} color={'#F0F4F8'}/*sx={{ typography: { sm: 'body1', xs: 'body2' } }}*/>
        {`Khata Details for ${villageN} (${blockN}, ${districtN})`}
      </Typography>

        {isHorizontal &&
            <Grid item xs={10} lg={3} m = {3} style={{alignItems:'center', justifyContent:'center', padding:'50px', backgroundColor:'#D9E2EC'}}>
              <Typography variant='h6' fontWeight={900} mb={4} style={{textAlign:'center', textDecoration:'solid', textTransform:'capitalize'}}>Enter any details which you have</Typography>
              <PlotDetailsForm1 data={data} onSubmit={handleFormSubmit} dataDetail = {dataDetail} />
            </Grid>
        }

        <Box ref={tableRef}>
          {formData && <Table formData={formData} />}
        </Box>

        {!isHorizontal &&
          <Grid item xs={10} lg={3} mt={5} mr = {20} ml = {20} mb = {5} style={{alignItems:'center', justifyContent:'center', padding:'50px', backgroundColor:'#D9E2EC'}}>
            <Typography variant='h6' fontWeight={900} mb={4} style={{textAlign:'center', textDecoration:'solid', textTransform:'capitalize'}}>Enter any details which you have</Typography>
            <PlotDetailsForm1 data={data} onSubmit={handleFormSubmit} dataDetail = {dataDetail} />
          </Grid>
        }
    </>
  );
};

export default Village;

