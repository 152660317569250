import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import ErrorImage from '../image/404-error.jpg'

const NotFoundPage = () => {
  return (
    <Container maxWidth="md" style={{ textAlign: 'center' }}>
      <img src={ErrorImage} alt="Error" style={{ maxWidth: '90%', margin:'0' }} />
      <Typography variant="body1" paragraph>
        The page you are looking for might be in another castle!
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        Go to Home Page
      </Button>
    </Container>
  );
};

export default NotFoundPage;
