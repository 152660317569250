import React, { useState, useRef } from 'react';
import { animateScroll } from 'react-scroll';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import PlotDetailsForm1 from '../components/form/plotDetailsForm';
import data from '../assets/data';
import { Typography, Grid, GlobalStyles,styled, Box } from '@mui/material';
import '@fontsource/roboto/300.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import Table from '../components/Table/table';


const StyledGridItem = styled(Grid)(({ theme }) => ({
  // Add any styles for the Grid item here
  padding: theme.spacing(4)
}));

const StyledLink = styled(NavLink)({
  textDecoration: 'none',
  fontSize: '1.2rem',
  fontStyle: 'unset',
  color: '#243B53',
});

const StyledBox = styled('div')(({ theme }) => ({
  border: '2px solid #ccc', // Set border properties for the rectangular box
  padding: theme.spacing(2), // Adjust padding as needed
  textAlign: 'center', // Center the text within the box
  // Add additional styles as needed
  transition: 'box-shadow 0.3s ease',
  backgroundColor:'#E0E8F9',


  '&:hover': {
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    color: '#A61B1B'
  },
}));


export default function District() {

  const isSmallScreen = useMediaQuery('(max-width:700px)');
  const isHorizontal = useMediaQuery('(max-width: 1199px)')
  const tableRef = useRef(null);

  console.log(data,"para");
  // const { districtid, districtN} = useParams();
  const { districtid, districtN } = useParams();

  const { state } = useLocation();

  console.log(state,'dist', data[0]);
  
  const currentState = state || (districtid ? data.find(district => district.district_id === districtid)
  : null) || { block: [] };

  const blocks = currentState.block; // district.block
  console.log(blocks, "sdfsdf");
  // const [searchQuery, setSearchQuery] = useState('');
  // const filteredBlocks = blocks.filter((block) =>
  //   block.block_name.toLowerCase().includes(searchQuery.toLowerCase())
  // );


  const dataDetail = {
    district: districtid,
    blockNum: '',
    village: '',
  }

  const [formData, setFormData] = useState(null);

  const handleFormSubmit = (data) => {
    
    setFormData(data);
    // useEffect(() => {
    // }, [formData]);
    animateScroll.scrollTo(tableRef.current.offsetTop, { duration: 2000 });

  };

  return (
    <>
      <GlobalStyles
        styles={{
            body: { backgroundColor: "#F0F4F8",
              margin :0,
              padding:0
            }
          }}
      />

      <Typography variant={isSmallScreen ? 'h5' : 'h3'} fontWeight={'900'} textAlign={'center'} backgroundColor={'#243B53'} paddingY={'10px'} color={'#F0F4F8'}/*sx={{ typography: { sm: 'body1', xs: 'body2' } }}*/>
        Land Record for Entire {districtN} District
      </Typography>
      

      <Grid container mt={4} pb={5} alignItems={'flex-start'} justifyContent={'space-around'} >
        {isHorizontal &&
          <Grid item xs={10} lg={3} mb ={5} style={{alignItems:'center', justifyContent:'center', padding:'50px', backgroundColor:'#D9E2EC'}}>
            <Typography variant='h6' fontWeight={900} mb={4} style={{textAlign:'center', textDecoration:'solid', textTransform:'capitalize'}}>Enter any details which you have</Typography>
            <PlotDetailsForm1 data={data} onSubmit={handleFormSubmit} dataDetail = {dataDetail} />
          </Grid>
        }
        
        <Grid item xs={10} lg={7} pb={4} alignContent={'flex-start'} style={{ backgroundColor: '#9FB3C8', overflowY: 'auto' }}>
          <Typography variant={isSmallScreen ? 'h6' : 'h4'} fontWeight={600} component="h2" textAlign={'center'} style={{ backgroundColor: '#334E68' }} color={'#E0FCFF'}>
            Bhumi Record for {districtN}
          </Typography>

          <Grid item container  >
            {blocks.map((block) => (
              <StyledGridItem item key={block.block_id} xs={12} sm={6} md={4}>
                <StyledLink to={`/plots/${districtid}/${districtN}/${block.block_no}/${block.block_name}`} state={block} >
                  <StyledBox>
                    <Typography variant="body1" >
                      {block.block_name}
                    </Typography>
                  </StyledBox>
                </StyledLink>
              </StyledGridItem>
            ))}
          </Grid>
        </Grid>

        {!isHorizontal &&
          <Grid item xs={10} lg={3} mb ={5} style={{alignItems:'center', justifyContent:'center', padding:'50px', backgroundColor:'#D9E2EC'}}>
            <Typography variant='h6' fontWeight={900} mb={4} style={{textAlign:'center', textDecoration:'solid', textTransform:'capitalize'}}>Enter any details which you have</Typography>
            <PlotDetailsForm1 data={data} onSubmit={handleFormSubmit} dataDetail = {dataDetail} />
          </Grid>
        }
      </Grid>

      <Box ref={tableRef}>
          {formData && <Table /*khataData={khatap}*/ formData={formData} />}
      </Box>
    </>
  );
}

// remove khatap when need is over
// { /*id : block.block_id,*/ name:block }
