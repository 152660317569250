// // DetailsTableComponent.js
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Box,
  GlobalStyles,
  Skeleton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import useMediaQuery from '@mui/material/useMediaQuery';
import baseUrl from "../../baseurl";

const DetailsTableComponent = () => {
  const isSmallScreen = useMediaQuery('(max-width:700px)');

  const {owner_name} = useParams();
  const { state } = useLocation();

  const [detailsData, setDetailsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleSeeMoreDetails = async () => {

    const finalurl = baseUrl + "/individualCategoryLevelCrawller";
    console.log(finalurl);
    try {
      const response = await Axios.post(
        //"http://localhost:3000/api/search/individualCategoryLevelCrawller",
        finalurl,
        {
          url: state,
        }
      );

      if (response.status === 200 && response.data.json_data) {
        console.log(response.data.json_data);
        setDetailsData(response.data.json_data);
      } else {
        setError("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      setError("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state) {
      handleSeeMoreDetails();
    }
  }, [state]);

  return (
    <>
      <GlobalStyles
        styles={{
          body: { backgroundColor: "#F0F4F8", margin: 0, padding: 0 },
        }}
      />

      <Typography
        variant={isSmallScreen ? "h5" : "h3"}
        fontWeight={"900"}
        textAlign={"center"}
        backgroundColor={"#243B53"}
        paddingY={"10px"}
        color={"#F0F4F8"} /*sx={{ typography: { sm: 'body1', xs: 'body2' } }}*/
      >
        Land Details for {owner_name}
      </Typography>

      <Box m={2}>
        <TableContainer component={Paper} style={{ overflowY: "hidden" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <TableHead style={{ fontStyle: "italic", fontWeight: "bolder" }}>
              <TableRow>
                <TableCell>Khata No</TableCell>
                <TableCell>Khesra No</TableCell>
                <TableCell>Owner Name</TableCell>
                <TableCell>Father Name</TableCell>
                <TableCell>Property Type</TableCell>
                <TableCell>Acres</TableCell>
                <TableCell>Hectares</TableCell>
                <TableCell>Dismils</TableCell>
                <TableCell>North</TableCell>
                <TableCell>South</TableCell>
                <TableCell>East</TableCell>
                <TableCell>West</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={13}>
                    <Skeleton animation="wave" height={40} />
                    <Skeleton animation="wave" height={40} />
                    <Skeleton animation="wave" height={40} />
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow>
                  <TableCell colSpan={13}>
                    <Typography variant="body1" textAlign={"center"}>
                      {error}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                detailsData.map((item) => (
                  <TableRow key={item.khata_no}>
                    <TableCell>
                      {!item.khata_no || item.khata_no.trim().length === 0
                        ? "-"
                        : item.khata_no}
                    </TableCell>
                    <TableCell>
                      {!item.khesra_no || item.khesra_no.trim().length === 0
                        ? "-"
                        : item.khesra_no}
                    </TableCell>
                    <TableCell>
                      {!item.owner_name || item.owner_name.trim().length === 0
                        ? "-"
                        : item.owner_name}
                    </TableCell>
                    <TableCell>
                      {!item.father_name || item.father_name.trim().length === 0
                        ? "-"
                        : item.father_name}
                    </TableCell>
                    <TableCell>
                      {!item.property_type ||
                      item.property_type.trim().length === 0
                        ? "-"
                        : item.property_type}
                    </TableCell>

                    <TableCell>
                      {!item.acres || item.acres.trim().length === 0
                        ? "-"
                        : item.acres}
                    </TableCell>

                    <TableCell>
                      {!item.hectares || item.hectares.trim().length === 0
                        ? "-"
                        : item.hectares}
                    </TableCell>

                    <TableCell>
                      {!item.dismils || item.dismils.trim().length === 0
                        ? "-"
                        : item.dismils}
                    </TableCell>

                    <TableCell>
                      {!item.north || item.north.trim().length === 0
                        ? "-"
                        : item.north}
                    </TableCell>
                    <TableCell>
                      {!item.south || item.south.trim().length === 0
                        ? "-"
                        : item.south}
                    </TableCell>
                    <TableCell>
                      {!item.east || item.east.trim().length === 0
                        ? "-"
                        : item.east}
                    </TableCell>
                    <TableCell>
                      {!item.west || item.west.trim().length === 0
                        ? "-"
                        : item.west}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default DetailsTableComponent;
