import React from 'react'
import { Routes, Route } from 'react-router-dom'
import SeePlot from './seePlot.js';
import District from './Page/district.js';
import Block from './Page/block.js';
import Village from "./Page/village.js";
import NotFound from './Page/NotFound.js'
import Home from "../src/Home.js"
import Panji from "../src/Page/Panji.js"
import DetailsPage from './components/Table/detailedtable.js';


const App = () => {
  return (
    <>
      <Routes>
        <Route path='/' element = {<Home/>} />
        <Route path='plots'>
          <Route index element={<SeePlot/>}/>
          <Route path=':districtid/:districtN' element = {<District/>} />
          <Route path=':districtid/:districtN/:blocknum/:blockN' element = {<Block/>} />
          <Route path=':districtid/:districtN/:blocknum/:blockN/:villageid/:villageN' element = {<Village/>} />
          <Route path='details/:owner_name' element={<DetailsPage/>} />
          <Route path='*' element = {<NotFound/>} />
        </Route>
        {/* <Route path='/panji' element = {<Panji/>} /> */}
        <Route path='*' element = {<NotFound/>} />
      </Routes>
    </>
  )
}

export default App;